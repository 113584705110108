import { useEffect, useState } from 'react';
import { AtmsApiClient } from '@amzn/et-console-components';
import { Project } from '../types/commonTypes';
import { stringify } from 'query-string';

interface State {
  isLoadingProject: boolean;
  project?: Project;
}

// TODO: Needs to reload when the user profile changes
export const useProject = (projectUid?: string): State => {
  const [state, setState] = useState<State>({ isLoadingProject: true, project: undefined });
  useEffect(() => {
    if (projectUid) {
      AtmsApiClient.httpGet(`/api/project?${stringify({ uid: projectUid })}`)
        .then(result => {
          setState({
            isLoadingProject: false,
            project: result,
          });
        })
        .catch(() => {
          setState({
            isLoadingProject: false,
          });
        });
    } else {
      setState({
        isLoadingProject: false,
      });
    }
  }, []);

  return state;
};
