import { Reference } from '../project/references/ReferenceCommons';
import { useEffect, useState } from 'react';
import { AtmsApiClient } from '@amzn/et-console-components';
import { getAppHostConfig } from '@amzn/et-console-components';

const { WEB_HOST_AND_PORT } = getAppHostConfig();

interface State {
  isLoadingReferences: boolean;
  references?: Reference[];
}

export const useReferences = (isProjectMode: boolean, uid?: string | number): State => {
  const [state, setState] = useState<State>({
    isLoadingReferences: true,
    references: undefined,
  });
  useEffect(() => {
    if (!state.isLoadingReferences) {
      setState({
        isLoadingReferences: true,
        references: undefined,
      });
    }
    if (uid) {
      const api = isProjectMode
        ? `//${WEB_HOST_AND_PORT}/web/api/v9/referenceFile/listByProject?project=` + uid
        : `//${WEB_HOST_AND_PORT}/web/api/v9/referenceFile/listByProjectTemplate?template=` + uid;
      AtmsApiClient.httpGet(`${api}`)
        .then(response => {
          setState({
            isLoadingReferences: false,
            references: response,
          });
        })
        .catch(() => {
          setState({
            isLoadingReferences: false,
            references: undefined,
          });
        });
    } else {
      setState({
        isLoadingReferences: false,
        references: undefined,
      });
    }
  }, [uid, isProjectMode]);

  return state;
};
