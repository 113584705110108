import React, { ReactElement, ReactNode, useReducer } from 'react';
import * as yup from 'yup';
import { ColumnLayout, Flash, Form } from '@amzn/awsui-components-react/polaris';
import I18n from '../../setupI18n';
import styled from '@emotion/styled';
import { css } from 'emotion';
import { HelpInfoLink } from '../HelpContentRouter';
import { PROJECT_CREATE_ASSETS_HELP } from './projectHelpContent';
import { FormikProps } from 'formik';
import { coalesceSummaryValue, WizardStepWithSummary } from '../components/WizardWithSummary';
import { Container } from '@amzn/et-polaris-utils';
import { T } from '../components/T';
import { TermBaseList } from './termbases/TermBaseList';
import { TermBaseAssignment } from './termbases/TermBaseCommons';
import { useSession, AuthenticatedSession } from '@amzn/et-console-components';
import { TranslationMemoryList } from './translationmemory/TranslationMemoryList';
import { WorkflowStep } from '../types/commonTypes';
import { TranslationMemoryModel } from './translationmemory/TranslationMemoryCommon';
import { ReferencesList } from './references/ReferencesList';
import { Reference } from './references/ReferenceCommons';

const formSpacing = css`
  padding-top: 14px;
  padding-bottom: 14px;
`;

const FormContainer = styled('div')`
  max-width: 800px;
  min-width: 280px;
`;

const DivContainerWithGutters = styled('div')`
  margin: 1.5rem 2rem;
`;

const SummaryContainer = styled('div')`
  margin: -0.5rem 0 -3rem;
`;

export interface Props<T extends ProjectCreateAPIStep2> extends FormikProps<T> {
  isProjectMode: boolean;
  validating: boolean;
  uid?: string;
  workflowSteps: WorkflowStep[];
}

export interface ProjectCreateAPIStep2 {
  termBaseAssignments: TermBaseAssignment[];
  translationMemoryAssignments: TranslationMemoryModel[];
  references: Reference[];
  sourceLocale?: string;
  targetLocales?: string[];
}

interface ReducerState {
  isError: boolean;
  error?: string;
}

export const schemaStep2 = yup.object().shape({});

export const initialFormValuesStep2 = (
  currentTermBaseAssignments?: TermBaseAssignment[],
  currentTranslationMemoryAssignments?: TranslationMemoryModel[],
  currentReferences?: Reference[]
): ProjectCreateAPIStep2 => {
  return {
    termBaseAssignments: currentTermBaseAssignments ?? [],
    translationMemoryAssignments: currentTranslationMemoryAssignments ?? [],
    references: currentReferences ?? [],
  };
};

const Content = <T extends ProjectCreateAPIStep2>({
  isProjectMode,
  errors,
  validating,
  values,
  setFieldValue,
  uid,
  workflowSteps,
}: Props<T>): ReactElement => {
  const init = (): ReducerState => ({
    isError: false,
  });

  const reducer = (state, action): ReducerState => {
    switch (action.type) {
      default:
        return state;
    }
  };

  const [reducerState] = useReducer(reducer, null, init);
  const session = useSession() as AuthenticatedSession;
  const organizationName = session?.organization?.name ?? '';
  const createdBy = session?.user ?? undefined;

  return (
    <FormContainer>
      <Form
        errorText={
          validating && Object.keys(errors).length !== 0
            ? I18n.t('The form contains errors. Fix them and resubmit.')
            : ''
        }
      >
        <TranslationMemoryList
          isProjectMode={isProjectMode}
          organizationName={organizationName}
          uid={uid}
          workflowSteps={workflowSteps.filter(wfs => values['workflowStepIds'].includes(wfs.id))}
          sourceLocale={values.sourceLocale}
          targetLocales={values.targetLocales}
          canEdit={true}
          isInProjectTemplateCreationEditPage={true}
          currConfiguredTransMemories={values.translationMemoryAssignments}
          onDeferredSave={(translationMemoryModels): void => {
            setFieldValue('translationMemoryAssignments', translationMemoryModels);
          }}
        />

        <TermBaseList
          organizationName={organizationName}
          uid={uid}
          sourceLocale={values.sourceLocale}
          targetLocales={values.targetLocales}
          canEdit={true}
          isInProjectTemplateCreationEditPage={true}
          currConfiguredTermBases={values.termBaseAssignments}
          onDeferredSave={(termBases): void => {
            setFieldValue('termBaseAssignments', termBases);
          }}
        />
        <ReferencesList
          isProjectMode={isProjectMode}
          uid={uid}
          canEdit={true}
          isInProjectTemplateCreationEditPage={true}
          currReferences={values.references}
          onDeferredSave={(references): void => {
            setFieldValue('references', references);
          }}
          createdBy={createdBy}
        />
        {reducerState.isError && (
          <div className={formSpacing}>
            <Flash
              id="validation-error"
              type="error"
              dismissible={true}
              content={I18n.t('Please try again. If the issue persists, contact support.')}
              header={I18n.t('Something went wrong.')}
            />
          </div>
        )}
      </Form>
    </FormContainer>
  );
};

export const ProjectCreateStep2Assets = <T extends ProjectCreateAPIStep2>(
  props: Props<T>
): WizardStepWithSummary => {
  const title = I18n.t('Assets');
  // unify TB, TM and reference object forms for comparison
  const unifyTB = (termbases): object[] => {
    return termbases
      ?.sort((a, b) => a.tbId - b.tbId)
      ?.map(tb => ({
        id: tb.tbId,
        readMode: tb.readMode,
        writeMode: tb.writeMode,
        qa: tb.qualityAssurance,
        priority: tb.priority,
        targetLang: tb.targetLang,
      }));
  };
  const unifyTM = (transmemories): object[] => {
    return transmemories
      ?.sort((a, b) => a.id - b.id)
      ?.map(tm => ({
        id: tm.id,
        readMode: tm.readMode,
        writeMode: tm.writeMode,
        penalty: tm.penalty,
        workflowStep: tm.workflowStepName,
        targetLang: tm.targetLang,
      }));
  };
  const unifyReference = (references?: Reference[]): object[] | undefined => {
    return references?.map?.(r => ({
      file: r.file ?? undefined,
      note: r.note ?? undefined,
    }));
  };
  return {
    title: title,
    info: (): ReactElement => <HelpInfoLink helpId={PROJECT_CREATE_ASSETS_HELP} />,
    description: I18n.t('Add translation memories, term bases and references'),
    content: (): ReactElement => <Content {...props} />,
    isOptional: true,
    summary: ({
      currentTermBaseAssignments,
      currentTranslationMemoryAssignments,
      currentReferences,
    }): ReactNode => {
      const { values, isProjectMode, uid, workflowSteps } = props;
      const summaryElements = [
        // term base settings
        coalesceSummaryValue({
          key: 'tbSettings',
          currentValue: JSON.stringify(unifyTB(currentTermBaseAssignments)),
          value: JSON.stringify(unifyTB(values.termBaseAssignments.filter(tb => tb.readMode))),
          displayValue: (
            <TermBaseList
              organizationName={''}
              uid={uid}
              sourceLocale={values['sourceLocale']}
              targetLocales={values['targetLocales']}
              isInProjectTemplateCreationEditPage={true}
              canEdit={false}
              currConfiguredTermBases={values.termBaseAssignments ?? []}
            />
          ),
        }),
        // translation memory settings
        coalesceSummaryValue({
          key: 'tmSettings',
          currentValue: JSON.stringify(unifyTM(currentTranslationMemoryAssignments)),
          value: JSON.stringify(
            unifyTM(values.translationMemoryAssignments.filter(tm => tm.readMode))
          ),
          displayValue: (
            <TranslationMemoryList
              isProjectMode={isProjectMode}
              organizationName={''}
              uid={uid}
              workflowSteps={workflowSteps.filter(wfs =>
                values['workflowStepIds'].includes(wfs.id)
              )}
              sourceLocale={values['sourceLocale']}
              targetLocales={values['targetLocales']}
              canEdit={false}
              isInProjectTemplateCreationEditPage={true}
              currConfiguredTransMemories={values.translationMemoryAssignments ?? []}
            />
          ),
        }),
        // reference settings
        coalesceSummaryValue({
          key: 'referenceSettings',
          currentValue: JSON.stringify(unifyReference(currentReferences)),
          value: JSON.stringify(unifyReference(values.references)),
          displayValue: (
            <ReferencesList
              isProjectMode={isProjectMode}
              uid={uid}
              canEdit={false}
              isInProjectTemplateCreationEditPage={true}
              currReferences={values.references ?? []}
            />
          ),
        }),
      ].filter(s => s);
      return (
        <Container title={title} key={title} withGutters={false}>
          <ColumnLayout columns={1}>
            <div data-awsui-column-layout-root="true">
              {summaryElements.length > 0 ? (
                <SummaryContainer>{summaryElements}</SummaryContainer>
              ) : (
                <DivContainerWithGutters>
                  <T>No changes</T>
                </DivContainerWithGutters>
              )}
            </div>
          </ColumnLayout>
        </Container>
      );
    },
  };
};
