import { useEffect, useState } from 'react';
import { AtmsApiClient, displayUser } from '@amzn/et-console-components';
import { Owner, User } from '../types/commonTypes';
import { getAppHostConfig } from '@amzn/et-console-components';

const { WEB_HOST_AND_PORT } = getAppHostConfig();

interface State {
  isLoadingOwners: boolean;
  owners: Owner[];
  ownersById: { [id: number]: Owner };
}

// TODO: Needs to reload when the user profile changes
export const useOwners = (): State => {
  const [state, setState] = useState<State>({
    isLoadingOwners: true,
    owners: [],
    ownersById: {},
  });
  useEffect(() => {
    AtmsApiClient.httpGet(
      `//${WEB_HOST_AND_PORT}/web/api/v9/user/list?role=PROJECTMANAGER&role=ADMIN&pageSize=1000&extended=false`
    ).then(result => {
      const users = result as User[];
      setState({
        isLoadingOwners: false,
        owners: users.map(user => ({ id: user.id, name: '' + displayUser(user) })),
        ownersById: result.reduce((acc, val) => {
          acc[val.id] = val;
          return acc;
        }, {}),
      });
    });
  }, []);

  return state;
};
