import I18n from '../setupI18n';

export default (apiError): string => {
  console.error(JSON.stringify(apiError));
  const errorDescription = apiError.details?.errorDescription;
  const errorName =
    apiError.details?.errorCode ??
    apiError.details?.errors?.[0]?.name ??
    apiError.details ??
    parseAsyncError(apiError) ??
    JSON.stringify(apiError);
  switch (errorName) {
    case 'JobQualityAssuranceUnresolved':
      return I18n.t('Unresolved QA warnings.');
    case 'NonBilingualFile':
      return I18n.t('The file is not an ATMS supported bilingual table (DOC/DOCX, MXLIFF).');
    case 'JobSegmentsUnconfirmed':
      return I18n.t('Less than 100% segments confirmed. Confirm all segments and then retry.');
    case 'JobNotDownloadable':
      return I18n.t(
        'The translated document cannot be exported, please open the job in the CAT tool and check for QA warnings.'
      );
    case 'JobContainsEmptyTargets':
      return I18n.t(
        'Some segments do not have a translation. Please add a translation to all segments and retry.'
      );
  }

  return `${errorDescription ?? errorName}`;
};

const parseAsyncError = apiError => {
  if (apiError.reason) {
    const reason = JSON.parse(apiError.reason);
    return reason.errors?.[0]
      ?.split(':')
      .pop()
      .trim();
  }
};
