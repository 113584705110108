import React, {
  ReactElement,
  useCallback,
  useLayoutEffect,
  useReducer,
  useState,
  Fragment,
} from 'react';
import {
  Alert,
  CustomDetailEvent,
  Table,
  TableSelection,
} from '@amzn/awsui-components-react/polaris';
import { Container, PageHeader } from '@amzn/et-polaris-utils';
import { stringify } from 'query-string';
import { AtmsApiClient, convertDate, displayUser } from '@amzn/et-console-components';
import { CellContents, User } from '../../types/commonTypes';
import { PROJECT_DETAIL_MANAGE_REFS_HELP } from './../projectHelpContent';
import { HelpInfoLink } from '../../HelpContentRouter';
import I18n from '../../../setupI18n';
import { StandardModal } from '../../components/StandardModal';
import { AddReference } from './AddReference';
import { Reference, createReferences, deleteReferences } from './ReferenceCommons';
import { DateTime } from 'luxon';
import { getAppHostConfig } from '@amzn/et-console-components';

const { WEB_HOST_AND_PORT } = getAppHostConfig();

const columnDefinitions: Table.ColumnDefinition<Reference>[] = [
  {
    id: 'name',
    header: I18n.t('Name'),
    cell: (item): CellContents => item.name ?? '-',
    width: 350,
  },
  {
    id: 'note',
    header: I18n.t('Note'),
    cell: (item): CellContents => item.note ?? '-',
  },
  {
    id: 'dateCreated',
    header: I18n.t('Created'),
    cell: (item): CellContents => (item.dateCreated ? convertDate(item.dateCreated) : '-'),
  },
  {
    id: 'createdBy',
    header: I18n.t('Created by'),
    cell: (item): CellContents => (item.createdBy ? displayUser(item.createdBy) : '-'),
  },
];

export interface Props {
  isProjectMode: boolean; // true: project; false: template
  uid?: string;
  canEdit: boolean;
  isInProjectTemplateCreationEditPage: boolean; // whether in project/template creation/edit pages
  currReferences?: Reference[]; // current references in project/template creation/edit pages
  onDeferredSave?: (references: Reference[]) => void;
  createdBy?: User;
}

interface ReducerState {
  isLoading: boolean;
  isError: boolean;
  isDownloadingReferences: boolean;
  isDeletingReferences: boolean;
  isUploadingReferences: boolean;
  error?: object;
  references: Reference[];
  selectedReferences: Reference[];
  addReferencePageToggle: boolean;
}

export const ReferencesList = ({
  isProjectMode,
  uid,
  canEdit,
  isInProjectTemplateCreationEditPage,
  currReferences,
  onDeferredSave,
  createdBy,
}: Props): ReactElement => {
  const init = (): ReducerState => ({
    isLoading: false,
    isError: false,
    isDownloadingReferences: false,
    isDeletingReferences: false,
    isUploadingReferences: false,
    references: [],
    selectedReferences: [],
    addReferencePageToggle: false,
  });

  const reducer = (state, action): ReducerState => {
    switch (action.type) {
      case 'loadingReferences':
        return {
          ...state,
          isLoading: true,
          isError: false,
        };
      case 'loadedReferences':
        return {
          ...state,
          isLoading: false,
          references: action.references,
        };
      case 'loadReferencesFailed':
        return {
          ...state,
          isLoading: false,
          isError: true,
          error: action.error,
        };
      case 'changeReferencesSelection':
        return {
          ...state,
          selectedReferences: action.selectedReferences,
        };
      case 'referenceDownloading':
        return {
          ...state,
          isDownloadingReferences: true,
        };
      case 'referenceDownloaded':
        return {
          ...state,
          isDownloadingReferences: false,
        };
      case 'referenceDownloadFailed':
        return {
          ...state,
          isDownloadingReferences: false,
          error: action.error,
        };
      case 'referenceDeleting':
        return {
          ...state,
          isDeletingReferences: true,
        };
      case 'referenceDeleted':
        return {
          ...state,
          isDeletingReferences: false,
        };
      case 'referenceDeleteFailed':
        return {
          ...state,
          isDeletingReferences: false,
          error: action.error,
        };
      case 'referenceUploading':
        return {
          ...state,
          isUploadingReferences: true,
        };
      case 'referenceUploaded':
        return {
          ...state,
          isUploadingReferences: false,
        };
      case 'referenceUploadFailed':
        return {
          ...state,
          isUploadingReferences: false,
          error: action.error,
        };
      case 'toggleAddReferencePage':
        return {
          ...state,
          addReferencePageToggle: action.addReferencePageToggle,
        };
      default:
        return state;
    }
  };

  const [reducerState, dispatch] = useReducer(reducer, null, init);
  const {
    isLoading,
    isError,
    isDownloadingReferences,
    isDeletingReferences,
    isUploadingReferences,
    error,
    references,
    selectedReferences,
    addReferencePageToggle,
  } = reducerState;
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const handleLoadReferences = useCallback(() => {
    dispatch({ type: 'loadingReferences' });
    const query = { project: uid };
    AtmsApiClient.httpGet(
      `//${WEB_HOST_AND_PORT}/web/api/v9/referenceFile/listByProject?${stringify(query)}`
    )
      .then(response => {
        dispatch({
          type: 'loadedReferences',
          references: response,
        });
      })
      .catch(e => {
        dispatch({ type: 'loadReferencesFailed', error: e });
      });
  }, [uid]);

  const handleSelectionChange = (
    changeDetail: CustomDetailEvent<TableSelection.SelectionChangeDetail>
  ): void => {
    dispatch({
      type: 'changeReferencesSelection',
      selectedReferences: changeDetail.detail.selectedItems,
    });
  };

  const handleDownloadClick = (): void => {
    dispatch({ type: 'referenceDownloading' });
    const query = {
      referenceFile: selectedReferences.map(ref => ref.id),
    };

    AtmsApiClient.download(
      `//${WEB_HOST_AND_PORT}/web/api/v2/referenceFile/download?${stringify(query)}`
    )
      .then(() => {
        dispatch({ type: 'referenceDownloaded' });
      })
      .catch(err => {
        dispatch({ type: 'referenceDownloadFailed', error: err });
      });
  };

  const handleDeleteDialogOkButtonClick = (event): void => {
    if (isInProjectTemplateCreationEditPage && onDeferredSave) {
      event.preventDefault();
      setShowDeleteDialog(false);
      onDeferredSave(references.filter(r => !selectedReferences.includes(r)));
    } else {
      setShowDeleteDialog(false);
      dispatch({ type: 'referenceDeleting' });
      deleteReferences(isProjectMode, selectedReferences)
        .then(() => {
          dispatch({ type: 'referenceDeleted' });
          handleLoadReferences();
        })
        .catch(e => {
          dispatch({ type: 'referenceDeleteFailed', error: e });
        });
    }
  };

  const handleOpenAddReferencePage = (event?): void => {
    if (isInProjectTemplateCreationEditPage && event) {
      event.preventDefault();
    }
    dispatch({
      type: 'toggleAddReferencePage',
      addReferencePageToggle: true,
    });
  };

  const handleCloseAddReferencePage = (event?): void => {
    if (isInProjectTemplateCreationEditPage && event) {
      event.preventDefault();
    }
    dispatch({
      type: 'toggleAddReferencePage',
      addReferencePageToggle: false,
    });
  };

  const handleUploadReference = (file, note): void => {
    if (isInProjectTemplateCreationEditPage && onDeferredSave) {
      const reference = {
        name: file?.name ?? undefined,
        file: file ?? undefined,
        note: note ?? undefined,
        dateCreated: DateTime.local().toISO(),
        createdBy: createdBy,
      } as Reference;
      onDeferredSave((currReferences ?? []).concat(reference));
      handleCloseAddReferencePage();
    } else {
      dispatch({ type: 'referenceUploading' });
      Promise.all(createReferences(isProjectMode, [{ file: file, note: note }], uid))
        .then(() => {
          dispatch({ type: 'referenceUploaded' });
          handleCloseAddReferencePage();
          handleLoadReferences();
        })
        .catch(e => {
          dispatch({ type: 'referenceUploadFailed', error: e });
        });
    }
  };

  // Load the page if not loaded or if UID changes.
  useLayoutEffect(() => {
    if (isInProjectTemplateCreationEditPage) {
      dispatch({
        type: 'loadedReferences',
        references: Array.isArray(currReferences ?? []) ? currReferences ?? [] : [],
      });
    } else {
      handleLoadReferences();
    }
  }, [uid, handleLoadReferences, isInProjectTemplateCreationEditPage, currReferences]);

  if (isError) {
    return <Alert type="error" content={`Failed to load references: ${error}`} />;
  }

  return (
    <Fragment>
      <Container withGutters={false}>
        <Table
          id="referenceList"
          variant="borderless"
          loading={isLoading}
          columnDefinitions={columnDefinitions}
          header={
            <PageHeader
              tag="h2"
              title={I18n.t('References')}
              extraContent={<HelpInfoLink helpId={PROJECT_DETAIL_MANAGE_REFS_HELP} />}
              buttons={[
                !isInProjectTemplateCreationEditPage && {
                  id: 'download',
                  text: I18n.t('Download'),
                  onClick: handleDownloadClick,
                  disabled: selectedReferences.length === 0 || isDownloadingReferences,
                  loading: isDownloadingReferences,
                },
                canEdit && {
                  id: 'deleteReference',
                  text: I18n.t('Delete'),
                  onClick: (event): void => {
                    if (isInProjectTemplateCreationEditPage) {
                      event.preventDefault();
                    }
                    setShowDeleteDialog(true);
                  },
                  disabled: selectedReferences.length === 0 || isDeletingReferences,
                  loading: isDeletingReferences,
                },
                canEdit && {
                  id: 'addReference',
                  text: I18n.t('Add reference'),
                  onClick: handleOpenAddReferencePage,
                  variant: 'primary',
                },
              ]}
            />
          }
          items={references}
          empty={I18n.t('No references found')}
        >
          <TableSelection
            selectedItems={selectedReferences}
            onSelectionChange={handleSelectionChange}
          />
        </Table>
        <AddReference
          visible={addReferencePageToggle}
          onClose={handleCloseAddReferencePage}
          onUploadReference={handleUploadReference}
          isUploadingReferences={isUploadingReferences}
          isInProjectTemplateCreationEditPage={isInProjectTemplateCreationEditPage}
        />
      </Container>

      <StandardModal
        id="referenceListDeleteModal"
        content={I18n.t(
          {
            one: 'Do you want to delete %{referenceName}?',
            other: 'Do you want to delete %{count} references?',
          },
          {
            count: selectedReferences.length,
            referenceName:
              selectedReferences.length > 0 &&
              (selectedReferences[0].name ?? 'note: ' + selectedReferences[0].note),
          }
        )}
        header={I18n.t(
          {
            one: 'Delete reference?',
            other: 'Delete %{count} references?',
          },
          {
            count: selectedReferences.length,
          }
        )}
        okButtonId="referenceListDeleteModalConfirm"
        visible={showDeleteDialog}
        handleCancelClick={() => setShowDeleteDialog(false)}
        handleOkClick={event => handleDeleteDialogOkButtonClick(event)}
      />
    </Fragment>
  );
};
