import React, {
  Fragment,
  useReducer,
  useState,
  useLayoutEffect,
  useCallback,
  ReactElement,
  useEffect,
  ReactNode,
} from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  Alert,
  Button,
  ButtonDropdown,
  CustomDetailEvent,
  Modal,
  Select,
  Spinner,
} from '@amzn/awsui-components-react/polaris';
import {
  canDo,
  Icon,
  useRecentItems,
  AuthenticatedSession,
  AtmsApiClient,
  formatLocaleCode,
  displayUserWithLoginLink,
} from '@amzn/et-console-components';
import { PageHeader, KeyValuePane, Container } from '@amzn/et-polaris-utils';
import { PROJECT_DETAIL_INFO_HELP, PROJECT_DETAIL_MAIN_HELP } from './projectHelpContent';
import { JobList } from './jobs/JobList';
import { AnalysisList } from './AnalysisList';
import { TranslationMemoryList } from './translationmemory/TranslationMemoryList';
import { TermBaseList } from './termbases/TermBaseList';
import { ReferencesList } from './references/ReferencesList';
import { Project, WorkflowStep, MediaType, Subject } from '../types/commonTypes';
import { History, Location } from 'history';
import { HelpInfoLink } from '../HelpContentRouter';
import I18n from '../../setupI18n';
import { Link } from 'react-router-dom';
import { displayTimestamp, displayMoment, toDateTime } from '../../shared/displayTimestamp';
import { parse, stringify } from 'query-string';
import styled from '@emotion/styled';
import { Tooltip } from '../components/Tooltip';
import { T } from '../components/T';
import { NoWrapCell } from '../../shared/Styled';
import { ScoringSummary } from './ScoringSummary';
import { isScoringProject } from '../../shared/scoring';
import { DateTime } from 'luxon';
import { publishCountMetric, publishLatencyMetric } from '../metricHelper';
import { getAppHostConfig } from '@amzn/et-console-components';

const { WEB_HOST_AND_PORT } = getAppHostConfig();

const InlineContainer = styled.div({
  display: 'inline-block',
});

export interface Props extends RouteComponentProps {
  session: AuthenticatedSession;
  history: History;
  location: Location;
}

export interface ReducerState {
  isLoading: boolean;
  isError: boolean;
  error?: object;
  isWarning: boolean;
  warning?: object;
  isDeleted: boolean;
  project?: Project;
  mediaTypes?: MediaType[];
  subjects?: Subject[];
  scoringEnabled?: boolean;
  scoringConfigIsLoading: boolean;
  deletionPostponed: boolean;
}

export const displayMachineTranslateSettings = (project): ReactNode => {
  if (project.machineTranslateSettings) {
    return project.machineTranslateSettings.name;
  } else if (project.langSettings) {
    const uniqueMachineTranslateSettings = [
      ...new Set(
        project.langSettings.map(
          (settings): number | undefined =>
            settings.machineTranslateSettings && settings.machineTranslateSettings.id
        )
      ),
    ];
    const filteredMachineTranslateSettings = uniqueMachineTranslateSettings.filter(e => e);

    if (uniqueMachineTranslateSettings.length === 1 && uniqueMachineTranslateSettings[0]) {
      return project.langSettings[0].machineTranslateSettings.name;
    } else if (
      filteredMachineTranslateSettings.length < uniqueMachineTranslateSettings.length &&
      filteredMachineTranslateSettings.length > 0
    ) {
      return I18n.t('Partially enabled');
    } else if (filteredMachineTranslateSettings.length > 0) {
      return I18n.t('Enabled');
    }
  }

  return I18n.t('Disabled');
};

export const ProjectDetail = withRouter(
  ({ session, history, location, location: { pathname, search } }: Props): ReactElement => {
    const init = (): ReducerState => ({
      isLoading: false,
      isError: false,
      isWarning: false,
      isDeleted: false,
      scoringConfigIsLoading: false,
      deletionPostponed: false,
    });

    // TODO: We should be able to get this from the Router.match.params prop
    const [projectUID, setProjectUID] = useState(pathname.substr(pathname.lastIndexOf('/') + 1));
    const [, addRecentItem] = useRecentItems(session.user.id);
    useEffect(() => {
      setProjectUID(pathname.substr(pathname.lastIndexOf('/') + 1));
    }, [pathname, setProjectUID]);

    const reducer = (state, action): ReducerState => {
      switch (action.type) {
        case 'loadingProject':
          return {
            ...state,
            isLoading: true,
            isError: false,
            project: null,
          };
        case 'loadedProject':
          return {
            ...state,
            isLoading: false,
            project: action.project,
          };
        case 'loadProjectFailed':
          return {
            ...state,
            isLoading: false,
            isError: true,
            error: action.error,
          };
        case 'mediaTypesLoaded':
          return {
            ...state,
            mediaTypes: action.mediaTypes,
          };
        case 'mediaTypesLoadFailed':
          return {
            ...state,
            error: action.error,
          };
        case 'subjectsLoaded':
          return {
            ...state,
            subjects: action.subjects,
          };
        case 'subjectsLoadFailed':
          return {
            ...state,
            error: action.error,
          };
        case 'deletingProject':
          return {
            ...state,
            isLoading: true,
            isError: false,
          };
        case 'deletedProject':
          return {
            ...state,
            isLoading: false,
            isError: false,
            isDeleted: true,
          };
        case 'deleteProjectFailed':
          return {
            ...state,
            isLoading: false,
            isError: true,
            error: action.error,
          };
        case 'postponedDeletion':
          return {
            ...state,
            deletionPostponed: true,
            isWarning: false,
          };
        case 'postponeDeletionFailed':
          return {
            ...state,
            isWarning: true,
            warning: action.warning,
          };
        case 'warningDismissed':
          return {
            ...state,
            isWarning: false,
          };
        case 'scoringConfigIsLoading':
          return {
            ...state,
            scoringConfigIsLoading: true,
          };
        case 'scoringConfigLoaded':
          return {
            ...state,
            scoringEnabled: action.scoringEnabled,
            scoringConfigIsLoading: false,
          };
        case 'scoringConfigFailed':
          return {
            ...state,
            error: action.error,
            scoringEnabled: undefined,
            scoringConfigIsLoading: false,
          };
        default:
          return state;
      }
    };

    const [reducerState, dispatch] = useReducer(reducer, null, init);
    const {
      project,
      isLoading,
      isError,
      error,
      isWarning,
      warning,
      mediaTypes,
      subjects,
      scoringEnabled,
      scoringConfigIsLoading,
      deletionPostponed,
    } = reducerState;

    // TODO: We should have a canonical list of available permissions instead of sprinkling them throughout the code
    const ownsProject: boolean = project?.owner?.id === session?.user?.id;
    const canEdit = ownsProject || canDo(session, 'project:editOther');
    const canDelete = ownsProject || canDo(session, 'project:deleteOther');
    const canCreateProjectTemplate = canDo(session, 'projectTemplate:create');
    const canDownload =
      session?.user?.role !== 'LINGUIST' || project?.allowLinguistsToDownloadTranslationJobs;

    // TODO: The workflow step picker should be pulled out of JobList se we don't have to do this.
    //  I attempted to do it in my first pass at this and I couldn't get the TranslationMemoryList to
    //  update reliably.
    const [query, setQuery] = useState(parse(search, { parseNumbers: true }));
    const [workflowStep, setWorkflowStep] = useState<WorkflowStep | undefined>();

    useEffect(() => {
      setQuery(parse(search, { parseNumbers: true }));
    }, [search]);

    useEffect(() => {
      if (query.level) {
        setWorkflowStep(project?.workflowSteps?.find(w => w.level === query.level));
      } else if (project?.activeWorkflowStep) {
        setWorkflowStep(project.activeWorkflowStep);
      }
    }, [project, query.level]);

    const handleLoadProject = useCallback(
      values => {
        dispatch({
          type: 'loadingProject',
        });
        const initialTime = new Date();

        AtmsApiClient.httpGet(`/api/project?uid=${values.projectUID}`)
          .then((response: Project) => {
            dispatch({ type: 'loadedProject', project: response });
            addRecentItem({ type: 'project', name: response.name, path: pathname });
            //Change the browser tab title
            document.title = response.name;
          })
          .catch(e => {
            if (
              e?.details?.errors?.[0]?.message.startsWith(
                'Argument "project" of type "domain(Project)" does not accept given value'
              )
            ) {
              // Indicates the project was not found
              history.replace('/web/error/notFound');
            } else {
              publishCountMetric('loadProjectFailed-ProjectDetail', 'error', e.message);
              dispatch({ type: 'loadProjectFailed', error: e });
            }
          })
          .finally(() => {
            const finalTime = new Date();
            publishLatencyMetric('ProjectDetailPage-Latency', initialTime, finalTime);
          });
      },
      [history, addRecentItem, pathname]
    );

    const handleLoadMediaTypes = useCallback(() => {
      dispatch({ type: 'mediaTypesLoading' });
      AtmsApiClient.httpGet(`//${WEB_HOST_AND_PORT}/web/api/v9/mediaType/list`)
        .then(response => {
          dispatch({
            type: 'mediaTypesLoaded',
            mediaTypes: response.reduce((acc, value) => ({ ...acc, [value.id]: value }), {}),
          });
        })
        .catch(e => {
          publishCountMetric('mediaTypesLoadFailed-ProjectDetail', 'error', e.message);
          dispatch({ type: 'mediaTypesLoadFailed', error: e });
        });
    }, []);

    const handleLoadSubjects = useCallback(() => {
      dispatch({ type: 'subjectsLoading' });
      AtmsApiClient.httpGet(`//${WEB_HOST_AND_PORT}/web/api/v9/subject/list`)
        .then(response => {
          dispatch({
            type: 'subjectsLoaded',
            subjects: response.reduce((acc, value) => ({ ...acc, [value.id]: value }), {}),
          });
        })
        .catch(e => {
          publishCountMetric('subjectsLoadFailed-ProjectDetail', 'error', e.message);
          dispatch({ type: 'subjectsLoadFailed', error: e });
        });
    }, []);

    // Load the page if not loaded or if UID changes.
    useLayoutEffect(() => {
      handleLoadProject({
        projectUID: projectUID,
      });
    }, [projectUID, handleLoadProject]);

    useEffect(() => {
      handleLoadMediaTypes();
      handleLoadSubjects();
    }, [handleLoadMediaTypes, handleLoadSubjects]);

    const handleWorkflowLevelChange = (
      changeDetail: CustomDetailEvent<Select.ChangeDetail>
    ): void => {
      const query = {
        // TODO: Don't clear the existing filters
        level: parseInt(changeDetail.detail.selectedId),
      };

      history.push({ ...location, search: stringify(query) });
    };

    const handleSaveAsMenuClick = (e: CustomDetailEvent<ButtonDropdown.ItemClick>): void => {
      switch (e.detail.id) {
        case 'save-as-template':
          history.push(`/web/projectTemplate/create/${projectUID}`);
          break;

        case 'delete':
          handleDeleteButtonClick();
          break;
      }
    };

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [jobListChangePointer, setJobListChangePointer] = useState(0);

    const handleDeleteButtonClick = (): void => {
      setShowDeleteDialog(true);
    };

    const handleDeleteDialogCancelButtonClick = (): void => {
      setShowDeleteDialog(false);
    };

    const handleDeleteDialogOkButtonClick = (): void => {
      setShowDeleteDialog(false);
      dispatch({
        type: 'deletingProject',
      });
      AtmsApiClient.httpDelete(`/api/project?uid=${projectUID}`)
        .then(() => {
          dispatch({ type: 'deletedProject' });
          history.push(`/web/project/list`);
        })
        .catch(e => {
          publishCountMetric('deleteProjectFailed-ProjectDetail', 'error', e.message);
          dispatch({ type: 'deleteProjectFailed', error: e });
        });
    };

    const canCreateJobs = (): boolean => {
      if (project && !project.jobCreationAllowed) {
        return false;
      }
      return true;
    };

    const shouldShowAutoDeletionWarning = (): boolean => {
      return canEdit && !canCreateJobs();
    };

    const getAutoDeleteWarning = (): string => {
      if (!project) {
        return '';
      }

      const autoDeletionDateTime = toDateTime(project.autoDeletionDateTime);
      if (!autoDeletionDateTime) {
        return '';
      }
      if (autoDeletionDateTime < DateTime.local()) {
        return I18n.t(
          'This project will be moved to the Recycle Bin soon and permanently deleted after 30 days. You can no longer create jobs in this project. Click Postpone to postpone moving the project to the Recycle Bin until %{postponeDate} and re-enable job creation.',
          {
            postponeDate: getPostponeDeletionDate(),
          }
        );
      } else {
        return I18n.t(
          'This project will be moved to the Recycle Bin on %{recycleDate} and permanently deleted after 30 days. You can no longer create jobs in this project. Click Postpone to postpone moving the project to the Recycle Bin until %{postponeDate} and re-enable job creation.',
          {
            recycleDate: displayTimestamp(project.autoDeletionDateTime, false),
            postponeDate: getPostponeDeletionDate(),
          }
        );
      }
    };

    const handlePostponeDeletionClick = (): void => {
      const query = {
        project: projectUID,
      };

      AtmsApiClient.httpGet(
        `//${WEB_HOST_AND_PORT}/web/api/v9/project/postponeDeletion?${stringify(query)}`
      )
        .then(() => {
          dispatch({ type: 'postponedDeletion' });
        })
        .catch(error => {
          publishCountMetric('postponeDeletionFailed-ProjectDetail', 'error', error.message);
          dispatch({
            type: 'postponeDeletionFailed',
            warning: `${I18n.t('Failed to postpone moving project to the recycle bin')}: ${error}`,
          });
        });
    };

    const getPostponeDeletionDate = (): string => {
      // TODO: This is the ProjectCleanerJob.POSTPONE_RECYCLE_INTERVAL_IN_MONTHS value in web. We should get this from web somehow so we don't have to keep them in sync.
      return (project && displayMoment(toDateTime(project.autoDeletionDateTime, 4), false)) || '';
    };

    const handleLoadScoringConfig = useCallback(() => {
      if (!project) {
        return;
      }
      const query = {
        project: project.id,
      };

      let scoringEnabled = false;
      dispatch({ type: 'scoringConfigIsLoading' });
      AtmsApiClient.httpGet(`/api/scoring/getAllConfigs?${stringify(query)}`)
        .then(response => {
          if (response && response.length > 0) {
            scoringEnabled = true;
          }
          dispatch({
            type: 'scoringConfigLoaded',
            scoringEnabled: scoringEnabled,
          });
        })
        .catch(e => {
          publishCountMetric('scoringConfigFailed-ProjectDetail', 'error', e.message);
          dispatch({ type: 'scoringConfigFailed', error: e });
        });
    }, [project]);

    useEffect(() => {
      handleLoadScoringConfig();
    }, [handleLoadScoringConfig]);

    const displayIsScoringEnabled = (): ReactNode => {
      if (scoringConfigIsLoading) {
        return <Spinner />;
      } else {
        return scoringEnabled ? I18n.t('Enabled') : I18n.t('Disabled');
      }
    };

    if (isError && !project) {
      return <Alert type="error" content={`${I18n.t('Failed')}: ${error}`} />;
    } else if (isLoading || !project) {
      return <Spinner size={'large'} />;
    } else {
      return (
        <div>
          {/* PROJECT METADATA */}
          <PageHeader
            title={project.name}
            extraContent={<HelpInfoLink helpId={PROJECT_DETAIL_MAIN_HELP} />}
            buttons={
              canEdit
                ? [
                    {
                      id: 'project-actions',
                      text: I18n.t('Actions'),
                      items: [
                        {
                          id: 'save-as-template',
                          text: I18n.t('Save as a template...'),
                          disabled: !canCreateProjectTemplate,
                        },
                        {
                          id: 'delete-submenu',
                          text: '',
                          items: [
                            {
                              id: 'delete',
                              text: I18n.t('Delete project'),
                              disabled: !canDelete,
                            },
                          ],
                        },
                      ],
                      onItemClick: handleSaveAsMenuClick,
                    },
                  ]
                : []
            }
          />
          {isWarning && (
            <div style={{ marginBottom: 15 }}>
              <Alert
                type="warning"
                dismissible={true}
                onDismiss={(): void => dispatch({ type: 'warningDismissed' })}
                content={warning}
              />
            </div>
          )}
          {isError && <Alert type="error" content={`${I18n.t('Failed')}: ${error}`} />}
          {!isError && (
            <Container
              title={I18n.t('Project info')}
              extraContent={<HelpInfoLink helpId={PROJECT_DETAIL_INFO_HELP} />}
              buttons={[
                canEdit && {
                  id: 'edit-project',
                  text: I18n.t('Edit project'),
                  onClick: (): void => history.push(`/web/project/edit/${projectUID}`),
                  variant: 'primary',
                },
              ]}
            >
              <KeyValuePane
                width={4}
                columns={[
                  [
                    {
                      key: I18n.t('Project id'),
                      value: (
                        <Fragment>
                          {project.internalId} ({project.id})
                        </Fragment>
                      ),
                    },
                    {
                      key: I18n.t('Shared'),
                      value: project.shared && <Icon name="status-positive" variant="success" />,
                    },
                    {
                      key: I18n.t('Status'),
                      value: project.status,
                    },
                    {
                      key: I18n.t('Due'),
                      value: displayTimestamp(project.dateDue, true, session?.user?.timezone),
                    },
                    {
                      key: I18n.t('Note'),
                      value: project.note && (
                        <NoWrapCell>
                          <Tooltip content={project.note}>
                            <Icon name="status-info" variant="link" />
                          </Tooltip>{' '}
                          {project.note}
                        </NoWrapCell>
                      ),
                    },
                    {
                      key: I18n.t('Scoring'),
                      value: isScoringProject(project.workflowSteps) && displayIsScoringEnabled(),
                    },
                    {
                      key: I18n.t('Template'),
                      value: project.projectTemplateId &&
                        canDo(session, 'projectTemplate:editOther') && (
                          <Link to={`/web/projectTemplate/edit/${project.projectTemplateId}`}>
                            {project.projectTemplateName} ({project.projectTemplateId})
                          </Link>
                        ),
                    },
                  ],
                  [
                    {
                      key: I18n.t('Owner'),
                      value:
                        project.rootOwner && displayUserWithLoginLink(project.rootOwner, session),
                    },
                    {
                      key: I18n.t("Vendor's owner"),
                      value:
                        project.vendorOwner &&
                        displayUserWithLoginLink(project.vendorOwner, session),
                    },
                    {
                      key: I18n.t("Buyer's owner"),
                      value:
                        project.buyerOwner && displayUserWithLoginLink(project.buyerOwner, session),
                    },
                    {
                      key: I18n.t('Created by'),
                      value: displayUserWithLoginLink(project.createdBy, session),
                    },
                    {
                      key: I18n.t('Created'),
                      value: displayTimestamp(project.dateCreated, true, session?.user?.timezone),
                    },
                    {
                      key: I18n.t('Automation widget'),
                      value: project.automationWidgetLink && (
                        <a href={project.automationWidgetLink}>{I18n.t('Status page')}</a>
                      ),
                    },
                  ],
                  [
                    {
                      key: I18n.t('Source language'),
                      value: formatLocaleCode(project.sourceLang),
                    },
                    {
                      key: I18n.t('Target language'),
                      value: project.targetLangs.map(
                        (l, idx): ReactNode => (
                          <span key={l}>
                            {idx === 0 ? '' : ', '}
                            {/* Change only the search portion of the location to avoid unintended side-effects (like closing the help pane) */}
                            <Link
                              href="#"
                              to={{
                                ...location,
                                search: stringify({ ...query, targetLocale: l }),
                              }}
                            >
                              {formatLocaleCode(l)}
                            </Link>
                          </span>
                        )
                      ),
                    },
                    {
                      key: I18n.t('Machine translation'),
                      value: displayMachineTranslateSettings(project),
                    },
                    {
                      key: I18n.t('Media type'),
                      value:
                        mediaTypes &&
                        project.mediaType &&
                        mediaTypes[project.mediaType] &&
                        mediaTypes[project.mediaType].name,
                    },
                    {
                      key: I18n.t('Subject'),
                      value:
                        subjects &&
                        project.subject &&
                        subjects[project.subject] &&
                        subjects[project.subject].name,
                    },
                  ],
                  [
                    {
                      key: I18n.t('Client'),
                      value: project.client && project.client.name,
                    },
                    {
                      key: I18n.t('Buyer'),
                      value: project.buyer && project.buyer.name,
                    },
                    {
                      key: I18n.t('Business unit'),
                      value: project.businessUnit && project.businessUnit.name,
                    },
                    {
                      key: I18n.t('Cost center'),
                      value: project.costCenter,
                    },
                  ],
                ]}
              />
            </Container>
          )}

          {/* PROJECT AUTO DELETE WARNING W/ POSTPONE BUTTON */}
          <div style={{ marginBottom: 15 }}>
            {shouldShowAutoDeletionWarning() && !deletionPostponed && (
              <Alert
                id="autoDeleteWarning"
                type="warning"
                buttonText={I18n.t('Postpone')}
                onButtonClick={(): void => {
                  handlePostponeDeletionClick();
                }}
              >
                {getAutoDeleteWarning()}
              </Alert>
            )}
            {shouldShowAutoDeletionWarning() && deletionPostponed && (
              <Alert id="postponeSuccess" type="success" dismissible={true}>
                {I18n.t('The project recycle date was successfully postponed')}
              </Alert>
            )}

            {/* SCORING SUMMARY */}
            {project && isScoringProject(project.workflowSteps) && (
              <ScoringSummary
                project={project}
                workflowStep={workflowStep}
                jobListChangePointer={jobListChangePointer}
              />
            )}
          </div>

          {/* WORKFLOW STEP */}
          <h2>
            {project?.workflowSteps?.length > 0 && (
              <T>
                Viewing workflow step{' '}
                <InlineContainer>
                  <Select
                    id="workflowStepSelecter"
                    label={I18n.t('Workflow step')}
                    options={project.workflowSteps.map(step => {
                      return {
                        id: '' + step.level,
                        label: step.name,
                      };
                    })}
                    selectedId={workflowStep && '' + workflowStep.level}
                    onChange={handleWorkflowLevelChange}
                  />
                </InlineContainer>
              </T>
            )}
            {project &&
              (!project.workflowSteps || project.workflowSteps.length === 0) &&
              I18n.t('Viewing default workflow step')}
          </h2>

          {/* JOB LIST */}
          <JobList
            project={project}
            session={session}
            level={workflowStep?.level}
            targetLocales={project.targetLangs}
            workflowSteps={project.workflowSteps}
            canEdit={canEdit}
            canCreateJobs={deletionPostponed || canCreateJobs()}
            workflowStep={workflowStep}
            scoringEnabled={scoringEnabled}
            onJobListRefreshed={(): void => {
              setJobListChangePointer(jobListChangePointer + 1);
            }}
            canDownload={canDownload}
          />

          {/* ANALYSIS LIST */}
          <AnalysisList
            session={session}
            projectUid={projectUID}
            history={history}
            canEdit={canEdit}
          />

          {/* TM LIST */}

          <TranslationMemoryList
            isProjectMode={true}
            organizationName={project?.createdBy?.organizationName ?? ''}
            uid={projectUID}
            workflowSteps={project.workflowSteps}
            sourceLocale={project.sourceLocale}
            targetLocales={project.targetLangs}
            canEdit={canEdit}
            isInProjectDetailPage={true}
            isInProjectTemplateCreationEditPage={false}
            currentWorkflowStep={workflowStep}
            isProjectShared={project.shared}
          />

          {/* TB LIST */}

          <TermBaseList
            organizationName={project?.createdBy?.organizationName ?? ''}
            uid={projectUID}
            sourceLocale={project.sourceLocale}
            targetLocales={project.targetLangs}
            canEdit={canEdit}
            isInProjectDetailPage={true}
            isInProjectTemplateCreationEditPage={false}
          />

          {/* REFS LIST */}
          <ReferencesList
            isProjectMode={true}
            uid={projectUID}
            canEdit={canEdit}
            isInProjectTemplateCreationEditPage={false}
          />

          <Modal
            id="deleteProjectModal"
            content={I18n.t("Do you want to delete project '%{projectName}?'", {
              projectName: project.name,
            })}
            visible={showDeleteDialog}
            header={I18n.t('Delete project')}
            expandToFit={true}
            footer={
              <span className="awsui-util-f-r">
                <Button
                  variant="link"
                  text={I18n.t('Cancel')}
                  onClick={handleDeleteDialogCancelButtonClick}
                />
                <Button
                  id="deleteConfirmButton"
                  variant="primary"
                  text={I18n.t('Ok')}
                  onClick={handleDeleteDialogOkButtonClick}
                />
              </span>
            }
          />
        </div>
      );
    }
  }
);
