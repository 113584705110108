import * as React from 'react';
import { ReactElement, useReducer, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { PageHeader, PolarisFormik } from '@amzn/et-polaris-utils';
import * as yup from 'yup';
import {
  Button,
  ColumnLayout,
  Flash,
  Form,
  FormField,
  FormSection,
} from '@amzn/awsui-components-react/polaris';
import { css } from 'emotion';
import { AtmsApiClient } from '@amzn/et-console-components';
import styled from '@emotion/styled';
import I18n from '../../setupI18n';
import { stringify } from 'query-string';
import { publishCountMetric } from '../metricHelper';

const formSpacing = css`
  padding-top: 14px;
  padding-bottom: 14px;
`;

const FormContainer = styled('div')`
  max-width: 800px;
  min-width: 280px;
`;

interface ReducerState {
  isImporting: boolean;
  isError: boolean;
  scoringFile?: File;
  setCompleted: boolean;
  error?: string;
}

export const ImportScoreCardWorkflow = withRouter(
  ({ location: { pathname, state }, history }: RouteComponentProps) => {
    // TODO: We should be able to get this from the Router.match.params prop
    const projectUID = pathname.substr(pathname.lastIndexOf('/') + 1);
    const init = (): ReducerState => ({
      isImporting: false,
      isError: false,
      setCompleted: false,
    });

    const reducer = (state, action): ReducerState => {
      switch (action.type) {
        case 'importingScoringFile':
          return {
            ...state,
            isImporting: true,
            isError: false,
          };
        case 'importedScoringFile':
          return {
            ...state,
            isImporting: false,
          };
        case 'importScoringFileFailed':
          return {
            ...state,
            isImporting: false,
            isError: true,
            error: action.error,
          };
        default:
          return state;
      }
    };

    const [reducerState, dispatch] = useReducer(reducer, null, init);
    const [validating, setValidating] = useState(false);
    const { isImporting } = reducerState;

    /**
     * Per AWS Specifications, only start validating after the initial submit.
     * @param e the event
     * @param handleSubmit formik submit event handler function.
     */
    const enableValidation = (e, handleSubmit): void => {
      e.preventDefault();
      setValidating(true);
      handleSubmit();
    };

    const handleSubmit = (values): void => {
      const body = {
        scoringFile: values.scoringFile[0],
      };

      dispatch({
        type: 'importingScoringFile',
        ...body,
      });

      const query = {
        jobPart: state.jobPartId,
      };

      // API not implemented yet. Upload will fail for now.
      AtmsApiClient.multipartUpload(`/api/scoring/import?${stringify(query)}`, body)
        .then(() => {
          dispatch({ type: 'importedScoringFile' });
          history.push(`/web/project2/show/${projectUID}`);
        })
        .catch(e => {
          publishCountMetric('importScoringFileFailed-ImportScoreCardWorkflow', 'error', e.message);
          dispatch({ type: 'importScoringFileFailed', error: e });
        });
    };

    const renderForm = ({ errors, handleSubmit, handleChange }): ReactElement => {
      return (
        <FormContainer>
          <form onSubmit={e => enableValidation(e, handleSubmit)}>
            <Form
              header={
                <PageHeader
                  title={I18n.t('Import scorecard')}
                  // extraContent={<HelpInfoLink helpId={} />} //TODO: create info
                />
              }
              actions={
                <div>
                  <Button
                    id="import"
                    text={I18n.t('Import file')}
                    variant="primary"
                    onClick={e => enableValidation(e, handleSubmit)}
                    disabled={isImporting}
                    loading={isImporting}
                  />
                </div>
              }
              errorText={
                Object.keys(errors).length !== 0
                  ? 'The form contains errors. Fix them and resubmit.'
                  : ''
              }
            >
              <FormSection>
                <ColumnLayout>
                  <div data-awsui-column-layout-root="true">
                    <FormField
                      label={I18n.t('Scoring file')}
                      errorText={errors.scoringFile ? I18n.t('Scoring file is required') : ''}
                    >
                      {/* TODO: Replace with AWS-UI component when available: https://polaris.corp.amazon.com/system/structures/fileupload/ */}
                      <input
                        type="file"
                        name="scoringFile"
                        accept=".csv,.xlsx"
                        onChange={handleChange}
                      />
                    </FormField>
                  </div>
                </ColumnLayout>
              </FormSection>
              {reducerState.isError && (
                <div className={formSpacing}>
                  <Flash
                    type="error"
                    dismissible={true}
                    content={I18n.t('Please try again. If the issue persists, contact support.')}
                    header={I18n.t('Something went wrong.')}
                  />
                </div>
              )}
            </Form>
          </form>
        </FormContainer>
      );
    };

    const initialFormValues = {
      scoringFile: reducerState.scoringFile,
    };

    const schema = yup.object().shape({
      scoringFile: yup.mixed().required(),
    });

    return (
      <PolarisFormik
        initialValues={initialFormValues}
        validationSchema={schema}
        validateOnChange={validating}
        onSubmit={handleSubmit}
        render={renderForm}
      />
    );
  }
);
