import { useState, useLayoutEffect } from 'react';

export default (ref, dependencies) => {
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });

  useLayoutEffect(() => {
    setSize({
      width: ref.current.offsetWidth,
      height: ref.current.offsetHeight,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return size;
};
