import React from 'react';
import { Link } from 'react-router-dom';

export default () => (
  <div
    css={{
      width: 660,
      padding: 18,
      margin: '0 auto',
      textAlign: 'center',
      '.awsui & h1': {
        lineHeight: '1em',
        fontSize: '20px',
        padding: '10px 0',
        margin: '0 0 20px',
      },
      '.awsui & p': {
        lineHeight: '1em',
        margin: '0 0 20px',
        padding: 0,
      },
    }}
  >
    <div
      css={{
        width: 154,
        height: 80,
        margin: '20px auto',
        background: 'url(/logo-white.svg) no-repeat',
        backgroundSize: 'contain',
      }}
    />
    <h1 translate="yes">Page not found.</h1>
    <p translate="yes">
      Proceed to the <Link to="/web">home page</Link>.
    </p>
  </div>
);
