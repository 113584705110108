import { useEffect, useState } from 'react';
import { paginateAll } from '../../shared/paginationHelper';
import { getAppHostConfig } from '@amzn/et-console-components';

const { WEB_HOST_AND_PORT } = getAppHostConfig();

interface SegmentationRule {
  id: number;
  name: string;
  locale: string;
}

interface State {
  isLoadingSegmentationRules: boolean;
  segmentationRules: SegmentationRule[];
  segmentationRulesById: { [id: number]: SegmentationRule };
}

// TODO: Needs to reload when the user profile changes
export const useSegmentationRules = (): State => {
  const [state, setState] = useState<State>({
    isLoadingSegmentationRules: true,
    segmentationRules: [],
    segmentationRulesById: {},
  });
  useEffect(() => {
    const paginate = async (): Promise<void> => {
      const result = await paginateAll(`//${WEB_HOST_AND_PORT}/web/api/v9/segRule/list`);

      setState({
        isLoadingSegmentationRules: false,
        segmentationRules: result,
        segmentationRulesById: result.reduce((acc, val) => {
          acc[val.id] = val;
          return acc;
        }, {}),
      });
    };

    paginate();
  }, []);

  return state;
};
