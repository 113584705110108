import React, { ReactElement, ReactNode, useReducer } from 'react';
import * as yup from 'yup';
import {
  Checkbox,
  ColumnLayout,
  Flash,
  Form,
  FormField,
  FormSection,
  Icon,
  Input,
} from '@amzn/awsui-components-react/polaris';
import I18n from '../../setupI18n';
import styled from '@emotion/styled';
import { css } from 'emotion';
import { HelpInfoLink } from '../HelpContentRouter';
import { PROJECT_CREATE_QUALITY_HELP } from './projectHelpContent';
import { FormikProps } from 'formik';
import { T } from '../components/T';
import { Project, ProjectTemplate } from '../types/commonTypes';
import { coalesceSummaryValue, WizardStepWithSummary } from '../components/WizardWithSummary';
import { Container } from '@amzn/et-polaris-utils';

const formSpacing = css`
  padding-top: 14px;
  padding-bottom: 14px;
`;

const FormContainer = styled('div')`
  max-width: 800px;
  min-width: 280px;
`;

const IndentedFormSection = styled('div')`
  margin-left: 20px;
`;

const PercentageInputContainer = styled('div')`
  display: inline-block;
  max-width: 70px;
  margin-right: 3px;
  margin-bottom: 10px;
`;

export interface Props<T extends ProjectCreateAPIStep5> extends FormikProps<T> {
  validating: boolean;
}

export interface ProjectCreateAPIStep5 {
  preventLinguistsFromCompletingJobsWithQAWarnings: boolean;
  qaExcludeLockedSegments: boolean;
  autoIgnorePreviouslyIgnoredQAWarnings: boolean;
  emptyTargets: boolean;
  identicalToSource: boolean;
  trailingPunctuation: boolean;
  inconsistentTranslations: boolean;
  missingNumbers: boolean;
  leadingAndTrailingSpace: boolean;
  multipleSpaces: boolean;
  newerVersionsInPreceedingWorkflowStep: boolean;
  repeatedWords: boolean;
  tagsAndFormatting: boolean;
  inconsistentTagContent: boolean;
  emptyTagContent: boolean;
  segmentJoinTags: boolean;
  noTextBetweenTags: boolean;
  xliffTags: boolean;
  terminology: boolean;
  allowUnapprovedTerms: boolean;
  forbiddenTermsWithoutSource: boolean;
  customForbiddenTerms: string;
  fuzzyTMMatchesWithNoPostEditing: boolean;
  spelling: boolean;
  includeCapitalizedWordsWhenIdenticalToSource: boolean;
  includeWordsInAllCaps: boolean;
  unconfirmedSegment: boolean;
  unresolvedComments: boolean;
  targetSegmentsLongerThanXCharacters: number;
  targetSegmentsLongerThanXPercentOfSource: number;
  overrideTargetLengthOptionsSetDuringJobCreation: boolean;
  overrideTargetSegmentsLongerThanXCharacters: boolean;
  overrideTargetSegmentsLongerThanXPercentOfSource: boolean;
}

interface ReducerState {
  isError: boolean;
  error?: string;
}

export const schemaStep5 = yup.object().shape({
  preventLinguistsFromCompletingJobsWithQAWarnings: yup.boolean(),
  qaExcludeLockedSegments: yup.boolean(),
  autoIgnorePreviouslyIgnoredQAWarnings: yup.boolean(),
  emptyTargets: yup.boolean(),
  identicalToSource: yup.boolean(),
  trailingPunctuation: yup.boolean(),
  inconsistentTranslations: yup.boolean(),
  missingNumbers: yup.boolean(),
  leadingAndTrailingSpace: yup.boolean(),
  multipleSpaces: yup.boolean(),
  newerVersionsInPreceedingWorkflowStep: yup.boolean(),
  repeatedWords: yup.boolean(),
  tagsAndFormatting: yup.boolean(),
  inconsistentTagContent: yup.boolean(),
  emptyTagContent: yup.boolean(),
  segmentJoinTags: yup.boolean(),
  noTextBetweenTags: yup.boolean(),
  xliffTags: yup.boolean(),
  terminology: yup.boolean(),
  allowUnapprovedTerms: yup.boolean(),
  forbiddenTermsWithoutSource: yup.boolean(),
  customForbiddenTerms: yup.string(),
  fuzzyTMMatchesWithNoPostEditing: yup.boolean(),
  spelling: yup.boolean(),
  includeCapitalizedWordsWhenIdenticalToSource: yup.boolean(),
  includeWordsInAllCaps: yup.boolean(),
  unconfirmedSegment: yup.boolean(),
  unresolvedComments: yup.boolean(),
  targetSegmentsLongerThanXCharacters: yup
    .number()
    .when('overrideTargetSegmentsLongerThanXCharacters', {
      is: true,
      then: yup.number().min(1, 'Must be greater than or equal to 1'), //TODO: This isn't really a sane value.
    }),
  targetSegmentsLongerThanXPercentOfSource: yup
    .number()
    .when('overrideTargetSegmentsLongerThanXPercentOfSource', {
      is: true,
      then: yup.number().min(1, 'Must be greater than or equal to 1%'), //TODO: This isn't really a sane value.
    }),
  overrideTargetLengthOptionsSetDuringJobCreation: yup.boolean(),
  overrideTargetSegmentsLongerThanXCharacters: yup.boolean(),
  overrideTargetSegmentsLongerThanXPercentOfSource: yup.boolean(),
});

export const initialFormValuesStep5 = (
  currentValue?: Project | ProjectTemplate
): ProjectCreateAPIStep5 => {
  return {
    preventLinguistsFromCompletingJobsWithQAWarnings:
      currentValue?.preventLinguistsFromCompletingJobsWithQAWarnings ?? true,
    qaExcludeLockedSegments: currentValue?.qaExcludeLockedSegments ?? true,
    autoIgnorePreviouslyIgnoredQAWarnings:
      currentValue?.autoIgnorePreviouslyIgnoredQAWarnings ?? true,
    emptyTargets: currentValue?.emptyTargets ?? true,
    identicalToSource: currentValue?.identicalToSource ?? true,
    trailingPunctuation: currentValue?.trailingPunctuation ?? true,
    inconsistentTranslations: currentValue?.inconsistentTranslations ?? true,
    missingNumbers: currentValue?.missingNumbers ?? true,
    leadingAndTrailingSpace: currentValue?.leadingAndTrailingSpace ?? true,
    multipleSpaces: currentValue?.multipleSpaces ?? true,
    newerVersionsInPreceedingWorkflowStep:
      currentValue?.newerVersionsInPreceedingWorkflowStep ?? true,
    repeatedWords: currentValue?.repeatedWords ?? true,
    tagsAndFormatting: currentValue?.tagsAndFormatting ?? true,
    inconsistentTagContent: currentValue?.inconsistentTagContent ?? true,
    emptyTagContent: currentValue?.emptyTagContent ?? true,
    segmentJoinTags: currentValue?.segmentJoinTags ?? true,
    noTextBetweenTags: currentValue?.noTextBetweenTags ?? true,
    xliffTags: currentValue?.xliffTags ?? true,
    terminology: currentValue?.terminology ?? true,
    allowUnapprovedTerms: currentValue?.allowUnapprovedTerms ?? true,
    forbiddenTermsWithoutSource: currentValue?.forbiddenTermsWithoutSource ?? true,
    fuzzyTMMatchesWithNoPostEditing: currentValue?.fuzzyTMMatchesWithNoPostEditing ?? true,
    spelling: currentValue?.spelling ?? true,
    includeCapitalizedWordsWhenIdenticalToSource:
      currentValue?.includeCapitalizedWordsWhenIdenticalToSource ?? true,
    includeWordsInAllCaps: currentValue?.includeWordsInAllCaps ?? true,
    unconfirmedSegment: currentValue?.unconfirmedSegment ?? true,
    unresolvedComments: currentValue?.unresolvedComments ?? true,
    overrideTargetLengthOptionsSetDuringJobCreation:
      currentValue?.overrideTargetLengthOptionsSetDuringJobCreation ?? false,
    overrideTargetSegmentsLongerThanXCharacters:
      currentValue?.overrideTargetSegmentsLongerThanXCharacters ?? false,
    overrideTargetSegmentsLongerThanXPercentOfSource:
      currentValue?.overrideTargetSegmentsLongerThanXPercentOfSource ?? false,
    targetSegmentsLongerThanXCharacters: currentValue?.targetSegmentsLongerThanXCharacters ?? 10000,
    targetSegmentsLongerThanXPercentOfSource:
      currentValue?.targetSegmentsLongerThanXPercentOfSource ?? 130,
    customForbiddenTerms: currentValue?.customForbiddenTerms ?? '',
  };
};

const Content = <T extends ProjectCreateAPIStep5>({
  errors,
  validating,
  values,
  handleChange,
  handleBlur,
  setFieldValue,
}: Props<T>): ReactElement => {
  const init = (): ReducerState => ({
    isError: false,
  });

  const reducer = (state, action): ReducerState => {
    switch (action.type) {
      default:
        return state;
    }
  };

  const [reducerState] = useReducer(reducer, null, init);

  const handleChangeCustomForbiddenTerms = (event: CustomEvent<Input.ChangeDetail>): void => {
    setFieldValue(
      'customForbiddenTerms',
      event.detail.value
        .split(',')
        .map(ft => ft.trim())
        .filter(ft => ft !== '')
        .join(',')
    );
  };

  return (
    <FormContainer>
      <Form
        errorText={
          validating && Object.keys(errors).length !== 0
            ? I18n.t('The form contains errors. Fix them and resubmit.')
            : ''
        }
      >
        <FormSection header={I18n.t('Default QA checks')}>
          <FormField
            label={
              <T>
                <h4>Untranslated content</h4>
              </T>
            }
          >
            <IndentedFormSection>
              <ColumnLayout columns={2}>
                <div data-awsui-column-layout-root="true">
                  <Checkbox
                    id="emptyTargets"
                    controlId="emptyTargets"
                    label={I18n.t('Empty targets')}
                    description={I18n.t('The target contains no characters or tags.')}
                    checked={values.emptyTargets}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Checkbox
                    id="identicalToSource"
                    controlId="identicalToSource"
                    label={I18n.t('Identical to source')}
                    description={I18n.t(
                      'Text may have been copied directly from the source. Ignores segments with only numbers/tags/non-translatables.'
                    )}
                    checked={values.identicalToSource}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </ColumnLayout>
            </IndentedFormSection>
          </FormField>
          <FormField
            label={
              <T>
                <h4>Incongruities</h4>
              </T>
            }
          >
            <IndentedFormSection>
              <ColumnLayout columns={2}>
                <div data-awsui-column-layout-root="true">
                  <Checkbox
                    id="trailingPunctuation"
                    controlId="trailingPunctuation"
                    label={I18n.t('Trailing punctuation')}
                    description={I18n.t(
                      'Trailing punctuation is different in the source and target. E.g., the source ends with "." but the target does not or vice versa.'
                    )}
                    checked={values.trailingPunctuation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Checkbox
                    id="inconsistentTranslations"
                    controlId="inconsistentTranslations"
                    label={I18n.t('Inconsistent translations')}
                    description={I18n.t(
                      'The source matches a preceding segment with different targets or vice versa.'
                    )}
                    checked={values.inconsistentTranslations}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Checkbox
                    id="missingNumbers"
                    controlId="missingNumbers"
                    label={I18n.t('Missing numbers')}
                    description={I18n.t(
                      'A number in the source is not present in the target. E.g., source="1 and 2" target="1 and 3".'
                    )}
                    checked={values.missingNumbers}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Checkbox
                    id="leadingAndTrailingSpace"
                    controlId="leadingAndTrailingSpace"
                    label={I18n.t('Leading and trailing spaces')}
                    description={I18n.t(
                      'There is a leading or trailing space in the target but not in the source.'
                    )}
                    checked={values.leadingAndTrailingSpace}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Checkbox
                    id="multipleSpaces"
                    controlId="multipleSpaces"
                    label={I18n.t('Multiple spaces')}
                    description={I18n.t(
                      'There are multiple consecutive spaces in the target but not in the source.'
                    )}
                    checked={values.multipleSpaces}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </ColumnLayout>
            </IndentedFormSection>
          </FormField>
          <FormField>
            <Checkbox
              id="newerVersionsInPreceedingWorkflowStep"
              controlId="newerVersionsInPreceedingWorkflowStep"
              label={I18n.t('Newer versions in preceeding workflow steps')}
              description={I18n.t(
                'Checks whether newer versions of segments exist in preceding workflow steps. E.g., a translator has modified a segment in the translation workflow step after it was revised in the revision workflow step.'
              )}
              checked={values.newerVersionsInPreceedingWorkflowStep}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormField>
          <FormField>
            <Checkbox
              id="repeatedWords"
              controlId="repeatedWords"
              label={I18n.t('Repeated words')}
              description={I18n.t('Two or more adjacent words in the target are identical.')}
              checked={values.repeatedWords}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormField>
          <FormField
            label={
              <T>
                <h4>Terminology</h4>
              </T>
            }
          >
            <Checkbox
              id="terminology"
              controlId="terminology"
              label={I18n.t('Check terminology')}
              description={I18n.t(
                'The terms in the source do not have a corresponding term translation in the target. A forbidden term is used for the translation (Forbidden terms in the source are ignored). Only terms found in the source will be checked.'
              )}
              checked={values.terminology}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <IndentedFormSection>
              <Checkbox
                id="allowUnapprovedTerms"
                controlId="allowUnapprovedTerms"
                label={I18n.t('Allow unapproved terms')}
                description={I18n.t('Allow terms in `New` state as well as `Approved`.')}
                checked={values.allowUnapprovedTerms}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!values.terminology}
              />
            </IndentedFormSection>
            <Checkbox
              id="forbiddenTermsWithoutSource"
              controlId="forbiddenTermsWithoutSource"
              label={I18n.t('Forbidden terms without source')}
              description={I18n.t(
                "Check for forbidden terms in target that don't match a corresponding forbidden term in the source"
              )}
              checked={values.forbiddenTermsWithoutSource}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FormField label={I18n.t('Custom forbidden terms')}>
              <Input
                disabled={false}
                id="customForbiddenTerms"
                controlId="customForbiddenTerms"
                value={values.customForbiddenTerms}
                onChange={handleChangeCustomForbiddenTerms}
                onBlur={handleBlur}
              />
            </FormField>
          </FormField>
          <FormField
            label={
              <T>
                <h4>Tags</h4>
              </T>
            }
          >
            <IndentedFormSection>
              <ColumnLayout columns={2}>
                <div data-awsui-column-layout-root="true">
                  <Checkbox
                    id="tagsAndFormatting"
                    controlId="tagsAndFormatting"
                    label={I18n.t('Tags and formatting')}
                    description={I18n.t(
                      'Ensure the correct type/quantity of tags are in the correct locations in the target text.'
                    )}
                    checked={values.tagsAndFormatting}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Checkbox
                    id="inconsistentTagContent"
                    controlId="inconsistentTagContent"
                    label={I18n.t('Inconsistent tag content')}
                    description={I18n.t(
                      'The content of a tag differs between source and target. E.g., source="<a href=‘http://bbc.co.uk’>" target="<a href=‘http://www.amazon.com’>"'
                    )}
                    checked={values.inconsistentTagContent}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Checkbox
                    id="emptyTagContent"
                    controlId="emptyTagContent"
                    label={I18n.t('Empty tag content')}
                    description={I18n.t(
                      'The target tag content is empty, while the source tag content is not. E.g., source="<a href=‘http://bbc.co.uk’>",  target="<a href=’‘>".'
                    )}
                    checked={values.emptyTagContent}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Checkbox
                    id="segmentJoinTags"
                    controlId="segmentJoinTags"
                    label={I18n.t('Tags - joined segments')}
                    description={I18n.t(
                      'The quantity of {j} tags is different in the source and target. E.g., source="xxx{j}yyy" target="xxx yyy"'
                    )}
                    checked={values.segmentJoinTags}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Checkbox
                    id="noTextBetweenTags"
                    controlId="noTextBetweenTags"
                    label={I18n.t('No text between tags')}
                    description={I18n.t(
                      'Paired tags are missing text between them that appears in the source. E.g., source="{1>abc<1}", target="{1><1}".'
                    )}
                    checked={values.noTextBetweenTags}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Checkbox
                    id="xliffTags"
                    controlId="xliffTags"
                    label={I18n.t('XLIFF tags')}
                    description={I18n.t(
                      'Paired tags (such as bpt and ept) are in the incorrect order in the completed target file. E.g., source="bpt bpt ept ept" target="bpt ept ept bpt".'
                    )}
                    checked={values.xliffTags}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </ColumnLayout>
            </IndentedFormSection>
          </FormField>
          <FormField
            label={
              <T>
                <h4>Spelling</h4>
              </T>
            }
          >
            <Checkbox
              id="spelling"
              controlId="spelling"
              label={I18n.t('Check spelling')}
              description={I18n.t(
                'Target contains misspelled words. False positives may happen if the target word is not currently in the dictionary. User may add a word to the dictionary by hovering over the red dotted line and click "Add to Dictionary" (Note: This word will only be added to the user\'s dictionary).'
              )}
              checked={values.spelling}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <IndentedFormSection>
              <ColumnLayout columns={2}>
                <div data-awsui-column-layout-root="true">
                  <Checkbox
                    id="includeCapitalizedWordsWhenIdenticalToSource"
                    controlId="includeCapitalizedWordsWhenIdenticalToSource"
                    label={I18n.t('Include Capitalized words when identical to source')}
                    checked={values.includeCapitalizedWordsWhenIdenticalToSource}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!values.spelling}
                  />
                  <Checkbox
                    id="includeWordsInAllCaps"
                    controlId="includeWordsInAllCaps"
                    label={I18n.t('Include words in ALL CAPS')}
                    checked={values.includeWordsInAllCaps}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!values.spelling}
                  />
                </div>
              </ColumnLayout>
            </IndentedFormSection>
          </FormField>
          <ColumnLayout columns={2}>
            <div data-awsui-column-layout-root="true">
              <FormField>
                <Checkbox
                  id="unconfirmedSegment"
                  controlId="unconfirmedSegment"
                  label={I18n.t('Unconfirmed segment')}
                  description={I18n.t('Segment is not confirmed.')}
                  checked={values.unconfirmedSegment}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  id="unresolvedComments"
                  controlId="unresolvedComments"
                  label={I18n.t('Unresolved comment')}
                  description={I18n.t('Segment contains an unresolved comment.')}
                  checked={values.unresolvedComments}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  id="fuzzyTMMatchesWithNoPostEditing"
                  controlId="fuzzyTMMatchesWithNoPostEditing"
                  label={I18n.t('Fuzzy TM matches with no post-editing')}
                  description={I18n.t(
                    'Segment content was pulled from a fuzzy TM match and the content was not corrected by the linguist.'
                  )}
                  checked={values.fuzzyTMMatchesWithNoPostEditing}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
            </div>
          </ColumnLayout>
          <FormField
            label={
              <T>
                <h4>Segment length</h4>
              </T>
            }
          >
            <Checkbox
              id="overrideTargetLengthOptionsSetDuringJobCreation"
              controlId="overrideTargetLengthOptionsSetDuringJobCreation"
              label={I18n.t('Override segment length options set during job creation')}
              description={I18n.t(
                'Imported from original file or set in Segmentation and Segment Length import option'
              )}
              checked={values.overrideTargetLengthOptionsSetDuringJobCreation}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <IndentedFormSection>
              <ColumnLayout columns={2}>
                <div data-awsui-column-layout-root="true">
                  <Checkbox
                    id="overrideTargetSegmentsLongerThanXCharacters"
                    controlId="overrideTargetSegmentsLongerThanXCharacters"
                    label={I18n.t('Target segments longer than [xxx] characters')}
                    description={I18n.t(
                      'The target segment length is greater than the given parameter (default 10000)'
                    )}
                    checked={
                      values.overrideTargetSegmentsLongerThanXCharacters &&
                      values.overrideTargetLengthOptionsSetDuringJobCreation
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!values.overrideTargetLengthOptionsSetDuringJobCreation}
                  />
                  <FormField>
                    <Input
                      id="targetSegmentsLongerThanXCharacters"
                      controlId="targetSegmentsLongerThanXCharacters"
                      value={'' + values.targetSegmentsLongerThanXCharacters}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={
                        !values.overrideTargetSegmentsLongerThanXCharacters ||
                        !values.overrideTargetLengthOptionsSetDuringJobCreation
                      }
                    />
                  </FormField>
                  <Checkbox
                    id="overrideTargetSegmentsLongerThanXPercentOfSource"
                    controlId="overrideTargetSegmentsLongerThanXPercentOfSource"
                    label={I18n.t('Target segments longer than x percent of source')}
                    description={I18n.t(
                      'The target segment length is greater than the given parameter (default 130%)'
                    )}
                    checked={
                      values.overrideTargetSegmentsLongerThanXPercentOfSource &&
                      values.overrideTargetLengthOptionsSetDuringJobCreation
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!values.overrideTargetLengthOptionsSetDuringJobCreation}
                  />
                  <FormField>
                    <PercentageInputContainer>
                      <Input
                        id="targetSegmentsLongerThanXPercentOfSource"
                        controlId="targetSegmentsLongerThanXPercentOfSource"
                        value={'' + values.targetSegmentsLongerThanXPercentOfSource}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={
                          !values.overrideTargetSegmentsLongerThanXPercentOfSource ||
                          !values.overrideTargetLengthOptionsSetDuringJobCreation
                        }
                      />
                    </PercentageInputContainer>{' '}
                    %
                  </FormField>
                </div>
              </ColumnLayout>
            </IndentedFormSection>
          </FormField>
        </FormSection>
        <FormSection header={I18n.t('Options')}>
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField>
                {/* TODO: This might need to be moved to the access and automation step. */}
                <Checkbox
                  id="preventLinguistsFromCompletingJobsWithQAWarnings"
                  controlId="preventLinguistsFromCompletingJobsWithQAWarnings"
                  label={I18n.t('Prevent linguists from completing jobs with QA warnings')}
                  description={I18n.t(
                    'Since linguist users can only mark a job as Completed by linguist (only PMs on the vendor side can mark a job as Delivered) this setting blocks linguists from completing a job if there are unresolved QA errors (i.e. errors that are not either fixed or ignored).'
                  )}
                  checked={values.preventLinguistsFromCompletingJobsWithQAWarnings}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  id="qaExcludeLockedSegments"
                  controlId="qaExcludeLockedSegments"
                  label={I18n.t('Exclude locked segments from QA')}
                  checked={values.qaExcludeLockedSegments}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  id="autoIgnorePreviouslyIgnoredQAWarnings"
                  controlId="autoIgnorePreviouslyIgnoredQAWarnings"
                  label={I18n.t('Auto-ignore previously ignored QA warnings')}
                  description={I18n.t('Propagates ignored warnings to other workflow steps')}
                  checked={values.autoIgnorePreviouslyIgnoredQAWarnings}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </FormSection>
        {reducerState.isError && (
          <div className={formSpacing}>
            <Flash
              id="validation-error"
              type="error"
              dismissible={true}
              content={I18n.t('Please try again. If the issue persists, contact support.')}
              header={I18n.t('Something went wrong.')}
            />
          </div>
        )}
      </Form>
    </FormContainer>
  );
};

export const ProjectCreateStep5Quality = <T extends ProjectCreateAPIStep5>(
  props: Props<T>
): WizardStepWithSummary => {
  const title = I18n.t('Quality');
  return {
    title: title,
    info: (): ReactElement => <HelpInfoLink helpId={PROJECT_CREATE_QUALITY_HELP} />,
    description: I18n.t('Configure quality assurance settings for the project'),
    content: (): ReactElement => <Content {...props} />,
    isOptional: true,
    summary: ({ currentValue }): ReactNode => {
      const { values } = props;
      const summaryElements = [
        coalesceSummaryValue({
          currentValue: currentValue?.emptyTargets,
          value: values.emptyTargets,
          label: I18n.t('Empty targets'),
          displayValue: values.emptyTargets ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.identicalToSource,
          value: values.identicalToSource,
          label: I18n.t('Identical to source'),
          displayValue: values.identicalToSource ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.trailingPunctuation,
          value: values.trailingPunctuation,
          label: I18n.t('Trailing punctuation'),
          displayValue: values.trailingPunctuation ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.inconsistentTranslations,
          value: values.inconsistentTranslations,
          label: I18n.t('Inconsistent translations'),
          displayValue: values.inconsistentTranslations ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.missingNumbers,
          value: values.missingNumbers,
          label: I18n.t('Missing numbers'),
          displayValue: values.missingNumbers ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.leadingAndTrailingSpace,
          value: values.leadingAndTrailingSpace,
          label: I18n.t('Leading and trailing spaces'),
          displayValue: values.leadingAndTrailingSpace ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.multipleSpaces,
          value: values.multipleSpaces,
          label: I18n.t('Multiple spaces'),
          displayValue: values.multipleSpaces ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.newerVersionsInPreceedingWorkflowStep,
          value: values.newerVersionsInPreceedingWorkflowStep,
          label: I18n.t('Newer versions in preceeding workflow steps'),
          displayValue: values.newerVersionsInPreceedingWorkflowStep ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.repeatedWords,
          value: values.repeatedWords,
          label: I18n.t('Repeated words'),
          displayValue: values.repeatedWords ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.terminology,
          value: values.terminology,
          label: I18n.t('Check terminology'),
          displayValue: values.terminology ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.allowUnapprovedTerms,
          value: values.allowUnapprovedTerms,
          label: I18n.t('Allow unapproved terms'),
          displayValue: values.allowUnapprovedTerms ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.forbiddenTermsWithoutSource,
          value: values.forbiddenTermsWithoutSource,
          label: I18n.t('Forbidden terms without source'),
          displayValue: values.forbiddenTermsWithoutSource ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.customForbiddenTerms,
          value: values.customForbiddenTerms,
          label: I18n.t('Custom forbidden terms'),
          displayValue: values.customForbiddenTerms,
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.tagsAndFormatting,
          value: values.tagsAndFormatting,
          label: I18n.t('Tags and formatting'),
          displayValue: values.tagsAndFormatting ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.inconsistentTagContent,
          value: values.inconsistentTagContent,
          label: I18n.t('Inconsistent tag content'),
          displayValue: values.inconsistentTagContent ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.emptyTagContent,
          value: values.emptyTagContent,
          label: I18n.t('Empty tag content'),
          displayValue: values.emptyTagContent ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.segmentJoinTags,
          value: values.segmentJoinTags,
          label: I18n.t('Tags - joined segments'),
          displayValue: values.segmentJoinTags ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.noTextBetweenTags,
          value: values.noTextBetweenTags,
          label: I18n.t('No text between tags'),
          displayValue: values.noTextBetweenTags ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.xliffTags,
          value: values.xliffTags,
          label: I18n.t('XLIFF tags'),
          displayValue: values.xliffTags ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.spelling,
          value: values.spelling,
          label: I18n.t('Check spelling'),
          displayValue: values.spelling ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.includeCapitalizedWordsWhenIdenticalToSource,
          value: values.includeCapitalizedWordsWhenIdenticalToSource,
          label: I18n.t('Include Capitalized words when identical to source'),
          displayValue: values.includeCapitalizedWordsWhenIdenticalToSource ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.includeWordsInAllCaps,
          value: values.includeWordsInAllCaps,
          label: I18n.t('Include words in ALL CAPS'),
          displayValue: values.includeWordsInAllCaps ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.unconfirmedSegment,
          value: values.unconfirmedSegment,
          label: I18n.t('Unconfirmed segment'),
          displayValue: values.unconfirmedSegment ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.unresolvedComments,
          value: values.unresolvedComments,
          label: I18n.t('Unresolved comment'),
          displayValue: values.unresolvedComments ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.fuzzyTMMatchesWithNoPostEditing,
          value: values.fuzzyTMMatchesWithNoPostEditing,
          label: I18n.t('Fuzzy TM matches with no post-editing'),
          displayValue: values.fuzzyTMMatchesWithNoPostEditing ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.overrideTargetLengthOptionsSetDuringJobCreation,
          value: values.overrideTargetLengthOptionsSetDuringJobCreation,
          label: I18n.t('Override segment length options set during job creation'),
          displayValue: values.overrideTargetLengthOptionsSetDuringJobCreation ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.overrideTargetSegmentsLongerThanXCharacters,
          value: values.overrideTargetSegmentsLongerThanXCharacters,
          label: I18n.t('Override target segments longer than [xxx] characters'),
          displayValue: values.overrideTargetSegmentsLongerThanXCharacters ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.targetSegmentsLongerThanXCharacters,
          value: values.targetSegmentsLongerThanXCharacters,
          label: I18n.t('Target segments longer than [xxx] characters'),
          displayValue: values.targetSegmentsLongerThanXCharacters,
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.overrideTargetSegmentsLongerThanXPercentOfSource,
          value: values.overrideTargetSegmentsLongerThanXPercentOfSource,
          label: I18n.t('Override target segments longer than x percent of source'),
          displayValue: values.overrideTargetSegmentsLongerThanXPercentOfSource ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.targetSegmentsLongerThanXPercentOfSource,
          value: values.targetSegmentsLongerThanXPercentOfSource,
          label: I18n.t('Target segments longer than [xxx]% of source'),
          displayValue: `${values.targetSegmentsLongerThanXPercentOfSource}%`,
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.preventLinguistsFromCompletingJobsWithQAWarnings,
          value: values.preventLinguistsFromCompletingJobsWithQAWarnings,
          label: I18n.t('Prevent linguists from completing jobs with QA warnings'),
          displayValue: values.preventLinguistsFromCompletingJobsWithQAWarnings ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.qaExcludeLockedSegments,
          value: values.qaExcludeLockedSegments,
          label: I18n.t('Exclude locked segments'),
          displayValue: values.qaExcludeLockedSegments ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.autoIgnorePreviouslyIgnoredQAWarnings,
          value: values.autoIgnorePreviouslyIgnoredQAWarnings,
          label: I18n.t('Auto-ignore previously ignored QA warnings'),
          displayValue: values.autoIgnorePreviouslyIgnoredQAWarnings ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
      ].filter(s => s);

      return (
        <Container title={title} key={title}>
          <ColumnLayout columns={4}>
            <div data-awsui-column-layout-root="true">
              {summaryElements.length > 0 ? (
                summaryElements
              ) : (
                <div>
                  <div>
                    <T>No changes</T>
                  </div>
                </div>
              )}
            </div>
          </ColumnLayout>
        </Container>
      );
    },
  };
};
