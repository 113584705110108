/**
 * This file is the copy of TranslationMemoryList.tsx. @liuyidin is responsible for developing the new TM UI
 */
import * as React from 'react';

import { ReactElement, useCallback, useLayoutEffect, useReducer } from 'react';
import { Alert, Icon, Table } from '@amzn/awsui-components-react/polaris';
import { Container, PageHeader } from '@amzn/et-polaris-utils';
import { stringify } from 'query-string';
import { CellContents } from '../../types/commonTypes';
import { PROJECT_DETAIL_MANAGE_TBS_HELP } from '../projectHelpContent';
import { HelpInfoLink } from '../../HelpContentRouter';
import I18n from '../../../setupI18n';
import { Link } from 'react-router-dom';
import { TableWithGroupByColumn } from '../../components/TableWithGroupByColumn';
import { TermBaseSelect } from './TermBaseSelect';
import {
  getPriorityLabel,
  isPublic,
  tbGroupByFunction,
  TermBaseAssignment,
  saveTBConfigs,
} from './TermBaseCommons';
import { AtmsApiClient } from '@amzn/et-console-components';
import { TermBaseConfiguration } from './TermBaseConfiguration';
import { publishCountMetric } from '../../metricHelper';
import { getAppHostConfig } from '@amzn/et-console-components';

const { WEB_HOST_AND_PORT } = getAppHostConfig();

const getColumnDefinitions = (): Table.ColumnDefinition<TermBaseAssignment>[] => {
  return [
    {
      id: 'name',
      header: I18n.t('Name'),
      cell: (item): CellContents => (
        <Link id={'tbNameLink'} to={`/web/termBase/show/${item.tbId}`}>
          {item.tbName}
        </Link>
      ),
      width: 400,
    },
    {
      id: 'read',
      header: I18n.t('Read'),
      cell: (item): CellContents =>
        item.readMode && <Icon id={'tbReadModeIcon'} name="status-positive" />,
    },
    {
      id: 'write',
      header: I18n.t('Write'),
      cell: (item): CellContents =>
        item.writeMode && <Icon id={'tbWriteModeIcon'} name="status-positive" />,
    },
    {
      id: 'qa',
      header: I18n.t('QA'),
      cell: (item): CellContents =>
        item.qualityAssurance && <Icon id={'tbQAIcon'} name="status-positive" />,
    },
    {
      id: 'priority',
      header: I18n.t('Priority'),
      cell: (item): CellContents => (
        <div id={'tbPriority'}>{getPriorityLabel(item.priority === null ? 0 : item.priority)}</div>
      ),
    },
  ];
};

export interface Props {
  uid?: string;
  sourceLocale?: string;
  targetLocales?: string[];
  canEdit: boolean;
  organizationName: string; // creator's organization name
  isInProjectDetailPage?: boolean; // whether in project detail page
  isInProjectTemplateCreationEditPage: boolean; // whether in project/template creation/edit pages
  currConfiguredTermBases?: TermBaseAssignment[];
  onDeferredSave?: (termBases: TermBaseAssignment[]) => void;
}

interface ReducerState {
  isLoading: boolean;
  isError: boolean;
  termbaseConfigurationModalToggle: boolean;
  termbaseSelectionModalToggle: boolean;
  canGoBackToSelection: boolean;
  error?: object;
  configuredTermBases: TermBaseAssignment[];
  configurableTermBases: TermBaseAssignment[];
  isLoadingSelectionTBFromServer: boolean;
}

export const reducer = (state: ReducerState, action): ReducerState => {
  switch (action.type) {
    case 'loadingTermBases':
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case 'loadedTermBases':
      return {
        ...state,
        isLoading: false,
        isError: false,
        configuredTermBases: action.configuredTermBases,
        configurableTermBases: action.configuredTermBases,
      };
    case 'termBasesAPIFailed':
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    case 'toggleSelectionTermBaseModal':
      return {
        ...state,
        termbaseSelectionModalToggle: action.toggle,
        isLoadingSelectionTBFromServer: action.isLoadingFromServer ?? false,
      };
    case 'toggleConfigurationTermBaseModal':
      return {
        ...state,
        termbaseConfigurationModalToggle: action.toggle,
        canGoBackToSelection: action.canGoBack ?? false,
      };
    case 'resetConfigurationTBPage':
      return {
        ...state,
        configurableTermBases: state.configuredTermBases,
      };
    case 'setConfigurationTBPage': {
      const tbConfigs: TermBaseAssignment[] = [];
      action.termbases.forEach(tb => {
        tb.supportedLangs.forEach(sl => {
          tbConfigs.push({
            ...tb,
            writeMode: false,
            readMode: true,
            qualityAssurance: false,
            priority: 0,
            targetLang: sl,
          });
        });
      });
      return {
        ...state,
        configurableTermBases: tbConfigs.map(tb => {
          const configuredTB = (action.isInProjectTemplateCreationEditPage
            ? action.currConfiguredTermBases
            : state.configurableTermBases
          ).find(ctb => ctb.tbId === tb.tbId && ctb.targetLang === tb.targetLang);
          return configuredTB ? configuredTB : tb;
        }),
      };
    }
    default:
      return state;
  }
};

export const TermBaseList = ({
  uid,
  sourceLocale,
  targetLocales,
  canEdit,
  organizationName,
  isInProjectDetailPage,
  isInProjectTemplateCreationEditPage,
  currConfiguredTermBases,
  onDeferredSave,
}: Props): ReactElement => {
  /**************** Init Reducer *****************/
  const init = (): ReducerState => ({
    isLoading: false,
    isError: false,
    canGoBackToSelection: false,
    termbaseConfigurationModalToggle: false,
    configuredTermBases: [],
    termbaseSelectionModalToggle: false,
    isLoadingSelectionTBFromServer: false,
    configurableTermBases: [],
  });
  const [reducerState, dispatch] = useReducer(reducer, null, init);
  const {
    isLoading,
    isError,
    error,
    configuredTermBases,
    configurableTermBases,
    termbaseSelectionModalToggle,
    termbaseConfigurationModalToggle,
    isLoadingSelectionTBFromServer,
    canGoBackToSelection,
  } = reducerState;

  /**************** React Hooks *****************/
  const handleLoadTermBases = useCallback(() => {
    dispatch({
      type: 'loadingTermBases',
    });

    AtmsApiClient.httpGet(
      `//${WEB_HOST_AND_PORT}/web/api/v9/project/getTermBases?${stringify({ project: uid })}`
    )
      .then(response => {
        dispatch({
          type: 'loadedTermBases',
          configuredTermBases: response.map(tb => ({
            ...tb,
            tbId: tb.termBase.id,
            tbName: tb.termBase.name,
            isPublic: isPublic(organizationName, tb),
            isSelected: true,
          })),
        });
      })
      .catch(e => {
        publishCountMetric('termBasesAPIFailed-TermBaseList', 'error', e.message);
        dispatch({ type: 'termBasesAPIFailed', error: e });
      });
  }, [uid]);

  // Load the page if not loaded or if UID changes for project detail page.
  useLayoutEffect(() => {
    if (isInProjectTemplateCreationEditPage) {
      dispatch({
        type: 'loadedTermBases',
        configuredTermBases: (currConfiguredTermBases ?? []).filter(tb => tb.readMode),
      });
    } else {
      handleLoadTermBases();
    }
  }, [uid, handleLoadTermBases, currConfiguredTermBases]);

  /**************** Event Handler && Functions *****************/
  const onGoBackToSelection = (event): void => {
    if (isInProjectTemplateCreationEditPage) {
      event.preventDefault();
    }
    dispatch({ type: 'toggleConfigurationTermBaseModal', toggle: false });
    dispatch({ type: 'toggleSelectionTermBaseModal', toggle: true });
  };

  const onConfigureSelectedTB = (event, termbases: TermBaseAssignment[]): void => {
    if (isInProjectTemplateCreationEditPage) {
      event.preventDefault();
    }
    dispatch({
      type: 'setConfigurationTBPage',
      termbases: termbases,
      isInProjectTemplateCreationEditPage: isInProjectTemplateCreationEditPage,
      currConfiguredTermBases: currConfiguredTermBases,
    });
    dispatch({ type: 'toggleSelectionTermBaseModal', toggle: false });
    dispatch({ type: 'toggleConfigurationTermBaseModal', toggle: true, canGoBack: true });
  };

  const onCloseSelectionTBPage = (event): void => {
    if (isInProjectTemplateCreationEditPage) {
      event.preventDefault();
    }
    dispatch({ type: 'toggleSelectionTermBaseModal', toggle: false });
  };

  const onCloseConfigureTBPage = (event): void => {
    if (isInProjectTemplateCreationEditPage) {
      event.preventDefault();
    }
    dispatch({ type: 'resetConfigurationTBPage' });
    dispatch({ type: 'toggleConfigurationTermBaseModal', toggle: false });
  };

  const onSaveConfiguredTBs = (event, termbases: TermBaseAssignment[]): void => {
    if (isInProjectTemplateCreationEditPage && onDeferredSave) {
      event.preventDefault();
      dispatch({ type: 'toggleConfigurationTermBaseModal', toggle: false });
      onDeferredSave(termbases);
    } else {
      Promise.all(saveTBConfigs(true, [], termbases, uid, targetLocales ?? []))
        .then(() => {
          dispatch({ type: 'toggleConfigurationTermBaseModal', toggle: false });
          handleLoadTermBases();
        })
        .catch(e => {
          dispatch({ type: 'termBasesAPIFailed', error: e });
        });
    }
  };

  /**************** React Components *****************/
  const tbTableHeader = (
    <PageHeader
      tag="h2"
      title={I18n.t('Term bases')}
      extraContent={<HelpInfoLink helpId={PROJECT_DETAIL_MANAGE_TBS_HELP} />}
      buttons={[
        (isInProjectDetailPage ?? false) &&
          canEdit && {
            id: 'createTB',
            text: I18n.t('Create'),
            href: `/web/termBase/create?project=${uid}`,
          },
        canEdit && {
          id: 'configure',
          text: I18n.t('Configure'),
          disabled: configuredTermBases.length === 0,
          onClick: (event): void => {
            if (isInProjectTemplateCreationEditPage) {
              event.preventDefault();
            }
            dispatch({
              type: 'toggleConfigurationTermBaseModal',
              toggle: true,
            });
          },
        },
        canEdit && {
          id: 'select',
          text: I18n.t('Select TB'),
          variant: 'primary',
          onClick: (event): void => {
            if (isInProjectTemplateCreationEditPage) {
              event.preventDefault();
            }
            dispatch({
              type: 'toggleSelectionTermBaseModal',
              toggle: true,
              isLoadingFromServer: true,
            });
          },
        },
      ]}
    />
  );

  return isError ? (
    <Alert type="error" content={`${I18n.t('Failed to load term bases')}: ${error}`} />
  ) : (
    <Container withGutters={false}>
      <TableWithGroupByColumn
        id="termbaseList"
        groupByFunction={tbGroupByFunction(false)}
        variant="borderless"
        loading={isLoading}
        columnDefinitions={getColumnDefinitions()}
        header={tbTableHeader}
        items={configuredTermBases}
        empty={I18n.t('No term bases configured')}
        groupByEmptyMessage={I18n.t('No term bases configured')}
        groupByHeaders={(targetLocales ?? []).map(tl => ({ name: tl }))}
      />
      <TermBaseSelect
        organizationName={organizationName}
        isLoadingFromServer={isLoadingSelectionTBFromServer}
        visible={termbaseSelectionModalToggle}
        sourceLocale={sourceLocale}
        targetLocales={targetLocales ?? []}
        onClose={onCloseSelectionTBPage}
        onConfigure={onConfigureSelectedTB}
        currConfiguredTermBases={(currConfiguredTermBases ?? configuredTermBases)?.filter(
          tb => tb.readMode
        )}
      />
      <TermBaseConfiguration
        visible={termbaseConfigurationModalToggle}
        canGoBackToSelection={canGoBackToSelection}
        targetLocales={targetLocales}
        configurableTermBases={configurableTermBases}
        onBack={onGoBackToSelection}
        onSave={onSaveConfiguredTBs}
        onClose={onCloseConfigureTBPage}
        isInProjectTemplateCreationEditPage={isInProjectTemplateCreationEditPage}
      />
    </Container>
  );
};
