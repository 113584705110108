import { useEffect, useState } from 'react';
import { AtmsApiClient, AuthenticatedSession, useSession } from '@amzn/et-console-components';
import { isPublic, TermBaseAssignment } from '../project/termbases/TermBaseCommons';
import { getAppHostConfig } from '@amzn/et-console-components';

const { WEB_HOST_AND_PORT } = getAppHostConfig();

interface State {
  isLoadingTermBaseAssignments: boolean;
  termBaseAssignments?: TermBaseAssignment[];
}

// TODO: Needs to reload when the user profile changes
export const useTermBases = (isProjectMode: boolean, uid?: string | number): State => {
  const [state, setState] = useState<State>({
    isLoadingTermBaseAssignments: true,
    termBaseAssignments: undefined,
  });
  const session = useSession() as AuthenticatedSession;
  const organizationName = session?.organization?.name;
  useEffect(() => {
    if (!state.isLoadingTermBaseAssignments) {
      setState({
        isLoadingTermBaseAssignments: true,
        termBaseAssignments: undefined,
      });
    }
    if (uid) {
      const api = isProjectMode
        ? `//${WEB_HOST_AND_PORT}/web/api/v9/project/getTermBases?project=` + uid
        : `//${WEB_HOST_AND_PORT}/web/api/v9/projectTemplate/getTermBases?template=` + uid;
      AtmsApiClient.httpGet(`${api}`)
        .then(result => {
          setState({
            isLoadingTermBaseAssignments: false,
            termBaseAssignments: result.map(tb => ({
              ...tb,
              tbId: tb.termBase.id,
              tbName: tb.termBase.name,
              isPublic: isPublic(organizationName, tb),
              isSelected: true,
            })),
          });
        })
        .catch(() => {
          setState({
            isLoadingTermBaseAssignments: false,
            termBaseAssignments: undefined,
          });
        });
    } else {
      setState({
        isLoadingTermBaseAssignments: false,
        termBaseAssignments: undefined,
      });
    }
  }, [uid, isProjectMode, organizationName]);

  return state;
};
