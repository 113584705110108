import * as React from 'react';
import { ReactElement, useReducer, useEffect } from 'react';
import {
  Table,
  Checkbox,
  Select,
  Badge,
  Modal,
  Button,
  Alert,
} from '@amzn/awsui-components-react/polaris';
import { CellContents } from '../../types/commonTypes';
import I18n from '../../../setupI18n';
import { TableWithGroupByColumn } from '../../components/TableWithGroupByColumn';
import {
  tbGroupByFunction,
  TermBaseAssignment,
  getPrioritySelectedOption,
  ModalTableContainer,
  getGroupHeader,
  getTermBasesInConfigurationPanel,
  isTBConfigurationsValid,
  getTermBasesToBeSaved,
  TermBaseInConfigurePanel,
  findNewWriteModeState,
  updateWriteEnabledLocales,
  hashWriteEnabledTbByLocales,
} from './TermBaseCommons';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { T } from '../../components/T';

const ItemName = styled('span')`
  color: var(--awsui-color-grey-600);
  font-weight: bold;
  margin-right: 10px;
`;

const MarginRight5px = styled('span')`
  margin-right: 5px;
`;

export interface Props {
  visible: boolean;
  targetLocales?: string[];
  configurableTermBases?: TermBaseAssignment[];
  onClose: (event) => void;
  onSave: (event, configuredTermBases: TermBaseAssignment[]) => void;
  onBack: (event) => void;
  canGoBackToSelection: boolean;
  isInProjectTemplateCreationEditPage: boolean;
}

interface ReducerState {
  termBases: TermBaseAssignment[];
  termBasesInConfigurePanel: TermBaseInConfigurePanel[];
  isSaving: boolean;
  isAllLocalesCombined: boolean;
  showConfigurationSwitchModal: boolean;
  writeEnabledTbInCombinePanelHashedByLocales: { [locale: string]: number[] };
  errorMessages: string[];
  isError: boolean;
}

const defaultPriorityOptionsList = [
  { label: I18n.t('None'), id: '0' },
  { label: I18n.t('Primary'), id: '1' },
  { label: I18n.t('Secondary'), id: '2' },
];

export const TermBaseConfiguration = ({
  visible,
  targetLocales,
  configurableTermBases,
  onClose,
  onSave,
  onBack,
  canGoBackToSelection,
  isInProjectTemplateCreationEditPage,
}: Props): ReactElement => {
  /**************** Initialize *****************/
  const init = (): ReducerState => ({
    termBases: [],
    termBasesInConfigurePanel: [],
    isSaving: false,
    isAllLocalesCombined: false,
    showConfigurationSwitchModal: false,
    writeEnabledTbInCombinePanelHashedByLocales: {},
    errorMessages: [],
    isError: false,
  });

  const reducer = (state: ReducerState, action): ReducerState => {
    switch (action.type) {
      case 'updateTBItem':
        //Only one termbase can be set to writeMode to true
        if (action.isWriteOperation) {
          if (action.item.writeMode) {
            state.termBasesInConfigurePanel = state.termBasesInConfigurePanel.map(tb => {
              if (state.isAllLocalesCombined && (tb.writeMode || tb.isWriteIndeterminate)) {
                const { newWriteModeState, newInWriteIndeterminateState } = findNewWriteModeState(
                  action.item,
                  tb,
                  state.writeEnabledTbInCombinePanelHashedByLocales
                );
                return {
                  ...tb,
                  writeMode: newWriteModeState,
                  isWriteIndeterminate: newInWriteIndeterminateState,
                };
              } else if (
                !state.isAllLocalesCombined &&
                tb.writeMode &&
                tb.targetLang === action.item.targetLang
              ) {
                return { ...tb, writeMode: false };
              }
              return tb;
            });
          }
          state.writeEnabledTbInCombinePanelHashedByLocales = state.isAllLocalesCombined
            ? updateWriteEnabledLocales(
                action.item.writeMode,
                state.writeEnabledTbInCombinePanelHashedByLocales,
                action.item
              )
            : {};
        }
        return {
          ...state,
          termBasesInConfigurePanel: state.termBasesInConfigurePanel.map(
            (tb: TermBaseAssignment): TermBaseAssignment => {
              if (tb.tbId === action.item.tbId && tb.targetLang === action.item.targetLang) {
                return action.item;
              }
              return tb;
            }
          ),
        };
      case 'setTBList':
        return {
          ...state,
          isSaving: false,
          isAllLocalesCombined: action.isAllLocalesCombined,
          termBases: action.termBases,
          termBasesInConfigurePanel: action.termBasesInConfigurePanel,
        };
      case 'setIsSaving':
        return {
          ...state,
          isSaving: true,
          isError: false,
          errorMessages: [],
        };
      case 'userToggledCombineAllLocalesCheckbox':
        return {
          ...state,
          showConfigurationSwitchModal: true,
        };
      case 'userConfirmedToSwitchConfigurationMode':
        return {
          ...state,
          showConfigurationSwitchModal: false,
          isAllLocalesCombined: !state.isAllLocalesCombined,
          termBasesInConfigurePanel: getTermBasesInConfigurationPanel(
            state.termBases,
            !state.isAllLocalesCombined
          ),
          writeEnabledTbInCombinePanelHashedByLocales: !state.isAllLocalesCombined
            ? hashWriteEnabledTbByLocales(state.termBases)
            : {},
          errorMessages: [],
          isError: false,
        };
      case 'userAbandonedToChangeConfigurationMode':
        return {
          ...state,
          showConfigurationSwitchModal: false,
        };
      case 'tbConfigurePanelClosed':
        return {
          ...state,
          isAllLocalesCombined: false,
          termBases: [],
          termBasesInConfigurePanel: [],
          errorMessages: [],
          isError: false,
        };
      case 'userProvidedInvalidConfiguration':
        return {
          ...state,
          errorMessages: action.errorMessages,
          isError: true,
        };
      default:
        return state;
    }
  };
  const [reducerState, dispatch] = useReducer(reducer, null, init);
  const {
    termBases,
    termBasesInConfigurePanel,
    isSaving,
    isAllLocalesCombined,
    showConfigurationSwitchModal,
    isError,
    errorMessages,
    writeEnabledTbInCombinePanelHashedByLocales,
  } = reducerState;

  const getColumnDefinitions = (): Table.ColumnDefinition<TermBaseInConfigurePanel>[] => {
    const columns = [
      {
        id: 'name',
        header: I18n.t('Name'),
        width: 300,
        cell: (item): CellContents => (
          <div>
            <ItemName id="tbName">{item.tbName}</ItemName>
            {item.isPublic && (
              <MarginRight5px>
                <FontAwesomeIcon id="globalIcon" icon={faGlobe} />
              </MarginRight5px>
            )}
            {item.isNew && (
              <Badge id="newBadge" color="blue">
                New
              </Badge>
            )}
          </div>
        ),
      },
      {
        id: 'read',
        header: I18n.t('Read'),
        width: 100,
        cell: (item): CellContents => (
          <Checkbox
            id={'tbReadCheckBox'}
            checked={item.readMode}
            indeterminate={item.isReadIndeterminate ?? false}
            onChange={(e): void =>
              dispatch({
                type: 'updateTBItem',
                isWriteOperation: true, // checking read mode enables write
                item: {
                  ...item,
                  readMode: e.detail.checked,
                  writeMode: e.detail.checked ? item.writeMode : false,
                  qualityAssurance: e.detail.checked ? item.qualityAssurance : false,
                  isReadIndeterminate: false,
                  isWriteIndeterminate: e.detail.checked ? item.isWriteIndeterminate : false,
                  isQaIndeterminate: e.detail.checked ? item.isQaIndeterminate : false,
                },
              })
            }
          />
        ),
      },
      {
        id: 'write',
        header: I18n.t('Write'),
        width: 100,
        cell: (item): CellContents => (
          <Checkbox
            id={'tbWriteCheckBox'}
            disabled={item.isPublic}
            checked={item.writeMode}
            indeterminate={item.isWriteIndeterminate ?? false}
            onChange={(e): void =>
              dispatch({
                type: 'updateTBItem',
                isWriteOperation: true,
                item: {
                  ...item,
                  writeMode: e.detail.checked,
                  readMode: e.detail.checked ? true : item.readMode,
                  isWriteIndeterminate: false,
                  isReadIndeterminate: e.detail.checked ? false : item.isReadIndeterminate,
                },
              })
            }
          />
        ),
      },
      {
        id: 'qa',
        header: I18n.t('QA'),
        width: 100,
        cell: (item): CellContents => (
          <Checkbox
            id={'tbQACheckBox'}
            checked={item.qualityAssurance}
            indeterminate={item.isQaIndeterminate ?? false}
            onChange={(e): void =>
              dispatch({
                type: 'updateTBItem',
                isWriteOperation: false,
                item: {
                  ...item,
                  isQaIndeterminate: false,
                  qualityAssurance: e.detail.checked,
                },
              })
            }
          />
        ),
      },
      {
        id: 'priority',
        header: I18n.t('Priority'),
        cell: (item): CellContents => (
          <Select
            id={'prioritySelectionList'}
            selectedOption={getPrioritySelectedOption(item.priority)}
            selectedLabel="Selected"
            onChange={(e): void =>
              dispatch({
                type: 'updateTBItem',
                item: {
                  ...item,
                  isPriorityIndeterminate: false,
                  priority: parseInt(e.detail.selectedId),
                },
              })
            }
            options={defaultPriorityOptionsList}
          />
        ),
      },
    ];

    return columns;
  };
  /**************** Hooks *****************/
  useEffect(() => {
    dispatch({
      type: 'setTBList',
      isAllLocalesCombined: false,
      termBases: configurableTermBases,
      termBasesInConfigurePanel: getTermBasesInConfigurationPanel(
        configurableTermBases ?? [],
        false
      ),
    });
  }, [configurableTermBases, onClose, onBack]);

  return (
    <Modal
      id="termbaseConfigurationModal"
      onDismiss={(event): void => {
        dispatch({ type: 'tbConfigurePanelClosed' });
        onClose(event);
      }}
      visible={visible}
      header={I18n.t('Configure term bases')}
      size={'large'}
      footer={
        <span className="awsui-util-f-r">
          <Button
            variant="link"
            onClick={(event): void => {
              dispatch({ type: 'tbConfigurePanelClosed' });
              onClose(event);
            }}
          >
            {I18n.t('Cancel')}
          </Button>
          {canGoBackToSelection && (
            <Button
              variant="normal"
              onClick={(event): void => {
                dispatch({ type: 'tbConfigurePanelClosed' });
                onBack(event);
              }}
            >
              {I18n.t('Back')}
            </Button>
          )}
          <Button
            id="termbaseConfigureSaveBtn"
            variant="primary"
            loading={isSaving}
            text={isInProjectTemplateCreationEditPage ? I18n.t('Confirm') : I18n.t('Save')}
            onClick={(event): void => {
              const termBasesToBeSaved: TermBaseAssignment[] = getTermBasesToBeSaved(
                termBasesInConfigurePanel,
                termBases,
                isAllLocalesCombined,
                writeEnabledTbInCombinePanelHashedByLocales
              );
              const { isValid, errorMessages } = isTBConfigurationsValid(
                termBasesToBeSaved,
                isAllLocalesCombined
              );
              if (isValid) {
                dispatch({ type: 'setIsSaving' });
                onSave(event, termBasesToBeSaved);
              } else {
                event.preventDefault();
                dispatch({
                  type: 'userProvidedInvalidConfiguration',
                  errorMessages: errorMessages,
                });
              }
            }}
          />
        </span>
      }
    >
      <ModalTableContainer>
        {isError &&
          errorMessages.map((message, index) => (
            <Alert
              id={`tbConfigurationPageAlert${index}`}
              key={`tbConfigurationPageAlert${index}`}
              type="error"
              content={message}
            />
          ))}
        {showConfigurationSwitchModal && (
          <Modal
            footer={
              <span className="awsui-util-f-r">
                <Button
                  id={'cancelTbCombineAllLocalesOptionBtn'}
                  variant="link"
                  text={I18n.t('Cancel')}
                  onClick={(): void => {
                    dispatch({
                      type: 'userAbandonedToChangeConfigurationMode',
                    });
                  }}
                />
                <Button
                  id="confirmTbCombineAllLocalesOptionBtn"
                  variant="primary"
                  text={I18n.t('OK')}
                  onClick={(): void => {
                    dispatch({
                      type: 'userConfirmedToSwitchConfigurationMode',
                    });
                  }}
                />
              </span>
            }
            closeLabel={'Close modal'}
            size={'small'}
            header={I18n.t('Are you sure?')}
            visible={showConfigurationSwitchModal}
            onDismiss={(): void => {
              dispatch({
                type: 'userAbandonedToChangeConfigurationMode',
              });
            }}
          >
            <T>
              <strong>You will lose any unsaved changes</strong> if you switch configuration modes
            </T>
          </Modal>
        )}
        <TableWithGroupByColumn
          id="termbaseConfiguration"
          groupByFunction={tbGroupByFunction(isAllLocalesCombined)}
          variant="borderless"
          columnDefinitions={getColumnDefinitions()}
          items={termBasesInConfigurePanel}
          empty={I18n.t('No term bases found')}
          groupByEmptyMessage={I18n.t('No term bases found')}
          groupByHeaders={getGroupHeader(isAllLocalesCombined, targetLocales)}
        >
          <Checkbox
            id={'combineAllLocalesTbCheckbox'}
            checked={isAllLocalesCombined}
            label={I18n.t('Configure all locales')}
            onChange={(event): void => {
              dispatch({
                type: 'userToggledCombineAllLocalesCheckbox',
                isAllLocalesCombined: event.detail.checked,
              });
            }}
          />
        </TableWithGroupByColumn>
      </ModalTableContainer>
    </Modal>
  );
};
