import * as React from 'react';
import { ReactElement, useLayoutEffect, useReducer, useState } from 'react';
import { withRouter } from 'react-router';
import { PageHeader, PolarisFormik } from '@amzn/et-polaris-utils';
import * as yup from 'yup';
import {
  Button,
  ColumnLayout,
  Flash,
  Form,
  FormField,
  FormSection,
  Input,
  Select,
  Spinner,
} from '@amzn/awsui-components-react/polaris';
import { css } from 'emotion';
import { AtmsApiClient } from '@amzn/et-console-components';
import styled from '@emotion/styled';
import { History, Location } from 'history';
import I18n from '../../setupI18n';
import { SegmentMetadata, SelectedJobPartApi } from '../types/commonTypes';
import { stringify } from 'query-string';
import { useCallback } from 'react';
import { T } from '../components/T';
import { publishCountMetric } from '../metricHelper';
import { getAppHostConfig } from '@amzn/et-console-components';

const { WEB_HOST_AND_PORT } = getAppHostConfig();

const formSpacing = css`
  padding-top: 14px;
  padding-bottom: 14px;
`;

const FormContainer = styled('div')`
  max-width: 800px;
  min-width: 280px;
`;

export const modes = [
  {
    id: 'intoXParts',
    label: I18n.t('Into X parts'),
    hint: I18n.t(
      'Enter 3 if you wish to have your current file split into 3 files, each with the same number of characters.'
    ),
    errorText: I18n.t(
      'Must be a single numerical value between 1 and the number of segments in the job'
    ),
    apiPath: `//${WEB_HOST_AND_PORT}/web/api/v9/job/splitByPartsCount`,
    apiDataKey: 'partsCount',
  },
  {
    id: 'everyXSegments',
    label: I18n.t('Every X segments'),
    hint: I18n.t(
      'Enter 1000 if you wish the new files to have 1000 segments each (plus one file with the rest).'
    ),
    errorText: I18n.t(
      'Must be a single numerical value between 1 and the number of segments in the job'
    ),
    apiPath: `//${WEB_HOST_AND_PORT}/web/api/v9/job/splitByPartSize`,
    apiDataKey: 'partSize',
  },
  {
    id: 'everyXWords',
    label: I18n.t('Every X words'),
    hint: I18n.t(
      'Enter 300 if you wish to split the file after every approx. 300 words. Example: A document with 3200 words would would be split into 10 parts with 300 words and 1 part with 200 words.'
    ),
    errorText: I18n.t(
      'Must be a single numerical value between 1 and the number of words in the job'
    ),
    isWordBased: true,
    apiPath: `//${WEB_HOST_AND_PORT}/web/api/v9/job/splitByWordCount`,
    apiDataKey: 'wordCount',
  },
  {
    id: 'afterGivenSegments',
    label: I18n.t('After X segments'),
    hint: I18n.t(
      "Enter segments of your choice (separated by comma), after which a new job should begin. Please note that the job will be split based on the job's initial segmentation."
    ),
    errorText: I18n.t(
      "Must be one or more numerical values between 1 and the number of segments in the job, separated by ','"
    ),
    allowMultipleInputs: true,
    apiPath: `//${WEB_HOST_AND_PORT}/web/api/v9/job/splitAfterSegment`,
    apiDataKey: 'segmentOrdinal',
  },
];

const modeOptions = modes.map(m => ({ id: m.id, label: m.label }));
const modesById = modes.reduce((acc, currentValue) => {
  acc[currentValue.id] = currentValue;
  return acc;
}, {});

export interface Props {
  location: Location<SelectedJobPartApi>;
  history: History;
}

interface ReducerState {
  isLoadingSegmentMetadata: boolean;
  isSplittingFile: boolean;
  isError: boolean;
  error?: string;
  segmentMetadata?: SegmentMetadata;
  mode: 'intoXParts' | 'everyXSegments' | 'everyXWords' | 'afterGivenSegments';
  data: number[];
}

export const SplitFileWorkflow = withRouter(({ location: { pathname, state }, history }: Props) => {
  // TODO: We should be able to get this from the Router.match.params prop
  const projectUID = pathname.substr(pathname.lastIndexOf('/') + 1);

  const init = (): ReducerState => ({
    isLoadingSegmentMetadata: false,
    isSplittingFile: false,
    isError: false,
    mode: 'intoXParts',
    data: [],
  });

  const reducer = (state, action): ReducerState => {
    switch (action.type) {
      case 'loadingSegmentMetadata':
        return {
          ...state,
          isLoadingSegmentMetadata: true,
        };
      case 'loadedSegmentMetadata':
        return {
          ...state,
          isLoadingSegmentMetadata: false,
          segmentMetadata: action.segmentMetadata,
        };
      case 'loadingSegmentMetadataFailed':
        return {
          ...state,
          isLoadingSegmentMetadata: false,
          error: action.error,
        };
      case 'splittingFile':
        return {
          ...state,
          isSplittingFile: true,
          isError: false,
          mode: action.mode,
          data: action.data,
        };
      case 'finishedSplittingFile':
        return {
          ...state,
          isSplittingFile: false,
        };
      case 'splittingFileFailed':
        return {
          ...state,
          isSplittingFile: false,
          isError: true,
          error: action.error,
        };
      default:
        return state;
    }
  };

  const [reducerState, dispatch] = useReducer(reducer, null, init);
  const [validating, setValidating] = useState(false);
  const { isSplittingFile, isLoadingSegmentMetadata, segmentMetadata } = reducerState;

  const handleLoadSegmentMetadata = useCallback((): void => {
    dispatch({
      type: 'loadingSegmentMetadata',
    });

    const query = {
      jobPart: state.jobPartId,
    };

    AtmsApiClient.httpGet(
      `//${WEB_HOST_AND_PORT}/web/api/v9/job/getSegmentsCount?${stringify(query)}`
    )
      .then(response => {
        dispatch({
          type: 'loadedSegmentMetadata',
          segmentMetadata: response[0],
        });
      })
      .catch(e => {
        publishCountMetric('loadSegmentMetadataFailed-SplitFileWorkflow', 'error', e.message);
        dispatch({ type: 'loadSegmentMetadataFailed', error: e });
      });
  }, [state.jobPartId]);

  useLayoutEffect(handleLoadSegmentMetadata, [handleLoadSegmentMetadata]);

  /**
   * Per AWS Specifications, only start validating after the initial submit.
   * @param e the event
   * @param handleSubmit formik submit event handler function.
   */
  const enableValidation = (e, handleSubmit): void => {
    e.preventDefault();
    setValidating(true);
    handleSubmit();
  };

  const handleSubmit = (values): void => {
    const mode = modesById[values.mode];
    const query: any = {
      jobPart: state.jobPartId,
      [mode.apiDataKey]: mode.allowMultipleInputs ? values.data : values.data[0],
    };

    dispatch({
      type: 'splittingFile',
      mode: values.mode,
      data: values.data,
    });
    AtmsApiClient.httpPost(`${mode.apiPath}?${stringify(query)}`)
      .then(() => {
        dispatch({ type: 'finishedSplittingFile' });
        history.push(`/web/project2/show/${projectUID}`);
      })
      .catch(e => {
        publishCountMetric('splittingFileFailed-SplitFileWorkflow', 'error', e.message);
        dispatch({ type: 'splittingFileFailed', error: e });
      });
  };

  const renderForm = ({ values, errors, handleSubmit, handleChange, handleBlur }): ReactElement => {
    const handleInputChange = (e): void => {
      e.detail.value = e.detail.value.split(',').map(v => {
        const parsed = parseInt(v);
        if (isNaN(parsed)) {
          return v;
        }
        return parsed;
      });
      handleChange(e);
    };

    return (
      <FormContainer>
        <form onSubmit={e => enableValidation(e, handleSubmit)}>
          <Form
            header={<PageHeader title={I18n.t('Split file')} />}
            actions={
              <div>
                <Button
                  id="splitFile"
                  text={I18n.t('Split file')}
                  variant="primary"
                  onClick={e => enableValidation(e, handleSubmit)}
                  loading={isSplittingFile}
                />
              </div>
            }
            errorText={
              Object.keys(errors).length !== 0
                ? I18n.t('The form contains errors. Fix them and resubmit.')
                : ''
            }
          >
            <FormSection>
              <ColumnLayout>
                <div data-awsui-column-layout-root="true">
                  <FormField label={I18n.t('Job statistics')}>
                    {isLoadingSegmentMetadata && <Spinner />}
                    {!isLoadingSegmentMetadata && segmentMetadata && (
                      <T>
                        {segmentMetadata.counts.segmentsCount} segments,{' '}
                        {segmentMetadata.counts.wordsCount} words
                      </T>
                    )}
                  </FormField>
                  <FormField
                    label={I18n.t('Split file')}
                    hintText={modesById[values.mode].hint}
                    errorText={errors.input && modesById[values.mode].errorText}
                  >
                    <Select
                      id="mode"
                      controlId="mode"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      selectedId={values.mode}
                      options={modeOptions}
                    />
                    <Input
                      id="data"
                      name="data"
                      onChange={handleInputChange}
                      value={values.data.join()}
                    />
                  </FormField>
                </div>
              </ColumnLayout>
            </FormSection>
            {reducerState.isError && (
              <div className={formSpacing}>
                <Flash
                  type="error"
                  dismissible={true}
                  content={I18n.t('Please try again. If the issue persists, contact support.')}
                  header={I18n.t('Something went wrong.')}
                />
              </div>
            )}
          </Form>
        </form>
      </FormContainer>
    );
  };

  const initialFormValues = {
    mode: reducerState.mode,
    data: reducerState.data,
  };

  // TODO: I tried to get this to work with intelligent validation of the array contents, but
  //  I wasn't able to get it to work. We should circle back to this if we decide to keep the
  //  split file functionality.

  // const wordsOrSegmentsLimitSchema =
  //     yup.number()
  //       .integer()
  //       .min(1)
  //       .when('mode', (mode, schema) => modesById[mode].isWordBased ?
  //           schema.max(segmentMetadata ? segmentMetadata.counts.wordsCount : 1) :
  //           schema.max(segmentMetadata ? segmentMetadata.counts.segmentsCount : 1));
  //
  // const schema = yup.object().shape({
  //   mode: yup.string(),
  //   data: yup.array().required()
  //     .of(wordsOrSegmentsLimitSchema)
  //     .when('mode', (mode, schema) => modesById[mode].allowMultipleInputs ? schema : schema.max(1)),
  // });

  const schema = yup.object().shape({
    mode: yup.string(),
    data: yup.array().required(),
  });

  return (
    <PolarisFormik
      initialValues={initialFormValues}
      validationSchema={schema}
      validateOnChange={validating}
      onSubmit={handleSubmit}
      render={renderForm}
    />
  );
});
