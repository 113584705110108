import styled from '@emotion/styled';
import { HeaderProps } from '../../components/TableWithGroupByColumn';
import { AtmsApiClient } from '@amzn/et-console-components';
import { stringify } from 'query-string';
import I18n from '../../../setupI18n';
import { getAppHostConfig } from '@amzn/et-console-components';

const { WEB_HOST_AND_PORT } = getAppHostConfig();

export interface TranslationMemoryModel {
  isOverride?: boolean;
  id: number;
  workflowStepName: string;
  workflowStepId: number | null;
  workflowStepOrd: number;
  name: string;
  readMode: boolean;
  writeMode: boolean;
  penalty: number;
  isPublic: boolean;
  isNew: boolean;
  isSelected: boolean;
  isReverse?: boolean;
  client: string;
  tmSourceLocale: string;
  tmTargetLocales: string[];
  projectTargetLocale: string;
  otherSupportedProjectTargetLocales: string[];
}

export interface TranslationMemorySelectionModel {
  name: string;
  id: number;
  tmSourceLocale: string;
  supportedTargetLocales: string[];
  client: string;
  workflowStepConfigurations?: TranslationMemoryModel[];
  isSelected: boolean;
  isReverse: boolean;
  isPublic: boolean;
}

export interface TranslationMemoryInConfigurePanel extends TranslationMemoryModel {
  isReadIndeterminate?: boolean;
  isWriteIndeterminate?: boolean;
  isPenaltyIndeterminate?: boolean;
  isOverrideIndeterminate?: boolean;
  projectTargetLocales?: string[];
}

export const ModalContentContainer = styled('div')`
  margin: -1.5rem -2rem;
`;

export const DEFAULT_WORKFLOW_STEP_CONFIG = 'Default';
const allLocalesHeader = I18n.t('All locales');
const maxWriteTMCount = 2;

/***************Table related functions*******************/
export const getTargetLocaleHeader = (
  targetLocales: string[] | undefined,
  isCombiningAllLocales: boolean
): HeaderProps[] => {
  return isCombiningAllLocales
    ? [{ name: allLocalesHeader }]
    : (targetLocales ?? []).map(tl => {
        return { name: tl };
      });
};

export const getGroupByFunction = (
  isCombiningAllLocales: boolean
): ((item: TranslationMemoryModel, tableWithGroupHeader: any) => any) => {
  return isCombiningAllLocales ? groupByAllLocales : groupByLocaleFunction;
};

const groupByAllLocales = (item: TranslationMemoryModel, tableWithGroupHeader: any): any => {
  tableWithGroupHeader[allLocalesHeader].push(item);
  return tableWithGroupHeader;
};

const groupByLocaleFunction = (item: TranslationMemoryModel, tableWithGroupHeader: any): any => {
  if (item.projectTargetLocale in tableWithGroupHeader) {
    tableWithGroupHeader[item.projectTargetLocale].push(item);
  }
  return tableWithGroupHeader;
};

export const isWriteTMCountValid = (
  currentTM: TranslationMemoryModel,
  translationMemoriesInConfigurePanel: TranslationMemoryInConfigurePanel[],
  configurableTMs: TranslationMemoryModel[],
  isCombiningAllLocales: boolean,
  projectTargetLocales: string[]
): any => {
  //At most two TMs in write mode are allowed for a target locale in a workflow.
  const defaultTMs = translationMemoriesInConfigurePanel.filter(
    tm =>
      tm.workflowStepId === null &&
      (isCombiningAllLocales || tm.projectTargetLocale === currentTM.projectTargetLocale)
  );
  const overrideTMs = translationMemoriesInConfigurePanel.filter(
    tm =>
      tm.workflowStepId === currentTM.workflowStepId &&
      (isCombiningAllLocales || tm.projectTargetLocale === currentTM.projectTargetLocale) &&
      tm.isOverride === true
  );
  const combinedTMs =
    defaultTMs.length !== 0
      ? defaultTMs.map(tm => overrideTMs.find(otm => otm.id === tm.id) ?? tm)
      : overrideTMs;
  const combinedWriteTMs = combinedTMs.filter(tm => tm.writeMode);

  if (!isCombiningAllLocales) {
    if (combinedWriteTMs.length + 1 > maxWriteTMCount) {
      const errorMessage = [
        `Locale ${currentTM.projectTargetLocale} exceeds the allowed number of TM in write mode (${maxWriteTMCount}).`,
      ];
      return { isValid: false, errorMessage: errorMessage };
    }
  } else {
    // When configuring for all locales, we need to consider the last saved settings for indeterminate writeMode TMs
    const localesWithMostWriteModeTM = getLocalesWithMostWriteModeTMForIntermediateTM(
      currentTM,
      translationMemoriesInConfigurePanel,
      configurableTMs,
      projectTargetLocales
    );
    // This adds up both write counts that apply to all TM as well as those that only apply to specific ones (intermediate)
    if (combinedWriteTMs.length + localesWithMostWriteModeTM.count > maxWriteTMCount) {
      const localeStr = localesWithMostWriteModeTM.locales.join(', ');
      const errorMessage = [
        `Locale(s) ${localeStr} exceed the allowed number of TM in write mode (${maxWriteTMCount}).`,
      ];
      return { isValid: false, errorMessage: errorMessage };
    }
  }
  return { isValid: true, errorMessage: [] };
};

const getLocalesWithMostWriteModeTMForIntermediateTM = (
  currentTM: TranslationMemoryModel,
  translationMemoriesInConfigurePanel: TranslationMemoryInConfigurePanel[],
  configurableTMs: TranslationMemoryModel[],
  projectTargetLocales: string[]
): { locales: string[]; count: number } => {
  const targetLocalesAffectedFromAddingCurrentTM = projectTargetLocales.filter(locale =>
    currentTM.tmTargetLocales.includes(locale)
  );
  const indeterminateWriteTMIds = new Set(
    translationMemoriesInConfigurePanel
      .filter(
        tm =>
          tm.isWriteIndeterminate &&
          tm.workflowStepId === currentTM.workflowStepId &&
          tm.id !== currentTM.id // We exclude the current TM to not double count
      )
      .map(tm => tm.id)
  );
  // Get writeMode counts for each locale
  const writeModeCountPerLocale: { [locale: string]: number } = {};
  configurableTMs.forEach(tm => {
    if (
      tm.writeMode &&
      indeterminateWriteTMIds.has(tm.id) &&
      tm.workflowStepId === currentTM.workflowStepId
    ) {
      writeModeCountPerLocale[tm.projectTargetLocale] = writeModeCountPerLocale[
        tm.projectTargetLocale
      ]
        ? writeModeCountPerLocale[tm.projectTargetLocale] + 1
        : 1;
    }
  });
  targetLocalesAffectedFromAddingCurrentTM.forEach(locale => {
    writeModeCountPerLocale[locale] = writeModeCountPerLocale[locale]
      ? writeModeCountPerLocale[locale] + 1
      : 1;
  });
  // Gets a list of the locales with the most writeMode configured TM, as well as the number of TMs
  const localesWithMostWriteModeTM = Object.entries(writeModeCountPerLocale).reduce(
    (acc, [locale, count]) => {
      return count === acc.count
        ? { locales: [...acc.locales, locale], count }
        : count > acc.count
        ? { locales: [locale], count }
        : acc;
    },
    { locales: [], count: 0 } as { locales: string[]; count: number }
  );
  return localesWithMostWriteModeTM;
};

export const isTMValidate = (
  configurableTMs: TranslationMemoryModel[],
  workflowSteps,
  targetLocales
): any => {
  //At least one TM should in write mode for each workflow step and each target locale
  let errorMessage: string[] = [];
  [{ name: DEFAULT_WORKFLOW_STEP_CONFIG }].concat(workflowSteps ?? []).forEach(wf => {
    (targetLocales ?? []).forEach(tl => {
      const defaultTMs = configurableTMs.filter(
        tm => tm.workflowStepName === DEFAULT_WORKFLOW_STEP_CONFIG && tm.projectTargetLocale === tl
      );

      const overrideTMs = configurableTMs.filter(
        tm =>
          tm.workflowStepName === wf.name && tm.projectTargetLocale === tl && tm.isOverride === true
      );

      // reverse and public TMs are read only.
      const readOnlyTMs =
        configurableTMs.filter(
          tm =>
            tm.workflowStepName === wf.name &&
            tm.projectTargetLocale === tl &&
            (tm.isReverse === true || tm.isPublic) &&
            tm.isOverride === true
        ) ?? [];

      const overrideWriteTMs = (overrideTMs ?? []).filter(tm => tm.writeMode) ?? [];

      const combinedTMs =
        defaultTMs.length !== 0
          ? defaultTMs.map(tm => overrideTMs.find(otm => otm.id === tm.id) ?? tm)
          : overrideTMs;
      const combinedWriteTMs = combinedTMs.filter(tm => tm.writeMode);
      const combinedReadTMs = combinedTMs.filter(tm => tm.readMode);

      // you cannot override a reverse or public TM  if there is no overridden TM with write Mode (per locale per workflowstep)
      const isReadOnlyTMOverrideValid =
        readOnlyTMs.length === 0 ? true : readOnlyTMs.length > 0 && overrideWriteTMs.length > 0;

      if (
        (combinedWriteTMs.length === 0 && combinedReadTMs.length !== 0) ||
        !isReadOnlyTMOverrideValid
      ) {
        errorMessage = errorMessage.concat(
          `${wf.name}/${tl} must have at least one Translation Memory in write mode`
        );
      }
    });
  });

  if (errorMessage.length !== 0) {
    return { isValid: false, errorMessage: errorMessage };
  }
  return { isValid: true, errorMessage: [] };
};

/**
 * This function will save the default workflow(workflowId == null) first, then parallelly save other workflows
 * @param isProjectMode
 * @param workflowSteps
 * @param targetLocales
 * @param newTranslationMemoryAssignments
 * @param uid
 */
export const saveTMConfig = async (
  isProjectMode,
  workflowSteps,
  existingTranslationMemoryAssignments,
  newTranslationMemoryAssignments,
  uid,
  projectTargetLocales
): Promise<any> => {
  // Includes any assignment that has changed since the last save, including assignments to be removed

  const affectedTargetLocales = getAffectedLocales(
    newTranslationMemoryAssignments,
    existingTranslationMemoryAssignments,
    projectTargetLocales
  );
  const tmAssignmentsToPersist = removeUnusedTM(newTranslationMemoryAssignments);
  const defaultWorkflowQueries = buildTMQueries(
    isProjectMode,
    null,
    affectedTargetLocales,
    tmAssignmentsToPersist,
    uid
  );
  await Promise.all(defaultWorkflowQueries.map(q => AtmsApiClient.httpGet(q)));
  const otherWorkflowQueries = workflowSteps
    .map(wf =>
      buildTMQueries(isProjectMode, wf.id, affectedTargetLocales, tmAssignmentsToPersist, uid)
    )
    .reduce((pre, cur) => [...pre, ...cur], []);
  return Promise.all(otherWorkflowQueries.map(q => AtmsApiClient.httpGet(q)));
};

const getAffectedLocales = (
  newTMs: TranslationMemoryModel[],
  existingTMs: TranslationMemoryModel[],
  projectTargetLocales: string[]
): string[] => {
  //this will filter out all the newly added or updated TMs
  const updatedOrAddedTMs = (newTMs ?? []).filter(
    tm =>
      !(existingTMs ?? []).some(
        existingTM =>
          tm.id === existingTM.id &&
          tm.projectTargetLocale === existingTM.projectTargetLocale &&
          tm.readMode === existingTM.readMode &&
          tm.writeMode === existingTM.writeMode &&
          tm.penalty === existingTM.penalty &&
          tm.isOverride === existingTM.isOverride &&
          tm.workflowStepId === existingTM.workflowStepId
      )
  );
  //this will filter out all the deleted TMs
  const deletedTMs = (existingTMs ?? []).filter(
    tm => !(newTMs ?? []).some(newTM => isStrongEquals(tm, newTM))
  );

  const affectedLocales = new Set(
    [...updatedOrAddedTMs, ...deletedTMs]
      .map(tm => tm.projectTargetLocale)
      .filter(value => projectTargetLocales.includes(value))
  );
  return [...affectedLocales];
};

const buildTMQueries = (isProjectMode, workflowStepId, targetLocales, tms, uid): string[] => {
  return (targetLocales ?? [])
    .map(tl => {
      //Only readMode TM will be saved to ATMS
      const filteredTMList = tms.filter(
        tm => tm.workflowStepId === workflowStepId && tm.projectTargetLocale === tl
      );
      const api = isProjectMode
        ? `//${WEB_HOST_AND_PORT}/web/api/v2/project/setTransMemories?project=` + uid + '&'
        : `//${WEB_HOST_AND_PORT}/web/api/v2/projectTemplate/setTransMemories?template=` +
          uid +
          '&';

      const query = stringify({
        readTransMemory: filteredTMList.map(tm => tm.id),
        writeTransMemory: filteredTMList.filter(tm => tm.writeMode).map(tm => tm.id),
        penalty: filteredTMList.map(tm => tm.penalty),
        targetLang: tl,
        workflowStep: workflowStepId,
      });
      return `${api}${query}`;
    })
    .filter(f => f);
};

/**
 * Transform API response to TranslationMemoryModel
 * @param res
 * @param organizationName
 */
export const transformResponseToTranslationMemoryModel = (
  res,
  organizationName
): TranslationMemoryModel[] => {
  return res.map(r => ({
    ...r,
    name: r.transMemory.name,
    id: r.transMemory.id,
    isNew: false,
    isPublic: isPublic(r, organizationName),
    isSelected: true,
    workflowStepId: r.workflowStep?.id ?? null,
    workflowStepName: r.workflowStep ? r.workflowStep.name : DEFAULT_WORKFLOW_STEP_CONFIG,
    workflowStepOrd: r.workflowStep?.ord,
    isOverride: r.workflowStep ? true : false,
    tmSourceLocale: r.transMemory.sourceLang,
    tmTargetLocales: r.transMemory.targetLangs,
    projectTargetLocale: r.targetLang,
    otherSupportedProjectTargetLocales: [],
    client: r.transMemory.client?.name ?? '-',
    readMode: r.readMode,
    writeMode: r.writeMode,
    penalty: r.penalty,
  }));
};

/***
 * This function extracts the tm from  list API response
 * @param tmResponse
 * @param organizationName
 * @param targetLocales
 *
 *
 *
 */
export const extractTransMemoriesFromResponse = (
  tmResponse,
  organizationName,
  targetLocales
): TranslationMemoryModel[] => {
  return tmResponse
    .reduce((pre, cur) => {
      return [...pre, [...cur.relevant]];
    }, [])
    .reduce((pre, cur, index) => {
      if (cur) {
        cur = cur.map(tm => ({
          ...tm,
          id: tm.id,
          name: tm.name,
          client: tm.client?.name ?? '-',
          // retain these
          tmSourceLocale: tm.sourceLang,
          tmTargetLocales: tm.targetLangs,
          projectTargetLocale: targetLocales[index],
          otherSupportedProjectTargetLocales: [],
          workflowStepId: null,

          readMode: true,
          writeMode: false,
          penalty: 0,
          isPublic: isPublic(tm, organizationName),
          isNew: true,
          isSelected: false,
          isOverride: false,
        }));
      }
      return [...pre, ...cur];
    }, []);
};

export const isReverse = (tm, projectSourceLocale): boolean => {
  if (!projectSourceLocale && !tm) return false;
  const tmSourceLocale = tm.tmSourceLocale;
  const tmTargetLocales = tm.tmTargetLocales ?? [];
  const projectTargetLocale = tm.projectTargetLocale;

  // if the source locales have the same code, TM is either regular or fallback TM
  if (getLocaleCode(projectSourceLocale) === getLocaleCode(tmSourceLocale)) return false;

  const isProjectTargetLocalesReversed =
    getLocaleCode(tmSourceLocale) === getLocaleCode(projectTargetLocale);

  // project source locale is one of TM target locale
  let isProjectSourceLocalesReversed = false;
  tmTargetLocales.forEach(locale => {
    if (getLocaleCode(locale) === getLocaleCode(projectSourceLocale))
      isProjectSourceLocalesReversed = true;
  });

  return isProjectTargetLocalesReversed && isProjectSourceLocalesReversed;
};

export const getLocaleCode = (locale): string => {
  if (locale) return locale.split('_')[0];
  return '';
};

/**
 *  This function adds configuration for newly selected TM's.
 * @param sourceLocale
 * @param targetLocales
 * @param workflowSteps
 * @param currentConfigurations
 * @param selectedTM
 */
export const addTMConfigurations = (
  sourceLocale,
  workflowSteps,
  currentConfigurations: TranslationMemoryModel[],
  selectedTM?: TranslationMemorySelectionModel[]
): TranslationMemoryModel[] => {
  const configurationsPerLocaleByWorkflowStep: { [id: string]: TranslationMemoryModel } = {};
  const workflowWithDefaultStep = [
    { name: DEFAULT_WORKFLOW_STEP_CONFIG, ord: 1, id: null },
    ...(workflowSteps ?? []),
  ];
  currentConfigurations = currentConfigurations.map(tmModel => {
    return {
      ...tmModel,
      isReverse: isReverse(tmModel, sourceLocale),
    };
  });

  if (selectedTM) {
    selectedTM.forEach(tmConfig => {
      if (tmConfig.workflowStepConfigurations) {
        tmConfig.workflowStepConfigurations.forEach(tm => {
          tm.isReverse = isReverse(tm, sourceLocale);
        });
      }
    });
    return addConfigurationForNewlyAddedTMs(
      currentConfigurations,
      selectedTM,
      workflowWithDefaultStep
    );
  } else {
    return addConfigurationForAlreadyConfiguredTMs(currentConfigurations, workflowWithDefaultStep);
  }
  return Object.values(configurationsPerLocaleByWorkflowStep);
};

const addConfigurationForNewlyAddedTMs = (
  currentConfigurations: TranslationMemoryModel[],
  selectedTM: TranslationMemorySelectionModel[],
  workflowWithDefaultStep
): TranslationMemoryModel[] => {
  const configurationsPerLocaleByWorkflowStep = {};
  const supportedLocalesByTmId: { [id: number]: string[] } = selectedTM.reduce(
    (acc, tmSelectionModel) => {
      acc[tmSelectionModel.id] = tmSelectionModel.supportedTargetLocales;
      return acc;
    },
    {}
  );
  const selectedConfigurationByLocaleAndWf: {
    [id: string]: TranslationMemoryModel;
  } = selectedTM.reduce((acc, tmSelectionModel) => {
    const workflowStepConfigurations = tmSelectionModel.workflowStepConfigurations;
    if (workflowStepConfigurations) {
      workflowStepConfigurations.forEach(tmModel => {
        const id =
          tmModel.id + '/' + tmModel.projectTargetLocale + '/' + (tmModel.workflowStepId ?? null);
        acc[id] = tmModel;
      });
    }
    return acc;
  }, {});
  const currentConfigurationsByLocaleAndWf: {
    [id: string]: TranslationMemoryModel;
  } = currentConfigurations.reduce((acc, tm) => {
    const id = tm.id + '/' + tm.projectTargetLocale + '/' + tm.workflowStepId;
    acc[id] = tm;
    return acc;
  }, {});

  Object.keys(supportedLocalesByTmId).forEach(tmId => {
    const supportedLocales = supportedLocalesByTmId[tmId];
    supportedLocales.forEach(locale => {
      workflowWithDefaultStep.forEach(wf => {
        const id = tmId + '/' + locale + '/' + wf.id;
        const defaultId = tmId + '/' + locale + '/' + null;

        /***
         *   If workflow step is default => TM is already configured then use it else use the default config
         *   If workflow step is non-default (like Translation) => TM has configuration then use it (currConfiguration) else use the
         *   default config (If exist use currConfiguration)
         */
        configurationsPerLocaleByWorkflowStep[id] =
          currentConfigurationsByLocaleAndWf[id] ??
          (wf.name === DEFAULT_WORKFLOW_STEP_CONFIG
            ? selectedConfigurationByLocaleAndWf[id]
            : currentConfigurationsByLocaleAndWf[defaultId] ??
              selectedConfigurationByLocaleAndWf[defaultId]);
        configurationsPerLocaleByWorkflowStep[id] = {
          ...configurationsPerLocaleByWorkflowStep[id],
          workflowStepId: wf.id,
          workflowStepOrd: wf.ord,
          workflowStepName: wf.name,
          isSelected: true,
        };
      });
    });
  });

  return Object.values(configurationsPerLocaleByWorkflowStep);
};

const addConfigurationForAlreadyConfiguredTMs = (
  currentConfigurations: TranslationMemoryModel[],
  workflowWithDefaultStep
): TranslationMemoryModel[] => {
  const configurationsPerLocaleByWorkflowStep = {};
  const currentConfigurationsByLocaleAndWf: {
    [id: string]: TranslationMemoryModel;
  } = currentConfigurations.reduce((acc, tm) => {
    const id = tm.id + '/' + tm.projectTargetLocale + '/' + tm.workflowStepId;
    acc[id] = tm;
    return acc;
  }, {});

  currentConfigurations.forEach(tmModel => {
    /* In case a tmModel & workflow paring isn't configured, use either the default step config
     * or whichever step is configured (after setting values to false)
     **/
    const backupConfig = currentConfigurationsByLocaleAndWf[
      tmModel.id + '/' + tmModel.projectTargetLocale + '/' + null
    ] ?? {
      ...tmModel,
      readMode: false,
      writeMode: false,
      penalty: 0,
    };
    workflowWithDefaultStep.forEach(wf => {
      const id = tmModel.id + '/' + tmModel.projectTargetLocale + '/' + wf.id;
      // if there is no configuration for the workflow step, take the config from the one that exists
      configurationsPerLocaleByWorkflowStep[id] = currentConfigurationsByLocaleAndWf[id] ?? {
        ...backupConfig,
        workflowStepId: wf.id,
        workflowStepOrd: wf.ord,
        workflowStepName: wf.name,
        isOverride: false, // it takes the default configuration so the override is false
      };
    });
  });

  return Object.values(configurationsPerLocaleByWorkflowStep);
};

/**
 *  This functions combines  configured TM, fallback TM
 *  and reverse TM into array of
 *  TranslationMemorySelectionModel
 *  input: curr: [{name: tm1, supportedLocales: [de-de]}]
 *         rele: [{name: tm1, supportedLocales: [ta_in]}]
 *         otherOrg: [{name: tm2, supportedLocales: [ta_in]}]
 *  output: [{name: tm1, supportedlocales: [de-de, ta-in]},
 *           {name: tm2, supportedLocales: [ta_in]}]
 * @param relevantTransMemories
 * @param currSelectedTransMemories
 * @param newTransMemories
 */

export const consolidateTransMemories = (
  currSelectedTransMemories: TranslationMemoryModel[],
  relevantTransMemories: TranslationMemoryModel[]
): TranslationMemorySelectionModel[] => {
  let transMemories = convertToTranslationMemorySelectionModel(currSelectedTransMemories, {});
  transMemories = convertToTranslationMemorySelectionModel(relevantTransMemories, transMemories);
  return Object.values(transMemories);
};

export const convertToTranslationMemorySelectionModel = (
  translationMemoryModels: TranslationMemoryModel[],
  tmTable
): any => {
  translationMemoryModels.forEach(tm => {
    if (!tmTable[tm.id]) {
      tmTable[tm.id] = {
        name: tm.name,
        id: tm.id,
        tmSourceLocale: tm.tmSourceLocale,
        supportedTargetLocales: [],
        client: tm.client,
        workflowStepConfigurations: [],
        isSelected: tm.isSelected,
        isPublic: tm.isPublic,
        isReverse: tm.isReverse === undefined ? false : tm.isReverse,
      };
    }

    tmTable[tm.id].supportedTargetLocales = [
      ...new Set(
        tmTable[tm.id].supportedTargetLocales.concat([
          ...tm.otherSupportedProjectTargetLocales,
          tm.projectTargetLocale,
        ])
      ),
    ];
    tmTable[tm.id].workflowStepConfigurations.push(tm);
  });
  return tmTable;
};
/**
 * This function is used to determine if the TM is public
 * @param tm
 * @param organizationName
 */
export const isPublic = (tm, organizationName: string): boolean => {
  const creatorName = (tm.transMemory ?? tm).createdBy.organizationName;
  const isPublic = (tm.transMemory ?? tm).isPublic;

  return isPublic && creatorName !== organizationName;
};

/**
 * If 'combine all locales' options is chosen then this function
 * applies the TM configuration to all target locales.
 * @param translationMemoriesInConfigurePanel
 * @param originallyConfiguredTranslationMemories
 * @param isAllLocalesCombined
 */
export const getTranslationMemoriesToBeSaved = (
  translationMemoriesInConfigurePanel: TranslationMemoryInConfigurePanel[],
  originallyConfiguredTranslationMemories: TranslationMemoryModel[],
  isAllLocalesCombined: boolean
): TranslationMemoryModel[] => {
  if (!isAllLocalesCombined) return translationMemoriesInConfigurePanel;

  const translationMemoriesToBeSaved: TranslationMemoryModel[] = [];

  // Hash the tm configuration by wokrflowStep/tmId for faster lookup in the below steps
  // key: id, value: translationmemory
  const translationMemoryInConfigurePanelHashedById: {
    [id: number]: TranslationMemoryModel;
  } = translationMemoriesInConfigurePanel.reduce((hash, tm) => {
    const id = `${tm.workflowStepId ?? 'null'}/${tm.id}`;
    hash[id] = tm;
    return hash;
  }, {});

  const originallyConfiguredTmHashedByIdAndLocale: {
    [id: number]: {
      [locale: string]: TranslationMemoryModel;
    };
  } = originallyConfiguredTranslationMemories.reduce((hash, tm) => {
    const id = `${tm.workflowStepId ?? 'null'}/${tm.id}`;
    if (!hash[id]) hash[id] = {};
    hash[id][tm.projectTargetLocale] = tm;
    return hash;
  }, {});

  /* For each TM, get the target locales and apply the TM configuration for each of the
   * target locales.
   **/
  Object.keys(translationMemoryInConfigurePanelHashedById).forEach(id => {
    const targetLocales: string[] =
      translationMemoryInConfigurePanelHashedById[id].projectTargetLocales ?? [];
    targetLocales.forEach(locale => {
      const previousConfig: TranslationMemoryModel =
        originallyConfiguredTmHashedByIdAndLocale[id][locale];
      const newConfig: TranslationMemoryInConfigurePanel =
        translationMemoryInConfigurePanelHashedById[id];
      translationMemoriesToBeSaved.push({
        ...previousConfig,
        readMode: newConfig.isReadIndeterminate ? previousConfig.readMode : newConfig.readMode,
        writeMode: newConfig.isWriteIndeterminate ? previousConfig.writeMode : newConfig.writeMode,
        penalty: newConfig.isPenaltyIndeterminate ? previousConfig.penalty : newConfig.penalty,
        isOverride: newConfig.isOverrideIndeterminate
          ? previousConfig.isOverride
          : newConfig.isOverride,
      });
    });
  });

  return translationMemoriesToBeSaved;
};

/**
 * This function provides the translation memories to be displayed in the Configure panel.
 * If 'combine all locales' option is enabled then it returns a list
 * of unique TM
 * @param translationMemories
 * @param isAllLocalesCombined
 */
export const getTranslationMemoriesInConfigurationPanel = (
  translationMemories: TranslationMemoryModel[],
  isAllLocalesCombined: boolean
): TranslationMemoryInConfigurePanel[] => {
  if (translationMemories) {
    translationMemories = resetIndeterminateFields(translationMemories);
    if (isAllLocalesCombined) return combinedTMConfigurationsByAllLocales(translationMemories);
  }

  return translationMemories;
};

const resetIndeterminateFields = (
  translationMemory: TranslationMemoryInConfigurePanel[]
): TranslationMemoryInConfigurePanel[] => {
  if (translationMemory) {
    translationMemory.forEach(tm => {
      tm.isWriteIndeterminate = false;
      tm.isReadIndeterminate = false;
      tm.isPenaltyIndeterminate = false;
      tm.isOverrideIndeterminate = false;
    });
  }

  return translationMemory;
};

/**
 *  This function combines all TM configuration across all locales.
 *  If a TM property in a locale 1 is different from another locale
 *  then it marks the property as indeterminate.
 * @param translationMemories
 */
const combinedTMConfigurationsByAllLocales = (
  translationMemories: TranslationMemoryInConfigurePanel[]
): TranslationMemoryInConfigurePanel[] => {
  if (!translationMemories) return translationMemories;

  const uniqueTranslationMemory = {};
  translationMemories.forEach(tm => {
    const id = `${tm.workflowStepId ?? 'null'}/${tm.id}`;
    if (!uniqueTranslationMemory[id]) {
      uniqueTranslationMemory[id] = {
        ...tm,
        targetLang: allLocalesHeader,
        projectTargetLocales: [tm.projectTargetLocale],
      };
    } else {
      uniqueTranslationMemory[id].isReadIndeterminate =
        uniqueTranslationMemory[id].isReadIndeterminate ||
        !isTmPropertyEquals(uniqueTranslationMemory[id], tm, 'readMode');
      uniqueTranslationMemory[id].isWriteIndeterminate =
        uniqueTranslationMemory[id].isWriteIndeterminate ||
        !isTmPropertyEquals(uniqueTranslationMemory[id], tm, 'writeMode');
      uniqueTranslationMemory[id].isPenaltyIndeterminate =
        uniqueTranslationMemory[id].isPenaltyIndeterminate ||
        !isTmPropertyEquals(uniqueTranslationMemory[id], tm, 'penalty');
      uniqueTranslationMemory[id].isOverrideIndeterminate =
        uniqueTranslationMemory[id].isOverrideIndeterminate ||
        !isTmPropertyEquals(uniqueTranslationMemory[id], tm, 'isOverride');

      uniqueTranslationMemory[id].projectTargetLocales.push(tm.projectTargetLocale);
    }
  });

  const tmInConfigurePanel: TranslationMemoryInConfigurePanel[] = Object.values(
    uniqueTranslationMemory
  );
  tmInConfigurePanel.map(translationMemory => {
    // In order for the indeterminate state to be visible, the property has to be marked unchecked
    translationMemory.readMode = translationMemory.isReadIndeterminate
      ? false
      : translationMemory.readMode;
    translationMemory.writeMode = translationMemory.isWriteIndeterminate
      ? false
      : translationMemory.writeMode;
    translationMemory.isOverride = translationMemory.isOverrideIndeterminate
      ? false
      : translationMemory.isOverride;
    // set the penalty to Multiple.
    translationMemory.penalty = translationMemory.isPenaltyIndeterminate
      ? -1
      : translationMemory.penalty;
  });
  return tmInConfigurePanel;
};

const isTmPropertyEquals = (
  translationMemory1: TranslationMemoryModel,
  translationMemory2: TranslationMemoryModel,
  property: string
): boolean => {
  return translationMemory1[property] === translationMemory2[property];
};

/**
 * There are two types of TMs will not save in back-end
 * 1. If the TM.readMode is false
 * 2. If the TM is no marked as override
 * @param newTMAssignments
 */
export const removeUnusedTM = (
  newTMAssignments: TranslationMemoryModel[]
): TranslationMemoryModel[] => {
  return newTMAssignments
    .filter(tm => tm.readMode)
    .filter(tm => tm.workflowStepId === null || (tm.workflowStepId !== null && tm.isOverride));
};

export const isWorkflowIdEquals = (actualId: number | null, filterId: string | null): boolean => {
  return filterId === null ? actualId === null : actualId === parseInt(filterId);
};

export const isStrongEquals = (
  t1: TranslationMemoryModel,
  t2: TranslationMemoryModel,
  ignoreLocale = false
): boolean => {
  return (
    t1.id === t2.id &&
    (t1.projectTargetLocale === t2.projectTargetLocale || ignoreLocale) &&
    t1.workflowStepName === t2.workflowStepName
  );
};

export const isCombiningLocalesSafe = (tms: TranslationMemoryModel[]): boolean => {
  const tmsByWorkflowAndTmId: { [id: string]: TranslationMemoryModel[] } = tms.reduce((acc, tm) => {
    const id = (tm.workflowStepId ?? 'null') + '/' + tm.id;
    if (acc[id]) {
      acc[id].push(tm);
    } else {
      acc[id] = [tm];
    }
    return acc;
  }, {});

  let result = true;
  Object.values(tmsByWorkflowAndTmId).forEach((tmBatch): void => {
    const firstTm = tmBatch[0];
    tmBatch.slice(1).forEach(tm => {
      if (!tmConfigEquals(firstTm, tm)) {
        result = false;
      }
    });
  });
  return result;
};

const tmConfigEquals = (tm1: TranslationMemoryModel, tm2: TranslationMemoryModel): boolean => {
  return (
    tm1.readMode === tm2.readMode && tm1.writeMode === tm2.writeMode && tm1.penalty === tm2.penalty
  );
};
