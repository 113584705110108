import React, {
  ReactElement,
  useCallback,
  useLayoutEffect,
  useReducer,
  Fragment,
  ReactNode,
} from 'react';

import { PageHeader, KeyValuePane } from '@amzn/et-polaris-utils';
import I18n from '../../setupI18n';
import {
  ProgressBar,
  ColumnLayout,
  Alert,
  Spinner,
  Table,
  Icon,
} from '@amzn/awsui-components-react/polaris';
import { stringify } from 'querystring';
import { CellContents, WorkflowStep, Group, Project } from '../types/commonTypes';
import {
  formatLocaleCode,
  formatNumber,
  ExpandableSection,
  AtmsApiClient,
} from '@amzn/et-console-components';
import styled from '@emotion/styled';
import { isScoringWorkflow } from '../../shared/scoring';
import { PROJECT_DETAIL_SCORING_SUMMARY_HELP } from './projectHelpContent';
import { HelpInfoLink } from '../HelpContentRouter';
import { CustomDetailEvent, TableSelection } from '@amzn/awsui-components-react/polaris';
import { publishCountMetric } from '../metricHelper';

const PanelDiv = styled.div({
  margin: -20,
});

const buildWorkflowGroupId = (projectId: number, workflowStepId: number): string => {
  return `web:Project:${projectId}-web:WorkflowStep:${workflowStepId}`;
};
const buildTargetGroupId = (workflowGroupId: string, targetLocale: string): string => {
  return `${workflowGroupId}-${targetLocale}`;
};
const parseTargetLangFromGroupId = (groupId: string): string => {
  return groupId.split('-')[2];
};
const calculateProgressBarValue = (jobCounts): number => {
  if (jobCounts.total > 0) {
    return ((jobCounts.total - jobCounts.pending) / jobCounts.total) * 100;
  } else {
    return 0;
  }
};
const scoresByTargetStatus = (group: Group): ReactNode => {
  let className;
  let iconName;
  if (group.status === 'COMPLETED') {
    if (group.scoredWordCount === 0) {
      return <span>{I18n.t('N/A')}</span>;
    }
    if (group.passing) {
      iconName = 'status-positive';
      className = 'awsui-util-status-positive';
    } else {
      iconName = 'status-negative';
      className = 'awsui-util-status-negative';
    }
    return (
      <span className={className}>
        {' '}
        <Icon name={iconName} /> {group.score}%
      </span>
    );
  } else {
    return (
      <span className="awsui-util-status-inactive">
        <Icon name="status-in-progress" /> In-progress
      </span>
    );
  }
};

const additionalInfo = (count): string => {
  const content = I18n.t(
    {
      one: '%{count} job left',
      other: '%{count} jobs left',
    },
    {
      count: count,
    }
  );
  return content;
};

export interface Props {
  project: Project;
  workflowStep?: WorkflowStep;
  jobListChangePointer?: number;
}

interface ReducerState {
  isLoading: boolean;
  isError: boolean;
  error?: object;
  group?: Group;
  noGroupFound: boolean;
  groups?: Group[];
  selectedItems?: Group[];
  isDownloadingFile: boolean;
  fileDownloadError?: string;
}

export const ScoringSummary = ({
  project,
  workflowStep,
  jobListChangePointer,
}: Props): ReactElement => {
  const init = (): ReducerState => ({
    isLoading: false,
    isError: false,
    noGroupFound: true,
    selectedItems: [],
    isDownloadingFile: false,
  });

  const reducer = (state: ReducerState, action): ReducerState => {
    switch (action.type) {
      case 'loadingWorkflowGroup':
        return {
          ...state,
          isLoading: true,
          isError: false,
          noGroupFound: false,
          selectedItems: [],
        };
      case 'loadedWorkflowGroup':
        return {
          ...state,
          isLoading: false,
          isError: false,
          group: action.group,
          groups: action.groups,
          noGroupFound: false,
        };
      case 'loadingWorkflowGroupFailed':
        return {
          ...state,
          isLoading: false,
          isError: true,
          error: action.error,
        };
      case 'noGroupFound':
        return {
          ...state,
          isLoading: false,
          isError: false,
          noGroupFound: true,
        };
      case 'itemSelectionChanged':
        return {
          ...state,
          selectedItems: action.selectedItems,
        };
      case 'fileDownloading':
        return {
          ...state,
          isDownloadingFile: true,
          fileDownloadError: undefined,
        };
      case 'fileDownloaded':
        return {
          ...state,
          isDownloadingFile: false,
        };
      case 'fileDownloadFailed':
        return {
          ...state,
          isDownloadingFile: false,
          fileDownloadError: action.fileDownloadError,
        };
      default:
        return state;
    }
  };

  const [reducerState, dispatch] = useReducer(reducer, null, init);
  const {
    group,
    isError,
    error,
    isLoading,
    noGroupFound,
    groups,
    selectedItems,
    isDownloadingFile,
    fileDownloadError,
  } = reducerState;

  const loadWorkflowGroup = useCallback(() => {
    if (!workflowStep) {
      return;
    }

    dispatch({
      type: 'loadingWorkflowGroup',
    });
    const workflowGroupId = buildWorkflowGroupId(project.id, workflowStep.id);

    const query = {
      groupIds: [workflowGroupId].concat(
        project.targetLangs.map(locale => buildTargetGroupId(workflowGroupId, locale))
      ),
    };

    AtmsApiClient.httpGet(`/api/scoring/bulkGetGroups?${stringify(query)}`)
      .then(response => {
        if (response?.[0]?.id === workflowGroupId) {
          dispatch({
            type: 'loadedWorkflowGroup',
            group: response[0],
            groups: response.slice(1),
          });
        } else dispatch({ type: 'noGroupFound' });
      })
      .catch(e => {
        publishCountMetric('loadingWorkflowGroupFailed-ScoringSummary', 'error', e.message);
        dispatch({ type: 'loadingWorkflowGroupFailed', error: e });
      });
  }, [project, workflowStep, jobListChangePointer]);

  const columnDefinitions: Table.ColumnDefinition<Group>[] = [
    {
      id: 'target',
      header: I18n.t('Target'),
      cell: (item): CellContents => {
        return formatLocaleCode(parseTargetLangFromGroupId(item.id));
      },
    },
    {
      id: 'words',
      header: I18n.t('Scored word count'),
      cell: (item): CellContents => {
        return formatNumber(item.scoredWordCount);
      },
    },
    {
      id: 'score',
      header: I18n.t('Score'),
      cell: (item): CellContents => scoresByTargetStatus(item),
      width: 110,
      minWidth: '90px',
    },
  ];

  useLayoutEffect(() => {
    loadWorkflowGroup();
  }, [project, workflowStep, loadWorkflowGroup]);

  const handleDownloadScoreCardClick = async (): Promise<void> => {
    dispatch({
      type: 'fileDownloading',
    });
    const formData: any = {
      groupIds: selectedItems?.map(j => j.id).sort(), // maintain order irrespective of the order of selections.
    };

    try {
      await AtmsApiClient.download('/api/scoring/downloadScoreCardByTarget', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: stringify(formData),
      });
      dispatch({
        type: 'fileDownloaded',
      });
    } catch (e) {
      publishCountMetric('fileDownloadFailed-ScoringSummary', 'error', e.message);
      dispatch({
        type: 'fileDownloadFailed',
        fileDownloadError: I18n.t('Failed to download file: %{err}', { err: e }),
      });
    }
  };

  const handleSelectionChange = useCallback(
    (e: CustomDetailEvent<TableSelection.SelectionChangeDetail>): void => {
      dispatch({
        type: 'itemSelectionChanged',
        selectedItems: e.detail.selectedItems,
      });
    },
    [dispatch]
  );

  const getPanelContent = (): ReactElement => {
    if (!(workflowStep && isScoringWorkflow(workflowStep.type))) {
      return (
        <div id="no-scoring-workflow" className="awsui-util-font-size-1">
          No scoring in this workflow step.
        </div>
      );
    } else if (isLoading) {
      return <Spinner size={'large'} />;
    } else if (!group || noGroupFound) {
      return (
        <div id="no-scoring-data" className="awsui-util-font-size-1">
          No scoring data available.
        </div>
      );
    } else {
      return (
        <Fragment>
          <PanelDiv>
            <ColumnLayout borders="horizontal" className="awsui-util-no-gutters">
              <div data-awsui-column-layout-root="false">
                <div className="awsui-util-p-l">
                  <ProgressBar
                    label="Progress"
                    value={calculateProgressBarValue(group.jobCounts)}
                    additionalInfo={`${additionalInfo(group.jobCounts.pending)}`}
                  />
                </div>
                <div className="awsui-util-p-l">
                  <KeyValuePane
                    width={4}
                    columns={[
                      [
                        {
                          key: I18n.t('Passed'),
                          value: group.jobCounts.succeeded.toString(),
                        },
                      ],
                      [
                        {
                          key: I18n.t('Failed'),
                          value: group.jobCounts.failed.toString(),
                        },
                      ],
                      [
                        {
                          key: I18n.t('Threshold'),
                          value: group.scoringModel.threshold
                            ? `${group.scoringModel.threshold}%`
                            : '-',
                        },
                      ],
                      [
                        {
                          key: I18n.t('Scoring model'),
                          value: group.scoringModel.name ? group.scoringModel.name : '-',
                        },
                      ],
                    ]}
                  />
                </div>

                {groups && groups.length > 0 && (
                  <Fragment>
                    {fileDownloadError && <Alert type="error" content={`${fileDownloadError}`} />}
                    <Table
                      id="scoresByTarget"
                      header={
                        <PageHeader
                          tag="h2"
                          title={I18n.t('Scores by target')}
                          buttons={[
                            {
                              id: 'downloadScoreCard',
                              text: I18n.t('Download scorecard'),
                              onClick: handleDownloadScoreCardClick,
                              disabled: selectedItems?.length === 0 || isDownloadingFile,
                              loading: isDownloadingFile,
                            },
                          ]}
                        />
                      }
                      columnDefinitions={columnDefinitions}
                      items={groups}
                      variant="borderless"
                      className="awsui-util-no-gutters"
                    >
                      <TableSelection
                        selectedItems={selectedItems}
                        trackBy="id"
                        onSelectionChange={handleSelectionChange}
                      />
                    </Table>
                  </Fragment>
                )}
              </div>
            </ColumnLayout>
          </PanelDiv>
        </Fragment>
      );
    }
  };

  return (
    <ExpandableSection
      header={
        <PageHeader
          tag="h2"
          title={I18n.t('Scoring')}
          extraContent={<HelpInfoLink helpId={PROJECT_DETAIL_SCORING_SUMMARY_HELP} />}
        />
      }
      expanded={false}
      variant="container"
      rememberKey="scoringSummary"
    >
      {isError && <Alert type="error" content={`Failed to load scoring summary: ${error}`} />}
      {!isError && getPanelContent()}
    </ExpandableSection>
  );
};
