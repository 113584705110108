import { Client } from '../types/commonTypes';
import { useEffect, useState } from 'react';
import { paginateAll } from '../../shared/paginationHelper';
import { getAppHostConfig } from '@amzn/et-console-components';

const { WEB_HOST_AND_PORT } = getAppHostConfig();

interface State {
  isLoadingClients: boolean;
  clients: Client[];
  clientsById: { [id: number]: Client };
}

// TODO: Needs to reload when the user profile changes
export const useClients = (): State => {
  const [state, setState] = useState<State>({
    isLoadingClients: true,
    clients: [],
    clientsById: {},
  });
  useEffect(() => {
    const paginate = async (): Promise<void> => {
      const result = await paginateAll(`//${WEB_HOST_AND_PORT}/web/api/v9/client/list`);

      setState({
        isLoadingClients: false,
        clients: result,
        clientsById: result.reduce((acc, val) => {
          if (val.id !== undefined) {
            acc[val.id] = val;
          }
          return acc;
        }, {}),
      });
    };

    paginate();
  }, []);

  return state;
};
