import * as React from 'react';
import { ReactElement, useEffect, useReducer, useState } from 'react';
import { withRouter } from 'react-router';
import { PageHeader, PolarisFormik } from '@amzn/et-polaris-utils';
import * as yup from 'yup';
import {
  Button,
  Checkbox,
  ColumnLayout,
  Flash,
  Form,
  FormField,
  FormSection,
  Input,
  Spinner,
} from '@amzn/awsui-components-react/polaris';
import { css } from 'emotion';
import { AtmsApiClient } from '@amzn/et-console-components';
import styled from '@emotion/styled';
import { History, Location } from 'history';
import { HelpInfoLink } from '../HelpContentRouter';
import { PRETRANSLATE_HELP } from './projectHelpContent';
import I18n from '../../setupI18n';
import { PreTranslateSettings, SelectAllOnServerApi } from '../types/commonTypes';
import { useCallback } from 'react';
import { publishCountMetric } from '../metricHelper';

const formSpacing = css`
  padding-top: 14px;
  padding-bottom: 14px;
`;

const FormContainer = styled('div')`
  max-width: 800px;
  min-width: 280px;
`;

export interface Props {
  location: Location<SelectAllOnServerApi>;
  history: History;
}

interface ReducerState {
  preTranslateSettings?: PreTranslateSettings;
  isPretranslating: boolean;
  isError: boolean;
  useTranslationMemory: boolean;
  translationMemoryThreshold: number;
  useMachineTranslation: boolean;
  insertMachineTranslationIntoTarget: boolean;
  preTranslateNonTranslatables: boolean;
  confirmNonTranslatables: boolean;
  confirm100Matches: boolean;
  confirm101Matches: boolean;
  lock101Matches: boolean;
  lock100Matches: boolean;
  lockNonTranslatables: boolean;
  overwrite: boolean;
  segmentFilter?: 'LOCKED' | 'NOT_LOCKED';
  useProjectPreTranslateSettings: boolean;
  setJobStatusCompleted?: boolean;
  setProjectStatusCompleted?: boolean;
}

export const PretranslateWorkflow = withRouter(
  ({ location: { pathname, state }, history }: Props) => {
    // TODO: We should be able to get this from the Router.match.params prop
    const projectUID = pathname.substr(pathname.lastIndexOf('/') + 1);

    const init = (): ReducerState => ({
      isPretranslating: false,
      isError: false,
      useTranslationMemory: true,
      translationMemoryThreshold: 70,
      useMachineTranslation: false,
      insertMachineTranslationIntoTarget: true,
      preTranslateNonTranslatables: false,
      confirmNonTranslatables: false,
      confirm100Matches: false,
      confirm101Matches: false,
      lock101Matches: false,
      lock100Matches: false,
      lockNonTranslatables: false,
      overwrite: false,
      useProjectPreTranslateSettings: false,
      setJobStatusCompleted: false,
      setProjectStatusCompleted: false,
    });

    const reducer = (state, action): ReducerState => {
      switch (action.type) {
        case 'preTranslateSettingsFetching':
          return {
            ...state,
            preTranslateSettings: null,
          };
        case 'pretranslateSettingsFetched':
          return {
            ...state,
            preTranslateSettings: action.preTranslateSettings,
          };
        case 'pretranslateSettingsFetchFailed':
          return {
            ...state,
            isError: true,
            error: action.error,
          };
        case 'pretranslating':
          return {
            ...state,
            isPretranslating: true,
            isError: false,
            useMachineTranslation: action.useMachineTranslation,
            preTranslateNonTranslatables: action.preTranslateNonTranslatables,
            overwrite: action.overwrite,
            setJobStatusComplete: action.setJobStatusComplete,
          };
        case 'finishedPretranslating':
          return {
            ...state,
            isPretranslating: false,
          };
        case 'pretranslatingFailed':
          return {
            ...state,
            isPretranslating: false,
            isError: true,
            error: action.error,
          };
        default:
          return state;
      }
    };

    const [reducerState, dispatch] = useReducer(reducer, null, init);
    const [validating, setValidating] = useState(false);
    const { isPretranslating, preTranslateSettings } = reducerState;

    /**
     * Per AWS Specifications, only start validating after the initial submit.
     * @param e the event
     * @param handleSubmit formik submit event handler function.
     */
    const enableValidation = (e, handleSubmit): void => {
      e.preventDefault();
      setValidating(true);
      handleSubmit();
    };

    const handleLoadPreTranslateSettings = useCallback(() => {
      dispatch({
        type: 'preTranslateSettingsFetching',
      });
      AtmsApiClient.httpGet(`/api/project?uid=${projectUID}`)
        .then(response => {
          dispatch({
            type: 'pretranslateSettingsFetched',
            preTranslateSettings: response.preTranslateSettings,
          });
        })
        .catch(e => {
          publishCountMetric(
            'pretranslateSettingsFetchFailed-PretranslateWorkflow',
            'error',
            e.message
          );
          dispatch({ type: 'pretranslateSettingsFetchFailed', error: e });
        });
    }, [projectUID]);

    const handleSubmit = (values): void => {
      // TODO: There are other options that are supported by the API
      const request = {
        projectUid: projectUID,
        jobPartIds: state.jobPartIds,
        selectAllOnServer: state.selectAllOnServer,
        level: state.level,
        assignedTo: state.assignedTo,
        status: state.status,
        fileName: state.fileName,
        targetLocale: state.targetLocale,
        dueInHours: state.dueInHours,
        useTranslationMemory: values.useTranslationMemory,
        translationMemoryThreshold: values.translationMemoryThreshold / 100,
        useMachineTranslation: values.useMachineTranslation,
        insertMachineTranslationIntoTarget: values.insertMachineTranslationIntoTarget,
        preTranslateNonTranslatables: values.preTranslateNonTranslatables,
        confirmNonTranslatables: values.confirmNonTranslatables,
        confirm100Matches: values.confirm100Matches,
        confirm101Matches: values.confirm101Matches,
        lock101Matches: values.lock101Matches,
        lock100Matches: values.lock100Matches,
        lockNonTranslatables: values.lockNonTranslatables,
        overwrite: values.overwrite,
        useProjectPreTranslateSettings: values.useProjectPreTranslateSettings,
        setJobStatusCompleted: values.setJobStatusCompleted,
        setProjectStatusCompleted: values.setProjectStatusCompleted,
      };

      // TODO: Formik triggers this method asynchronously; so there's no way to avoid
      //  the 'use act()' test warning.
      dispatch({
        type: 'pretranslating',
      });
      AtmsApiClient.httpPost('/api/job/preTranslate', request)
        .then(() => {
          dispatch({ type: 'finishedPretranslating' });
          history.push(`/web/project2/show/${projectUID}`);
        })
        .catch(e => {
          publishCountMetric('pretranslatingFailed-PreTranslateWorkflow', 'error', e.message);
          dispatch({ type: 'pretranslatingFailed', error: e });
        });
    };

    useEffect(handleLoadPreTranslateSettings, [handleLoadPreTranslateSettings]);

    const renderForm = ({
      values,
      errors,
      handleSubmit,
      handleChange,
      handleBlur,
    }): ReactElement => {
      return (
        <FormContainer>
          <form onSubmit={e => enableValidation(e, handleSubmit)}>
            <Form
              header={
                <PageHeader
                  title={I18n.t('Pre-translate where empty')}
                  extraContent={<HelpInfoLink helpId={PRETRANSLATE_HELP} />}
                />
              }
              actions={
                <div>
                  <Button
                    id="pretranslate"
                    text={I18n.t('Pre-translate')}
                    variant="primary"
                    onClick={e => enableValidation(e, handleSubmit)}
                    loading={isPretranslating}
                  />
                </div>
              }
              errorText={
                Object.keys(errors).length !== 0
                  ? I18n.t('The form contains errors. Fix them and resubmit.')
                  : ''
              }
            >
              <FormSection header={I18n.t('Translation memory')}>
                <ColumnLayout>
                  <div data-awsui-column-layout-root="true">
                    <FormField>
                      <div>
                        <Checkbox
                          id="useTranslationMemory"
                          name="useTranslationMemory"
                          label={I18n.t('Pre-translate from translation memory')}
                          onChange={handleChange}
                          checked={values.useTranslationMemory}
                        />
                      </div>
                    </FormField>
                    <FormField label={I18n.t('Pre-translation threshold (%)')}>
                      <Input
                        id="translationMemoryThreshold"
                        name="translationMemoryThreshold"
                        value={'' + values.translationMemoryThreshold}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormField>
                  </div>
                </ColumnLayout>
              </FormSection>
              <FormSection header={I18n.t('Machine translation')}>
                <ColumnLayout>
                  <div data-awsui-column-layout-root="true">
                    <FormField>
                      <Checkbox
                        id="useMachineTranslation"
                        name="useMachineTranslation"
                        label={I18n.t('Pre-translate from machine translation')}
                        onChange={handleChange}
                        checked={values.useMachineTranslation}
                      />
                    </FormField>
                    <FormField>
                      <Checkbox
                        id="preTranslateNonTranslatables"
                        name="preTranslateNonTranslatables"
                        label={I18n.t('Pre-translate non-translatables')}
                        onChange={handleChange}
                        checked={values.preTranslateNonTranslatables}
                      />
                    </FormField>
                  </div>
                </ColumnLayout>
              </FormSection>
              <FormSection header={I18n.t('Confirm segments')}>
                <ColumnLayout>
                  <div data-awsui-column-layout-root="true">
                    <FormField>
                      <Checkbox
                        id="confirmNonTranslatables"
                        name="confirmNonTranslatables"
                        label={I18n.t("Set segment status to 'confirmed' for non-translatables")}
                        onChange={handleChange}
                        checked={values.confirmNonTranslatables}
                      />
                    </FormField>
                    <FormField>
                      <Checkbox
                        id="confirm100Matches"
                        name="confirm100Matches"
                        label={I18n.t("Set segment status to 'confirmed' for 100% matches")}
                        onChange={handleChange}
                        checked={values.confirm100Matches}
                      />
                    </FormField>
                    <FormField>
                      <Checkbox
                        id="confirm101Matches"
                        name="confirm101Matches"
                        label={I18n.t("Set segment status to 'confirmed' for 101% matches")}
                        onChange={handleChange}
                        checked={values.confirm101Matches}
                      />
                    </FormField>
                  </div>
                </ColumnLayout>
              </FormSection>
              <FormSection header={I18n.t('Job status automation')}>
                <ColumnLayout>
                  <div data-awsui-column-layout-root="true">
                    <FormField>
                      <Checkbox
                        id="setJobStatusCompleted"
                        name="setJobStatusCompleted"
                        label={I18n.t('Set job to completed once pre-translated')}
                        onChange={handleChange}
                        checked={values.setJobStatusCompleted}
                      />
                    </FormField>
                    <FormField>
                      <Checkbox
                        id="setProjectStatusCompleted"
                        name="setProjectStatusCompleted"
                        label={I18n.t('Set project to completed once all jobs pre-translated')}
                        onChange={handleChange}
                        checked={values.setProjectStatusCompleted}
                      />
                    </FormField>
                  </div>
                </ColumnLayout>
              </FormSection>
              <FormSection header={I18n.t('Lock segments')}>
                <ColumnLayout>
                  <div data-awsui-column-layout-root="true">
                    <FormField>
                      <Checkbox
                        id="lock100Matches"
                        name="lock100Matches"
                        label={I18n.t('Lock 100% matches')}
                        onChange={handleChange}
                        checked={values.lock100Matches}
                      />
                    </FormField>
                    <FormField>
                      <Checkbox
                        id="lock101Matches"
                        name="lock101Matches"
                        label={I18n.t('Lock 101% matches')}
                        onChange={handleChange}
                        checked={values.lock101Matches}
                      />
                    </FormField>
                    <FormField>
                      <Checkbox
                        id="lockNonTranslatables"
                        name="lockNonTranslatables"
                        label={I18n.t('Lock non-translatables')}
                        onChange={handleChange}
                        checked={values.lockNonTranslatables}
                      />
                    </FormField>
                  </div>
                </ColumnLayout>
              </FormSection>
              <FormSection header={I18n.t('Overwrite translations')}>
                <ColumnLayout>
                  <div data-awsui-column-layout-root="true">
                    <FormField>
                      <Checkbox
                        id="overwrite"
                        name="overwrite"
                        label={I18n.t('Overwrite existing translations in target segments')}
                        onChange={handleChange}
                        checked={values.overwrite}
                      />
                    </FormField>
                  </div>
                </ColumnLayout>
              </FormSection>
              {reducerState.isError && (
                <div className={formSpacing}>
                  <Flash
                    type="error"
                    dismissible={true}
                    content={I18n.t('Please try again. If the issue persists, contact support.')}
                    header={I18n.t('Something went wrong.')}
                  />
                </div>
              )}
            </Form>
          </form>
        </FormContainer>
      );
    };

    const initialFormValues = {
      useTranslationMemory:
        preTranslateSettings?.useTranslationMemory ?? reducerState.useTranslationMemory,
      translationMemoryThreshold:
        (preTranslateSettings && preTranslateSettings.translationMemoryThreshold * 100) ??
        reducerState.translationMemoryThreshold,
      useMachineTranslation:
        preTranslateSettings?.useMachineTranslation ?? reducerState.useMachineTranslation,
      insertMachineTranslationIntoTarget:
        preTranslateSettings?.insertMachineTranslationIntoTarget ??
        reducerState.insertMachineTranslationIntoTarget,
      preTranslateNonTranslatables:
        preTranslateSettings?.preTranslateNonTranslatables ??
        reducerState.preTranslateNonTranslatables,
      confirmNonTranslatables:
        preTranslateSettings?.confirmNonTranslatables ?? reducerState.confirmNonTranslatables,
      confirm100Matches: preTranslateSettings?.confirm100Matches ?? reducerState.confirm100Matches,
      confirm101Matches: preTranslateSettings?.confirm101Matches ?? reducerState.confirm101Matches,
      lock101Matches: preTranslateSettings?.lock101Matches ?? reducerState.lock101Matches,
      lock100Matches: preTranslateSettings?.lock100Matches ?? reducerState.lock100Matches,
      lockNonTranslatables:
        preTranslateSettings?.lockNonTranslatables ?? reducerState.lockNonTranslatables,
      overwrite: preTranslateSettings?.overwrite ?? reducerState.overwrite,
      useProjectPreTranslateSettings: reducerState.useProjectPreTranslateSettings,
      setJobStatusCompleted:
        preTranslateSettings?.setJobStatusCompleted ?? reducerState.setJobStatusCompleted,
      setProjectStatusCompleted:
        preTranslateSettings?.setProjectStatusCompleted ?? reducerState.setProjectStatusCompleted,
    };

    const schema = yup.object().shape({
      useTranslationMemory: yup.boolean(),
      translationMemoryThreshold: yup.number(),
      useMachineTranslation: yup.boolean(),
      insertMachineTranslationIntoTarget: yup.boolean(),
      preTranslateNonTranslatables: yup.boolean(),
      confirmNonTranslatables: yup.boolean(),
      confirm100Matches: yup.boolean(),
      confirm101Matches: yup.boolean(),
      lock101Matches: yup.boolean(),
      lock100Matches: yup.boolean(),
      lockNonTranslatables: yup.boolean(),
      overwrite: yup.boolean(),
      useProjectPreTranslateSettings: yup.boolean(),
      setJobStatusCompleted: yup.boolean(),
      setProjectStatusCompleted: yup.boolean(),
    });

    return !preTranslateSettings ? (
      <Spinner size="large" />
    ) : (
      <PolarisFormik
        initialValues={initialFormValues}
        validationSchema={schema}
        validateOnChange={validating}
        onSubmit={handleSubmit}
        render={renderForm}
      />
    );
  }
);
