import { useLayoutEffect, useReducer, useCallback } from 'react';

/**
 * Hook to generate an opaque key that changes along with the watched value,
 * unless you tell it to skipNextUpdate prior to an imminent change
 */

const initialState = {
  key: 0,
  skipNextUpdate: false,
};

const reducer = (state, { type, value }) => {
  switch (type) {
    case 'skipNextUpdate':
      return {
        ...state,
        skipNextUpdate: true,
      };
    case 'update':
      // the dependencies array doesn't prevent it from updating the first
      // time, so we protect against it here
      if (state.value === value) {
        return state;
      } else if (state.skipNextUpdate) {
        return {
          ...state,
          value,
          skipNextUpdate: false,
        };
      } else {
        return {
          ...state,
          value,
          key: state.key + 1,
        };
      }
    default:
      throw new Error();
  }
};

export default valueToWatch => {
  const [{ key }, dispatch] = useReducer(reducer, {
    value: valueToWatch,
    ...initialState,
  });

  useLayoutEffect(() => {
    dispatch({ type: 'update', value: valueToWatch });
  }, [valueToWatch]);

  const skipNextUpdate = useCallback(() => dispatch({ type: 'skipNextUpdate' }), []);

  return [key, skipNextUpdate];
};
