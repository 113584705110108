import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import { CellContents, TableColumnDef } from '../types/commonTypes';
import {
  RadioGroup,
  ColumnLayout,
  Checkbox,
  Table,
  Toggle,
  Button,
  CustomDetailEvent,
} from '@amzn/awsui-components-react/polaris';
import I18n from '../../setupI18n';
import { StandardModal } from './StandardModal';
import cloneDeep from 'lodash.clonedeep';

interface Props {
  modalId?: string;
  prefsTableId?: string;
  okButtonId?: string;
  visible: boolean;
  columnOrder: string[];
  columnMap: Map<string, TableColumnDef>;
  onDismiss: () => void;
  onApply: (
    newColumnOrder: string[],
    newColumnMap: Map<string, TableColumnDef>,
    newPageSize?: number,
    newPaginationType?: boolean
  ) => void;
  columnOrderDefault: string[];
  columnMapDefault: Map<string, TableColumnDef>;
  pageSizeOptions?: number[];
  pageSize?: number;
  isOpenEndedPagination: boolean;
}

export const TablePrefsModal = ({
  modalId,
  prefsTableId,
  visible,
  okButtonId,
  columnOrder,
  columnMap,
  onDismiss,
  onApply,
  columnOrderDefault,
  columnMapDefault,
  pageSize = 50,
  pageSizeOptions,
  isOpenEndedPagination,
}: Props): ReactElement => {
  const [newColumnOrder, setNewColumnOrder] = useState([] as string[]);
  const [newColumnMap, setNewColumnMap] = useState(new Map<string, TableColumnDef>());
  const [newPageSize, setNewPageSize] = useState(pageSize);
  const [newPaginationType, setNewPaginationType] = useState(isOpenEndedPagination);

  useEffect(() => {
    setNewColumnOrder(columnOrder);
  }, [setNewColumnOrder, columnOrder]);

  useEffect(() => {
    setNewColumnMap(columnMap);
  }, [setNewColumnMap, columnMap]);

  const prepHandleToggleChange = (id: string) => {
    return (e: CustomDetailEvent<Toggle.ChangeDetail>): void => {
      const clone = cloneDeep(newColumnMap);
      const columnDef = clone.get(id);
      if (columnDef) {
        columnDef.visible = e.detail.checked;
        clone.set(id, columnDef);
        setNewColumnMap(clone);
      }
    };
  };

  const prepHandleReorder = (i: number, newI?: number) => {
    return (): void => {
      if (
        i < 0 ||
        i >= newColumnOrder.length ||
        newI === undefined ||
        newI < 0 ||
        newI >= newColumnOrder.length
      ) {
        return;
      }
      const clone = [...newColumnOrder];
      const t = clone[i];
      clone[i] = clone[newI];
      clone[newI] = t;
      setNewColumnOrder(clone);
    };
  };

  const handleOkClick = (): void => {
    onDismiss();
    onApply(newColumnOrder, newColumnMap, newPageSize, newPaginationType);
  };

  const handleCancelClick = (): void => {
    setNewColumnOrder(columnOrder);
    setNewColumnMap(columnMap);
    onDismiss();
  };

  const onReset = (): void => {
    setNewColumnOrder(columnOrderDefault);
    setNewColumnMap(columnMapDefault);
  };

  const columnDef: Table.ColumnDefinition<{ index: number; column: string }>[] = [
    {
      id: 'name',
      header: I18n.t('Name'),
      cell: (item): CellContents =>
        (newColumnMap.get(item.column) ?? { header: item.column }).header ??
        (newColumnMap.get(item.column) ?? { alternateName: item.column }).alternateName,
    },
    {
      id: 'reorder',
      header: I18n.t('Reorder'),
      cell: (item): CellContents => {
        if (
          (newColumnMap.get(newColumnOrder[item.index]) || { canReorder: false }).canReorder ===
          false
        ) {
          return <Fragment />;
        }
        let upIndex: number | undefined;
        for (let i = item.index - 1; i >= 0; i--) {
          if ((newColumnMap.get(newColumnOrder[i]) || { canReorder: false }).canReorder !== false) {
            upIndex = i;
            break;
          }
        }
        let downIndex: number | undefined;
        for (let i = item.index + 1; i > 0 && i < newColumnOrder.length; i++) {
          if ((newColumnMap.get(newColumnOrder[i]) || { canReorder: false }).canReorder !== false) {
            downIndex = i;
            break;
          }
        }
        return (
          <Fragment>
            <Button
              id="reorderUp"
              variant="icon"
              icon="caret-up-filled"
              disabled={upIndex === undefined}
              onClick={prepHandleReorder(item.index, upIndex)}
            />
            <Button
              id="reorderDown"
              variant="icon"
              icon="caret-down-filled"
              disabled={downIndex === undefined}
              onClick={prepHandleReorder(item.index, downIndex)}
            />
          </Fragment>
        );
      },
    },
    {
      id: 'visible',
      header: I18n.t('Visible'),
      cell: (item): CellContents => (
        <Toggle
          id={'toggle-' + (newColumnMap.get(item.column) ?? { id: item.column }).id}
          checked={newColumnMap.get(item.column)?.visible ?? true}
          disabled={!(newColumnMap.get(item.column)?.canHide ?? true)}
          onChange={prepHandleToggleChange(item.column)}
        />
      ),
    },
  ];

  return (
    <StandardModal
      id={modalId}
      header={I18n.t('Manage preferences')}
      content={
        <ColumnLayout columns={2} borders={'horizontal'} css={'awsui-util-no-gutters'}>
          <div data-awsui-column-layout-root="true">
            <div>
              <h3>
                <label>{I18n.t('Pagination Options')}</label>
              </h3>
              <div style={{ marginTop: 15 }} />
              <label>{I18n.t('Select page size')}</label>
              <RadioGroup
                value={'' + newPageSize}
                items={(pageSizeOptions ?? [pageSize]).map(i => {
                  return {
                    value: '' + i,
                    label: I18n.t('%{recordCount} items', { recordCount: i }),
                  };
                })}
                onChange={(e): void => {
                  setNewPageSize(parseInt(e.detail.value));
                }}
              />
              <div style={{ marginTop: 15 }} />
              <label>{I18n.t('Pagination Type')}</label>
              <Checkbox
                name="openEndedPagination"
                label={I18n.t('Open-ended')}
                onChange={(e): void => {
                  setNewPaginationType(e.detail.checked);
                }}
                checked={newPaginationType}
              />
            </div>
            <div>
              <Table
                id={prefsTableId}
                header={I18n.t('Columns')}
                items={newColumnOrder.map((v, i) => ({ index: i, column: v }))}
                columnDefinitions={columnDef}
                variant="borderless"
              />
              <div className="awsui-util-mt-m">
                <Button
                  id="reset-preferences"
                  text={I18n.t('Reset preferences')}
                  onClick={onReset}
                />
              </div>
            </div>
          </div>
        </ColumnLayout>
      }
      visible={visible}
      handleCancelClick={handleCancelClick}
      handleOkClick={handleOkClick}
      okButtonId={okButtonId}
      size={'large'}
    />
  );
};
