import React, { forwardRef, ReactElement } from 'react';

import Tippy, { TippyProps, useSingleton } from '@tippy.js/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import styled from '@emotion/styled';

const TooltipPane = styled.div({
  maxWidth: 'calc(100vw - 10px)',
  overflowWrap: 'break-word',
  wordWrap: 'break-word',
  flexWrap: 'wrap',
  whiteSpace: 'normal',
  color: 'white',
});

// TODO: We need the non-interactive version because the 'interactive' flag causes the tooltip to attach
//  directly to the parent instead of document.body (by default), which causes z-index issues inside tables
//  and such. We should be able to pass in a ref to override that behavior, but for now we have two
//  elements.
export const Tooltip = forwardRef(
  ({ children, content, ...props }: TippyProps): ReactElement => {
    // TODO: Find a way to use the same singleton instance across the entire app
    const singleton = useSingleton({ allowHTML: true, theme: 'material', arrow: true });

    return (
      <Tippy
        content={<TooltipPane className="awsui">{content}</TooltipPane>}
        {...props}
        singleton={singleton}
      >
        <span>{children}</span>
      </Tippy>
    );
  }
);

export const InteractiveTooltip = forwardRef(
  ({ children, content, ...props }: TippyProps): ReactElement => {
    // TODO: Find a way to use the same singleton instance across the entire app
    const singleton = useSingleton({
      allowHTML: true,
      theme: 'material',
      arrow: true,
      interactive: true,
    });

    return (
      <Tippy
        content={<TooltipPane className="awsui">{content}</TooltipPane>}
        {...props}
        singleton={singleton}
      >
        <span>{children}</span>
      </Tippy>
    );
  }
);
