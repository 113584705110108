/**
 * This file is the copy of TranslationMemoryList.tsx. @liuyidin is responsible for developing the new TM UI
 */
import * as React from 'react';
import { ReactElement, useCallback, useEffect, useLayoutEffect, useReducer } from 'react';
import { Alert, Icon, Table } from '@amzn/awsui-components-react/polaris';
import { Container, PageHeader } from '@amzn/et-polaris-utils';
import { AtmsApiClient } from '@amzn/et-console-components';
import { stringify } from 'query-string';
import { CellContents, WorkflowStep } from '../../types/commonTypes';
import { PROJECT_DETAIL_MANAGE_TMS_HELP } from '../projectHelpContent';
import { HelpInfoLink } from '../../HelpContentRouter';
import I18n from '../../../setupI18n';
import { Link } from 'react-router-dom';
import { TableWithGroupByColumn } from '../../components/TableWithGroupByColumn';
import {
  addTMConfigurations,
  getGroupByFunction,
  getTargetLocaleHeader,
  isWorkflowIdEquals,
  removeUnusedTM,
  saveTMConfig,
  transformResponseToTranslationMemoryModel,
  TranslationMemoryModel,
  TranslationMemorySelectionModel,
} from './TranslationMemoryCommon';
import { TranslationMemorySelection } from './TranslationMemorySelection';
import { TranslationMemoryConfiguration } from './TranslationMemoryConfiguration';
import { WorkflowStepFilter } from './TranslationMemoryWorkflowStepFilter';
import { publishCountMetric } from '../../metricHelper';
import { getAppHostConfig } from '@amzn/et-console-components';

const { WEB_HOST_AND_PORT } = getAppHostConfig();

const columnDefinitions: Table.ColumnDefinition<TranslationMemoryModel>[] = [
  {
    id: 'name',
    header: I18n.t('Name'),
    cell: (item): CellContents => (
      <Link id={'tmNameLink'} to={`/web/transMemory/show/${item.id}`}>
        {item.isOverride ? item.name + ' [OVERRIDE]' : item.name}
      </Link>
    ),
    width: 400,
  },
  {
    id: 'read',
    header: I18n.t('Read'),
    cell: (item): CellContents =>
      item.readMode && <Icon id={'tmReadModeIcon'} name="status-positive" />,
  },
  {
    id: 'write',
    header: I18n.t('Write'),
    cell: (item): CellContents =>
      item.writeMode && <Icon id={'tmWriteModeIcon'} name="status-positive" />,
  },
  {
    id: 'penalty',
    header: I18n.t('Penalty'),
    cell: (item): CellContents => <div id={'tmPenalty'}>{item.penalty}%</div>,
  },
];

export interface Props {
  isProjectMode: boolean; // true: project; false: template
  uid?: string;
  workflowSteps: WorkflowStep[];
  currentWorkflowStep?: WorkflowStep;
  organizationName: string; // creator's organization name
  sourceLocale?: string;
  targetLocales?: string[];
  canEdit: boolean;
  isInProjectDetailPage?: boolean; // whether in project detail page
  isInProjectTemplateCreationEditPage: boolean; // whether in project/template creation/edit pages
  currConfiguredTransMemories?: TranslationMemoryModel[];
  onDeferredSave?: (transMemories: TranslationMemoryModel[]) => void;
  isProjectShared?: boolean;
}

interface ReducerState {
  isLoadingSelectionPageFromServer: boolean;
  workflowStepIdFilter: string | null;
  selectionPageToggle: boolean;
  configurationPageToggle: boolean;
  isLoading: boolean;
  isError: boolean;
  error?: object;
  canGoBackToSelection: boolean;
  translationMemories: TranslationMemoryModel[];
  translationMemoriesConfiguration: TranslationMemoryModel[];
}

export const reducer = (state: ReducerState, action): ReducerState => {
  switch (action.type) {
    case 'toggleSelectionPage':
      return {
        ...state,
        selectionPageToggle: action.selectionPageToggle,
        isLoadingSelectionPageFromServer: action.isLoadingFromServer ?? false,
      };
    case 'toggleConfigurationPage':
      return {
        ...state,
        configurationPageToggle: action.configurationPageToggle,
        canGoBackToSelection: action.canGoBack ?? false,
      };
    case 'resetConfigurationPageTranslationMemories':
      return {
        ...state,
        translationMemoriesConfiguration: action.translationMemoriesConfiguration,
      };
    case 'loadingTranslationMemories':
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case 'loadedTranslationMemories':
      return {
        ...state,
        isLoading: false,
        translationMemories: action.translationMemories,
        translationMemoriesConfiguration: action.translationMemoriesConfiguration,
      };
    case 'loadTranslationMemoriesFailed':
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    case 'updateWorkflowStepFilter':
      return {
        ...state,
        workflowStepIdFilter: action.filter,
      };
    case 'loadConfigurableTransMemories': {
      return {
        ...state,
        translationMemoriesConfiguration: action.selectedTransMemories,
      };
    }
    default:
      return state;
  }
};

export const TranslationMemoryList = ({
  isProjectMode,
  uid,
  workflowSteps,
  canEdit,
  sourceLocale,
  targetLocales,
  organizationName,
  isInProjectDetailPage,
  isInProjectTemplateCreationEditPage,
  currConfiguredTransMemories,
  currentWorkflowStep,
  onDeferredSave,
  isProjectShared,
}: Props): ReactElement => {
  const init = (): ReducerState => ({
    selectionPageToggle: false,
    configurationPageToggle: false,
    isLoading: false,
    isError: false,
    canGoBackToSelection: false,
    translationMemories: [],
    translationMemoriesConfiguration: [],
    workflowStepIdFilter: null,
    isLoadingSelectionPageFromServer: false,
  });

  /*******************Init state **********************/
  const [reducerState, dispatch] = useReducer(reducer, null, init);
  const {
    isLoading,
    isError,
    error,
    translationMemories,
    translationMemoriesConfiguration,
    selectionPageToggle,
    configurationPageToggle,
    canGoBackToSelection,
    isLoadingSelectionPageFromServer,
    workflowStepIdFilter,
  } = reducerState;

  /******************* Functions **********************/
  const onCloseSelectionPage = (event: CustomEvent): void => {
    if (isInProjectTemplateCreationEditPage) {
      event.preventDefault();
    }
    dispatch({ type: 'toggleSelectionPage', selectionPageToggle: false });
  };

  const onConfigureSelectionPage = (
    event: CustomEvent,
    selectedTMList: TranslationMemorySelectionModel[]
  ): void => {
    if (isInProjectTemplateCreationEditPage) {
      event.preventDefault();
    }
    dispatch({ type: 'toggleSelectionPage', selectionPageToggle: false });
    dispatch({
      type: 'loadConfigurableTransMemories',
      selectedTransMemories: addUnusedTMOverrides(translationMemories, selectedTMList),
    });
    dispatch({ type: 'toggleConfigurationPage', configurationPageToggle: true, canGoBack: true });
  };

  const onCloseConfigurationPage = (event: CustomEvent): void => {
    if (isInProjectTemplateCreationEditPage) {
      event.preventDefault();
    }
    dispatch({
      type: 'resetConfigurationPageTranslationMemories',
      translationMemoriesConfiguration: addUnusedTMOverrides(translationMemories),
    });
    dispatch({ type: 'toggleConfigurationPage', configurationPageToggle: false });
  };

  const onBackConfigurationPage = (event: CustomEvent): void => {
    if (isInProjectTemplateCreationEditPage) {
      event.preventDefault();
    }
    dispatch({ type: 'toggleConfigurationPage', configurationPageToggle: false });
    dispatch({ type: 'toggleSelectionPage', selectionPageToggle: true });
  };

  const onSaveConfigurationPage = (configuredTMList: TranslationMemoryModel[]): void => {
    if (isInProjectTemplateCreationEditPage && onDeferredSave) {
      dispatch({ type: 'toggleConfigurationPage', configurationPageToggle: false });
      onDeferredSave(removeUnusedTM(configuredTMList));
    } else {
      saveTMConfig(
        isProjectMode,
        workflowSteps,
        [],
        configuredTMList,
        uid ?? '',
        targetLocales
      ).then(() => {
        dispatch({ type: 'toggleConfigurationPage', configurationPageToggle: false });
        handleLoadTranslationMemories();
      });
    }
  };

  const onSelectWorkflowStepFilter = (workflowStepId: string | null): void => {
    dispatch({ type: 'updateWorkflowStepFilter', filter: workflowStepId });
  };

  const getFilteredTranslationMemories = (): TranslationMemoryModel[] => {
    const tmsByTargetLangWithDefault = {};

    translationMemories.forEach(tm => {
      if (!tm.workflowStepId && !tmsByTargetLangWithDefault[tm.id + '/' + tm.projectTargetLocale]) {
        //Add default workflow TM
        tmsByTargetLangWithDefault[tm.id + '/' + tm.projectTargetLocale] = tm;
      } else if (isWorkflowIdEquals(tm.workflowStepId, workflowStepIdFilter)) {
        //Do the override
        tmsByTargetLangWithDefault[tm.id + '/' + tm.projectTargetLocale] = tm;
      }
    });
    return Object.values(tmsByTargetLangWithDefault);
  };

  const addUnusedTMOverrides = useCallback(
    (
      currentConfigurations: TranslationMemoryModel[],
      selectedTM?: TranslationMemorySelectionModel[]
    ): TranslationMemoryModel[] => {
      return addTMConfigurations(sourceLocale, workflowSteps, currentConfigurations, selectedTM);
    },
    [sourceLocale, targetLocales, currConfiguredTransMemories]
  );

  /******************* React Hooks **********************/
  const handleLoadTranslationMemories = useCallback((): void => {
    dispatch({
      type: 'loadingTranslationMemories',
    });
    const workflowStepIds: (number | undefined | null)[] =
      workflowSteps.length > 0 ? workflowSteps.map(wfs => wfs.id) : [undefined];
    if (isInProjectDetailPage && isProjectShared) workflowStepIds.push(null);

    Promise.all(
      workflowStepIds.map(wfsId => {
        return AtmsApiClient.httpGet(
          `//${WEB_HOST_AND_PORT}/web/api/v4/transMemory/gatherByProject?${stringify({
            project: uid,
            workflowStep: wfsId,
          })}`
        );
      })
    )
      .then(responses => {
        const response = responses.flatMap(v => v);
        const translationMemories: TranslationMemoryModel[] = transformResponseToTranslationMemoryModel(
          response,
          organizationName
        );
        dispatch({
          type: 'loadedTranslationMemories',
          translationMemories: translationMemories,
          translationMemoriesConfiguration: addUnusedTMOverrides(translationMemories),
        });
      })
      .catch(e => {
        publishCountMetric(
          'translationMemory-loadTranslationMemoriesFailed-TranslationMemoryList',
          'error',
          e.message
        );
        dispatch({ type: 'loadTranslationMemoriesFailed', error: e });
      });
  }, [uid, organizationName, addUnusedTMOverrides]);

  // Load the page if not loaded or if UID changes.
  useLayoutEffect((): void => {
    if (isInProjectTemplateCreationEditPage) {
      dispatch({
        type: 'loadedTranslationMemories',
        translationMemories: currConfiguredTransMemories ?? [],
        translationMemoriesConfiguration: addUnusedTMOverrides(currConfiguredTransMemories ?? []),
      });
    } else {
      handleLoadTranslationMemories();
    }
  }, [
    handleLoadTranslationMemories,
    isInProjectTemplateCreationEditPage,
    currConfiguredTransMemories,
  ]);

  useEffect((): void => {
    dispatch({ type: 'updateWorkflowStepFilter', filter: currentWorkflowStep?.id });
  }, [currentWorkflowStep]);

  /******************* Component **********************/
  const pageHeader = (
    <PageHeader
      tag="h2"
      title={I18n.t('Translation memories')}
      extraContent={<HelpInfoLink helpId={PROJECT_DETAIL_MANAGE_TMS_HELP} />}
      buttons={[
        (isInProjectDetailPage ?? false) &&
          canEdit && {
            id: 'createTM',
            text: I18n.t('Create'),
            href: `/web/transMemory/create?project=${uid}`,
          },
        canEdit && {
          id: 'Configure',
          text: I18n.t('Configure'),
          disabled: translationMemories.length === 0,
          onClick: (event): void => {
            if (isInProjectTemplateCreationEditPage) {
              event.preventDefault();
            }
            dispatch({
              type: 'toggleConfigurationPage',
              configurationPageToggle: true,
              canGoBack: false,
            });
          },
        },
        canEdit && {
          id: 'SelectTMs',
          text: I18n.t('Select TM'),
          variant: 'primary',
          onClick: (event): void => {
            if (isInProjectTemplateCreationEditPage) {
              event.preventDefault();
            }
            dispatch({
              type: 'toggleSelectionPage',
              selectionPageToggle: true,
              isLoadingFromServer: true,
            });
          },
        },
      ]}
    />
  );

  return isError ? (
    <Alert
      id={'mtListPageErrorAlert'}
      type="error"
      content={`${I18n.t('Failed to load translation memories')}: ${error}`}
    />
  ) : (
    <Container withGutters={false}>
      <TableWithGroupByColumn
        id="transmemList"
        groupByFunction={getGroupByFunction(false)}
        variant="borderless"
        loading={isLoading}
        columnDefinitions={columnDefinitions}
        header={pageHeader}
        items={isLoading ? null : getFilteredTranslationMemories()}
        empty={I18n.t('No translation memories found')}
        groupByEmptyMessage={I18n.t('No translation memories configured')}
        groupByHeaders={getTargetLocaleHeader(targetLocales, false)}
      >
        {isInProjectTemplateCreationEditPage && (
          <WorkflowStepFilter
            id={'tmListWorkflowStepFilter'}
            workflowSteps={workflowSteps}
            onChange={onSelectWorkflowStepFilter}
          />
        )}
      </TableWithGroupByColumn>
      <TranslationMemorySelection
        organizationName={organizationName}
        isLoadingFromServer={isLoadingSelectionPageFromServer}
        onClose={onCloseSelectionPage}
        onConfigure={onConfigureSelectionPage}
        sourceLocale={sourceLocale}
        targetLocales={targetLocales}
        visible={selectionPageToggle}
        currConfiguredTransMemories={translationMemories.filter(tm => tm.readMode)}
        uid={uid}
      />
      {configurationPageToggle && (
        <TranslationMemoryConfiguration
          onClose={onCloseConfigurationPage}
          onBack={onBackConfigurationPage}
          onSave={onSaveConfigurationPage}
          visible={configurationPageToggle}
          originalConfigurableTMs={translationMemoriesConfiguration ?? []}
          projectTargetLocales={targetLocales}
          workflowSteps={workflowSteps}
          canGoBackToSelection={canGoBackToSelection}
          isInProjectTemplateCreationEditPage={isInProjectTemplateCreationEditPage}
        />
      )}
    </Container>
  );
};
