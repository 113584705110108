import React, { ReactElement, ReactNode } from 'react';
import { Wizard } from '@amzn/awsui-components-react/polaris';
import styled from '@emotion/styled';
import { HelpInfoLink } from '../../HelpContentRouter';
import { FormikProps } from 'formik';

const FormContainer = styled('div')`
  max-width: 800px;
  min-width: 280px;
`;

interface Props extends FormikProps<any> {
  children: ReactNode;
  title: string;
  helpId: string;
  description: string;
}

const Content = ({ props }): ReactElement => {
  return <FormContainer>{props.children}</FormContainer>;
};

export const JobCreateSummary = ({ title, helpId, description, ...props }: Props): Wizard.Step => {
  return {
    title: title,
    info: (): ReactElement => <HelpInfoLink helpId={helpId} />,
    description: description,
    isOptional: true,
    content: (): ReactElement => <Content props={props} />,
  };
};
