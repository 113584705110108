import React, { ReactElement } from 'react';
import {
  Checkbox,
  ColumnLayout,
  FormField,
  FormSection,
  Select,
} from '@amzn/awsui-components-react/polaris';
import { T } from '../../components/T';
import I18n from '../../../setupI18n';
import styled from '@emotion/styled';

const TopLevelCheckboxText = styled('span')`
  font-size: 1.4rem;
  color: var(--awsui-color-text-form-label);
`;
export const TranslationMemoryMatchingSettingsConfiguration = ({
  values,
  handleChange,
  handleBlur,
}): ReactElement => {
  return (
    <FormSection header={I18n.t('Translation memory match context and optimization')}>
      <ColumnLayout>
        <div data-awsui-column-layout-root="true">
          <FormField label={I18n.t('Context type')}>
            <Select
              options={[
                { label: I18n.t('Automatic'), id: 'AUTO' },
                { label: I18n.t('Previous and next segment'), id: 'PREV_AND_NEXT_SEGMENT' },
                { label: I18n.t('Segment key'), id: 'SEGMENT_KEY' },
                { label: I18n.t('No context'), id: 'NO_CONTEXT' },
              ]}
              selectedId={values.contextType}
              controlId="contextType"
              id="contextType"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormField>
          <T>
            <h4>Translation memory matching optimization</h4>
          </T>
          <FormField>
            <Checkbox
              description={
                <TopLevelCheckboxText>
                  {I18n.t('Previous OR next segment context as 101%')}
                </TopLevelCheckboxText>
              }
              id="previousOrNextSegmentContextAs101"
              controlId="previousOrNextSegmentContextAs101"
              checked={values.previousOrNextSegmentContextAs101}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormField>
          <FormField>
            <Checkbox
              description={
                <TopLevelCheckboxText>
                  {I18n.t('Penalize multiple 101% translation memory matches by 1%')}
                </TopLevelCheckboxText>
              }
              id="penalizeMultiple101TMMatchesBy1"
              controlId="penalizeMultiple101TMMatchesBy1"
              checked={values.penalizeMultiple101TMMatchesBy1}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormField>
        </div>
      </ColumnLayout>
    </FormSection>
  );
};
