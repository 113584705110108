import React, { ReactElement, useLayoutEffect, useReducer } from 'react';
import {
  Modal,
  Button,
  FormField,
  Textarea,
  ColumnLayout,
} from '@amzn/awsui-components-react/polaris';
import I18n from '../../../setupI18n';

export interface Props {
  visible: boolean;
  onClose: (event) => void;
  onUploadReference: (file?: File, note?: string) => void;
  isUploadingReferences: boolean;
  isInProjectTemplateCreationEditPage: boolean; // whether in project/template creation/edit pages
}

interface ReducerState {
  file?: File;
  fileValue: string;
  note?: string;
  isReferenceMissing: boolean;
  referenceMissingError?: object;
}

export const AddReference = ({
  visible,
  onClose,
  onUploadReference,
  isUploadingReferences,
  isInProjectTemplateCreationEditPage,
}: Props): ReactElement => {
  const init = (): ReducerState => ({
    isReferenceMissing: false,
    fileValue: '',
  });
  const reducer = (state, action): ReducerState => {
    switch (action.type) {
      case 'reset':
        return {
          ...state,
          file: undefined,
          fileValue: '',
          note: undefined,
          isReferenceMissing: false,
        };
      case 'updateFile':
        return {
          ...state,
          file: action.file,
          fileValue: action.fileValue,
          isReferenceMissing: false,
        };
      case 'updateNote':
        return {
          ...state,
          note: action.note,
          isReferenceMissing: action.note.length === 0 && !state.file ? true : false,
        };
      case 'validateUploadFailed':
        return {
          ...state,
          isReferenceMissing: true,
          referenceMissingError: action.referenceMissingError,
        };
      default:
        return state;
    }
  };
  const [reducerState, dispatch] = useReducer(reducer, null, init);
  const { file, fileValue, note, isReferenceMissing, referenceMissingError } = reducerState;

  useLayoutEffect(() => {
    if (visible) {
      dispatch({ type: 'reset' });
    }
  }, [visible]);

  /******************* Functions **********************/
  const isUploadValidate = (): boolean => {
    if (file || note) {
      return true;
    } else {
      dispatch({
        type: 'validateUploadFailed',
        referenceMissingError: 'Either file or note is required, both are optional.',
      });
      return false;
    }
  };

  /******************* Event Handlers **********************/
  const onFileChange = (event): void => {
    dispatch({
      type: 'updateFile',
      file: event.target.files[0],
      fileValue: event.target.value,
    });
  };

  const onNoteChange = (event): void => {
    dispatch({
      type: 'updateNote',
      note: event.detail.value,
    });
  };

  /******************* Components **********************/
  return (
    <Modal
      id="addReferenceModal"
      visible={visible}
      header={I18n.t('Add reference')}
      size={'large'}
      onDismiss={onClose}
      footer={
        <span className="awsui-util-f-r">
          <Button
            id="addReferenceCancelBtn"
            variant="link"
            text={I18n.t('Cancel')}
            onClick={onClose}
          />
          <Button
            id="addReferenceUploadBtn"
            loading={isUploadingReferences}
            variant="primary"
            text={isInProjectTemplateCreationEditPage ? I18n.t('Confirm') : I18n.t('Upload')}
            onClick={(event): void => {
              if (isInProjectTemplateCreationEditPage) {
                event.preventDefault();
              }
              if (isUploadValidate()) {
                onUploadReference(file, note);
              }
            }}
          />
        </span>
      }
    >
      <ColumnLayout>
        <div data-awsui-column-layout-root="true">
          <FormField
            label="File"
            id="fileForm"
            errorText={isReferenceMissing ? referenceMissingError : ''}
          >
            <input type="file" id="file" value={fileValue} onChange={onFileChange} />
          </FormField>
          <FormField
            label="Note"
            id="noteForm"
            errorText={isReferenceMissing ? referenceMissingError : ''}
          >
            <Textarea id="referenceNote" value={note ?? ''} onChange={onNoteChange} />
          </FormField>
        </div>
      </ColumnLayout>
    </Modal>
  );
};
