import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { parse } from 'query-string';
import { AtmsApiClient } from '@amzn/et-console-components';
import { Flashbar, Spinner } from '@amzn/awsui-components-react-v3';
import { TextContent } from '@amzn/awsui-components-react-v3/polaris';

const checkHealth = ({ envToCheck, setIsLoading, setError, setStatus }): void => {
  setIsLoading(true);
  AtmsApiClient.httpGet(`https://web.${envToCheck}.atms.a2z.com/web/healthApi`)
    .then(resp => {
      setStatus(resp.status);
    })
    .catch(e => {
      setError(e.details?.errors?.[0]?.message || 'checking health failed');
    })
    .finally(() => setIsLoading(false));
};

export const HealthChecker = withRouter(({ location: { pathname, search } }) => {
  const [envToCheck, setEnvToCheck] = useState(parse(search).env ?? 'beta2');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [status, setStatus] = useState('');

  useEffect(() => {
    setEnvToCheck(parse(search).env ?? 'beta2');
  }, [pathname, search]);

  useEffect(() => {
    checkHealth({ envToCheck, setIsLoading, setError, setStatus });
  }, [envToCheck]);

  return (
    <div>
      {error !== '' ? (
        <Flashbar
          items={[
            {
              header: 'Error',
              type: 'error',
              content: error,
              dismissible: true,
            },
          ]}
        />
      ) : (
        <TextContent>
          <p>{isLoading ? <Spinner /> : `${envToCheck}.atms.a2z.com is in ${status} state`}</p>
        </TextContent>
      )}
    </div>
  );
});
