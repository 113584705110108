import { useState, useLayoutEffect } from 'react';

export default query => {
  const [match, setMatch] = useState(false);

  useLayoutEffect(() => {
    const mql = window.matchMedia(query);
    setMatch(mql.matches);
    const updateMatch = e => setMatch(e.matches);
    mql.addListener(updateMatch);
    return () => mql.removeListener(updateMatch);
  }, [query]);

  return match;
};
