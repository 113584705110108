import { FormikProps } from 'formik';
import * as yup from 'yup';
import React, { ReactElement, ReactNode, useMemo, useReducer } from 'react';
import I18n from '../../../setupI18n';
import { coalesceSummaryValue, WizardStepWithSummary } from '../../components/WizardWithSummary';
import { HelpInfoLink } from '../../HelpContentRouter';
import styled from '@emotion/styled';
import {
  Checkbox,
  ColumnLayout,
  Container,
  Form,
  FormField,
  Header,
  Flashbar,
  SpaceBetween,
  Icon,
} from '@amzn/awsui-components-react-v3';
import { css } from 'emotion';
import { DateTime } from 'luxon';
import { Language, Project } from '../../types/commonTypes';
import { formatLocaleCode } from '@amzn/et-console-components';
import { OptionDefinition } from '@amzn/awsui-components-react-v3/polaris/internal/components/option/interfaces';
import { Multiselect } from '@amzn/awsui-components-react-v3/polaris';

const formSpacing = css`
  padding-top: 14px;
  padding-bottom: 14px;
`;

const FormContainer = styled('div')`
  max-width: 800px;
  min-width: 280px;
  margin-bottom: 25px;
`;

const SummaryFormContainer = styled('div')`
  margin-bottom: 20px;
`;

const FormFieldDescriptionIndentation = styled('span')`
  padding-left: 22px;
`;

const title = I18n.t('Job details');

const SummaryTable = styled('table')`
  border-collapse: collapse;
  text-align: left;
`;

const SummaryTableTR = styled('tr')`
  border-bottom: 1px solid #d3d3d3;
`;

export interface JobCreateAPIStep1 {
  targetLocales?: string[];
  dueDate?: string;
  dueTime?: string;
  dueDateTime?: DateTime;
  preTranslate: boolean;
  atmsFiles?: File[];
  notifyLinguistsOfJobCreation: boolean;
  notifyLinguistsEmailTemplate?: OptionDefinition;
  notifyLinguistsInterval?: OptionDefinition;
}

interface ReducerState {
  isError: boolean;
  error?: string;
}

export const jobCreateSchemaStep1 = yup.object().shape({
  atmsFiles: yup
    .mixed()
    .required(I18n.t('At least one file is required'))
    .test('fileLength', I18n.t('At least one file is required'), value =>
      value ? value.length > 0 : false
    ),
  targetLocales: yup.array().required(I18n.t('At least one target locale is required')),
});

export interface Props<T extends JobCreateAPIStep1> extends FormikProps<T> {
  validating: boolean;
  isLoadingActiveLanguages: boolean;
  projectTargetLocales?: string[];
  activeLanguages: Language[];
}

export const Content = <T extends JobCreateAPIStep1>({
  errors,
  validating,
  values,
  setFieldValue,
  handleChange,
  handleBlur,
  isLoadingActiveLanguages,
  projectTargetLocales,
  activeLanguages,
}: Props<T>): ReactElement => {
  const init = (): ReducerState => ({
    isError: false,
  });
  const reducer = (state, action): ReducerState => {
    switch (action.type) {
      default:
        return state;
    }
  };

  const [reducerState] = useReducer(reducer, null, init);
  const { isError } = reducerState;

  /********************* Functions ************************/
  const activeLanguagesInProjectOptions = useMemo(
    () =>
      activeLanguages
        .filter(l => projectTargetLocales?.includes(l.code))
        .map(lang => {
          return {
            value: lang.code,
            label: lang.name,
            labelTag: formatLocaleCode(lang.code),
          };
        }),
    [projectTargetLocales, activeLanguages]
  );
  const selectedTargetLanguagesOptions = useMemo(
    () => activeLanguagesInProjectOptions.filter(o => values.targetLocales?.includes(o.value)),
    [activeLanguagesInProjectOptions, values.targetLocales]
  );

  /********************* Components ************************/
  return (
    <FormContainer id={'JobCreateStep1'}>
      <Form
        errorText={
          validating && Object.keys(errors).length !== 0
            ? I18n.t('The form contains errors. Fix them and resubmit.')
            : ''
        }
      >
        <ColumnLayout>
          <Container header={<Header variant="h2">Files</Header>}>
            <SpaceBetween size="l">
              <FormField
                id="atmsFilesField"
                label={I18n.t('Upload files')}
                errorText={validating && errors.atmsFiles}
                description={I18n.t(
                  'Upload files to ATMS. Each file will create one ATMS job per target locale.'
                )}
              >
                {/* TODO: Replace with AWS-UI component when available: https://polaris.corp.amazon.com/system/structures/fileupload/ */}
                <input
                  type="file"
                  id="atmsFiles"
                  name="atmsFiles"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  multiple
                />
              </FormField>
            </SpaceBetween>
          </Container>
          <Container header={<Header variant="h2">Job settings</Header>}>
            <SpaceBetween size="l">
              <FormField
                id="targetLocalesField"
                label={I18n.t('Target locale(s)')}
                description={I18n.t('Languages the content will be translated into')}
                errorText={validating && errors['targetLocales']}
              >
                <Multiselect
                  statusType={isLoadingActiveLanguages ? 'loading' : 'finished'}
                  filteringType="auto"
                  options={activeLanguagesInProjectOptions}
                  selectedOptions={selectedTargetLanguagesOptions}
                  onChange={(e): void =>
                    setFieldValue(
                      'targetLocales',
                      e.detail.selectedOptions.map(o => o.value)
                    )
                  }
                  onBlur={handleBlur}
                  controlId="targetLocales"
                  id="targetLocales"
                />
              </FormField>
              <FormField
                label={
                  <Checkbox
                    id="preTranslate"
                    controlId="preTranslate"
                    checked={values.preTranslate}
                    onChange={(e): void => {
                      setFieldValue('preTranslate', e.detail.checked);
                    }}
                  >
                    {I18n.t('Pre-translate')}
                  </Checkbox>
                }
                description={
                  <FormFieldDescriptionIndentation>
                    {I18n.t(
                      "Pre-translate using translation memory and/or machine translation based on the project's pre-translate settings"
                    )}
                  </FormFieldDescriptionIndentation>
                }
              ></FormField>
            </SpaceBetween>
          </Container>
        </ColumnLayout>
        {isError && (
          <div className={formSpacing}>
            <Flashbar
              id="validation-error"
              items={[
                {
                  type: 'error',
                  dismissible: true,
                  content: I18n.t('Please try again. If the issue persists, contact support.'),
                  header: I18n.t('Something went wrong.'),
                },
              ]}
            />
          </div>
        )}
      </Form>
    </FormContainer>
  );
};

const Summary = <T extends JobCreateAPIStep1>({ values }: Props<T>): ReactElement => {
  const summaryElements = [
    //Add file name to summary
    ...Array.from(values.atmsFiles ?? []).map(file =>
      coalesceSummaryValue({
        value: file.name,
        label: I18n.t('File'),
      })
    ),
    //Add due date
    coalesceSummaryValue({
      value: values.dueDateTime?.toFormat('DD t'),
      label: I18n.t('Due'),
    }),
    //Add pre-translate settings
    coalesceSummaryValue({
      value: values.preTranslate,
      label: I18n.t('Pre-translate'),
      displayValue: values.preTranslate ? (
        <Icon name={'status-positive'} />
      ) : (
        <Icon name={'status-negative'} />
      ),
    }),
    //Add notification settings
    coalesceSummaryValue({
      value: values.notifyLinguistsOfJobCreation,
      label: I18n.t('Notify linguists of job creation'),
      displayValue: values.notifyLinguistsOfJobCreation ? (
        <SummaryTable>
          <tbody>
            <SummaryTableTR>
              <th>Email template</th>
              <th>Interval</th>
            </SummaryTableTR>
            <SummaryTableTR>
              <td>{values.notifyLinguistsEmailTemplate?.value}</td>
              <td>{values.notifyLinguistsInterval?.value}</td>
            </SummaryTableTR>
          </tbody>
        </SummaryTable>
      ) : (
        <SummaryTable>
          <tbody>-</tbody>
        </SummaryTable>
      ),
    }),
  ];

  return (
    <SummaryFormContainer>
      <Container header={<Header variant={'h2'}>{title}</Header>} key={title}>
        <ColumnLayout columns={4}>{summaryElements}</ColumnLayout>
      </Container>
    </SummaryFormContainer>
  );
};

export const jobCreateInitialFormValuesStep1 = (project?: Project): JobCreateAPIStep1 => {
  return {
    targetLocales: project?.targetLocales ?? [],
    notifyLinguistsOfJobCreation: false,
    preTranslate: project?.preTranslateSettings?.preTranslateOnJobCreation ?? false,
  };
};

export const JobCreateStep1UploadFiles = <T extends JobCreateAPIStep1>(
  props: Props<T>
): WizardStepWithSummary => {
  return {
    title: title,
    info: (): ReactElement => <HelpInfoLink helpId={''} />,
    description: I18n.t('Upload files and set translation settings'),
    content: (): ReactElement => <Content {...props} />,
    summary: (): ReactNode => <Summary {...props} />,
  };
};
