import React, { ReactElement, ReactNode } from 'react';
import { Project, ProjectTemplate } from '../types/commonTypes';
import { Button, Wizard } from '@amzn/awsui-components-react/polaris';
import I18n from '../../setupI18n';
import { TermBaseAssignment } from '../project/termbases/TermBaseCommons';
import { TranslationMemoryModel } from '../project/translationmemory/TranslationMemoryCommon';
import { Reference } from '../project/references/ReferenceCommons';

export interface SummaryInput {
  currentValue?: Project | ProjectTemplate;
  currentTermBaseAssignments?: TermBaseAssignment[];
  currentTranslationMemoryAssignments?: TranslationMemoryModel[];
  currentReferences?: Reference[];
}

export interface WizardStepWithSummary extends Wizard.Step {
  summary: (input: SummaryInput) => React.ReactNode;
}

export interface DisplayProps {
  key?: string;
  label?: string;
  value: ReactNode;
}
export interface CoalesceProps {
  key?: string;
  label?: string;
  displayValue?: ReactNode;
  currentValue?: unknown;
  value?: unknown;
  hideIfUnchanged?: boolean;
}
export const coalesceSummaryValue = ({
  key,
  label,
  currentValue,
  value,
  displayValue,
  hideIfUnchanged = true,
}: CoalesceProps): ReactNode => {
  if ((currentValue === value || (currentValue == null && value == null)) && hideIfUnchanged) {
    return;
  }

  return displaySummaryValue({
    key,
    label,
    value: displayValue ?? (value as string) ?? '-',
  });
};

export const displaySummaryValue = ({ key, label, value }: DisplayProps): ReactNode => (
  <div key={key ?? label}>
    <div className="awsui-util-label">{label}</div>
    <div>{value}</div>
  </div>
);

interface Props extends Wizard.Props {
  additionalButtons?: ReactNode[];
  isSubmitting?: boolean;
}
export const WizardWithSummary = ({
  steps,
  additionalButtons,
  activeStepIndex,
  isSubmitting,
  onStepNavigationClick,
  onPreviousButtonClick,
  onNextButtonClick,
  onCancelButtonClick,
  onSubmitButtonClick,
  i18nStrings,
}: Props): ReactElement => {
  const {
    nextButton = I18n.t('Next'),
    previousButton = I18n.t('Previous'),
    submitButton = I18n.t('Submit'),
    cancelButton = I18n.t('Cancel'),
    stepNumberLabel = (stepNum): string => I18n.t('Step %{stepNum}', { stepNum: stepNum }),
    collapsedStepsLabel = (stepNum, totalSteps): string =>
      I18n.t('Step %{stepNum} of %{totalSteps}', { stepNum: stepNum, totalSteps: totalSteps }),
    optional = I18n.t('Optional'),
  } = i18nStrings ?? {};

  const resolvedActiveStepIndex = activeStepIndex ?? 0;

  const onStepNavigationClickDispatcher = (index: number) => (): void => {
    if (onStepNavigationClick) {
      onStepNavigationClick(
        new CustomEvent<Wizard.StepClickDetail>('awsui:stepNavigationClick', {
          detail: { requestedStepIndex: index },
          bubbles: true,
          cancelable: true,
        })
      );
    }
  };

  const onNextButtonClickDispatcher = (): void => {
    if (onPreviousButtonClick) {
      onPreviousButtonClick(
        new CustomEvent<Wizard.StepClickDetail>('awsui:nextButtonClick', {
          detail: {
            requestedStepIndex: Math.min((steps?.length ?? 1) - 1, resolvedActiveStepIndex + 1),
          },
          bubbles: true,
          cancelable: true,
        })
      );
    }
  };

  const onPreviousButtonClickDispatcher = (): void => {
    if (onNextButtonClick) {
      onNextButtonClick(
        new CustomEvent<Wizard.StepClickDetail>('awsui:previousButtonClick', {
          detail: { requestedStepIndex: Math.max(0, resolvedActiveStepIndex - 1) },
          bubbles: true,
          cancelable: true,
        })
      );
    }
  };

  const step = steps?.[resolvedActiveStepIndex ?? 0];

  return React.createElement(
    'awsui-wizard',
    undefined,
    <div className="awsui-wizard">
      <nav className="awsui-util-pt-xs awsui-wizard__column-navigation">
        <ul className="awsui-list-unstyled awsui-wizard__navigation-links">
          {steps?.map((step, index) => (
            <li key={index} className="awsui-wizard__navigation-link-item">
              <small>
                {stepNumberLabel(index + 1)}
                {step.isOptional && <i> - {optional}</i>}
              </small>
              <div>
                {resolvedActiveStepIndex === index ? (
                  <span
                    className="awsui-wizard__navigation-link awsui-wizard__navigation-link--active"
                    aria-current="step"
                    aria-disabled="true"
                  >
                    {step.title}
                  </span>
                ) : (
                  <a
                    href="#"
                    className="awsui-wizard__navigation-link"
                    onClick={onStepNavigationClickDispatcher(index)}
                  >
                    {step.title}
                  </a>
                )}
              </div>
            </li>
          ))}
        </ul>
      </nav>
      <div className="awsui-wizard__column-form">
        <div className="awsui-form">
          <div className="awsui-form-header">
            <div className="awsui-form-title" awsui-form-region="header">
              <span>
                <div>
                  <div className="awsui-wizard__step-number">
                    <p className="awsui-text-secondary">
                      {collapsedStepsLabel(resolvedActiveStepIndex + 1, (steps?.length ?? 0) + 1)}
                    </p>
                  </div>
                  <h1 className="awsui-util-d-i">{step?.title}</h1>
                  <span className="awsui-wizard__info">
                    <span>
                      {typeof step?.info === 'function' && step.info()}
                      {step && typeof step?.info !== 'function' && step.info}
                    </span>
                  </span>
                </div>
              </span>
            </div>
            <div className="awsui-form-description" awsui-form-region="description">
              <span>
                <span>
                  <span>{step?.description}</span>
                </span>
              </span>
            </div>
          </div>
          <div className="awsui-form-content">{step?.content()}</div>
          <div className="awsui-form-actions">
            <span>
              <div>
                <Button
                  formAction="none"
                  onClick={onCancelButtonClick}
                  variant="link"
                  className="awsui-wizard__cancel-button"
                >
                  {cancelButton}
                </Button>
                {additionalButtons}
                {resolvedActiveStepIndex > 0 && (
                  <Button formAction="none" onClick={onPreviousButtonClickDispatcher}>
                    {previousButton}
                  </Button>
                )}
                {resolvedActiveStepIndex < (steps?.length ?? 1) - 1 && (
                  <Button
                    formAction="none"
                    variant="primary"
                    className="awsui-wizard__primary-button"
                    onClick={onNextButtonClickDispatcher}
                  >
                    {nextButton}
                  </Button>
                )}
                {resolvedActiveStepIndex === (steps?.length ?? 1) - 1 && (
                  <Button
                    formAction="none"
                    variant="primary"
                    className="awsui-wizard__primary-button"
                    onClick={onSubmitButtonClick}
                    loading={isSubmitting ?? false}
                  >
                    {submitButton}
                  </Button>
                )}
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
