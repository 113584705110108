import { useEffect, useState } from 'react';
import { AtmsApiClient } from '@amzn/et-console-components';
import { WorkflowStep } from '../types/commonTypes';
import { getAppHostConfig } from '@amzn/et-console-components';

const { WEB_HOST_AND_PORT } = getAppHostConfig();

interface State {
  isLoadingWorkflowSteps: boolean;
  workflowSteps: WorkflowStep[];
  workflowStepsById: { [id: number]: WorkflowStep };
}

// TODO: Needs to reload when the user profile changes
export const useWorkflowSteps = (): State => {
  const [state, setState] = useState<State>({
    isLoadingWorkflowSteps: true,
    workflowSteps: [],
    workflowStepsById: {},
  });
  useEffect(() => {
    AtmsApiClient.httpGet(`//${WEB_HOST_AND_PORT}/web/api/v9/workflowStep/list`).then(result => {
      setState({
        isLoadingWorkflowSteps: false,
        workflowSteps: result,
        workflowStepsById: result.reduce((acc, val) => {
          acc[val.id] = val;
          return acc;
        }, {}),
      });
    });
  }, []);

  return state;
};
