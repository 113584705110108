import React, { FunctionComponent, ReactElement } from 'react';
import 'tippy.js/dist/tippy.css';

import { Icon, Spinner } from '@amzn/awsui-components-react/polaris';
import { Group } from '../types/commonTypes';
import { Tooltip } from '../components/Tooltip';
import I18n from '../../setupI18n';

type AggregateStatus =
  | 'NEW'
  | 'IN_PROGRESS'
  | 'IN_PROGRESS_PENDING_FAILURE'
  | 'SUCCEEDED'
  | 'FAILED';

const getTooltipContent = (status: AggregateStatus, groups: Array<Group>): ReactElement => {
  const succeeded = groups.reduce((sum, { jobCounts: { succeeded } }) => sum + succeeded, 0);
  const failed = groups.reduce((sum, { jobCounts: { failed } }) => sum + failed, 0);
  const pending = groups.reduce((sum, { jobCounts: { pending } }) => sum + pending, 0);
  const scoringModel = groups.length > 1 ? I18n.t('Multiple') : groups[0].scoringModel.name;

  return (
    <div>
      {I18n.t('Overall status: %{overallStatus}', { overallStatus: getStatusText(status) })}
      <br />
      {I18n.t('# remaining: %{pending}', { pending })}
      <br />
      {I18n.t('# passed: %{succeeded}', { succeeded })}
      <br />
      {I18n.t('# failed: %{failed}', { failed })}
      <br />
      {I18n.t('Scoring model: %{scoringModel}', { scoringModel })}
    </div>
  );
};

const hasntStarted = ({ jobCounts: { total, pending } }: Group): boolean => total === pending;
const hasFinished = ({ jobCounts: { pending } }: Group): boolean => pending === 0;
const hasFailures = ({ jobCounts: { failed } }: Group): boolean => failed > 0;

const getEffectiveStatus = (groups: Array<Group>): AggregateStatus => {
  if (groups.every(hasFinished)) {
    return groups.some(hasFailures) ? 'FAILED' : 'SUCCEEDED';
  } else if (groups.every(hasntStarted)) {
    return 'NEW';
  } else {
    return groups.some(hasFailures) ? 'IN_PROGRESS_PENDING_FAILURE' : 'IN_PROGRESS';
  }
};

const getStatusText = (status: AggregateStatus): string => {
  switch (status) {
    case 'NEW':
      return I18n.t('Requested');
    case 'IN_PROGRESS':
    case 'IN_PROGRESS_PENDING_FAILURE':
      return I18n.t('In progress');
    case 'SUCCEEDED':
    case 'FAILED':
      return I18n.t('Done');
  }
};

const getStatusIcon = (status: AggregateStatus): ReactElement => {
  switch (status) {
    case 'NEW':
      return <Icon name="status-pending" variant="subtle" />;
    case 'IN_PROGRESS':
      return <Icon name="status-in-progress" variant="subtle" />;
    case 'IN_PROGRESS_PENDING_FAILURE':
      return <Icon name="status-warning" variant="error" />;
    case 'SUCCEEDED':
      return <Icon name="status-positive" variant="success" />;
    case 'FAILED':
      return <Icon name="status-negative" variant="error" />;
  }
};

interface Props {
  scoringStatus: 'loading' | 'loaded' | 'error';
  groups?: Group[];
}

const ProjectScoringStatus: FunctionComponent<Props> = ({ scoringStatus, groups }: Props) => {
  if (scoringStatus === 'loading') {
    return <Spinner />;
  } else if (scoringStatus === 'error' || !groups || !groups.length) {
    return <span>-</span>;
  }

  const status = getEffectiveStatus(groups);
  return (
    <Tooltip content={getTooltipContent(status, groups)}>
      <span style={{ position: 'relative' }}>{getStatusIcon(status)}</span>
    </Tooltip>
  );
};

export default ProjectScoringStatus;
