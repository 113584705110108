import * as React from 'react';
import { ReactElement, useReducer, useState } from 'react';
import { withRouter } from 'react-router';
import { PolarisFormik } from '@amzn/et-polaris-utils';
import * as yup from 'yup';
import {
  Button,
  Checkbox,
  ColumnLayout,
  CustomDetailEvent,
  Flash,
  Form,
  FormField,
  FormSection,
  Input,
  RadioGroup,
  Select,
} from '@amzn/awsui-components-react/polaris';
import { css } from 'emotion';
import { AtmsApiClient } from '@amzn/et-console-components';
import styled from '@emotion/styled';
import { PageHeader } from '@amzn/et-polaris-utils';
import { CREATE_ANALYSIS_HELP } from './projectHelpContent';
import { HelpInfoLink } from '../HelpContentRouter';
import I18n from '../../setupI18n';
import { AnalysisType, SelectAllOnServerApi, WorkflowStep } from '../types/commonTypes';
import { History, Location } from 'history';
import { publishCountMetric } from '../metricHelper';

const formSpacing = css`
  padding-top: 14px;
  padding-bottom: 14px;
`;

const FormContainer = styled('div')`
  max-width: 800px;
  min-width: 280px;
`;

export interface CreateAnalysisWorkflowLocationState extends SelectAllOnServerApi {
  workflowSteps?: WorkflowStep[];
}

export interface Props {
  history: History;
  location: Location<CreateAnalysisWorkflowLocationState>;
}

export interface ReducerState {
  isLoading: boolean;
  isError: boolean;
  error?: object;
  isCreating: boolean;
  type: AnalysisType;
  name: string;
  compareWorkflowLevel?: number;
  includeFuzzyRepetitions: boolean;
  excludeConfirmedSegments: boolean;
  excludeLockedSegments: boolean;
  excludeNumbers: boolean;
  analyzeByLinguist: boolean;
  countSourceUnits: boolean;
}

export const CreateAnalysisWorkflow = withRouter(
  ({ location: { pathname, state }, history }: Props): ReactElement => {
    // TODO: We should be able to get this from the Router.match.params prop
    const projectUID = pathname.substr(pathname.lastIndexOf('/') + 1);

    const init = (): ReducerState => ({
      isLoading: false,
      isError: false,
      isCreating: false,
      type: 'PreAnalyse',
      name: '',
      includeFuzzyRepetitions: true,
      excludeConfirmedSegments: false,
      excludeLockedSegments: false,
      excludeNumbers: false,
      analyzeByLinguist: false,
      countSourceUnits: true,
    });

    const reducer = (state, action): ReducerState => {
      switch (action.type) {
        case 'creatingAnalysis':
          return {
            ...state,
            isCreating: true,
            isError: false,
          };
        case 'createdAnalysis':
          return {
            ...state,
            isCreating: false,
          };
        case 'createAnalysisFailed':
          return {
            ...state,
            isCreating: false,
            isError: true,
            error: action.error,
          };
        default:
          return state;
      }
    };

    const [reducerState, dispatch] = useReducer(reducer, null, init);
    const [validating, setValidating] = useState(false);
    const { isCreating, isError } = reducerState;

    /**
     * Per AWS Specifications, only start validating after the initial submit.
     * @param e the event
     * @param handleSubmit formik submit event handler function.
     */
    const enableValidation = (e, handleSubmit): void => {
      e.preventDefault();
      setValidating(true);
      handleSubmit();
    };

    const handleSubmit = (values): void => {
      const req = {
        projectUid: projectUID,
        jobPartIds: state.jobPartIds,
        selectAllOnServer: state.selectAllOnServer,
        level: state.level,
        assignedTo: state.assignedTo,
        status: state.status,
        fileName: state.fileName,
        targetLocale: state.targetLocale,
        dueInHours: state.dueInHours,
        type: values.type,
        name: values.name.length > 0 ? values.name : undefined,
        compareWorkflowLevel: values.compareWorkflowLevel,
        includeFuzzyRepetitions: values.includeFuzzyRepetitions,
        includeConfirmedSegments: !values.excludeConfirmedSegments,
        includeLockedSegments: !values.excludeLockedSegments,
        includeNumbers: !values.excludeNumbers,
        analyzeByLinguist: values.analyzeByLinguist,
        countSourceUnits: values.countSourceUnits === 'true',
      };

      dispatch({
        type: 'creatingAnalysis',
      });
      AtmsApiClient.httpPost('/api/analysis/create', req)
        .then(() => {
          dispatch({ type: 'createdAnalysis' });
          history.push(`/web/project2/show/${projectUID}`);
        })
        .catch(e => {
          publishCountMetric('createAnalysisFailed-CreateAnalysisWorkflow', 'error', e.message);
          dispatch({ type: 'createAnalysisFailed', error: e });
        });
    };

    const renderForm = ({
      values,
      errors,
      handleSubmit,
      handleChange,
      handleBlur,
    }): ReactElement => {
      const handleCountUnitsOfChange = (e: CustomDetailEvent<RadioGroup.ChangeDetail>): void => {
        handleChange({
          target: { component: { __properties: { name: 'countSourceUnits' } } },
          detail: { value: e.detail.value === 'true' },
        });
      };

      return (
        <FormContainer>
          <form onSubmit={e => enableValidation(e, handleSubmit)}>
            <Form
              header={
                <PageHeader
                  title={I18n.t('Create analysis')}
                  extraContent={<HelpInfoLink helpId={CREATE_ANALYSIS_HELP} />}
                />
              }
              actions={
                <div>
                  <Button
                    id="create-analysis"
                    text={I18n.t('Create analysis')}
                    variant="primary"
                    onClick={e => enableValidation(e, handleSubmit)}
                    loading={isCreating}
                  />
                </div>
              }
              errorText={
                Object.keys(errors).length !== 0
                  ? I18n.t('The form contains errors. Fix them and resubmit.')
                  : ''
              }
            >
              <FormSection>
                <ColumnLayout>
                  <div data-awsui-column-layout-root="true">
                    <FormField label={I18n.t('Type')}>
                      <Select
                        id="type"
                        controlId="type"
                        options={[
                          {
                            id: 'PreAnalyse',
                            label: I18n.t('Default'),
                          },
                          {
                            id: 'Compare',
                            label: I18n.t('Compare'),
                            disabled: !state.workflowSteps,
                          },
                        ]}
                        selectedId={values.type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormField>
                    {state.workflowSteps && values.type === 'Compare' && (
                      <FormField
                        label={I18n.t('Compare with workflow step')}
                        errorText={errors.type ? I18n.t('Compare with is required.') : ''}
                      >
                        <Select
                          id="compareWorkflowLevel"
                          controlId="compareWorkflowLevel"
                          options={state.workflowSteps.map(step => {
                            return {
                              id: '' + step.level,
                              label: step.name,
                              disabled: state.level === step.level,
                            };
                          })}
                          selectedId={values.compareWorkflowLevel}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </FormField>
                    )}
                    <FormField
                      label={I18n.t('Name')}
                      description={I18n.t('The name to give the analysis (optional).')}
                    >
                      <Input
                        name="name"
                        id="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormField>
                    <FormField>
                      <Checkbox
                        name="includeFuzzyRepetitions"
                        label={I18n.t('Include internal fuzzies')}
                        onChange={handleChange}
                        checked={values.includeFuzzyRepetitions}
                      />
                    </FormField>
                    <FormField>
                      <Checkbox
                        name="excludeConfirmedSegments"
                        label={I18n.t('Exclude confirmed segments')}
                        onChange={handleChange}
                        checked={values.excludeConfirmedSegments}
                      />
                    </FormField>
                    <FormField>
                      <Checkbox
                        name="excludeLockedSegments"
                        label={I18n.t('Exclude locked segments')}
                        onChange={handleChange}
                        checked={values.excludeLockedSegments}
                      />
                    </FormField>
                    <FormField>
                      <Checkbox
                        name="excludeNumbers"
                        label={I18n.t('Exclude numbers')}
                        onChange={handleChange}
                        checked={values.excludeNumbers}
                      />
                    </FormField>
                    <FormField>
                      <Checkbox
                        name="analyzeByLinguist"
                        label={I18n.t('Analyze by linguist')}
                        onChange={handleChange}
                        checked={values.analyzeByLinguist}
                        disabled={true}
                      />
                    </FormField>
                    <FormField label={I18n.t('Count units of')}>
                      <RadioGroup
                        onChange={handleCountUnitsOfChange}
                        value={'' + values.countSourceUnits}
                        items={[
                          { value: 'true', label: I18n.t('Source') },
                          { value: 'false', label: I18n.t('Target') },
                        ]}
                      />
                    </FormField>
                  </div>
                </ColumnLayout>
              </FormSection>
              {isError && (
                <div className={formSpacing}>
                  <Flash
                    id="submit-error"
                    type="error"
                    dismissible={true}
                    content={I18n.t(
                      'Please try again. If the issue persists, contact support. Error: %{e}',
                      { e: JSON.stringify(reducerState.error) }
                    )}
                    header={I18n.t('Something went wrong.')}
                  />
                </div>
              )}
            </Form>
          </form>
        </FormContainer>
      );
    };

    const initialFormValues = {
      type: reducerState.type,
      name: reducerState.name,
      compareWorkflowLevel: reducerState.compareWorkflowLevel,
      includeFuzzyRepetitions: reducerState.includeFuzzyRepetitions,
      excludeConfirmedSegments: reducerState.excludeConfirmedSegments,
      excludeLockedSegments: reducerState.excludeLockedSegments,
      excludeNumbers: reducerState.excludeNumbers,
      analyzeByLinguist: reducerState.analyzeByLinguist,
      countSourceUnits: reducerState.countSourceUnits ? 'true' : 'false',
    };

    const schema = yup.object().shape({
      type: yup.string().required(),
      name: yup.string(),
      compareWorkflowLevel: yup.number(),
      includeFuzzyRepetitions: yup.boolean(),
      excludeConfirmedSegments: yup.boolean(),
      excludeLockedSegments: yup.boolean(),
      excludeNumbers: yup.boolean(),
      analyzeByLinguist: yup.boolean(),
      countSourceUnits: yup.boolean(),
    });

    return (
      <PolarisFormik
        initialValues={initialFormValues}
        validationSchema={schema}
        validateOnChange={validating}
        onSubmit={handleSubmit}
        render={renderForm}
      />
    );
  }
);
