import { MediaType } from '../types/commonTypes';
import { useEffect, useState } from 'react';
import { AtmsApiClient } from '@amzn/et-console-components';
import { getAppHostConfig } from '@amzn/et-console-components';

const { WEB_HOST_AND_PORT } = getAppHostConfig();

interface State {
  isLoadingMediaTypes: boolean;
  mediaTypes: MediaType[];
  mediaTypesById: { [id: string]: MediaType };
}

// TODO: Needs to reload when the user profile changes
export const useMediaTypes = (): State => {
  const [state, setState] = useState<State>({
    isLoadingMediaTypes: true,
    mediaTypes: [],
    mediaTypesById: {},
  });
  useEffect(() => {
    AtmsApiClient.httpGet(`//${WEB_HOST_AND_PORT}/web/api/v9/mediaType/list`).then(result => {
      setState({
        isLoadingMediaTypes: false,
        mediaTypes: result,
        mediaTypesById: result.reduce((acc, val) => {
          acc[val.id] = val;
          return acc;
        }, {}),
      });
    });
  }, []);

  return state;
};
