import { Alert, Button, Spinner, Wizard } from '@amzn/awsui-components-react/polaris';
import { AtmsApiClient, AuthenticatedSession } from '@amzn/et-console-components';
import { PolarisFormik } from '@amzn/et-polaris-utils';
import { FormikProps } from 'formik';
import React, { ReactElement, useReducer, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import * as yup from 'yup';
import I18n from '../../setupI18n';
import { WizardStepWithSummary, WizardWithSummary } from '../components/WizardWithSummary';
import { publishCountMetric, publishLatencyMetric } from '../metricHelper';
import { useActiveLanguages } from '../resourceHooks/useActiveLanguages';
import { useBusinessUnits } from '../resourceHooks/useBusinessUnits';
import { useClients } from '../resourceHooks/useClients';
import { useCostCenters } from '../resourceHooks/useCostCenters';
import { useMachineTranslateSettings } from '../resourceHooks/useMachineTranslateSettings';
import { useMediaTypes } from '../resourceHooks/useMediaTypes';
import { useOwners } from '../resourceHooks/useOwners';
import { useProjectTemplate } from '../resourceHooks/useProjectTemplate';
import { useReferences } from '../resourceHooks/useReferences';
import { useScoringModels } from '../resourceHooks/useScoringModels';
import { useSegmentationRules } from '../resourceHooks/useSegmentationRules';
import { useSubjects } from '../resourceHooks/useSubjects';
import { useSupportedFileEncodings } from '../resourceHooks/useSupportedFileEncodings';
import { useSupportedFileFormats } from '../resourceHooks/useSupportedFileFormats';
import { useTermBases } from '../resourceHooks/useTermBases';
import { useTranslationMemories } from '../resourceHooks/useTranslationMemories';
import { useUsersAndVendorsForJobAssign } from '../resourceHooks/useUsersAndVendorsForJobAssign';
import { useWorkflowSteps } from '../resourceHooks/useWorkflowSteps';
import {
  JobCreateAPIStep2,
  jobCreateInitialFormValuesStep2,
  JobCreateStep2AssignJobs,
} from './jobs/JobCreateStep2AssignJobs';
import {
  JobCreateAPIStep3,
  jobCreateInitialFormValuesStep3,
  JobCreateStep3FileImporting,
} from './jobs/JobCreateStep3FileImporting';
import {
  initialFormValuesStep1,
  ProjectCreateAPIStep1,
  ProjectCreateStep1Metadata,
  schemaStep1,
} from './ProjectCreateStep1Metadata';
import {
  initialFormValuesStep2,
  ProjectCreateAPIStep2,
  ProjectCreateStep2Assets,
  schemaStep2,
} from './ProjectCreateStep2Assets';
import {
  initialFormValuesStep3,
  ProjectCreateAPIStep3,
  ProjectCreateStep3Pretranslate,
  schemaStep3,
} from './ProjectCreateStep3Pretranslate';
import {
  initialFormValuesStep4,
  ProjectCreateAPIStep4,
  ProjectCreateStep4Analysis,
  schemaStep4,
} from './ProjectCreateStep4Analysis';
import {
  initialFormValuesStep5,
  ProjectCreateAPIStep5,
  ProjectCreateStep5Quality,
  schemaStep5,
} from './ProjectCreateStep5Quality';
import {
  initialFormValuesStep6,
  ProjectCreateAPIStep6,
  ProjectCreateStep6AccessAndAutomation,
  schemaStep6,
} from './ProjectCreateStep6AccessAndAutomation';
import { ProjectCreateSummary } from './ProjectCreateSummary';
import { PROJECT_CREATE_REVIEW_AND_SAVE_HELP } from './projectHelpContent';
import { createReferences, deleteReferences } from './references/ReferenceCommons';
import { saveTBConfigs } from './termbases/TermBaseCommons';
import { saveTMConfig } from './translationmemory/TranslationMemoryCommon';

interface ReducerState {
  isSavingProjectTemplate: boolean;
  isError: boolean;
  error?: string;
  activeStep: number;
}

interface ProjectTemplateEditAPI
  extends ProjectCreateAPIStep1,
    ProjectCreateAPIStep2,
    ProjectCreateAPIStep3,
    ProjectCreateAPIStep4,
    ProjectCreateAPIStep5,
    ProjectCreateAPIStep6,
    JobCreateAPIStep2,
    JobCreateAPIStep3 {}

export interface Props extends RouteComponentProps {
  session: AuthenticatedSession;
}

export const ProjectTemplateEdit = withRouter(
  ({ history, location: { pathname }, session }: Props) => {
    // TODO: We should be able to get this from the Router.match.params prop
    const projectTemplateId = pathname.substr(pathname.lastIndexOf('/') + 1);

    const init = (): ReducerState => ({
      activeStep: 0,
      isSavingProjectTemplate: false,
      isError: false,
    });

    const reducer = (state, action): ReducerState => {
      switch (action.type) {
        case 'activeStepChange':
          return {
            ...state,
            activeStep: action.activeStep,
          };
        case 'savingProjectTemplate':
          return {
            ...state,
            isSavingProjectTemplate: true,
          };
        case 'projectTemplateSaved':
          return {
            ...state,
            isSavingProjectTemplate: false,
          };
        case 'projectTemplateSaveFailed':
          return {
            ...state,
            isSavingProjectTemplate: false,
            isError: true,
            error: action.error,
          };
        case 'clearError':
          return {
            ...state,
            isError: false,
            error: undefined,
          };
        default:
          return state;
      }
    };

    const [reducerState, dispatch] = useReducer(reducer, null, init);
    const { isSavingProjectTemplate, activeStep, isError, error } = reducerState;
    const [validating, setValidating] = useState(false);
    const { isLoadingProjectTemplate, projectTemplate } = useProjectTemplate(projectTemplateId);
    const { isLoadingWorkflowSteps, workflowSteps, workflowStepsById } = useWorkflowSteps();
    const { isLoadingClients, clients, clientsById } = useClients();
    const { isLoadingOwners, owners, ownersById } = useOwners();
    const { isLoadingCostCenters, costCenters, costCentersById } = useCostCenters();
    const { isLoadingBusinessUnits, businessUnits, businessUnitsById } = useBusinessUnits();
    const { isLoadingMediaTypes, mediaTypes, mediaTypesById } = useMediaTypes();
    const { isLoadingSubjects, subjects, subjectsById } = useSubjects();
    const {
      isLoadingActiveLanguages,
      activeLanguages,
      activeLanguagesByCode,
    } = useActiveLanguages();
    const { isLoadingScoringModels, scoringModels, scoringModelsById } = useScoringModels();
    const {
      isLoadingMachineTranslateSettings,
      machineTranslateSettings,
      machineTranslateSettingsById,
    } = useMachineTranslateSettings();
    const { isLoadingUsers, foundUsers } = useUsersAndVendorsForJobAssign();
    const { isLoadingSupportedFileFormats, supportedFileFormats } = useSupportedFileFormats();
    const { isLoadingSupportedFileEncodings, supportedFileEncodings } = useSupportedFileEncodings();
    const { isLoadingSegmentationRules, segmentationRules } = useSegmentationRules();
    const { termBaseAssignments } = useTermBases(false, projectTemplateId);
    const { translationMemoryAssignments } = useTranslationMemories(false, projectTemplateId);
    const { references } = useReferences(false, projectTemplateId);

    const handleSubmit = (values: ProjectTemplateEditAPI): void => {
      if (!projectTemplate || !values.targetLocales) {
        return;
      }
      dispatch({ type: 'savingProjectTemplate' });

      const {
        termBaseAssignments: newTermBaseAssignments,
        translationMemoryAssignments: newTranslationMemoryAssignments,
        references: newReferences,
        ...projectValues
      } = values;

      const request = {
        projectTemplateId: projectTemplateId,
        ...projectValues,
        name: (values.name ?? '').length === 0 ? values.projectTemplateName : values.name,
        note: values.note ? values.note : undefined, // It seems like the Textarea returns 'null' instead of 'undefined' for 'no value'
        perLocaleMachineTranslateSettingsIds: JSON.stringify(
          values.perLocaleMachineTranslateSettingsIds
        ),
        machineTranslateSettingsId: values.machineTranslateSettingsId,
        sourceSegmentationRuleId:
          values.sourceSegmentationRuleId?.toString() === 'undefined'
            ? undefined
            : values.sourceSegmentationRuleId,
        targetSegmentationRuleId:
          values.targetSegmentationRuleId?.toString() === 'undefined'
            ? undefined
            : values.targetSegmentationRuleId,
        targetLength: values.targetLength,
        targetLengthMax:
          values.targetLengthMax?.toString() === 'undefined' ? undefined : values.targetLengthMax,
        targetLengthPercent: values.targetLengthPercent,
        targetLengthPercentValue:
          values.targetLengthPercentValue?.toString() === 'undefined'
            ? undefined
            : values.targetLengthPercentValue,
        workflowStepIds: values.workflowStepIds.filter(id => workflowStepsById[id] !== undefined), // remove the deleted workflow step
      };

      const referencesToAdd = newReferences.filter(r => !r.id);
      const referencesToDelete = references?.filter(
        r => !newReferences.map(vr => vr.id).includes(r.id)
      );
      const initialTime = new Date();
      AtmsApiClient.httpPut(`/api/v2/projectTemplate`, request)
        .then(() => {
          Promise.all(
            saveTBConfigs(
              false,
              termBaseAssignments,
              newTermBaseAssignments,
              projectTemplateId,
              values.targetLocales ?? []
            )
              .concat(createReferences(false, referencesToAdd, projectTemplateId))
              .concat(deleteReferences(false, referencesToDelete))
              .concat(
                saveTMConfig(
                  false,
                  workflowSteps.filter(wfs => values.workflowStepIds.includes(wfs.id)),
                  translationMemoryAssignments,
                  newTranslationMemoryAssignments,
                  projectTemplateId,
                  values.targetLocales
                )
              )
          ).then(() => {
            dispatch({ type: 'projectTemplateSaved' });
            history.push(`/web/projectTemplate/list`);
          });
        })
        .catch(err => {
          publishCountMetric('projectTemplateSaveFailed-ProjectTemplateEdit', 'error', err.message);
          dispatch({
            type: 'projectTemplateSaveFailed',
            error: I18n.t('Failed to save project template: %{e}', {
              e: JSON.stringify(err),
            }),
          });
        })
        .finally(() => {
          const finalTime = new Date();
          const numTargetLocales = values.targetLocales?.length ?? 1;
          const numOperations =
            newTermBaseAssignments.length * numTargetLocales +
            referencesToAdd.length +
            (referencesToDelete?.length ?? 0) +
            newTranslationMemoryAssignments.length * numTargetLocales * workflowSteps.length;
          publishLatencyMetric('projectTemplateEdit-Latency', initialTime, finalTime);
          publishLatencyMetric(
            'projectTemplateEdit-LatencyPerOperation',
            initialTime,
            finalTime,
            numOperations
          );
        });
    };

    /**
     * Per AWS Specifications, only start validating after the initial submit.
     * @param e the event
     * @param handleSubmit formik submit event handler function.
     */
    const handleStepChange = (e: CustomEvent<Wizard.StepClickDetail>, errors): void => {
      if (Object.values(errors).length > 0) {
        e.preventDefault();
      } else {
        dispatch({ type: 'activeStepChange', activeStep: e.detail.requestedStepIndex });
      }
      setValidating(true);
    };

    /**
     * Per AWS Specifications, only start validating after the initial submit.
     * @param e the event
     * @param handleSubmit formik submit event handler function.
     */
    const handleSubmitWithValidation = (e: CustomEvent<Button.ClickDetail>, handleSubmit): void => {
      e.preventDefault();
      setValidating(true);
      handleSubmit();
    };

    const renderForm = (props: FormikProps<ProjectTemplateEditAPI>): ReactElement => {
      const steps: WizardStepWithSummary[] = [
        ProjectCreateStep1Metadata({
          validating,
          ...props,
          isLoadingProject: isLoadingProjectTemplate,
          isLoadingWorkflowSteps,
          isLoadingScoringModels,
          isLoadingMediaTypes,
          isLoadingSubjects,
          isLoadingClients,
          isLoadingOwners,
          isLoadingBusinessUnits,
          isLoadingCostCenters,
          isLoadingActiveLanguages,
          project: projectTemplate,
          workflowSteps,
          workflowStepsById,
          scoringModels,
          scoringModelsById,
          mediaTypes,
          mediaTypesById,
          subjects,
          subjectsById,
          clients,
          clientsById,
          owners,
          ownersById,
          businessUnits,
          businessUnitsById,
          costCenters,
          costCentersById,
          activeLanguages,
          activeLanguagesByCode,
          showProjectTemplateFields: true,
          showEditFields: true,
        }),
        ProjectCreateStep2Assets({
          validating,
          ...props,
          isProjectMode: false,
          uid: projectTemplateId,
          workflowSteps,
        }),
        ProjectCreateStep3Pretranslate({
          validating,
          session,
          ...props,
          isLoadingMachineTranslateSettings,
          isLoadingActiveLanguages,
          machineTranslateSettings,
          machineTranslateSettingsById,
          activeLanguages,
          activeLanguagesByCode,
        }),
        ProjectCreateStep4Analysis({ validating, ...props }),
        ProjectCreateStep5Quality({ validating, ...props }),
        ProjectCreateStep6AccessAndAutomation({ validating, ...props }),
        JobCreateStep2AssignJobs({
          session,
          validating,
          isProjectTemplateMode: true,
          isLoadingActiveLanguages,
          isLoadingWorkflowSteps,
          isLoadingUsers,
          workflowSteps,
          workflowStepsById,
          activeLanguages,
          activeLanguagesByCode,
          foundUsers,
          ...props,
        }),
        JobCreateStep3FileImporting({
          validating,
          isLoadingSupportedFileFormats,
          isLoadingSupportedFileEncodings,
          isLoadingSegmentationRules,
          supportedFileFormats,
          supportedFileEncodings,
          segmentationRules,
          isProjectTemplateMode: true,
          ...props,
        }),
      ];
      const { handleSubmit, errors } = props;

      return (
        <form>
          {isError && (
            <Alert
              id={'editProjectTemplateError'}
              type="error"
              content={error}
              dismissible={true}
              onDismiss={(): void => dispatch({ type: 'clearError' })}
            />
          )}
          <WizardWithSummary
            steps={[
              ...steps,
              ProjectCreateSummary({
                ...props,
                children: steps.map(s =>
                  s.summary({
                    currentValue: projectTemplate,
                    currentTranslationMemoryAssignments: translationMemoryAssignments,
                    currentTermBaseAssignments: termBaseAssignments,
                    currentReferences: references,
                  })
                ),
                title: I18n.t('Review and save'),
                helpId: PROJECT_CREATE_REVIEW_AND_SAVE_HELP,
                description: I18n.t(
                  'Review the project settings before saving the template. This view shows only fields that have changed.'
                ),
              }),
            ]}
            i18nStrings={{
              stepNumberLabel: (stepNumber: number): string =>
                I18n.t('Step %{stepNumber}', { stepNumber: stepNumber }),
              collapsedStepsLabel: (stepNumber: number, stepsCount: number): string =>
                I18n.t('Step %{stepNumber} of %{stepsCount}', {
                  stepNumber: stepNumber,
                  stepsCount: stepsCount,
                }),
              cancelButton: I18n.t('Cancel'),
              previousButton: I18n.t('Previous'),
              nextButton: I18n.t('Next'),
              submitButton: I18n.t('Save project template'),
              optional: I18n.t('Optional'),
            }}
            onCancelButtonClick={() => history.goBack()}
            onSubmitButtonClick={() => handleSubmit()}
            onNextButtonClick={e => handleStepChange(e, errors)}
            onPreviousButtonClick={e => handleStepChange(e, errors)}
            onStepNavigationClick={e => handleStepChange(e, errors)}
            additionalButtons={[
              activeStep > 0 && activeStep < steps.length && (
                <Button
                  key="reviewAndSave"
                  formAction="none"
                  onClick={(): void =>
                    handleStepChange(
                      new CustomEvent<Wizard.StepClickDetail>('stepClickDetail', {
                        detail: { requestedStepIndex: steps.length },
                      }),
                      errors
                    )
                  }
                >
                  {I18n.t('Review and save')}
                </Button>
              ),
              activeStep === 0 && (
                <Button
                  key="save"
                  formAction="none"
                  onClick={e => handleSubmitWithValidation(e, handleSubmit)}
                  loading={isSavingProjectTemplate}
                >
                  {I18n.t('Save project template')}
                </Button>
              ),
            ].filter(b => b)}
            isSubmitting={isSavingProjectTemplate}
            isLoadingNextStep={isSavingProjectTemplate}
            activeStepIndex={activeStep}
          />
        </form>
      );
    };

    const initialFormValues = {
      ...initialFormValuesStep1(false, projectTemplate, session),
      ...initialFormValuesStep2(termBaseAssignments, translationMemoryAssignments, references),
      ...initialFormValuesStep3(projectTemplate),
      ...initialFormValuesStep4(projectTemplate),
      ...initialFormValuesStep5(projectTemplate),
      ...initialFormValuesStep6(projectTemplate),
      ...jobCreateInitialFormValuesStep2(projectTemplate),
      ...jobCreateInitialFormValuesStep3(projectTemplate),
    };

    const schema = yup
      .object()
      .concat(schemaStep1(false))
      .concat(schemaStep2)
      .concat(schemaStep3)
      .concat(schemaStep4)
      .concat(schemaStep5)
      .concat(schemaStep6);

    return projectTemplate && termBaseAssignments && translationMemoryAssignments && references ? (
      <PolarisFormik
        initialValues={initialFormValues}
        validationSchema={schema}
        validateOnChange={true}
        onSubmit={handleSubmit}
        render={renderForm}
      />
    ) : (
      <Spinner size="large" />
    );
  }
);
