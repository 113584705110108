import { DateTime } from 'luxon';

export const displayTimestamp = (
  rawDate?: string | number,
  displayTime = true,
  tz?: string
): string | null => {
  if (!rawDate) {
    return null;
  }
  let moment = toDateTime(rawDate);
  if (tz && moment) {
    moment = moment.setZone(tz);
  }
  return displayMoment(moment, displayTime);
};

export const displayMoment = (date?: DateTime | null, displayTime = true): string | null => {
  if (!date) {
    return null;
  }
  return date.toLocaleString(displayTime ? DateTime.DATETIME_MED : DateTime.DATE_MED);
};

export const toDateTime = (rawDate?: string | number, addMonths = 0): DateTime | null => {
  if (!rawDate) {
    return null;
  }

  let parsedDate: DateTime;
  if (typeof rawDate === 'number') {
    parsedDate = DateTime.fromMillis(rawDate);
  } else {
    parsedDate = DateTime.fromISO(rawDate);
  }

  if (addMonths) {
    parsedDate = parsedDate.plus({ months: addMonths });
  }

  return parsedDate;
};
