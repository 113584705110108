import { useEffect, useState } from 'react';
import { AtmsApiClient } from '@amzn/et-console-components';

export interface ScoringModel {
  id:
    | {
        id: string;
        scope: string;
      }
    | string;
  name: string;
  threshold: number;
}

interface State {
  isLoadingScoringModels: boolean;
  scoringModels: ScoringModel[];
  scoringModelsById: { [id: string]: ScoringModel };
}

// TODO: Needs to reload models when the user profile changes
export const useScoringModels = (): State => {
  const [state, setState] = useState<State>({
    isLoadingScoringModels: true,
    scoringModels: [],
    scoringModelsById: {},
  });
  useEffect(() => {
    AtmsApiClient.httpGet('/api/scoring/getAllScoringModels').then(result => {
      setState({
        isLoadingScoringModels: false,
        scoringModels: result,
        scoringModelsById: result.reduce((acc, val) => {
          acc[val.id] = val;
          return acc;
        }, {}),
      });
    });
  }, []);

  return state;
};
