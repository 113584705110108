import React, { ReactElement, ReactNode, useReducer } from 'react';
import * as yup from 'yup';
import {
  Checkbox,
  ColumnLayout,
  Flash,
  Form,
  FormField,
  FormSection,
  Icon,
  RadioGroup,
  Select,
} from '@amzn/awsui-components-react/polaris';
import I18n from '../../setupI18n';
import styled from '@emotion/styled';
import { css } from 'emotion';
import { HelpInfoLink } from '../HelpContentRouter';
import { PROJECT_CREATE_ANALYSIS_HELP } from './projectHelpContent';
import { FormikProps } from 'formik';
import { AnalysisType, Project, ProjectTemplate } from '../types/commonTypes';
import { coalesceSummaryValue, WizardStepWithSummary } from '../components/WizardWithSummary';
import { Container } from '@amzn/et-polaris-utils';
import { T } from '../components/T';

const formSpacing = css`
  padding-top: 14px;
  padding-bottom: 14px;
`;

const FormContainer = styled('div')`
  max-width: 800px;
  min-width: 280px;
`;

export interface Props<T extends ProjectCreateAPIStep4> extends FormikProps<T> {
  validating: boolean;
}

export interface ProjectCreateAPIStep4 {
  analysisType: AnalysisType;
  includeInternalFuzzies?: boolean;
  excludeConfirmedSegments?: boolean;
  excludeLockedSegments?: boolean;
  excludeNumbers?: boolean;
  analyzeByLinguist?: boolean;
  countUnitsIn: 'SOURCE' | 'TARGET';
}

interface ReducerState {
  isError: boolean;
  error?: string;
}

export const schemaStep4 = yup.object().shape({
  analysisType: yup.string(),
  includeInternalFuzzies: yup.boolean(),
  excludeConfirmedSegments: yup.boolean(),
  excludeLockedSegments: yup.boolean(),
  excludeNumbers: yup.boolean(),
  analyzeByLinguist: yup.boolean(),
  countUnitsIn: yup.string(),
});

export const initialFormValuesStep4 = (
  currentValue?: Project | ProjectTemplate
): ProjectCreateAPIStep4 => {
  return {
    analysisType: currentValue?.analysisType ?? 'PreAnalyse',
    includeInternalFuzzies: currentValue?.includeInternalFuzzies ?? true,
    excludeConfirmedSegments: currentValue?.excludeConfirmedSegments ?? false,
    excludeLockedSegments: currentValue?.excludeLockedSegments ?? false,
    excludeNumbers: currentValue?.excludeNumbers ?? false,
    analyzeByLinguist: currentValue?.analyzeByLinguist ?? false,
    countUnitsIn: currentValue?.countUnitsIn ?? 'SOURCE',
  };
};

const Content = <T extends ProjectCreateAPIStep4>({
  errors,
  validating,
  values,
  handleChange,
  handleBlur,
}: Props<T>): ReactElement => {
  const init = (): ReducerState => ({
    isError: false,
  });

  const reducer = (state, action): ReducerState => {
    switch (action.type) {
      default:
        return state;
    }
  };

  const [reducerState] = useReducer(reducer, null, init);

  return (
    <FormContainer>
      <Form
        errorText={
          validating && Object.keys(errors).length !== 0
            ? I18n.t('The form contains errors. Fix them and resubmit.')
            : ''
        }
      >
        <FormSection header={I18n.t('Setup')}>
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField
                label={I18n.t('Analysis type')}
                errorText={validating && errors['analysisType']}
              >
                <Select
                  options={[
                    { id: 'PreAnalyse', label: I18n.t('Default') },
                    { id: 'Compare', label: I18n.t('Compare') },
                  ]}
                  controlId="analysisType"
                  id="analysisType"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  selectedId={values.analysisType}
                  disabled={true}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Include internal fuzzies')}
                  id="includeInternalFuzzies"
                  controlId="includeInternalFuzzies"
                  checked={values.includeInternalFuzzies}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Exclude confirmed segments')}
                  id="excludeConfirmedSegments"
                  controlId="excludeConfirmedSegments"
                  checked={values.excludeConfirmedSegments}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Exclude locked segments')}
                  id="excludeLockedSegments"
                  controlId="excludeLockedSegments"
                  checked={values.excludeLockedSegments}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Exclude numbers')}
                  id="excludeNumbers"
                  controlId="excludeNumbers"
                  checked={values.excludeNumbers}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Analyze by linguist')}
                  id="analyzeByLinguist"
                  controlId="analyzeByLinguist"
                  checked={values.analyzeByLinguist}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField label={I18n.t('Count characters/words/pages from')}>
                <RadioGroup
                  id="countUnitsIn"
                  name="countUnitsIn"
                  value={values.countUnitsIn}
                  items={[
                    { value: 'SOURCE', label: I18n.t('Source') },
                    { value: 'TARGET', label: I18n.t('Target') },
                  ]}
                  onChange={handleChange}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </FormSection>
        {reducerState.isError && (
          <div className={formSpacing}>
            <Flash
              id="validation-error"
              type="error"
              dismissible={true}
              content={I18n.t('Please try again. If the issue persists, contact support.')}
              header={I18n.t('Something went wrong.')}
            />
          </div>
        )}
      </Form>
    </FormContainer>
  );
};

export const ProjectCreateStep4Analysis = <T extends ProjectCreateAPIStep4>(
  props: Props<T>
): WizardStepWithSummary => {
  const title = I18n.t('Analysis');
  return {
    title: title,
    info: (): ReactElement => <HelpInfoLink helpId={PROJECT_CREATE_ANALYSIS_HELP} />,
    description: I18n.t('Configure how the content will be analysed.'),
    content: (): ReactElement => <Content {...props} />,
    isOptional: true,
    summary: ({ currentValue }): ReactNode => {
      const { values } = props;
      const summaryElements = [
        coalesceSummaryValue({
          label: I18n.t('Analysis type'),
          value: values.analysisType,
          currentValue: currentValue?.analysisType,
          displayValue: values.analysisType,
        }),
        coalesceSummaryValue({
          label: I18n.t('Include internal fuzzies'),
          value: values.includeInternalFuzzies,
          currentValue: currentValue?.includeInternalFuzzies,
          displayValue: values.includeInternalFuzzies ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          label: I18n.t('Exclude confirmed segments'),
          value: values.excludeConfirmedSegments,
          currentValue: currentValue?.excludeConfirmedSegments,
          displayValue: values.excludeConfirmedSegments ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          label: I18n.t('Exclude locked segments'),
          value: values.excludeLockedSegments,
          currentValue: currentValue?.excludeLockedSegments,
          displayValue: values.excludeLockedSegments ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          label: I18n.t('Exclude numbers'),
          value: values.excludeNumbers,
          currentValue: currentValue?.excludeNumbers,
          displayValue: values.excludeNumbers ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          label: I18n.t('Analyze by linguist'),
          value: values.analyzeByLinguist,
          currentValue: currentValue?.analyzeByLinguist,
          displayValue: values.analyzeByLinguist ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          label: I18n.t('Count characters/words/pages from'),
          value: values.countUnitsIn,
          currentValue: currentValue?.countUnitsIn,
          displayValue: values.countUnitsIn === 'SOURCE' ? I18n.t('Source') : I18n.t('Target'),
        }),
      ].filter(s => s);

      return (
        <Container title={title} key={title}>
          <ColumnLayout columns={4}>
            <div data-awsui-column-layout-root="true">
              {summaryElements.length > 0 ? (
                summaryElements
              ) : (
                <div>
                  <div>
                    <T>No changes</T>
                  </div>
                </div>
              )}
            </div>
          </ColumnLayout>
        </Container>
      );
    },
  };
};
