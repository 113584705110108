import React, {
  Fragment,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { Container, PageHeader } from '@amzn/et-polaris-utils';
import { JOB_EDIT_HELP } from '../projectHelpContent';
import { HelpInfoLink } from '../../HelpContentRouter';
import { parse, stringify } from 'query-string';
import {
  Job,
  ProjectStatus,
  SavedFilter,
  ScoringJob,
  SegmentMetadata,
  TableColumnDef,
  TablePropertyFilteringOption,
  User,
} from '../../types/commonTypes';
import { RouteComponentProps, withRouter } from 'react-router';
import { History, Location } from 'history';
import I18n from '../../../setupI18n';
import {
  AuthenticatedSession,
  AtmsApiClient,
  Icon,
  formatLocaleCode,
  formatPercent,
  usePref,
} from '@amzn/et-console-components';
import 'abort-controller/polyfill';
import { Link } from '@amzn/awsui-components-react-v3';
import { AtmsTable, MainStateBase } from '../../components/AtmsTable';
import { NoWrapCell } from '../../../shared/Styled';
import { Tooltip } from '../../components/Tooltip';
import { displayTimestamp } from '../../../shared/displayTimestamp';
import { qaStatus, scoreStatus } from './JobList';
import {
  Alert,
  Spinner,
  TablePagination,
  CustomDetailEvent,
  ButtonDropdown,
} from '@amzn/awsui-components-react/polaris';
import { getJobPartId, isScoringWorkflow } from '../../../shared/scoring';
import apiErrorToErrorMessage from '../../../shared/apiErrorToErrorMessage';
import { publishCountMetric } from '../../metricHelper';
import { getAppHostConfig } from '@amzn/et-console-components';

const { WEB_HOST_AND_PORT } = getAppHostConfig();

const statusToDisplay = {
  NEW: I18n.t('New'),
  EMAILED: I18n.t('Emailed'),
  ASSIGNED: I18n.t('Accepted'),
  DECLINED_BY_LINGUIST: I18n.t('Declined'),
  COMPLETED_BY_LINGUIST: I18n.t('Completed'),
  COMPLETED: I18n.t('Delivered'),
  CANCELLED: I18n.t('Cancelled'),
};

const displayToStatus = {};
Object.entries(statusToDisplay).map(([k, v]) => (displayToStatus[v] = k));

const defaultColumnOrder = [
  'jobPartId',
  'workflowStep',
  'progress',
  'qa',
  'score',
  'fileName',
  'buyer',
  'businessUnit',
  'words',
  'target',
  'note',
  'dateDue',
];

const defaultFilteringOrder = ['fileName', 'targetLocale', 'dueInHours'];

export const InfoLink = ({ id, onFollow }): ReactElement => (
  <Link variant="info" id={id} onFollow={onFollow}>
    Info
  </Link>
);

const EditorLink = ({ children, ...props }: React.HTMLProps<HTMLAnchorElement>): ReactElement => (
  <a role="button" tabIndex={0} css={{ cursor: 'pointer' }} {...props}>
    {children}
  </a>
);

export const getHeaderCounterText = (items = [], selectedItems = []): string => {
  return selectedItems && selectedItems.length > 0
    ? `(${selectedItems.length}/${items.length})`
    : `(${items.length})`;
};

export const getServerHeaderCounterText = (totalCount, selectedItems): string => {
  return selectedItems && selectedItems.length > 0
    ? `(${selectedItems.length}/${totalCount}+)`
    : `(${totalCount}+)`;
};
const dueInHoursToDisplay = {
  '-1': I18n.t('Overdue'),
  4: I18n.t('4 hours'),
  8: I18n.t('8 hours'),
  24: I18n.t('1 day'),
  72: I18n.t('3 days'),
};
const displayToDueInHours = {};
Object.entries(dueInHoursToDisplay).forEach(([k, v]) => (displayToDueInHours[v] = k));

const completionStatus = (isLoading, segmentMetadata?: SegmentMetadata): ReactNode => {
  if (isLoading) {
    return (
      <span className="awsui-util-status-inactive">
        <Spinner />
      </span>
    );
  }

  if (!segmentMetadata?.counts) {
    return '-';
  }

  const {
    counts: { confirmedSegmentsCount, segmentsCount },
  } = segmentMetadata;

  if (segmentsCount === 0) {
    return (
      <Fragment>
        <span className="awsui-util-status-negative">
          <Tooltip content={I18n.t('There are no segments in this job')}>
            <Icon name="status-warning" />
          </Tooltip>
        </span>
      </Fragment>
    );
  }

  return confirmedSegmentsCount > 0 ? formatPercent(confirmedSegmentsCount / segmentsCount) : '0%';
};

const getWordCount = (isLoading, segmentMetadata?: SegmentMetadata): ReactNode => {
  if (isLoading) {
    return (
      <span className="awsui-util-status-inactive">
        <Spinner />
      </span>
    );
  }

  if (!segmentMetadata?.counts) {
    return '-';
  }

  const {
    counts: { wordsCount },
  } = segmentMetadata;

  return wordsCount;
};

interface Props extends RouteComponentProps {
  history: History;
  location: Location;
  session: AuthenticatedSession;
  defaultPageSize?: number;
}

interface FilteringQueryApi {
  fileName?: string;
  searchPattern?: string;
  targetLocale?: string;
  dueInHours?: number;
}

export interface NonFilteringQueryApiBase {
  sortField?: string;
  sortOrder?: string;
  page: number;
}

interface UnmanagedQueryApi {
  listStatus?: string;
}

interface State extends MainStateBase<Job, FilteringQueryApi, NonFilteringQueryApiBase> {
  error?: object;
  isChangingStatus: boolean;
  user?: User;
  saveFiltersNonLinguist?: {
    default: SavedFilter[];
    updateRemote: (x: SavedFilter[]) => Promise<void>;
    getRemote: () => Promise<SavedFilter[] | undefined>;
  };
  failedStatusChange?: {
    projectIds: number[];
    status: ProjectStatus;
  };
  items: Job[];
  selectedItems: Job[];
  pages?: number;
  isLoadingSegmentMetadata: boolean;
  segmentMetadata: Map<number, SegmentMetadata>;
  languageCodeList: Array<string>;
  isLoadingScoringJobs: boolean;
  scoringJobs: Map<number, ScoringJob>;

  editorUrl?: string;
  atmsServerUrl?: string;

  filteringQuery: FilteringQueryApi;
  nonFilteringQuery: NonFilteringQueryApiBase;
  unmanagedQuery: UnmanagedQueryApi;

  isDownloadingFile: boolean;
  fileDownloadError?: string;
}

// reducer
const jobListReducer = (state: State, action): State => {
  switch (action.type) {
    case 'itemSelectionChanged':
      return {
        ...state,
        selectedItems: action.selectedItems,
      };
    case 'searchChanged':
      return {
        ...state,
        queryLoaded: true,
        filteringQuery: action.filteringQuery,
        nonFilteringQuery: action.nonFilteringQuery,
        unmanagedQuery: action.unmanagedQuery,
        items: [],
        totalItems: 0,
      };
    case 'jobsCleared':
      return {
        ...state,
        items: [],
        error: undefined,
      };
    case 'jobsLoading':
      return {
        ...state,
        isLoading: true,
        error: undefined,
        selectedItems: [],
      };
    case 'jobsLoaded':
      return {
        ...state,
        items: action.jobs,
        pages: action.pages,
        editorUrl: action.editorUrl,
        atmsServerUrl: action.atmsServerUrl,
        totalItems: state.items.concat(action.jobs).length,
      };
    case 'doneLoadingJobs':
      return {
        ...state,
        isLoading: false,
      };
    case 'jobsLoadFailed':
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case 'segmentMetadataLoading':
      return {
        ...state,
        isLoadingSegmentMetadata: true,
        error: undefined,
      };
    case 'segmentMetadataLoaded':
      return {
        ...state,
        isLoadingSegmentMetadata: false,
        segmentMetadata: action.segmentMetadata,
      };
    case 'segmentMetadataLoadFailed':
      return {
        ...state,
        isLoadingSegmentMetadata: false,
        error: action.error,
      };
    case 'scoringJobsLoading':
      return {
        ...state,
        isLoadingScoringJobs: true,
        error: undefined,
      };
    case 'scoringJobsLoaded':
      return {
        ...state,
        isLoadingScoringJobs: false,
        scoringJobs: new Map([...state.scoringJobs, ...action.scoringJobs]),
      };
    case 'scoringJobsLoadFailed':
      return {
        ...state,
        isLoadingScoringJobs: false,
        error: action.error,
      };
    case 'activeLanguageLoaded':
      return {
        ...state,
        languageCodeList: action.languageCodeList,
      };
    case 'activeLanguageLoadFailed':
      return {
        ...state,
        error: action.error,
      };
    case 'editorClickFailed':
      return {
        ...state,
        error: action.error,
      };
    case 'jobStatusChanging':
      return {
        ...state,
        isChangingStatus: true,
      };
    case 'jobStatusChangeFailed':
      return {
        ...state,
        isChangingStatus: false,
        error: action.error,
      };
    case 'fileDownloading':
      return {
        ...state,
        isDownloadingFile: true,
        fileDownloadError: undefined,
      };
    case 'fileDownloaded':
      return {
        ...state,
        isDownloadingFile: false,
      };
    case 'fileDownloadFailed':
      return {
        ...state,
        isDownloadingFile: false,
        fileDownloadError: action.fileDownloadError,
      };
    default:
      return state;
  }
};

const init = (): State => {
  return {
    isLoading: true,
    isChangingStatus: false,
    items: [],
    selectedItems: [],
    totalItems: 0,
    pages: 0,
    isLoadingSegmentMetadata: false,
    segmentMetadata: new Map(),
    languageCodeList: [],
    isLoadingScoringJobs: false,
    scoringJobs: new Map(),

    filteringQuery: {},
    nonFilteringQuery: { page: 0 },
    unmanagedQuery: {},
    queryLoaded: false,
    isDownloadingFile: false,
  };
};

export const LinguistJobList = withRouter(({ history, location, defaultPageSize }: Props) => {
  // const [prefs] = usePref('projectListPrefs', {});
  const [pageSize, setPageSize] = usePref('pageSize', defaultPageSize);

  const { search } = location;

  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = useCallback(
    (e: CustomDetailEvent<TablePagination.PaginationChangeDetail>): void => {
      setCurrentPage(e.detail.currentPageIndex - 1);
    },
    []
  );

  //useReducer hook
  const [state, dispatch] = useReducer(jobListReducer, null, init);

  // destructuring
  const {
    user,
    error,
    selectedItems,
    filteringQuery,
    nonFilteringQuery,
    unmanagedQuery,
    editorUrl,
    atmsServerUrl,
    queryLoaded,
    items,
    isLoading,
    isLoadingSegmentMetadata,
    segmentMetadata,
    isLoadingScoringJobs,
    scoringJobs,
    languageCodeList,
    isChangingStatus,
    isDownloadingFile,
    fileDownloadError,
  } = state;

  const searchToState = useCallback((): [
    FilteringQueryApi,
    NonFilteringQueryApiBase,
    UnmanagedQueryApi
  ] => {
    const query = parse(search, { parseNumbers: true, arrayFormat: 'none' });

    return [
      {
        fileName: query.fileName as string,
        searchPattern: query.searchPattern as string,
        targetLocale: query.targetLocale as string,
        dueInHours: query.dueInHours as number,
      },
      {
        sortField: query.sortField as string,
        sortOrder: query.sortOrder as string,
        page: currentPage,
      },
      {
        listStatus: (query.listStatus ?? 'NEW') as string,
      },
    ];
  }, [search, currentPage]);

  const loadJobs = useCallback(
    async (abortController = undefined) => {
      let status: string | string[] | undefined = unmanagedQuery.listStatus;
      if (unmanagedQuery.listStatus === 'NEW') {
        status = ['NEW', 'EMAILED'];
      } else if (unmanagedQuery.listStatus === 'COMPLETED_BY_LINGUIST') {
        status = ['COMPLETED_BY_LINGUIST', 'COMPLETED'];
      }
      const localUnmanagedQuery = {
        ...unmanagedQuery,
        status,
      };

      const query = {
        ...filteringQuery,
        ...nonFilteringQuery,
        ...localUnmanagedQuery,
        pageSize: pageSize,
        page: currentPage,
      };

      const getPageCountForOpenEndedPageLoad = (response, query): number => {
        const getPageCount = (response, query): number => {
          // We will show the next page pointer only if the response list has 50 records.
          // This is done since project list api starts from 0 but the page list on the table starts from 1.
          // Thus, for query.page=0 (with pageSize records) => Page pointer 1 and 2 will be shown.
          // Moreover, for any page if we have less than pageSize records we will consider that there are no more projects left to be shown
          // and we will not show the next page pointer.
          return query.page >= 0 && response.jobs.length === pageSize
            ? query.page + 2
            : query.page + 1;
        };
        return query?.page !== undefined ? getPageCount(response, query) : 1;
      };

      dispatch({
        type: 'jobsLoading',
      });

      try {
        const options = abortController ? { signal: abortController.signal } : undefined;

        const response = await AtmsApiClient.httpGet(
          `/api/job/filter?${stringify(query)}`,
          {},
          options
        );
        // Check if the particular flow is aborted. Helps to chase down race condition.
        if (abortController?.signal?.aborted) {
          return;
        }

        dispatch({
          type: 'jobsLoaded',
          jobs: response.jobs,
          pages: getPageCountForOpenEndedPageLoad(response, query),
          editorUrl: response.editorUrl,
          atmsServerUrl: response.atmsServerUrl,
        });

        await loadScoringJobsIfNeeded(response.jobs);

        dispatch({
          type: 'doneLoadingJobs',
        });
      } catch (e) {
        if (abortController?.signal?.aborted) return;
        publishCountMetric('jobsLoadFailed-LinguistJobList', 'error', e.message);
        dispatch({
          type: 'jobsLoadFailed',
          error: I18n.t('Failed to load projects: %{error}', { error: e }),
        });
      }
    },
    [
      filteringQuery.dueInHours,
      filteringQuery.fileName,
      filteringQuery.searchPattern,
      filteringQuery.targetLocale,
      nonFilteringQuery.sortField,
      nonFilteringQuery.sortOrder,
      unmanagedQuery.listStatus,
      pageSize,
      currentPage,
    ]
  );

  const loadScoringJobsIfNeeded = async (jobParts: Job[]): Promise<void> => {
    const jobPartRootIdToId: Map<number, number> = jobParts
      .filter(j => j.workflowStep && isScoringWorkflow(j.workflowStep.type))
      .reduce((acc, curr: any) => acc.set(curr.rootId, curr.id), new Map());

    if (jobPartRootIdToId.size === 0) {
      return;
    }
    const query = {
      jobPart: Array.from(jobPartRootIdToId.values()),
    };

    dispatch({
      type: 'scoringJobsLoading',
    });

    try {
      const response = await AtmsApiClient.httpGet(
        `/api/scoring/getBulkScoringJobs?${stringify(query)}`
      );
      dispatch({
        type: 'scoringJobsLoaded',
        scoringJobs: response.reduce(
          (acc, curr: ScoringJob) => acc.set(jobPartRootIdToId.get(getJobPartId(curr.id)), curr),
          new Map()
        ),
      });
    } catch (e) {
      publishCountMetric('scoringJobsLoadFailed-LinguistJobList', 'error', e.message);
      dispatch({ type: 'scoringJobsLoadFailed', error: e });
    }
  };

  const loadSegmentMetadata = useCallback(async (): Promise<void> => {
    if (items.length === 0 || isLoading) {
      return;
    }
    dispatch({
      type: 'segmentMetadataLoading',
    });

    const query = {
      jobPart: items.filter(j => j.creationTask.status === 'OK').map(j => j.id),
    };

    if (query.jobPart.length === 0) {
      return;
    }

    try {
      const response = await AtmsApiClient.httpGet(
        `//${WEB_HOST_AND_PORT}/web/api/v9/job/getSegmentsCount?${stringify(query)}`
      );
      dispatch({
        type: 'segmentMetadataLoaded',
        segmentMetadata: response.reduce(
          (a, currentValue) => a.set(currentValue.jobPartId, currentValue),
          new Map()
        ),
      });
    } catch (e) {
      dispatch({
        type: 'segmentMetadataLoadFailed',
        error: 'Failed to load segment metadata',
      });
    }
  }, [isLoading]);

  const loadActiveLanguages = useCallback(async () => {
    try {
      const response = await AtmsApiClient.httpGet(
        `//${WEB_HOST_AND_PORT}/web/api/v9/language/listActiveLangs`
      );
      const newLanguageCodeSet = new Set<string>();
      response.forEach(v => {
        newLanguageCodeSet.add(v.code);
      });
      const sortedLanguageCodes = [...newLanguageCodeSet].sort();
      dispatch({
        type: 'activeLanguageLoaded',
        languageCodeList: sortedLanguageCodes,
      });
    } catch (e) {
      publishCountMetric('activeLanguageLoadFailed-LinguistJobList', 'error', e.message);
      dispatch({
        type: 'activeLanguageLoadFailed',
        error: I18n.t('Failed to load language codes: %{error}', { error: e }),
      });
    }
  }, []);

  const reloadJobs = useCallback(() => {
    dispatch({
      type: 'jobsCleared',
    });

    loadJobs();
  }, [loadJobs]);

  useEffect(() => {
    const [filteringQuery, nonFilteringQuery, unmanagedQuery] = searchToState();

    dispatch({ type: 'searchChanged', filteringQuery, nonFilteringQuery, unmanagedQuery });
  }, [searchToState]);

  useEffect(() => {
    // If you click on any sort column multiple times there could be race condition due to which the table might show incorrect values.
    // Create the current flow's abort controller. This will help in resolving the race condition.
    const abortController = new AbortController();
    if (queryLoaded) {
      loadJobs(abortController);
    }

    // Clean up function.
    // This will make sure the existing flow is stopped when there is a change in dependency.
    return () => abortController.abort();
  }, [loadJobs, user, queryLoaded, currentPage]);

  // Load on initial mount only.
  useEffect(() => {
    document.title = I18n.t('Jobs - ATMS');
    loadActiveLanguages();
  }, [loadActiveLanguages]);

  useEffect(() => {
    loadSegmentMetadata();
  }, [loadSegmentMetadata]);

  const removeEmptyOrNull = (obj): void => {
    Object.keys(obj).forEach(
      k =>
        (obj[k] && typeof obj[k] === 'object' && removeEmptyOrNull(obj[k])) ||
        (obj[k] == null && delete obj[k])
    );
  };

  const handleActionsClick = async (
    e: CustomDetailEvent<ButtonDropdown.ItemClick>
  ): Promise<any> => {
    let newStatus: string | undefined;
    switch (e.detail.id) {
      case 'actions-accept':
        newStatus = 'ASSIGNED';
        break;
      case 'actions-decline':
        newStatus = 'DECLINED_BY_LINGUIST';
        break;
      case 'actions-complete':
        newStatus = 'COMPLETED_BY_LINGUIST';
        break;
    }

    // TODO: Support 'selectAllOnServer'

    const jobsByProjectUid = selectedItems.reduce((acc, job) => {
      const projectUid = job?.project?.uid;
      if (projectUid) {
        const projectJobs = acc[projectUid] ?? [];
        acc[projectUid] = projectJobs;
        projectJobs.push(job);
      }
      return acc;
    }, {});

    // TODO: We're grouping by project and calling separately because the existing changeStatus API only works on jobs
    //  within a single project.

    const allPromises: Promise<any>[] = [];
    for (const projectUid of Object.keys(jobsByProjectUid)) {
      const request = {
        projectUid: projectUid,
        jobPartIds: jobsByProjectUid[projectUid].map(j => j.id),
        newStatus: newStatus,
      };

      allPromises.push(AtmsApiClient.httpPost('/api/job/changeStatus', request));
    }

    dispatch({ type: 'jobStatusChanging' });
    try {
      await Promise.all(allPromises);
      reloadJobs();
    } catch (err) {
      const errorMessage = I18n.t('Failed to set job status: %{errorDetail}', {
        errorDetail: apiErrorToErrorMessage(err),
      });
      dispatch({ type: 'jobStatusChangeFailed', error: errorMessage });
    }
  };

  const handleDownloadClick = async (id: string): Promise<void> => {
    dispatch({ type: 'fileDownloading' });

    let formData: any = {
      jobPart: selectedItems.map(j => j.id),
    };

    let url;
    switch (id) {
      case 'download-scorecard-csv':
        url = `/api/scoring/downloadScoreCard`;
        formData = {
          ...formData,
          format: 'CSV',
        };
        break;
      case 'download-scorecard-xlsx':
        url = `/api/scoring/downloadScoreCard`;
        formData = {
          ...formData,
          format: 'XLSX',
        };
        break;
    }

    try {
      await AtmsApiClient.download(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: stringify(formData),
      });
      dispatch({
        type: 'fileDownloaded',
      });
    } catch (e) {
      dispatch({
        type: 'fileDownloadFailed',
        fileDownloadError: e,
      });
    }
  };

  const handleCreateTicketClick = (): void => {
    const item = selectedItems[0];
    const query = {
      projectName: item.project?.name,
      projectId: item.project?.id,
      jobPartId: item.id,
      sourceLocale: item.sourceLocale,
      targetLocale: item.targetLocale,
      dueDate: item.dateDue,
      workflowStep: item.workflowStep?.name,
    };

    removeEmptyOrNull(query);
    window.location.assign(`/web/tickets/list/createTicket?${stringify(query)}`);
  };

  const getTweUrl = (jobs): string => {
    const addJobPartQueue = jobs.length > 1 ? '?mode=jobPartQueue' : '';
    return `${editorUrl}/twe/translation/job/${jobs
      .map(j => j.id)
      .sort()
      .join('-')}${addJobPartQueue}`;
  };

  // disabled downloading score card if any of the jobs:
  // 1. are now allowed to be downloaded by linguists
  // 2. are not a scoring workflow job
  const downloadScoreCardDisabled = (selectedJobs: Job[]): boolean => {
    return (
      selectedJobs.some(j => !j.project?.allowLinguistsToDownloadTranslationJobs) ||
      selectedJobs.some(j => !(j.workflowStep && isScoringWorkflow(j.workflowStep.type)))
    );
  };

  const isDownloadingDisabled = downloadScoreCardDisabled(selectedItems);

  const prepHandleEditorLinkClick = useCallback(
    (
      job: Job
      // force = false
    ): MouseEventHandler<HTMLAnchorElement> => {
      const handler = (): void => {
        // Emulate the current functionality of opening all of the jobs that are selected if one of them is clicked
        let jobsToOpen: Job[] = [...selectedItems];
        const jobIsSelected = jobsToOpen.some(j => j.id === job.id);

        if (!jobIsSelected) {
          jobsToOpen = [job];
        }

        dispatch({
          type: 'openingEditor',
        });

        window.open(getTweUrl(jobsToOpen), '_blank');
      };
      return handler;
    },
    [editorUrl, atmsServerUrl, selectedItems]
  );

  const tz = user?.timezone ?? 'UTC';

  // todo: replace score, qa, progress, and words with actually values.
  const columnMap: Map<string, TableColumnDef<Job>> = new Map([
    [
      'jobPartId',
      {
        id: 'jobPartId',
        header: I18n.t('Project local ID'),
        cell: item => (
          <span id={'jobPart-' + item.project?.internalId}>{item.project?.internalId}</span>
        ),
        minWidth: '90px',
        width: 90,
        canReorder: false,
      },
    ],
    [
      'workflowStep',
      {
        id: 'workflowStep',
        header: I18n.t('Workflow step'),
        cell: item => (item.workflowStep ? item.workflowStep.name : '-'),
        width: 135,
      },
    ],
    [
      'progress',
      {
        id: 'progress',
        header: I18n.t('Progress'),
        cell: item => completionStatus(isLoadingSegmentMetadata, segmentMetadata.get(item.id)),
        minWidth: '120px',
        width: 120,
      },
    ],
    [
      'qa',
      {
        id: 'qa',
        header: I18n.t('QA'),
        cell: item => qaStatus(isLoadingSegmentMetadata, segmentMetadata.get(item.id)),
        minWidth: '65px',
        width: 65,
      },
    ],
    [
      'score',
      {
        id: 'score',
        header: I18n.t('Score'),
        cell: item =>
          scoreStatus(
            item.workflowStep?.type,
            isLoadingScoringJobs,
            false, // TODO: This is not ideal, but we don't have the project in order to determine if a scoring job is expected
            scoringJobs.get(item.id)
          ),
        minWidth: '135px',
        width: 135,
      },
    ],
    [
      'fileName',
      {
        id: 'fileName',
        header: I18n.t('File'),
        cell: item => (
          <EditorLink onClick={prepHandleEditorLinkClick(item)}>{item.fileName}</EditorLink>
        ),
        minWidth: '120px',
        width: 200,
        canHide: false,
        canSort: true,
      },
    ],
    [
      'buyer',
      {
        id: 'buyer',
        header: I18n.t('Buyer'),
        cell: item => (item.buyer ? item.buyer : '-'),
      },
    ],
    [
      'businessUnit',
      {
        id: 'businessUnit',
        header: I18n.t('Business unit'),
        cell: item => (item.businessUnit ? item.businessUnit : '-'),
      },
    ],
    [
      'words',
      {
        id: 'words',
        header: I18n.t('Raw words'),
        cell: item => getWordCount(isLoadingSegmentMetadata, segmentMetadata.get(item.id)),
      },
    ],
    [
      'target',
      {
        id: 'target',
        header: I18n.t('Target language'),
        cell: item => item.targetLocale && formatLocaleCode(item.targetLocale),
        minWidth: '100px',
        width: 130,
        canSort: true,
      },
    ],
    [
      'note',
      {
        id: 'note',
        header: I18n.t('Note'),
        cell: item =>
          item.project?.note ? (
            <NoWrapCell>
              <Tooltip content={item.project?.note}>
                <Icon name="status-info" variant="link" />
              </Tooltip>{' '}
              {item.project?.note}
            </NoWrapCell>
          ) : (
            '-'
          ),
      },
    ],
    [
      'dateDue',
      {
        id: 'dateDue',
        header: I18n.t('Due'),
        cell: item => (item.dateDue && displayTimestamp(item.dateDue, true, tz)) || '-',
        canSort: true,
      },
    ],
  ]);

  const filteringMap: Map<string, TablePropertyFilteringOption> = new Map([
    [
      'fileName',
      {
        propertyKey: 'fileName',
        propertyLabel: I18n.t('File'),
        groupValuesLabel: I18n.t('File'),
        values: [],
      },
    ],
    [
      'targetLocale',
      {
        propertyKey: 'targetLocale',
        propertyLabel: I18n.t('Target language'),
        groupValuesLabel: I18n.t('Target language'),
        values: languageCodeList,
        filterByMultiple: false,
      },
    ],
    [
      'dueInHours',
      {
        propertyKey: 'dueInHours',
        propertyLabel: I18n.t('Due in'),
        groupValuesLabel: I18n.t('Due in'),
        values: Object.keys(displayToDueInHours),
      },
    ],
  ]);

  const transformFilterValue = useCallback((key: string, value): any => {
    if (key === 'dueInHours') {
      return displayToDueInHours[value];
    }
    return value;
  }, []);

  const untransformFilterValue = useCallback((key: string, value): any => {
    if (key === 'dueInHours') {
      return dueInHoursToDisplay[value];
    }
    return value;
  }, []);

  return (
    <Fragment>
      <PageHeader title={I18n.t('Jobs')} extraContent={<HelpInfoLink helpId={JOB_EDIT_HELP} />} />
      <Container withGutters={false}>
        {error && (
          <Alert id="error" type="error" dismissible={true}>
            {error}
          </Alert>
        )}
        {fileDownloadError && (
          <Alert id={'file-download-error'} type={'error'} dismissible={true}>
            {`Failed to download scorecard: ${fileDownloadError}`}
          </Alert>
        )}
        <AtmsTable<FilteringQueryApi, NonFilteringQueryApiBase, State>
          atmsTableId="linguistJobList"
          history={history}
          location={location}
          mainState={state}
          mainDispatch={dispatch}
          stickyHeader={true}
          features={['propertyFiltering', 'pagination', 'selection', 'sorting']}
          header={
            <PageHeader
              title={I18n.t('Manage jobs')}
              tag={'h2'}
              buttons={[
                {
                  id: 'open',
                  text: I18n.t('Open'),
                  variant: 'primary',
                  disabled: selectedItems.length === 0,
                  href: getTweUrl(selectedItems),
                },
                {
                  id: 'create-ticket',
                  text: I18n.t('Create ticket'),
                  disabled: selectedItems.length !== 1,
                  onClick: handleCreateTicketClick,
                },
                {
                  id: 'actions',
                  text: I18n.t('Actions'),
                  disabled: selectedItems.length === 0,
                  onItemClick: handleActionsClick,
                  items: [
                    {
                      id: 'actions-accept',
                      text: I18n.t('Accept'),
                      disabled: selectedItems.some(
                        j => !(j.status === 'NEW' || j.status === 'EMAILED') || isChangingStatus
                      ),
                    },
                    {
                      id: 'actions-decline',
                      text: I18n.t('Decline'),
                      disabled: selectedItems.some(
                        j => !(j.status === 'NEW' || j.status === 'EMAILED') || isChangingStatus
                      ),
                    },
                    {
                      id: 'actions-complete',
                      text: I18n.t('Complete'),
                      disabled:
                        selectedItems.some(j => j.status !== 'ASSIGNED') || isChangingStatus,
                    },
                  ],
                },
                {
                  id: 'download',
                  text: I18n.t('Download'),
                  onItemClick: (e): void => {
                    handleDownloadClick(e.detail.id);
                    return;
                  },
                  disabled:
                    selectedItems.length === 0 || isDownloadingFile || isDownloadingDisabled,
                  loading: isDownloadingFile,
                  items: [
                    {
                      id: 'download-scorecard-csv',
                      text: I18n.t('Scorecard as CSV'),
                    },
                    {
                      id: 'download-scorecard-xlsx',
                      text: I18n.t('Scorecard as XLSX'),
                    },
                  ].filter(v => v) as ButtonDropdown.Item[],
                },
              ]}
            />
          }
          role={'LINGUIST'}
          columnMapDefault={columnMap}
          columnOrderDefault={defaultColumnOrder}
          filteringMapDefault={filteringMap}
          filteringOrderDefault={defaultFilteringOrder}
          filteringDefaultPropertyKey="fileName"
          transformFilterValue={transformFilterValue}
          untransformFilterValue={untransformFilterValue}
          filteringPlaceholder={I18n.t('Click to filter jobs by property values')}
          wrapLines={true}
          empty={I18n.t('No jobs found')}
          noMatch={I18n.t('No matching jobs found')}
          pageChangeHandler={handlePageChange}
          pageSizeOptions={[5, 20, 50, 100]}
          pageSize={pageSize}
          onChangePageSize={(newPageSize): void => {
            setPageSize(newPageSize);
            reloadJobs();
          }}
          isOpenEndedPagination={true}
          onChangeOpenEndedPagination={(): void => undefined}
        />
      </Container>
    </Fragment>
  );
});
