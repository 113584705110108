import { AtmsApiClient } from '@amzn/et-console-components';

// TODO: move to et-console-components?
export const paginateAll = async (url: string): Promise<any> => {
  let page = 0;
  let result = [];

  // support urls with existing query parameters
  if (url.includes('?')) {
    url = `${url}&`;
  } else {
    url = `${url}?`;
  }

  let keepPaging = true;
  while (keepPaging) {
    const pageResult = await AtmsApiClient.httpGet(`${url}page=${page}`);
    if (pageResult.length < 1) {
      keepPaging = false;
    } else {
      result = result.concat(pageResult);
      page = page + 1;
    }
  }

  return result;
};

export const paginateInParallel = async (url: string, batchSize = 2): Promise<any> => {
  let page = 0;
  const result = [] as any[];

  if (url.includes('?')) {
    url = `${url}&`;
  } else {
    url = `${url}?`;
  }
  let keepPaging = true;

  while (keepPaging) {
    const pages = [...Array(batchSize).keys()];
    const batchedResponses = await Promise.all(
      pages.map(async p => {
        return AtmsApiClient.httpGet(`${url}page=${page + p}`);
      })
    );

    batchedResponses.forEach(response => {
      if (response && response.length > 0) {
        result.push(...response);
      } else {
        keepPaging = false;
      }
    });

    if (keepPaging) {
      page += batchSize;
    }
  }
  return result;
};
