import React, { ReactElement } from 'react';
import { Modal, Button, CustomDetailEvent } from '@amzn/awsui-components-react/polaris';
import I18n from '../../setupI18n';

export interface Props {
  id?: string;
  content: ReactElement;
  header: ReactElement;
  okButtonId?: string;
  visible: boolean;
  handleCancelClick: (e: CustomDetailEvent<Button.ClickDetail | Modal.DismissDetail>) => void;
  handleOkClick: (e: CustomDetailEvent<Button.ClickDetail>) => void;
  size?: 'small' | 'medium' | 'large' | 'max';
}

export const StandardModal = ({
  id,
  header,
  content,
  visible,
  okButtonId,
  handleCancelClick,
  handleOkClick,
  size,
}: Props): ReactElement => {
  return (
    <Modal
      id={id}
      header={header}
      content={content}
      visible={visible}
      onDismiss={handleCancelClick}
      size={size}
      footer={
        <span className="awsui-util-f-r">
          <Button variant="link" text={I18n.t('Cancel')} onClick={handleCancelClick} />
          <Button id={okButtonId} variant="primary" text={I18n.t('Ok')} onClick={handleOkClick} />
        </span>
      }
    />
  );
};
