import { WorkflowStep } from '../App/types/commonTypes';

export const isScoringWorkflow = (workflowType?: string): boolean => {
  return workflowType === 'SCORING' || workflowType === 'SCORING_AND_REVISION';
};

export const isScoringProject = (workflowSteps: WorkflowStep[]): boolean => {
  return workflowSteps.some(workflowStep => isScoringWorkflow(workflowStep.type));
};

export const buildWorkflowGroupId = (projectId: number, workflowStepId: number): string => {
  return `web:Project:${projectId}-web:WorkflowStep:${workflowStepId}`;
};

export const getJobPartId = (scoringJobId: string): number => {
  return parseInt(scoringJobId.split(':').pop() as string, 10);
};

export const buildGroupId = (values): string => {
  const groupIds: string[] = [];
  Object.keys(values).forEach(value => {
    groupIds.push(`web:${value}:${values[value].id}`);
  });
  return groupIds.join('-');
};
