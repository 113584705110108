import I18n from '../../../setupI18n';
import { Select } from '@amzn/awsui-components-react/polaris';
import styled from '@emotion/styled';
import { AtmsApiClient } from '@amzn/et-console-components';
import { stringify } from 'query-string';
import { HeaderProps } from '../../components/TableWithGroupByColumn';
import { getAppHostConfig } from '@amzn/et-console-components';

const { WEB_HOST_AND_PORT } = getAppHostConfig();

export interface TermBaseAssignment {
  tbId: number;
  tbName: string;
  clientName: string;
  targetLang: string;
  supportedLangs: string[]; // target locales of project supported by the termbase
  readMode: boolean;
  writeMode: boolean;
  qualityAssurance: boolean;
  priority: number;
  isPublic: boolean;
  isSelected: boolean;
  isNew: boolean;
}

export interface TermBaseInConfigurePanel extends TermBaseAssignment {
  isReadIndeterminate?: boolean;
  isWriteIndeterminate?: boolean;
  isQaIndeterminate?: boolean;
  isPriorityIndeterminate?: boolean;
  projectTargetLocales?: string[];
}

export const allLocalesHeader = I18n.t('All locales');

export const getPriorityLabel = (priority: number): string => {
  if (priority === 0) {
    return I18n.t('None');
  } else if (priority === 1) {
    return I18n.t('Primary');
  } else if (priority === 2) {
    return I18n.t('Secondary');
  } else {
    return I18n.t('Multiple');
  }
};

export const getPrioritySelectedOption = (priority: number): Select.Option => {
  if (priority === 0) {
    return { label: I18n.t('None'), id: '0' };
  } else if (priority === 1) {
    return { label: I18n.t('Primary'), id: '1' };
  } else if (priority === 2) {
    return { label: I18n.t('Secondary'), id: '2' };
  } else {
    return { label: I18n.t('Multiple'), id: '3' };
  }
};

export const getGroupHeader = (
  isAllLocalesCombined: boolean,
  targetLocales: string[] | undefined
): HeaderProps[] => {
  return isAllLocalesCombined
    ? [{ name: allLocalesHeader }]
    : (targetLocales ?? []).map(tl => ({ name: tl }));
};

export const tbGroupByFunction = (
  isAllLocalesCombined: boolean
): ((item: TermBaseAssignment, groupHeaderMap: any) => any) => {
  return isAllLocalesCombined ? tbGroupByAllLocales : tbGroupByLocale;
};

export const tbGroupByAllLocales = (item: TermBaseAssignment, groupHeaderMap: any): any => {
  groupHeaderMap[allLocalesHeader].push(item);
  return groupHeaderMap;
};

export const tbGroupByLocale = (item: TermBaseAssignment, groupHeaderMap: any): any => {
  if (item.targetLang in groupHeaderMap) {
    groupHeaderMap[item.targetLang].push(item);
  }
  return groupHeaderMap;
};

export const isPublic = (organizationName: string, tb): boolean => {
  const creatorName = (tb.termBase ?? tb)?.createdBy?.organizationName;
  const isPublic = (tb.termBase ?? tb)?.isPublic;

  return isPublic === true && creatorName !== organizationName;
};

/**
 * If 'combine all locales' options is chosen then this function
 * applies the TB configuration to all target locales.
 * @param termBasesInConfigurePanel
 * @param originallyConfiguredTermBases
 * @param isAllLocalesCombined
 */
export const getTermBasesToBeSaved = (
  termBasesInConfigurePanel: TermBaseInConfigurePanel[],
  originallyConfiguredTermBases: TermBaseAssignment[],
  isAllLocalesCombined: boolean,
  writeEnabledTbInAllLocalesCombinedPanel: { [locale: string]: number[] }
): TermBaseAssignment[] => {
  if (!isAllLocalesCombined) return termBasesInConfigurePanel;

  const termBasesToBeSaved: TermBaseAssignment[] = [];

  // Hash the tb configuration by TbId for faster lookup in the below steps
  // key: tbId, value: termbase
  const termBaseInConfigurePanelHashedById: {
    [id: number]: TermBaseInConfigurePanel;
  } = termBasesInConfigurePanel.reduce((hash, tb) => {
    hash[tb.tbId] = tb;
    return hash;
  }, {});

  const originallyConfiguredTbHashedByIdAndLocale: {
    [id: number]: {
      [locale: string]: TermBaseAssignment;
    };
  } = originallyConfiguredTermBases.reduce((hash, tb) => {
    if (!hash[tb.tbId]) hash[tb.tbId] = {};
    hash[tb.tbId][tb.targetLang] = tb;
    return hash;
  }, {});

  /* For each TB, get the target locales and apply the TB configuration for each of the
   * target locales.
   **/
  Object.keys(termBaseInConfigurePanelHashedById).forEach(id => {
    const targetLocales: string[] =
      termBaseInConfigurePanelHashedById[id].projectTargetLocales ?? [];
    targetLocales.forEach(locale => {
      const previousConfig: TermBaseAssignment =
        originallyConfiguredTbHashedByIdAndLocale[id][locale];
      const newConfig: TermBaseInConfigurePanel = termBaseInConfigurePanelHashedById[id];
      termBasesToBeSaved.push({
        ...previousConfig,
        readMode: newConfig.isReadIndeterminate ? previousConfig.readMode : newConfig.readMode,
        writeMode: getWriteModeForTermBaseInCombinedPanel(
          writeEnabledTbInAllLocalesCombinedPanel[locale],
          Number(id)
        ),
        qualityAssurance: newConfig.isQaIndeterminate
          ? previousConfig.qualityAssurance
          : newConfig.qualityAssurance,
        priority: newConfig.isPriorityIndeterminate ? previousConfig.priority : newConfig.priority,
      });
    });
  });

  return termBasesToBeSaved;
};

const getWriteModeForTermBaseInCombinedPanel = (
  writeEnabledTb: number[] | undefined,
  tbId: number
): boolean => {
  if (writeEnabledTb) {
    return writeEnabledTb.includes(tbId);
  }
  return false;
};

/**
 *  If a term base has write mode enabled then this term base will be the only TB with write mode enabled in all of the
 *  relevant target locales. For example: TB-1 is configured for de & fr then on enabling write mode for this tb will
 *  turn off the write mode for other TB's in de & fr locales.
 *  If a term base has write mode disabled then the tb will removed from the hash.
 * @param isWriteEnabled
 * @param hashWriteEnabledTbByLocales
 * @param termBase
 */
export const updateWriteEnabledLocales = (
  isWriteEnabled: boolean,
  hashWriteEnabledTbByLocales: { [locale: string]: number[] },
  termBase: TermBaseInConfigurePanel
): { [locale: string]: number[] } => {
  const affectedLocales = termBase.projectTargetLocales ?? [];

  affectedLocales.forEach(locale => {
    if (locale in hashWriteEnabledTbByLocales) {
      hashWriteEnabledTbByLocales[locale] = isWriteEnabled
        ? [termBase.tbId]
        : hashWriteEnabledTbByLocales[locale].filter(id => id !== termBase.tbId);
    }
  });

  return hashWriteEnabledTbByLocales;
};

/**
 * This function determines the new write mode state for a TB.
 * If a TB has write Mode enabled then it toggles the write Mode of other TBs so that only one TB per locale has write Mode enabled
 * @param newTB
 * @param oldTB
 */
export const findNewWriteModeState = (
  writeEnabledTermBase: TermBaseInConfigurePanel,
  affectedTermBase: TermBaseInConfigurePanel,
  writeEnabledTbInCombinePanelHashedByLocales: { [locale: string]: number[] }
): any => {
  const newState = {
    newWriteModeState: false,
    newInWriteIndeterminateState: false,
  };

  const affectedLocales = writeEnabledTermBase.projectTargetLocales ?? [];
  const writeEnabledLocalesInAffectedTb =
    Object.keys(writeEnabledTbInCombinePanelHashedByLocales).filter(locale => {
      return writeEnabledTbInCombinePanelHashedByLocales[locale].includes(affectedTermBase.tbId);
    }) ?? [];
  const commonWriteEnabledLocales = writeEnabledLocalesInAffectedTb.filter(locale =>
    affectedLocales.includes(locale)
  );

  // If there is no common locales then the write properties will be retained
  if (commonWriteEnabledLocales.length === 0) {
    newState.newWriteModeState = affectedTermBase.writeMode;
    newState.newInWriteIndeterminateState = affectedTermBase.isWriteIndeterminate ?? false;
  } else {
    // If the all of the write enabled locales are affected locales then the write Mode is false
    // If a portion of locales are affected then the write Mode will be enabled for some and disabled for other hence writeIndeterminate state is true for this case
    newState.newInWriteIndeterminateState =
      commonWriteEnabledLocales.length !== writeEnabledLocalesInAffectedTb.length;
  }

  return newState;
};

/**
 * This function creates a hashtable (key: locale value: array of tb Id which has write mode enabled for the locale
 * @param termBases
 */
export const hashWriteEnabledTbByLocales = (
  termBases: TermBaseAssignment[]
): { [locale: string]: number[] } => {
  const hashWriteEnabledTBByLocales: { [locale: string]: number[] } = {};

  termBases.forEach(tb => {
    if (!(tb.targetLang in hashWriteEnabledTBByLocales))
      hashWriteEnabledTBByLocales[tb.targetLang] = [];
    if (tb.writeMode) hashWriteEnabledTBByLocales[tb.targetLang].push(tb.tbId);
  });

  return hashWriteEnabledTBByLocales;
};
/**
 * This function provides the termbases to be displayed in the Configure panel.
 * If 'combine all locales' option is enabled then it returns a list
 * of unique TB
 * @param termBases
 * @param isAllLocalesCombined
 */
export const getTermBasesInConfigurationPanel = (
  termBases: TermBaseAssignment[],
  isAllLocalesCombined: boolean
): TermBaseInConfigurePanel[] => {
  if (termBases) {
    termBases = resetIndeterminateFields(termBases);
    if (isAllLocalesCombined) return combinedTBConfigurationsByAllLocales(termBases);
  }

  return termBases;
};

const resetIndeterminateFields = (
  termBase: TermBaseInConfigurePanel[]
): TermBaseInConfigurePanel[] => {
  if (termBase) {
    termBase.forEach(tb => {
      tb.isWriteIndeterminate = false;
      tb.isReadIndeterminate = false;
      tb.isQaIndeterminate = false;
      tb.isPriorityIndeterminate = false;
    });
  }

  return termBase;
};

/**
 *  This function combines all TB configuration across all locales.
 *  If a TB property in a locale 1 is different from another locale
 *  then it marks the property as indeterminate.
 * @param termBases
 */
const combinedTBConfigurationsByAllLocales = (
  termBases: TermBaseInConfigurePanel[]
): TermBaseInConfigurePanel[] => {
  if (!termBases) return termBases;

  const uniqueTermBase = {};
  termBases.forEach(tb => {
    if (!uniqueTermBase[tb.tbId]) {
      uniqueTermBase[tb.tbId] = {
        ...tb,
        targetLang: allLocalesHeader,
        projectTargetLocales: [tb.targetLang],
      };
    } else {
      uniqueTermBase[tb.tbId].isReadIndeterminate =
        uniqueTermBase[tb.tbId].isReadIndeterminate ||
        !isTbPropertyEquals(uniqueTermBase[tb.tbId], tb, 'readMode');
      uniqueTermBase[tb.tbId].isWriteIndeterminate =
        uniqueTermBase[tb.tbId].isWriteIndeterminate ||
        !isTbPropertyEquals(uniqueTermBase[tb.tbId], tb, 'writeMode');
      uniqueTermBase[tb.tbId].isQaIndeterminate =
        uniqueTermBase[tb.tbId].isQaIndeterminate ||
        !isTbPropertyEquals(uniqueTermBase[tb.tbId], tb, 'qualityAssurance');
      uniqueTermBase[tb.tbId].isPriorityIndeterminate =
        uniqueTermBase[tb.tbId].isPriorityIndeterminate ||
        !isTbPropertyEquals(uniqueTermBase[tb.tbId], tb, 'priority');
      uniqueTermBase[tb.tbId].projectTargetLocales.push(tb.targetLang);
    }
  });

  const tbInConfigurePanel: TermBaseInConfigurePanel[] = Object.values(uniqueTermBase);
  tbInConfigurePanel.map(termBase => {
    // In order for the indeterminate state to be visible, the property has to be marked unchecked
    termBase.readMode = termBase.isReadIndeterminate ? false : termBase.readMode;
    termBase.writeMode = termBase.isWriteIndeterminate ? false : termBase.writeMode;
    termBase.qualityAssurance = termBase.isQaIndeterminate ? false : termBase.qualityAssurance;
    // set the priority to Multiple.
    termBase.priority = termBase.isPriorityIndeterminate ? 3 : termBase.priority;
  });
  return tbInConfigurePanel;
};

const isTbPropertyEquals = (
  termBase1: TermBaseAssignment,
  termBase2: TermBaseAssignment,
  property: string
): boolean => {
  return termBase1[property] === termBase2[property];
};

/**
 * This function iterates through each termBase and checks whether they have
 * any invalid configurations.
 * If any TB has no read or write permission but qa enabled then UI throws an error
 *
 * @param termBases
 */
export const isTBConfigurationsValid = (
  termBases: TermBaseAssignment[],
  isAllLocalesCombined: boolean
): any => {
  let isValid = true;
  const errorMessages: string[] = [];
  const errorText = 'requires read permission to be enabled for enabling permissions for QA';
  termBases.forEach(tb => {
    if (tb.qualityAssurance && !tb.readMode && !tb.writeMode) {
      isValid = false;
      const text = isAllLocalesCombined
        ? `${tb.tbName} ${errorText}`
        : `${tb.tbName}/${tb.targetLang} ${errorText}`;
      if (!errorMessages.includes(text)) errorMessages.push(text);
    }
  });
  return { isValid: isValid, errorMessages: errorMessages };
};

export const ModalTableContainer = styled('div')`
  margin: -1.5rem -2rem;
`;

export const saveTBConfigs = (
  isProjectMode: boolean,
  existingTermBaseAssignments,
  newTermBaseAssignments,
  uid,
  projectTargetLocales
): Promise<any>[] => {
  const affectedTargetLocales = getAffectedLocales(
    newTermBaseAssignments,
    existingTermBaseAssignments,
    projectTargetLocales
  );

  const queries = affectedTargetLocales.map(tl => {
    //Only readMode TB will be saved to ATMS
    const filteredTermBases = newTermBaseAssignments.filter(
      tb => tb.targetLang === tl && tb.readMode
    );
    const api = isProjectMode
      ? `//${WEB_HOST_AND_PORT}/web/api/v9/project/setTermBases?project=` + uid + '&'
      : `//${WEB_HOST_AND_PORT}/web/api/v9/projectTemplate/setTermBases?template=` + uid + '&';
    return `${api}${stringify({
      readTermBase: filteredTermBases.map(tb => tb.tbId),
      writeTermBase: filteredTermBases.filter(tb => tb.writeMode).map(tb => tb.tbId),
      targetLang: tl,
      qualityAssuranceTermBase: filteredTermBases
        .filter(tb => tb.qualityAssurance)
        .map(tb => tb.tbId),
      priority: filteredTermBases.map(tb => tb.priority ?? 0),
    })}`;
  });
  return queries.map(q => AtmsApiClient.httpGet(q));
};

const getAffectedLocales = (newTBs, existingTBs, projectTargetLocales: string[]): string[] => {
  // This filter will do a diff on the existing TB list and new TB list after user config the TB settings
  // It can detect the change like adding a new TB, changing the priority, changing QA, read, write values etc.
  const updatedOrAddedTBs = (newTBs ?? []).filter(
    tb =>
      !(existingTBs ?? []).some(
        existingTB =>
          (tb.tbId ?? tb.id) === (existingTB.tbId ?? tb.id) &&
          tb.targetLang === existingTB.targetLang &&
          tb.readMode === existingTB.readMode &&
          tb.writeMode === existingTB.writeMode &&
          tb.qualityAssurance === existingTB.qualityAssurance &&
          tb.priority === existingTB.priority
      )
  );
  // If a TB is deleted from the existing TB list, this will filter will find that
  const deletedTBs = (existingTBs ?? []).filter(
    tb =>
      !(newTBs ?? []).some(
        newTB =>
          (tb.tbId ?? tb.id) === (newTB.tbId ?? newTB.id) && tb.targetLang === newTB.targetLang
      )
  );

  // After finding all the changes, we only care about the locales, and later will call save TB per local
  const affectedLocales = new Set(
    [...updatedOrAddedTBs, ...deletedTBs]
      .map(tb => tb.targetLang)
      .filter(value => projectTargetLocales.includes(value))
  );
  return [...affectedLocales];
};

/*
  This function takes the results from the calls to the listbyLocales endpoint and the list of
  target locales and builds out a map that maps the term base id to the relevant target locales,
  and it gets rid of duplicates across the responses as part of the process.

  It does this by adding each TB id as a key in a map, then adding a reference to the target lang
  associated with that specific listByLocales response to a set that is the value in the map. As a
  final step, we convert that map into an object with TbIds mapped to arrays of the relevant target
  locales.
 */
export const extractSupportedLocalesByTbIdFromResponse = (
  tbResponses,
  targetLangs
): { [tbId: number]: string[] } => {
  const supportedLocalesByTbId: { [tbId: number]: string[] } = {};
  const mapOfTbIdToSupportedLocaleSet: Map<number, Set<string>> = new Map<number, Set<string>>();
  tbResponses.forEach((tbResponse, index) => {
    tbResponse.forEach(tb => {
      if (!mapOfTbIdToSupportedLocaleSet.has(tb.id)) {
        mapOfTbIdToSupportedLocaleSet.set(tb.id, new Set());
      }
      const targetLangsSet = mapOfTbIdToSupportedLocaleSet.get(tb.id);
      if (targetLangsSet) {
        targetLangsSet.add(targetLangs[index]);
      }
    });
  });
  mapOfTbIdToSupportedLocaleSet.forEach((targetLangsSet, tbId) => {
    supportedLocalesByTbId[tbId] = [...targetLangsSet];
  });
  return supportedLocalesByTbId;
};

/*
  This function takes a list of term bases, and deduplicates on term base id. For all duplicates
  for a given id, it will take the list of supportedLangs from each of those termbases and merge
  them together in a deduplicated list.
 */
export const mergeSameTermBases = (tbs: TermBaseAssignment[]): TermBaseAssignment[] => {
  const uniqueTbs = {};
  const mapOfTbIdToSupportedLangSet = new Map<number, Set<string>>();

  tbs.forEach(tb => {
    if (!uniqueTbs[tb.tbId]) {
      uniqueTbs[tb.tbId] = tb;
      mapOfTbIdToSupportedLangSet.set(tb.tbId, new Set(tb.supportedLangs));
    } else {
      const supportedLangSet = mapOfTbIdToSupportedLangSet.get(tb.tbId);
      if (supportedLangSet) {
        mapOfTbIdToSupportedLangSet.set(
          tb.tbId,
          new Set([...supportedLangSet, ...tb.supportedLangs])
        );
      }
    }
  });

  mapOfTbIdToSupportedLangSet.forEach((targetLangSet, tbId) => {
    uniqueTbs[tbId].supportedLangs = [...targetLangSet];
  });

  return Object.values(uniqueTbs);
};
