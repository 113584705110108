import React, { Fragment, ReactElement, useCallback, useEffect, useReducer, useState } from 'react';
import {
  Button,
  ButtonDropdown,
  CustomDetailEvent,
  Input,
  Table,
  TablePagination,
  TablePropertyFiltering,
  TableSelection,
  TableSorting,
} from '@amzn/awsui-components-react/polaris';
import I18n from '../../setupI18n';
import { TablePrefsModal } from './TablePrefsModal';
import {
  CellContents,
  Id,
  SavedFilter,
  TableColumnDef,
  TablePref,
  TablePropertyFilteringOption,
} from '../types/commonTypes';
import { parse, stringify } from 'query-string';
import { History, Location } from 'history';
import { usePref } from '@amzn/et-console-components';
import {
  SelectionChangeDetailWithSelectAllOnServer,
  TableSelectionWithSelectAllOnServer,
  TableWithSelectAllOnServer,
} from './TableWithSelectAllOnServer';
import styled from '@emotion/styled';
import { StandardModal } from './StandardModal';
import { ManageFiltersModal } from './ManageFiltersModal';

// If there are new default columns that don't exist in the existing prefs, add them.
// They should always exist, just potentially in a different order, hidden, etc.
// Returns true if an update was done.
export const updateRolePrefIfNeeded = (
  prefColumnOrder,
  prefColumnMap,
  columnOrderDefault: Array<string>,
  columnMapDefault: Map<string, any>
): boolean => {
  let changedPrefs = false;
  columnOrderDefault.forEach((columnId: string, idx: number) => {
    if (!prefColumnOrder.includes(columnId)) {
      // Insert it after the column it's listed next to in the default columns
      const insertAt = idx === 0 ? 0 : prefColumnOrder.indexOf(columnOrderDefault[idx - 1]) + 1;
      prefColumnOrder.splice(insertAt, 0, columnId);
      const columnDefault = columnMapDefault.get(columnId);
      if (columnDefault != null) {
        prefColumnMap.set(columnId, columnDefault);
      }
      changedPrefs = true;
    }
  });
  return changedPrefs;
};

const TablePrefsDiv = styled.div({
  order: 3,
  marginRight: '-1.5rem',
  padding: '0 1rem',
  alignSelf: 'flex-start',
});

const SelectFilterDiv = styled.div({
  order: 0,
  padding: '0 1rem 0 0',
  alignSelf: 'flex-start',
});

export interface Props<Item, MainState> {
  atmsTableId: string;
  history: History;
  location: Location;
  stickyHeader?: boolean;
  header?: JSX.Element;
  mainState: MainState;
  mainDispatch: React.Dispatch<any>;
  role?: string;
  columnMapDefault: Map<string, TableColumnDef<Item>>;
  columnOrderDefault: string[];
  filteringMapDefault: Map<string, TablePropertyFilteringOption>;
  filteringOrderDefault: string[];
  filteringDefaultPropertyKey: string;
  transformFilterValue?: (key: string, value: any) => any;
  untransformFilterValue?: (key: string, value: any) => any;
  pageSize?: number;
  pageSizeOptions?: number[];
  onChangePageSize?: (number) => void;
  filteringPlaceholder: string;
  wrapLines: boolean;
  empty: string;
  noMatch: string;
  selectAllOnServer?: {
    columnId: string;
    label: JSX.Element;
  };
  saveFilters?: {
    default: SavedFilter[];
    updateRemote: (x: SavedFilter[]) => Promise<void>;
    getRemote: () => Promise<SavedFilter[] | undefined>;
  };
  features: TableFeature[];
  pageChangeHandler?: (e: CustomDetailEvent<TablePagination.PaginationChangeDetail>) => void;
  isOpenEndedPagination: boolean;
  onChangeOpenEndedPagination: (boolean) => void;
}

export interface NonFilteringQueryApiBase {
  page?: number;
  sortField?: string;
  sortOrder?: string;
}

export type TableFeature =
  | 'filtering'
  | 'propertyFiltering'
  | 'sorting'
  | 'pagination'
  | 'selection';

export interface MainStateBase<Item, FilteringQueryApi, NonFilteringQueryApi> {
  isLoading: boolean;
  items: Item[];
  selectedItems: Item[];
  pages?: number;
  pageSize?: number;
  totalItems: number;
  queryLoaded: boolean;
  filteringQuery: FilteringQueryApi;
  nonFilteringQuery: NonFilteringQueryApi;
  unmanagedQuery?: object;
}

interface ConfigState<T> {
  columnOrder: string[];
  columnMap: Map<string, TableColumnDef<T>>;
  sortableColumns: Id[];
  filteringTokens: TablePropertyFiltering.FilteringToken[];
  showPrefsModal: boolean;
  showSaveFilterModal: boolean;
  saveFilterName: string;
  saveFilterNameErrors: Set<string>;
  showManageFiltersModal: boolean;
}

const defaultSaveFilters = {
  getRemote: (): Promise<SavedFilter[]> => Promise.resolve([]),
  updateRemote: (): Promise<void> => Promise.resolve(),
};

const columnMapToSortable = (columnMap: Map<string, TableColumnDef<any>>): Id[] => {
  const sortableColumns: Id[] = [];
  columnMap.forEach((value, key) => {
    if (value.canSort) {
      sortableColumns.push({ id: key });
    }
  });
  return sortableColumns;
};

const filteringInfoToDefs = (
  filteringOrder: string[],
  filteringMap: Map<string, TablePropertyFilteringOption>
): TablePropertyFilteringOption[] => {
  const filteringDefs: TablePropertyFilteringOption[] = [];
  filteringOrder.forEach(id => {
    const filteringDef = filteringMap.get(id);
    if (filteringDef) {
      filteringDefs.push(filteringDef);
    }
  });
  return filteringDefs;
};

const columnInfoToDefs = (
  columnOrder: string[],
  columnMap: Map<string, TableColumnDef<any>>
): TableColumnDef<any>[] => {
  const columnDefs: TableColumnDef<any>[] = [];
  columnOrder.forEach(id => {
    const columnDef: TableColumnDef<any> = columnMap.get(id) ?? {
      id: id,
      header: id,
      cell: (): CellContents => undefined,
    };
    if (columnDef.visible !== false) {
      columnDefs.push(columnDef);
    }
  });
  return columnDefs;
};

const columnInfoToPrefs = (
  columnOrder: string[],
  columnMap: Map<string, TableColumnDef<any>>
): TablePref[] =>
  columnOrder.map(id => {
    const columnDef: TableColumnDef<any> = columnMap.get(id) ?? {
      header: id,
      cell: (): CellContents => undefined,
    };
    return { id: columnDef.id ?? id, visible: columnDef.visible, width: columnDef.width };
  });

const prefsToColumnInfo = (
  prefs: TablePref[],
  columnMap: Map<string, TableColumnDef<any>>
): [string[], Map<string, TableColumnDef<any>>] => [
  prefs.map(pref => pref.id),
  prefs.reduce((t: Map<string, TableColumnDef<any>>, pref: TablePref) => {
    t.set(pref.id, {
      ...(columnMap.get(pref.id) ?? {
        id: pref.id,
        header: pref.id,
        cell: (): CellContents => undefined,
      }),
      visible: pref.visible,
      width: pref.width,
    });
    return t;
  }, new Map<string, TableColumnDef<any>>()),
];

export function AtmsTable<
  FilteringQueryApi,
  NonFilteringQueryApi extends NonFilteringQueryApiBase,
  MainState extends MainStateBase<Item, FilteringQueryApi, NonFilteringQueryApi>,
  Item = Table.Item
>({
  atmsTableId,
  history,
  location,
  header,
  stickyHeader = false,
  mainState,
  mainDispatch,
  role = 'ROLE',
  columnMapDefault,
  columnOrderDefault,
  filteringMapDefault,
  filteringOrderDefault,
  filteringDefaultPropertyKey,
  transformFilterValue,
  untransformFilterValue,
  pageSize: altPageSize,
  pageSizeOptions,
  onChangePageSize,
  filteringPlaceholder,
  wrapLines,
  empty,
  noMatch,
  selectAllOnServer,
  saveFilters,
  features,
  pageChangeHandler,
  isOpenEndedPagination,
  onChangeOpenEndedPagination,
}: Props<Item, MainState>): ReactElement {
  const configReducer = (state: ConfigState<Item>, action): ConfigState<Item> => {
    switch (action.type) {
      case 'columnInfoChanged':
        return {
          ...state,
          columnOrder: action.columnOrder,
          columnMap: action.columnMap,
          sortableColumns: action.sortableColumns,
        };
      case 'filteringTokensChanged':
        return {
          ...state,
          filteringTokens: action.filteringTokens,
        };
      case 'showPrefsModalChanged':
        return {
          ...state,
          showPrefsModal: action.showPrefsModal,
        };
      case 'showSaveFilterModalChanged':
        return {
          ...state,
          saveFilterName: '',
          saveFilterNameErrors: new Set<string>(),
          showSaveFilterModal: action.showSaveFilterModal,
        };
      case 'saveFilterNameChanged':
        return {
          ...state,
          saveFilterName: action.saveFilterName,
        };
      case 'saveFilterNameErrorsChanged':
        return {
          ...state,
          saveFilterNameErrors: action.saveFilterNameErrors,
        };
      case 'showManageFiltersModalChanged':
        return {
          ...state,
          showManageFiltersModal: action.showManageFiltersModal,
        };
      default:
        return state;
    }
  };

  const configInit = (): ConfigState<Item> => {
    return {
      columnOrder: [],
      columnMap: new Map<string, TableColumnDef<Item>>(),
      sortableColumns: [],
      filteringTokens: [],
      showPrefsModal: false,
      showSaveFilterModal: false,
      saveFilterName: '',
      saveFilterNameErrors: new Set<string>(),
      showManageFiltersModal: false,
    };
  };

  const [configState, configDispatch] = useReducer(configReducer, null, configInit);

  const [prefs, setPrefs] = usePref(`${atmsTableId}Prefs`, {});

  const { getRemote: getRemoteFilters, updateRemote: updateRemoteFilters } =
    saveFilters ?? defaultSaveFilters;
  const [filters, setFilters] = useState([] as SavedFilter[]);

  const {
    isLoading,
    items,
    selectedItems,
    pages,
    pageSize,
    totalItems,
    filteringQuery,
    nonFilteringQuery,
    unmanagedQuery = {},
  } = mainState;

  const { page, sortField, sortOrder } = nonFilteringQuery;

  const {
    columnOrder,
    columnMap,
    sortableColumns,
    filteringTokens,
    showPrefsModal,
    showSaveFilterModal,
    saveFilterName,
    saveFilterNameErrors,
    showManageFiltersModal,
  } = configState;

  useEffect(() => {
    if (!prefs[role]) {
      // If we've never saved prefs, save them.
      const newPrefs = { ...prefs };
      newPrefs[role] = columnInfoToPrefs(columnOrderDefault, columnMapDefault);
      setPrefs(newPrefs);
    } else {
      // Update prefs if anything needs to be changed.
      const [prefColumnOrder, prefColumnMap] = prefsToColumnInfo(prefs[role], columnMapDefault);
      if (
        updateRolePrefIfNeeded(prefColumnOrder, prefColumnMap, columnOrderDefault, columnMapDefault)
      ) {
        const updatedPrefs = { ...prefs };
        updatedPrefs[role] = columnInfoToPrefs(prefColumnOrder, prefColumnMap);
        setPrefs(updatedPrefs);
      }
    }
  }, [role, columnMapDefault, columnOrderDefault, prefs, setPrefs]);

  const updateAndRefreshFilters = useCallback(
    newFilters => {
      return updateRemoteFilters(newFilters)
        .then(() => getRemoteFilters())
        .then(filters => {
          setFilters(filters ?? []);
        });
    },
    [updateRemoteFilters, getRemoteFilters]
  );

  useEffect(() => {
    getRemoteFilters().then(filters => {
      if ((!filters || filters.length === 0) && saveFilters) {
        filters = saveFilters.default;
      }
      setFilters(filters ?? []);
    });
  }, [getRemoteFilters, setFilters, saveFilters]);

  useEffect(() => {
    const [newColumnOrder, newColumnMap] = prefsToColumnInfo(prefs[role] || [], columnMapDefault);
    configDispatch({
      type: 'columnInfoChanged',
      columnOrder: newColumnOrder,
      columnMap: newColumnMap,
      sortableColumns: columnMapToSortable(newColumnMap),
    });
  }, [role, prefs, columnMapDefault]);

  useEffect(() => {
    const filteringInfoToTokens = (
      filteringOrder: string[],
      filteringMap: Map<string, TablePropertyFilteringOption>,
      filteringQuery: FilteringQueryApi
    ): TablePropertyFiltering.FilteringToken[] => {
      const filteringTokens: TablePropertyFiltering.FilteringToken[] = [];
      filteringOrder.forEach(id => {
        const filter = filteringQuery[id]
          ? filteringQuery[id] instanceof Array
            ? filteringQuery[id]
            : [filteringQuery[id]]
          : [];
        filter.forEach(v => {
          const value = untransformFilterValue ? untransformFilterValue(id, v) : v;
          filteringTokens.push({
            propertyKey: id,
            propertyLabel: (filteringMap.get(id) ?? { propertyLabel: id }).propertyLabel,
            value,
            label: value,
            isFreeText: (filteringMap.get(id) ?? { isFreeText: true }).isFreeText,
            negated: false,
          });
        });
      });
      return filteringTokens;
    };

    configDispatch({
      type: 'filteringTokensChanged',
      filteringTokens: filteringInfoToTokens(
        filteringOrderDefault,
        filteringMapDefault,
        filteringQuery
      ),
    });
  }, [filteringOrderDefault, filteringMapDefault, filteringQuery, untransformFilterValue]);

  const handleSelectionChangeWithSelectAllOnServer = useCallback(
    (e: CustomDetailEvent<SelectionChangeDetailWithSelectAllOnServer>): void => {
      mainDispatch({
        type: 'itemSelectionWithSelectAllOnServerChanged',
        selectedItems: e.detail.selectedItems,
        selectAllOnServer: e.detail.selectAllOnServer,
      });
    },
    [mainDispatch]
  );

  const handleSelectionChange = useCallback(
    (e: CustomDetailEvent<TableSelection.SelectionChangeDetail>): void => {
      mainDispatch({
        type: 'itemSelectionChanged',
        selectedItems: e.detail.selectedItems,
      });
    },
    [mainDispatch]
  );

  const handlePrefsClick = useCallback((): void => {
    configDispatch({
      type: 'showPrefsModalChanged',
      showPrefsModal: true,
    });
  }, [configDispatch]);

  const onDismissPrefsModal = useCallback((): void => {
    configDispatch({
      type: 'showPrefsModalChanged',
      showPrefsModal: false,
    });
  }, [configDispatch]);

  const onApplyNewPrefs = useCallback(
    (
      newColumnOrder: string[],
      newColumnMap: Map<string, TableColumnDef<Item>>,
      newPageSize?: number,
      newPaginationType?: boolean
    ): void => {
      const newPrefs = { ...prefs };
      newPrefs[role] = columnInfoToPrefs(newColumnOrder, newColumnMap);
      setPrefs(newPrefs);
      configDispatch({
        type: 'showPrefsModalChanged',
        showPrefsModal: false,
      });
      if (newPageSize && onChangePageSize) {
        onChangePageSize(newPageSize);
      }
      if (onChangeOpenEndedPagination) {
        onChangeOpenEndedPagination(newPaginationType);
      }
    },
    [role, prefs, setPrefs]
  );

  const handleColumnWidthChange = useCallback(
    (e: CustomDetailEvent<Table.ColumnWidthsChangeDetail>): void => {
      let j = 0;
      columnOrder.forEach(id => {
        const columnDef: TableColumnDef<Item> = columnMap.get(id) ?? {
          id: id,
          header: id,
          cell: (): CellContents => undefined,
        };
        if (columnDef.visible !== false) {
          columnDef.width = e.detail.widths[j];
          j++;
        }
      });
      onApplyNewPrefs(columnOrder, columnMap);
    },
    [columnMap, columnOrder, onApplyNewPrefs]
  );

  const handleFilteringChange = useCallback(
    (changeDetail: CustomDetailEvent<TablePropertyFiltering.ChangeDetail>): void => {
      const newFilteringQuery = {};
      changeDetail.detail.tokens.forEach(token => {
        const propertyKey = token.propertyKey || filteringDefaultPropertyKey;
        const filterValue = transformFilterValue
          ? transformFilterValue(propertyKey, token.value)
          : token.value;
        if (
          !newFilteringQuery[propertyKey] ||
          !(filteringMapDefault.get(propertyKey) || { filterByMultiple: false }).filterByMultiple
        ) {
          newFilteringQuery[propertyKey] = [filterValue];
        } else {
          newFilteringQuery[propertyKey].push(filterValue);
        }
      });
      history.push({
        ...location,
        search: stringify({
          ...unmanagedQuery,
          ...newFilteringQuery,
          sortField: nonFilteringQuery.sortField,
          sortOrder: nonFilteringQuery.sortOrder,
          ...(page !== undefined ? { page: 0 } : undefined),
        }),
      });
    },
    [
      filteringDefaultPropertyKey,
      filteringMapDefault,
      history,
      location,
      nonFilteringQuery,
      transformFilterValue,
      unmanagedQuery,
    ]
  );

  const handlePageChange = useCallback(
    (e: CustomDetailEvent<TablePagination.PaginationChangeDetail>): void => {
      const query = {
        ...unmanagedQuery,
        ...filteringQuery,
        sortField: nonFilteringQuery.sortField,
        sortOrder: nonFilteringQuery.sortOrder,
        page: e.detail.currentPageIndex - 1,
      };
      history.push({ ...location, search: stringify(query) });
    },
    [filteringQuery, history, location, nonFilteringQuery, unmanagedQuery]
  );

  const handleSortingChange = useCallback(
    (e: CustomDetailEvent<TableSorting.SortingChangeDetail>): void => {
      const query = {
        ...unmanagedQuery,
        ...filteringQuery,
        sortField: e.detail.sortingColumn,
        sortOrder: e.detail.sortingDescending ? 'desc' : 'asc',
        ...(page !== undefined ? { page: 0 } : undefined),
      };
      history.push({ ...location, search: stringify(query) });
    },
    [filteringQuery, history, location, unmanagedQuery]
  );

  const handleSelectFilterClick = useCallback(
    (e: CustomDetailEvent<ButtonDropdown.ItemClick>): void => {
      if (e.detail.id === 'saveFilter') {
        configDispatch({
          type: 'showSaveFilterModalChanged',
          showSaveFilterModal: true,
        });
        return;
      }

      if (e.detail.id === 'manageFilters') {
        configDispatch({
          type: 'showManageFiltersModalChanged',
          showManageFiltersModal: true,
        });
        return;
      }

      const i = parseInt(e.detail.id);
      if (isNaN(i)) {
        return;
      }

      const query = parse(filters[i].url);

      history.push({ ...location, search: stringify({ ...query, page: 0 }) });
    },
    [configDispatch, filters, history, location]
  );

  const handleSaveFilterModalCancelButtonClick = useCallback((): void => {
    configDispatch({
      type: 'showSaveFilterModalChanged',
      showSaveFilterModal: false,
    });
  }, [configDispatch]);

  const validateFilterName = useCallback(
    (value): Set<string> => {
      const errorsCopy = new Set(saveFilterNameErrors);
      const blankError = I18n.t('Name cannot be blank.');
      const sameNameError = I18n.t('Name already used. Please select a different name.');
      if (!value.trim()) {
        errorsCopy.add(blankError);
      } else {
        errorsCopy.delete(blankError);
      }
      if (filters.some(filter => filter.text === value)) {
        errorsCopy.add(sameNameError);
      } else {
        errorsCopy.delete(sameNameError);
      }
      return errorsCopy;
    },
    [filters, saveFilterNameErrors]
  );

  const handleSaveFilterModalOkButtonClick = useCallback((): void => {
    const errorsCopy = validateFilterName(saveFilterName);
    if (errorsCopy.size) {
      configDispatch({
        type: 'saveFilterNameErrorsChanged',
        saveFilterNameErrors: errorsCopy,
      });
      return;
    }

    const savedFiltersCopy: SavedFilter[] = [...filters];
    savedFiltersCopy.push({ text: saveFilterName, url: location.search });
    updateAndRefreshFilters(savedFiltersCopy).then(() => {
      configDispatch({
        type: 'showSaveFilterModalChanged',
        showSaveFilterModal: false,
      });
    });
  }, [filters, location.search, saveFilterName, updateAndRefreshFilters, validateFilterName]);

  const onSaveFilterNameChange = useCallback(
    (e: CustomDetailEvent<Input.ChangeDetail>): void => {
      configDispatch({
        type: 'saveFilterNameChanged',
        saveFilterName: e.detail.value,
      });
      const errorsCopy = validateFilterName(e.detail.value);
      configDispatch({
        type: 'saveFilterNameErrorsChanged',
        saveFilterNameErrors: errorsCopy,
      });
    },
    [validateFilterName]
  );

  const onApplyNewSavedFilters = useCallback(
    (newSavedFilters: SavedFilter[]): void => {
      updateAndRefreshFilters(newSavedFilters).then(() => {
        configDispatch({
          type: 'showManageFiltersModalChanged',
          showManageFiltersModal: false,
        });
      });
    },
    [updateAndRefreshFilters]
  );

  const onDismissManageFiltersModal = useCallback((): void => {
    configDispatch({
      type: 'showManageFiltersModalChanged',
      showManageFiltersModal: false,
    });
  }, [configDispatch]);

  // Hide the pagination feature if appropriate
  const actualFeatures = features.filter(
    f => f !== 'pagination' || (pages ? pages > 0 : items.length > 0)
  );
  const tableProps: Table.Props = {
    id: `${atmsTableId}Table`,
    loading: isLoading,
    stickyHeader,
    header,
    items,
    columnDefinitions: columnInfoToDefs(columnOrder, columnMap),
    empty,
    noMatch,
    resizableColumns: true,
    wrapLines,
    onColumnWidthsChange: handleColumnWidthChange,
    variant: 'borderless',
    features: actualFeatures,
  };

  const tableChildren = (
    <Fragment>
      {actualFeatures.includes('pagination') && (
        <TablePagination
          pageSize={pageSize ?? altPageSize ?? 50}
          currentPageIndex={page ? page + 1 : 1}
          openEnd={isOpenEndedPagination}
          pagesCount={pages}
          onPaginationChange={pageChangeHandler ?? handlePageChange}
        />
      )}
      {actualFeatures.includes('sorting') && (
        <TableSorting
          sortableColumns={sortableColumns}
          onSortingChange={handleSortingChange}
          sortingColumn={sortField}
          sortingDescending={sortOrder !== undefined && sortOrder.toLowerCase() === 'desc'}
        />
      )}
      {actualFeatures.includes('propertyFiltering') && (
        <TablePropertyFiltering
          disabled={isLoading}
          filteringFunction={null}
          onPropertyFilteringChange={handleFilteringChange}
          tokens={filteringTokens}
          filteringOptions={filteringInfoToDefs(filteringOrderDefault, filteringMapDefault)}
          groupValuesText={I18n.t('Values')}
          groupPropertiesText={I18n.t('Properties')}
          hideOperations={true}
          clearFiltersText={I18n.t('Clear filter')}
          placeholder={filteringPlaceholder}
          allowFreeTextFiltering={true}
          filteringCountTextFunction={(): string => {
            if (!isOpenEndedPagination) {
              return I18n.t(
                {
                  one: '%{count} match',
                  other: '%{count} matches',
                },
                { count: totalItems }
              );
            }
            return '';
          }}
        />
      )}
      <TablePrefsDiv className="awsui-table-preferences-trigger">
        <Button variant="icon" icon="settings" onClick={handlePrefsClick} />
      </TablePrefsDiv>
      {saveFilters && (
        <SelectFilterDiv>
          <ButtonDropdown
            id="filterActions"
            text={I18n.t('Filter actions')}
            onItemClick={handleSelectFilterClick}
            items={[
              {
                id: 'selectFilter',
                text: I18n.t('Apply saved filter'),
                items:
                  filters && filters.length > 0
                    ? filters.map((v, i) => ({
                        id: '' + i,
                        text: v.text,
                      }))
                    : [{ id: 'noSavedFilters', text: I18n.t('No saved filters'), disabled: true }],
              },
              {
                id: 'saveFilter',
                text: I18n.t('Save current filter'),
              },
              {
                id: 'manageFilters',
                text: I18n.t('Manage saved filters'),
              },
            ]}
          />
        </SelectFilterDiv>
      )}
    </Fragment>
  );

  return (
    <Fragment>
      {selectAllOnServer ? (
        <TableWithSelectAllOnServer
          {...tableProps}
          totalItems={totalItems}
          selectAllOnServerColumnId={selectAllOnServer.columnId}
          selectAllOnServerLabel={selectAllOnServer.label}
        >
          {actualFeatures.includes('selection') && (
            <TableSelectionWithSelectAllOnServer
              pageSize={pageSize ?? altPageSize ?? 50}
              selectedItems={selectedItems}
              trackBy="id"
              onSelectionChangeWithSelectAllOnServer={handleSelectionChangeWithSelectAllOnServer}
            />
          )}
          {tableChildren}
        </TableWithSelectAllOnServer>
      ) : (
        <Table {...tableProps}>
          {actualFeatures.includes('selection') && (
            <TableSelection
              selectedItems={selectedItems}
              trackBy="id"
              onSelectionChange={handleSelectionChange}
            />
          )}
          {tableChildren}
        </Table>
      )}

      <TablePrefsModal
        modalId={`${atmsTableId}PrefsModal`}
        prefsTableId={`${atmsTableId}PrefsTable`}
        okButtonId={`${atmsTableId}ConfirmPrefs`}
        visible={showPrefsModal}
        columnOrder={columnOrder}
        columnMap={columnMap}
        onDismiss={onDismissPrefsModal}
        onApply={onApplyNewPrefs}
        columnOrderDefault={columnOrderDefault}
        columnMapDefault={columnMapDefault}
        pageSize={pageSize}
        pageSizeOptions={pageSizeOptions}
        isOpenEndedPagination={isOpenEndedPagination}
      />

      <ManageFiltersModal
        modalId={`${atmsTableId}ManageFiltersModal`}
        prefsTableId={`${atmsTableId}ManageFiltersTable`}
        okButtonId={`${atmsTableId}ConfirmFilters`}
        visible={showManageFiltersModal}
        onDismiss={onDismissManageFiltersModal}
        onApply={onApplyNewSavedFilters}
        savedFilters={filters}
      />

      <StandardModal
        id={`${atmsTableId}SaveFilterModal`}
        header={I18n.t('Save current filter')}
        content={
          <Fragment>
            <Input
              id={`${atmsTableId}SaveFilterModalInput`}
              value={saveFilterName}
              onInput={onSaveFilterNameChange}
              placeholder={I18n.t('Filter name')}
              invalid={saveFilterNameErrors.size > 0}
            />
            {saveFilterNameErrors}
          </Fragment>
        }
        okButtonId={`${atmsTableId}SaveFilterModalConfirm`}
        visible={showSaveFilterModal}
        handleCancelClick={handleSaveFilterModalCancelButtonClick}
        handleOkClick={handleSaveFilterModalOkButtonClick}
      />
    </Fragment>
  );
}
