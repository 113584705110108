import { useEffect, useState } from 'react';
import { AtmsApiClient, AuthenticatedSession, useSession } from '@amzn/et-console-components';
import {
  transformResponseToTranslationMemoryModel,
  TranslationMemoryModel,
} from '../project/translationmemory/TranslationMemoryCommon';
import { getAppHostConfig } from '@amzn/et-console-components';

const { WEB_HOST_AND_PORT } = getAppHostConfig();

interface State {
  isLoadingTransMemoryAssignments: boolean;
  translationMemoryAssignments?: TranslationMemoryModel[];
}

// TODO: Needs to reload when the user profile changes
export const useTranslationMemories = (isProjectMode: boolean, uid?: string | number): State => {
  const [state, setState] = useState<State>({
    isLoadingTransMemoryAssignments: true,
    translationMemoryAssignments: undefined,
  });
  const session = useSession() as AuthenticatedSession;
  const organizationName = session?.organization?.name;
  useEffect(() => {
    if (!state.isLoadingTransMemoryAssignments) {
      setState({
        isLoadingTransMemoryAssignments: true,
        translationMemoryAssignments: undefined,
      });
    }
    if (uid) {
      const api = isProjectMode
        ? `//${WEB_HOST_AND_PORT}/web/api/v2/project/getTransMemories?project=` + uid
        : `//${WEB_HOST_AND_PORT}/web/api/v2/projectTemplate/getTransMemories?template=` + uid;
      AtmsApiClient.httpGet(`${api}`)
        .then(result => {
          setState({
            isLoadingTransMemoryAssignments: false,
            translationMemoryAssignments: transformResponseToTranslationMemoryModel(
              result,
              organizationName
            ),
          });
        })
        .catch(() => {
          setState({
            isLoadingTransMemoryAssignments: false,
            translationMemoryAssignments: undefined,
          });
        });
    } else {
      setState({
        isLoadingTransMemoryAssignments: false,
        translationMemoryAssignments: undefined,
      });
    }
  }, [uid, isProjectMode, organizationName]);

  return state;
};
