import React, { useLayoutEffect, useMemo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import useUpdatingKey from './useUpdatingKey';
import I18n from '../setupI18n';

const LegacyPageIframe = ({ path, preNavigate, onLoad, webUri, ...props }) => {
  // useLayoutEffect instead of useEffect ... we need to ensure we're listening
  // before we render, otherwise the iframe can win the race and we'll miss a
  // postMessage
  useLayoutEffect(() => {
    const receiveIframeMessage = ({ origin, data }) => {
      if (origin === webUri && data.type === 'LOAD_PATH') {
        const {
          payload: { path, title, features, item, csrfToken, method },
        } = data;
        onLoad({ path, title, features, item, csrfToken, method });
      } else if (origin === webUri && data.type === 'NAVIGATE_PATH') {
        const {
          payload: { path },
        } = data;
        preNavigate({ path });
      }
    };

    window.addEventListener('message', receiveIframeMessage);
    return () => window.removeEventListener('message', receiveIframeMessage);
  }, [onLoad, webUri, preNavigate]);

  // TODO: error handling/etc for page errors; we rely on postMessage, but that only
  // works if the page renders normally. So for timeouts, unexpected page errors, etc.,
  // we need some logic around that. Perhaps we could leverage onLoad since it will
  // all be on the same domain thanks to cloudfront / http-proxy-middleware
  return (
    <iframe
      {...props}
      title={I18n.t('ATMS legacy page')}
      type="text/html"
      src={`${webUri}${path}`}
    />
  );
};

const LegacyPageLoader = ({ path, preNavigate, onLoad, webUri }) => {
  // the location can change two ways:
  // 1. a navigation event happened above us, in which case we want to
  //    re-render so the iframe loads the URL, or
  // 2. a navigation event happened in the iframe, in which case we already
  //    know about it and don't want to re-render and reload the same page,
  //    thus skipNextUpdate
  const [key, skipNextUpdate] = useUpdatingKey(path);

  const pathRef = useRef();
  pathRef.current = path;

  const onLoadIframe = useCallback(
    data => {
      if (pathRef.current !== data.path) {
        // but make sure we don't reload the iframe itself
        skipNextUpdate();
      }
      // let the app know about the new URL, page title, page features, etc
      onLoad(data);
    },
    [onLoad, skipNextUpdate]
  );

  return useMemo(
    () => (
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          '@supports (-webkit-overflow-scrolling: touch)': {
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
          },
        }}
      >
        <LegacyPageIframe
          key={key}
          path={path}
          css={{ border: 0, flexGrow: 1 }}
          preNavigate={preNavigate}
          onLoad={onLoadIframe}
          webUri={webUri}
        />
      </div>
    ),
    // yes, I know what I'm doing 😆
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [key]
  );
};

LegacyPageLoader.propTypes = {
  onLoad: PropTypes.func,
  path: PropTypes.string.isRequired,
  webUri: PropTypes.string.isRequired,
};

LegacyPageLoader.defaultProps = {
  onLoad: Function.prototype,
};

export default LegacyPageLoader;
