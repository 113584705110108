import { useEffect, useState } from 'react';
import { AtmsApiClient } from '@amzn/et-console-components';
import { ProjectTemplate } from '../types/commonTypes';
import { stringify } from 'query-string';

interface State {
  isLoadingProjectTemplate: boolean;
  projectTemplate?: ProjectTemplate;
}

// TODO: Needs to reload when the user profile changes
export const useProjectTemplate = (projectTemplateId?: string | number): State => {
  const [state, setState] = useState<State>({
    isLoadingProjectTemplate: true,
    projectTemplate: undefined,
  });
  useEffect(() => {
    if (projectTemplateId) {
      if (!state.isLoadingProjectTemplate) {
        setState({
          isLoadingProjectTemplate: true,
          projectTemplate: undefined,
        });
      }
      AtmsApiClient.httpGet(`/api/v2/projectTemplate?${stringify({ id: projectTemplateId })}`)
        .then(result => {
          setState({
            isLoadingProjectTemplate: false,
            projectTemplate: result,
          });
        })
        .catch(() => {
          setState({
            isLoadingProjectTemplate: false,
            projectTemplate: undefined,
          });
        });
    } else {
      setState({
        isLoadingProjectTemplate: false,
      });
    }
  }, [projectTemplateId]);

  return state;
};
