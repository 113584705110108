import { ReactNode } from 'react';
import { Table, TablePropertyFiltering } from '@amzn/awsui-components-react/polaris';
import { EmailNotificationSettings } from '../../shared/JobNotification';

export type CellContents = string | number | boolean | ReactNode | null | undefined;

export interface User {
  id: number;
  federateId: string;
  firstName?: string;
  lastName?: string;
  userName?: string;
  email?: string;
  organizationName?: string;
  role?: string;
  disableProjectSharing?: boolean;
  timezone?: string;
  machineTranslateSettingsDefaultId?: number;
}

export interface Vendor {
  id: number;
  name: string;
  buyerUserId: number;
}

export interface WorkflowStep {
  id: number;
  level: number;
  ord: number;
  name: string;
  type: 'TRANSLATION' | 'LIGHT_MTPE' | 'REVISION' | 'SCORING' | 'SCORING_AND_REVISION' | 'OTHER';
}

export interface WorkflowSetting {
  workflowStep: WorkflowStep;
  assignedTo: [
    {
      targetLang: string;
      users: User[];
    }
  ];
  notificationId: number;
  notificationIntervalInMinutes: number;
  notificationName: string;
}

export interface MachineTranslateSettings {
  id?: number;
  name: string;
}

export interface EmailTemplate {
  id?: number;
  name: string;
}

export interface PreTranslateSettings {
  useTranslationMemory: boolean;
  translationMemoryThreshold: number;
  overwrite: boolean;
  preTranslateNonTranslatables: boolean;
  confirmNonTranslatables: boolean;
  confirm100Matches: boolean;
  confirm101Matches: boolean;
  useMachineTranslation: boolean;
  insertMachineTranslationIntoTarget: boolean;
  lock100Matches: boolean;
  lock101Matches: boolean;
  lockNonTranslatables: boolean;
  setJobStatusCompleted: boolean;
  setProjectStatusCompleted: boolean;
  preTranslateOnJobCreation: boolean;
  pseudoTranslateReplacement: string;
  pseudoTranslateIntersperse: boolean;
  pseudoTranslatePrefix: string;
  pseudoTranslateSuffix: string;
  pseudoTranslateLength:
    | 0.1
    | 0.5
    | 0.6
    | 0.7
    | 0.8
    | 0.9
    | 1.0
    | 1.1
    | 1.2
    | 1.3
    | 1.4
    | 1.5
    | 2.0;
}

export enum ProjectStatus {
  NEW = 'NEW',
  ASSIGNED = 'ASSIGNED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}

export interface ProjectBase {
  // This is the new set of properties geared for use in the UI. The old properties will be phased out.
  sourceLocale: string;
  targetLocales: string[];
  workflowStepIds?: number[];
  vendorNames?: string[];
  ownerId: number;
  clientId?: number;
  costCenterId?: number;
  businessUnitId?: number;
  useTranslationMemory: boolean;
  tmMatchThreshold: number;
  useMachineTranslation: boolean;
  preTranslateNonTranslatables: boolean;
  machineTranslateSettingsId?: number;
  perLocaleMachineTranslation: boolean;
  perLocaleMachineTranslateSettingsIds: { [locale: string]: number };
  automaticallyPretranslate: boolean;
  confirmNonTranslatable: boolean;
  confirm100Match: boolean;
  confirm101Match: boolean;
  lockNonTranslatable: boolean;
  lock100Match: boolean;
  lock101Match: boolean;
  completeJobsOncePretranslated: boolean;
  completeProjectOnceAllJobsPretranslated: boolean;
  overwriteExistingTranslationsWithPretranslation: boolean;
  contextType: 'AUTO' | 'PREV_AND_NEXT_SEGMENT' | 'SEGMENT_KEY' | 'NO_CONTEXT';
  previousOrNextSegmentContextAs101: boolean;
  penalizeMultiple101TMMatchesBy1: boolean;
  analysisType: AnalysisType;
  includeInternalFuzzies: boolean;
  countUnitsIn: 'SOURCE' | 'TARGET';
  excludeConfirmedSegments: boolean;
  excludeNumbers: boolean;
  excludeLockedSegments: boolean;
  analyzeByLinguist: boolean;

  preventLinguistsFromCompletingJobsWithQAWarnings: boolean;
  qaExcludeLockedSegments: boolean;
  autoIgnorePreviouslyIgnoredQAWarnings: boolean;
  emptyTargets: boolean;
  identicalToSource: boolean;
  trailingPunctuation: boolean;
  inconsistentTranslations: boolean;
  missingNumbers: boolean;
  leadingAndTrailingSpace: boolean;
  multipleSpaces: boolean;
  newerVersionsInPreceedingWorkflowStep: boolean;
  repeatedWords: boolean;
  tagsAndFormatting: boolean;
  inconsistentTagContent: boolean;
  emptyTagContent: boolean;
  segmentJoinTags: boolean;
  noTextBetweenTags: boolean;
  xliffTags: boolean;
  terminology: boolean;
  allowUnapprovedTerms: boolean;
  forbiddenTermsWithoutSource: boolean;
  customForbiddenTerms: string;
  fuzzyTMMatchesWithNoPostEditing: boolean;
  spelling: boolean;
  includeCapitalizedWordsWhenIdenticalToSource: boolean;
  includeWordsInAllCaps: boolean;
  unconfirmedSegment: boolean;
  unresolvedComments: boolean;
  targetSegmentsLongerThanXCharacters: number;
  targetSegmentsLongerThanXPercentOfSource: number;
  overrideTargetLengthOptionsSetDuringJobCreation: boolean;
  overrideTargetSegmentsLongerThanXCharacters: boolean;
  overrideTargetSegmentsLongerThanXPercentOfSource: boolean;

  allowLinguistsToUseTheWebEditor: boolean;
  allowLinguistsToDownloadTranslationJobs: boolean;
  allowLinguistsToViewUserMetadataInTheWebEditor: boolean;
  preventCompletingJobsWithEmptyTargets: boolean;
  setProjectToAssignedEnabled: boolean;
  setProjectToAssignedJobStatus: 'Emailed' | 'Accepted';
  setProjectToCompletedEnabled: boolean;
  setProjectToCompletedJobStatus: 'Completed' | 'Delivered' | 'CompletedOrDelivered';
  setProjectToCompletedWhenProjectIsCompletedByVendor: boolean;
  setProjectToCanceledEnabled: boolean;
  setProjectToCanceledJobStatus: 'Canceled';
  setRenameCompletedFilesToEnabled: boolean;
  renameCompletedFilesTo: string;
  deliverNotificationsByEmail: boolean;
  notifyWhenPreviousStepIs: 'Completed' | 'Delivered';

  scoringModelIdsByWorkflowStepId: { [id: number]: string };
}

export interface Project extends ProjectBase {
  isPostMortemScoring: string;
  name: string;
  id: number;
  uid: string;
  internalId: number;
  createdBy: User;
  dateCreated: string;
  status: ProjectStatus;
  dateDue: string;
  sourceLang: string;
  targetLangs: string[];
  machineTranslateSettings?: MachineTranslateSettings;
  preTranslateSettings?: PreTranslateSettings;
  langSettings: { targetLang: string; machineTranslateSettings: MachineTranslateSettings }[];
  owner: User;
  rootOwner?: User;
  buyerOwner?: User;
  vendorOwner?: User;
  mediaType: string;
  subject: string;
  domain?: { name: string };
  subDomain?: { name: string };
  client?: Client;
  costCenter?: string;
  workflowSteps: WorkflowStep[];
  activeWorkflowStep?: WorkflowStep;
  progress?: { totalCount: number; finishedCount: number; overdueCount: number };
  buyer?: { name: string };
  businessUnit?: BusinessUnit;
  note?: string;
  shared: boolean;
  automationWidgetLink?: string;
  autoDeletionDateTime?: string;
  jobCreationAllowed?: boolean;
  scoring?: Group[];

  // New parameters
  dueDate?: string;
  projectTemplateId: number;
  projectTemplateName: string;
}

export interface FileImportSettings {
  zipCharset: string;
  importEncoding: string;
  exportEncoding: string;
  fileFormat?: string; // TODO: This is an enum with MANY values
  sourceSegmentationRuleId?: number;
  targetSegmentationRuleId?: number;
  projectTargetLocales: string[];
  targetLength: boolean;
  targetLengthMax?: number;
  targetLengthPercent: boolean;
  targetLengthPercentValue?: number;

  csvDelimiter: string;
  csvUseHtmlSubfilter: boolean;
  csvTagRegexp?: string;
  csvImportColumns?: string;
  csvContextNoteColumns?: string;
  csvContextKeyColumn?: string;
  csvMaxLengthColumn?: string;
  csvImportRows?: string;

  ditaTranslatableElements?: string;
  ditaInlineElements?: string;
  ditaInlineSingleElements?: string;
  ditaSkipElements?: string;

  docBookTranslatableElements?: string;
  docBookInlineElements?: string;
  docBookInlineSingleElements?: string;
  docBookSkipElements?: string;

  htmlBreakTagCreatesSegment: boolean;
  htmlUnknownTagCreatesTag: boolean;
  htmlPreserveWhitespace: boolean;
  htmlCharacterEntitiesToConvertToTags?: string;
  htmlTagRegexp?: string;
  htmlTranslatableMetaTagRegexp?: string;
  htmlExcludeElements?: string;

  idmlExtractNotes: boolean;
  idmlSimplifyCodes: boolean;
  idmlExtractMasterSpreads: boolean;
  idmlExtractInvisibleLayers: boolean;
  idmlExtractHiddenConditionalText: boolean;
  idmlExtractLockedLayer: boolean;
  idmlKeepKerning: boolean;
  idmlKeepTracking: boolean;
  idmlTargetFont?: string;
  idmlReplaceFont: boolean;
  idmlRemoveXmlElements: boolean;
  idmlTagRegexp?: string;

  jsonTagRegexp?: string;
  jsonIncludedKeysRegexp?: string;
  jsonContextNotePath?: string;
  jsonUseHtmlSubfilter: boolean;
  jsonMaxLengthPath?: string;

  mifExtractBodyPages: boolean;
  mifExtractReferencePages: boolean;
  mifExtractCustomReferencePages: boolean;
  mifExtractDefaultReferencePages: boolean;
  mifExtractMasterPages: boolean;
  mifExtractHiddenPages: boolean;
  mifExtractVariables: boolean;
  mifExtractUsedVariables: boolean;
  mifExtractIndexMarkers: boolean;
  mifExtractLinks: boolean;
  mifExtractXRefs: boolean;
  mifExtractUsedXRefs: boolean;
  mifExtractPgfNumberFormats: boolean;
  mifExtractUsedPgfNumberFormats: boolean;
  mifExtractHiddenConditionalText: boolean;

  multilingualXlsSourceColumn?: string;
  multilingualXlsTagRegexp?: string;
  multilingualXlsUseHtmlSubfilter: boolean;
  multilingualXlsContextNoteColumn?: string;
  multilingualXlsContextKeyColumn?: string;
  multilingualXlsMaxLengthColumn?: string;
  multilingualXlsSegment: boolean;
  multilingualXlsImportRows?: string;
  multilingualXlsTargetColumn?: string;
  multilingualXlsAllTargetColumns: string[];

  msExcelCellFlow: 'DownRight' | 'RightDown' | 'DownLeft' | 'LeftDown';
  msExcelExtractSheetNames: boolean;
  msExcelExtractHidden: boolean;
  msExcelExtractOther: boolean;
  msExcelUseHtmlSubfilter: boolean;
  msExcelTagRegexp?: string;
  msExcelExtractComments: boolean;

  msPowerPointExtractNotes: boolean;
  msPowerPointExtractMasterSlides: boolean;
  msPowerPointExtractOther: boolean;
  msPowerPointExtractHidden: boolean;

  msWordExtractComments: boolean;
  msWordExtractIndexes: boolean;
  msWordExtractOther: boolean;
  msWordTagRegexp?: string;
  msWordTargetFont?: string;
  msWordExtractHyperlinkTarget: boolean;
  msWordJoinSimilarRuns: boolean;
  msWordExtractProperties: boolean;
  msWordExtractHidden: boolean;

  ttxSaveConfirmedSegments: boolean;

  txtTagRegexp?: string;
  txtTranslatableTextRegexp?: string;

  poTagRegexp?: string;
  poExportMultilineStrings: boolean;

  propertiesTagRegexp?: string;
  propertiesEscapeAllBackslashes: boolean;
  propertiesSmartHtmlSubfilter: boolean;

  xlfExtractNote: boolean;
  xlfSaveConfirmedSegmentsToTMOnImport: boolean;
  xlfSegment: boolean;
  xlfInterpretLineBreaksTags: boolean;
  xlfPreserveWhitespace: boolean;
  xlfCopySourceToTargetIfSegmentNotImported: boolean;
  xlfDoNotImportSegmentWhenRules?: string;
  xlfConfirmSegmentWhenRules?: string;
  xlfLockSegmentWhenRules?: string;
  xlfExportConfirmedAndLockedRules?: string;
  xlfExportConfirmedAndNotLockedRules?: string;
  xlfExportNotConfirmedAndLockedRules?: string;
  xlfExportNotConfirmedAndNotLockedRules?: string;
  xlfContextType?: string;

  xlf2ImportNotes: boolean;
  xlf2SaveConfirmedSegmentsToTMOnImport: boolean;
  xlf2Segment: boolean;
  xlf2InterpretLineBreakTags: boolean;
  xlf2PreserveWhitespace: boolean;
  xlf2CopySourceToTargetIfSegmentNotImported: boolean;
  xlf2RespectTranslateAttribute: boolean;
  xlf2SkipImportRules?: string;
  xlf2ConfirmSegmentWhenRules?: string;
  xlf2LockSegmentWhenRules?: string;
  xlf2ExportConfirmedAndLockedRules?: string;
  xlf2ExportConfirmedAndNotLockedRules?: string;
  xlf2ExportNotConfirmedAndLockedRules?: string;
  xlf2ExportNotConfirmedAndNotLockedRules?: string;
  xlf2ContextKeyXPath?: string;

  sdlXlfSkipImportRules?: string;
  sdlXlfImportAsConfirmedRules?: string;
  sdlXlfImportAsLockedRules?: string;
  sdlXlfExportAttrsWhenConfirmedAndLocked?: string;
  sdlXlfExportAttrsWhenConfirmedAndNotLocked?: string;
  sdlXlfExportAttrsWhenNotConfirmedAndLocked?: string;
  sdlXlfExportAttrsWhenNotConfirmedAndNotLocked?: string;
  sdlXlfSaveConfirmedSegments: boolean;

  xmlRulesFormat: 'PLAIN' | 'XPATH';
  xmlIncludeTags: boolean;
  xmlIncludeExcludeTags?: string;
  xmlIncludeAttributes: boolean;
  xmlIncludeExcludeAttributes?: string;
  xmlImportEntities: boolean;
  xmlTagRegexp?: string;
  xmlCharacterEntitiesToPreserveOnExport?: string;
  xmlSegment: boolean;
  xmlXslUrl?: string;
  xmlEscapeUnresolvedEntities: boolean;
  xmlIgnorePreprocessingInstructions: boolean;
  xmlLockedTags?: string;
  xmlLockedAttributes?: string;
  xmlElementsToProcessAsHtml?: string;
  xmlSmartHtmlSubfilter: boolean;
  xmlInlineTags?: string;
  xmlInlineSingleTag?: string;
  xmlSmartInlineTags: boolean;
  xmlElementsAndAttributesXPath?: string;
  xmlLockedElementsAndAttributesXPath?: string;
  xmlInlineElementsXPath?: string;
  xmlInlineSingleElementXPath?: string;
  xmlSubfilterElementsXPath?: string;
  xmlXPathSmartHtmlSubfilter: boolean;
  xmlPreserveWhitespaceXPath?: string;
  xmlContextNoteXPath?: string;
  xmlMaxLengthXPath?: string;
  xmlXpathSmartInlineElement: boolean;
  xmlContextKeyXPath?: string;

  yamlTagRegexp?: string;
  yamlUseHtmlSubfilter: boolean;
  yamlContextKeyRegexp?: string;
  yamlContextNoteRegexp?: string;

  multilingualXmlTranslatableElementsXPath?: string;
  multilingualXmlSourceElementsXPath?: string;
  multilingualXmlTargetElementsXPath?: string;
  multilingualXmlNonTranslatableInlineElementsXPath?: string;
  multilingualXmlTagRegexp?: string;
  multilingualXmlSegment: boolean;
  multilingualXmlUseHtmlSubfilter: boolean;
  multilingualXmlContextNoteXPath?: string;
  multilingualXmlContextKeyXPath?: string;
  multilingualXmlMaxLengthXPath?: string;
  multilingualXmlPreserveWhitespace: boolean;

  macStringsUseHtmlSubfilter: boolean;
  macStringsTagRegexp?: string;

  androidStringTagRegexp?: string;

  phpTagRegexp?: string;
  phpUseHtmlSubfilter: boolean;

  resxTagRegexp?: string;

  pdfFilter: 'DEFAULT' | 'TRANS_PDF';

  quarkTagRemoveKerningTrackingTags: boolean;

  markdownTranslateImageAltText: boolean;
  markdownTranslateHeaderMetadata: boolean;
  markdownTranslateCodeBlocks: boolean;
  markdownTranslateInlineCodeBlocks: boolean;
  markdownTranslateUrls: boolean;
  markdownConvertToTags: string;
}

export interface ProjectTemplate extends ProjectBase, FileImportSettings {
  id?: number;
  templateName: string;
  name?: string;
  sourceLang?: string;
  targetLangs?: string[];
  note?: string;
  owner?: User;
  client?: Client;
  mediaType?: string;
  subject?: string;
  createdBy?: User;
  dateCreated?: string;
  workflowSteps?: WorkflowStep[];
  workflowSettings?: WorkflowSetting[];
  businessUnit?: BusinessUnit;
  costCenter?: CostCenter;
  // mtSettingsPerLanguage?: MtSettings;
  allowedApiClients?: string[];
  mtSettingsPerLanguage?: { [key: string]: MachineTranslateSettings };
  analyseSettings: {
    type: string;
    includeFuzzyRepetitions: boolean;
    includeConfirmedSegments: boolean;
    includeNumbers: boolean;
    includeLockedSegments: boolean;
    analyzeByLinguist: boolean;
    countSourceUnits: boolean;
  };
  preTranslateSettings: {
    useTranslationMemory: boolean;
    translationMemoryThreshold: number;
    overwrite: boolean;
    preTranslateNonTranslatables: boolean;
    confirmNonTranslatables: boolean;
    confirm100Matches: boolean;
    confirm101Matches: boolean;
    useMachineTranslation: boolean;
    insertMachineTranslationIntoTarget: boolean;
    lock100Matches: boolean;
    lock101Matches: boolean;
    lockNonTranslatables: boolean;
    setJobStatusCompleted: boolean;
    setProjectStatusCompleted: boolean;
    preTranslateOnJobCreation: boolean;
    pseudoTranslateReplacement: string;
    pseudoTranslateIntersperse: boolean;
    pseudoTranslatePrefix: string;
    pseudoTranslateSuffix: string;
    pseudoTranslateLength: number;
  };
  qualityAssuranceSettings: {
    emptyTranslation: boolean;
    inconsistentTranslation: boolean;
    joinTags: boolean;
    missingNumbers: boolean;
    segmentNotConfirmed: boolean;
    terminology: boolean;
    multipleSpaces: boolean;
    trailingSpace: boolean;
    trailingPunctuation: boolean;
    targetLength: {
      enabled: boolean;
      max: number;
    };
    formatting: boolean;
    unresolvedComment: boolean;
    emptyPairTags: boolean;
    strictJobStatus: boolean;
    forbiddenStrings: {
      enabled: boolean;
      list: string[];
    };
    excludeLockedSegments: boolean;
    ignoreNotApprovedTerms: boolean;
    spellCheck: boolean;
    repeatedWords: boolean;
    inconsistentTagContent: boolean;
    emptyTagContent: boolean;
    xliffTags: boolean;
    forbiddenTerms: boolean;
    targetLengthPercent: {
      enabled: boolean;
      max: number;
    };
    targetLengthPerSegment: boolean;
    newerAtPrecedingWorkflowStep: boolean;
    leadingAndTrailingSpaces: boolean;
    ignoreInAllWorkflowSteps: boolean;
    targetSourceIdentical: boolean;
    unmodifiedFuzzyTranslation: boolean;
  };
  spellCheckSettings: {
    checkCaps: boolean;
    checkAllCaps: boolean;
  };
  accessSettings: {
    downloadEnabled: boolean;
    webEditorEnabledForLinguists: boolean;
    showUserDataToLinguists: boolean;
    emailNotificationsByWorkflowStep: boolean;
    strictWorkflowFinish: boolean;
    useVendors: boolean;
    preventEmptyTargetJobCompletion: true;
  };
  notifySettings: {
    notifyNextWorkflowStepOnDelivered: boolean;
  };
  projectStatusAutomationSettings: {
    assignedWhenJobsAssigned: boolean;
    assignedWhenJobsAccepted: boolean;
    completedWhenJobsCompleted: boolean;
    completedWhenJobsDelivered: boolean;
    completedWhenJobsCompletedOrDelivered: boolean;
    cancelledWhenJobsCancelled: boolean;
    completedWhenProjectCompletedByVendor: boolean;
  };
  fileNamingSettings: {
    renameCompleted: boolean;
    completedFilePattern: string;
  };
  transMemoryMatchSettings: {
    contextType: 'AUTO' | 'PREV_AND_NEXT_SEGMENT' | 'SEGMENT_KEY' | 'NO_CONTEXT';
    prevOrNextSegment: boolean;
    penalizeMultiContextMatch: boolean;
  };
  workflowStepConfigs: {
    assignedUserIds: number[];
    assignedUserIdsByTargetLocale: { [localeCode: string]: number[] };
    assignPerTargetLocale: boolean;
    notifyLinguists: EmailNotificationSettings;
    dueDate?: string;
  };
}

export interface BackgroundTask {
  id: number;
  status: 'RUNNING' | 'ERROR' | 'OK';
  reason?: string;
  finishedDataText?: string;
}

export interface Job {
  id: number;
  rootId: number;
  uid: string;
  internalId: number;
  hasParent?: boolean;
  fileName: string;
  status: string;
  sourceLocale: string;
  targetLocale: string;
  workflowStep?: {
    id: number;
    name: string;
    level: number;
    type: string;
  };
  assignees: User[];
  dateCreated: string;
  dateDue: string;
  creationTask: BackgroundTask;
  lastTask: BackgroundTask;
  hasOffer: boolean;
  client?: Client;
  businessUnit?: string;
  buyer?: string;
  project?: {
    id: number;
    uid: string;
    internalId: number;
    name: string;
    note: string;
    allowLinguistsToDownloadTranslationJobs?: boolean;
  };
}

export interface SegmentCounts {
  completed?: boolean;
  wordsCount: number;
  lockedSegmentsCount: number;
  machineTranslationRelevantSegmentsCount: number;
  confirmedSegmentsCount: number;
  confirmedCharsCount: number;
  qualityAssurance: { segmentsCount: number; warningsCount: number; ignoredWarningsCount: number };
  translatedSegmentsCount: number;
  machineTranslationPostEditedSegmentsCount: number;
  charsCount: number;
  qualityAssuranceResolved: number;
  segmentsCount: number;
  segmentGroupsCount: number;
  allConfirmed: number;
  translatedLockedSegmentsCount: number;
}

export interface SegmentMetadata {
  counts: SegmentCounts;
  previousWorkflow?: { completed: boolean; counts: SegmentCounts };
}

export interface Id {
  id: string;
}

export interface SavedFilter {
  url: string;
  text: string;
}

export interface TableColumnDef<Item = Table.Item> extends Table.ColumnDefinition<Item> {
  canHide?: boolean;
  canSort?: boolean;
  canReorder?: boolean;
  visible?: boolean;
  alternateName?: string;
}

export interface TablePref extends Id {
  visible?: boolean;
  width?: number;
}

export interface TablePropertyFilteringOption extends TablePropertyFiltering.Option {
  filterByMultiple?: boolean;
  isFreeText?: boolean;
}

export interface JobListApi {
  level?: number;
  page?: number;
  assignedTo?: number[];
  status?: string[];
  fileName?: string[];
  targetLocale?: string[];
  dueInHours?: number[];
}

export interface SelectedJobPartApi {
  jobPartId: number;
}

export interface SelectAllOnServerApi extends JobListApi {
  jobPartIds: number[];
  selectAllOnServer: boolean;
  totalSelectedJobs?: number;
}

export interface CXMSurveyDTO {
  isShowQuestion?: boolean;
  questionId?: number;
  questionText?: string;
  answer?: number;
  userId?: number;
  federateId?: string;
  timestamp?: number;
  sourceUrl?: string;
  isSurveyAlreadyDisplayed?: boolean;
  organizationName?: string;
}

interface ScoringModel {
  id: number;
  threshold: number;
  name: string;
}

export interface Group {
  id: string;
  score: number;
  jobCounts: {
    total: number;
    pending: number;
    succeeded: number;
    failed: number;
  };
  passing: boolean;
  scoringModel: ScoringModel;
  status: string;
  scoredWordCount: number;
  totalWordCount: number;
}

export interface ScoringJob {
  id: string;
  status: string;
  threshold: number;
  score: number;
  passing: boolean;
  scoredWordCount: number;
}

export interface Language {
  code: string;
  name: string;
}

export interface MediaType {
  id?: string;
  name: string;
}

export interface Subject {
  id?: string;
  name: string;
}

export interface Client {
  id?: number;
  name: string;
}

export interface Owner {
  id?: number;
  name: string;
}

export interface BusinessUnit {
  id?: number;
  name: string;
}

export interface CostCenter {
  id?: number;
  name: string;
}

export type AnalysisType = 'PreAnalyse' | 'PostAnalyse' | 'PreAnalyseTarget' | 'Compare';
