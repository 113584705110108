import React, { Fragment, useCallback, useEffect, useReducer, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  Header,
  SpaceBetween,
  Spinner,
} from '@amzn/awsui-components-react-v3';
import I18n from '../../../setupI18n';
import { withRouter } from 'react-router';
import { AtmsApiClient } from '@amzn/et-console-components';
import { publishCountMetric } from '../../metricHelper';

interface ReducerState {
  hasAsinLocAsVendor: boolean;
  isError: boolean;
  apiError?: string;
  isLoading: boolean;
  isSavingPostMortemScoringSettings: boolean;
}

export const PostMortemScoringSettings = withRouter(({ history }) => {
  const init = (): ReducerState => ({
    isLoading: false,
    isSavingPostMortemScoringSettings: false,
    isError: false,
    hasAsinLocAsVendor: false,
    apiError: '',
  });

  const reducer = (state, action): ReducerState => {
    switch (action.type) {
      case 'loading':
        return {
          ...state,
          isLoading: true,
          isError: false,
        };
      case 'finishedLoading':
        return {
          ...state,
          isLoading: false,
          hasAsinLocAsVendor: action.hasAsinLocAsVendor,
        };
      case 'loadingFailed':
        return {
          ...state,
          isLoading: false,
          isError: true,
          apiError: action.error,
        };
      case 'savingPostMortemScoringSettings':
        return {
          ...state,
          isSavingPostMortemScoringSettings: true,
          isError: false,
        };
      case 'postMortemScoringSettingsSaved':
        return {
          ...state,
          isSavingPostMortemScoringSettings: false,
          postMortemScoringEnabled: action.enabled,
          isError: false,
        };
      case 'savingPostMortemScoringSettingsFailed':
        return {
          ...state,
          isSavingPostMortemScoringSettings: false,
          isError: true,
          apiError: action.error,
        };
      default:
        return state;
    }
  };

  const [isChecked, setIsChecked] = useState(false);
  const [reducerState, dispatch] = useReducer(reducer, null, init);
  const {
    isLoading,
    isSavingPostMortemScoringSettings,
    isError,
    apiError,
    hasAsinLocAsVendor,
  } = reducerState;

  const handleLoad = useCallback(async () => {
    dispatch({ type: 'loading' });

    let vendorOrgIds;
    try {
      const vendorTokensByOrgId = await AtmsApiClient.httpGet('/api/vendors/listVendorTokens');
      vendorOrgIds = Object.keys(vendorTokensByOrgId).map(id => parseInt(id));
    } catch (err) {
      publishCountMetric(
        'loadingVendorTokensFailed-PostMortemScoringSettings',
        'error',
        err.details.errors[0].message
      );
      dispatch({ type: 'loadingFailed', error: err.details.errors[0].message });
      return;
    }

    try {
      const postMortemScoringSettings = await AtmsApiClient.httpGet(
        '/api/postMortemScoringSettings'
      );
      const hasAsinLocAsVendor = postMortemScoringSettings.administrarOrgIds?.some(
        administrarOrgId => vendorOrgIds.includes(administrarOrgId)
      );
      setIsChecked(postMortemScoringSettings.enabled);
      dispatch({ type: 'finishedLoading', hasAsinLocAsVendor: hasAsinLocAsVendor });
    } catch (err) {
      publishCountMetric(
        'loadingPostMortemScoringSettingsFailed-PostMortemScoringSettings',
        'error',
        err.details.errors[0].message
      );
      dispatch({ type: 'loadingFailed', error: err.details.errors[0].message });
      console.warn('Failed to load current post mortem settings');
    }
  }, []);

  const handleSubmit = async (): Promise<void> => {
    dispatch({ type: 'savingPostMortemScoringSettings' });

    const request = {
      enablePostMortemScoring: isChecked,
    };

    try {
      const response = await AtmsApiClient.httpPut('/api/postMortemScoringSettings', request);
      dispatch({ type: 'postMortemScoringSettingsSaved', enabled: response.enabled });
      history.push('/web/setup/index');
    } catch (err) {
      publishCountMetric(
        'savingPostMortemScoringSettingsFailed-PostMortemScoringSettings',
        'error',
        err.details.errors[0].message
      );
      dispatch({
        type: 'savingPostMortemScoringSettingsFailed',
        error: err.details.errors[0].message,
      });
    }
  };

  useEffect(() => {
    handleLoad();
  }, [handleLoad]);

  return (
    <Fragment>
      {isError && (
        <Alert id={'error'} type={'error'} dismissible>
          {apiError}
        </Alert>
      )}
      <Container header={<Header variant={'h2'}>{I18n.t('Sampled Post-Delivery QA')}</Header>}>
        {!isLoading && !hasAsinLocAsVendor && (
          <Alert id={'asinLocInfo'} type={'info'} dismissible>
            {I18n.t(
              'You must add ASIN Loc as a vendor before you can enable Sampled Post-Delivery QA'
            )}
          </Alert>
        )}
        {isLoading ? (
          <Spinner size="big" />
        ) : (
          <Box margin={'s'}>
            <SpaceBetween size={'m'}>
              <Checkbox
                id={'enabledCheckbox'}
                onChange={({ detail }) => setIsChecked(detail.checked)}
                checked={isChecked}
                disabled={!hasAsinLocAsVendor}
              >
                {I18n.t('Allow ASIN Loc to perform Sampled Post-Delivery QA on my projects')}
              </Checkbox>
              <Button
                id={'save-postMortemScoring'}
                variant="primary"
                disabled={isSavingPostMortemScoringSettings}
                loading={isSavingPostMortemScoringSettings}
                onClick={handleSubmit}
              >
                {I18n.t('Save')}
              </Button>
            </SpaceBetween>
          </Box>
        )}
      </Container>
    </Fragment>
  );
});
