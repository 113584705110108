import { useState, useLayoutEffect } from 'react';

const getSize = () => ({
  width: window.innerWidth,
  height: window.innerHeight,
});

export default () => {
  const [size, setSize] = useState(getSize);

  useLayoutEffect(() => {
    const updateSize = () => setSize(getSize());
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
};
