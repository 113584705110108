import React, { ReactElement, ReactNode, useMemo, useReducer } from 'react';
import * as yup from 'yup';
import {
  Checkbox,
  ColumnLayout,
  Flash,
  Form,
  FormField,
  FormSection,
  Icon,
  Input,
  Select,
} from '@amzn/awsui-components-react/polaris';
import I18n from '../../setupI18n';
import styled from '@emotion/styled';
import { css } from 'emotion';
import { HelpInfoLink } from '../HelpContentRouter';
import { PROJECT_CREATE_PRETRANSLATE_HELP } from './projectHelpContent';
import { Language, MachineTranslateSettings, Project, ProjectTemplate } from '../types/commonTypes';
import { FormikProps } from 'formik';
import { coalesceSummaryValue, WizardStepWithSummary } from '../components/WizardWithSummary';
import { Container } from '@amzn/et-polaris-utils';
import { T } from '../components/T';
import { AuthenticatedSession } from '@amzn/et-console-components';
import { TranslationMemoryMatchingSettingsConfiguration } from './translationmemory/TranslationMemoryMatchingSettings';

const formSpacing = css`
  padding-top: 14px;
  padding-bottom: 14px;
`;

const FormContainer = styled('div')`
  max-width: 800px;
  min-width: 280px;
`;
const IndentedFormSection = styled('div')`
  margin-left: 20px;
`;

const TmMatchThresholdContainer = styled('div')`
  display: inline-block;
  max-width: 70px;
  margin-right: 3px;
  margin-bottom: 10px;
`;

const TopLevelCheckboxText = styled('span')`
  font-size: 1.4rem;
  color: var(--awsui-color-text-form-label);
`;

export interface Props<T extends ProjectCreateAPIStep3> extends FormikProps<T> {
  isLoadingMachineTranslateSettings: boolean;
  isLoadingActiveLanguages: boolean;
  machineTranslateSettings: MachineTranslateSettings[];
  machineTranslateSettingsById: { [id: number]: MachineTranslateSettings };
  activeLanguages: Language[];
  activeLanguagesByCode: { [code: string]: Language };
  validating: boolean;
  session?: AuthenticatedSession;
}

export interface ProjectCreateAPIStep3 {
  targetLocales?: string[]; // Redefine target locales so they can be used to build the per-locale MT settings

  useTranslationMemory: boolean;
  tmMatchThreshold: number;
  useMachineTranslation: boolean;
  preTranslateNonTranslatables: boolean;
  machineTranslateSettingsId?: number;
  perLocaleMachineTranslation: boolean;
  perLocaleMachineTranslateSettingsIds: { [locale: string]: number };
  automaticallyPretranslate: boolean;
  confirmNonTranslatable: boolean;
  confirm100Match: boolean;
  confirm101Match: boolean;
  lockNonTranslatable: boolean;
  lock100Match: boolean;
  lock101Match: boolean;
  completeJobsOncePretranslated: boolean;
  completeProjectOnceAllJobsPretranslated: boolean;
  overwriteExistingTranslationsWithPretranslation: boolean;
  contextType: 'AUTO' | 'PREV_AND_NEXT_SEGMENT' | 'SEGMENT_KEY' | 'NO_CONTEXT';
  previousOrNextSegmentContextAs101: boolean;
  penalizeMultiple101TMMatchesBy1: boolean;
}

interface ReducerState {
  isError: boolean;
  error?: string;
}

export const getDefaultMachineTranslationEngine = (
  session?: AuthenticatedSession,
  machineTranslateSettings?: MachineTranslateSettings[]
): number | undefined => {
  let machineTranslateSettingsDefaultId =
    session?.user?.machineTranslateSettingsDefaultId ?? undefined;

  // In case the user deletes the MachineTranslateEngine that was the default one, we should check
  // if it exists on machineTranslateSettingsPlusNone list, if not, use None (0)
  machineTranslateSettingsDefaultId = (machineTranslateSettings ?? []).some(
    x => x.id === machineTranslateSettingsDefaultId
  )
    ? machineTranslateSettingsDefaultId
    : undefined;

  return machineTranslateSettingsDefaultId;
};

export const schemaStep3 = yup.object().shape({
  useTranslationMemory: yup.boolean(),
  tmMatchThreshold: yup
    .number()
    .integer(I18n.t('Match threshold must be a whole number'))
    .max(101, I18n.t('Match threshold must be less than or equal to 101%'))
    .min(0, I18n.t('Match threshold must be greater than or equal to 0%'))
    .nullable(),
  useMachineTranslation: yup.boolean(),
  preTranslateNonTranslatables: yup.boolean(),
  machineTranslateSettingsId: yup.number().nullable(),
  perLocaleMachineTranslation: yup.boolean(),
  perLocaleMachineTranslateSettingsIds: yup.object(),
  // TODO: Support per-language MT
  automaticallyPretranslate: yup.boolean(),
  confirmNonTranslatable: yup.boolean(),
  confirm100Match: yup.boolean(),
  confirm101Match: yup.boolean(),
  lockNonTranslatable: yup.boolean(),
  lock100Match: yup.boolean(),
  lock101Match: yup.boolean(),
  completeJobsOncePretranslated: yup.boolean(),
  completeProjectOnceAllJobsPretranslated: yup.boolean(),
  overwriteExistingTranslationsWithPretranslation: yup.boolean(),
  contextType: yup.string(),
  previousOrNextSegmentContextAs101: yup.boolean(),
  penalizeMultiple101TMMatchesBy1: yup.boolean(),
});

export const instanceOfProjectTemplate = (
  data: Project | ProjectTemplate | undefined
): data is ProjectTemplate => {
  return (
    (data as ProjectTemplate)?.templateName !== undefined ||
    (data as Project)?.projectTemplateId !== undefined
  );
};

export const getPerLocaleMTSettingsId = (
  currentValue,
  defaultMachineTranslationEngine
): { [locale: string]: number } => {
  const perLocaleMTSettingsId = {};
  currentValue?.perLocaleMachineTranslateSettingsIds &&
    (currentValue?.targetLangs ?? []).forEach(t => {
      if (instanceOfProjectTemplate(currentValue)) {
        perLocaleMTSettingsId[t] =
          currentValue?.perLocaleMachineTranslateSettingsIds[t] ?? undefined;
      } else {
        perLocaleMTSettingsId[t] = defaultMachineTranslationEngine ?? undefined;
      }
    });
  return perLocaleMTSettingsId;
};

export const initialFormValuesStep3 = (
  currentValue?: Project | ProjectTemplate,
  defaultMachineTranslationEngine?: number
): ProjectCreateAPIStep3 => {
  const perLocaleMTSettingsId = getPerLocaleMTSettingsId(
    currentValue,
    defaultMachineTranslationEngine
  );
  //we want to keep the template's MT engine setting instead of overriding it with the org's default MT Engine
  const machineTranslateSettingsId = instanceOfProjectTemplate(currentValue)
    ? currentValue?.machineTranslateSettingsId
    : currentValue?.machineTranslateSettingsId ?? defaultMachineTranslationEngine;
  return {
    useTranslationMemory: currentValue?.useTranslationMemory ?? true,
    tmMatchThreshold: currentValue?.tmMatchThreshold ?? 75,
    useMachineTranslation: currentValue?.useMachineTranslation ?? true,
    preTranslateNonTranslatables:
      currentValue?.preTranslateSettings?.preTranslateNonTranslatables ?? false,
    machineTranslateSettingsId: machineTranslateSettingsId,
    perLocaleMachineTranslation: currentValue?.perLocaleMachineTranslation ?? false,
    perLocaleMachineTranslateSettingsIds: perLocaleMTSettingsId,
    automaticallyPretranslate: currentValue?.automaticallyPretranslate ?? true,
    confirmNonTranslatable: currentValue?.confirmNonTranslatable ?? false,
    confirm100Match: currentValue?.confirm100Match ?? false,
    confirm101Match: currentValue?.confirm101Match ?? false,
    lockNonTranslatable: currentValue?.lockNonTranslatable ?? false,
    lock100Match: currentValue?.lock100Match ?? false,
    lock101Match: currentValue?.lock101Match ?? false,
    completeJobsOncePretranslated: currentValue?.completeJobsOncePretranslated ?? false,
    completeProjectOnceAllJobsPretranslated:
      currentValue?.completeProjectOnceAllJobsPretranslated ?? false,
    overwriteExistingTranslationsWithPretranslation:
      currentValue?.overwriteExistingTranslationsWithPretranslation ?? false,
    contextType: currentValue?.contextType ?? 'PREV_AND_NEXT_SEGMENT',
    previousOrNextSegmentContextAs101: currentValue?.previousOrNextSegmentContextAs101 ?? false,
    penalizeMultiple101TMMatchesBy1: currentValue?.penalizeMultiple101TMMatchesBy1 ?? false,
  };
};

const Content = <T extends ProjectCreateAPIStep3>({
  errors,
  values,
  setFieldValue,
  handleBlur,
  handleChange,
  validating,
  isLoadingMachineTranslateSettings,
  machineTranslateSettings,
  activeLanguages,
}: Props<T>): ReactElement => {
  const init = (): ReducerState => ({
    isError: false,
  });

  const reducer = (state, action): ReducerState => {
    switch (action.type) {
      default:
        return state;
    }
  };

  const activeLangNameMap = useMemo(
    () =>
      activeLanguages.reduce((acc, val) => {
        acc[val.code] = val.name;
        return acc;
      }, {}),
    [activeLanguages]
  );

  const [reducerState] = useReducer(reducer, null, init);

  const handleMTChange = (e): void => {
    setFieldValue(
      'machineTranslateSettingsId',
      e.detail.selectedId === '0' ? undefined : parseInt(e.detail.selectedId)
    );
  };

  const handlePerLocaleMTChange = (e): void => {
    const controlId = e.target && e.target.component.__properties.controlId;
    const localeCode = controlId.split('.')[1];

    setFieldValue('perLocaleMachineTranslateSettingsIds', {
      ...values.perLocaleMachineTranslateSettingsIds,
      [localeCode]: e.detail.selectedId > 0 ? e.detail.selectedId : undefined,
    });
  };

  const handlePreTranslateJobCompleteChange = (e): void => {
    handleChange(e);
    if (!e.detail.checked) {
      setFieldValue('completeProjectOnceAllJobsPretranslated', false);
    }
  };

  const machineTranslateSettingsPlusNone: MachineTranslateSettings[] = [
    { id: 0, name: 'None' },
    ...machineTranslateSettings,
  ];
  return (
    <FormContainer>
      <Form
        errorText={
          validating && Object.keys(errors).length !== 0
            ? I18n.t('The form contains errors. Fix them and resubmit.')
            : ''
        }
      >
        <FormSection header={I18n.t('Setup')}>
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField>
                <Checkbox
                  description={
                    <TopLevelCheckboxText>
                      {I18n.t('Pre-translate from translation memory')}
                    </TopLevelCheckboxText>
                  }
                  id="useTranslationMemory"
                  controlId="useTranslationMemory"
                  checked={values.useTranslationMemory}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <IndentedFormSection>
                <FormField
                  label={I18n.t('Match threshold')}
                  description={I18n.t(
                    "Don't use translation matches scoring below this threshold. Segments with no translation memory matches above the threshold will be translated with machine translation if it's enabled."
                  )}
                  errorText={validating && errors['tmMatchThreshold']}
                >
                  <TmMatchThresholdContainer>
                    <Input
                      id="tmMatchThreshold"
                      controlId="tmMatchThreshold"
                      type="number"
                      value={'' + values.tmMatchThreshold}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!values.useTranslationMemory}
                    />
                  </TmMatchThresholdContainer>
                  %
                </FormField>
              </IndentedFormSection>
              <FormField>
                <Checkbox
                  description={
                    <TopLevelCheckboxText>
                      {I18n.t('Pre-translate from machine translation')}
                    </TopLevelCheckboxText>
                  }
                  id="useMachineTranslation"
                  controlId="useMachineTranslation"
                  checked={values.useMachineTranslation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <IndentedFormSection>
                <FormField>
                  <Checkbox
                    description={
                      <TopLevelCheckboxText>
                        {I18n.t('Per-locale machine translation engines')}
                      </TopLevelCheckboxText>
                    }
                    id="perLocaleMachineTranslation"
                    controlId="perLocaleMachineTranslation"
                    checked={values.perLocaleMachineTranslation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!values.useMachineTranslation}
                  />
                </FormField>
                {!values.perLocaleMachineTranslation && (
                  <FormField
                    label={I18n.t('Machine translation engine')}
                    errorText={validating && errors['machineTranslateSettingsId']}
                  >
                    <Select
                      loading={isLoadingMachineTranslateSettings}
                      filteringType="auto"
                      options={machineTranslateSettingsPlusNone.map(machineTranslateSetting => {
                        return {
                          id: '' + machineTranslateSetting.id,
                          label: machineTranslateSetting.name,
                        };
                      })}
                      selectedId={'' + (values.machineTranslateSettingsId ?? 0)}
                      disabled={!values.useMachineTranslation}
                      onChange={handleMTChange}
                      onBlur={handleBlur}
                      controlId="machineTranslateSettingsId"
                      id="machineTranslateSettingsId"
                    />
                  </FormField>
                )}
                {values.targetLocales && values.perLocaleMachineTranslation && (
                  <FormField
                    label={I18n.t('Machine translation engine')}
                    errorText={validating && errors['perLocaleMachineTranslateSettingsId']}
                  >
                    {values.targetLocales.map(locale => (
                      <div key={locale}>
                        <h4>{activeLangNameMap[locale]}</h4>
                        <Select
                          loading={isLoadingMachineTranslateSettings}
                          filteringType="auto"
                          options={machineTranslateSettingsPlusNone.map(machineTranslateSetting => {
                            return {
                              id: '' + machineTranslateSetting?.id,
                              label: machineTranslateSetting?.name,
                            };
                          })}
                          selectedId={
                            '' + (values.perLocaleMachineTranslateSettingsIds[locale] ?? 0)
                          }
                          disabled={!values.useMachineTranslation}
                          onChange={handlePerLocaleMTChange}
                          onBlur={handleBlur}
                          controlId={`perLocaleMachineTranslateSettingsIds.${locale}`}
                          id={`perLocaleMachineTranslateSettingsIds.${locale}`}
                        />
                      </div>
                    ))}
                  </FormField>
                )}
              </IndentedFormSection>
              <FormField
                hintText={I18n.t(
                  'Automatically run pre-translation for any new content added to the project'
                )}
              >
                <Checkbox
                  description={
                    <TopLevelCheckboxText>
                      {I18n.t('Automatically pre-translate')}
                    </TopLevelCheckboxText>
                  }
                  id="automaticallyPretranslate"
                  controlId="automaticallyPretranslate"
                  checked={values.automaticallyPretranslate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  description={
                    <TopLevelCheckboxText>
                      {I18n.t('Pre-translate non-translatables')}
                    </TopLevelCheckboxText>
                  }
                  id="preTranslateNonTranslatables-checkbox"
                  controlId="preTranslateNonTranslatables"
                  checked={values.preTranslateNonTranslatables}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </FormSection>
        <FormSection header={I18n.t('Actions')}>
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField label={I18n.t('Confirm segments when...')}>
                <Checkbox
                  description={I18n.t('Non-translatable')}
                  id="confirmNonTranslatable"
                  controlId="confirmNonTranslatable"
                  checked={values.confirmNonTranslatable}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Checkbox
                  description={I18n.t('100% match')}
                  id="confirm100Match"
                  controlId="confirm100Match"
                  checked={values.confirm100Match}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Checkbox
                  description={I18n.t('101% match')}
                  id="confirm101Match"
                  controlId="confirm101Match"
                  checked={values.confirm101Match}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField label={I18n.t('Lock segments when...')}>
                <Checkbox
                  description={I18n.t('Non-translatable')}
                  id="lockNonTranslatable"
                  controlId="lockNonTranslatable"
                  checked={values.lockNonTranslatable}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Checkbox
                  description={I18n.t('100% match')}
                  id="lock100Match"
                  controlId="lock100Match"
                  checked={values.lock100Match}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Checkbox
                  description={I18n.t('101% match')}
                  id="lock101Match"
                  controlId="lock101Match"
                  checked={values.lock101Match}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  description={
                    <TopLevelCheckboxText>
                      {I18n.t('Complete jobs once pre-translated')}
                    </TopLevelCheckboxText>
                  }
                  id="completeJobsOncePretranslated"
                  controlId="completeJobsOncePretranslated"
                  checked={values.completeJobsOncePretranslated}
                  onChange={handlePreTranslateJobCompleteChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <IndentedFormSection>
                <FormField>
                  <Checkbox
                    description={
                      <TopLevelCheckboxText>
                        {I18n.t('Set project to completed once all jobs pre-translated')}
                      </TopLevelCheckboxText>
                    }
                    id="completeProjectOnceAllJobsPretranslated"
                    controlId="completeProjectOnceAllJobsPretranslated"
                    checked={values.completeProjectOnceAllJobsPretranslated}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!values.completeJobsOncePretranslated}
                  />
                </FormField>
              </IndentedFormSection>
              <FormField>
                <Checkbox
                  description={
                    <TopLevelCheckboxText>
                      {I18n.t('Overwrite existing translations in target segments')}
                    </TopLevelCheckboxText>
                  }
                  id="overwriteExistingTranslationsWithPretranslation"
                  controlId="overwriteExistingTranslationsWithPretranslation"
                  checked={values.overwriteExistingTranslationsWithPretranslation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </FormSection>{' '}
        <TranslationMemoryMatchingSettingsConfiguration
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
        {reducerState.isError && (
          <div className={formSpacing}>
            <Flash
              id="validation-error"
              type="error"
              dismissible={true}
              content={I18n.t('Please try again. If the issue persists, contact support.')}
              header={I18n.t('Something went wrong.')}
            />
          </div>
        )}
      </Form>
    </FormContainer>
  );
};

export const ProjectCreateStep3Pretranslate = <T extends ProjectCreateAPIStep3>(
  props: Props<T>
): WizardStepWithSummary => {
  const title = I18n.t('Pre-translate');
  return {
    title: title,
    info: (): ReactElement => <HelpInfoLink helpId={PROJECT_CREATE_PRETRANSLATE_HELP} />,
    description: I18n.t('Configure how the content will be pre-translated'),
    content: (): ReactElement => <Content {...props} />,
    isOptional: true,
    summary: ({ currentValue }): ReactNode => {
      const { values, activeLanguagesByCode, machineTranslateSettingsById } = props;
      const summaryElements = [
        coalesceSummaryValue({
          label: I18n.t('Use translation memory'),
          value: values.useTranslationMemory,
          currentValue: currentValue?.useTranslationMemory,
          displayValue: values.useTranslationMemory ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          label: I18n.t('Match threshold'),
          value: values.tmMatchThreshold,
          currentValue: currentValue?.tmMatchThreshold,
          displayValue: `${values.tmMatchThreshold}%`,
        }),
        coalesceSummaryValue({
          label: I18n.t('Use machine translation'),
          value: values.useMachineTranslation,
          currentValue: currentValue?.useMachineTranslation,
          displayValue: values.useMachineTranslation ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          label: I18n.t('Pre-translate non-translatables'),
          value: values.preTranslateNonTranslatables,
          currentValue: currentValue?.preTranslateNonTranslatables,
          displayValue: values.preTranslateNonTranslatables ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          label: I18n.t('Machine translation engine'),
          value: values.machineTranslateSettingsId,
          currentValue: currentValue?.machineTranslateSettingsId,
          displayValue:
            values.machineTranslateSettingsId &&
            machineTranslateSettingsById[values.machineTranslateSettingsId]
              ? machineTranslateSettingsById[values.machineTranslateSettingsId].name
              : '-',
        }),
        coalesceSummaryValue({
          label: I18n.t('Per-locale machine translation'),
          value: values.perLocaleMachineTranslation,
          currentValue: currentValue?.perLocaleMachineTranslation,
          displayValue: values.perLocaleMachineTranslation ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          label: I18n.t('Per-locale machine translation engines'),
          value: values.perLocaleMachineTranslateSettingsIds,
          currentValue: currentValue?.perLocaleMachineTranslateSettingsIds,
          displayValue: values.perLocaleMachineTranslateSettingsIds
            ? Object.entries(values.perLocaleMachineTranslateSettingsIds).map(([locale, mtId]) => (
                <div key={locale}>{`${activeLanguagesByCode[locale]?.name ??
                  locale}: ${machineTranslateSettingsById[mtId]?.name ?? I18n.t('None')}`}</div>
              ))
            : '-',
        }),
        coalesceSummaryValue({
          label: I18n.t('Automatically pre-translate'),
          value: values.automaticallyPretranslate,
          currentValue: currentValue?.automaticallyPretranslate,
          displayValue: values.automaticallyPretranslate ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          label: I18n.t('Confirm segments when non-translatable'),
          value: values.confirmNonTranslatable,
          currentValue: currentValue?.confirmNonTranslatable,
          displayValue: values.confirmNonTranslatable ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          label: I18n.t('Confirm segments when 100% match'),
          value: values.confirm100Match,
          currentValue: currentValue?.confirm100Match,
          displayValue: values.confirm100Match ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          label: I18n.t('Confirm segments when 101% match'),
          value: values.confirm101Match,
          currentValue: currentValue?.confirm101Match,
          displayValue: values.confirm101Match ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          label: I18n.t('Lock segments when non-translatable'),
          value: values.lockNonTranslatable,
          currentValue: currentValue?.lockNonTranslatable,
          displayValue: values.lockNonTranslatable ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          label: I18n.t('Lock segments when 100% match'),
          value: values.lock100Match,
          currentValue: currentValue?.lock100Match,
          displayValue: values.lock100Match ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          label: I18n.t('Lock segments when 101% match'),
          value: values.lock101Match,
          currentValue: currentValue?.lock101Match,
          displayValue: values.lock101Match ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          label: I18n.t('Complete jobs once pre-translated'),
          value: values.completeJobsOncePretranslated,
          currentValue: currentValue?.completeJobsOncePretranslated,
          displayValue: values.completeJobsOncePretranslated ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          label: I18n.t('Complete project once all jobs pre-translated'),
          value: values.completeProjectOnceAllJobsPretranslated,
          currentValue: currentValue?.completeProjectOnceAllJobsPretranslated,
          displayValue: values.completeProjectOnceAllJobsPretranslated ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          label: I18n.t('Overwrite existing translations in target segments'),
          value: values.overwriteExistingTranslationsWithPretranslation,
          currentValue: currentValue?.overwriteExistingTranslationsWithPretranslation,
          displayValue: values.overwriteExistingTranslationsWithPretranslation ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.contextType,
          value: values.contextType,
          label: I18n.t('Context type'),
          displayValue:
            values.contextType === 'AUTO'
              ? I18n.t('Automatic')
              : values.contextType === 'PREV_AND_NEXT_SEGMENT'
              ? I18n.t('Previous and next segment')
              : values.contextType === 'SEGMENT_KEY'
              ? I18n.t('Segment key')
              : I18n.t('No context'),
        }),
        coalesceSummaryValue({
          label: I18n.t('Previous OR next segment context as 101%'),
          value: values.previousOrNextSegmentContextAs101,
          currentValue: currentValue?.previousOrNextSegmentContextAs101,
          displayValue: values.previousOrNextSegmentContextAs101 ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          label: I18n.t('Penalize multiple 101% TM matches by 1%'),
          value: values.penalizeMultiple101TMMatchesBy1,
          currentValue: currentValue?.penalizeMultiple101TMMatchesBy1,
          displayValue: values.penalizeMultiple101TMMatchesBy1 ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
      ].filter(e => e);

      return (
        <Container title={I18n.t('Pre-translate')} key={title}>
          <ColumnLayout columns={4}>
            <div data-awsui-column-layout-root="true">
              {summaryElements.length > 0 ? (
                summaryElements
              ) : (
                <div>
                  <div>
                    <T>No changes</T>
                  </div>
                </div>
              )}
            </div>
          </ColumnLayout>
        </Container>
      );
    },
  };
};
