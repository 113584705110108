import React, { ReactElement, useState } from 'react';
import { CustomDetailEvent, Table, TableSelection } from '@amzn/awsui-components-react/polaris';
import { CellContents, TableColumnDef } from '../types/commonTypes';

export interface SelectionChangeDetailWithSelectAllOnServer<Item = Table.Item>
  extends TableSelection.SelectionChangeDetail<Item> {
  selectAllOnServer: boolean;
}

export interface TableSelectionWithSelectAllOnServerProps<Item = Table.Item>
  extends TableSelection.Props {
  pageSize: number;

  onSelectionChangeWithSelectAllOnServer?: (
    event: CustomDetailEvent<SelectionChangeDetailWithSelectAllOnServer<Item>>
  ) => void;
}

export const TableSelectionWithSelectAllOnServer = ({
  selectedItems,
  onSelectionChangeWithSelectAllOnServer,
  ...props
}: TableSelectionWithSelectAllOnServerProps): ReactElement => {
  const [selectAllOnServerItem, setSelectAllOnServerItem] = useState<Table.Item>();

  const newOnSelectionChange = ({
    detail: { selectedItems },
    ...event
  }: CustomDetailEvent<TableSelection.SelectionChangeDetail>): void => {
    // @ts-ignore
    const selectAllOnServerItem = selectedItems.find(i => i.selectAllOnServer);
    // @ts-ignore
    selectedItems = selectedItems.filter(i => !i.selectAllOnServer);
    if (onSelectionChangeWithSelectAllOnServer) {
      onSelectionChangeWithSelectAllOnServer({
        detail: { selectedItems, selectAllOnServer: !!selectAllOnServerItem },
        ...event,
      });
    }
    setSelectAllOnServerItem(selectAllOnServerItem);
  };

  const selectedItemsWithSelectAllOnServerItem =
    selectedItems && selectAllOnServerItem
      ? [selectAllOnServerItem, ...selectedItems]
      : selectedItems;

  return (
    <TableSelection
      selectedItems={selectedItemsWithSelectAllOnServerItem}
      onSelectionChange={newOnSelectionChange}
      {...props}
    />
  );
};
TableSelectionWithSelectAllOnServer.displayName = 'TableSelectionWithSelectAllOnServer';

export interface ItemWithSelectAllOnServer extends Table.Item {
  selectAllOnServer?: boolean;
}

export interface TableWithSelectAllOnServerProps extends Table.Props {
  selectAllOnServerColumnId: string;
  selectAllOnServerLabel: JSX.Element;
  children?: any; //https://stackoverflow.com/a/55858755
  columnDefinitions?: TableColumnDef<ItemWithSelectAllOnServer>[] | null;
  items?: ItemWithSelectAllOnServer[] | null;
  totalItems: number;
}

export const TableWithSelectAllOnServer = ({
  selectAllOnServerColumnId,
  selectAllOnServerLabel,
  items,
  totalItems,
  columnDefinitions,
  children,
  ...props
}: TableWithSelectAllOnServerProps): ReactElement => {
  const tableSelection: ReactElement = children.find(
    c => c.type.displayName === 'TableSelectionWithSelectAllOnServer'
  );

  let extendedItems = items;
  if (tableSelection && items) {
    extendedItems =
      tableSelection.props.selectedItems.length >= items.length && totalItems > items.length
        ? [{ selectAllOnServer: true }, ...items]
        : items;
  }

  let extendedColumnDefs = columnDefinitions;
  if (columnDefinitions) {
    extendedColumnDefs = columnDefinitions.map(c => {
      return {
        ...c,
        cell: (item): CellContents => {
          if (item.selectAllOnServer) {
            return c.id === selectAllOnServerColumnId && selectAllOnServerLabel;
          }
          // @ts-ignore
          return c.cell(item);
        },
      };
    });
  }

  return (
    <Table items={extendedItems} columnDefinitions={extendedColumnDefs} {...props}>
      {children}
    </Table>
  );
};
