import React, { ReactElement } from 'react';
import { Icon, Popover } from '@amzn/awsui-components-react/polaris';
import styled from '@emotion/styled';

const BoldFont = styled('div')`
  font-weight: bold;
`;

export interface Props {
  supportedLocales: string[];
  targetLocales: string[];
}

export const TargetLocalesPopup = ({
  supportedLocales,
  targetLocales,
}: Props): React.ReactElement => {
  const allSupported = (
    <Popover
      size="medium"
      position="bottom"
      triggerType="custom"
      dismissButton={false}
      content={
        <span id={'localesSupported'}>
          <BoldFont>Supported</BoldFont>
          {targetLocales.join(', ')}
        </span>
      }
    >
      <div
        id={'allSupported'}
        style={{ borderBottom: '1px dotted' }}
        className="awsui-util-status-positive"
      >
        <Icon id="status-positive-icon" name="status-positive" /> All supported
      </div>
    </Popover>
  );

  const missingLocales = (supported: string[], notSupported: string[]): ReactElement => {
    return (
      <Popover
        size="medium"
        position="bottom"
        triggerType="custom"
        dismissButton={false}
        content={
          <span>
            <div id={'localesSupported'}>
              <BoldFont>Supported</BoldFont>
              {supported.join(', ')}
            </div>
            <div id={'localesNotSupported'}>
              <BoldFont>Missing</BoldFont>
              <div className="awsui-util-status-negative">{notSupported.join(', ')}</div>
            </div>
          </span>
        }
      >
        <div
          id={'notAllSupported'}
          style={{ borderBottom: '1px dotted' }}
          className="awsui-util-status-negative"
        >
          <Icon id="status-warning-icon" name="status-warning" /> {notSupported.length} missing
        </div>
      </Popover>
    );
  };

  if (targetLocales.length === 0) {
    return allSupported;
  } else {
    const notSupported = targetLocales.filter(f => !supportedLocales.includes(f));
    return notSupported.length > 0 ? missingLocales(supportedLocales, notSupported) : allSupported;
  }
};
