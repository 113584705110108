import { HelpContentCollectionSpec } from '../HelpContentRouter';
import I18n from '../../setupI18n';

const PROJECT_DETAIL_MAIN_HELP = 'project-detail-main';
const PROJECT_DETAIL_INFO_HELP = 'project-detail-info';
const PROJECT_DETAIL_SCORING_SUMMARY_HELP = 'project-detail-scoring-summary';
const PROJECT_DETAIL_MANAGE_JOBS_HELP = 'project-detail-manage-jobs';
const PROJECT_DETAIL_MANAGE_TMS_HELP = 'project-detail-manage-tms';
const PROJECT_DETAIL_MANAGE_TBS_HELP = 'project-detail-manage-tbs';
const PROJECT_DETAIL_MANAGE_REFS_HELP = 'project-detail-manage-refs';
const PROJECT_DETAIL_VIEW_ANALYSES_HELP = 'project-detail-view-analyses';
const CREATE_ANALYSIS_HELP = 'create-analysis';
const PRETRANSLATE_HELP = 'pretranslate';
const UPLOAD_BILINGUAL_FILE_HELP = 'upload-bilingual-file';
const EMAIL_JOBS_HELP = 'email-jobs';
const PROJECT_LIST_HELP = 'project-list';
const JOB_EDIT_HELP = 'job-edit';
const PROJECT_CREATE_MAIN_HELP = 'project-create-main';
const PROJECT_TEMPLATE_CREATE_MAIN_HELP = 'roject-tenplate-create-main';
const PROJECT_CREATE_ASSETS_HELP = 'project-create-assets';
const PROJECT_CREATE_PRETRANSLATE_HELP = 'project-create-pretranslate';
const PROJECT_CREATE_ANALYSIS_HELP = 'project-create-analysis';
const PROJECT_CREATE_QUALITY_HELP = 'project-create-quality';
const PROJECT_CREATE_ACCESS_AND_AUTOMATION_HELP = 'project-create-access-and-automation';
const PROJECT_CREATE_REVIEW_AND_CREATE_HELP = 'project-create-review-and-create-help';
const PROJECT_CREATE_REVIEW_AND_SAVE_HELP = 'project-create-review-and-save-help';

export const projectHelpContent: HelpContentCollectionSpec = {
  [PROJECT_DETAIL_MAIN_HELP]: {
    title: I18n.t('Project details'),
    content: I18n.t(`
The Project Details page displays the important settings for a project, including the jobs it
contains, the analysis for the jobs, and the localization assets used for the jobs (TMs, TBs, and
Reference files such as style guides).

You can perform the following actions on an existing Project:

* **Save as a template **- A Project Template is the best way to minimize the time to create new
  projects and to drive consistency.
* **Delete** - The project will be available in the Recycle Bin. After 30 days, the project will be
  permanently deleted and will not be recoverable.
    `),
    internalLinks: [
      {
        url:
          'https://w.amazon.com/bin/view/ATMS/Docs/Projects_in_ATMS/#HAddFilesforTranslation28Jobs29',
        label: I18n.t('User documentation'),
      },
    ],
    paths: ['/web/project2/show/:uid', '/web/project/show/:uid', '/web/project/detail/:uid'],
  },
  [PROJECT_DETAIL_INFO_HELP]: {
    title: I18n.t('Project info'),
    content: I18n.t(`
This pane provides the configuration settings associated with this project, such as the project ID,
locales, or the due date. Click **Edit project** to change the settings for the project. Some
settings can’t be modified.
    `),
    internalLinks: [
      {
        url:
          'https://w.amazon.com/bin/view/ATMS/Docs/Projects_in_ATMS/#HAddFilesforTranslation28Jobs29',
        label: I18n.t('User documentation'),
      },
    ],
    paths: ['/web/project2/show/:uid', '/web/project/show/:uid', '/web/project/detail/:uid'],
  },
  [PROJECT_DETAIL_MANAGE_JOBS_HELP]: {
    title: I18n.t('Manage jobs'),
    content: I18n.t(`
Click **Create job** to add new jobs to a project. You can select multiple jobs to perform most of
the job actions. 

_Before you perform an action on a job_, make sure you choose to view the Workflow step you want for
the job (**Viewing workflow step** field above the Jobs heading),
 
Key parts of the Jobs panel:

* The **Edit** button enables you to assign linguists to the job or change the status of the job.
* The **Download** button enables you to download the source file or the translated version of the
  file.
* The **Progress** column displays the status of confirmed segments for the job.
* The **QA** column displays the status of the QA check for the job.
    * Dash - QA has not been run.
    * Ellipsis - QA has been run but there are outstanding warnings.
    * Check - QA has been run and all warnings have been resolved.
* Click on the link in the **File **column to open the Translator Web Editor (CAT tool) to view and
  work on the job segments.
* The **Settings icon** (Gear icon) enables you to hide/show the list columns and rearrange the
  order of the columns.
    `),
    internalLinks: [
      {
        url: 'https://w.amazon.com/bin/view/ATMS/Docs/Projects_in_ATMS/#HJobTools',
        label: I18n.t('User documentation'),
      },
    ],
  },
  [PROJECT_DETAIL_VIEW_ANALYSES_HELP]: {
    title: I18n.t('View job analyses'),
    content: I18n.t(`
The analysis reports in this section provide summaries of the word count and TM matching for all the
jobs in the project per target language. These reports do not include the TER scores for Machine
Translation leveraging, because that is calculated after the job is completed.

There are two different analysis reports in this list: an **Automatic Analysis** report for every
job in the project per locale and an **Instant Quote** report that provides an aggregated view of
all the analysis reports for the jobs with the same target locale.
    `),
    internalLinks: [
      {
        url: 'https://w.amazon.com/bin/view/ATMS/Docs/Analyzing_jobs/',
        label: I18n.t('User documentation'),
      },
    ],
  },
  [PROJECT_DETAIL_MANAGE_TMS_HELP]: {
    title: I18n.t('Select translation memories (TMs)'),
    content: I18n.t(`
TMs used for all jobs in the project.

* Click **Select TM** to assign TMs to the project. The assigned TMs will be used for the
  pre-translation step for the jobs in the project.
* You can only assign TMs that contain the same source and target locale of the project.
* You can assign TMs for all the locales and workflow steps of the project, but you also have the
  option to assign TMs for a specific locale and/or a workflow step.
* Multiple TMs with the same penalty do not have a matching order.
* You can assign up to 10 individual TMs to a project.
    `),
    internalLinks: [
      {
        url: 'https://w.amazon.com/bin/view/ATMS/Docs/Managing_a_Translation_Memory/',
        label: I18n.t('User documentation'),
      },
    ],
    paths: ['/web/selectTranslationMemoryWorkflow/:projectUid'],
  },
  [PROJECT_DETAIL_MANAGE_TBS_HELP]: {
    title: I18n.t('Select term bases (TBs)'),
    content: I18n.t(`
TBs used for all jobs in the project.

* Click **Select TB** to assign existing TBs to the project. The assigned TBs will be used to provide
  terminology options in the CAT tool when a matched term is found.
* You can only add TBs that contain the same locales of the project.
* TBs are prioritized during the translation based on the order listed.
* You can't put a penalty on TBs.
    `),
    internalLinks: [
      {
        url: 'https://w.amazon.com/index.php/ATMS/Docs/Managing%20a%20Term%20Base',
        label: I18n.t('User documentation'),
      },
    ],
    paths: ['/web/selectTermBaseWorkflow/:projectUid'],
  },
  [PROJECT_DETAIL_MANAGE_REFS_HELP]: {
    title: I18n.t('Manage reference files'),
    content: I18n.t(`
Reference files help the translators understand the context of the source content (screen shots) or
provide guidelines for the language (localization style guides).
    `),
    internalLinks: [
      {
        url: 'https://w.amazon.com/index.php/ATMS/Docs/Projects_in_ATMS#HCreatingaNewProject',
        label: I18n.t('User documentation'),
      },
    ],
  },
  [CREATE_ANALYSIS_HELP]: {
    title: I18n.t('Create analysis'),
    content: I18n.t(`
You can create a custom analysis of your jobs. which provides the TM matching percentages
by segments, pages, words, or characters . When a job is created, the system performs an
analysis with the default settings for the first workflow step. This analysis does not
provide TER data, which can’t be calculated until the project is completed.

You can use the Compare analysis option to create a report on a job in two different
workflow steps.
    `),
    internalLinks: [
      {
        url: 'https://w.amazon.com/bin/view/ATMS/Docs/Analyzing_jobs/',
        label: I18n.t('User documentation'),
      },
    ],
    paths: ['/web/analysis/create/:uid'],
  },
  [PRETRANSLATE_HELP]: {
    title: I18n.t('Pre-translate where empty'),
    content: I18n.t(`
In most cases, a project is configured to perform pre-translations, which includes both
Translation Memory (TM) and Machine Translation for segments that have TM matches less than
70%. However, there may be situations when the pre-translation failed for some segments and
you need to rerun it. You should not perform this action after the linguist has started the
translation.
    `),
    internalLinks: [
      {
        url: 'https://w.amazon.com/bin/view/ATMS/Docs/Pre-translation',
        label: I18n.t('User documentation'),
      },
    ],
    paths: ['/web/pretranslate/:uid'],
  },
  [UPLOAD_BILINGUAL_FILE_HELP]: {
    title: I18n.t('Upload bilingual file'),
    content: I18n.t(`
This action is usually used when you need to upload a bilingual docx file that was
reviewed by someone outside of ATMS. To get feedback from in-country experts, you can
download a bilingual docx file for them to review and when you get it back, you can upload
their changes.

The most appropriate TM update option depends on your workflow. However, since
translations in later workflow steps will overwrite earlier translations, we recommend
choosing *All Segments* for saving to TM.
    `),
    internalLinks: [
      {
        url:
          'https://w.amazon.com/bin/view/ATMS/Docs/Using_Bilingual_DOCX_for_review/#HDownloadabilingualDOCXfile',
        label: I18n.t('User documentation'),
      },
    ],
    paths: ['/web/uploadBilingualFile/:uid'],
  },
  [EMAIL_JOBS_HELP]: {
    title: I18n.t('Email'),
    content: I18n.t(`
This action is used to send an email to the linguist assigned to the job in the currently selected
workflow step. You can create your own email or choose from one of the email templates provided in
the **Setup→Email** Templates page.
    `),
    internalLinks: [
      {
        url: 'https://w.amazon.com/bin/view/ATMS/Docs/Projects_in_ATMS/#HEmail...',
        label: I18n.t('User documentation'),
      },
    ],
    paths: ['/web/emailJobsWorkflow/:uid'],
  },
  [PROJECT_LIST_HELP]: {
    title: I18n.t('Projects'),
    content: I18n.t(`
The Projects page is the main dashboard in ATMS. It enables you to keep track of all your projects, including their progress, status, and due date. Once you find your project on the list, you can click on the project link to view the details for the project, including the jobs in the project.

You can click **Create project** to create a new project and can also change the overall status of a selected project to **Completed**, **Cancelled**, or **New **through the** Change status **menu. If you have your ATMS org configured properly, an ATMS project should automatically be set to Completed through the Project Status Automation settings.

Here are some important actions you can take on this page:

* Click the link in the **Name** column to view the details for the project, including the jobs for that project.
* The **Progress** column shows how much work has been done on the project. 
* The **Status** column shows the high-level status for each project: **New**, **Assigned**, **Completed**, or **Cancelled**.
* Use the **Filter actions** menu to choose pre-defined filters or a customized filter that you saved. You can use these filters to quickly view only a specific set of projects.
* Create a customized filter by adding filters using the search bar, and then save it by choosing **Filter actions > Save current filter** to add to your list of saved filters.
* The **Preferences icon** (gear icon) enables you to hide/show the list columns and rearrange the order of the columns.
* The list displays 50 projects at a time. You can’t currently change this number.
* Horizontal scroll is provided to view columns which are hidden due to the limitations of the screen. To perform a horizontal scroll, click on the project list table and follow any of the below steps:
    * **Laptops**: use 2 fingers and move left and right
    * **Mouse**: use the keyboard shortcut SHIFT + Mouse scroll wheel
    * **Keyboard**: use left/right arrow keys
    `),
    roleContent: {
      LINGUIST: I18n.t(`
The Projects page is the main dashboard in ATMS. It enables you to keep track of all your projects. Once you find your project on the list, you can click on the project link to view the details for the project, including the jobs in the project.

Here are some important actions you can take on this page:

* Click the link in the **Name** column to view the details for the project, including the jobs for that project.
* Filter projects by name, creation date, and owner.
* Use the **Filter actions** menu to choose pre-defined filters or a customized filter that you saved. You can use these filters to quickly view only a specific set of projects.
* Create a customized filter by adding filters using the search bar, and then save it by choosing **Filter actions > Save current filter** to add to your list of saved filters.
* The **Preferences icon** (gear icon) enables you to hide/show the list columns and rearrange the order of the columns.
* The list displays 50 projects at a time. You can’t currently change this number.
* Horizontal scroll is provided to view columns which are hidden due to the limitations of the screen. To perform a horizontal scroll, click on the project list table and follow any of the below steps:
    * **Laptops**: use 2 fingers and move left and right
    * **Mouse**: use the keyboard shortcut SHIFT + Mouse scroll wheel
    * **Keyboard**: use left/right arrow keys
      `),
    },
    internalLinks: [
      {
        url:
          'https://w.amazon.com/bin/view/ATMS/Docs/Projects_in_ATMS/#HProjectsViewCustomizationandFiltering',
        label: I18n.t('User documentation'),
      },
    ],
    paths: ['/web/project/list', '/web/project/listDev'],
  },
  [PROJECT_DETAIL_SCORING_SUMMARY_HELP]: {
    title: I18n.t('Scoring'),
    content: I18n.t(`
The scoring section displays a summary of scoring for the entire project as well as for each target language where scoring has happened.

As soon as scoring becomes available, you'll see:
* **Progress** - Percentage of the jobs that have been completed
* **Passed** - Number of jobs that have passed scoring
* **Failed** - Number of jobs that failed scoring
* **Threshold** - Lowest score that a job can have and pass scoring
* **Scoring Model** - Model that will be used to score the jobs
* **Scores by target** - Summaries of jobs by target language and their aggregate status. Since you can have multiple scoring steps and jobs-per-language, this is an easy way to understand where scoring is at for each target language as a whole.
    `),
    internalLinks: [
      {
        url: 'https://w.amazon.com/bin/view/ATMS/Docs/Projects_in_ATMS/#HScoring',
        label: I18n.t('User documentation'),
      },
    ],
  },
  [JOB_EDIT_HELP]: {
    title: I18n.t('Edit jobs'),
    content: I18n.t(`
The Edit Jobs page is used to set the assignee(s), status, and due date of one or more jobs.

**Assignees and states:**
A job may have multiple assignees in the \`New\` or \`Emailed to Linguist\` states. Other states only allow one assignee.

**Editing multiple jobs:**
If multiple jobs are being edited, the ‘Assigned linguist’, ‘Status’, and ‘Due date’ fields will take their values from the first job in the list.

**Due date:**
The due date is in the local time of the user editing the jobs, as configured [here](/web/setup/timezoneSettings).
    `),
    internalLinks: [
      {
        url: 'https://w.amazon.com/bin/view/ATMS/Docs/Projects_in_ATMS/#HEditingJobs',
        label: I18n.t('User documentation'),
      },
    ],
    paths: ['/web/project2/editJobs'],
  },
  [PROJECT_CREATE_MAIN_HELP]: {
    title: I18n.t('Project details'),
    content: I18n.t(
      `Set project metadata fields, such as name, due date, etc. Set up workflow steps for the project.`
    ),
    internalLinks: [
      {
        url: 'https://w.amazon.com/bin/view/ATMS/Docs/Projects_in_ATMS/#HCreateaProject',
        label: I18n.t('User documentation'),
      },
    ],
    paths: ['/web/project/create', '/web/project/edit/:projectUid'],
  },
  [PROJECT_TEMPLATE_CREATE_MAIN_HELP]: {
    title: I18n.t('Project template details'),
    content: I18n.t(
      `Set project template metadata fields, such as name, due date, etc. Set up workflow steps for the project template.`
    ),
    internalLinks: [
      {
        url:
          'https://w.amazon.com/bin/view/ATMS/Docs/Projects_in_ATMS#HCreatingandEditingProjectTemplates',
        label: I18n.t('User documentation'),
      },
    ],
    paths: ['/web/projectTemplate/create'],
  },
  [PROJECT_CREATE_ASSETS_HELP]: {
    title: I18n.t('Project assets'),
    content: I18n.t(`TODO`),
    internalLinks: [
      {
        url: 'https://w.amazon.com/bin/view/ATMS/Docs/Projects_in_ATMS/#HCreateaProject',
        label: I18n.t('User documentation'),
      },
    ],
    paths: ['/web/project/create', '/web/project/edit/:projectUid'],
  },
  [PROJECT_CREATE_PRETRANSLATE_HELP]: {
    title: I18n.t('Project pre-translate settings'),
    content: I18n.t(
      `Set up the settings for automatic pre-translation that runs whenever content is added to the project. These settings will also be the defaults when pre-translate is run manually.`
    ),
    internalLinks: [
      {
        url: 'https://w.amazon.com/bin/view/ATMS/Docs/Projects_in_ATMS/#HCreateaProject',
        label: I18n.t('User documentation'),
      },
    ],
    paths: ['/web/project/create', '/web/project/edit/:projectUid'],
  },
  [PROJECT_CREATE_ANALYSIS_HELP]: {
    title: I18n.t('Project analysis settings'),
    content: I18n.t(
      `Set up the settings for automatic analyses that run whenever content is added to the project. These settings will also be the defaults when an analysis is run manually.`
    ),
    internalLinks: [
      {
        url: 'https://w.amazon.com/bin/view/ATMS/Docs/Projects_in_ATMS/#HCreateaProject',
        label: I18n.t('User documentation'),
      },
    ],
    paths: ['/web/project/create', '/web/project/edit/:projectUid'],
  },
  [PROJECT_CREATE_QUALITY_HELP]: {
    title: I18n.t('Project quality settings'),
    content: I18n.t(
      `Choose which QA checks to run in the CAT tool and whether linguists are allowed to complete jobs with unresolved QA warnings.`
    ),
    internalLinks: [
      {
        url: 'https://w.amazon.com/bin/view/ATMS/Docs/Projects_in_ATMS/#HCreateaProject',
        label: I18n.t('User documentation'),
      },
    ],
    paths: ['/web/project/create', '/web/project/edit/:projectUid'],
  },
  [PROJECT_CREATE_ACCESS_AND_AUTOMATION_HELP]: {
    title: I18n.t('Project access and automation settings'),
    content: I18n.t(`
### Access
Set project-level permissions.

### Automation
Set up project status automation and other automation settings.
    `),
    internalLinks: [
      {
        url: 'https://w.amazon.com/bin/view/ATMS/Docs/Projects_in_ATMS/#HCreateaProject',
        label: I18n.t('User documentation'),
      },
    ],
    paths: ['/web/project/create', '/web/project/edit/:projectUid'],
  },
  [PROJECT_CREATE_REVIEW_AND_CREATE_HELP]: {
    title: I18n.t('Review and create project'),
    content: I18n.t(`TODO`),
    internalLinks: [
      {
        url: 'https://w.amazon.com/bin/view/ATMS/Docs/Projects_in_ATMS/#HCreateaProject',
        label: I18n.t('User documentation'),
      },
    ],
    paths: ['/web/project/create', '/web/project/edit/:projectUid'],
  },
  [PROJECT_CREATE_REVIEW_AND_SAVE_HELP]: {
    title: I18n.t('Review and save project'),
    content: I18n.t(`TODO`),
    internalLinks: [
      {
        url: 'https://w.amazon.com/bin/view/ATMS/Docs/Projects_in_ATMS/#HCreateaProject',
        label: I18n.t('User documentation'),
      },
    ],
    paths: ['/web/project/create', '/web/project/edit/:projectUid'],
  },
};

export {
  PROJECT_DETAIL_MAIN_HELP,
  PROJECT_DETAIL_INFO_HELP,
  PROJECT_DETAIL_MANAGE_JOBS_HELP,
  PROJECT_DETAIL_MANAGE_REFS_HELP,
  PROJECT_DETAIL_MANAGE_TBS_HELP,
  PROJECT_DETAIL_MANAGE_TMS_HELP,
  PROJECT_DETAIL_SCORING_SUMMARY_HELP,
  PROJECT_DETAIL_VIEW_ANALYSES_HELP,
  CREATE_ANALYSIS_HELP,
  PRETRANSLATE_HELP,
  UPLOAD_BILINGUAL_FILE_HELP,
  PROJECT_LIST_HELP,
  JOB_EDIT_HELP,
  PROJECT_CREATE_MAIN_HELP,
  PROJECT_TEMPLATE_CREATE_MAIN_HELP,
  PROJECT_CREATE_ASSETS_HELP,
  PROJECT_CREATE_PRETRANSLATE_HELP,
  PROJECT_CREATE_ANALYSIS_HELP,
  PROJECT_CREATE_QUALITY_HELP,
  PROJECT_CREATE_ACCESS_AND_AUTOMATION_HELP,
  PROJECT_CREATE_REVIEW_AND_CREATE_HELP,
  PROJECT_CREATE_REVIEW_AND_SAVE_HELP,
};
