import { useEffect, useState } from 'react';
import { AtmsApiClient } from '@amzn/et-console-components';
import { getAppHostConfig } from '@amzn/et-console-components';

const { WEB_HOST_AND_PORT } = getAppHostConfig();

interface State {
  isLoadingSupportedFileEncodings: boolean;
  supportedFileEncodings: string[];
}

// TODO: Needs to reload when the user profile changes
export const useSupportedFileEncodings = (): State => {
  const [state, setState] = useState<State>({
    isLoadingSupportedFileEncodings: true,
    supportedFileEncodings: [],
  });
  useEffect(() => {
    AtmsApiClient.httpGet(`//${WEB_HOST_AND_PORT}/web/api/v9/file/getSupportedFileEncodings`).then(
      result => {
        setState({
          isLoadingSupportedFileEncodings: false,
          supportedFileEncodings: result,
        });
      }
    );
  }, []);

  return state;
};
