import React, { ReactElement, useCallback, useEffect, useReducer } from 'react';
import { Multiselect, Select } from '@amzn/awsui-components-react/polaris';
import I18n from '../../setupI18n';

interface ReducerState {
  filteredOptionsWithSelectAll?: Array<Select.IOption | Select.Option | Select.OptionsGroup> | null;
  selectAllIdsToOptions?: { [key: string]: Array<Select.IOption | Select.Option> };
}

interface SimpleFilteringChangeEvent {
  detail: {
    value: string;
  };
}

/**
 * Multiselect that adds a dynamic 'select all' option to each option group. It does internal
 * filtering so that the 'select all' options don't get filtered out.
 *
 * Filtering emulates the built-in filtering and supports simple tokenization and case-insensitive
 * matching against multiple option fields.
 */
export const MultiselectWithSelectAllInGroup = (props: Multiselect.Props): ReactElement => {
  const { options, onChange, selectedIds } = props;
  const init = (): ReducerState => {
    return {
      filteredOptionsWithSelectAll: options,
    };
  };

  const reducer = (state, action): ReducerState => {
    switch (action.type) {
      case 'optionFilteringChange':
        return {
          ...state,
          filteredOptionsWithSelectAll: action.filteredOptionsWithSelectAll,
          selectAllIdsToOptions: action.selectAllIdsToOptions,
        };
      default:
        return state;
    }
  };

  const [reducerState, dispatch] = useReducer(reducer, null, init);
  const { filteredOptionsWithSelectAll, selectAllIdsToOptions } = reducerState;

  const optionMatches = (option: Select.IOption | Select.Option, filter: string): boolean => {
    if (!filter) {
      return true;
    }

    const lFilter = filter.toLowerCase();
    const searchTokens = lFilter.split(' ');

    let allMatch = true;
    searchTokens.forEach(token => {
      allMatch =
        allMatch &&
        (option.label.toLowerCase().includes(token) ||
          (option['labelTag'] && option['labelTag'].toLowerCase().includes(token)) ||
          (option['description'] && option['description'].toLowerCase().includes(token)));
    });

    return allMatch;
  };

  const onFilteringChange = useCallback(
    (e: SimpleFilteringChangeEvent): void => {
      const filteredOptions: Array<Select.IOption | Select.Option | Select.OptionsGroup> = [];

      if (options) {
        options.forEach(o => {
          if (o['options']) {
            // This is an option group; we have to clone it
            const group: Select.OptionsGroup = { ...o, options: [] };
            o['options'] &&
              o['options'].forEach(o2 => {
                if (optionMatches(o2, e.detail.value) || selectedIds?.includes(o2.id)) {
                  group.options.push(o2);
                }
              });

            if (group.options.length > 0) {
              filteredOptions.push(group);
            }
          } else if (
            optionMatches(o, e.detail.value) ||
            (selectedIds && o['id'] && selectedIds.includes(o['id']))
          ) {
            filteredOptions.push(o);
          }
        });
      }

      const filteredOptionsWithSelectAll: Array<
        Select.IOption | Select.Option | Select.OptionsGroup
      > = [];
      const selectAllIdsToOptions = {};
      if (filteredOptions) {
        filteredOptions.forEach(o => {
          if (o['options'] && o['options'].length > 1) {
            // This is an option group with more than one option; add 'select all' option
            // To improve performance, we're only showing up to 10 items in each group.
            // The 'Select all' feature will continue to work even if the items aren't displayed
            // Note: we have to include all selected items in the filtered set, or the Multiselect
            // will unselect them.

            // This method of filtering preserves the relative order of the items
            let unselectedOptionsCount = 0;
            const truncatedOptions: Select.Option[] = [];

            o['options'].forEach((o2): void => {
              if (selectedIds?.includes(o2.id)) {
                truncatedOptions.push(o2);
              } else if (unselectedOptionsCount < 10) {
                unselectedOptionsCount++;
                truncatedOptions.push(o2);
              }
            });
            filteredOptionsWithSelectAll.push({
              ...o,
              options: [
                {
                  id: o.label + '_SELECT_ALL',
                  description:
                    o['options'].length > truncatedOptions.length
                      ? I18n.t('Select all, including %{unshownCount} not shown', {
                          unshownCount: o['options'].length - truncatedOptions.length,
                        })
                      : I18n.t('Select all'),
                  label: '',
                },
                ...truncatedOptions,
              ],
            });
            selectAllIdsToOptions[o.label + '_SELECT_ALL'] = o['options'];
          } else if (o['options'] && o['options'].length > 0) {
            // This is an option group with only 1 option; don't add the select all option
            filteredOptionsWithSelectAll.push({ ...o, options: [...o['options']] });
          } else {
            // This is a plain option
            filteredOptionsWithSelectAll.push(o);
          }
        });
      }

      dispatch({
        type: 'optionFilteringChange',
        filteredOptionsWithSelectAll,
        selectAllIdsToOptions,
      });
    },
    [options, selectedIds]
  );

  const onChangeWithSelectAll = useCallback(
    (e: CustomEvent<Select.MultiselectChangeDetail>): void => {
      if (
        selectAllIdsToOptions &&
        Object.keys(selectAllIdsToOptions).includes(e.detail.selectedId)
      ) {
        e.detail.selectedIds.push(
          ...selectAllIdsToOptions[e.detail.selectedId].map(o => (o['id'] ? o['id'] : o.label))
        );
      }
      if (onChange) {
        onChange(e);
      }
    },
    [onChange, selectAllIdsToOptions]
  );

  useEffect(() => {
    onFilteringChange({ detail: { value: '' } });
  }, [options, onFilteringChange]);

  return (
    <Multiselect
      {...props}
      filteringType="manual"
      onDelayedFilteringChange={onFilteringChange}
      onChange={onChangeWithSelectAll}
      options={filteredOptionsWithSelectAll}
    />
  );
};
