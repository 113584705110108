import React, { ReactElement, useCallback, useLayoutEffect, useReducer, Fragment } from 'react';
import {
  Alert,
  CustomDetailEvent,
  Table,
  TableSelection,
} from '@amzn/awsui-components-react/polaris';
import { Container, PageHeader } from '@amzn/et-polaris-utils';
import {
  convertDate,
  formatLocaleCode,
  displayUser,
  AtmsApiClient,
  getAppHostConfig,
  AuthenticatedSession,
} from '@amzn/et-console-components';
import { UserOrVendor } from '@amzn/et-console-components/dist/utils/displayUser';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { stringify } from 'query-string';
import { useState } from 'react';
import { History } from 'history';
import { CellContents } from '../types/commonTypes';
import { PROJECT_DETAIL_VIEW_ANALYSES_HELP } from './projectHelpContent';
import { HelpInfoLink } from '../HelpContentRouter';
import I18n from 'i18n-js';
import { StandardModal } from '../components/StandardModal';
import { publishCountMetric } from '../metricHelper';

const { WEB_HOST_AND_PORT } = getAppHostConfig();

const columnDefinitions = [
  {
    id: 'id',
    header: '#',
    cell: (item: ExtendedAnalysis): CellContents => item.innerId,
  },
  {
    id: 'name',
    header: I18n.t('Name'),
    cell: (item: ExtendedAnalysis): CellContents => {
      return (
        <a
          href={`//${WEB_HOST_AND_PORT}/web/analyse/show/${item.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {item.name ?? `Analysis #${item.innerId}`}
        </a>
      );
    },
  },
  {
    id: 'linguist',
    header: I18n.t('Linguist'),
    cell: (item: ExtendedAnalysis): CellContents =>
      (item.linguist && displayUser(item.linguist)) || '-',
  },
  {
    id: 'type',
    header: I18n.t('Type'),
    cell: (item: ExtendedAnalysis): CellContents => item.type, //TODO: Transform to display name
  },
  {
    id: 'dateCreated',
    header: I18n.t('Created'),
    cell: (item: ExtendedAnalysis): CellContents =>
      (item.dateCreated && convertDate(item.dateCreated)) || '-',
  },
  {
    id: 'createdBy',
    header: I18n.t('Created by'),
    cell: (item: ExtendedAnalysis): CellContents => {
      if (item.isExternal) {
        return (
          <Fragment>
            <FontAwesomeIcon id="tbHeaderIcon" icon={faBuilding} /> {item.organization.name}
          </Fragment>
        );
      }
      return (item.createdBy && displayUser(item.createdBy)) || '-';
    },
  },
  {
    id: 'languages',
    header: I18n.t('Languages'),
    cell: (item: ExtendedAnalysis): CellContents =>
      item.languages && item.languages[0]
        ? `${formatLocaleCode(item.languages[0].sourceLocale)} ➔ ${item.languages
            .map(lp => formatLocaleCode(lp.targetLocale))
            .join(', ')}`
        : '-',
  },
];

export interface Props {
  session: AuthenticatedSession;
  projectUid: string;
  history: History;
  canEdit: boolean;
}

export interface Analysis {
  id: number;
  innerId: number;
  name?: string;
  type: string;
  dateCreated?: string;
  createdBy?: UserOrVendor;
  linguist?: UserOrVendor;
  organization: {
    id: number;
    name: string;
  };
  languages: [
    {
      sourceLocale: string;
      targetLocale: string;
    }
  ];
}
export interface ExtendedAnalysis extends Analysis {
  isExternal: boolean;
}

export interface ReducerState {
  isLoading: boolean;
  isError: boolean;
  error?: object;
  analyses: ExtendedAnalysis[];
  selectedAnalyses: ExtendedAnalysis[];
}

export const AnalysisList = ({ session, projectUid, history, canEdit }: Props): ReactElement => {
  const init = (): ReducerState => ({
    isLoading: false,
    isError: false,
    analyses: [],
    selectedAnalyses: [],
  });

  const reducer = (state, action): ReducerState => {
    switch (action.type) {
      case 'loadingAnalyses':
        return {
          ...state,
          isLoading: true,
          isError: false,
        };
      case 'loadedAnalyses':
        return {
          ...state,
          isLoading: false,
          analyses: action.analyses,
        };
      case 'loadAnalysesFailed':
        return {
          ...state,
          isLoading: false,
          isError: true,
          error: action.error,
        };
      case 'changeAnalysisSelection':
        return {
          ...state,
          selectedAnalyses: action.selectedAnalyses,
        };
      case 'deleteAnalysesFailed':
        return {
          ...state,
          error: action.error,
        };
      default:
        return state;
    }
  };

  const [reducerState, dispatch] = useReducer(reducer, null, init);
  const { isLoading, isError, error, analyses, selectedAnalyses } = reducerState;
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const handleLoadAnalyses = useCallback((): void => {
    dispatch({
      type: 'loadingAnalyses',
    });
    const query = {
      projectUid,
    };

    AtmsApiClient.httpGet(`/api/analysis/listByProject?${stringify(query)}`)
      .then(response => {
        dispatch({
          type: 'loadedAnalyses',
          analyses: response.analyses.map(
            (a): ExtendedAnalysis => ({
              ...a,
              isExternal: session.organization.id !== a.organization.id,
            })
          ),
        });
      })
      .catch(e => {
        publishCountMetric('loadAnalysesFailed-AnalysisList', 'error', e.message);
        dispatch({ type: 'loadAnalysesFailed', error: e });
      });
  }, [projectUid, session]);

  const handleSelectionChange = (
    changeDetail: CustomDetailEvent<TableSelection.SelectionChangeDetail>
  ): void => {
    dispatch({
      type: 'changeAnalysisSelection',
      selectedAnalyses: changeDetail.detail.selectedItems,
    });
  };

  const handleEditClick = (): void => {
    const query = {
      analyseSelection: selectedAnalyses.map(j => j.id),
    };
    // TODO: This is not redirecting back to the project page after saving
    history.push(`/web/analyse/edit?${stringify(query)}`);
  };

  const handleDeleteDialogCancelButtonClick = (): void => {
    setShowDeleteDialog(false);
  };

  const handleDeleteDialogOkButtonClick = (): void => {
    setShowDeleteDialog(false);
    dispatch({
      type: 'deletingAnalyses',
    });
    const query = {
      analyse: selectedAnalyses.map(a => a.id),
    };

    AtmsApiClient.httpDelete(`//${WEB_HOST_AND_PORT}/web/api/v2/analyse/delete?${stringify(query)}`)
      .then(() => {
        dispatch({ type: 'deletedAnalyses' });
        handleLoadAnalyses();
      })
      .catch(e => {
        publishCountMetric('deleteAnalysesFailed-AnalysisList', 'error', e.message);
        dispatch({ type: 'deleteAnalysesFailed', error: e });
      });
  };

  // Load the page if not loaded or if UID changes.
  useLayoutEffect((): void => {
    handleLoadAnalyses();
  }, [projectUid, handleLoadAnalyses]);

  if (isError) {
    return <Alert type="error" content={`Failed to load analyses: ${error}`} />;
  }

  return (
    <Fragment>
      <Container withGutters={false}>
        <Table
          id="analysisList"
          variant="borderless"
          loading={isLoading}
          header={
            <PageHeader
              tag="h2"
              title={I18n.t('Analyses')}
              extraContent={<HelpInfoLink helpId={PROJECT_DETAIL_VIEW_ANALYSES_HELP} />}
              buttons={[
                canEdit && {
                  id: 'delete',
                  text: I18n.t('Delete'),
                  // TODO: The delete analysis API only accepts a single item
                  disabled: selectedAnalyses.length === 0,
                  onClick: (): void => setShowDeleteDialog(true),
                },
                canEdit && {
                  id: 'edit',
                  text: I18n.t('Edit'),
                  variant: 'primary',
                  disabled: selectedAnalyses.length === 0,
                  onClick: handleEditClick,
                },
              ]}
            />
          }
          columnDefinitions={columnDefinitions}
          items={analyses}
          empty={I18n.t('No analyses found')}
          noMatch={I18n.t('No matching analyses found')}
        >
          <TableSelection
            selectedItems={selectedAnalyses}
            trackBy="id"
            onSelectionChange={handleSelectionChange}
            isItemDisabled={(item: ExtendedAnalysis): boolean => item.isExternal}
          />
        </Table>
      </Container>

      <StandardModal
        content={I18n.t(
          {
            one: 'Do you want to delete %{name}?',
            other: 'Do you want to delete %{count} analyses?',
          },
          {
            count: selectedAnalyses.length,
            name:
              selectedAnalyses.length > 0 &&
              (selectedAnalyses[0].name || `Analysis #${selectedAnalyses[0].innerId}`),
          }
        )}
        header={I18n.t(
          {
            one: 'Delete analysis?',
            other: 'Delete analyses?',
          },
          {
            count: selectedAnalyses.length,
          }
        )}
        okButtonId="analysisListDeleteModalConfirm"
        visible={showDeleteDialog}
        handleCancelClick={handleDeleteDialogCancelButtonClick}
        handleOkClick={handleDeleteDialogOkButtonClick}
      />
    </Fragment>
  );
};
