import React, { useReducer, useEffect, useLayoutEffect, useState } from 'react';
import { withRouter } from 'react-router';
import {
  Button,
  Flash,
  Form,
  FormField,
  FormSection,
  Input,
  Table,
} from '@amzn/awsui-components-react/polaris';
import { PolarisFormik } from '@amzn/et-polaris-utils';
import * as yup from 'yup';
import styled from '@emotion/styled';
import { css } from 'emotion';
import { AtmsApiClient } from '@amzn/et-console-components';
import { parse } from 'query-string';
import I18n from '../setupI18n';

const FormDiv = styled.div({
  width: '50%',
  margin: 'auto',
});

const TableDiv = styled.div({
  width: '98%',
  margin: 'auto',
});

const formSpacing = css`
  padding-top: 14px;
  padding-bottom: 14px;
`;

const columnDefinitions = [
  {
    id: 'segmentId',
    header: I18n.t('Segment ID'),
    cell: item => item.segmentId,
  },
  {
    id: 'srcText',
    header: I18n.t('Source text'),
    cell: item => item.srcText || '-',
  },
  {
    id: 'targetText',
    header: I18n.t('Target text'),
    cell: item => item.targetText || '-',
  },
  {
    id: 'timestamp',
    header: I18n.t('Modification time'),
    cell: item =>
      item.timestamp ? `${new Date(item.timestamp).toLocaleString()} (${item.timestamp})` : '-',
  },
  {
    id: 'modifiedBy',
    header: I18n.t('Modified by'),
    cell: item =>
      item.modifiedByUsername ||
      I18n.t('external user (%{userId})', { userId: item.modifiedById }) ||
      '-',
  },
  {
    id: 'traceId',
    header: I18n.t('Trace id'),
    cell: item => item.traceId || '-',
  },
];

export const JobPartAudit = withRouter(({ location: { pathname, search } }) => {
  const [queryParams, setQueryParams] = useState(parse(search));

  useEffect(() => {
    setQueryParams(parse(search));
  }, [pathname, search]);

  const init = () => ({
    isLoading: false,
    isLoaded: false,
    isError: false,
    items: [],
    jobPartId: queryParams.jobPartId,
    level: queryParams.level,
    snapshotTimestamp: queryParams.snapshotTimestamp,
  });

  const reducer = (state, action) => {
    switch (action.type) {
      case 'loadingSegments':
        return {
          ...state,
          isLoading: true,
          isError: false,
          jobPartId: action.jobPartId,
          snapshotTimestamp: action.snapshotTimestamp,
          level: action.level,
        };
      case 'loadedSegments':
        return {
          ...state,
          isLoading: false,
          isLoaded: true,
          items: action.items,
        };
      case 'changeSettings':
        return {
          ...state,
          isLoaded: false,
          items: [],
        };
      case 'loadSegmentsFailed':
        return {
          ...state,
          isLoading: false,
          isLoaded: true,
          isError: true,
        };
      default:
        return state;
    }
  };

  const [reducerState, dispatch] = useReducer(reducer, null, init);
  const [validating, setValidating] = useState(false);

  /**
   * Per AWS Specifications, only start validating after the initial submit.
   * @param e the event
   * @param handleSubmit formik submit event handler function.
   */
  const enableValidation = (e, handleSubmit) => {
    e.preventDefault();
    setValidating(true);
    handleSubmit();
  };

  const handleSubmit = values => {
    const req = {
      jobParts: [values.jobPartId],
      level: values.level,
      snapshotTimestamp: values.snapshotTimestamp,
    };

    dispatch({
      type: 'loadingSegments',
      jobPartId: values.jobPartId,
      snapshotTimestamp: Number(values.snapshotTimestamp),
      level: Number(values.level),
    });
    AtmsApiClient.httpPost('/api/jobPartAudit', req)
      .then(response => {
        // The server supports returning multiple reports, but there should only be one.
        const report = response[0];
        dispatch({ type: 'loadedSegments', items: report.segments });
      })
      .catch(() => {
        dispatch({ type: 'loadSegmentsFailed' });
      });
  };

  // Load the page if all parameters are present.
  useLayoutEffect(() => {
    if (reducerState.jobPartId && reducerState.level && reducerState.snapshotTimestamp) {
      handleSubmit({
        jobPartId: reducerState.jobPartId,
        level: reducerState.level,
        snapshotTimestamp: reducerState.snapshotTimestamp,
      });
    }
  }, [
    pathname,
    search,
    reducerState.jobPartId,
    reducerState.level,
    reducerState.snapshotTimestamp,
  ]);

  const renderForm = ({ values, errors, handleSubmit, handleChange, handleBlur }) => {
    return (
      <FormDiv>
        <form onSubmit={e => enableValidation(e, handleSubmit)}>
          <Form
            header={I18n.t('Audit settings')}
            actions={
              <div>
                <Button
                  id="Submit"
                  text={I18n.t('Submit')}
                  variant="primary"
                  onClick={e => enableValidation(e, handleSubmit)}
                />
              </div>
            }
            errorText={
              Object.keys(errors).length !== 0
                ? I18n.t('The form contains errors. Fix them and resubmit.')
                : ''
            }
          >
            <FormSection header="Job details">
              <FormField
                label={I18n.t('Job part ID')}
                hintText={I18n.t('The job part ID to audit.')}
                errorText={errors.jobPartId ? I18n.t('Job part ID is required.') : ''}
              />
              <Input
                name="jobPartId"
                id="jobPartId"
                value={values.jobPartId}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FormField
                label={I18n.t('Workflow step level')}
                hintText={I18n.t('Numeric.  Enter 1 for first workflow step, 2 for second, etc.')}
                errorText={errors.level ? I18n.t('Workflow step level must be numeric.') : ''}
              />
              <Input
                name="level"
                id="level"
                value={(values.level || '').toString()}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FormField
                label={I18n.t('Snapshot timestamp')}
                hintText={I18n.t('The snapshot point in time in milliseconds since epoch.')}
                errorText={
                  errors.snapshotTimestamp ? I18n.t('Snapshot timestamp is required.') : ''
                }
              />
              <Input
                name="snapshotTimestamp"
                id="snapshotTimestamp"
                value={(values.snapshotTimestamp || '').toString()}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormSection>
            {reducerState.isError && (
              <div className={formSpacing}>
                <Flash
                  type="error"
                  dismissible={true}
                  content={I18n.t('Please try again. If the issue persists, contact support.')}
                  header={I18n.t('Something went wrong.')}
                />
              </div>
            )}
          </Form>
        </form>
      </FormDiv>
    );
  };

  const initialFormValues = {
    jobPartId: reducerState.jobPartId,
    snapshotTimestamp: reducerState.snapshotTimestamp,
    level: reducerState.level,
  };

  const schema = yup.object().shape({
    jobPartId: yup.string().required(),
    snapshotTimestamp: yup.number().required(),
    level: yup.number().required(),
  });

  if (reducerState.isLoading || (reducerState.items && reducerState.items.length)) {
    return (
      <div>
        <TableDiv>
          <Table
            loading={reducerState.isLoading}
            loadingText="Loading report"
            columnDefinitions={columnDefinitions}
            items={reducerState.items}
            header={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h2 style={{ flexGrow: '1' }}>
                  {I18n.t('Snapshot of job part %{jobPartId} as of %{date}', {
                    jobPartId: reducerState.jobPartId,
                    date: new Date(reducerState.snapshotTimestamp).toLocaleString(),
                  })}
                </h2>
                <Button
                  text={I18n.t('Change report settings')}
                  variant="primary"
                  onClick={() => dispatch({ type: 'changeSettings' })}
                />
              </div>
            }
          />
        </TableDiv>
      </div>
    );
  } else {
    return (
      <div>
        <PolarisFormik
          initialValues={initialFormValues}
          validationSchema={schema}
          validateOnChange={validating}
          onSubmit={handleSubmit}
          render={renderForm}
        />
      </div>
    );
  }
});
