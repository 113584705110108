import React, { Fragment, ReactElement, useEffect, useReducer, useState } from 'react';
import * as yup from 'yup';
import I18n from '../../../setupI18n';
import { BackgroundTask, Job, SelectAllOnServerApi, User, Vendor } from '../../types/commonTypes';
import { RouteComponentProps, withRouter } from 'react-router';
import { parse, stringify } from 'query-string';
import {
  Alert,
  Button,
  ColumnLayout,
  DatePicker,
  Flash,
  Form,
  FormField,
  FormSection,
  Select,
  Spinner,
  TimeInput,
} from '@amzn/awsui-components-react/polaris';
import { PageHeader, PolarisFormik } from '@amzn/et-polaris-utils';
import { css } from 'emotion';
import styled from '@emotion/styled';
import {
  displayUser,
  AuthenticatedSession,
  AtmsApiClient,
  getAppHostConfig,
} from '@amzn/et-console-components';
import { HelpInfoLink } from '../../HelpContentRouter';
import { JOB_EDIT_HELP } from '../projectHelpContent';
import { DateTime, LocalZone, Zone } from 'luxon';
import { MultiselectWithSelectAllInGroup } from '../../components/MultiselectWithSelectAllInGroup';
import { publishCountMetric } from '../../metricHelper';
import apiErrorToErrorMessage from '../../../shared/apiErrorToErrorMessage';

const { WEB_HOST_AND_PORT } = getAppHostConfig();

const formSpacing = css`
  padding-top: 14px;
  padding-bottom: 14px;
`;

const FormContainer = styled('div')`
  max-width: 800px;
  min-width: 280px;
`;

const DateTimeContainer = styled('div')`
  display: inline-block;
  max-width: 200px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

interface ReducerState extends SelectAllOnServerApi {
  projectUid: string;
  jobParts?: Job[];
  assignedUsers?: (User | Vendor)[];
  foundUsers?: {
    other: User[];
    vendors: Vendor[];
    recent: User[];
    discovered: User[];
  };
  isLoadingJobs: boolean;
  isUpdatingJobs: boolean;
  isLoadingUsers: boolean;
  isError: boolean;
  error?: object;
  offset: string;
  returnUrl?: string;
}

interface UpdateJobsApi {
  jobPartIds: number[];
  assignedUsers?: number[];
  assignedVendors?: number[];
  newStatus?: string;
  dueDate?: string;
  dueTime?: string;
}

interface Props extends RouteComponentProps {
  session: AuthenticatedSession;
}

const getUserTimezone = (session): Zone | string => {
  return session && 'user' in session && session.user.timezone
    ? session.user.timezone
    : new LocalZone();
};

const getUniqueOrUndefined = <T extends unknown>(items: T[]): T | undefined => {
  const uniqueItems = new Set(items);
  if (uniqueItems.size === 1) {
    return uniqueItems.values().next().value;
  }
  return undefined;
};

const getUserRole = (session): string => {
  return session && 'user' in session && session.user.role;
};

export const JobEdit = withRouter(
  ({ location, history, session }: Props): ReactElement => {
    const init = (): ReducerState => {
      const query = parse(location.search, { parseNumbers: true, parseBooleans: true });
      const { id, jobSelection, jobSelectionAll, jobSelectionTotal } = query;
      const level = query['jobFilter.level'];
      const fileName = query['jobFilter.fileName'];
      const targetLocale = query['jobFilter.targetLang'];
      const assignedTo = query['jobFilter.assignedTo.id'];
      const status = query['jobFilter.status'];
      const dueInHours = query['jobFilter.dateDue'];
      const jobPartIds =
        typeof jobSelection === 'number' ? [jobSelection] : (jobSelection as number[]);

      return {
        projectUid: id as string,
        jobPartIds: jobPartIds,
        selectAllOnServer: jobSelectionAll === true,
        totalSelectedJobs: (jobSelectionTotal as number) || jobPartIds.length,
        level: level as number,
        fileName: typeof fileName === 'string' ? [fileName] : (fileName as string[]),
        targetLocale:
          typeof targetLocale === 'string' ? [targetLocale] : (targetLocale as string[]),
        assignedTo: typeof assignedTo === 'number' ? [assignedTo] : (assignedTo as number[]),
        status: typeof status === 'string' ? [status] : (status as string[]),
        dueInHours: typeof dueInHours === 'number' ? [dueInHours] : (dueInHours as number[]),
        isLoadingJobs: false,
        isLoadingUsers: false,
        isUpdatingJobs: false,
        isError: false,
        offset: DateTime.local().toFormat('ZZZZZ'),
        returnUrl: query.returnUrl as string,
      };
    };

    const reducer = (state, action): ReducerState => {
      switch (action.type) {
        case 'loadingJobParts':
          return {
            ...state,
            isLoadingJobs: true,
            isError: false,
          };
        case 'loadedJobParts':
          return {
            ...state,
            isLoadingJobs: false,
            jobParts: action.jobParts,
            assignedUsers: action.assignedUsers,
          };
        case 'loadJobPartsFailed':
          console.warn(action.error);
          return {
            ...state,
            isLoadingJobs: false,
            isError: true,
            error: action.error,
          };
        case 'loadingUsers':
          return {
            ...state,
            isLoadingUsers: true,
            isError: false,
          };
        case 'loadedUsers':
          return {
            ...state,
            isLoadingUsers: false,
            foundUsers: action.foundUsers,
          };
        case 'loadUsersFailed':
          console.warn(action.error);
          return {
            ...state,
            isLoadingUsers: false,
            isError: true,
            error: action.error,
          };
        case 'updatingJobs':
          return {
            ...state,
            isUpdatingJobs: true,
          };
        case 'updatedJobs':
          return {
            ...state,
            isUpdatingJobs: false,
          };
        case 'updateJobsFailed':
          console.warn(action.error);
          return {
            ...state,
            isUpdatingJobs: false,
            isError: true,
            error: action.error,
          };
        case 'offsetChanged':
          return {
            ...state,
            offset: action.offset,
          };
        default:
          return state;
      }
    };

    const [reducerState, dispatch] = useReducer(reducer, null, init);
    const [validating, setValidating] = useState(false);
    const {
      projectUid,
      jobPartIds,
      jobParts,
      totalSelectedJobs,
      foundUsers,
      assignedUsers,
      isLoadingJobs,
      isUpdatingJobs,
      isLoadingUsers,
      isError,
      error,
      selectAllOnServer,
      level,
      assignedTo,
      status,
      fileName,
      targetLocale,
      dueInHours,
      offset,
      returnUrl,
    } = reducerState;

    useEffect(() => {
      dispatch({
        type: 'offsetChanged',
        offset: DateTime.local()
          .setZone(getUserTimezone(session))
          .toFormat("ZZZZZ '(UTC'ZZ')'"),
      });
    }, [session]);

    useEffect(() => {
      dispatch({ type: 'loadingJobParts' });
      const query = {
        jobPartIds,
      };

      AtmsApiClient.httpGet(`/api/job/list?${stringify(query)}`)
        .then(result => {
          dispatch({
            type: 'loadedJobParts',
            jobParts: result.jobs,
            assignedUsers: Object.values(
              result.jobs[0].assignees.reduce((map, linguist) => {
                map[linguist.id] = linguist;
                return map;
              }, {})
            ),
          });
        })
        .catch(err => {
          publishCountMetric('loadJobPartsFailed-JobEdit', 'error', err.message);
          dispatch({ type: 'loadJobPartsFailed', error: apiErrorToErrorMessage(err) });
        });
    }, [jobPartIds]);

    useEffect(() => {
      if (jobParts) {
        dispatch({ type: 'loadingUsers' });
        const query = {
          // TODO: Get source locale, target locale, workflow step, and client for additional filtering
          sourceLocale: getUniqueOrUndefined(jobParts.map(jp => jp.sourceLocale)),
          targetLocale: getUniqueOrUndefined(jobParts.map(jp => jp.targetLocale)),
          workflowStep: getUniqueOrUndefined(
            jobParts.map(jp => jp.workflowStep && jp.workflowStep.id)
          ),
          client: getUniqueOrUndefined(jobParts.map(jp => jp.client && jp.client.id)),
        };

        AtmsApiClient.httpGet(
          `//${WEB_HOST_AND_PORT}/web/api/v9/job/getUsersAndVendorsForJobAssign?${stringify(query)}`
        )
          .then(result => {
            dispatch({ type: 'loadedUsers', foundUsers: result });
          })
          .catch(err => {
            publishCountMetric('loadUsersFailed-JobEdit', 'error', err.message);
            dispatch({ type: 'loadUsersFailed', error: apiErrorToErrorMessage(err) });
          });
      }
    }, [jobPartIds, jobParts, assignedUsers]);

    /**
     * Per AWS Specifications, only start validating after the initial submit.
     * @param e the event
     * @param handleSubmit formik submit event handler function.
     */
    const enableValidation = (e, handleSubmit): void => {
      e.preventDefault();
      setValidating(true);
      handleSubmit();
    };

    const toDateTime = (
      date: string | undefined,
      time: string | undefined
    ): DateTime | undefined => {
      let dateString: string;
      if (!date || date.length === 0) {
        return undefined;
      }
      if (time && time.length > 0) {
        dateString = `${date}T${time}`;
      } else {
        dateString = date;
      }
      return DateTime.fromISO(dateString, {
        zone: userTimezone,
      });
    };

    const handleSubmit = async (values: UpdateJobsApi): Promise<void> => {
      dispatch({ type: 'updatingJobs' });

      // TODO: This can be made more efficient if needed
      const assignedVendors = values?.assignedUsers
        ?.map(uid => foundUsers?.vendors?.find(v => uid === v.buyerUserId))
        ?.filter(v => v);
      const assignedVendorUserIds = assignedVendors?.map(v => v?.buyerUserId);
      const assignedVendorIds = assignedVendors?.map(v => v?.id);
      const assignedUserIds = values?.assignedUsers?.filter(
        uid => !assignedVendorUserIds?.includes(uid)
      );

      const dueDateTime = toDateTime(values.dueDate, values.dueTime);

      const request = {
        projectUid: projectUid,
        jobPartIds: values.jobPartIds,
        assignedUserIds: assignedUserIds && assignedUserIds.length > 0 ? assignedUserIds : 'null',
        assignedVendorIds:
          assignedVendorIds && assignedVendorIds.length > 0 ? assignedVendorIds : 'null',
        newStatus: values.newStatus,
        dueDate: dueDateTime?.toISO?.(),
        selectAllOnServer,
        level,
        assignedTo,
        status,
        fileName,
        targetLocale,
        dueInHours,
      };

      try {
        const backgroundTask: BackgroundTask = await AtmsApiClient.httpPost(
          '/api/job/updateAsync',
          request
        );
        await awaitBackgroundTask(backgroundTask);
        dispatch({ type: 'jobsUpdated' });
        history.push(returnUrl ?? `/web/project2/show/${projectUid}`);
      } catch (err) {
        publishCountMetric('updateJobsFailed-JobEdit', 'error', err.message);
        dispatch({ type: 'updateJobsFailed', error: apiErrorToErrorMessage(err) });
      }
    };

    const awaitBackgroundTask = (inputBackgroundTask: BackgroundTask): Promise<BackgroundTask> => {
      return new Promise<BackgroundTask>((resolve, reject) => {
        if (inputBackgroundTask.status === 'OK') {
          resolve(inputBackgroundTask);
        } else if (inputBackgroundTask.status === 'ERROR') {
          reject(inputBackgroundTask);
        } else {
          setTimeout(() => {
            AtmsApiClient.httpGet(
              `/api/backgroundTask?${stringify({ id: inputBackgroundTask.id })}`
            ).then((backgroundTask: BackgroundTask) => {
              resolve(awaitBackgroundTask(backgroundTask));
            });
          }, 1000);
        }
      });
    };

    const anyJobPartsHaveOffers = jobParts?.find(jp => jp.hasOffer) != null;

    const renderForm = ({
      values,
      errors,
      handleSubmit,
      handleChange,
      handleBlur,
      setFieldValue,
    }): ReactElement => {
      const handleChangeUsers = (e: CustomEvent<Select.MultiselectChangeDetail>): void => {
        setFieldValue(
          'assignedUsers',
          e.detail.selectedIds.map(stringId => parseInt(stringId))
        );
      };

      const handleChangeDate = (e: CustomEvent<DatePicker.ChangeDetail>): void => {
        handleChange(e);

        const dueDate = e.detail.value;
        const dateTime = toDateTime(dueDate, values.dueTime) ?? DateTime.local();
        const offset = dateTime.toFormat('ZZZZZ');

        dispatch({ type: 'offsetChanged', offset: offset });
      };

      const handleChangeTime = (e: CustomEvent<TimeInput.ChangeDetail>): void => {
        handleChange(e);

        const dueTime = e.detail.value;
        const dateTime = toDateTime(values.dueDate, dueTime) ?? DateTime.local();
        const offset = dateTime.toFormat('ZZZZZ');

        dispatch({ type: 'offsetChanged', offset: offset });
      };

      return (
        <FormContainer>
          <form onSubmit={e => enableValidation(e, handleSubmit)}>
            <Form
              header={
                <PageHeader
                  title={I18n.t(
                    { one: 'Editing %{fileName}', other: 'Editing %{count} jobs' },
                    {
                      count: totalSelectedJobs,
                      fileName: jobParts?.[0]?.fileName,
                    }
                  )}
                  extraContent={<HelpInfoLink helpId={JOB_EDIT_HELP} />}
                />
              }
              actions={
                <div>
                  <Button
                    id="update"
                    text={I18n.t(
                      { one: 'Update job', other: 'Update jobs' },
                      { count: jobParts?.length }
                    )}
                    variant="primary"
                    onClick={e => enableValidation(e, handleSubmit)}
                    disabled={isUpdatingJobs}
                    loading={isUpdatingJobs}
                  />
                </div>
              }
              errorText={
                Object.keys(errors).length !== 0
                  ? I18n.t('The form contains errors. Fix them and resubmit.')
                  : ''
              }
            >
              <FormSection>
                <ColumnLayout>
                  <div data-awsui-column-layout-root="true">
                    <FormField
                      label={I18n.t('Assigned linguists')}
                      description={
                        anyJobPartsHaveOffers
                          ? I18n.t(
                              'Changing assignment is disabled because one or more selected jobs have open offers'
                            )
                          : undefined
                      }
                    >
                      <MultiselectWithSelectAllInGroup
                        loading={isLoadingUsers}
                        disabled={anyJobPartsHaveOffers}
                        options={
                          (foundUsers &&
                            ([
                              foundUsers.recent &&
                                foundUsers.recent.length > 0 && {
                                  label: I18n.t('Recent'),
                                  options: [
                                    ...foundUsers.recent.map(linguist => {
                                      return {
                                        id: '' + linguist.id,
                                        label: '' + displayUser(linguist),
                                        description: `${linguist.userName} (${linguist.email})`,
                                        labelTag: linguist.role,
                                      };
                                    }),
                                  ],
                                },
                              foundUsers.vendors &&
                                foundUsers.vendors.length > 0 && {
                                  label: I18n.t('Vendors'),
                                  options: [
                                    ...foundUsers.vendors.map(vendor => {
                                      return {
                                        id: '' + vendor.buyerUserId,
                                        label: '' + vendor.name,
                                      };
                                    }),
                                  ],
                                },
                              foundUsers.discovered &&
                                foundUsers.discovered.length > 0 && {
                                  label: I18n.t('Discovered'),
                                  options: [
                                    ...foundUsers.discovered.map(linguist => {
                                      return {
                                        id: '' + linguist.id,
                                        label: '' + displayUser(linguist),
                                        description: `${linguist.userName} (${linguist.email})`,
                                        labelTag: linguist.role,
                                      };
                                    }),
                                  ],
                                },
                              foundUsers.other &&
                                foundUsers.other.length > 0 && {
                                  label: I18n.t('Other'),
                                  options: [
                                    ...foundUsers.other.map(linguist => {
                                      return {
                                        id: '' + linguist.id,
                                        label: '' + displayUser(linguist),
                                        description: `${linguist.userName} (${linguist.email})`,
                                        labelTag: linguist.role,
                                      };
                                    }),
                                  ],
                                },
                            ].filter(o => o) as Select.OptionsGroup[])) ||
                          []
                        }
                        selectedIds={values.assignedUsers.map(id => '' + id)}
                        onChange={handleChangeUsers}
                        onBlur={handleBlur}
                        placeholder={I18n.t('Choose linguists')}
                        checkboxes={true}
                        keepOpen={true}
                        controlId="assignedUsers"
                        id="assignedUsers"
                        filteringType="auto"
                      />
                    </FormField>
                    <FormField
                      label={I18n.t('Status')}
                      description={
                        getUserRole(session) !== 'ADMIN' && anyJobPartsHaveOffers
                          ? I18n.t(
                              'Changing status is disabled because one or more selected jobs have open offers'
                            )
                          : undefined
                      }
                      errorText={errors.newStatus}
                    >
                      <Select
                        disabled={getUserRole(session) !== 'ADMIN' && anyJobPartsHaveOffers}
                        options={[
                          { label: I18n.t('New'), id: 'NEW' },
                          { label: I18n.t('Emailed to linguist'), id: 'EMAILED' },
                          { label: I18n.t('Accepted by linguist'), id: 'ASSIGNED' },
                          { label: I18n.t('Declined by linguist'), id: 'DECLINED_BY_LINGUIST' },
                          { label: I18n.t('Completed by linguist'), id: 'COMPLETED_BY_LINGUIST' },
                          { label: I18n.t('Delivered'), id: 'COMPLETED' },
                          {
                            label: I18n.t('Canceled'),
                            id: 'CANCELLED',
                            disabled: jobPart?.hasParent,
                          },
                        ]}
                        selectedId={values.newStatus}
                        onChange={handleChange}
                        controlId="newStatus"
                        id="newStatus"
                      />
                    </FormField>
                    {jobPart && !jobPart.hasParent && (
                      <FormField
                        label={I18n.t('Due date')}
                        hintText={I18n.t('Time zone: %{tz}', { tz: offset })}
                      >
                        <DateTimeContainer>
                          <DatePicker
                            placeholder="YYYY-MM-DD"
                            todayLabel={I18n.t('Today')}
                            nextMonthLabel={I18n.t('Next month')}
                            previousMonthLabel={I18n.t('Previous month')}
                            value={values.dueDate}
                            onChange={handleChangeDate}
                            name="dueDate"
                          />
                        </DateTimeContainer>
                        <DateTimeContainer>
                          <TimeInput
                            placeholder={'hh:mm'}
                            format={'hh:mm'}
                            value={values.dueTime}
                            onChange={handleChangeTime}
                            name="dueTime"
                          />
                        </DateTimeContainer>
                      </FormField>
                    )}
                  </div>
                </ColumnLayout>
              </FormSection>
              {reducerState.isError && (
                <div className={formSpacing}>
                  <Flash
                    id="validation-error"
                    type="error"
                    dismissible={true}
                    content={I18n.t(
                      'Please fix the issue and retry. If the issue persists, contact support. Error: %{e}',
                      { e: JSON.stringify(error) }
                    )}
                    header={I18n.t('Something went wrong.')}
                  />
                </div>
              )}
            </Form>
          </form>
        </FormContainer>
      );
    };

    const jobPart = jobParts?.[0];
    const userTimezone = getUserTimezone(session);
    const dueDateTime =
      jobPart?.dateDue && userTimezone
        ? DateTime.fromISO(jobPart.dateDue).setZone(userTimezone)
        : undefined;

    const initialFormValues: UpdateJobsApi = {
      jobPartIds: reducerState.jobPartIds,
      assignedUsers: assignedUsers?.map(u => (u as Vendor).buyerUserId || u.id),
      newStatus: jobPart?.status,
      dueDate: dueDateTime?.toFormat('yyyy-LL-dd') ?? '',
      dueTime: dueDateTime?.toFormat('HH:mm') ?? '00:00',
    };

    const schema = yup.object().shape({
      jobPartIds: yup.array(),
      assignedUsers: yup.array(),
      newStatus: yup.string().when('assignedUsers', (assignedUsers, schema) => {
        return assignedUsers.length > 1
          ? schema.oneOf(
              [
                'NEW',
                'EMAILED',
                'DECLINED_BY_LINGUIST',
                'COMPLETED_BY_LINGUIST',
                'COMPLETED',
                'CANCELLED',
              ],
              I18n.t("Multiple assignees are not allowed in 'Accepted by linguist' state")
            )
          : schema.oneOf([
              'NEW',
              'EMAILED',
              'ASSIGNED',
              'DECLINED_BY_LINGUIST',
              'COMPLETED_BY_LINGUIST',
              'COMPLETED',
              'CANCELLED',
            ]);
      }),
      dueDate: yup.string(),
      dueTime: yup.string(),
    });

    return (
      <Fragment>
        {isLoadingJobs && <Spinner size="large" />}
        {isError && !jobParts && (
          <Alert
            content={I18n.t('No matching jobs found')}
            type="error"
            buttonText={I18n.t('Return to project')}
            onButtonClick={(): void => history.push(`/web/project2/show/${projectUid}`)}
          />
        )}
        {jobParts && (
          <PolarisFormik
            initialValues={initialFormValues}
            validationSchema={schema}
            validateOnChange={validating}
            onSubmit={handleSubmit}
            render={renderForm}
          />
        )}
      </Fragment>
    );
  }
);
