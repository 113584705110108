import React, { ReactElement, useEffect, useState } from 'react';
import {
  Button,
  Box,
  Flashbar,
  FormField,
  Header,
  SpaceBetween,
  Modal,
  Cards,
} from '@amzn/awsui-components-react-v3/polaris';
import PropTypes from 'prop-types';
import { AtmsApiClient } from '@amzn/et-console-components';

interface ErrorInfo {
  header: string;
  message: string;
}

interface CardItem {
  header: string;
  description: JSX.Element;
  content: JSX.Element;
}

export const ScoringModel = ({ apiClient }): ReactElement => {
  const FEATURE_IMPORT_SCORING_MAPPING = 'import-scoring-mappings';
  const FEATURE_UPDATE_SCORING_MODEL = 'update-scoring-model';
  const [showSelfServiceModal, setshowSelfServiceModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<ErrorInfo | null>(null);
  const [scoringModelFile, setScoringModelFile] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState('');
  const [feature, setFeature] = useState('');
  const [modalHeader, setModalHeader] = useState('');
  const [items, setItems] = useState([] as CardItem[]);

  const handleSubmit = async (): Promise<void> => {
    setError(null);
    setIsSubmitting(true);

    switch (feature) {
      case FEATURE_UPDATE_SCORING_MODEL:
        await apiClient
          .multipartUpload(`/api/scoring/updateScoringModel`, {
            scoringFileName: scoringModelFile,
          })
          .then(response => {
            setSuccessAlert(
              `Successfully imported scoring model: ${response.name} (version ${response.version})`
            );
            setShowSuccessAlert(true);
          })
          .catch(error => {
            setError({
              header: 'Error importing scoring model file',
              message: `There was a problem importing scoring model file: ${error}`,
            });
          })
          .finally(() => {
            setshowSelfServiceModal(false);
            setIsSubmitting(false);
            setFeature('');
          });
        break;

      case FEATURE_IMPORT_SCORING_MAPPING:
        await apiClient
          .multipartUpload(`/api/scoring/importSampledQAMappings`, {
            mappingsFileName: scoringModelFile,
          })
          .then(() => {
            setSuccessAlert(`Successfully mapped project templates to scoring models`);
            setShowSuccessAlert(true);
          })
          .catch(error => {
            setError({
              header: 'Error mapping scoring models',
              message: `There was a problem importing scoring model mapping file: ${error}`,
            });
          })
          .finally(() => {
            setshowSelfServiceModal(false);
            setIsSubmitting(false);
            setFeature('');
          });
        break;

      default:
        break;
    }
  };

  const handleFileChange = (event): void => {
    setScoringModelFile(event.target.files[0]);
  };

  useEffect(() => {
    const evaluateCardItems = async () => {
      const items: CardItem[] = [];
      items.push({
        header: 'Import an ATMS Scoring Model',
        description: (
          <>
            Import an ATMS Scoring Model for your organization.
            <br />
            For more information about ATMS Scoring Model SOP{' '}
            <a
              href="https://w.amazon.com/bin/view/TranslationTeam/SDPillar/ProjectManagement/"
              target="_blank"
              rel="noopener noreferrer"
            >
              click here
            </a>
            .
            <br />
          </>
        ),
        content: (
          <>
            <Button
              id="importScoringModelButton"
              variant="primary"
              loading={isSubmitting}
              iconName="upload"
              onClick={() => {
                setshowSelfServiceModal(true);
                setFeature(FEATURE_UPDATE_SCORING_MODEL);
                setModalHeader('Upload a scoring model file');
              }}
            >
              Import Scoring Model
            </Button>
          </>
        ),
      });

      try {
        const postMortemScoringSettings = await apiClient.httpGet('/api/postMortemScoringSettings');
        if (postMortemScoringSettings.enabled) {
          items.push({
            header: 'Map ATMS project templates and scoring models for Post Mortem Scoring',
            description: (
              <>
                Map ATMS project templates to scoring models.
                <br />
                For more information about ATMS Scoring Model SOP{' '}
                <a
                  href="https://w.amazon.com/bin/view/TranslationTeam/SDPillar/ProjectManagement/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  click here
                </a>
                .
                <br />
              </>
            ),
            content: (
              <>
                <Button
                  id="mapScoringModelButton"
                  variant="primary"
                  loading={isSubmitting}
                  iconName="upload"
                  onClick={() => {
                    setshowSelfServiceModal(true);
                    setFeature(FEATURE_IMPORT_SCORING_MAPPING);
                    setModalHeader('Post Mortem Scoring: map project templates and scoring models');
                  }}
                >
                  Map project templates to scoring models
                </Button>
              </>
            ),
          });
        }
      } catch (error) {
        console.error(`error checking post mortem scoring settings, error: ${error}`);
      }
      setItems(items);
    };
    evaluateCardItems();
  }, []);

  return (
    <>
      <Modal
        id="selfServiceModal"
        onDismiss={() => {
          setshowSelfServiceModal(false);
        }}
        visible={showSelfServiceModal}
        closeAriaLabel="Close modal"
        size="medium"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                id="cancelButton"
                variant="link"
                onClick={() => {
                  setshowSelfServiceModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                id="submitScoringModelButton"
                variant="primary"
                disabled={scoringModelFile == null || isSubmitting}
                onClick={() => handleSubmit()}
                loading={isSubmitting}
              >
                Submit
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={<Header variant="h2">{modalHeader}</Header>}
      >
        <FormField>
          <input type="file" name="scoringModelFile" accept=".xlsx" onChange={handleFileChange} />
        </FormField>
      </Modal>
      <SpaceBetween size="m" direction="vertical">
        {showSuccessAlert && (
          <Flashbar
            id={'successAlert'}
            items={[
              {
                type: 'success',
                content: successAlert,
                dismissible: true,
                onDismiss: () => setShowSuccessAlert(false),
              },
            ]}
          />
        )}
        {error != null && (
          <Flashbar
            id={'errorFlashbar'}
            items={[
              {
                header: error.header,
                type: 'error',
                content: error.message,
                dismissible: true,
                onDismiss: () => setError(null),
              },
            ]}
          />
        )}
      </SpaceBetween>
      <header id="header">
        <h1 style={{ marginBottom: 15, padding: 0 }}>Configure Scoring Models</h1>
      </header>
      <Cards
        cardDefinition={{
          header: item => item.header,
          sections: [
            {
              content: item => item.description,
            },
            {
              content: item => item.content,
            },
          ],
        }}
        cardsPerRow={[{ cards: 1 }, { minWidth: 500, cards: 2 }]}
        items={items}
      />
    </>
  );
};

ScoringModel.propTypes = {
  apiClient: PropTypes.object,
};

export const ScoringModelView = (): ReactElement => {
  return ScoringModel({
    apiClient: AtmsApiClient,
  });
};

export default ScoringModelView;
