import React, { useState, useRef } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Route, Switch, Redirect, withRouter } from 'react-router';
import { cx, css } from 'emotion';
import { AppLayout, Flash } from '@amzn/awsui-components-react/polaris';

import ErrorBoundary from '../shared/ErrorBoundary';

import { HelpContentRouter } from './HelpContentRouter';
import _LegacyPageLoader from './LegacyPageLoader';
import NotFound from './NotFound';
import { CreateAnalysisWorkflow } from './project/CreateAnalysisWorkflow';
import { EmailJobsWorkflow } from './project/EmailJobsWorkflow';
import { JobEdit } from './project/jobs/JobEdit';
import { JobCreate } from './project/jobs/JobCreate';
import { PrivacyPolicyConsent } from './PrivacyPolicyConsent';
import { PretranslateWorkflow } from './project/PretranslateWorkflow';
import { ProjectCreate } from './project/ProjectCreate';
import { ProjectEdit } from './project/ProjectEdit';
import { ProjectList } from './project/ProjectList';
import { ProjectTemplateCreate } from './project/ProjectTemplateCreate';
import { ProjectTemplateEdit } from './project/ProjectTemplateEdit';
import { PseudoTranslateWorkflow } from './project/PseudoTranslateWorkflow';
import { SelectTermBaseWorkflow } from './project/SelectTermBaseWorkflow';
import { SelectTranslationMemoryWorkflow } from './project/SelectTranslationMemoryWorkflow';
import { SplitFileWorkflow } from './project/SplitFileWorkflow';
import { UploadBilingualFileWorkflow } from './project/UploadBilingualFileWorkflow';
import { ImportScoreCardWorkflow } from './project/ImportScoreCardWorkflow';
import isBrowserSupported from './isBrowserSupported';
import unifiedHelpResolver, { unifiedHelpContent } from './unifiedHelpContent';
import useElementSize from './useElementSize';
import useMedia from './useMedia';
import useWindowSize from './useWindowSize';
import { JobPartAudit } from './JobPartAudit';
import { ProjectDetail } from './project/ProjectDetail';
import {
  withRefreshIfStale,
  TmsNav,
  ConsoleNav,
  withAuth,
  useRecentItems,
  withSession,
  usePref,
  isInternalUser,
  getAppHostConfig,
  getPath,
} from '@amzn/et-console-components';
import I18n from '../setupI18n';
import { LinguistJobList } from './project/jobs/LinguistJobList';
import { PostMortemScoringSettings } from './settings/organization/PostMortemScoringSettings';
import { PrivacyPolicy } from './PrivacyPolicy';
import { ScoringModelView } from './settings/organization/ScoringModel';
import { HealthChecker } from './HealthChecker';

const { BASE_HOST, WEB_HOST_AND_PORT } = getAppHostConfig();

// explicitly set the domain (web does this too) so that
// JS in iframe can check size of parent window
document.domain = BASE_HOST;

export const SMALL_SCREEN_QUERY = 'only screen and (max-width: 1000px)';

export const Layout = ({
  location,
  session,
  children,
  innerCss = {},
  className = null,
  includeNav = true,
}) => {
  // AppLayout has a breakpoint at 768 where it moves the left nav to the top;
  // we also set an intermediate breakpoint to auto-collapse the left nav
  // to ensure there's enough space for the content
  const isSmallScreen = useMedia(SMALL_SCREEN_QUERY);

  const currentPath = getPath(location);

  const navEnabled = includeNav && session.user;
  const [navOpen, setIsNavOpen] = usePref('navOpen', !isSmallScreen);

  const headerRef = useRef(null);
  const headerHeight = useElementSize(headerRef, [navEnabled]).height;
  // calculate content height based on window, since vh units are unreliable in iOS
  const contentHeight = useWindowSize().height - headerHeight;

  const [hasHelpContent, setHasHelpContent] = useState(false);
  const [helpOpen, setHelpOpen] = useState(false);

  const isInternal = isInternalUser(session);

  const appClassName = cx(
    css({
      '.awsui & .awsui-app-layout': {
        height: `${contentHeight}px !important`,
      },
      '.awsui & .awsui-app-layout__navigation > div': {
        height: '100%', // allows tmsNav to take up the whole height
      },
    }),
    className
  );

  return (
    <div>
      <div id="header" ref={headerRef}>
        {!isBrowserSupported() && (
          <Flash
            type="info"
            content={I18n.t(
              'Your browser is not supported. Some features might not work as expected. We recommend you upgrade to a newer browser version.'
            )}
          />
        )}

        {navEnabled && <ConsoleNav currentPath={currentPath} session={session} />}

        {session.user && <PrivacyPolicyConsent session={session} />}
      </div>

      {/* TODO: Open tools pane when help content changes */}
      <AppLayout
        className={appClassName}
        navigation={navEnabled && <TmsNav />}
        navigationHide={!navEnabled}
        navigationOpen={navOpen}
        // onToolsChange={}
        onNavigationChange={({ detail: { open } }) => setIsNavOpen(open)}
        toolsHide={!hasHelpContent && true}
        tools={
          <HelpContentRouter
            helpContent={unifiedHelpContent}
            helpResolver={unifiedHelpResolver}
            setHelpOpen={setHelpOpen}
            setHasHelpContent={setHasHelpContent}
            userRole={'user' in session && session.user.role}
            isInternalUser={isInternal}
          />
        }
        toolsOpen={hasHelpContent && helpOpen}
        headerSelector="#header"
        content={
          <section css={innerCss}>
            <ErrorBoundary autoResetOn={currentPath}>{children}</ErrorBoundary>
          </section>
        }
      />
    </div>
  );
};

const LegacyLayout = props => {
  return (
    <Layout
      innerCss={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        visibility: props.session.loading ? 'hidden' : 'visible',
      }}
      {...props}
    />
  );
};

/**
 * Common page component for any new route
 */
export const Page = withAuth(
  ({ location, session, PageLoader, hasBetaVersion = false, hideBetaOptIn = false }) => {
    return (
      <Layout
        location={location}
        session={session}
        includeNav={true}
        hasBetaVersion={hasBetaVersion}
        hideBetaOptIn={hideBetaOptIn}
      >
        <PageLoader session={session} />
      </Layout>
    );
  }
);

/**
 * Load legacy routes in an iframe embedded inside a <LegacyLayout>.
 *
 * If the requested page shouldn't have any chrome, hide the layout chrome
 * (top/side navs). We hide rather than conditionally rendering the layout, since
 * the latter would change the DOM and cause the iframe/subdocument we just loaded
 * to reload
 */
export const LegacyPage = withAuth(
  ({
    location,
    history,
    session,
    LegacyPageLoader = _LegacyPageLoader,
    hasBetaVersion = false,
    hideBetaOptIn = false,
  }) => {
    const currentPath = getPath(location);
    const [includeNav, setIncludeNav] = useState(true);

    const includeNavRef = useRef();
    includeNavRef.current = includeNav;

    // extra styles so the iframe can take up all available space
    const className = cx(
      css({
        '.awsui & .awsui-app-layout__content': {
          overflow: 'hidden',
          position: 'relative',
          width: '100%',
          height: '100%',
        },
      }),
      'awsui-util-no-gutters'
    );

    const [, addRecentItem] = useRecentItems(session.user && session.user.id);

    const onLoadLegacyPage = ({ path, title, features = { includeNav: true }, item, method }) => {
      document.title = title;
      if (includeNavRef.current !== features.includeNav) {
        setIncludeNav(features.includeNav);
      }
      if (item) {
        addRecentItem({ path, ...item });
      }
      // because navigation events in the iframe create a duplicate top-level
      // history entry, we can always just replace that with the real iframe
      // path (replace instead of push, and no need to check its value)
      history.replace(path, { method });
    };

    const beforeNavigateLegacyPage = ({ path }) => {
      // We're getting a notification before the iframe starts navigating instead
      // of after it finishes. This is faster and prevents a bug in Firefox where
      // history frames were being lost.
      history.push(path);
    };

    return (
      <LegacyLayout
        location={location}
        session={session}
        className={className}
        includeNav={includeNav}
        hasBetaVersion={hasBetaVersion}
        hideBetaOptIn={hideBetaOptIn}
      >
        <LegacyPageLoader
          preNavigate={beforeNavigateLegacyPage}
          onLoad={onLoadLegacyPage}
          webUri={`${window.location.protocol}//${WEB_HOST_AND_PORT}`}
          path={currentPath}
        />
      </LegacyLayout>
    );
  }
);

// although we don't explicitly use any router props here, we use the withRouter HOC:
//  1. to ensure re-renders when the URL changes; that way withSession will pick up
//     JWT changes that happen in iframe responses (there's no browser API for
//     listening to cookie change events)
//  2. so that withRefreshIfStale can safely reload the app when a new version is released
export const RootApp = withRouter(
  withSession(
    withRefreshIfStale(({ session }) => {
      const isInBeta = false;

      if (session.error) {
        // ErrorBoundary will give them a nice error page that gives them some
        // options (back to old UI, retry, etc)
        throw session.error;
      }

      let role;
      if (session && session.user) {
        role = session.user.role;
      }

      // TODO: We need to revise these steps since we're not likely to run another wholesale beta.
      //
      // Migrate pages in three stages:
      //  1. Create a new version of the page with a separate path for development.
      //  2. Move the page to the same path as the original page and add the 'isBeta'
      //    flag and the 'prefix' key to start the beta. Add the 'newPage' flag
      //    to any new pages you add for the beta as well (e.g. modals converted to
      //    full pages).
      //  3. Remove the 'isBeta' and 'newPage' flags when all blockers are resolved
      //    to end the beta.
      const pages = [
        {
          content: ProjectDetail,
          path: '/web/project(2)?/show/:uid',
        },
        {
          content: CreateAnalysisWorkflow,
          path: '/web/analysis/create/:projectUid',
        },
        {
          content: PretranslateWorkflow,
          path: '/web/pretranslate/:projectUid',
        },
        {
          content: PseudoTranslateWorkflow,
          path: '/web/pseudoTranslate/:projectUid',
        },
        {
          content: UploadBilingualFileWorkflow,
          path: '/web/uploadBilingualFile/:projectUid',
        },
        {
          content: SelectTranslationMemoryWorkflow,
          path: '/web/selectTranslationMemoryWorkflow/:projectUid',
        },
        {
          content: SelectTermBaseWorkflow,
          path: '/web/selectTermBaseWorkflow/:projectUid',
        },
        {
          content: EmailJobsWorkflow,
          path: '/web/emailJobsWorkflow/:projectUid',
        },
        {
          content: SplitFileWorkflow,
          path: '/web/splitFileWorkflow/:projectUid',
        },
        {
          content: ProjectList,
          path: '/web/project/list',
        },
        role === 'LINGUIST' && {
          content: LinguistJobList,
          path: '/web/job/list',
        },
        {
          content: ImportScoreCardWorkflow,
          path: '/web/importScoreCardWorkFlow/:projectUid',
        },
        {
          content: JobEdit,
          path: '/web/project2/editJobs',
        },
        {
          content: JobCreate,
          path: '/web/job2/create',
          prefix: '/web/job2/create',
        },
        {
          content: ProjectCreate,
          path: '/web/project/create',
          prefix: '/web/project/create',
        },
        {
          content: ProjectEdit,
          path: '/web/project/edit/:projectUid',
          prefix: '/web/project/edit',
        },
        {
          content: ProjectTemplateCreate,
          path: '/web/projectTemplate/create',
          prefix: '/web/projectTemplate/create',
        },
        {
          content: ProjectTemplateEdit,
          path: '/web/projectTemplate/edit/:projectTemplateId',
          prefix: '/web/projectTemplate/edit',
        },
        {
          content: PostMortemScoringSettings,
          path: '/web/setup/postMortemScoringSettings',
        },
        {
          content: ScoringModelView,
          path: '/web/setup/scoringModel',
        },
        {
          content: PrivacyPolicy,
          path: '/privacyPolicy',
        },
      ].filter(t => t);

      const pageHasProperty = (url, prop) => {
        // eslint-disable-next-line no-unused-vars
        for (const page of pages) {
          if (page[prop] && url.startsWith(page.prefix)) {
            return true;
          }
        }
        return false;
      };

      const hasBetaVersion = url => {
        return pageHasProperty(url, 'isBeta');
      };

      const hideBetaOptIn = url => {
        return pageHasProperty(url, 'hideBetaOptIn');
      };

      return (
        <Switch>
          {/*
        TODO: add new routes here to override legacy UI
        Note: be sure to wire up RecentItems for ones we want to track
        */}
          <Route path="/jobPartAudit" render={props => <JobPartAudit {...props} />} />
          <Route path="/healthCheck" render={props => <HealthChecker {...props} />} />
          {pages.map(page => {
            if (page.newPage || !page.isBeta || isInBeta) {
              return (
                <Route
                  key={page.path}
                  path={page.path}
                  render={props => (
                    <Page
                      session={session}
                      PageLoader={page.content}
                      hasBetaVersion={page.isBeta || page.newPage}
                      hideBetaOptIn={page.hideBetaOptIn}
                      {...props}
                    />
                  )}
                />
              );
            } else {
              return null;
            }
          })}
          <Route
            path="/web"
            render={props => (
              <LegacyPage
                session={session}
                hasBetaVersion={hasBetaVersion(props.location.pathname)}
                hideBetaOptIn={hideBetaOptIn(props.location.pathname)}
                {...props}
              />
            )}
          />
          <Redirect from="/" to="/web" exact />
          <Route component={NotFound} />
        </Switch>
      );
    })
  )
);

export default () => (
  <BrowserRouter>
    <ErrorBoundary errorScreenClassName={css({ height: '100vh !important' })}>
      <RootApp />
    </ErrorBoundary>
  </BrowserRouter>
);
