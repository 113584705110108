import * as React from 'react';
import { ReactElement, useCallback, useEffect, useReducer } from 'react';
import { HeaderProps, TableWithGroupByColumn } from '../../components/TableWithGroupByColumn';
import I18n from '../../../setupI18n';
import {
  Table,
  TablePropertyFiltering,
  TableSelection,
  Modal,
  Button,
  Alert,
} from '@amzn/awsui-components-react/polaris';
import { CellContents } from '../../types/commonTypes';
import {
  extractSupportedLocalesByTbIdFromResponse,
  mergeSameTermBases,
  ModalTableContainer,
  TermBaseAssignment,
} from './TermBaseCommons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faDoorClosed } from '@fortawesome/free-solid-svg-icons';
import { stringify } from 'query-string';
import { isPublic } from './TermBaseCommons';
import { TargetLocalesPopup } from '../../components/TargetLocalesPopup';
import { filteringFunction } from '../../../shared/propertyFiltering';
import { publishCountMetric } from '../../metricHelper';
import { AtmsApiClient } from '@amzn/et-console-components';
import { getAppHostConfig } from '@amzn/et-console-components';

const { WEB_HOST_AND_PORT } = getAppHostConfig();

export interface Props {
  organizationName: string; // creator's organization name
  sourceLocale?: string;
  targetLocales: string[];
  visible: boolean;
  isLoadingFromServer?: boolean;
  onClose: (event) => void;
  onConfigure: (event, selectedTBs: TermBaseAssignment[]) => void;
  currConfiguredTermBases?: TermBaseAssignment[];
}

interface ReducerState {
  isLoading: boolean;
  isError: boolean;
  error?: object;
  termBases: TermBaseAssignment[];
  filteringOptions: any[];
  tokens: TablePropertyFiltering.FilteringToken[];
}

export const TermBaseSelect = ({
  sourceLocale,
  targetLocales,
  onClose,
  onConfigure,
  visible,
  isLoadingFromServer,
  organizationName,
  currConfiguredTermBases,
}: Props): ReactElement => {
  /**********************Init***********************/
  const initFilteringOptions = [
    {
      propertyKey: 'tbName',
      propertyLabel: 'name',
      groupValuesLabel: 'Name',
      values: [],
    },
    {
      propertyKey: 'supportedLangs',
      propertyLabel: 'targetLang',
      groupValuesLabel: 'Target locale',
      values: targetLocales,
    },
    {
      propertyKey: 'clientName',
      propertyLabel: 'client',
      groupValuesLabel: 'Client',
      values: [],
    },
  ].filter(f => f);

  const init = (): ReducerState => ({
    isLoading: false,
    isError: false,
    termBases: [],
    filteringOptions: initFilteringOptions,
    tokens: [],
  });

  const reducer = (state: ReducerState, action): ReducerState => {
    switch (action.type) {
      case 'loadingTermBases':
        return {
          ...state,
          isLoading: true,
          isError: false,
        };
      case 'loadedTermBases':
        return {
          ...state,
          isLoading: false,
          isError: false,
          termBases: action.termBases
            .filter(tb => !state.termBases.find(stb => stb.tbId === tb.tbId))
            .concat(state.termBases),
        };
      case 'loadTermBasesFailed':
        return {
          ...state,
          isLoading: false,
          isError: true,
          error: action.error,
        };
      case 'resetTablesAndFiltering':
        return {
          ...state,
          isLoading: false,
          isError: false,
          termBases: [],
          tokens: [],
          filteringOptions: initFilteringOptions,
        };
      case 'checkOrUncheckTermBase':
        return {
          ...state,
          termBases: state.termBases.map(tb => {
            if (tb.tbId === action.termBase.tbId) {
              return action.termBase;
            }
            return tb;
          }),
        };
      case 'updateFilteringOptions':
        state.filteringOptions = initFilteringOptions;
        action.tokens.forEach(token => {
          const propertyKey = token.propertyKey;
          state.filteringOptions = state.filteringOptions.filter(
            f => f['propertyKey'] !== propertyKey
          );
          // if user enters freetext, disable 'name' option
          if (propertyKey === null) {
            state.filteringOptions = state.filteringOptions.filter(
              f => f['propertyKey'] !== 'name'
            );
          }
        });
        return {
          ...state,
          filteringOptions: state.filteringOptions,
          tokens: action.tokens,
        };
      default:
        return {
          ...state,
        };
    }
  };

  const [reducerState, dispatch] = useReducer(reducer, null, init);
  const { isLoading, isError, error, termBases, filteringOptions, tokens } = reducerState;

  /**********************Hooks***********************/

  const loadTermBases = useCallback(
    async (query, currSelectedTermBaseIds: Set<number>): Promise<void> => {
      if (sourceLocale) {
        await Promise.all(
          (targetLocales ?? []).map(tl =>
            AtmsApiClient.httpGet(
              `//${WEB_HOST_AND_PORT}/web/api/v2/termBase/listByLocales?${stringify({
                ...query,
                targetLang: tl,
                sourceLang: sourceLocale,
              })}`
            )
          )
        )
          .then(responses => {
            const supportedLocalesByTbId: {
              [tmId: number]: string[];
            } = extractSupportedLocalesByTbIdFromResponse(responses, targetLocales);
            const extractedTermBases = extractTBsFromResponses(
              responses,
              targetLocales,
              supportedLocalesByTbId
            );
            const allTermBases = markSelectedTermBases(
              mergeSameTermBases(extractedTermBases),
              currSelectedTermBaseIds
            );
            dispatch({
              type: 'loadedTermBases',
              termBases: allTermBases,
            });
          })
          .catch(e => {
            publishCountMetric('loadTermBasesFailed-TermBaseSelect', 'error', e.message);
            dispatch({ type: 'loadTermBasesFailed', error: e });
          });
      } else {
        dispatch({ type: 'loadTermBasesFailed', error: 'Source locale not specified.' });
      }
    },
    [sourceLocale, targetLocales]
  );

  const handleLoadTermBases = useCallback(
    async (tokens: TablePropertyFiltering.FilteringToken[]): Promise<void> => {
      dispatch({ type: 'loadingTermBases' });
      const query = prepareQuery(tokens);
      const currSelectedTermBaseIds = new Set((currConfiguredTermBases ?? []).map(tb => tb.tbId));
      await loadTermBases(query, currSelectedTermBaseIds);
    },
    [loadTermBases, currConfiguredTermBases]
  );

  useEffect(() => {
    // only load the term bases when open the selection page
    if (visible && isLoadingFromServer) {
      dispatch({ type: 'resetTablesAndFiltering' });
      handleLoadTermBases([]);
    }
  }, [visible, isLoadingFromServer, handleLoadTermBases]);

  /**********************Functions***********************/
  const prepareQuery = (tokens: TablePropertyFiltering.FilteringToken[]): object => {
    const query = {};
    tokens.forEach(t => {
      if (t.propertyKey === null) {
        query['name'] = [t.value];
      } else {
        query[t.propertyLabel] = [t.value];
      }
    });
    return query;
  };

  const markSelectedTermBases = (
    termBases,
    currSelectedTermBaseIds: Set<number>
  ): TermBaseAssignment[] => {
    return termBases.map(tb => ({
      ...tb,
      isSelected: currSelectedTermBaseIds.has(tb.tbId),
    }));
  };

  // Helper function of loadTermBases to extracts fallback TBs from the Web API response
  const extractTBsFromResponses = (
    responses,
    targetLangs: string[],
    supportedLocalesByTbId
  ): TermBaseAssignment[] => {
    return responses !== undefined
      ? responses.reduce((pre, cur, index) => {
          if (cur && cur.length > 0) {
            cur = cur.map(tb => ({
              ...tb,
              tbId: tb.id,
              tbName: tb.name,
              clientName: tb.client?.name ?? '-',
              targetLang: targetLangs[index],
              supportedLangs: tb.langs
                ? supportedLocalesByTbId[tb.id]
                : tb.termBasesWithSupportedTargetLocales
                ? tb.termBasesWithSupportedTargetLocales[tb.id]
                : [],
              priority: 0,
              readMode: true,
              writeMode: false,
              qualityAssurance: false,
              isPublic: isPublic(organizationName, tb),
              isNew: true,
              isSelected: false,
            }));
          }
          return [...pre, ...cur];
        }, [])
      : [];
  };

  const getColumnDefinitions = (): Table.ColumnDefinition<TermBaseAssignment>[] => {
    return [
      {
        id: 'name',
        width: 350,
        header: I18n.t('Name'),
        cell: (item): CellContents => <div id={'tbName'}>{item.tbName}</div>,
      },
      {
        id: 'locales',
        width: 200,
        header: I18n.t('Locales'),
        cell: (item): CellContents => (
          <div id={'tbSupportedLocales'}>
            <TargetLocalesPopup
              targetLocales={targetLocales ?? []}
              supportedLocales={item.supportedLangs ?? []}
            />
          </div>
        ),
      },
      {
        id: 'client',
        width: 300,
        header: I18n.t('Client'),
        cell: (item): CellContents => <div id={'tbClient'}>{item.clientName}</div>,
      },
    ];
  };

  const groupByFunction = (item: TermBaseAssignment, groupHeaderMap: any): any => {
    if (item.isPublic) {
      groupHeaderMap['Public'].push(item);
    } else {
      groupHeaderMap['Organization'].push(item);
    }
    return groupHeaderMap;
  };

  /*******************Event Handlers**********************/
  const onSelectionChange = (event): void => {
    dispatch({
      type: 'checkOrUncheckTermBase',
      termBase: event.detail.selectedItems[0],
    });
  };

  const onPropertyFilteringChange = (event): void => {
    dispatch({
      type: 'updateFilteringOptions',
      tokens: event.detail.tokens,
    });
    if (event.detail.tokens.length > 0) {
      handleLoadTermBases(event.detail.tokens);
    }
  };

  /*******************Constants**********************/
  const headers: HeaderProps[] = [
    { icon: <FontAwesomeIcon id="tbHeaderIcon" icon={faDoorClosed} />, name: 'Organization' },
    {
      icon: <FontAwesomeIcon id="tbHeaderIcon" icon={faGlobe} />,
      name: 'Public',
    },
  ];
  termBases.sort((a, b) => a.tbId - b.tbId);

  /**********************Components***********************/
  return isError ? (
    <Alert type="error" content={`${I18n.t('Failed to load term bases')}: ${error}`} />
  ) : (
    <Modal
      id="termbaseSelectModal"
      onDismiss={onClose}
      visible={visible}
      header={I18n.t('Select term bases')}
      size={'large'}
      footer={
        <span className="awsui-util-f-r">
          <Button
            variant="link"
            text={I18n.t('Cancel')}
            onClick={(event): void => {
              onClose(event);
            }}
          />
          <Button
            id="termbaseConfigureBtn"
            variant="primary"
            text={I18n.t('Configure')}
            onClick={(event): void => {
              onConfigure(
                event,
                termBases.filter(tb => tb.isSelected)
              );
            }}
          />
        </span>
      }
    >
      <ModalTableContainer>
        <TableWithGroupByColumn
          id="termbaseSelectTable"
          variant="borderless"
          stickyHeader={false}
          columnDefinitions={getColumnDefinitions()}
          empty={I18n.t('No term bases found')}
          groupByFunction={groupByFunction}
          groupByEmptyMessage={I18n.t('There are no term bases configured')}
          loading={isLoading}
          groupByHeaders={headers}
          items={termBases}
        >
          <TablePropertyFiltering
            filteringFunction={filteringFunction}
            filteringOptions={filteringOptions}
            groupValuesText="Values"
            groupPropertiesText="Properties"
            clearFiltersText="Clear filter"
            placeholder="Search by name, locale, etc."
            allowFreeTextFiltering={true}
            hideOperations={true}
            tokens={tokens}
            onPropertyFilteringChange={onPropertyFilteringChange}
          />
          <TableSelection
            selectedItems={termBases.filter(tb => tb.isSelected)}
            onSelectionChange={onSelectionChange}
          />
        </TableWithGroupByColumn>
      </ModalTableContainer>
    </Modal>
  );
};
