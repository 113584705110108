import React, {
  Fragment,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useReducer,
  MouseEventHandler,
} from 'react';
import {
  Alert,
  ButtonDropdown,
  CustomDetailEvent,
  Icon,
  Spinner,
} from '@amzn/awsui-components-react/polaris';
import {
  displayUser,
  formatLocaleCode,
  formatPercent as etFormatPercent,
  AtmsApiClient,
  AuthenticatedSession,
  getAppHostConfig,
  displayUserWithLoginLink,
} from '@amzn/et-console-components';
import { PageHeader, Container } from '@amzn/et-polaris-utils';
import { parse, stringify } from 'query-string';
import {
  BackgroundTask,
  CellContents,
  Job,
  ScoringJob,
  SegmentMetadata,
  SelectedJobPartApi,
  TableColumnDef,
  TablePropertyFilteringOption,
  User,
  WorkflowStep,
  Project,
} from '../../types/commonTypes';
import { History, Location } from 'history';
import { PROJECT_DETAIL_MANAGE_JOBS_HELP } from './../projectHelpContent';
import { HelpInfoLink } from '../../HelpContentRouter';
import I18n from '../../../setupI18n';
import { StandardModal } from '../../components/StandardModal';
import { displayTimestamp } from '../../../shared/displayTimestamp';
import { EmailJobsWorkflowState } from './../EmailJobsWorkflow';
import { AtmsTable, MainStateBase, NonFilteringQueryApiBase } from '../../components/AtmsTable';
import { T } from '../../components/T';
import { Tooltip } from '../../components/Tooltip';
import { CreateAnalysisWorkflowLocationState } from './../CreateAnalysisWorkflow';
import { RouteComponentProps, withRouter } from 'react-router';
import { buildWorkflowGroupId, getJobPartId, isScoringWorkflow } from '../../../shared/scoring';
import apiErrorToErrorMessage from '../../../shared/apiErrorToErrorMessage';
import { publishCountMetric } from '../../metricHelper';

const formatPercent = (number): number => {
  // only show 100% if it truly is complete
  if (number >= 0.999 && number < 1) number = 0.999;
  return etFormatPercent(number);
};

const { WEB_HOST_AND_PORT, ATMS_BFF_HOST_AND_PORT } = getAppHostConfig();

const statusToDisplay = {
  NEW: I18n.t('New'),
  EMAILED: I18n.t('Emailed'),
  ASSIGNED: I18n.t('Accepted'),
  DECLINED_BY_LINGUIST: I18n.t('Declined'),
  COMPLETED_BY_LINGUIST: I18n.t('Completed'),
  COMPLETED: I18n.t('Delivered'),
  CANCELLED: I18n.t('Cancelled'),
};

const displayToStatus = {};
Object.entries(statusToDisplay).map(([k, v]) => (displayToStatus[v] = k));

const EditorLink = ({ children, ...props }: React.HTMLProps<HTMLAnchorElement>): ReactElement => (
  <a role="button" tabIndex={0} css={{ cursor: 'pointer' }} {...props}>
    {children}
  </a>
);

const isJobClosed = (job: Job): boolean =>
  job.status === 'COMPLETED_BY_LINGUIST' ||
  job.status === 'COMPLETED' ||
  job.status === 'CANCELLED';

const isProjectClosed = (project: Project): boolean =>
  project.status === 'COMPLETED' || project.status === 'CANCELLED';

const canOpenJob = (user: User, job: Job, project: Project): boolean => {
  if (user && user.role === 'LINGUIST') {
    return !isJobClosed(job) && !isProjectClosed(project);
  }
  return true;
};

export const scoreStatus = (
  workflowStep: string | undefined,
  isLoading: boolean,
  isScoringJobExpected: boolean,
  scoringJob: ScoringJob | undefined
): ReactNode | null => {
  if (workflowStep == null || !isScoringWorkflow(workflowStep)) {
    return '-';
  }

  if (isLoading) {
    return (
      <span className="awsui-util-status-inactive">
        <Spinner className="score-loading" />
      </span>
    );
  }

  if (!scoringJob && isScoringJobExpected) {
    return (
      <Tooltip
        content={I18n.t(
          'There was an problem configuring scoring for this job. Please contact ATMS support'
        )}
      >
        <span className="awsui-util-status-negative">
          <Icon name="status-warning" className="score-not-configured" />{' '}
          {I18n.t('Scoring job not found')}
        </span>
      </Tooltip>
    );
  }

  if (!scoringJob) {
    return '-';
  }

  switch (scoringJob.status) {
    case 'NEW':
      return (
        <span className="awsui-util-status-inactive">
          <Icon name="status-in-progress" className="score-new" /> {I18n.t('Requested')}
        </span>
      );
    case 'IN_PROGRESS':
      return (
        <span className="awsui-util-status-inactive">
          <Icon name="status-in-progress" className="score-in-progress" /> {I18n.t('In-progress')}
        </span>
      );
    case 'COMPLETED': {
      if (scoringJob.scoredWordCount === 0) {
        return <span>{I18n.t('N/A')}</span>;
      }
      let className;
      let iconName;
      if (scoringJob.passing) {
        iconName = 'status-positive' as 'status-positive';
        className = 'awsui-util-status-positive' as 'awsui-util-status-positive';
      } else {
        iconName = 'status-negative' as 'status-negative';
        className = 'awsui-util-status-negative' as 'awsui-util-status-negative';
      }
      return (
        <span className={className}>
          <Icon name={iconName} className="score-completed" /> {scoringJob.score}%
        </span>
      );
    }
    case 'CANCELED':
      return (
        <span className="awsui-util-status-inactive">
          <Icon name="status-stopped" className="score-canceled" /> {I18n.t('Canceled')}
        </span>
      );
    default:
      return <span className="awsui-util-status-inactive">{I18n.t('Unknown')}</span>;
  }
};

const taskStatus = (task): ReactElement | null => {
  if (!task) {
    return null;
  }

  if (task.status === 'ERROR') {
    return (
      <span className="awsui-util-status-negative">
        <Tooltip content={task.reason ?? task.finishedDataText}>
          <Icon name="status-warning" />
        </Tooltip>
      </span>
    );
  } else if (task.status === 'RUNNING') {
    return (
      <span className="awsui-util-status-inactive">
        <Spinner />
      </span>
    );
  }

  return null;
};

const completionStatus = (isLoading, segmentMetadata?: SegmentMetadata): ReactNode => {
  if (isLoading) {
    return (
      <span className="awsui-util-status-inactive">
        <Spinner />
      </span>
    );
  }

  if (!segmentMetadata?.counts) {
    return '-';
  }

  const {
    counts: { confirmedSegmentsCount, lockedSegmentsCount, segmentsCount, wordsCount },
  } = segmentMetadata;

  if (segmentMetadata.previousWorkflow && !segmentMetadata.previousWorkflow.completed) {
    return (
      <Fragment>
        <span className="awsui-util-status-negative">
          <Tooltip content={I18n.t('Previous workflow step is not complete')}>
            <Icon name="status-warning" />
          </Tooltip>
        </span>{' '}
        {confirmedSegmentsCount > 0 ? formatPercent(confirmedSegmentsCount / segmentsCount) : ''}
      </Fragment>
    );
  }

  if (segmentsCount === 0) {
    return (
      <Fragment>
        <span className="awsui-util-status-negative">
          <Tooltip content={I18n.t('There are no segments in this job')}>
            <Icon name="status-warning" />
          </Tooltip>
        </span>
      </Fragment>
    );
  }

  return (
    <Tooltip
      content={
        <T>
          <div>Segments: {segmentsCount}</div>
          <div>Confirmed: {confirmedSegmentsCount}</div>
          <div>Locked: {lockedSegmentsCount}</div>
          <div>Words: {wordsCount}</div>
        </T>
      }
    >
      <span>
        {confirmedSegmentsCount > 0 ? formatPercent(confirmedSegmentsCount / segmentsCount) : '-'}
      </span>
    </Tooltip>
  );
};

const wordCount = (isLoading, segmentMetadata?: SegmentMetadata): ReactNode => {
  if (isLoading) {
    return (
      <span className="awsui-util-status-inactive">
        <Spinner />
      </span>
    );
  }

  if (!segmentMetadata?.counts) {
    return '-';
  }

  const {
    counts: { wordsCount },
  } = segmentMetadata;

  return wordsCount;
};

export const qaStatus = (isLoading, segmentMetadata: SegmentMetadata | undefined): ReactNode => {
  if (isLoading) {
    return (
      <span className="awsui-util-status-inactive">
        <Spinner />
      </span>
    );
  }

  if (!segmentMetadata?.counts) {
    return '-';
  }

  const {
    counts: {
      qualityAssuranceResolved,
      qualityAssurance: { warningsCount },
    },
  } = segmentMetadata;

  if (qualityAssuranceResolved) {
    return (
      <Tooltip content={I18n.t('All QA warnings have been resolved')}>
        <span className="awsui-util-status-positive">
          <Icon name="status-positive" className="qa-resolved" />
        </span>
      </Tooltip>
    );
  } else if (warningsCount === 0) {
    // Not run
    return (
      <Tooltip content={I18n.t('QA has not been run')}>
        <span>-</span>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip
        className="awsui-util-status-inactive"
        content={I18n.t('The job has unresolved QA warnings')}
      >
        <Icon name="status-in-progress" className="qa-unresolved" />
      </Tooltip>
    );
  }
};

const statusWithChanges = (
  isLoading,
  status,
  statusChanges: StatusChange[] | undefined
): ReactNode => {
  if (isLoading || !statusChanges || statusChanges.length === 0) {
    return <span>{statusToDisplay[status]}</span>;
  }
  return (
    <Tooltip
      content={
        <dl>
          {statusChanges.map(sc => (
            <Fragment key={sc.dateCreated.timestamp}>
              <dt>{statusToDisplay[sc.status]}</dt>
              <dd>
                {sc.createdBy ? displayUser(sc.createdBy) : I18n.t('Unknown user')} -{' '}
                {displayTimestamp(sc.dateCreated.timestamp)} - {sc.organizationName}
              </dd>
            </Fragment>
          ))}
        </dl>
      }
    >
      {statusToDisplay[status]}
    </Tooltip>
  );
};

export interface Props extends RouteComponentProps {
  project: Project;
  session: AuthenticatedSession;
  level?: number;
  targetLocales?: string[];
  history: History;
  location: Location;
  workflowSteps?: WorkflowStep[];
  canEdit: boolean;
  canCreateJobs: boolean;
  backgroundTasksPollInterval?: number;
  workflowStep?: WorkflowStep;
  scoringEnabled?: boolean;
  onJobListRefreshed?: Function;
  canDownload?: boolean;
}

interface JobBackgroundTasks {
  creationTask: BackgroundTask;
  lastTask: BackgroundTask;
  jobPartId: number;
}

// state
interface FilteringQueryApi {
  assignedTo?: number[];
  status?: string[];
  fileName?: string[];
  targetLocale?: string[];
  dueInHours?: number[];
}

interface StatusChange {
  status: string;
  createdBy: User;
  organizationName: string;
  dateCreated: { timestamp: number };
}

interface MainState extends MainStateBase<Job, FilteringQueryApi, NonFilteringQueryApiBase> {
  isPretranslating: boolean;
  isDeleting: boolean;
  error?: object;
  isLoadingSegmentMetadata: boolean;
  segmentMetadata: Map<number, SegmentMetadata>;
  isLoadingStatusChanges: boolean;
  statusChanges: Map<number, StatusChange[]>;
  backgroundTasks: Map<number, JobBackgroundTasks>;
  lastTasksLoaded: boolean;
  selectAllOnServer: boolean;
  editorUrl?: string;
  atmsServerUrl?: string;
  assignees: User[];
  isDownloadingFile: boolean;
  fileDownloadError?: string;
  fileDownloadWarning?: string;
  showDeleteModal: boolean;
  showDeleteAllTranslationsModal: boolean;
  isChangingJobStatus: boolean;
  showIncompletePreviousWorkflowStep: boolean;
  retryOpenEditorHandler?: () => void;
  isLoadingScoringJobs: boolean;
  scoringJobs: Map<number, ScoringJob>;
}

// reducer
const reducer = (state: MainState, action): MainState => {
  switch (action.type) {
    case 'itemSelectionWithSelectAllOnServerChanged':
      return {
        ...state,
        selectedItems: action.selectedItems,
        selectAllOnServer: action.selectAllOnServer,
      };
    case 'itemSelectionChanged':
      return {
        ...state,
        selectedItems: action.selectedItems,
      };
    case 'searchChanged':
      return {
        ...state,
        queryLoaded: true,
        filteringQuery: action.filteringQuery,
        nonFilteringQuery: action.nonFilteringQuery,
        unmanagedQuery: action.unmanagedQuery,
      };
    case 'jobsLoading':
      return {
        ...state,
        isLoading: true,
        error: undefined,
        backgroundTasks: new Map(),
        segmentMetadata: new Map(),
        statusChanges: new Map(),
        scoringJobs: new Map(),
        selectedItems: [],
      };
    case 'jobsLoaded':
      return {
        ...state,
        isLoading: false,
        items: action.jobs,
        totalItems: action.totalJobs,
        pages: action.pages,
        editorUrl: action.editorUrl,
        atmsServerUrl: action.atmsServerUrl,
        backgroundTasks: action.backgroundTasks,
        lastTasksLoaded: false,
      };
    case 'jobsLoadFailed':
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case 'backgroundTasksLoaded':
      return {
        ...state,
        backgroundTasks: action.backgroundTasks,
        lastTasksLoaded: true,
      };
    case 'backgroundTasksLoadFailed':
      return {
        ...state,
        error: action.error,
      };
    case 'segmentMetadataLoading':
      return {
        ...state,
        isLoadingSegmentMetadata: true,
        error: undefined,
      };
    case 'segmentMetadataLoaded':
      return {
        ...state,
        isLoadingSegmentMetadata: false,
        segmentMetadata: action.segmentMetadata,
      };
    case 'segmentMetadataLoadFailed':
      return {
        ...state,
        isLoadingSegmentMetadata: false,
        error: action.error,
      };
    case 'statusChangesLoading':
      return {
        ...state,
        isLoadingStatusChanges: true,
        error: undefined,
      };
    case 'statusChangesLoaded':
      return {
        ...state,
        isLoadingStatusChanges: false,
        statusChanges: action.statusChanges,
      };
    case 'statusChangesLoadFailed':
      return {
        ...state,
        isLoadingStatusChanges: false,
        error: action.error,
      };
    case 'assigneesLoading':
      return {
        ...state,
        assignees: [],
      };
    case 'assigneesLoaded':
      return {
        ...state,
        assignees: action.assignees,
      };
    case 'assigneesLoadFailed':
      return {
        ...state,
        error: action.error,
      };
    case 'fileDownloading':
      return {
        ...state,
        isDownloadingFile: true,
        fileDownloadError: undefined,
        fileDownloadWarning: undefined,
      };
    case 'fileDownloaded':
      return {
        ...state,
        isDownloadingFile: false,
      };
    case 'fileDownloadFailed':
      return {
        ...state,
        isDownloadingFile: false,
        fileDownloadError: action.fileDownloadError,
        fileDownloadWarning: action.fileDownloadWarning,
      };
    case 'isDeletingChanged':
      return {
        ...state,
        isDeleting: action.isDeleting,
      };
    case 'isPretranslatingChanged':
      return {
        ...state,
        isPretranslating: action.isPretranslating,
      };
    case 'sourceToTargetCopyFailed':
      return {
        ...state,
        error: action.error,
      };
    case 'showDeleteModalChanged':
      return {
        ...state,
        showDeleteModal: action.showDeleteModal,
      };
    case 'showDeleteAllTranslationsModalChanged':
      return {
        ...state,
        showDeleteAllTranslationsModal: action.showDeleteAllTranslationsModal,
      };
    case 'editorClickFailed':
      return {
        ...state,
        error: action.error,
      };
    case 'jobStatusChanging':
      return {
        ...state,
        isChangingJobStatus: true,
      };
    case 'jobStatusChanged':
      return {
        ...state,
        isChangingJobStatus: false,
      };
    case 'jobStatusChangeFailed':
      return {
        ...state,
        isChangingJobStatus: false,
        error: action.error,
      };
    case 'openEditorWarnIncompletePreviousWorkflowStep':
      return {
        ...state,
        showIncompletePreviousWorkflowStep: true,
        retryOpenEditorHandler: action.retryHandler,
      };
    case 'openingEditor':
      return {
        ...state,
        showIncompletePreviousWorkflowStep: false,
        retryOpenEditorHandler: undefined,
      };
    case 'scoringJobsLoading':
      return {
        ...state,
        isLoadingScoringJobs: true,
        error: undefined,
      };
    case 'scoringJobsLoaded':
      return {
        ...state,
        isLoadingScoringJobs: false,
        scoringJobs: action.scoringJobs,
      };
    case 'scoringJobsLoadFailed':
      return {
        ...state,
        isLoadingScoringJobs: false,
        error: action.error,
      };
    default:
      return state;
  }
};

const init = (): MainState => {
  return {
    items: [],
    pages: 0,
    totalItems: 0,
    assignees: [],
    isLoadingStatusChanges: false,
    statusChanges: new Map(),
    backgroundTasks: new Map(),
    lastTasksLoaded: false,
    isLoadingSegmentMetadata: false,
    segmentMetadata: new Map(),
    pageSize: 50,
    selectedItems: [],
    selectAllOnServer: false,
    isLoading: false,
    isPretranslating: false,
    isDeleting: false,
    isDownloadingFile: false,
    queryLoaded: false,
    filteringQuery: {},
    nonFilteringQuery: { page: 0 },
    unmanagedQuery: {},
    showDeleteModal: false,
    showDeleteAllTranslationsModal: false,
    isChangingJobStatus: false,
    showIncompletePreviousWorkflowStep: false,
    scoringJobs: new Map(),
    isLoadingScoringJobs: false,
  };
};

export const JobList = withRouter(
  ({
    project,
    session,
    level,
    targetLocales,
    history,
    location,
    workflowSteps,
    canEdit,
    canCreateJobs,
    backgroundTasksPollInterval = 1000,
    workflowStep,
    scoringEnabled,
    onJobListRefreshed,
    canDownload = false,
  }: Props): ReactElement => {
    const { pathname, search } = location;
    const projectId = project.id;
    const projectUid = project.uid;

    // useReducer hook
    const [state, dispatch] = useReducer(reducer, null, init);

    // destructuring
    const {
      error,
      backgroundTasks,
      lastTasksLoaded,
      isLoadingSegmentMetadata,
      segmentMetadata,
      isLoadingStatusChanges,
      statusChanges,
      items,
      totalItems,
      selectedItems,
      selectAllOnServer,
      editorUrl,
      atmsServerUrl,
      assignees,
      isDownloadingFile,
      fileDownloadError,
      fileDownloadWarning,
      isPretranslating,
      isDeleting,
      queryLoaded,
      filteringQuery,
      nonFilteringQuery,
      showDeleteModal,
      showDeleteAllTranslationsModal,
      isChangingJobStatus,
      showIncompletePreviousWorkflowStep,
      retryOpenEditorHandler,
      isLoadingScoringJobs,
      scoringJobs,
    } = state;

    const { assignedTo, status, fileName, targetLocale, dueInHours } = filteringQuery;

    const { page, sortField, sortOrder } = nonFilteringQuery;

    // useCallback hooks
    const searchToState = useCallback((): [FilteringQueryApi, NonFilteringQueryApiBase, object] => {
      const {
        sortField,
        sortOrder,
        assignedTo,
        status,
        fileName,
        targetLocale,
        dueInHours,
        page,
        ...unmanagedQuery
      } = parse(search, { parseNumbers: true });
      return [
        {
          assignedTo: typeof assignedTo === 'number' ? [assignedTo] : (assignedTo as number[]),
          status: typeof status === 'string' ? [status] : (status as string[]),
          fileName: typeof fileName === 'string' ? [fileName] : (fileName as string[]),
          targetLocale:
            typeof targetLocale === 'string' ? [targetLocale] : (targetLocale as string[]),
          dueInHours: typeof dueInHours === 'number' ? [dueInHours] : (dueInHours as number[]),
        },
        {
          page: (page as number) || 0,
          sortField: sortField as string,
          sortOrder: sortOrder as string,
        },
        unmanagedQuery,
      ];
    }, [search]);

    const loadScoringJobsIfNeeded = useCallback(
      async (jobParts: Job[]): Promise<void> => {
        // Map the ids that correspond to the scoring job part ids so we can tie them back to
        // the list of job parts we have (which can be different for child job parts, which point
        // to root job parts, that the scoring job is connected to.
        const jobPartRootIdToId: Map<number, number> = jobParts.reduce(
          (acc, curr: any) => acc.set(curr.rootId, curr.id),
          new Map()
        );

        if (
          jobPartRootIdToId.size === 0 ||
          !(workflowStep && isScoringWorkflow(workflowStep.type))
        ) {
          return;
        }
        const query = {
          jobPart: Array.from(jobPartRootIdToId.values()),
          group: buildWorkflowGroupId(projectId, workflowStep.id),
        };

        dispatch({
          type: 'scoringJobsLoading',
        });

        try {
          const response = await AtmsApiClient.httpGet(
            `/api/scoring/getGroupScoringJobs?${stringify(query)}`
          );
          dispatch({
            type: 'scoringJobsLoaded',
            scoringJobs: response.reduce(
              (acc, curr: ScoringJob) =>
                acc.set(jobPartRootIdToId.get(getJobPartId(curr.id)), curr),
              new Map()
            ),
          });
        } catch (e) {
          publishCountMetric('scoringJobsLoadFailed-JobList', 'error', e.message);
          dispatch({ type: 'scoringJobsLoadFailed', error: apiErrorToErrorMessage(e) });
        }
      },
      [projectId, workflowStep]
    );

    const loadJobs = useCallback(async (): Promise<void> => {
      dispatch({
        type: 'jobsLoading',
      });

      const query = {
        projectUid,
        level,
        page,
        assignedTo,
        status,
        fileName,
        targetLocale,
        dueInHours,
        sortField,
        sortOrder,
      };

      let jobs: Job[] = [];
      try {
        const response = await AtmsApiClient.httpGet(`/api/job/listByProject?${stringify(query)}`);
        dispatch({
          type: 'jobsLoaded',
          jobs: response.jobs,
          backgroundTasks: response.jobs.reduce(
            (acc, curr) =>
              acc.set(curr.id, {
                creationTask: curr.creationTask,
                // lastTask is no longer returned by the job/listByProject API to save an expensive lazy join
                jobPartId: curr.id,
              }),
            new Map()
          ),
          totalJobs: response.totalJobs,
          pages: response.pages,
          editorUrl: response.editorUrl,
          atmsServerUrl: response.atmsServerUrl,
        });
        jobs = response.jobs;
      } catch (e) {
        publishCountMetric('jobsLoadFailed-JobList', 'error', e.message);
        dispatch({
          type: 'jobsLoadFailed',
          error: I18n.t('Failed to load jobs: %{err}', { err: apiErrorToErrorMessage(e) }),
        });
      }
      loadScoringJobsIfNeeded(jobs);
    }, [
      loadScoringJobsIfNeeded,
      projectUid,
      level,
      page,
      assignedTo,
      status,
      fileName,
      targetLocale,
      dueInHours,
      sortField,
      sortOrder,
    ]);

    const loadAssignees = useCallback(async (): Promise<void> => {
      dispatch({
        type: 'assigneesLoading',
      });
      const query = {
        project: projectUid,
        workflowLevel: level,
      };

      try {
        const response = await AtmsApiClient.httpGet(
          `//${WEB_HOST_AND_PORT}/web/api/v9/user/listByProjectAssignment?${stringify(query)}`,
          []
        );
        dispatch({
          type: 'assigneesLoaded',
          assignees: response,
        });
      } catch (e) {
        publishCountMetric(
          'failedToLoadLinguistsForFiltering-JobList',
          'error',
          'Failed to load linguists for filtering'
        );
        dispatch({
          type: 'assigneesLoadFailed',
          error: 'Failed to load linguists for filtering',
        });
      }
    }, [projectUid, level]);

    const loadBackgroundTasks = useCallback(async (): Promise<void> => {
      const unresolvedBackgroundTasks: JobBackgroundTasks[] = [];
      for (const backgroundTask of backgroundTasks.values()) {
        if (
          backgroundTask.creationTask?.status === 'RUNNING' ||
          !lastTasksLoaded ||
          backgroundTask.lastTask?.status === 'RUNNING'
        ) {
          unresolvedBackgroundTasks.push(backgroundTask);
        }
      }

      if (unresolvedBackgroundTasks.length === 0) {
        return;
      }

      const query = {
        jobPart: unresolvedBackgroundTasks.map(bt => bt.jobPartId),
      };

      try {
        const response = await AtmsApiClient.httpGet(
          `//${WEB_HOST_AND_PORT}/web/api/v9/job/getBackgroundTasksBulk?${stringify(query)}`
        );
        dispatch({
          type: 'backgroundTasksLoaded',
          backgroundTasks: response.reduce(
            (a, currentValue) =>
              a.set(currentValue.jobPart.id, {
                creationTask: currentValue.creationTask,
                lastTask: currentValue.lastTask,
                jobPartId: currentValue.jobPart.id,
              }),
            new Map(backgroundTasks)
          ),
        });
      } catch (e) {
        publishCountMetric('backgroundTasksLoadFailed-JobList', 'error', e.message);
        dispatch({
          type: 'backgroundTasksLoadFailed',
          error: I18n.t('Failed to load background task statuses: %{err}', {
            err: apiErrorToErrorMessage(e),
          }),
        });
      }
    }, [backgroundTasks, lastTasksLoaded]);

    const loadSegmentMetadata = useCallback(async (): Promise<void> => {
      if (items.length === 0) {
        return;
      }
      dispatch({
        type: 'segmentMetadataLoading',
      });

      const query = {
        jobPart: items
          .filter(j => backgroundTasks.get(j.id)?.creationTask?.status === 'OK')
          .map(j => j.id),
      };

      if (query.jobPart.length === 0) {
        return;
      }

      try {
        const response = await AtmsApiClient.httpGet(
          `//${WEB_HOST_AND_PORT}/web/api/v9/job/getSegmentsCount?${stringify(query)}`
        );
        dispatch({
          type: 'segmentMetadataLoaded',
          segmentMetadata: response.reduce(
            (a, currentValue) => a.set(currentValue.jobPartId, currentValue),
            new Map()
          ),
        });
      } catch (e) {
        publishCountMetric('segmentMetadataLoadFailed-JobList', 'error', e.message);
        dispatch({
          type: 'segmentMetadataLoadFailed',
          error: 'Failed to load segment metadata',
        });
      }
    }, [items]);

    const loadStatusChanges = useCallback(async (): Promise<void> => {
      if (items.length === 0) {
        return;
      }

      const query = {
        jobPart: items
          .filter(j => backgroundTasks.get(j.id)?.creationTask?.status === 'OK')
          .map(j => j.id),
      };

      if (query.jobPart.length === 0) {
        return;
      }

      try {
        const response = await AtmsApiClient.httpGet(
          `//${WEB_HOST_AND_PORT}/web/api/v9/job/getStatusChangesBulk?${stringify(query)}`
        );
        dispatch({
          type: 'statusChangesLoaded',
          statusChanges: response.reduce(
            (a, currentValue) => a.set(currentValue.jobPartId, currentValue.statusChanges),
            new Map()
          ),
        });
      } catch (e) {
        publishCountMetric('statusChangesLoadFailed-JobList', 'error', e.message);
        dispatch({
          type: 'statusChangesLoadFailed',
          error: apiErrorToErrorMessage(e),
        });
      }
    }, [items]);

    // useEffect hooks
    useEffect(() => {
      const [filteringQuery, nonFilteringQuery, unmanagedQuery] = searchToState();
      dispatch({ type: 'searchChanged', filteringQuery, nonFilteringQuery, unmanagedQuery });
    }, [searchToState]);

    useEffect(() => {
      if (queryLoaded) {
        loadJobs();
      }
    }, [loadJobs, queryLoaded]);

    useEffect(() => {
      if (canEdit) {
        loadAssignees();
      }
    }, [loadAssignees]);

    useEffect(() => {
      const timeout = setTimeout((): void => {
        loadBackgroundTasks();
        return;
      }, backgroundTasksPollInterval);
      return (): void => clearTimeout(timeout);
    }, [loadBackgroundTasks, backgroundTasksPollInterval]);

    useEffect(() => {
      loadSegmentMetadata();
    }, [loadSegmentMetadata]);

    useEffect(() => {
      loadStatusChanges();
    }, [loadStatusChanges]);

    // handlers
    const handleEditClick = (): void => {
      let query: any = {
        id: projectUid,
        jobSelection: selectedItems.map(j => j.id),
        returnUrl: `${pathname}${search}`,
      };

      if (selectAllOnServer) {
        // The *presence* of the jobSelectionAll parameter is enough to trigger the selectAllOnServer behavior
        query = {
          ...query,
          jobSelectionAll: true,
          jobSelectionTotal: totalItems,
          'jobFilter.level': level,
          'jobFilter.fileName': fileName,
          'jobFilter.targetLang': targetLocale,
          'jobFilter.assignedTo.id': assignedTo,
          'jobFilter.status': status,
          'jobFilter.dateDue': dueInHours,
        };
      }

      history.push(`/web/project2/editJobs?${stringify(query)}`);
    };

    const handleChangeStatusClick = (id): void => {
      dispatch({ type: 'jobStatusChanging' });

      let newStatus;
      switch (id) {
        case 'change-status-assigned':
          newStatus = 'ASSIGNED';
          break;
        case 'change-status-declined':
          newStatus = 'DECLINED_BY_LINGUIST';
          break;
        case 'change-status-completed':
          newStatus = 'COMPLETED_BY_LINGUIST';
          break;
      }

      const request = {
        projectUid: projectUid,
        jobPartIds: selectedItems.map(j => j.id),
        newStatus: newStatus,
        selectAllOnServer: selectAllOnServer,
        level: level,
        assignedTo: assignedTo,
        status: status,
        fileName: fileName,
        targetLocale: targetLocale,
        dueInHours: dueInHours,
      };

      AtmsApiClient.httpPost('/api/job/changeStatus', request)
        .then(() => {
          dispatch({ type: 'jobStatusChanged' });
          loadJobs();
        })
        .catch(err => {
          const errorMessage = I18n.t('Failed to set job status: %{errorDetail}', {
            errorDetail: apiErrorToErrorMessage(err),
          });
          publishCountMetric('jobStatusChangeFailed-JobList', 'error', err.message);
          dispatch({ type: 'jobStatusChangeFailed', error: errorMessage });
        });
    };

    const handleDownloadClick = async (id: string, force = false): Promise<void> => {
      dispatch({
        type: 'fileDownloading',
      });

      let formData: any = {
        jobPart: selectedItems.map(j => j.id),
      };

      try {
        if (selectAllOnServer) {
          const filters = {
            projectUid,
            level,
            assignedTo,
            status,
            fileName,
            targetLocale,
            dueInHours,
          };

          formData.jobPart = await AtmsApiClient.httpGet(
            `//${ATMS_BFF_HOST_AND_PORT}/api/job/listAllIdsByProject?${stringify(filters)}`
          );
        }

        let url;
        switch (id) {
          case 'download-original':
            url = `//${WEB_HOST_AND_PORT}/web/api/v9/job/getOriginalFiles`;
            break;
          case 'download-mxliff':
            url = `//${WEB_HOST_AND_PORT}/web/api/v9/job/getBilingualFile`;
            formData = {
              ...formData,
              getBilingual: true,
              format: 'MXLF',
              sortByInternalId: true,
            };
            break;
          case 'download-bilingual-docx':
            url = `//${WEB_HOST_AND_PORT}/web/api/v9/job/getBilingualFile`;
            formData = {
              ...formData,
              getBilingual: true,
              format: 'DOCX',
              sortByInternalId: true,
            };
            break;
          case 'download-bilingual-tmx':
            url = `//${WEB_HOST_AND_PORT}/web/api/v9/job/getBilingualFile`;
            formData = {
              ...formData,
              getBilingual: true,
              format: 'TMX',
              sortByInternalId: true,
            };
            break;
          case 'download-mxliff-zip':
            url = `//${WEB_HOST_AND_PORT}/web/api/v9/job/getBilingualFile`;
            formData = {
              ...formData,
              getBilingual: true,
              format: 'MXLF',
              mode: 'ZIP',
            };
            break;
          case 'download-bilingual-docx-zip':
            url = `//${WEB_HOST_AND_PORT}/web/api/v9/job/getBilingualFile`;
            formData = {
              ...formData,
              getBilingual: true,
              format: 'DOCX',
              mode: 'ZIP',
            };
            break;
          case 'download-bilingual-tmx-zip':
            url = `//${WEB_HOST_AND_PORT}/web/api/v9/job/getBilingualFile`;
            formData = {
              ...formData,
              getBilingual: true,
              format: 'TMX',
              mode: 'ZIP',
            };
            break;
          case 'download-completed':
            url = `//${WEB_HOST_AND_PORT}/web/api/v9/job/getCompletedFiles`;
            formData = {
              ...formData,
              failOnWarnings: !force,
              level: level,
              includeApiResultHeader: false,
            };
            break;
          case 'download-scorecard-csv':
            url = `/api/scoring/downloadScoreCard`;
            formData = {
              ...formData,
              format: 'CSV',
            };
            break;
          case 'download-scorecard-xlsx':
            url = `/api/scoring/downloadScoreCard`;
            formData = {
              ...formData,
              format: 'XLSX',
            };
            break;
        }

        await AtmsApiClient.download(url, {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: stringify(formData),
        });
        dispatch({
          type: 'fileDownloaded',
        });
      } catch (e) {
        // Three possible error returns
        // 1. error description and errors -> Error
        // 2. error description and warnings -> Warning
        // 3. error description and no errors or warnings -> Error

        const errorMessage = apiErrorToErrorMessage(e);
        const hasWarnings = e.details?.warnings?.length > 0;
        const hasErrors = e.details?.errors?.length > 0 || (errorMessage != null && !hasWarnings);

        publishCountMetric('fileDownloadFailed-JobList', 'error', e.message);

        dispatch({
          type: 'fileDownloadFailed',
          fileDownloadError:
            hasErrors &&
            I18n.t(
              {
                one: 'Failed to download file: %{err}',
                other: 'Failed to download the files: %{err}',
              },
              {
                count: selectedItems?.length,
                err: errorMessage,
              }
            ),
          fileDownloadWarning:
            hasWarnings &&
            I18n.t(
              {
                one: 'The file has download warnings: %{err}',
                other: 'The files have download warnings: %{err}',
              },
              { count: selectedItems?.length, err: errorMessage }
            ),
        });
      }
    };

    const handleCopySourceClick = async (): Promise<void> => {
      dispatch({
        type: 'isPretranslatingChanged',
        isPretranslating: true,
      });

      const request = {
        projectUid,
        jobPartIds: selectedItems.map(j => j.id),
        selectAllOnServer,
        level,
        assignedTo,
        status,
        fileName,
        targetLocale,
        dueInHours,
      };

      try {
        await AtmsApiClient.httpPost('/api/job/copySourceToTarget', request);
        loadJobs();
      } catch (e) {
        dispatch({ type: 'sourceToTargetCopyFailed', error: apiErrorToErrorMessage(e) });
      } finally {
        dispatch({
          type: 'isPretranslatingChanged',
          isPretranslating: false,
        });
      }
    };

    const handleDeleteAllTranslationsClick = (): void => {
      dispatch({
        type: 'showDeleteAllTranslationsModalChanged',
        showDeleteAllTranslationsModal: true,
      });
    };

    const handleDeleteAllTranslationsModalCancelButtonClick = (): void => {
      dispatch({
        type: 'showDeleteAllTranslationsModalChanged',
        showDeleteAllTranslationsModal: false,
      });
    };

    const handleDeleteAllTranslationsModalOkButtonClick = async (): Promise<void> => {
      dispatch({
        type: 'isPretranslatingChanged',
        isPretranslating: true,
      });
      dispatch({
        type: 'showDeleteAllTranslationsModalChanged',
        showDeleteAllTranslationsModal: false,
      });

      const request = {
        projectUid,
        jobPartIds: selectedItems.map(j => j.id),
        selectAllOnServer,
        level,
        assignedTo,
        status,
        fileName,
        targetLocale,
        dueInHours,
      };

      try {
        await AtmsApiClient.httpPost('/api/job/deleteAllTranslations', request);
        loadJobs();
      } catch (e) {
        publishCountMetric('translationsDeleteFailed-JobList', 'error', e.message);
        dispatch({
          type: 'translationsDeleteFailed',
          error: apiErrorToErrorMessage(e),
        });
      } finally {
        dispatch({
          type: 'isPretranslatingChanged',
          isPretranslating: false,
        });
      }
    };

    const handleRedirectToPseudoTranslateWorkflow = (): void => {
      const state = {
        jobPartIds: selectedItems.map(j => j.id),
        selectAllOnServer,
        level,
        assignedTo,
        status,
        fileName,
        targetLocale,
        dueInHours,
      };

      history.push(`/web/pseudoTranslate/${projectUid}`, state);
    };

    const handleRedirectToPretranslateWorkflow = (): void => {
      const state = {
        jobPartIds: selectedItems.map(j => j.id),
        selectAllOnServer,
        level,
        assignedTo,
        status,
        fileName,
        targetLocale,
        dueInHours,
      };

      history.push(`/web/pretranslate/${projectUid}`, state);
    };

    const handlePretranslateClick = (e: CustomDetailEvent<ButtonDropdown.ItemClick>): void => {
      switch (e.detail.id) {
        case 'pre-translate':
          handleRedirectToPretranslateWorkflow();
          break;
        case 'copy-source':
          handleCopySourceClick();
          break;
        case 'pseudo-translate':
          handleRedirectToPseudoTranslateWorkflow();
          break;
        case 'delete-translations':
          handleDeleteAllTranslationsClick();
          break;
      }
    };

    const handleRedirectToUploadBilingualFileWorkflow = (): void => {
      history.push(`/web/uploadBilingualFile/${projectUid}`);
    };

    const handleRedirectToEmailJobsWorkflow = (): void => {
      let assignees: User[] = [];
      selectedItems.forEach(j => {
        assignees = assignees.concat(j.assignees);
      });
      const state: EmailJobsWorkflowState = {
        jobPartIds: selectedItems.map(j => j.id),
        emailTo: [...new Set(assignees.map(u => displayUser(u)))].join('; '),
        selectAllOnServer,
        level: level,
        assignedTo: assignedTo,
        status: status,
        fileName: fileName,
        targetLocale: targetLocale,
        dueInHours: dueInHours,
      };

      history.push(`/web/emailJobsWorkflow/${projectUid}`, state);
    };

    const handleRedirectToSplitFileWorkflow = (): void => {
      const state: SelectedJobPartApi = {
        jobPartId: selectedItems.map(j => j.id)[0],
      };

      history.push(`/web/splitFileWorkflow/${projectUid}`, state);
    };

    const handleRedirectToExtractWorkflowChanges = (): void => {
      let query: any = {
        jobSelection: selectedItems.map(j => j.id),
      };

      if (selectAllOnServer) {
        // The *presence* of the jobSelectionAll parameter is enough to trigger the selectAllOnServer behavior
        query = {
          ...query,
          jobSelectionAll: true,
          'jobFilter.fileName': fileName,
          'jobFilter.targetLang': targetLocale,
          'jobFilter.assignedTo.id': assignedTo,
          'jobFilter.status': status,
          'jobFilter.dateDue': dueInHours,
        };
      }
      window.open(
        `//${WEB_HOST_AND_PORT}/web/project2/getWorkflowChanges?${stringify(query)}`,
        '_blank'
      );
    };

    const handleRedirectToImportScoreCardWorkflow = (): void => {
      const state: SelectedJobPartApi = {
        jobPartId: selectedItems.map(j => j.id)[0],
      };
      history.push(`/web/importScoreCardWorkflow/${projectUid}`, state);
    };

    const handleRedirectToCreateTicket = (): void => {
      const query = {
        projectName: project.name,
        projectId: projectId,
        jobPartId: selectedItems.map(j => j.id),
        sourceLocale: project.sourceLocale,
        targetLocale: project.targetLocales,
        dueDate: selectedItems.map(j => j.dateDue),
        workflowStep: selectedItems.map(j => j.workflowStep?.name),
      };

      removeEmptyOrNull(query);
      window.location.assign(`/web/tickets/list/createTicket?${stringify(query)}`);
    };

    const removeEmptyOrNull = (obj): void => {
      Object.keys(obj).forEach(
        k =>
          (obj[k] && typeof obj[k] === 'object' && removeEmptyOrNull(obj[k])) ||
          (obj[k] == null && delete obj[k])
      );
    };

    const handleActionsClick = (e: CustomDetailEvent<ButtonDropdown.ItemClick>): void => {
      switch (e.detail.id) {
        case 'actions-email':
          handleRedirectToEmailJobsWorkflow();
          break;
        case 'actions-split-file':
          handleRedirectToSplitFileWorkflow();
          break;
        case 'actions-extract-workflow-changes':
          handleRedirectToExtractWorkflowChanges();
          break;
        case 'actions-upload':
          handleRedirectToUploadBilingualFileWorkflow();
          break;
        case 'actions-analyze':
          handleAnalyzeClick();
          break;
        case 'actions-delete':
          handleDeleteButtonClick();
          break;
        case 'actions-import-scorecard':
          handleRedirectToImportScoreCardWorkflow();
          break;
        case 'actions-create-ticket':
          handleRedirectToCreateTicket();
      }
    };

    const handleAnalyzeClick = (): void => {
      const state: CreateAnalysisWorkflowLocationState = {
        jobPartIds: selectedItems.map(j => j.id),
        selectAllOnServer,
        level,
        assignedTo,
        status,
        fileName,
        targetLocale,
        dueInHours,
        workflowSteps,
      };

      history.push(`/web/analysis/create/${projectUid}`, state);
    };

    const handleDeleteButtonClick = (): void => {
      dispatch({
        type: 'showDeleteModalChanged',
        showDeleteModal: true,
      });
    };

    const handleDeleteModalCancelButtonClick = (): void => {
      dispatch({
        type: 'showDeleteModalChanged',
        showDeleteModal: false,
      });
    };

    const handleDeleteModalOkButtonClick = async (): Promise<void> => {
      dispatch({
        type: 'isDeletingChanged',
        isDeleting: true,
      });
      dispatch({
        type: 'showDeleteModalChanged',
        showDeleteModal: false,
      });

      const query = {
        projectUid,
        jobPartIds: selectedItems.map(j => j.id),
        selectAllOnServer,
        level,
        assignedTo,
        status,
        fileName,
        targetLocale,
        dueInHours,
      };

      try {
        await AtmsApiClient.httpDelete('/api/job', query);
        loadJobs();
        if (onJobListRefreshed != null) {
          onJobListRefreshed();
        }
      } catch (e) {
        publishCountMetric('jobsDeleteFailed-JobList', 'error', e.message);
        dispatch({
          type: 'jobsDeleteFailed',
          error: apiErrorToErrorMessage(e),
        });
      } finally {
        dispatch({
          type: 'isDeletingChanged',
          isDeleting: false,
        });
      }
    };

    const doJobsIncludeImportErrors = (jobs: Job[]): boolean =>
      jobs.some(j => {
        return backgroundTasks?.get(j.id)?.creationTask?.status !== 'OK';
      });

    const doesSelectionIncludeImportErrors = doJobsIncludeImportErrors(selectedItems);

    const prepHandleEditorLinkClick = (
      job: Job,
      force = false
    ): MouseEventHandler<HTMLAnchorElement> => {
      const handler = (): void => {
        // Emulate the current functionality of opening all of the jobs that are selected if one of them is clicked
        let jobsToOpen: Job[] = [...selectedItems];
        const jobIsSelected = jobsToOpen.some(j => j.id === job.id);

        if (!jobIsSelected) {
          jobsToOpen = [job];
        } else if (new Set(selectedItems.map(j => j.targetLocale)).size > 1) {
          //Assert all selected jobs have the same target locale
          publishCountMetric(
            'editorClickFailed-JobList',
            'error',
            'Failed to open editor: Only files with identical target languages can be joined'
          );
          dispatch({
            type: 'editorClickFailed',
            error: I18n.t(
              'Failed to open editor: Only files with identical target languages can be joined'
            ),
          });
          return;
        }

        if (doJobsIncludeImportErrors(jobsToOpen)) {
          publishCountMetric(
            'failedToOpenEditor-fileFailedImport-JobList',
            'error',
            'Failed to open editor: One or more selected files failed to import'
          );
          dispatch({
            type: 'editorClickFailed',
            error: I18n.t('Failed to open editor: One or more selected files failed to import'),
          });
          return;
        }

        if (jobsToOpen.some(job => !canOpenJob(session.user, job, project))) {
          publishCountMetric(
            'failedToOpenEditor-jobCompletedOrCanceled-JobList',
            'error',
            'Failed to open editor: One or more selected jobs are completed or canceled'
          );
          dispatch({
            type: 'editorClickFailed',
            error: I18n.t(
              'Failed to open editor: One or more selected jobs are completed or canceled'
            ),
          });
          return;
        }

        if (
          !canEdit &&
          !force &&
          jobsToOpen.some(j => {
            const jobSegmentMetadata = segmentMetadata?.get(j.id);
            return (
              !jobSegmentMetadata ||
              (jobSegmentMetadata.previousWorkflow &&
                !jobSegmentMetadata.previousWorkflow.completed)
            );
          })
        ) {
          dispatch({
            type: 'openEditorWarnIncompletePreviousWorkflowStep',
            retryHandler: prepHandleEditorLinkClick(job, true),
          });
          return;
        }

        dispatch({
          type: 'openingEditor',
        });

        window.open(
          `${editorUrl}/twe/translation/job/${jobsToOpen
            .map(j => j.id)
            .sort()
            .join('-')}?atmsServerUrl=${atmsServerUrl}`,
          '_blank'
        );
      };
      return handler;
    };

    const editorLink = (job: Job, backgroundTask?: JobBackgroundTasks): ReactNode => {
      if (
        backgroundTask?.creationTask?.status !== 'OK' ||
        !canOpenJob(session.user, job, project)
      ) {
        return job.fileName;
      }

      return (
        <EditorLink onClick={prepHandleEditorLinkClick(job, false)}>{job.fileName}</EditorLink>
      );
    };

    // columns and filtering
    const columnMapDefault: Map<string, TableColumnDef<Job>> = new Map([
      [
        'id',
        {
          id: 'id',
          header: '#',
          cell: (item): CellContents => <span id={'jobPart-' + item.id}>{item.internalId}</span>,
          minWidth: '80px',
          width: 80,
          canHide: false,
          canReorder: false,
        },
      ],
      [
        'taskStatus',
        {
          id: 'taskStatus',
          header: '',
          cell: (item): CellContents => {
            const jobBackgroundTasks = backgroundTasks.get(item.id);
            return taskStatus(jobBackgroundTasks?.lastTask ?? jobBackgroundTasks?.creationTask);
          },
          minWidth: '50px',
          width: 50,
          canHide: false,
          canReorder: false,
        },
      ],
      [
        'confirmed',
        {
          id: 'confirmed',
          header: I18n.t('Progress'),
          cell: (item): CellContents =>
            completionStatus(isLoadingSegmentMetadata, segmentMetadata.get(item.id)),
          minWidth: '100px',
          width: 100,
        },
      ],
      [
        'wordCount',
        {
          id: 'wordCount',
          header: I18n.t('Words'),
          cell: (item): CellContents =>
            wordCount(isLoadingSegmentMetadata, segmentMetadata.get(item.id)),
          minWidth: '85px',
          width: 85,
          visible: false,
        },
      ],
      [
        'qa',
        {
          id: 'qa',
          header: I18n.t('QA'),
          cell: (item): CellContents =>
            qaStatus(isLoadingSegmentMetadata, segmentMetadata.get(item.id)),
          minWidth: '65px',
          width: 65,
        },
      ],
      [
        'score',
        {
          id: 'score',
          header: I18n.t('Score'),
          cell: (item): CellContents =>
            scoreStatus(
              workflowStep?.type,
              isLoadingScoringJobs,
              (workflowStep?.id ?? -1) in project.scoringModelIdsByWorkflowStepId,
              scoringJobs.get(item.id)
            ),
          minWidth: '135px',
          width: 135,
        },
      ],
      [
        'file',
        {
          id: 'file',
          header: I18n.t('File'),
          cell: (item): CellContents => editorLink(item, backgroundTasks?.get(item.id)),
          minWidth: '120px',
          width: 200,
          canHide: false,
        },
      ],
      [
        'status',
        {
          id: 'status',
          header: I18n.t('Status'),
          cell: (item): CellContents =>
            statusWithChanges(isLoadingStatusChanges, item.status, statusChanges?.get(item.id)),
          minWidth: '100px',
          width: 100,
          canSort: true,
        },
      ],
      [
        'target',
        {
          id: 'target',
          header: I18n.t('Target'),
          cell: (item): CellContents => item.targetLocale && formatLocaleCode(item.targetLocale),
          minWidth: '100px',
          width: 100,
          canSort: true,
        },
      ],
      [
        'linguist',
        {
          id: 'linguist',
          header: I18n.t('Linguist'),
          cell: (item): CellContents =>
            item.assignees?.length
              ? item.assignees.map(a => displayUserWithLoginLink(a, session))
              : '-',
        },
      ],
      [
        'due',
        {
          id: 'due',
          header: I18n.t('Due'),
          cell: (item): CellContents =>
            displayTimestamp(item.dateDue, true, session?.user?.timezone) ?? '-',
        },
      ],
      [
        'created',
        {
          id: 'created',
          header: I18n.t('Created'),
          cell: (item): CellContents =>
            displayTimestamp(item.dateCreated, true, session?.user?.timezone) ?? '-',
        },
      ],
      [
        'uid',
        {
          id: 'uid',
          header: I18n.t('UID'),
          cell: (item): CellContents => item.uid,
          minWidth: '80px',
          width: 80,
          visible: false,
        },
      ],
    ]);

    const columnOrderDefault = [
      'id',
      'taskStatus',
      'confirmed',
      'wordCount',
      'qa',
      'score',
      'file',
      'status',
      'target',
      'linguist',
      'created',
      'due',
      'uid',
    ];

    const filteringMapDefault: Map<string, TablePropertyFilteringOption> = new Map([
      [
        'fileName',
        {
          propertyKey: 'fileName',
          groupValuesLabel: I18n.t('File values'),
          propertyLabel: I18n.t('File'),
          values: [],
        },
      ],
      [
        'status',
        {
          propertyKey: 'status',
          groupValuesLabel: I18n.t('Status values'),
          propertyLabel: I18n.t('Status'),
          values: Object.keys(displayToStatus),
          filterByMultiple: true,
        },
      ],
      [
        'targetLocale',
        {
          propertyKey: 'targetLocale',
          groupValuesLabel: I18n.t('Target values'),
          propertyLabel: I18n.t('Target'),
          values: targetLocales?.map(locale => formatLocaleCode(locale)) ?? [],
        },
      ],
      [
        'assignedTo',
        {
          propertyKey: 'assignedTo',
          groupValuesLabel: I18n.t('Linguist values'),
          propertyLabel: I18n.t('Linguist'),
          values: assignees.map(displayUser),
        },
      ],
      [
        'dueInHours',
        {
          propertyKey: 'dueInHours',
          groupValuesLabel: I18n.t('Due in values (hours)'),
          propertyLabel: I18n.t('Due in (hours)'),
          // These are the preset values provided by the old UI, but users can enter free-form values as well
          values: ['0', '4', '8', '24', '36'],
        },
      ],
    ]);

    const filteringOrderDefault: string[] = [
      'fileName',
      'status',
      'targetLocale',
      'assignedTo',
      'dueInHours',
    ];

    const transformFilterValue = (key: string, value): any => {
      if (key === 'assignedTo') {
        const user = assignees.find(u => displayUser(u) === value);
        return user ? '' + user.id : value;
      } else if (key === 'status') {
        return displayToStatus[value];
      }
      return value;
    };

    const untransformFilterValue = (key: string, value): any => {
      if (key === 'assignedTo') {
        const user = assignees.find(u => '' + u.id === '' + value);
        return user ? '' + displayUser(user) : value;
      } else if (key === 'status') {
        return statusToDisplay[value];
      }
      return value;
    };

    return (
      <Fragment>
        {error && <Alert type="error" id="general-error" content={error} />}
        <Container withGutters={false}>
          {fileDownloadError && (
            <Alert
              id="file-download-error"
              type="error"
              content={fileDownloadError}
              dismissible={true}
            />
          )}
          {fileDownloadWarning && !fileDownloadError && (
            <Alert
              id="file-download-warning"
              type="error"
              content={fileDownloadWarning}
              buttonText={I18n.t('Download anyway')}
              onButtonClick={(): void => {
                handleDownloadClick('download-completed', true);
                return;
              }}
              dismissible={true}
            />
          )}
          {showIncompletePreviousWorkflowStep && (
            <Alert
              id="edit-incomplete-prev-workflow-warning"
              type="warning"
              content={I18n.t(
                'The selected file(s) are not completed in the previous workflow step'
              )}
              buttonText={I18n.t('Open anyway')}
              onButtonClick={retryOpenEditorHandler}
              dismissible={true}
            />
          )}
          {!canEdit && selectedItems.some(j => j.hasOffer) && (
            <Alert
              id="disable-change-status-info"
              type="info"
              content={I18n.t(
                'The selected file(s) contains an offer and needs to be claimed through Translator Portal'
              )}
              dismissible={true}
            />
          )}
          <AtmsTable<FilteringQueryApi, NonFilteringQueryApiBase, MainState>
            atmsTableId="jobList"
            history={history}
            location={location}
            features={['propertyFiltering', 'pagination', 'selection', 'sorting']}
            header={
              <PageHeader
                tag="h2"
                title={I18n.t('Jobs')}
                extraContent={<HelpInfoLink helpId={PROJECT_DETAIL_MANAGE_JOBS_HELP} />}
                buttons={[
                  canEdit && {
                    id: 'job-actions',
                    text: I18n.t('Actions'),
                    onItemClick: handleActionsClick,
                    items: [
                      {
                        id: 'actions-email',
                        text: I18n.t('Email...'),
                        disabled: selectedItems.length === 0 || doesSelectionIncludeImportErrors,
                      },
                      {
                        id: 'actions-split-file',
                        text: I18n.t('Split file...'),
                        disabled:
                          selectedItems.length !== 1 ||
                          doesSelectionIncludeImportErrors ||
                          (workflowStep && isScoringWorkflow(workflowStep.type)),
                      },
                      {
                        id: 'actions-extract-workflow-changes',
                        text: I18n.t('Export workflow changes'),
                        disabled: selectedItems.length === 0 || doesSelectionIncludeImportErrors,
                      },
                      {
                        id: 'actions-upload',
                        text: I18n.t('Upload...'),
                      },
                      {
                        id: 'actions-analyze',
                        text: I18n.t('Analyze...'),
                        disabled: selectedItems.length === 0 || doesSelectionIncludeImportErrors,
                      },
                      {
                        id: 'delete-submenu',
                        text: '', // Adds a divider between the previous elements and 'delete'
                        items: [
                          {
                            id: 'actions-delete',
                            text: I18n.t('Delete'),
                            disabled: selectedItems.length === 0 || isDeleting,
                          },
                        ],
                      },
                      scoringEnabled && {
                        id: 'actions-import-scorecard',
                        text: I18n.t('Import Scorecard'),
                        disabled:
                          selectedItems.length !== 1 ||
                          !(workflowStep != null && isScoringWorkflow(workflowStep.type)),
                      },
                      {
                        id: 'actions-create-ticket',
                        text: I18n.t('Create ticket'),
                        disabled: selectedItems.length !== 1,
                      },
                    ].filter(v => v) as ButtonDropdown.Item[],
                  },
                  !canEdit && {
                    id: 'create-ticket',
                    text: I18n.t('Create ticket'),
                    onClick: handleRedirectToCreateTicket,
                    disabled: selectedItems.length !== 1,
                  },
                  !canEdit && {
                    id: 'upload',
                    text: I18n.t('Upload'),
                    onClick: handleRedirectToUploadBilingualFileWorkflow,
                  },
                  canEdit && {
                    id: 'pretranslate',
                    text: I18n.t('Pre-translate'),
                    onItemClick: handlePretranslateClick,
                    disabled:
                      selectedItems.length === 0 ||
                      isPretranslating ||
                      doesSelectionIncludeImportErrors,
                    loading: isPretranslating,
                    items: [
                      {
                        id: 'pre-translate',
                        text: I18n.t('Pre-translate where empty...'),
                      },
                      {
                        id: 'copy-source',
                        text: I18n.t('Copy source to target where empty'),
                      },
                      {
                        id: 'pseudo-translate',
                        text: I18n.t('Pseudo-translate where empty...'),
                      },
                      {
                        id: 'delete-translations',
                        text: I18n.t('Delete all translations'),
                      },
                    ],
                  },
                  {
                    id: 'download',
                    text: I18n.t('Download'),
                    onItemClick: (e): void => {
                      handleDownloadClick(e.detail.id);
                      return;
                    },
                    disabled:
                      selectedItems.length === 0 ||
                      isDownloadingFile ||
                      doesSelectionIncludeImportErrors ||
                      !canDownload,
                    loading: isDownloadingFile,
                    items: [
                      {
                        id: 'download-original',
                        text: I18n.t('Original file'),
                      },
                      {
                        id: 'download-mxliff',
                        text: I18n.t('Bilingual MXLIFF'),
                      },
                      {
                        id: 'download-bilingual-docx',
                        text: I18n.t('Bilingual DOCX'),
                      },
                      {
                        id: 'download-bilingual-tmx',
                        text: I18n.t('Bilingual TMX'),
                      },
                      {
                        id: 'download-mxliff-zip',
                        text: I18n.t('Bilingual MXLIFF as ZIP'),
                        disabled: selectedItems.length < 2,
                      },
                      {
                        id: 'download-bilingual-docx-zip',
                        text: I18n.t('Bilingual DOCX as ZIP'),
                        disabled: selectedItems.length < 2,
                      },
                      {
                        id: 'download-bilingual-tmx-zip',
                        text: I18n.t('Bilingual TMX as ZIP'),
                        disabled: selectedItems.length < 2,
                      },
                      {
                        id: 'download-completed',
                        text: I18n.t('Completed file'),
                      },
                      scoringEnabled && {
                        id: 'download-scorecard-csv',
                        text: I18n.t('Scorecard as CSV'),
                        disabled: !(workflowStep && isScoringWorkflow(workflowStep.type)),
                      },
                      scoringEnabled && {
                        id: 'download-scorecard-xlsx',
                        text: I18n.t('Scorecard as XLSX'),
                        disabled: !(workflowStep && isScoringWorkflow(workflowStep.type)),
                      },
                    ].filter(v => v) as ButtonDropdown.Item[],
                  },
                  canEdit && {
                    id: 'edit',
                    text: I18n.t('Edit'),
                    onClick: (): void => handleEditClick(),
                    disabled: selectedItems.length === 0 || doesSelectionIncludeImportErrors,
                  },
                  !canEdit && {
                    id: 'change-status',
                    text: I18n.t('Change status'),
                    onItemClick: (e): void => handleChangeStatusClick(e.detail.id),
                    disabled:
                      selectedItems.length === 0 ||
                      isChangingJobStatus ||
                      doesSelectionIncludeImportErrors ||
                      selectedItems.some(j => j.hasOffer),
                    loading: isChangingJobStatus,
                    items: [
                      {
                        id: 'change-status-assigned',
                        text: I18n.t('Accept'),
                        disabled: selectedItems.some(
                          j => !(j.status === 'NEW' || j.status === 'EMAILED')
                        ),
                      },
                      {
                        id: 'change-status-declined',
                        text: I18n.t('Decline'),
                        disabled: selectedItems.some(
                          j => !(j.status === 'NEW' || j.status === 'EMAILED')
                        ),
                      },
                      {
                        id: 'change-status-completed',
                        text: I18n.t('Complete'),
                        disabled: selectedItems.some(j => j.status !== 'ASSIGNED'),
                      },
                    ],
                  },
                  canEdit && {
                    id: 'create-job',
                    text: I18n.t('Create job'),
                    variant: 'primary',
                    href: `/web/job2/create/${projectUid}`,
                    disabled: !canCreateJobs,
                  },
                ]}
              />
            }
            mainState={state}
            mainDispatch={dispatch}
            columnMapDefault={columnMapDefault}
            columnOrderDefault={columnOrderDefault}
            filteringMapDefault={filteringMapDefault}
            filteringOrderDefault={filteringOrderDefault}
            filteringDefaultPropertyKey="fileName"
            transformFilterValue={transformFilterValue}
            untransformFilterValue={untransformFilterValue}
            filteringPlaceholder={I18n.t('Click to filter jobs by property values')}
            wrapLines={false}
            empty={I18n.t('No jobs found')}
            noMatch={I18n.t('No matching jobs found')}
            selectAllOnServer={{
              columnId: 'file',
              label: (
                <Fragment>
                  {I18n.t('Select all %{jobCount} jobs', { jobCount: totalItems })}
                </Fragment>
              ),
            }}
            isOpenEndedPagination={false}
            onChangeOpenEndedPagination={(): void => undefined}
          />
        </Container>

        <StandardModal
          id="jobListDeleteModal"
          header={I18n.t(
            {
              one: 'Delete job?',
              other: 'Delete jobs?',
            },
            {
              count: selectedItems.length,
            }
          )}
          content={I18n.t(
            {
              one: 'Do you want to delete %{jobName}?',
              other: 'Do you want to delete %{count} jobs?',
            },
            {
              count: selectAllOnServer ? totalItems : selectedItems.length,
              jobName: selectedItems.length > 0 && selectedItems[0].fileName,
            }
          )}
          okButtonId="jobListDeleteModalConfirm"
          visible={showDeleteModal}
          handleCancelClick={handleDeleteModalCancelButtonClick}
          handleOkClick={handleDeleteModalOkButtonClick}
        />

        <StandardModal
          id="jobListDeleteTranslationsModal"
          content={I18n.t(
            {
              one: 'Do you want to delete all translations for %{jobName}?',
              other: 'Do you want to delete all transations for %{count} jobs?',
            },
            {
              count: selectAllOnServer ? totalItems : selectedItems.length,
              jobName: selectedItems.length > 0 && selectedItems[0].fileName,
            }
          )}
          okButtonId="jobListDeleteTranslationsModalConfirm"
          header={I18n.t('Delete all translations?')}
          visible={showDeleteAllTranslationsModal}
          handleOkClick={handleDeleteAllTranslationsModalOkButtonClick}
          handleCancelClick={handleDeleteAllTranslationsModalCancelButtonClick}
        />
      </Fragment>
    );
  }
);
