import { useCallback, useRef } from 'react';
import { usePref } from '@amzn/et-console-components';

// cached filters ar scoped to the current userId, since you can potentially switch users
// (either via logout/login, or "log in as")
export default userId => {
  const [filterCache, setFilterCache] = usePref('userFilterCache', {});
  const ref = useRef();
  ref.current = { userId, filterCache };

  /**
   * Update the filter cache for a user
   *
   * @param {string} filters the filters to save for the current user
   */
  const updateFilterCache = useCallback(
    filters => {
      filterCache[userId] = filters;
      setFilterCache(filterCache);
    },
    [userId, filterCache, setFilterCache]
  );

  return [filterCache[userId] || '', updateFilterCache];
};
