import { Table, TablePropertyFiltering } from '@amzn/awsui-components-react/polaris';

// Custom filtering function to deal with arrays:
// It loops through the filtering tokens, and performs a case insensitive string match on the value
// in the item for the propertyKey specified by the token and applies boolean operation 'and' for each match.
// When the value is an array, if any of the array element matches, it will return true.
export const filteringFunction = (
  item: Table.Item,
  tokens: Array<TablePropertyFiltering.FilteringToken>,
  operation: string
): boolean => {
  const matchStategy = (
    token: TablePropertyFiltering.FilteringToken,
    item: Table.Item
  ): boolean => {
    const searchText = token.value.toLowerCase();
    const searchableProps = token.propertyKey ? [token.propertyKey] : Object.keys(item);
    return searchableProps.some(prop => {
      const itemText = Array.isArray(item[prop]) ? item[prop] : [item[prop]];
      return itemText.some(f => typeof f === 'string' && f.toLowerCase().includes(searchText));
    });
  };
  return (
    !tokens.length ||
    tokens.reduce((include: boolean, token: TablePropertyFiltering.FilteringToken) => {
      return include && matchStategy(token, item);
    }, operation === 'and')
  );
};
