import React, { ReactElement, useCallback, useLayoutEffect, useReducer } from 'react';
import {
  TranslationMemoryModel,
  ModalContentContainer,
  consolidateTransMemories,
  extractTransMemoriesFromResponse,
  TranslationMemorySelectionModel,
} from './TranslationMemoryCommon';
import {
  Modal,
  Button,
  Table,
  TablePropertyFiltering,
  TableSelection,
  Alert,
} from '@amzn/awsui-components-react/polaris';
import I18n from '../../../setupI18n';
import { HeaderProps, TableWithGroupByColumn } from '../../components/TableWithGroupByColumn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDoorClosed, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { CellContents } from '../../types/commonTypes';
import { AtmsApiClient } from '@amzn/et-console-components';
import { stringify } from 'query-string';
import { TargetLocalesPopup } from '../../components/TargetLocalesPopup';
import { filteringFunction } from '../../../shared/propertyFiltering';
import { publishCountMetric } from '../../metricHelper';
import { getAppHostConfig } from '@amzn/et-console-components';

const { WEB_HOST_AND_PORT } = getAppHostConfig();

export interface Props {
  onConfigure: (event: CustomEvent, selectedTMList: TranslationMemorySelectionModel[]) => void;
  onClose: (event: CustomEvent) => void;
  organizationName: string; // creator's organization name
  sourceLocale?: string;
  targetLocales?: string[];
  visible: boolean;
  isLoadingFromServer?: boolean;
  currConfiguredTransMemories?: TranslationMemoryModel[];
  uid: string | undefined;
}

interface ReducerState {
  isLoading: boolean;
  isError: boolean;
  error?: object;
  transMemorySelectionList: TranslationMemorySelectionModel[];
  filteringOptions: any[];
  tokens: TablePropertyFiltering.FilteringToken[];
}

export const TranslationMemorySelection = ({
  onConfigure,
  onClose,
  sourceLocale,
  targetLocales,
  visible,
  isLoadingFromServer,
  organizationName,
  currConfiguredTransMemories,
  uid,
}: Props): ReactElement => {
  /******************* Init state **********************/
  const initFilteringOptions = [
    {
      propertyKey: 'name',
      propertyLabel: 'name',
      groupValuesLabel: 'Name',
      values: [],
    },
    {
      propertyKey: 'supportedTargetLocales',
      propertyLabel: 'targetLang',
      groupValuesLabel: 'Target locale',
      values: targetLocales ?? [],
    },
    {
      propertyKey: 'client',
      propertyLabel: 'client',
      groupValuesLabel: 'Client',
      values: [],
    },
  ].filter(f => f);

  const init = (): ReducerState => ({
    isLoading: false,
    isError: false,
    transMemorySelectionList: [],
    filteringOptions: initFilteringOptions,
    tokens: [],
  });

  const reducer = (state: ReducerState, action: any): ReducerState => {
    switch (action.type) {
      case 'loadingTransMemorySelectionTable':
        return {
          ...state,
          isLoading: true,
          isError: false,
        };
      case 'loadedTransMemorySelectionTable':
        return {
          ...state,
          isLoading: false,
          transMemorySelectionList: action.transMemorySelectionList
            .filter(tm => !state.transMemorySelectionList.find(stm => stm.id === tm.id))
            .concat(state.transMemorySelectionList),
        };
      case 'loadTransMemorySelectionTableFailed':
        return {
          ...state,
          isLoading: false,
          isError: true,
          error: action.error,
        };
      case 'resetTablesAndFiltering':
        return {
          ...state,
          isLoading: false,
          isError: false,
          transMemorySelectionList: [],
          tokens: [],
          filteringOptions: initFilteringOptions,
        };
      case 'checkOrUncheckTranslationMemory':
        return {
          ...state,
          transMemorySelectionList: state.transMemorySelectionList.map(tm => {
            if (tm.id === action.transMemory.id) {
              return action.transMemory;
            }
            return tm;
          }),
        };
      case 'updateFilteringOptions':
        state.filteringOptions = initFilteringOptions;
        action.tokens.forEach(token => {
          const propertyKey = token.propertyKey;
          state.filteringOptions = state.filteringOptions.filter(
            f => f['propertyKey'] !== propertyKey
          );
          // if user enters free text, disable 'name' option
          if (propertyKey === null) {
            state.filteringOptions = state.filteringOptions.filter(
              f => f['propertyKey'] !== 'name'
            );
          }
        });
        return {
          ...state,
          filteringOptions: state.filteringOptions,
          tokens: action.tokens,
        };
      default:
        return state;
    }
  };

  const [reducerState, dispatch] = useReducer(reducer, null, init);
  const {
    isLoading,
    isError,
    error,
    transMemorySelectionList,
    filteringOptions,
    tokens,
  } = reducerState;

  /******************* React Hooks **********************/

  const loadTransMemoriesWithoutUid = useCallback(
    async (query: object, currSelectedTransMemories: TranslationMemoryModel[]): Promise<void> => {
      const relevantTransMemories = await getRelevantTransMemories(query);
      dispatch({
        type: 'loadedTransMemorySelectionTable',
        transMemorySelectionList: consolidateTransMemories(
          currSelectedTransMemories,
          relevantTransMemories
        ),
      });
    },
    [sourceLocale, targetLocales]
  );
  /**
   * Use listByLocales API to get the relevant TM (including fallback, reverse, and fallback reverse).
   */
  const getRelevantTransMemories = useCallback(
    async (query: object): Promise<TranslationMemoryModel[]> => {
      let relevantTransMemories: TranslationMemoryModel[] = [];
      const api = `//${WEB_HOST_AND_PORT}/web/api/v4/transMemory/listByLocales?`;
      await Promise.all(
        (targetLocales ?? []).map(tl =>
          AtmsApiClient.httpGet(
            `${api}${stringify({
              ...query,
              sourceLang: sourceLocale,
              targetLang: tl,
            })}`
          )
        )
      )
        .then(responses => {
          relevantTransMemories = extractTransMemoriesFromResponse(
            responses,
            organizationName,
            targetLocales
          );
        })
        .catch(err => {
          publishCountMetric(
            'loadTransMemorySelectionTableFailed-ProjectListPage',
            'error',
            err.message
          );
          dispatch({ type: 'loadTransMemorySelectionTableFailed', error: err });
        });
      return relevantTransMemories;
    },
    [uid, targetLocales, loadTransMemoriesWithoutUid]
  );

  const handleLoadTransMemories = useCallback(
    (tokens: TablePropertyFiltering.FilteringToken[]) => {
      dispatch({ type: 'loadingTransMemorySelectionTable' });
      loadTransMemoriesWithoutUid(prepareQuery(tokens), currConfiguredTransMemories ?? []);
    },
    [currConfiguredTransMemories, loadTransMemoriesWithoutUid]
  );

  useLayoutEffect(() => {
    if (visible && isLoadingFromServer) {
      dispatch({ type: 'resetTablesAndFiltering' });
      handleLoadTransMemories([]);
    }
  }, [visible, isLoadingFromServer, handleLoadTransMemories]);

  /******************* Functions **********************/
  const prepareQuery = (tokens: TablePropertyFiltering.FilteringToken[]): object => {
    const query = { targetLang: targetLocales };
    tokens.forEach(t => {
      if (t.propertyKey === null) {
        query['name'] = [t.value];
      } else {
        query[t.propertyLabel] = [t.value];
      }
    });
    return query;
  };

  const getColumnDefinitions = (
    targetLocales?: string[]
  ): Table.ColumnDefinition<TranslationMemorySelectionModel>[] => {
    return [
      {
        id: 'name',
        width: 300,
        header: I18n.t('Name'),
        cell: (item): CellContents => <div id={'tmName'}>{item.name}</div>,
      },
      {
        id: 'source',
        width: 150,
        header: I18n.t('Source'),
        cell: (item): CellContents => <div id={'tmSourceLocale'}>{item.tmSourceLocale}</div>,
      },
      {
        id: 'locales',
        width: 150,
        header: I18n.t('Target locale(s)'),
        cell: (item): CellContents => (
          <div id={'tmSupportedLocales'}>
            <TargetLocalesPopup
              targetLocales={targetLocales ?? []}
              supportedLocales={item.supportedTargetLocales}
            />
          </div>
        ),
      },
      {
        id: 'client',
        width: 150,
        header: I18n.t('Client'),
        cell: (item): CellContents => <div id={'tmClient'}>{item.client}</div>,
      },
    ];
  };

  const groupByFunction = (item: TranslationMemoryModel, groupHeaderMap: any): any => {
    if (item.isPublic) {
      groupHeaderMap['Public'].push(item);
    } else {
      groupHeaderMap['Organization'].push(item);
    }
    return groupHeaderMap;
  };

  /******************* Event Handlers **********************/
  const onSelectionChange = (event): void => {
    dispatch({
      type: 'checkOrUncheckTranslationMemory',
      transMemory: event.detail.selectedItems[0],
    });
  };

  const onPropertyFilteringChange = (event): void => {
    dispatch({
      type: 'updateFilteringOptions',
      tokens: event.detail.tokens,
    });
    if (event.detail.tokens.length > 0) {
      handleLoadTransMemories(event.detail.tokens);
    }
  };

  /******************* Constants **********************/
  const headers: HeaderProps[] = [
    { icon: <FontAwesomeIcon id="tmHeaderIcon" icon={faDoorClosed} />, name: 'Organization' },
    {
      icon: <FontAwesomeIcon id="tmHeaderIcon" icon={faGlobe} />,
      name: 'Public',
    },
  ];
  transMemorySelectionList.sort((a, b) => a.id - b.id);

  /******************* Components **********************/
  return isError ? (
    <Alert
      id="transMemorySelectionAlert"
      type="error"
      onDismiss={onClose}
      dismissible={true}
      content={`${I18n.t('Failed to load translation memory selection table')}: ${error}`}
    />
  ) : (
    <Modal
      id="transMemorySelectionModal"
      onDismiss={onClose}
      visible={visible}
      header={I18n.t('Select translation memories')}
      size={'large'}
      footer={
        <span className="awsui-util-f-r">
          <Button
            id="transMemoryCancelBtn"
            variant="link"
            text={I18n.t('Cancel')}
            onClick={onClose}
          />
          <Button
            id="transMemoryConfigureBtn"
            variant="primary"
            text={I18n.t('Configure')}
            onClick={(event): void => {
              onConfigure(
                event,
                transMemorySelectionList.filter(tm => tm.isSelected)
              );
            }}
          />
        </span>
      }
    >
      <ModalContentContainer>
        <TableWithGroupByColumn
          id="transMemorySelectionTable"
          variant="borderless"
          loading={isLoading}
          stickyHeader={false}
          columnDefinitions={getColumnDefinitions(targetLocales)}
          empty={I18n.t('No translation memories found')}
          groupByFunction={groupByFunction}
          groupByEmptyMessage={I18n.t('There are no translation memories configured')}
          groupByHeaders={headers}
          items={transMemorySelectionList}
        >
          <TablePropertyFiltering
            filteringFunction={filteringFunction}
            filteringOptions={filteringOptions}
            groupValuesText="Values"
            groupPropertiesText="Properties"
            clearFiltersText="Clear filter"
            placeholder="Search by name, locale, etc."
            allowFreeTextFiltering={true}
            hideOperations={true}
            tokens={tokens}
            onPropertyFilteringChange={onPropertyFilteringChange}
          />
          <TableSelection
            selectedItems={transMemorySelectionList.filter(tm => tm.isSelected)}
            onSelectionChange={onSelectionChange}
          />
        </TableWithGroupByColumn>
      </ModalContentContainer>
    </Modal>
  );
};
