import { PolarisFormik } from '@amzn/et-polaris-utils';
import { Button, Spinner, Wizard } from '@amzn/awsui-components-react/polaris';
import React, { ReactElement, useEffect, useReducer, useState } from 'react';
import * as yup from 'yup';
import { FormikProps } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  JobCreateAPIStep1,
  jobCreateInitialFormValuesStep1,
  jobCreateSchemaStep1,
  JobCreateStep1UploadFiles,
} from './JobCreateStep1UploadFiles';
import {
  JobCreateAPIStep2,
  jobCreateInitialFormValuesStep2,
  jobCreateSchemaStep2,
  JobCreateStep2AssignJobs,
} from './JobCreateStep2AssignJobs';
import {
  JobCreateAPIStep3,
  jobCreateInitialFormValuesStep3,
  jobCreateSchemaStep3,
  JobCreateStep3FileImporting,
} from './JobCreateStep3FileImporting';
import { WizardStepWithSummary, WizardWithSummary } from '../../components/WizardWithSummary';
import { useWorkflowSteps } from '../../resourceHooks/useWorkflowSteps';
import { useActiveLanguages } from '../../resourceHooks/useActiveLanguages';
import { useUsersAndVendorsForJobAssign } from '../../resourceHooks/useUsersAndVendorsForJobAssign';
import { useSupportedFileFormats } from '../../resourceHooks/useSupportedFileFormats';
import { useSupportedFileEncodings } from '../../resourceHooks/useSupportedFileEncodings';
import { useSegmentationRules } from '../../resourceHooks/useSegmentationRules';
import { useProject } from '../../resourceHooks/useProject';
import I18n from '../../../setupI18n';
import { JobCreateSummary } from './JobCreateSummary';
import { useProjectTemplate } from '../../resourceHooks/useProjectTemplate';
import { Alert } from '@amzn/awsui-components-react-v3';
import { AtmsApiClient, AuthenticatedSession } from '@amzn/et-console-components';
import { publishCountMetric, publishLatencyMetric } from '../../metricHelper';
import { getAppHostConfig } from '@amzn/et-console-components';

const { WEB_HOST_AND_PORT } = getAppHostConfig();

export interface Props extends RouteComponentProps {
  session: AuthenticatedSession;
}

interface JobCreateAPI extends JobCreateAPIStep1, JobCreateAPIStep2, JobCreateAPIStep3 {}

interface ReducerState {
  isError: boolean;
  error?: string;
  activeStep: number;
  validating: boolean;
  isCreatingJobs: boolean;
}

export const JobCreate = withRouter(({ history, location: { pathname }, session }: Props) => {
  // TODO: We should be able to get this from the Router.match.params prop
  const [projectUID, setProjectUID] = useState(pathname.substr(pathname.lastIndexOf('/') + 1));
  useEffect(() => {
    setProjectUID(pathname.substr(pathname.lastIndexOf('/') + 1));
  }, [pathname, setProjectUID]);

  const init = (): ReducerState => ({
    activeStep: 0,
    isError: false,
    validating: false,
    isCreatingJobs: false,
  });

  const reducer = (state: ReducerState, action): ReducerState => {
    switch (action.type) {
      case 'activeStepChange':
        return {
          ...state,
          activeStep: action.activeStep,
        };
      case 'setValidating':
        return {
          ...state,
          validating: action.validating,
        };
      case 'creatingJobs':
        return {
          ...state,
          isCreatingJobs: true,
          isError: false,
        };
      case 'createJobsDone':
        return {
          ...state,
          isCreatingJobs: false,
          isError: false,
        };
      case 'createJobsFailed':
        return {
          ...state,
          isCreatingJobs: false,
          isError: true,
          error: action.error,
        };
      case 'clearError':
        return {
          ...state,
          isError: false,
          error: undefined,
        };
      default:
        return state;
    }
  };
  const [reducerState, dispatch] = useReducer(reducer, null, init);
  const { validating, activeStep, isCreatingJobs, isError, error } = reducerState;
  const { isLoadingWorkflowSteps, workflowSteps, workflowStepsById } = useWorkflowSteps();
  const { isLoadingProject, project } = useProject(projectUID);
  const { isLoadingProjectTemplate, projectTemplate } = useProjectTemplate(
    project?.projectTemplateId
  );
  const { isLoadingActiveLanguages, activeLanguages, activeLanguagesByCode } = useActiveLanguages();
  const { isLoadingUsers, foundUsers } = useUsersAndVendorsForJobAssign();
  const { isLoadingSupportedFileFormats, supportedFileFormats } = useSupportedFileFormats();
  const { isLoadingSupportedFileEncodings, supportedFileEncodings } = useSupportedFileEncodings();
  const { isLoadingSegmentationRules, segmentationRules } = useSegmentationRules();
  const renderForm = (props: FormikProps<JobCreateAPI>): ReactElement => {
    const handleSubmitWithValidation = (e: CustomEvent<Button.ClickDetail>, handleSubmit): void => {
      e.preventDefault();
      dispatch({ type: 'setValidating', validating: true });
      handleSubmit();
    };

    const { handleSubmit, errors } = props;
    /********************** Components **********************/
    const steps: WizardStepWithSummary[] = [
      JobCreateStep1UploadFiles({
        validating,
        isLoadingActiveLanguages,
        projectTargetLocales: project?.targetLocales,
        activeLanguages,
        ...props,
      }),
      JobCreateStep2AssignJobs({
        session,
        validating,
        isProjectTemplateMode: false,
        isLoadingActiveLanguages,
        isLoadingWorkflowSteps,
        isLoadingUsers,
        workflowSteps,
        workflowStepsById,
        activeLanguages,
        activeLanguagesByCode,
        foundUsers,
        ...props,
      }),
      JobCreateStep3FileImporting({
        validating,
        isLoadingSupportedFileFormats,
        isLoadingSupportedFileEncodings,
        isLoadingSegmentationRules,
        supportedFileFormats,
        supportedFileEncodings,
        segmentationRules,
        isProjectTemplateMode: false,
        ...props,
      }),
    ];
    const stepsWithSummary = [
      ...steps,
      JobCreateSummary({
        ...props,
        children: steps.map(s => s.summary({ currentValue: project })),
        title: I18n.t('Review and save'),
        helpId: '',
        description: I18n.t(
          'Review the job settings before uploading the jobs. This view shows only fields that have changed.'
        ),
      }),
    ];

    const additionalButtons = [
      activeStep === 0 ? (
        <Button
          id="create"
          key="create"
          formAction="none"
          loading={isCreatingJobs}
          onClick={e => handleSubmitWithValidation(e, handleSubmit)}
        >
          {I18n.t('Create jobs')}
        </Button>
      ) : activeStep > 0 && activeStep < steps.length ? (
        <Button
          id={'reviewAndCreate'}
          key="reviewAndCreate"
          formAction="none"
          onClick={(): void =>
            handleStepChange(
              new CustomEvent<Wizard.StepClickDetail>('stepClickDetail', {
                detail: { requestedStepIndex: steps.length },
              }),
              errors
            )
          }
        >
          {I18n.t('Review and create')}
        </Button>
      ) : null,
    ].filter(t => t);
    return (
      <form>
        {isError && (
          <Alert
            type="error"
            onDismiss={(): void => {
              dispatch({ type: 'clearError' });
            }}
            dismissible={true}
          >
            {error}
          </Alert>
        )}
        <WizardWithSummary
          steps={stepsWithSummary}
          activeStepIndex={activeStep}
          onCancelButtonClick={() => history.goBack()}
          onNextButtonClick={e => handleStepChange(e, props.errors)}
          onPreviousButtonClick={e => handleStepChange(e, props.errors)}
          onStepNavigationClick={e => handleStepChange(e, props.errors)}
          onSubmitButtonClick={(): void => {
            handleSubmit();
          }}
          additionalButtons={additionalButtons}
          isSubmitting={isCreatingJobs}
        />
      </form>
    );
  };
  const handleSubmit = (values: JobCreateAPI): void => {
    dispatch({ type: 'creatingJobs' });
    const { atmsFiles, ...params } = values;

    const form = new FormData();

    // append params
    form.append(
      'params',
      JSON.stringify({
        ...params,
        projectUid: projectUID,
        notifyLinguistsEmailTemplate: params.notifyLinguistsEmailTemplate?.value,
        notifyLinguistsInterval: params.notifyLinguistsInterval?.value,
      })
    );

    // TODO: we can't use AtmsApiClient.multipartUpload because it doesn't allow us to append the same key multiple times
    //  we might consider changing the way that function works if we want to upload multiple files in a different place later
    // append files
    if (atmsFiles) {
      for (let i = 0; i < atmsFiles.length; i++) {
        form.append('file', atmsFiles[i]);
      }
    }

    const options = {
      method: 'POST',
      body: form,
      credentials: 'include' as RequestCredentials,
    };

    const initialTime = new Date();
    AtmsApiClient.httpRequest('/api/v2/job', options)
      .then(() => {
        dispatch({ type: 'createJobsDone' });
        history.push(`/web/project2/show/${projectUID}`);
      })
      .catch(err => {
        if (err.status === 504) {
          publishCountMetric('createJobsFailed-504-Error-JobCreate', 'error', err.message);
          // Special treatment for gateway timeout
          // The request timed out waiting for import to start, but the jobs might still get created successfully
          pollForAsyncRequest();
        } else {
          publishCountMetric('createJobsFailed-JobCreate', 'error', err.message);
          dispatch({
            type: 'createJobsFailed',
            error: I18n.t('Failed to create jobs: %{e}', { e: JSON.stringify(err) }),
          });
        }
      })
      .finally(() => {
        const finalTime = new Date();
        publishLatencyMetric(
          'JobCreate-Latency',
          initialTime,
          finalTime,
          atmsFiles ? atmsFiles.length : 1
        );
      });
  };

  const pollForAsyncRequest = (): void => {
    AtmsApiClient.httpGet(`//${WEB_HOST_AND_PORT}/web/api/v3/async/listPendingRequests`)
      .then(asyncRequests => {
        const matchingRequests = asyncRequests.filter(
          ar => ar.project.id === project?.id && ar.action === 'IMPORT_JOB'
        );
        if (matchingRequests.length === 0) {
          setTimeout(pollForAsyncRequest, 1000);
        } else {
          dispatch({ type: 'createJobsDone' });
          history.push(`/web/project2/show/${projectUID}`);
        }
      })
      .catch(err => {
        publishCountMetric('createJobsFailed-JobCreate', 'error', err.message);
        dispatch({
          type: 'createJobsFailed',
          error: I18n.t('Failed waiting for job creation: %{e}', { e: JSON.stringify(err) }),
        });
      });
  };

  const handleStepChange = (e: CustomEvent<Wizard.StepClickDetail>, errors): void => {
    if (Object.values(errors).length > 0) {
      e.preventDefault();
    } else {
      dispatch({ type: 'activeStepChange', activeStep: e.detail.requestedStepIndex });
    }
    dispatch({ type: 'setValidating', validating: true });
  };
  const initFormValues = {
    ...jobCreateInitialFormValuesStep1(project),
    ...jobCreateInitialFormValuesStep2(project, projectTemplate),
    ...jobCreateInitialFormValuesStep3(projectTemplate, project),
  };

  const schema = yup
    .object()
    .concat(jobCreateSchemaStep1)
    .concat(jobCreateSchemaStep2)
    .concat(jobCreateSchemaStep3);

  return !isLoadingProject && !isLoadingProjectTemplate ? (
    <PolarisFormik
      initialValues={initFormValues}
      validationSchema={schema}
      validateOnChange={true}
      onSubmit={handleSubmit}
      render={renderForm}
      initialErrors={{ atmsFiles: I18n.t('At least one file is required') }}
    />
  ) : (
    <Spinner size="large" />
  );
});
