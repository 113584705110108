import React, { ReactElement, useEffect, useReducer } from 'react';
import {
  isStrongEquals,
  ModalContentContainer,
  TranslationMemoryModel,
  getGroupByFunction,
  getTargetLocaleHeader,
  isWorkflowIdEquals,
  isWriteTMCountValid,
  isTMValidate,
  TranslationMemoryInConfigurePanel,
  getTranslationMemoriesToBeSaved,
  getTranslationMemoriesInConfigurationPanel,
} from './TranslationMemoryCommon';
import {
  Modal,
  Button,
  Badge,
  Table,
  Checkbox,
  Select,
  Alert,
} from '@amzn/awsui-components-react/polaris';
import I18n from '../../../setupI18n';
import { CellContents, WorkflowStep } from '../../types/commonTypes';
import { TableWithGroupByColumn } from '../../components/TableWithGroupByColumn';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { WorkflowStepFilter } from './TranslationMemoryWorkflowStepFilter';
import { T } from '../../components/T';

const ItemName = styled('span')`
  color: var(--awsui-color-grey-600);
  font-weight: bold;
  margin-right: 10px;
`;

const PenaltyInputContainer = styled('div')`
  display: inline-block;
  max-width: 70px;
  margin-right: 3px;
  margin-bottom: 10px;
`;

interface Props {
  onSave: (configuredTMs: TranslationMemoryModel[]) => void;
  onClose: (event: CustomEvent) => void;
  onBack: (event: CustomEvent) => void;
  projectTargetLocales?: string[];
  workflowSteps?: WorkflowStep[];
  originalConfigurableTMs?: TranslationMemoryModel[];
  visible: boolean;
  canGoBackToSelection: boolean;
  isInProjectTemplateCreationEditPage: boolean; // whether in project/template creation/edit pages
}

interface ReducerState {
  configurableTMs: TranslationMemoryModel[];
  translationMemoriesInConfigurePanel: TranslationMemoryInConfigurePanel[];
  workflowStepIdFilter: string | null;
  isError: boolean;
  errorMessage: string[];
  isSaveButtonLoading: boolean;
  isCombiningAllLocales: boolean;
  showConfirmCombineLocales: boolean;
}

export const TranslationMemoryConfiguration = ({
  onSave,
  onClose,
  onBack,
  visible,
  originalConfigurableTMs,
  workflowSteps,
  projectTargetLocales,
  canGoBackToSelection,
  isInProjectTemplateCreationEditPage,
}: Props): ReactElement => {
  const init = (): ReducerState => ({
    configurableTMs: [],
    translationMemoriesInConfigurePanel: [],
    isError: false,
    errorMessage: [],
    workflowStepIdFilter: null,
    isSaveButtonLoading: false,
    isCombiningAllLocales: false, // TODO: we should base this on whether collapsing all locales loses any fidelity
    showConfirmCombineLocales: false,
  });
  const reducer = (state: ReducerState, action): ReducerState => {
    switch (action.type) {
      case 'updateTMItem':
        return {
          ...state,
          translationMemoriesInConfigurePanel: state.translationMemoriesInConfigurePanel.map(tm => {
            if (isStrongEquals(tm, action.configurableTM, state.isCombiningAllLocales)) {
              return action.configurableTM;
            }
            return tm;
          }),
        };
      case 'loadConfigurableTMs':
        return {
          ...state,
          isSaveButtonLoading: false,
          configurableTMs: action.configurableTMs,
          translationMemoriesInConfigurePanel: action.translationMemoriesInConfigurePanel,
        };
      case 'atmsAPICallError':
        return {
          ...state,
          errorMessage: action.message,
          isError: true,
        };
      case 'resetErrorState':
        return {
          ...state,
          isError: false,
          errorMessage: [],
        };
      case 'updateWorkflowStepFilter':
        return {
          ...state,
          workflowStepIdFilter: action.filter,
        };
      case 'updateSaveButtonLoadingState':
        return {
          ...state,
          isSaveButtonLoading: action.isLoading,
        };
      case 'userToggledCombineAllLocalesCheckbox':
        return {
          ...state,
          showConfirmCombineLocales: true,
        };
      case 'userAbandonedToChangeConfigurationMode':
        return {
          ...state,
          showConfirmCombineLocales: false,
        };
      case 'userConfirmedToSwitchConfigurationMode':
        return {
          ...state,
          showConfirmCombineLocales: false,
          isCombiningAllLocales: !state.isCombiningAllLocales,
          translationMemoriesInConfigurePanel: getTranslationMemoriesInConfigurationPanel(
            state.configurableTMs,
            !state.isCombiningAllLocales
          ),
        };
      default:
        return state;
    }
  };
  const [reducerState, dispatch] = useReducer(reducer, null, init);
  const {
    configurableTMs,
    isError,
    errorMessage,
    workflowStepIdFilter,
    isSaveButtonLoading,
    isCombiningAllLocales,
    translationMemoriesInConfigurePanel,
    showConfirmCombineLocales,
  } = reducerState;

  /************************* React Hooks **************************/
  useEffect(() => {
    dispatch({
      type: 'loadConfigurableTMs',
      configurableTMs: originalConfigurableTMs,
      translationMemoriesInConfigurePanel: getTranslationMemoriesInConfigurationPanel(
        originalConfigurableTMs ?? [],
        false
      ),
    });
  }, [originalConfigurableTMs, onClose]);

  /************************* Constant & Functions **************************/
  const columnDefinitions: Table.ColumnDefinition<TranslationMemoryInConfigurePanel>[] = [
    {
      id: 'name',
      header: I18n.t('Name'),
      width: 350,
      cell: (item): CellContents => (
        <div>
          <ItemName id={'tmName'}>
            {item.isOverride ? item.name + ' [OVERRIDE]' : item.name}
          </ItemName>
          {item.isPublic && (
            <FontAwesomeIcon
              id={'globalIcon'}
              style={{ margin: '0px 5px 0px 0px' }}
              icon={faGlobe}
            />
          )}
          {item.isNew && (
            <Badge id={'newBadge'} color="blue">
              New
            </Badge>
          )}
        </div>
      ),
    },
    {
      id: 'override',
      width: 150,
      header: I18n.t('Override'),
      cell: (item): CellContents =>
        item.workflowStepId !== null && (
          <Checkbox
            id={'tmOverrideCheckBox'}
            controlId={'tmOverrideCheckBox'}
            checked={item.isOverride}
            indeterminate={item.isOverrideIndeterminate ?? false}
            onChange={(e): void => {
              dispatch({
                type: 'updateTMItem',
                configurableTM: {
                  ...item,
                  isOverride: e.detail.checked,
                  isOverrideIndeterminate: false,
                },
              });
            }}
          />
        ),
    },
    {
      id: 'read',
      header: I18n.t('Read'),
      width: 90,
      cell: (item): CellContents => (
        <Checkbox
          id={'tmReadCheckBox'}
          controlId={'tmReadCheckBox'}
          disabled={!item.isOverride && item.workflowStepId != null}
          checked={item.readMode}
          indeterminate={item.isReadIndeterminate ?? false}
          onChange={(e): void => {
            dispatch({
              type: 'updateTMItem',
              configurableTM: {
                ...item,
                readMode: e.detail.checked,
                writeMode: e.detail.checked ? item.writeMode : false,
                isReadIndeterminate: false,
                isWriteIndeterminate: e.detail.checked ? item.isWriteIndeterminate : false,
              },
            });
          }}
        />
      ),
    },
    {
      id: 'write',
      header: I18n.t('Write'),
      width: 90,
      cell: (item): CellContents => (
        <Checkbox
          id={'tmWriteCheckBox'}
          controlId={'tmWriteCheckBox'}
          checked={item.writeMode}
          indeterminate={item.isWriteIndeterminate ?? false}
          disabled={
            item.isPublic || item.isReverse || (!item.isOverride && item.workflowStepId != null)
          }
          onChange={(e): void => {
            dispatch({ type: 'resetErrorState' });
            const { isValid, errorMessage } = isWriteTMCountValid(
              item,
              translationMemoriesInConfigurePanel,
              configurableTMs,
              isCombiningAllLocales,
              projectTargetLocales ?? []
            );
            if (item.writeMode || isValid || item.isWriteIndeterminate) {
              const newWriteMode = !isValid && item.isWriteIndeterminate ? false : e.detail.checked;
              dispatch({
                type: 'updateTMItem',
                configurableTM: {
                  ...item,
                  writeMode: newWriteMode,
                  readMode: e.detail.checked ? true : item.readMode,
                  isWriteIndeterminate: false,
                  isReadIndeterminate: e.detail.checked ? false : item.isReadIndeterminate,
                },
              });
            } else {
              dispatch({ type: 'atmsAPICallError', message: errorMessage });
            }
          }}
        />
      ),
    },
    {
      id: 'penalty',
      header: I18n.t('Penalty'),
      cell: (item): CellContents => (
        <div>
          <PenaltyInputContainer>
            <Select
              id={'mtPenaltySelect'}
              options={[
                { id: '0', label: '0%' },
                { id: '1', label: '1%' },
                { id: '2', label: '2%' },
                { id: '3', label: '3%' },
                { id: '4', label: '4%' },
                { id: '5', label: '5%' },
                { id: '10', label: '10%' },
                { id: '20', label: '20%' },
                { id: '30', label: '30%' },
              ]}
              disabled={!item.isOverride && item.workflowStepId != null}
              selectedOption={
                !item.isPenaltyIndeterminate
                  ? { id: item.penalty + '', label: item.penalty + '%' }
                  : { id: '-1', label: 'Multiple' }
              }
              selectedLabel="Selected"
              onChange={(e): void => {
                dispatch({
                  type: 'updateTMItem',
                  configurableTM: {
                    ...item,
                    penalty: parseInt(e.detail.selectedId),
                    readMode: true,
                    isOverride: item.workflowStepId !== null,
                    isPenaltyIndeterminate: false,
                  },
                });
              }}
            />
          </PenaltyInputContainer>
        </div>
      ),
    },
  ];

  const getItems = (): TranslationMemoryModel[] => {
    return translationMemoriesInConfigurePanel.filter(tm => {
      return isWorkflowIdEquals(tm.workflowStepId, workflowStepIdFilter);
    });
  };

  return (
    <Modal
      id="translationMemoryConfigureModal"
      onDismiss={onClose}
      visible={visible}
      header={I18n.t('Configure translation memories')}
      size={'large'}
      footer={
        <span className="awsui-util-f-r">
          <Button variant="link" text={I18n.t('Cancel')} onClick={onClose} />
          {canGoBackToSelection && (
            <Button
              id="tmConfigurationPageGoBack"
              variant="normal"
              text={I18n.t('Back')}
              onClick={onBack}
            />
          )}
          <Button
            id="translationMemoryConfigureBtn"
            variant="primary"
            text={isInProjectTemplateCreationEditPage ? I18n.t('Confirm') : I18n.t('Save')}
            loading={isSaveButtonLoading}
            onClick={(event): void => {
              const translationMemoriesToBeSaved: TranslationMemoryModel[] = getTranslationMemoriesToBeSaved(
                translationMemoriesInConfigurePanel,
                configurableTMs,
                isCombiningAllLocales
              );
              if (isInProjectTemplateCreationEditPage) {
                event.preventDefault();
              }
              dispatch({ type: 'resetErrorState' });
              const { isValid, errorMessage } = isTMValidate(
                translationMemoriesToBeSaved,
                workflowSteps ?? [],
                projectTargetLocales ?? []
              );
              if (isValid) {
                dispatch({ type: 'updateSaveButtonLoadingState', isLoading: true });
                onSave(translationMemoriesToBeSaved);
              } else {
                dispatch({ type: 'atmsAPICallError', message: errorMessage });
              }
            }}
          />
        </span>
      }
    >
      <ModalContentContainer>
        {isError &&
          errorMessage.map((m, index) => (
            <Alert
              id={`tmConfigurationPageAlert${index}`}
              key={`tmConfigurationPageAlert${index}`}
              type="error"
              content={m}
            />
          ))}
        {showConfirmCombineLocales && (
          <Modal
            id={'tmCombineAllLocalesModal'}
            footer={
              <span className="awsui-util-f-r">
                <Button
                  id={'cancelTmCombineAllLocalesOptionBtn'}
                  variant="link"
                  text={I18n.t('Cancel')}
                  onClick={(): void =>
                    dispatch({
                      type: 'userAbandonedToChangeConfigurationMode',
                    })
                  }
                />
                <Button
                  id="translationMemoryConfigureBtn"
                  variant="primary"
                  text={I18n.t('OK')}
                  onClick={(): void =>
                    dispatch({
                      type: 'userConfirmedToSwitchConfigurationMode',
                    })
                  }
                />
              </span>
            }
            closeLabel={'Close modal'}
            size={'small'}
            header={I18n.t('Are you sure?')}
            visible={showConfirmCombineLocales}
            onDismiss={(): void => {
              dispatch({ type: 'userAbandonedToChangeConfigurationMode' });
            }}
          >
            <T>
              <strong>You will lose any unsaved changes</strong> if you switch configuration modes
            </T>
          </Modal>
        )}
        <TableWithGroupByColumn
          id="translationMemoryConfiguration"
          groupByFunction={getGroupByFunction(isCombiningAllLocales)}
          variant="borderless"
          columnDefinitions={columnDefinitions}
          items={getItems()}
          empty={I18n.t('No translation memory to configure')}
          groupByEmptyMessage={I18n.t('No translation memories selected for this locale')}
          groupByHeaders={getTargetLocaleHeader(projectTargetLocales, isCombiningAllLocales)}
        >
          <WorkflowStepFilter
            id={'tmConfigureWorkflowStepFilter'}
            workflowSteps={workflowSteps ?? []}
            onChange={(workflowStepId): void => {
              dispatch({ type: 'updateWorkflowStepFilter', filter: workflowStepId });
            }}
          />
          <Checkbox
            id={'combineAllLocalesCheckbox'}
            checked={isCombiningAllLocales}
            label={I18n.t('Combine locale configuration')}
            onChange={(event): void => {
              dispatch({
                type: 'userToggledCombineAllLocalesCheckbox',
                isCombiningAllLocales: event.detail.checked,
              });
            }}
          />
        </TableWithGroupByColumn>
      </ModalContentContainer>
    </Modal>
  );
};
