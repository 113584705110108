import React, { ReactElement, useCallback, useEffect, useReducer, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import I18n from '../../setupI18n';
import { Button, Wizard, Spinner, Alert } from '@amzn/awsui-components-react/polaris';
import {
  initialFormValuesStep1,
  ProjectCreateAPIStep1,
  ProjectCreateStep1Metadata,
  schemaStep1,
} from './ProjectCreateStep1Metadata';
import { AtmsApiClient, AuthenticatedSession } from '@amzn/et-console-components';
import { FormikProps } from 'formik';
import { PolarisFormik } from '@amzn/et-polaris-utils';
import * as yup from 'yup';
import { ProjectCreateSummary } from './ProjectCreateSummary';
import {
  getDefaultMachineTranslationEngine,
  initialFormValuesStep3,
  ProjectCreateAPIStep3,
  ProjectCreateStep3Pretranslate,
  schemaStep3,
} from './ProjectCreateStep3Pretranslate';
import { ProjectTemplate } from '../types/commonTypes';
import {
  initialFormValuesStep2,
  ProjectCreateAPIStep2,
  ProjectCreateStep2Assets,
  schemaStep2,
} from './ProjectCreateStep2Assets';
import {
  initialFormValuesStep4,
  ProjectCreateAPIStep4,
  ProjectCreateStep4Analysis,
  schemaStep4,
} from './ProjectCreateStep4Analysis';
import {
  initialFormValuesStep5,
  ProjectCreateAPIStep5,
  ProjectCreateStep5Quality,
  schemaStep5,
} from './ProjectCreateStep5Quality';
import {
  initialFormValuesStep6,
  ProjectCreateAPIStep6,
  ProjectCreateStep6AccessAndAutomation,
  schemaStep6,
} from './ProjectCreateStep6AccessAndAutomation';
import { WizardStepWithSummary, WizardWithSummary } from '../components/WizardWithSummary';
import { parse, stringify } from 'query-string';
import { PROJECT_CREATE_REVIEW_AND_CREATE_HELP } from './projectHelpContent';
import { useBusinessUnits } from '../resourceHooks/useBusinessUnits';
import { useClients } from '../resourceHooks/useClients';
import { useOwners } from '../resourceHooks/useOwners';
import { useWorkflowSteps } from '../resourceHooks/useWorkflowSteps';
import { useMediaTypes } from '../resourceHooks/useMediaTypes';
import { useSubjects } from '../resourceHooks/useSubjects';
import { useCostCenters } from '../resourceHooks/useCostCenters';
import { useMachineTranslateSettings } from '../resourceHooks/useMachineTranslateSettings';
import { useActiveLanguages } from '../resourceHooks/useActiveLanguages';
import { useProjectTemplates } from '../resourceHooks/useProjectTemplates';
import { useScoringModels } from '../resourceHooks/useScoringModels';
import { saveTMConfig } from './translationmemory/TranslationMemoryCommon';
import { saveTBConfigs } from './termbases/TermBaseCommons';
import { createReferences } from './references/ReferenceCommons';
import { useProjectTemplate } from '../resourceHooks/useProjectTemplate';
import { useTermBases } from '../resourceHooks/useTermBases';
import { useTranslationMemories } from '../resourceHooks/useTranslationMemories';
import { useReferences } from '../resourceHooks/useReferences';
import { publishCountMetric, publishLatencyMetric } from '../metricHelper';

interface ReducerState {
  isCreatingProject: boolean;
  projectTemplateId?: number;
  projectTemplate?: ProjectTemplate;
  isError: boolean;
  error?: string;
  activeStep: number;
}

interface ProjectCreateAPI
  extends ProjectCreateAPIStep1,
    ProjectCreateAPIStep2,
    ProjectCreateAPIStep3,
    ProjectCreateAPIStep4,
    ProjectCreateAPIStep5,
    ProjectCreateAPIStep6 {}

export interface Props extends RouteComponentProps {
  session: AuthenticatedSession;
}
export const ProjectCreate = withRouter(
  ({ history, location, location: { search }, session }: Props) => {
    const query = parse(search, { parseNumbers: true });
    const templateIdFromQuery = query['templateId'] as number;
    const init = (): ReducerState => {
      return {
        activeStep: 0,
        isCreatingProject: false,
        isError: false,
      };
    };

    const reducer = (state, action): ReducerState => {
      switch (action.type) {
        case 'activeStepChange':
          return {
            ...state,
            activeStep: action.activeStep,
          };
        case 'projectTemplateChanged':
          return {
            ...state,
            projectTemplateId: action.projectTemplateId,
          };
        case 'creatingProject':
          return {
            ...state,
            isCreatingProject: true,
          };
        case 'projectCreated':
          return {
            ...state,
            isCreatingProject: false,
          };
        case 'projectCreationFailed':
          return {
            ...state,
            isCreatingProject: false,
            isError: true,
            error: action.error,
          };
        case 'clearError':
          return {
            ...state,
            isError: false,
            error: undefined,
          };
        default:
          return state;
      }
    };

    const [reducerState, dispatch] = useReducer(reducer, null, init);
    const { isCreatingProject, activeStep, projectTemplateId, isError, error } = reducerState;
    const [validating, setValidating] = useState(false);
    const { isLoadingProjectTemplates, projectTemplates } = useProjectTemplates();
    const { isLoadingWorkflowSteps, workflowSteps, workflowStepsById } = useWorkflowSteps();
    const { isLoadingClients, clients, clientsById } = useClients();
    const { isLoadingOwners, owners, ownersById } = useOwners();
    const { isLoadingCostCenters, costCenters, costCentersById } = useCostCenters();
    const { isLoadingBusinessUnits, businessUnits, businessUnitsById } = useBusinessUnits();
    const { isLoadingMediaTypes, mediaTypes, mediaTypesById } = useMediaTypes();
    const { isLoadingSubjects, subjects, subjectsById } = useSubjects();
    const {
      isLoadingActiveLanguages,
      activeLanguages,
      activeLanguagesByCode,
    } = useActiveLanguages();
    const { isLoadingScoringModels, scoringModels, scoringModelsById } = useScoringModels();
    const {
      isLoadingMachineTranslateSettings,
      machineTranslateSettings,
      machineTranslateSettingsById,
    } = useMachineTranslateSettings();
    const { isLoadingTermBaseAssignments, termBaseAssignments } = useTermBases(
      false,
      projectTemplateId
    );
    const {
      isLoadingTransMemoryAssignments,
      translationMemoryAssignments,
    } = useTranslationMemories(false, projectTemplateId);
    const { isLoadingReferences, references } = useReferences(false, projectTemplateId);
    const { isLoadingProjectTemplate, projectTemplate } = useProjectTemplate(projectTemplateId);
    useEffect(() => {
      dispatch({ type: 'projectTemplateChanged', projectTemplateId: templateIdFromQuery });
    }, [templateIdFromQuery]);

    const handleSubmit = (values: ProjectCreateAPI): void => {
      dispatch({ type: 'creatingProject' });

      const {
        termBaseAssignments: newTermBaseAssignments,
        translationMemoryAssignments: newTranslationMemoryAssignments,
        references,
        ...projectValues
      } = values;

      const request = {
        ...projectValues,
        note: values.note ? values.note : undefined, // It seems like the Textarea returns 'null' instead of 'undefined' for 'no value'
        perLocaleMachineTranslateSettingsIds: JSON.stringify(
          values.perLocaleMachineTranslateSettingsIds
        ),
        machineTranslateSettingsId: values.machineTranslateSettingsId,
        workflowStepIds: values.workflowStepIds.filter(id => workflowStepsById[id] !== undefined), // remove the deleted workflow step
      };
      const initialTime = new Date();
      AtmsApiClient.httpPost('/api/project', request)
        .then(response => {
          Promise.all(
            saveTBConfigs(
              true,
              termBaseAssignments,
              newTermBaseAssignments,
              response.id,
              values.targetLocales ?? []
            )
              .concat(createReferences(true, values.references, response.id)) // TODO: Need to copy references from project template
              .concat(
                saveTMConfig(
                  true,
                  workflowSteps,
                  translationMemoryAssignments,
                  newTranslationMemoryAssignments,
                  response.id,
                  values.targetLocales
                )
              )
          ).then(() => {
            dispatch({ type: 'projectCreated' });
            history.push(`/web/project2/show/${response.uid}`);
          });
        })
        .catch(err => {
          publishCountMetric('projectCreationFailed-ProjectCreate', 'error', err.message);
          dispatch({
            type: 'projectCreationFailed',
            error: I18n.t('Failed to create project: %{e}', { e: JSON.stringify(err) }),
          });
        })
        .finally(() => {
          const finalTime = new Date();
          publishLatencyMetric('projectCreate-Latency', initialTime, finalTime);
        });
    };

    /**
     * Per AWS Specifications, only start validating after the initial submit.
     * @param e the event
     * @param errors
     */
    const handleStepChange = (e: CustomEvent<Wizard.StepClickDetail>, errors): void => {
      if (Object.values(errors).length > 0) {
        e.preventDefault();
      } else {
        dispatch({ type: 'activeStepChange', activeStep: e.detail.requestedStepIndex });
      }
      setValidating(true);
    };

    /**
     * Per AWS Specifications, only start validating after the initial submit.
     * @param e the event
     * @param handleSubmit formik submit event handler function.
     */
    const handleSubmitWithValidation = (e: CustomEvent<Button.ClickDetail>, handleSubmit): void => {
      e.preventDefault();
      setValidating(true);
      handleSubmit();
    };

    const updateProjectTemplate = useCallback(
      (templateId?: string | number): void => {
        const query = parse(search, { parseNumbers: true });
        history.replace({ ...location, search: stringify({ ...query, templateId }) });
      },
      [search]
    );

    const renderForm = (props: FormikProps<ProjectCreateAPI>): ReactElement => {
      const steps: WizardStepWithSummary[] = [
        ProjectCreateStep1Metadata({
          validating,
          ...props,
          updateProjectTemplate,
          isLoadingActiveLanguages,
          isLoadingMediaTypes,
          isLoadingProjectTemplates,
          isLoadingSubjects,
          isLoadingWorkflowSteps,
          isLoadingClients,
          isLoadingOwners,
          isLoadingBusinessUnits,
          isLoadingCostCenters,
          isLoadingScoringModels,
          projectTemplates,
          workflowSteps,
          workflowStepsById,
          mediaTypes,
          mediaTypesById,
          subjects,
          subjectsById,
          clients,
          clientsById,
          owners,
          ownersById,
          businessUnits,
          businessUnitsById,
          costCenters,
          costCentersById,
          activeLanguages,
          activeLanguagesByCode,
          scoringModels,
          scoringModelsById,
          showProjectTemplates: true,
        }),
        ProjectCreateStep2Assets({
          validating,
          ...props,
          isProjectMode: true,
          workflowSteps,
        }),
        ProjectCreateStep3Pretranslate({
          session,
          validating,
          ...props,
          isLoadingMachineTranslateSettings,
          isLoadingActiveLanguages,
          machineTranslateSettings,
          machineTranslateSettingsById,
          activeLanguages,
          activeLanguagesByCode,
        }),
        ProjectCreateStep4Analysis({ validating, ...props }),
        ProjectCreateStep5Quality({ validating, ...props }),
        ProjectCreateStep6AccessAndAutomation({ validating, ...props }),
      ];
      const { handleSubmit, errors } = props;

      return (
        <form>
          {isError && (
            <Alert
              id={'createProjectError'}
              type="error"
              content={error}
              dismissible={true}
              onDismiss={(): void => dispatch({ type: 'clearError' })}
            />
          )}
          <WizardWithSummary
            steps={[
              ...steps,
              ProjectCreateSummary({
                ...props,
                children: steps.map(s => s.summary({ currentValue: projectTemplate })),
                title: I18n.t('Review and create'),
                helpId: PROJECT_CREATE_REVIEW_AND_CREATE_HELP,
                description: I18n.t(
                  'Review the project settings before creating the project. This view shows only fields that have changed.'
                ),
              }),
            ]}
            i18nStrings={{
              stepNumberLabel: (stepNumber: number): string =>
                I18n.t('Step %{stepNumber}', { stepNumber: stepNumber }),
              collapsedStepsLabel: (stepNumber: number, stepsCount: number): string =>
                I18n.t('Step %{stepNumber} of %{stepsCount}', {
                  stepNumber: stepNumber,
                  stepsCount: stepsCount,
                }),
              cancelButton: I18n.t('Cancel'),
              previousButton: I18n.t('Previous'),
              nextButton: I18n.t('Next'),
              submitButton: I18n.t('Create project'),
              optional: I18n.t('Optional'),
            }}
            onCancelButtonClick={() => history.goBack()}
            onSubmitButtonClick={() => handleSubmit()}
            onNextButtonClick={e => handleStepChange(e, errors)}
            onPreviousButtonClick={e => handleStepChange(e, errors)}
            onStepNavigationClick={e => handleStepChange(e, errors)}
            additionalButtons={[
              activeStep > 0 && activeStep < steps.length && (
                <Button
                  id={'reviewAndCreate'}
                  key="reviewAndCreate"
                  formAction="none"
                  onClick={(): void =>
                    handleStepChange(
                      new CustomEvent<Wizard.StepClickDetail>('stepClickDetail', {
                        detail: { requestedStepIndex: steps.length },
                      }),
                      errors
                    )
                  }
                >
                  {I18n.t('Review and create')}
                </Button>
              ),
              activeStep === 0 && (
                <Button
                  id="create"
                  key="create"
                  formAction="none"
                  onClick={e => handleSubmitWithValidation(e, handleSubmit)}
                  loading={isCreatingProject}
                >
                  {I18n.t('Create project')}
                </Button>
              ),
            ].filter(b => b)}
            isSubmitting={isCreatingProject}
            isLoadingNextStep={isCreatingProject}
            activeStepIndex={activeStep}
          />
        </form>
      );
    };

    const initialFormValues: ProjectCreateAPI = {
      ...initialFormValuesStep1(true, projectTemplate, session),
      ...initialFormValuesStep2(termBaseAssignments, translationMemoryAssignments, references),
      ...initialFormValuesStep3(
        projectTemplate,
        getDefaultMachineTranslationEngine(session, machineTranslateSettings)
      ),
      ...initialFormValuesStep4(projectTemplate),
      ...initialFormValuesStep5(projectTemplate),
      ...initialFormValuesStep6(projectTemplate),
    };
    initialFormValues['projectTemplateId'] = projectTemplateId;
    const schema = yup
      .object()
      .concat(schemaStep1(true))
      .concat(schemaStep2)
      .concat(schemaStep3)
      .concat(schemaStep4)
      .concat(schemaStep5)
      .concat(schemaStep6);

    const initialErrors =
      !initialFormValues.name ||
      !initialFormValues.sourceLocale ||
      !initialFormValues?.targetLocales?.length
        ? {
            name: initialFormValues.name ? null : I18n.t('The project name is required'),
            sourceLocale: initialFormValues.sourceLocale
              ? null
              : I18n.t('The source locale is required'),
            targetLocales: initialFormValues?.targetLocales?.length
              ? null
              : I18n.t('Choose at least one target locale'),
          }
        : undefined;
    return !isLoadingProjectTemplate &&
      !isLoadingTermBaseAssignments &&
      !isLoadingTransMemoryAssignments &&
      !isLoadingReferences &&
      !isLoadingMachineTranslateSettings ? (
      <PolarisFormik
        initialValues={initialFormValues}
        initialErrors={initialErrors}
        validationSchema={schema}
        validateOnChange={true}
        onSubmit={handleSubmit}
        render={renderForm}
      />
    ) : (
      <Spinner size="large" />
    );
  }
);
