import { HelpContentCollectionSpec } from './HelpContentRouter';
import I18n from '../setupI18n';

const PROJECT_EDIT_MAIN_HELP = 'project-edit-main';
const SETTINGS_MAIN_HELP = 'settings-main';

export const legacyPageHelpContent: HelpContentCollectionSpec = {
  [PROJECT_EDIT_MAIN_HELP]: {
    title: I18n.t('Project Edit'),
    content: I18n.t(`
The Project Edit page enables you to edit the project’s current configuration. 
Click **Save** at the bottom of the page to apply any changes you make. You can’t 
change the Source locale for the project. Any project changes will be applied to 
existing jobs in the project where applicable.
    `),
    internalLinks: [
      {
        url:
          'https://w.amazon.com/bin/view/ATMS/Docs/Projects_in_ATMS/#HAddFilesforTranslation28Jobs29',
        label: I18n.t('User documentation'),
      },
    ],
    paths: ['/web/project/create', '/web/project/edit/:uid'],
  },
  [SETTINGS_MAIN_HELP]: {
    title: I18n.t('Settings'),
    content: I18n.t(`
You can edit many of the same settings that you configured when creating a project. Unless you know
what you are doing, you can keep most defaults as they are. You can’t remove any workflow steps that
were selected during project creation; you can only add more workflow steps.

The Machine Translation, Project Status Automation, and Workflow Settings are the most important
settings to understand and manage.
    `),
    paths: ['/web/setup/index'],
  },
};

export { PROJECT_EDIT_MAIN_HELP };
