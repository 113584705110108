import React, { ReactElement, useReducer, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import I18n from '../../setupI18n';
import { Alert, Button, Spinner, Wizard } from '@amzn/awsui-components-react/polaris';
import {
  initialFormValuesStep1,
  ProjectCreateAPIStep1,
  ProjectCreateStep1Metadata,
  schemaStep1,
} from './ProjectCreateStep1Metadata';
import { FormikProps } from 'formik';
import { PolarisFormik } from '@amzn/et-polaris-utils';
import * as yup from 'yup';
import { ProjectCreateSummary } from './ProjectCreateSummary';
import {
  initialFormValuesStep3,
  ProjectCreateAPIStep3,
  ProjectCreateStep3Pretranslate,
  schemaStep3,
} from './ProjectCreateStep3Pretranslate';
import {
  initialFormValuesStep2,
  ProjectCreateAPIStep2,
  ProjectCreateStep2Assets,
  schemaStep2,
} from './ProjectCreateStep2Assets';
import {
  initialFormValuesStep4,
  ProjectCreateAPIStep4,
  ProjectCreateStep4Analysis,
  schemaStep4,
} from './ProjectCreateStep4Analysis';
import {
  initialFormValuesStep5,
  ProjectCreateAPIStep5,
  ProjectCreateStep5Quality,
  schemaStep5,
} from './ProjectCreateStep5Quality';
import {
  initialFormValuesStep6,
  ProjectCreateAPIStep6,
  ProjectCreateStep6AccessAndAutomation,
  schemaStep6,
} from './ProjectCreateStep6AccessAndAutomation';
import { WizardStepWithSummary, WizardWithSummary } from '../components/WizardWithSummary';
import { PROJECT_CREATE_REVIEW_AND_SAVE_HELP } from './projectHelpContent';
import { useWorkflowSteps } from '../resourceHooks/useWorkflowSteps';
import { useMediaTypes } from '../resourceHooks/useMediaTypes';
import { useSubjects } from '../resourceHooks/useSubjects';
import { useBusinessUnits } from '../resourceHooks/useBusinessUnits';
import { useClients } from '../resourceHooks/useClients';
import { useOwners } from '../resourceHooks/useOwners';
import { useCostCenters } from '../resourceHooks/useCostCenters';
import { useMachineTranslateSettings } from '../resourceHooks/useMachineTranslateSettings';
import { useActiveLanguages } from '../resourceHooks/useActiveLanguages';
import { useProject } from '../resourceHooks/useProject';
import { useScoringModels } from '../resourceHooks/useScoringModels';
import { useTermBases } from '../resourceHooks/useTermBases';
import { saveTBConfigs } from './termbases/TermBaseCommons';
import { saveTMConfig } from './translationmemory/TranslationMemoryCommon';
import { useTranslationMemories } from '../resourceHooks/useTranslationMemories';
import { useReferences } from '../resourceHooks/useReferences';
import { createReferences, deleteReferences } from './references/ReferenceCommons';
import { AtmsApiClient, AuthenticatedSession } from '@amzn/et-console-components';
import { publishCountMetric, publishLatencyMetric } from '../metricHelper';

interface ReducerState {
  isSavingProject: boolean;
  isError: boolean;
  error?: string;
  activeStep: number;
}

interface ProjectEditAPI
  extends ProjectCreateAPIStep1,
    ProjectCreateAPIStep2,
    ProjectCreateAPIStep3,
    ProjectCreateAPIStep4,
    ProjectCreateAPIStep5,
    ProjectCreateAPIStep6 {}

export interface Props extends RouteComponentProps {
  session: AuthenticatedSession;
}

export const ProjectEdit = withRouter(({ history, location: { pathname }, session }: Props) => {
  // TODO: We should be able to get this from the Router.match.params prop
  const projectUID = pathname.substr(pathname.lastIndexOf('/') + 1);

  const init = (): ReducerState => ({
    activeStep: 0,
    isSavingProject: false,
    isError: false,
  });

  const reducer = (state, action): ReducerState => {
    switch (action.type) {
      case 'activeStepChange':
        return {
          ...state,
          activeStep: action.activeStep,
        };
      case 'savingProject':
        return {
          ...state,
          isSavingProject: true,
        };
      case 'projectSaved':
        return {
          ...state,
          isSavingProject: false,
        };
      case 'projectSaveFailed':
        return {
          ...state,
          isSavingProject: false,
          isError: true,
          error: action.error,
        };
      case 'clearError':
        return {
          ...state,
          isError: false,
          error: undefined,
        };
      default:
        return state;
    }
  };

  const [reducerState, dispatch] = useReducer(reducer, null, init);
  const { isSavingProject, activeStep, isError, error } = reducerState;
  const [validating, setValidating] = useState(false);
  const { isLoadingProject, project } = useProject(projectUID);
  const { isLoadingWorkflowSteps, workflowSteps, workflowStepsById } = useWorkflowSteps();
  const { isLoadingClients, clients, clientsById } = useClients();
  const { isLoadingOwners, owners, ownersById } = useOwners();
  const { isLoadingCostCenters, costCenters, costCentersById } = useCostCenters();
  const { isLoadingBusinessUnits, businessUnits, businessUnitsById } = useBusinessUnits();
  const { isLoadingMediaTypes, mediaTypes, mediaTypesById } = useMediaTypes();
  const { isLoadingSubjects, subjects, subjectsById } = useSubjects();
  const { isLoadingActiveLanguages, activeLanguages, activeLanguagesByCode } = useActiveLanguages();
  const { isLoadingScoringModels, scoringModels, scoringModelsById } = useScoringModels();
  const {
    isLoadingMachineTranslateSettings,
    machineTranslateSettings,
    machineTranslateSettingsById,
  } = useMachineTranslateSettings();
  const { termBaseAssignments } = useTermBases(true, projectUID);
  const { translationMemoryAssignments } = useTranslationMemories(true, projectUID);
  const { references } = useReferences(true, projectUID);

  const handleSubmit = (values: ProjectEditAPI): void => {
    if (!project || !values.targetLocales) {
      return;
    }

    dispatch({ type: 'savingProject' });

    const {
      termBaseAssignments: newTermBaseAssignments,
      translationMemoryAssignments: newTranslationMemoryAssignments,
      references: newReferences,
      ...projectValues
    } = values;

    const request = {
      projectUid: projectUID,
      ...projectValues,
      note: values.note ? values.note : undefined, // It seems like the Textarea returns 'null' instead of 'undefined' for 'no value'
      perLocaleMachineTranslateSettingsIds: JSON.stringify(
        values.perLocaleMachineTranslateSettingsIds
      ),
      machineTranslateSettingsId: values.machineTranslateSettingsId,
      workflowStepIds: values.workflowStepIds.filter(id => workflowStepsById[id] !== undefined), // remove the deleted workflow step
    };

    const referencesToAdd = newReferences.filter(r => !r.id);
    const referencesToDelete = references?.filter(
      r => !newReferences.map(vr => vr.id).includes(r.id)
    );
    const initialTime = new Date();
    AtmsApiClient.httpPut(`/api/project`, request)
      .then(() => {
        Promise.all(
          saveTBConfigs(
            true,
            termBaseAssignments,
            newTermBaseAssignments,
            projectUID,
            values.targetLocales ?? []
          )
            .concat(createReferences(true, referencesToAdd, projectUID))
            .concat(deleteReferences(true, referencesToDelete))
            .concat(
              saveTMConfig(
                true,
                workflowSteps.filter(wfs => values.workflowStepIds.includes(wfs.id)),
                translationMemoryAssignments,
                newTranslationMemoryAssignments,
                projectUID,
                values.targetLocales
              )
            )
        ).then(() => {
          dispatch({ type: 'projectSaved' });
          history.push(`/web/project2/show/${projectUID}`);
        });
      })
      .catch(err => {
        publishCountMetric('projectSaveFailed-ProjectEdit', 'error', err.message);
        dispatch({
          type: 'projectSaveFailed',
          error: I18n.t('Failed to save project: %{e}', { e: JSON.stringify(err) }),
        });
      })
      .finally(() => {
        const finalTime = new Date();
        const numTargetLocales = values.targetLocales?.length ?? 1;
        const numOperations =
          newTermBaseAssignments.length * numTargetLocales +
          referencesToAdd.length +
          (referencesToDelete?.length ?? 0) +
          newTranslationMemoryAssignments.length * numTargetLocales * workflowSteps.length;
        publishLatencyMetric('projectEdit-Latency', initialTime, finalTime);
        publishLatencyMetric(
          'projectEdit-LatencyPerOperation',
          initialTime,
          finalTime,
          numOperations
        );
      });
  };

  /**
   * Per AWS Specifications, only start validating after the initial submit.
   * @param e the event
   * @param handleSubmit formik submit event handler function.
   */
  const handleStepChange = (e: CustomEvent<Wizard.StepClickDetail>, errors): void => {
    if (Object.values(errors).length > 0) {
      e.preventDefault();
    } else {
      dispatch({ type: 'activeStepChange', activeStep: e.detail.requestedStepIndex });
    }
    setValidating(true);
  };

  /**
   * Per AWS Specifications, only start validating after the initial submit.
   * @param e the event
   * @param handleSubmit formik submit event handler function.
   */
  const handleSubmitWithValidation = (e: CustomEvent<Button.ClickDetail>, handleSubmit): void => {
    e.preventDefault();
    setValidating(true);
    handleSubmit();
  };

  const renderForm = (props: FormikProps<ProjectEditAPI>): ReactElement => {
    const steps: WizardStepWithSummary[] = [
      ProjectCreateStep1Metadata({
        validating,
        ...props,
        isLoadingProject,
        isLoadingWorkflowSteps,
        isLoadingScoringModels,
        isLoadingMediaTypes,
        isLoadingSubjects,
        isLoadingClients,
        isLoadingOwners,
        isLoadingBusinessUnits,
        isLoadingCostCenters,
        isLoadingActiveLanguages,
        project,
        workflowSteps,
        workflowStepsById,
        scoringModels,
        scoringModelsById,
        mediaTypes,
        mediaTypesById,
        subjects,
        subjectsById,
        clients,
        clientsById,
        owners,
        ownersById,
        businessUnits,
        businessUnitsById,
        costCenters,
        costCentersById,
        activeLanguages,
        activeLanguagesByCode,
        showEditFields: true,
      }),
      ProjectCreateStep2Assets({
        validating,
        ...props,
        isProjectMode: true,
        uid: projectUID,
        workflowSteps,
      }),
      ProjectCreateStep3Pretranslate({
        session,
        validating,
        ...props,
        isLoadingMachineTranslateSettings,
        isLoadingActiveLanguages,
        machineTranslateSettings,
        machineTranslateSettingsById,
        activeLanguages,
        activeLanguagesByCode,
      }),
      ProjectCreateStep4Analysis({ validating, ...props }),
      ProjectCreateStep5Quality({ validating, ...props }),
      ProjectCreateStep6AccessAndAutomation({ validating, ...props }),
    ];
    const { handleSubmit, errors } = props;

    return (
      <form>
        {isError && (
          <Alert
            id={'editProjectError'}
            type="error"
            content={error}
            dismissible={true}
            onDismiss={(): void => dispatch({ type: 'clearError' })}
          />
        )}
        <WizardWithSummary
          steps={[
            ...steps,
            ProjectCreateSummary({
              ...props,
              children: steps.map(s => s.summary({ currentValue: project })),
              title: I18n.t('Review and save'),
              helpId: PROJECT_CREATE_REVIEW_AND_SAVE_HELP,
              description: I18n.t(
                'Review the project settings before saving the project. This view shows only fields that have changed.'
              ),
            }),
          ]}
          i18nStrings={{
            stepNumberLabel: (stepNumber: number): string =>
              I18n.t('Step %{stepNumber}', { stepNumber: stepNumber }),
            collapsedStepsLabel: (stepNumber: number, stepsCount: number): string =>
              I18n.t('Step %{stepNumber} of %{stepsCount}', {
                stepNumber: stepNumber,
                stepsCount: stepsCount,
              }),
            cancelButton: I18n.t('Cancel'),
            previousButton: I18n.t('Previous'),
            nextButton: I18n.t('Next'),
            submitButton: I18n.t('Save project'),
            optional: I18n.t('Optional'),
          }}
          onCancelButtonClick={() => history.goBack()}
          onSubmitButtonClick={() => handleSubmit()}
          onNextButtonClick={e => handleStepChange(e, errors)}
          onPreviousButtonClick={e => handleStepChange(e, errors)}
          onStepNavigationClick={e => handleStepChange(e, errors)}
          additionalButtons={[
            activeStep > 0 && activeStep < steps.length && (
              <Button
                key="reviewAndSave"
                formAction="none"
                onClick={(): void =>
                  handleStepChange(
                    new CustomEvent<Wizard.StepClickDetail>('stepClickDetail', {
                      detail: { requestedStepIndex: steps.length },
                    }),
                    errors
                  )
                }
              >
                {I18n.t('Review and save')}
              </Button>
            ),
            activeStep === 0 && (
              <Button
                key="save"
                formAction="none"
                onClick={e => handleSubmitWithValidation(e, handleSubmit)}
                loading={isSavingProject}
              >
                {I18n.t('Save project')}
              </Button>
            ),
          ].filter(b => b)}
          isSubmitting={isSavingProject}
          isLoadingNextStep={isSavingProject}
          activeStepIndex={activeStep}
        />
      </form>
    );
  };

  const initialFormValues = {
    ...initialFormValuesStep1(false, project, session),
    ...initialFormValuesStep2(termBaseAssignments, translationMemoryAssignments, references),
    ...initialFormValuesStep3(project),
    ...initialFormValuesStep4(project),
    ...initialFormValuesStep5(project),
    ...initialFormValuesStep6(project),
  };
  const schema = yup
    .object()
    .concat(schemaStep1(true))
    .concat(schemaStep2)
    .concat(schemaStep3)
    .concat(schemaStep4)
    .concat(schemaStep5)
    .concat(schemaStep6);

  return project && termBaseAssignments && translationMemoryAssignments && references ? (
    <PolarisFormik
      initialValues={initialFormValues}
      validationSchema={schema}
      validateOnChange={true}
      onSubmit={handleSubmit}
      render={renderForm}
    />
  ) : (
    <Spinner size="large" />
  );
});
