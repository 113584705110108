import React, { ReactElement, ReactNode, ReactNodeArray, useMemo } from 'react';
import { Checkbox, Table, TableSelection } from '@amzn/awsui-components-react/polaris';
import styled from '@emotion/styled';

const IconMargin = styled('span')`
  margin-right: 5px;
`;

const GroupHeader = styled('div')`
  color: var(--awsui-color-grey-600);
  font-weight: bold;
  text-transform: uppercase;
`;

const Indent = styled('div')`
  padding-left: 20px;
`;

const Empty = styled('div')`
  width: 550px;
  color: var(--awsui-color-text-empty);
`;

export interface HeaderProps {
  icon?: ReactNode;
  checkbox?: ReactNode;
  name: string;
}

export interface TableWithGroupByColumnProps<Item = Table.Item> extends Table.Props<Item> {
  groupByFunction: (item: any, groupByHeaderMap: any) => boolean;
  groupByHeaders: HeaderProps[];
  groupByEmptyMessage: string;
  children?: ReactNode | ReactNodeArray;
}

export interface GroupByHeader extends HeaderProps {
  groupByRow: boolean;
}

export interface GroupByEmpty {
  emptyMessage: string;
  groupByEmpty: boolean;
}

export type Row = GroupByHeader | GroupByEmpty | Table.Item;

/**
 * A table with customized group by/selection/filtering function
 * @param groupByField The field in @items which you want to group by
 * @param columnDefinitions The table column definition
 * @param groupByHeaders The group by header
 * @param groupByFunction A callback function to determine whether the item should go to which group
 * @param groupByEmptyMessage The message will show if that group is empty
 * @param children
 * @param items The actual table content
 * @param features features like selecting and filtering
 * @param props parent props
 * @constructor
 */
export const TableWithGroupByColumn = <Item extends {} = Table.Item>({
  groupByFunction,
  columnDefinitions,
  groupByHeaders,
  groupByEmptyMessage,
  children,
  items,
  features,
  ...props
}: TableWithGroupByColumnProps<Item>): ReactElement => {
  const featuresWithoutSelectionOrSorting =
    features?.filter(f => f !== 'selection' && f !== 'sorting') ?? [];
  let tableSelectionChild: ReactNode = undefined;
  let tableSortingChild: ReactNode = undefined;
  const childrenWithoutSelectionOrSorting: ReactNode[] = [];
  React.Children.map(children, child => {
    if (child?.['type']?.['displayName'] === 'TableSelection') {
      tableSelectionChild = child;
    } else if (child?.['type']?.['displayName'] === 'TableSorting') {
      tableSortingChild = child;
    } else {
      childrenWithoutSelectionOrSorting.push(child);
    }
  });
  const selectionProps: TableSelection.Props | undefined = (tableSelectionChild?.[
    'props'
  ] as unknown) as TableSelection.Props;

  if (tableSortingChild != null) {
    throw "TableWithGroupByColumn doesn't support the sorting feature";
  }

  const handleSelectionChange = (item: Item) => (e: CustomEvent<Checkbox.ChangeDetail>): void => {
    selectionProps?.onSelectionChange &&
      selectionProps.onSelectionChange(
        new CustomEvent<TableSelection.SelectionChangeDetail<Item>>('selectionChange', {
          detail: {
            selectedItems: [{ ...item, isSelected: e.detail.checked }],
          },
        })
      );
  };

  const buildTable = useMemo(() => {
    //Convert groupHeader string array to dict
    let groupHeaderMap = {};
    groupByHeaders.forEach(header => {
      groupHeaderMap[header.name] = [];
    });

    //Group the items
    (items ?? []).forEach(item => {
      groupHeaderMap = groupByFunction(item, groupHeaderMap);
    });

    //Build the table
    const table: any[] = [];
    groupByHeaders.forEach(header => {
      table.push({ ...header, groupByRow: true } as GroupByHeader);
      if (groupHeaderMap[header.name].length > 0) {
        groupHeaderMap[header.name].forEach(item => {
          table.push(item);
        });
      } else {
        table.push({ emptyMessage: groupByEmptyMessage, groupByEmpty: true } as GroupByEmpty);
      }
    });
    return table;
  }, [items]);

  /***************** Component *******************/
  const columnDefinitionWithUndefinedCheck = tableSelectionChild
    ? {
        cell: (item): ReactNode => {
          if (!item.groupByRow && !item.groupByEmpty) {
            return (
              <Checkbox checked={item.isSelected ?? false} onChange={handleSelectionChange(item)} />
            ); // TODO: This won't work with Polaris test selectors
          } else if ('groupByRow' in item && item.groupByRow && item.checkbox) {
            return item.checkbox;
          }
          return undefined;
        },
        width: 30,
      }
    : undefined;
  const columnDefinitionWithGroupHeader = {
    ...columnDefinitions?.[0],
    cell: (item): ReactNode => {
      if (item.groupByRow) {
        return item.icon ? (
          <GroupHeader>
            <IconMargin>{item.icon}</IconMargin>
            {item.name}
          </GroupHeader>
        ) : (
          <GroupHeader>{item.name}</GroupHeader>
        );
      } else if (item.groupByEmpty) {
        return (
          <Indent>
            <Empty>{item.emptyMessage}</Empty>
          </Indent>
        );
      }
      const originalCell = columnDefinitions?.[0]?.cell;
      const originalCellContent =
        typeof originalCell == 'function' ? originalCell(item) : originalCell;

      return <Indent>{originalCellContent}</Indent>;
    },
  };
  const otherColumnDefinitionWithoutGroupHeader =
    columnDefinitions?.slice(1)?.map(cd => ({
      ...cd,
      cell: (item): ReactNode => {
        if (!item.groupByRow && !item.groupByEmpty) {
          // @ts-ignore
          return cd.cell && cd.cell(item);
        } else {
          return undefined;
        }
      },
    })) ?? [];

  const columnDefinitionsWithUndefinedCheck: Table.ColumnDefinition[] = [
    columnDefinitionWithUndefinedCheck,
    columnDefinitionWithGroupHeader,
    ...otherColumnDefinitionWithoutGroupHeader,
  ].filter(f => f) as Table.ColumnDefinition[];

  return (
    <Table
      {...props}
      items={buildTable}
      columnDefinitions={columnDefinitionsWithUndefinedCheck}
      features={featuresWithoutSelectionOrSorting}
    >
      {childrenWithoutSelectionOrSorting}
    </Table>
  );
};
