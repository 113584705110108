import React from 'react';
import PropTypes from 'prop-types';
import { Button, Alert } from '@amzn/awsui-components-react/polaris';
import I18n from '../setupI18n';
import { publishCountMetric, publishErrorMetric } from '../App/metricHelper';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, autoResetOn: props.autoResetOn };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.autoResetOn !== state.autoResetOn) {
      return { error: null, autoResetOn: props.autoResetOn };
    }
    return null;
  }

  static getDerivedStateFromError(error) {
    return { error: error.toString() };
  }

  render() {
    const { errorScreenClassName } = this.props;
    const { error } = this.state;
    if (error) {
      publishCountMetric('ErrorBoundaryLoadPageError', 'error', error.message);
      // You can render any custom fallback UI
      return (
        <ErrorScreen
          className={errorScreenClassName}
          title={I18n.t('There was an error loading this page')}
          action={
            <Button text={I18n.t('Try again')} onClick={() => this.setState({ error: null })} />
          }
          message={
            <div translate="yes">
              <span role="img" aria-label="frowny face">
                😞
              </span>{' '}
              Unexpected error: {error}
              <br />
              <br />
              If you keep getting this error, you can <a href="/">return to the main page</a> or
              clear your cookies.
            </div>
          }
        />
      );
    }

    return this.props.children;
  }
}
ErrorBoundary.propTypes = {
  errorScreenClassName: PropTypes.string,
};

const ErrorScreen = ({ title, action, message, className }) => (
  <div css={{ height: '100%', width: '100%', display: 'grid' }} className={className}>
    <div css={{ margin: 'auto', minWidth: '50%' }}>
      <Alert
        header={
          <div css={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>{title}</div>
            {action && <div>{action}</div>}
          </div>
        }
        type="error"
      >
        {message}
      </Alert>
    </div>
  </div>
);
