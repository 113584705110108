import React, { ReactElement, useCallback, useEffect, useReducer, useState } from 'react';
import { PageHeader, PolarisFormik } from '@amzn/et-polaris-utils';
import * as yup from 'yup';
import {
  Button,
  Checkbox,
  ColumnLayout,
  CustomDetailEvent,
  Flash,
  Form,
  FormField,
  FormSection,
  Select,
  Input,
  Textarea,
} from '@amzn/awsui-components-react/polaris';
import I18n from '../../setupI18n';
import styled from '@emotion/styled';
import { History, Location } from 'history';
import { withRouter } from 'react-router';
import { css } from 'emotion';
import { AtmsApiClient } from '@amzn/et-console-components';
import { SelectAllOnServerApi } from '../types/commonTypes';
import { publishCountMetric } from '../metricHelper';
import { getAppHostConfig } from '@amzn/et-console-components';

const { WEB_HOST_AND_PORT } = getAppHostConfig();

const formSpacing = css`
  padding-top: 14px;
  padding-bottom: 14px;
`;

const FormContainer = styled('div')`
  max-width: 800px;
  min-width: 280px;
`;

export interface EmailJobsWorkflowState extends SelectAllOnServerApi {
  emailTo: string;
}

export interface Props {
  location: Location<EmailJobsWorkflowState>;
  history: History;
}

export interface EmailJobsApi {
  emailJobsProgress: boolean;
  emailJobsToDefault: boolean;
  overrideSettings: boolean;
  emailJobsTemplate?: number;
  emailJobsTo?: string;
  emailJobsCc?: string;
  emailJobsBcc?: string;
  emailJobsSubject: string;
  emailJobsBody: string;
  setStatusToEmailed: boolean;
}

interface EmailTemplate {
  id?: number;
  type?: string;
  name: string;
  subject: string;
  body: string | null;
  ccAddress: string | null;
  bccAddress: string | null;
}

interface ReducerState extends EmailJobsApi {
  isLoadingEmailTemplates: boolean;
  isSendingEmail: boolean;
  emailTemplates: EmailTemplate[];
  isError: boolean;
  error?: string;
}

export const EmailJobsWorkflow = withRouter(({ location: { pathname, state }, history }: Props) => {
  // TODO: We should be able to get this from the Router.match.params prop
  const projectUID = pathname.substr(pathname.lastIndexOf('/') + 1);

  const init = (): ReducerState => ({
    isLoadingEmailTemplates: false,
    isSendingEmail: false,
    emailTemplates: [],
    isError: false,
    emailJobsProgress: true,
    emailJobsToDefault: true,
    overrideSettings: true,
    emailJobsTemplate: undefined,
    // This value is for display only; it isn't used by the server
    emailJobsTo: state.selectAllOnServer ? `${state.emailTo}...` : state.emailTo,
    emailJobsCc: undefined,
    emailJobsBcc: undefined,
    emailJobsSubject: '',
    emailJobsBody: '',
    setStatusToEmailed: true,
  });

  const reducer = (state, action): ReducerState => {
    switch (action.type) {
      case 'loadingEmailTemplates':
        return {
          ...state,
          isLoadingEmailTemplates: true,
          isError: false,
        };
      case 'finishedLoadingEmailTemplates':
        return {
          ...state,
          isLoadingEmailTemplates: false,
          emailTemplates: action.emailTemplates,
        };
      case 'loadingEmailTemplatesFailed':
        return {
          ...state,
          isLoadingEmailTemplates: false,
          isError: true,
          error: action.error,
        };
      case 'selectEmailTemplate':
        return {
          ...state,
          emailJobsCc: action.emailJobsCc,
          emailJobsBcc: action.emailJobsBcc,
          emailJobsSubject: action.emailJobsSubject,
          emailJobsBody: action.emailJobsBody,
        };
      case 'emailSending':
        return {
          ...state,
          isSendingEmail: true,
          isError: false,
        };
      case 'emailSent':
        return {
          ...state,
          isSendingEmail: false,
        };
      case 'emailSendFailed':
        return {
          ...state,
          isSendingEmail: false,
          isError: true,
          error: action.error,
        };
      default:
        return state;
    }
  };

  const [reducerState, dispatch] = useReducer(reducer, null, init);
  const { isLoadingEmailTemplates, emailTemplates, isSendingEmail } = reducerState;
  const [validating, setValidating] = useState(false);

  const handleLoadEmailTemplates = useCallback(() => {
    dispatch({ type: 'loadingEmailTemplates' });

    AtmsApiClient.httpGet(`//${WEB_HOST_AND_PORT}/web/api/v9/emailTemplate/list?type=JobAssigned`)
      .then(result => {
        dispatch({ type: 'finishedLoadingEmailTemplates', emailTemplates: result });
      })
      .catch(err => {
        publishCountMetric('loadingEmailTemplatesFailed-EmailJobsWorkflow', 'error', err.message);
        dispatch({ type: 'loadingEmailTemplatesFailed', error: err });
      });
  }, []);

  /**
   * Per AWS Specifications, only start validating after the initial submit.
   * @param e the event
   * @param handleSubmit formik submit event handler function.
   */
  const enableValidation = (e, handleSubmit): void => {
    e.preventDefault();
    setValidating(true);
    handleSubmit();
  };

  const handleSubmit = (values: EmailJobsApi): void => {
    dispatch({ type: 'emailSending' });

    const request = {
      projectUid: projectUID,
      jobPartIds: state.jobPartIds,
      selectAllOnServer: state.selectAllOnServer,
      level: state.level,
      assignedTo: state.assignedTo,
      status: state.status,
      fileName: state.fileName,
      targetLocale: state.targetLocale,
      dueInHours: state.dueInHours,

      subject: values.emailJobsSubject,
      body: values.emailJobsBody,
      cc: values.emailJobsCc && values.emailJobsCc.split(';').map(v => v.trim()),
      bcc: values.emailJobsBcc && values.emailJobsBcc.split(';').map(v => v.trim()),
      setStatusToEmailed: values.setStatusToEmailed,
    };

    AtmsApiClient.httpPost('/api/job/email', request)
      .then(() => {
        dispatch({ type: 'emailSent' });
        history.push(`/web/project2/show/${projectUID}`);
      })
      .catch(err => {
        publishCountMetric('emailSendFailed-EmailJobsWorkflow', 'error', err.message);
        dispatch({ type: 'emailSendFailed', error: err });
      });
  };

  useEffect(handleLoadEmailTemplates, [handleLoadEmailTemplates]);

  const emailTemplatesPlusNone: EmailTemplate[] = [
    { id: undefined, name: 'None', ccAddress: '', bccAddress: '', subject: '', body: '' },
    ...emailTemplates,
  ];

  const renderForm = ({
    values,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
  }): ReactElement => {
    const handleUpdateTemplate = (event: CustomDetailEvent<Select.ChangeDetail>): void => {
      const template = emailTemplatesPlusNone.find(
        t => t.id === (event.detail.selectedId && parseInt(event.detail.selectedId))
      );
      setFieldValue('emailJobsCc', template?.ccAddress);
      setFieldValue('emailJobsBcc', template?.bccAddress);
      setFieldValue('emailJobsSubject', template?.subject);
      setFieldValue('emailJobsBody', template?.body);

      handleChange(event);
    };

    return (
      <FormContainer>
        <form onSubmit={e => enableValidation(e, handleSubmit)}>
          <Form
            header={<PageHeader title={I18n.t('Email')} />}
            actions={
              <div>
                <Button
                  id="email"
                  text={I18n.t('Email')}
                  variant="primary"
                  onClick={e => enableValidation(e, handleSubmit)}
                  disabled={isSendingEmail}
                  loading={isSendingEmail}
                />
              </div>
            }
            errorText={
              Object.keys(errors).length !== 0
                ? I18n.t('The form contains errors. Fix them and resubmit.')
                : ''
            }
          >
            <FormSection>
              <ColumnLayout>
                <div data-awsui-column-layout-root="true">
                  <FormField label={I18n.t('Use template')}>
                    <Select
                      loading={isLoadingEmailTemplates}
                      options={emailTemplatesPlusNone.map(emailTemplate => {
                        return {
                          id: '' + emailTemplate?.id,
                          label: emailTemplate?.name,
                        };
                      })}
                      selectedId={'' + values?.emailJobsTemplate}
                      onChange={handleUpdateTemplate}
                      onBlur={handleBlur}
                      controlId="emailJobsTemplate"
                      id="emailJobsTemplate"
                    />
                  </FormField>
                  <FormField label={I18n.t('To')}>
                    <Input
                      id="emailJobsTo"
                      name="emailJobsTo"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.emailJobsTo}
                      disabled={true}
                    />
                  </FormField>
                  <FormField
                    label={I18n.t('Cc')}
                    hintText={I18n.t("Separate multiple email addresses with ';'")}
                  >
                    <Input
                      id="emailJobsCc"
                      name="emailJobsCc"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.emailJobsCc}
                    />
                  </FormField>
                  <FormField
                    label={I18n.t('Bcc')}
                    hintText={I18n.t("Separate multiple email addresses with ';'")}
                  >
                    <Input
                      id="emailJobsBcc"
                      name="emailJobsBcc"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.emailJobsBcc}
                    />
                  </FormField>
                  <FormField
                    label={I18n.t('Subject')}
                    errorText={errors.emailJobsSubject && I18n.t('The subject may not be blank')}
                  >
                    <Input
                      id="emailJobsSubject"
                      name="emailJobsSubject"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.emailJobsSubject}
                    />
                  </FormField>
                  <FormField
                    label={I18n.t('Body')}
                    errorText={errors.emailJobsBody && I18n.t('The email body may not be blank')}
                  >
                    <Textarea
                      id="emailJobsBody"
                      name="emailJobsBody"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.emailJobsBody}
                      rows={10}
                    />
                  </FormField>
                  <FormField>
                    <Checkbox
                      id="setStatusToEmailed"
                      name="setStatusToEmailed"
                      label={I18n.t("Set status to 'Emailed'")}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values.setStatusToEmailed}
                    />
                  </FormField>
                </div>
              </ColumnLayout>
            </FormSection>
            {reducerState.isError && (
              <div className={formSpacing}>
                <Flash
                  id="validation-error"
                  type="error"
                  dismissible={true}
                  content={I18n.t('Please try again. If the issue persists, contact support.')}
                  header={I18n.t('Something went wrong.')}
                />
              </div>
            )}
          </Form>
        </form>
      </FormContainer>
    );
  };

  const initialFormValues: EmailJobsApi = {
    emailJobsProgress: reducerState.emailJobsProgress,
    emailJobsToDefault: reducerState.emailJobsToDefault,
    overrideSettings: reducerState.overrideSettings,
    emailJobsTemplate: reducerState.emailJobsTemplate,
    emailJobsTo: reducerState.emailJobsTo,
    emailJobsCc: reducerState.emailJobsCc,
    emailJobsBcc: reducerState.emailJobsBcc,
    emailJobsSubject: reducerState.emailJobsSubject,
    emailJobsBody: reducerState.emailJobsBody,
    setStatusToEmailed: reducerState.setStatusToEmailed,
  };

  const schema = yup.object().shape({
    useMachineTranslation: yup.boolean(),
    preTranslateNonTranslatables: yup.boolean(),
    overwrite: yup.boolean(),
    emailJobsProgress: yup.boolean(),
    emailJobsToDefault: yup.boolean(),
    overrideSettings: yup.boolean(),
    emailJobsTemplate: yup.number(),
    emailJobsTo: yup.string(),
    emailJobsCc: yup.string().nullable(),
    emailJobsBcc: yup.string().nullable(),
    emailJobsSubject: yup.string().required(),
    emailJobsBody: yup.string().required(),
    setStatusToEmailed: yup.boolean(),
  });

  return (
    <PolarisFormik
      initialValues={initialFormValues}
      validationSchema={schema}
      validateOnChange={validating}
      onSubmit={handleSubmit}
      render={renderForm}
    />
  );
});
