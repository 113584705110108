import { MediaType } from '../types/commonTypes';
import { useEffect, useState } from 'react';
import { AtmsApiClient } from '@amzn/et-console-components';
import { getAppHostConfig } from '@amzn/et-console-components';

const { WEB_HOST_AND_PORT } = getAppHostConfig();

interface State {
  isLoadingSubjects: boolean;
  subjects: MediaType[];
  subjectsById: { [id: string]: MediaType };
}

// TODO: Needs to reload when the user profile changes
export const useSubjects = (): State => {
  const [state, setState] = useState<State>({
    isLoadingSubjects: true,
    subjects: [],
    subjectsById: {},
  });
  useEffect(() => {
    AtmsApiClient.httpGet(`//${WEB_HOST_AND_PORT}/web/api/v9/subject/list`).then(result => {
      setState({
        isLoadingSubjects: false,
        subjects: result,
        subjectsById: result.reduce((acc, val) => {
          acc[val.id] = val;
          return acc;
        }, {}),
      });
    });
  }, []);

  return state;
};
