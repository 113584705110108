import React, { ReactElement, useEffect, useState } from 'react';
import { StandardModal } from './StandardModal';
import { Table, Button } from '@amzn/awsui-components-react/polaris';
import I18n from '../../setupI18n';
import { CellContents, SavedFilter } from '../types/commonTypes';

interface Props {
  modalId?: string;
  prefsTableId?: string;
  okButtonId?: string;
  visible: boolean;
  onDismiss: () => void;
  onApply: (newFilters: SavedFilter[]) => void;
  savedFilters: SavedFilter[];
}

export const ManageFiltersModal = ({
  modalId,
  prefsTableId,
  okButtonId,
  visible,
  onDismiss,
  onApply,
  savedFilters,
}: Props): ReactElement => {
  const [newSavedFilters, setNewSavedFilters] = useState([] as SavedFilter[]);

  useEffect(() => {
    setNewSavedFilters(savedFilters);
  }, [savedFilters]);

  const prepDelete = (i: number) => {
    return (): void => {
      const newSavedFiltersCopy = [...newSavedFilters];
      newSavedFiltersCopy.splice(i, 1);
      setNewSavedFilters(newSavedFiltersCopy);
    };
  };

  const handleCancelClick = (): void => {
    onDismiss();
  };

  const handleOkClick = (): void => {
    onDismiss();
    onApply(newSavedFilters);
  };

  const columnDef: Table.ColumnDefinition<{ index: number; filter: SavedFilter }>[] = [
    {
      id: 'name',
      header: I18n.t('Name'),
      cell: (item): CellContents => item.filter.text,
    },
    {
      id: 'delete',
      header: '',
      cell: (item): CellContents => (
        <Button text={I18n.t('Delete')} onClick={prepDelete(item.index)} />
      ),
    },
  ];

  return (
    <StandardModal
      id={modalId}
      header={I18n.t('Manage saved filters')}
      content={
        <Table
          id={prefsTableId}
          header={I18n.t('Saved filters')}
          items={newSavedFilters ? newSavedFilters.map((v, i) => ({ index: i, filter: v })) : []}
          columnDefinitions={columnDef}
          variant="borderless"
        />
      }
      visible={visible}
      handleCancelClick={handleCancelClick}
      handleOkClick={handleOkClick}
      okButtonId={okButtonId}
    />
  );
};
