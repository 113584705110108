import React, { Fragment, ReactElement, ReactNode } from 'react';

export interface Props {
  children: ReactNode;
}

/**
 * Exists as an auto-translated element; configured in .i18nrc
 * @param children
 * @constructor
 */
export const T = ({ children }: Props): ReactElement => {
  return <Fragment>{children}</Fragment>;
};
