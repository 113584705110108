import React from 'react';
import { T } from './components/T';
import { ContentLayout } from '@amzn/awsui-components-react-v3';
import { useSession } from '@amzn/et-console-components';
import { AuthenticatedSession } from '@amzn/et-console-components/dist/hooks/useSession';
import styled from '@emotion/styled';

// IMPORTANT: If you change the contents of the privacy policy, make sure you update this value to re-trigger the
// consent for all users.
export const dateLateUpdated = new Date('2024-06-25');

const ContentContainer = styled('div')`
  max-width: 800px;
  min-width: 280px;
`;

export const PrivacyPolicy = (): JSX.Element => {
  const session = useSession() as AuthenticatedSession;
  const internalDsarMessage = '';
  const externalDsarMessage = (
    <T>
      <h2>Personal data access</h2>
      <p>
        Data Subject Access Requests (DSAR) for ATMS data should be directed to us using the
        following steps:
      </p>
      <ul>
        <li>
          Visit <a href={'/web/tickets/list'}>the ATMS support page</a>.
        </li>
        <li>Click on 'Create Ticket'.</li>
        <li>Select 'Data subject access request (DSAR)' from the 'Problem type' dropdown.</li>
        <li>Fill out the form.</li>
        <li>Click 'Submit'.</li>
      </ul>
    </T>
  );

  return (
    <ContentContainer>
      <ContentLayout>
        <T>
          <h1>Privacy policy</h1>
          <h2>Last updated {dateLateUpdated.toLocaleDateString()}</h2>
          <p>
            ATMS is subject to the{' '}
            <a
              href={
                'https://www.amazon.co.uk/gp/help/customer/display.html?nodeId=GX7NJQ4ZB8MHFRNJ'
              }
            >
              Amazon.co.uk privacy policy
            </a>
            . We do not share the identity of ATMS users with any third parties for any reason,
            except where required by law. We will not contact you using the email address associated
            with ATMS for any reason except those necessary to facilitate the operation of ATMS.
          </p>
          <h2>Cookies</h2>
          <p>
            We use cookies to enable single sign-on among the ATMS-owned sites. These cookies are
            required for the correct functioning of the sites.
          </p>
        </T>
        {session.user.email.includes('@amazon.') ? internalDsarMessage : externalDsarMessage}
      </ContentLayout>
    </ContentContainer>
  );
};
