import { useEffect, useState } from 'react';
import { AtmsApiClient } from '@amzn/et-console-components';
import { User, Vendor } from '../types/commonTypes';
import { stringify } from 'query-string';
import { getAppHostConfig } from '@amzn/et-console-components';

const { WEB_HOST_AND_PORT } = getAppHostConfig();

interface FoundUsers {
  other: User[];
  vendors: Vendor[];
  recent: User[];
  discovered: User[];
}

interface State {
  isLoadingUsers: boolean;
  foundUsers: FoundUsers;
  usersById: { [id: number]: User };
  vendorsById: { [id: number]: Vendor };
}

// TODO: Needs to reload when the user profile changes
export const useUsersAndVendorsForJobAssign = (): State => {
  const [state, setState] = useState<State>({
    isLoadingUsers: true,
    foundUsers: { other: [], vendors: [], recent: [], discovered: [] },
    usersById: {},
    vendorsById: {},
  });
  useEffect(() => {
    const query = {
      // TODO: Get source locale, target locale, workflow step, and client for additional filtering
      // sourceLocale: getUniqueOrUndefined(jobParts.map(jp => jp.sourceLocale)),
      // targetLocale: getUniqueOrUndefined(jobParts.map(jp => jp.targetLocale)),
      // workflowStep: getUniqueOrUndefined(
      //   jobParts.map(jp => jp.workflowStep && jp.workflowStep.id)
      // ),
      // client: getUniqueOrUndefined(jobParts.map(jp => jp.client && jp.client.id)),
    };

    AtmsApiClient.httpGet(
      `//${WEB_HOST_AND_PORT}/web/api/v9/job/getUsersAndVendorsForJobAssign?${stringify(query)}`
    ).then(result => {
      const foundUsers = result as FoundUsers;
      setState({
        isLoadingUsers: false,
        foundUsers: foundUsers,
        usersById: [...foundUsers.other, ...foundUsers.recent, ...foundUsers.discovered].reduce(
          (acc, val) => {
            acc[val.id] = val;
            return acc;
          },
          {}
        ),
        vendorsById: foundUsers.vendors.reduce((acc, val) => {
          acc[val.id] = val;
          return acc;
        }, {}),
      });
    });
  }, []);

  return state;
};
