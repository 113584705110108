import React, { ReactElement, ReactNode, useReducer } from 'react';
import * as yup from 'yup';
import {
  Checkbox,
  ColumnLayout,
  Flash,
  Form,
  FormField,
  FormSection,
  Icon,
  Input,
  Select,
} from '@amzn/awsui-components-react/polaris';
import I18n from '../../setupI18n';
import styled from '@emotion/styled';
import { css } from 'emotion';
import { HelpInfoLink } from '../HelpContentRouter';
import { PROJECT_CREATE_ACCESS_AND_AUTOMATION_HELP } from './projectHelpContent';
import { FormikProps } from 'formik';
import { T } from '../components/T';
import { Project, ProjectTemplate } from '../types/commonTypes';
import { coalesceSummaryValue, WizardStepWithSummary } from '../components/WizardWithSummary';
import { Container } from '@amzn/et-polaris-utils';

const formSpacing = css`
  padding-top: 14px;
  padding-bottom: 14px;
`;

const FormContainer = styled('div')`
  max-width: 800px;
  min-width: 280px;
`;

const InlineContainer = styled('div')`
  display: inline-block;
`;

const InlineSelectContainer = styled('div')`
  display: inline-block;
  vertical-align: revert;
  margin-left: 4px;
`;

const InlineInputContainer = styled('div')`
  display: inline-block;
  margin-left: 4px;
  width: 95%;
`;

export interface Props<T extends ProjectCreateAPIStep6> extends FormikProps<T> {
  validating: boolean;
}

export interface ProjectCreateAPIStep6 {
  allowLinguistsToUseTheWebEditor: boolean;
  allowLinguistsToDownloadTranslationJobs: boolean;
  allowLinguistsToViewUserMetadataInTheWebEditor: boolean;
  preventCompletingJobsWithEmptyTargets: boolean;
  setProjectToAssignedEnabled: boolean;
  setProjectToAssignedJobStatus: 'Emailed' | 'Accepted';
  setProjectToCompletedEnabled: boolean;
  setProjectToCompletedJobStatus: 'Completed' | 'Delivered' | 'CompletedOrDelivered';
  setProjectToCompletedWhenProjectIsCompletedByVendor: boolean;
  setProjectToCanceledEnabled: boolean;
  setProjectToCanceledJobStatus: 'Canceled';
  setRenameCompletedFilesToEnabled: boolean;
  renameCompletedFilesTo: string;
  deliverNotificationsByEmail: boolean;
  notifyWhenPreviousStepIs: 'Completed' | 'Delivered';
}

interface ReducerState {
  isError: boolean;
  error?: string;
}

export const schemaStep6 = yup.object().shape({});

export const initialFormValuesStep6 = (
  currentValue?: Project | ProjectTemplate
): ProjectCreateAPIStep6 => {
  return {
    allowLinguistsToUseTheWebEditor: currentValue?.allowLinguistsToUseTheWebEditor ?? true,
    allowLinguistsToDownloadTranslationJobs:
      currentValue?.allowLinguistsToDownloadTranslationJobs ?? true,
    allowLinguistsToViewUserMetadataInTheWebEditor:
      currentValue?.allowLinguistsToViewUserMetadataInTheWebEditor ?? true,
    preventCompletingJobsWithEmptyTargets:
      currentValue?.preventCompletingJobsWithEmptyTargets ?? false,
    setProjectToAssignedEnabled: currentValue?.setProjectToAssignedEnabled ?? true,
    setProjectToAssignedJobStatus: currentValue?.setProjectToAssignedJobStatus ?? 'Emailed',
    setProjectToCompletedEnabled: currentValue?.setProjectToCompletedEnabled ?? true,
    setProjectToCompletedJobStatus: currentValue?.setProjectToCompletedJobStatus ?? 'Completed',
    setProjectToCompletedWhenProjectIsCompletedByVendor:
      currentValue?.setProjectToCompletedWhenProjectIsCompletedByVendor ?? true,
    setProjectToCanceledEnabled: currentValue?.setProjectToCanceledEnabled ?? true,
    setProjectToCanceledJobStatus: currentValue?.setProjectToCanceledJobStatus ?? 'Canceled',
    setRenameCompletedFilesToEnabled: currentValue?.setRenameCompletedFilesToEnabled ?? true,
    renameCompletedFilesTo:
      currentValue?.renameCompletedFilesTo ??
      '{path}/{fileName}-{sourceLang}-{targetLang}-{workflow}-{status}',
    deliverNotificationsByEmail: currentValue?.deliverNotificationsByEmail ?? true,
    notifyWhenPreviousStepIs: currentValue?.notifyWhenPreviousStepIs ?? 'Completed',
  };
};

const Content = <T extends ProjectCreateAPIStep6>({
  values,
  errors,
  validating,
  handleChange,
  handleBlur,
}: Props<T>): ReactElement => {
  const init = (): ReducerState => ({
    isError: false,
  });

  const reducer = (state, action): ReducerState => {
    switch (action.type) {
      default:
        return state;
    }
  };

  const [reducerState] = useReducer(reducer, null, init);

  return (
    <FormContainer>
      <Form
        errorText={
          validating && Object.keys(errors).length !== 0
            ? I18n.t('The form contains errors. Fix them and resubmit.')
            : ''
        }
      >
        <FormSection header={I18n.t('Access')}>
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField>
                <Checkbox
                  id={'preventCompletingJobsWithEmptyTargets'}
                  controlId={'preventCompletingJobsWithEmptyTargets'}
                  label={I18n.t('Prevent jobs from being completed with empty targets')}
                  checked={values.preventCompletingJobsWithEmptyTargets}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField label={I18n.t('Allow linguists to...')}>
                <Checkbox
                  label={I18n.t('Use the Web Editor')}
                  id="allowLinguistsToUseTheWebEditor"
                  controlId="allowLinguistsToUseTheWebEditor"
                  checked={values.allowLinguistsToUseTheWebEditor}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Checkbox
                  label={I18n.t('Download translation jobs')}
                  id="allowLinguistsToDownloadTranslationJobs"
                  controlId="allowLinguistsToDownloadTranslationJobs"
                  checked={values.allowLinguistsToDownloadTranslationJobs}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Checkbox
                  label={I18n.t('View user metadata in Web Editor')}
                  id="allowLinguistsToViewUserMetadataInTheWebEditor"
                  controlId="allowLinguistsToViewUserMetadataInTheWebEditor"
                  checked={values.allowLinguistsToViewUserMetadataInTheWebEditor}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </FormSection>
        <FormSection header={I18n.t('Automation')}>
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <T>
                <h4>Project status</h4>
              </T>
              <FormField label={I18n.t('Set project to Assigned when...')}>
                <InlineContainer>
                  <Checkbox
                    label={I18n.t('All jobs are')}
                    checked={values.setProjectToAssignedEnabled}
                    id="setProjectToAssignedEnabled"
                    controlId="setProjectToAssignedEnabled"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </InlineContainer>
                <InlineSelectContainer>
                  <Select
                    options={[
                      { label: I18n.t('Emailed'), id: 'Emailed' },
                      { label: I18n.t('Accepted'), id: 'Accepted' },
                    ]}
                    disabled={!values.setProjectToAssignedEnabled}
                    selectedId={values.setProjectToAssignedJobStatus}
                    id="setProjectToAssignedJobStatus"
                    controlId="setProjectToAssignedJobStatus"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </InlineSelectContainer>
              </FormField>
              <FormField label={I18n.t("Set project to 'Completed' when...")}>
                <InlineContainer>
                  <Checkbox
                    label={I18n.t('All jobs are')}
                    checked={values.setProjectToCompletedEnabled}
                    id="setProjectToCompletedEnabled"
                    controlId="setProjectToCompletedEnabled"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </InlineContainer>
                <InlineSelectContainer>
                  <Select
                    options={[
                      { label: I18n.t('Completed'), id: 'Completed' },
                      { label: I18n.t('Delivered'), id: 'Delivered' },
                      { label: I18n.t('Completed OR delivered'), id: 'CompletedOrDelivered' },
                    ]}
                    disabled={!values.setProjectToCompletedEnabled}
                    selectedId={values.setProjectToCompletedJobStatus}
                    id="setProjectToCompletedJobStatus"
                    controlId="setProjectToCompletedJobStatus"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </InlineSelectContainer>
                <Checkbox
                  label={I18n.t("Project state is 'Completed by Vendor'")}
                  id="setProjectToCompletedWhenProjectIsCompletedByVendor"
                  controlId="setProjectToCompletedWhenProjectIsCompletedByVendor"
                  checked={values.setProjectToCompletedWhenProjectIsCompletedByVendor}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField label={I18n.t("Set project to 'Canceled' when...")}>
                <InlineContainer>
                  <Checkbox
                    label={I18n.t('All jobs are')}
                    checked={values.setProjectToCanceledEnabled}
                    id="setProjectToCanceledEnabled"
                    controlId="setProjectToCanceledEnabled"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </InlineContainer>
                <InlineSelectContainer>
                  <Select
                    options={[{ label: I18n.t('Canceled'), id: 'Canceled' }]}
                    disabled={!values.setProjectToCanceledEnabled}
                    selectedId={values.setProjectToCanceledJobStatus}
                    id="setProjectToCanceledJobStatus"
                    controlId="setProjectToCanceledJobStatus"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </InlineSelectContainer>
              </FormField>
              <T>
                <h4>File renaming</h4>
              </T>
              <FormField label={I18n.t('Rename completed files to')}>
                <InlineContainer>
                  <Checkbox
                    checked={values.setRenameCompletedFilesToEnabled}
                    id="setRenameCompletedFilesToEnabled"
                    controlId="setRenameCompletedFilesToEnabled"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </InlineContainer>
                <InlineInputContainer>
                  <Input
                    id="renameCompletedFilesTo"
                    disabled={!values.setRenameCompletedFilesToEnabled}
                    controlId="renameCompletedFilesTo"
                    value={values.renameCompletedFilesTo}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </InlineInputContainer>
              </FormField>
              <T>
                <h4>Notifications</h4>
              </T>
              <FormField>
                <Checkbox
                  label={I18n.t('Deliver by email')}
                  id="deliverNotificationsByEmail"
                  controlId="deliverNotificationsByEmail"
                  checked={values.deliverNotificationsByEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField label={I18n.t('Notify when previous step is...')}>
                <Select
                  options={[
                    { label: I18n.t('Completed'), id: 'Completed' },
                    { label: I18n.t('Delivered'), id: 'Delivered' },
                  ]}
                  selectedId={values.notifyWhenPreviousStepIs}
                  controlId="notifyWhenPreviousStepIs"
                  id="notifyWhenPreviousStepIs"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </FormSection>
        {reducerState.isError && (
          <div className={formSpacing}>
            <Flash
              id="validation-error"
              type="error"
              dismissible={true}
              content={I18n.t('Please try again. If the issue persists, contact support.')}
              header={I18n.t('Something went wrong.')}
            />
          </div>
        )}
      </Form>
    </FormContainer>
  );
};

export const ProjectCreateStep6AccessAndAutomation = <T extends ProjectCreateAPIStep6>(
  props: Props<T>
): WizardStepWithSummary => {
  const title = I18n.t('Access and automation');
  return {
    title: title,
    info: (): ReactElement => <HelpInfoLink helpId={PROJECT_CREATE_ACCESS_AND_AUTOMATION_HELP} />,
    description: I18n.t('Configure permissions controlling project access'),
    content: (): ReactElement => <Content {...props} />,
    isOptional: true,
    summary: ({ currentValue }): ReactNode => {
      const { values } = props;
      const summaryElements = [
        coalesceSummaryValue({
          currentValue: currentValue?.allowLinguistsToUseTheWebEditor,
          value: values.allowLinguistsToUseTheWebEditor,
          label: I18n.t('Allow linguists to use the web editor'),
          displayValue: values.allowLinguistsToUseTheWebEditor ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.allowLinguistsToDownloadTranslationJobs,
          value: values.allowLinguistsToDownloadTranslationJobs,
          label: I18n.t('Allow linguists to download translation jobs'),
          displayValue: values.allowLinguistsToDownloadTranslationJobs ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.allowLinguistsToViewUserMetadataInTheWebEditor,
          value: values.allowLinguistsToViewUserMetadataInTheWebEditor,
          label: I18n.t('Allow linguists to view user metadata in the Web Editor'),
          displayValue: values.allowLinguistsToViewUserMetadataInTheWebEditor ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.preventCompletingJobsWithEmptyTargets,
          value: values.preventCompletingJobsWithEmptyTargets,
          label: I18n.t('Prevent jobs from being completed with empty targets'),
          displayValue: values.preventCompletingJobsWithEmptyTargets ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.setProjectToAssignedEnabled,
          value: values.setProjectToAssignedEnabled,
          label: I18n.t('Set project to Assigned enabled'),
          displayValue: values.setProjectToAssignedEnabled ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.setProjectToAssignedJobStatus,
          value: values.setProjectToAssignedJobStatus,
          label: I18n.t('Set project to Assigned when all jobs are'),
          displayValue:
            values.setProjectToAssignedJobStatus === 'Emailed'
              ? I18n.t('Emailed')
              : I18n.t('Accepted'),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.setProjectToCompletedEnabled,
          value: values.setProjectToCompletedEnabled,
          label: I18n.t('Set project to Completed enabled'),
          displayValue: values.setProjectToCompletedEnabled ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.setProjectToCompletedJobStatus,
          value: values.setProjectToCompletedJobStatus,
          label: I18n.t('Set project to Completed when all jobs are'),
          displayValue:
            values.setProjectToCompletedJobStatus === 'Completed'
              ? I18n.t('Completed')
              : values.setProjectToCompletedJobStatus === 'Delivered'
              ? I18n.t('Delivered')
              : I18n.t('Completed OR delivered'),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.setProjectToCompletedWhenProjectIsCompletedByVendor,
          value: values.setProjectToCompletedWhenProjectIsCompletedByVendor,
          label: I18n.t('Set project to Completed when project is Completed by Vendor'),
          displayValue: values.setProjectToCompletedWhenProjectIsCompletedByVendor ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.setProjectToCanceledEnabled,
          value: values.setProjectToCanceledEnabled,
          label: I18n.t('Set project to Canceled enabled'),
          displayValue: values.setProjectToCanceledEnabled ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.setProjectToCanceledJobStatus,
          value: values.setProjectToCanceledJobStatus,
          label: I18n.t('Set project to Canceled when all jobs are'),
          displayValue: I18n.t('Canceled'),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.renameCompletedFilesTo,
          value: values.renameCompletedFilesTo,
          label: I18n.t('Rename completed files to'),
          displayValue: values.renameCompletedFilesTo,
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.deliverNotificationsByEmail,
          value: values.deliverNotificationsByEmail,
          label: I18n.t('Deliver notifications by email'),
          displayValue: values.deliverNotificationsByEmail ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.notifyWhenPreviousStepIs,
          value: values.notifyWhenPreviousStepIs,
          label: I18n.t('Notify when previous step is'),
          displayValue:
            values.notifyWhenPreviousStepIs === 'Completed'
              ? I18n.t('Completed')
              : I18n.t('Delivered'),
        }),
      ].filter(s => s);

      return (
        <Container title={title} key={title}>
          <ColumnLayout columns={4}>
            <div data-awsui-column-layout-root="true">
              {summaryElements.length > 0 ? (
                summaryElements
              ) : (
                <div>
                  <div>
                    <T>No changes</T>
                  </div>
                </div>
              )}
            </div>
          </ColumnLayout>
        </Container>
      );
    },
  };
};
