import React, { ReactElement, ReactNode, useCallback, useMemo, useReducer } from 'react';
import * as yup from 'yup';
import {
  Checkbox,
  ColumnLayout,
  Flash,
  Form,
  FormField,
  Icon,
  Input,
  RadioGroup,
  Select,
  Textarea,
} from '@amzn/awsui-components-react/polaris';
import { ExpandableSection, Header } from '@amzn/awsui-components-react-v3';
import I18n from '../../../setupI18n';
import styled from '@emotion/styled';
import { css } from 'emotion';
import { HelpInfoLink } from '../../HelpContentRouter';
import { PROJECT_CREATE_ASSETS_HELP } from '../projectHelpContent';
import { FormikProps } from 'formik';
import { coalesceSummaryValue, WizardStepWithSummary } from '../../components/WizardWithSummary';
import { Container } from '@amzn/et-polaris-utils';
import { T } from '../../components/T';
import { Project, ProjectTemplate } from '../../types/commonTypes';
import { formatLocaleCode } from '@amzn/et-console-components';
import { Link, TextContent } from '@amzn/awsui-components-react-v3/polaris';
import { TranslationMemoryMatchingSettingsConfiguration } from '../translationmemory/TranslationMemoryMatchingSettings';

const formSpacing = css`
  padding-top: 14px;
  padding-bottom: 14px;
`;

const FormContainer = styled('div')`
  max-width: 800px;
  min-width: 280px;
`;

const autoDetectValue = I18n.t('Auto-detect');

const ENCODING_ZIP_FILE = [
  { id: 'Big5', label: 'Big5 - Chinese Traditional' },
  { id: 'Big5-HKSCS', label: 'Big5-HKSCS' },
  { id: 'CESU-8', label: 'CESU-8' },
  { id: 'EUC-JP', label: 'EUC-JP - Japanese' },
  { id: 'EUC-KR', label: 'EUC-KR - Korean' },
  { id: 'GB18030', label: 'GB18030' },
  { id: 'GB2312', label: 'GB2312 - Chinese Simplified' },
  { id: 'GBK', label: 'GBK' },
  { id: 'IBM-Thai', label: 'IBM-Thai' },
  { id: 'IBM00858', label: 'IBM00858' },
  { id: 'IBM01140', label: 'IBM01140' },
  { id: 'IBM01141', label: 'IBM01141' },
  { id: 'IBM01142', label: 'IBM01142' },
  { id: 'IBM01143', label: 'IBM01143' },
  { id: 'IBM01144', label: 'IBM01144' },
  { id: 'IBM01145', label: 'IBM01145' },
  { id: 'IBM01146', label: 'IBM01146' },
  { id: 'IBM01147', label: 'IBM01147' },
  { id: 'IBM01148', label: 'IBM01148' },
  { id: 'IBM01149', label: 'IBM01149' },
  { id: 'IBM037', label: 'IBM037' },
  { id: 'IBM1026', label: 'IBM1026' },
  { id: 'IBM1047', label: 'IBM1047' },
  { id: 'IBM273', label: 'IBM273' },
  { id: 'IBM277', label: 'IBM277' },
  { id: 'IBM278', label: 'IBM278' },
  { id: 'IBM280', label: 'IBM280' },
  { id: 'IBM284', label: 'IBM284' },
  { id: 'IBM285', label: 'IBM285' },
  { id: 'IBM290', label: 'IBM290' },
  { id: 'IBM297', label: 'IBM297' },
  { id: 'IBM420', label: 'IBM420' },
  { id: 'IBM424', label: 'IBM424' },
  { id: 'IBM437', label: 'IBM437' },
  { id: 'IBM500', label: 'IBM500' },
  { id: 'IBM775', label: 'IBM775 - Baltic' },
  { id: 'IBM850', label: 'IBM850 - Western European' },
  { id: 'IBM852', label: 'IBM852 - Central European' },
  { id: 'IBM855', label: 'IBM855' },
  { id: 'IBM857', label: 'IBM857 - Turkish' },
  { id: 'IBM860', label: 'IBM860 - Portuguese' },
  { id: 'IBM861', label: 'IBM861 - Icelandic' },
  { id: 'IBM862', label: 'IBM862 - Hebrew' },
  { id: 'IBM863', label: 'IBM863' },
  { id: 'IBM864', label: 'IBM864 - Arabic' },
  { id: 'IBM865', label: 'IBM865 - Nordic' },
  { id: 'IBM866', label: 'IBM866 - Cyrillic' },
  { id: 'IBM868', label: 'IBM868' },
  { id: 'IBM869', label: 'IBM869 - Greek' },
  { id: 'IBM870', label: 'IBM870' },
  { id: 'IBM871', label: 'IBM871' },
  { id: 'IBM918', label: 'IBM918' },
  { id: 'ISO-2022-CN', label: 'ISO-2022-CN - Chinese' },
  { id: 'ISO-2022-JP', label: 'ISO-2022-JP - Japanese' },
  { id: 'ISO-2022-JP-2', label: 'ISO-2022-JP-2 - Japanese' },
  { id: 'ISO-2022-KR', label: 'ISO-2022-KR - Korean' },
  { id: 'ISO-8859-1', label: 'ISO-8859-1 - Western European' },
  { id: 'ISO-8859-13', label: 'ISO-8859-13 - Baltic' },
  { id: 'ISO-8859-15', label: 'ISO-8859-15' },
  { id: 'ISO-8859-2', label: 'ISO-8859-2 - Central European' },
  { id: 'ISO-8859-3', label: 'ISO-8859-3' },
  { id: 'ISO-8859-4', label: 'ISO-8859-4 - Baltic' },
  { id: 'ISO-8859-5', label: 'ISO-8859-5 - Cyrillic' },
  { id: 'ISO-8859-6', label: 'ISO-8859-6 - Arabic' },
  { id: 'ISO-8859-7', label: 'ISO-8859-7 - Greek' },
  { id: 'ISO-8859-8', label: 'ISO-8859-8 - Hebrew' },
  { id: 'ISO-8859-9', label: 'ISO-8859-9 - Turkish' },
  { id: 'JIS_X0201', label: 'JIS_X0201' },
  { id: 'JIS_X0212-1990', label: 'JIS_X0212-1990' },
  { id: 'KOI8-R', label: 'KOI8-R - Cyrillic' },
  { id: 'KOI8-U', label: 'KOI8-U - Cyrillic' },
  { id: 'Shift_JIS', label: 'Shift_JIS - Japanese' },
  { id: 'TIS-620', label: 'TIS-620' },
  { id: 'US-ASCII', label: 'US-ASCII' },
  { id: 'UTF-16', label: 'UTF-16' },
  { id: 'UTF-16BE', label: 'UTF-16BE' },
  { id: 'UTF-16LE', label: 'UTF-16LE' },
  { id: 'UTF-32', label: 'UTF-32' },
  { id: 'UTF-32BE', label: 'UTF-32BE' },
  { id: 'UTF-32LE', label: 'UTF-32LE' },
  { id: 'UTF-8', label: 'UTF-8' },
  { id: 'windows-1250', label: 'windows-1250 - Central European' },
  { id: 'windows-1251', label: 'windows-1251 - Cyrillic' },
  { id: 'windows-1252', label: 'windows-1252 - Western European' },
  { id: 'windows-1253', label: 'windows-1253 - Greek' },
  { id: 'windows-1254', label: 'windows-1254 - Turkish' },
  { id: 'windows-1255', label: 'windows-1255 - Hebrew' },
  { id: 'windows-1256', label: 'windows-1256 - Arabic' },
  { id: 'windows-1257', label: 'windows-1257 - Baltic' },
  { id: 'windows-1258', label: 'windows-1258 - Vietnamese' },
  { id: 'windows-31j', label: 'windows-31j' },
  { id: 'x-Big5-HKSCS-2001', label: 'x-Big5-HKSCS-2001' },
  { id: 'x-Big5-Solaris', label: 'x-Big5-Solaris' },
  { id: 'x-COMPOUND_TEXT', label: 'x-COMPOUND_TEXT' },
  { id: 'x-euc-jp-linux', label: 'x-euc-jp-linux' },
  { id: 'x-EUC-TW', label: 'x-EUC-TW' },
  { id: 'x-eucJP-Open', label: 'x-eucJP-Open' },
  { id: 'x-IBM1006', label: 'x-IBM1006' },
  { id: 'x-IBM1025', label: 'x-IBM1025' },
  { id: 'x-IBM1046', label: 'x-IBM1046' },
  { id: 'x-IBM1097', label: 'x-IBM1097' },
  { id: 'x-IBM1098', label: 'x-IBM1098' },
  { id: 'x-IBM1112', label: 'x-IBM1112' },
  { id: 'x-IBM1122', label: 'x-IBM1122' },
  { id: 'x-IBM1123', label: 'x-IBM1123' },
  { id: 'x-IBM1124', label: 'x-IBM1124' },
  { id: 'x-IBM1166', label: 'x-IBM1166' },
  { id: 'x-IBM1364', label: 'x-IBM1364' },
  { id: 'x-IBM1381', label: 'x-IBM1381' },
  { id: 'x-IBM1383', label: 'x-IBM1383' },
  { id: 'x-IBM300', label: 'x-IBM300' },
  { id: 'x-IBM33722', label: 'x-IBM33722' },
  { id: 'x-IBM737', label: 'x-IBM737 - Greek' },
  { id: 'x-IBM833', label: 'x-IBM833' },
  { id: 'x-IBM834', label: 'x-IBM834' },
  { id: 'x-IBM856', label: 'x-IBM856' },
  { id: 'x-IBM874', label: 'x-IBM874' },
  { id: 'x-IBM875', label: 'x-IBM875' },
  { id: 'x-IBM921', label: 'x-IBM921' },
  { id: 'x-IBM922', label: 'x-IBM922' },
  { id: 'x-IBM930', label: 'x-IBM930' },
  { id: 'x-IBM933', label: 'x-IBM933' },
  { id: 'x-IBM935', label: 'x-IBM935' },
  { id: 'x-IBM937', label: 'x-IBM937' },
  { id: 'x-IBM939', label: 'x-IBM939' },
  { id: 'x-IBM942', label: 'x-IBM942' },
  { id: 'x-IBM942C', label: 'x-IBM942C' },
  { id: 'x-IBM943', label: 'x-IBM943' },
  { id: 'x-IBM943C', label: 'x-IBM943C' },
  { id: 'x-IBM948', label: 'x-IBM948' },
  { id: 'x-IBM949', label: 'x-IBM949' },
  { id: 'x-IBM949C', label: 'x-IBM949C' },
  { id: 'x-IBM950', label: 'x-IBM950' },
  { id: 'x-IBM964', label: 'x-IBM964' },
  { id: 'x-IBM970', label: 'x-IBM970' },
  { id: 'x-ISCII91', label: 'x-ISCII91' },
  { id: 'x-ISO-2022-CN-CNS', label: 'x-ISO-2022-CN-CNS' },
  { id: 'x-ISO-2022-CN-GB', label: 'x-ISO-2022-CN-GB' },
  { id: 'x-iso-8859-11', label: 'x-iso-8859-11' },
  { id: 'x-JIS0208', label: 'x-JIS0208' },
  { id: 'x-JISAutoDetect', label: 'x-JISAutoDetect' },
  { id: 'x-Johab', label: 'x-Johab' },
  { id: 'x-MacArabic', label: 'x-MacArabic' },
  { id: 'x-MacCentralEurope', label: 'x-MacCentralEurope' },
  { id: 'x-MacCroatian', label: 'x-MacCroatian' },
  { id: 'x-MacCyrillic', label: 'x-MacCyrillic' },
  { id: 'x-MacDingbat', label: 'x-MacDingbat' },
  { id: 'x-MacGreek', label: 'x-MacGreek' },
  { id: 'x-MacHebrew', label: 'x-MacHebrew' },
  { id: 'x-MacIceland', label: 'x-MacIceland' },
  { id: 'x-MacRoman', label: 'x-MacRoman' },
  { id: 'x-MacRomania', label: 'x-MacRomania' },
  { id: 'x-MacSymbol', label: 'x-MacSymbol' },
  { id: 'x-MacThai', label: 'x-MacThai' },
  { id: 'x-MacTurkish', label: 'x-MacTurkish' },
  { id: 'x-MacUkraine', label: 'x-MacUkraine' },
  { id: 'x-MS932_0213', label: 'x-MS932_0213' },
  { id: 'x-MS950-HKSCS', label: 'x-MS950-HKSCS' },
  { id: 'x-MS950-HKSCS-XP', label: 'x-MS950-HKSCS-XP' },
  { id: 'x-mswin-936', label: 'x-mswin-936' },
  { id: 'x-PCK', label: 'x-PCK' },
  { id: 'x-SJIS_0213', label: 'x-SJIS_0213' },
  { id: 'x-UTF-16LE-BOM', label: 'x-UTF-16LE-BOM' },
  { id: 'X-UTF-32BE-BOM', label: 'X-UTF-32BE-BOM' },
  { id: 'X-UTF-32LE-BOM', label: 'X-UTF-32LE-BOM' },
  { id: 'x-windows-50220', label: 'x-windows-50220' },
  { id: 'x-windows-50221', label: 'x-windows-50221' },
  { id: 'x-windows-874', label: 'x-windows-874' },
  { id: 'x-windows-949', label: 'x-windows-949' },
  { id: 'x-windows-950', label: 'x-windows-950' },
  { id: 'x-windows-iso2022jp', label: 'x-windows-iso2022jp' },
];

export interface Props<T extends JobCreateAPIStep3> extends FormikProps<T> {
  validating: boolean;
  isLoadingSupportedFileFormats: boolean;
  isLoadingSupportedFileEncodings: boolean;
  isLoadingSegmentationRules: boolean;
  isProjectTemplateMode: boolean;
  supportedFileFormats?: {
    value: string;
    title: string;
    backendFormat: string;
  }[];
  supportedFileEncodings?: string[];
  segmentationRules?: {
    id: number;
    name: string;
    locale: string;
  }[];
}

export interface JobCreateAPIStep3 {
  zipCharset?: string;
  importEncoding?: string;
  exportEncoding?: string;
  fileFormat?: string; // TODO: This is an enum with MANY values

  sourceSegmentationRuleId?: number;
  targetSegmentationRuleId?: number;
  targetLength: boolean;
  targetLengthMax?: number;
  targetLengthPercent: boolean;
  targetLengthPercentValue?: number;

  csvDelimiter: string;
  csvUseHtmlSubfilter: boolean;
  csvTagRegexp?: string;
  csvImportColumns?: string;
  csvContextNoteColumns?: string;
  csvContextKeyColumn?: string;
  csvMaxLengthColumn?: string;
  csvImportRows?: string;

  ditaTranslatableElements?: string;
  ditaInlineElements?: string;
  ditaInlineSingleElements?: string;
  ditaSkipElements?: string;

  docBookTranslatableElements?: string;
  docBookInlineElements?: string;
  docBookInlineSingleElements?: string;
  docBookSkipElements?: string;

  htmlBreakTagCreatesSegment: boolean;
  htmlUnknownTagCreatesTag: boolean;
  htmlPreserveWhitespace: boolean;
  htmlCharacterEntitiesToConvertToTags?: string;
  htmlTagRegexp?: string;
  htmlTranslatableMetaTagRegexp?: string;
  htmlExcludeElements?: string;

  idmlExtractNotes: boolean;
  idmlSimplifyCodes: boolean;
  idmlExtractMasterSpreads: boolean;
  idmlExtractInvisibleLayers: boolean;
  idmlExtractHiddenConditionalText: boolean;
  idmlExtractLockedLayer: boolean;
  idmlKeepKerning: boolean;
  idmlKeepTracking: boolean;
  idmlTargetFont?: string;
  idmlReplaceFont: boolean;
  idmlRemoveXmlElements: boolean;
  idmlTagRegexp?: string;

  jsonTagRegexp?: string;
  jsonIncludedKeysRegexp?: string;
  jsonContextNotePath?: string;
  jsonUseHtmlSubfilter: boolean;
  jsonMaxLengthPath?: string;

  mifExtractBodyPages: boolean;
  mifExtractReferencePages: boolean;
  mifExtractCustomReferencePages: boolean;
  mifExtractDefaultReferencePages: boolean;
  mifExtractMasterPages: boolean;
  mifExtractHiddenPages: boolean;
  mifExtractVariables: boolean;
  mifExtractUsedVariables: boolean;
  mifExtractIndexMarkers: boolean;
  mifExtractLinks: boolean;
  mifExtractXRefs: boolean;
  mifExtractUsedXRefs: boolean;
  mifExtractPgfNumberFormats: boolean;
  mifExtractUsedPgfNumberFormats: boolean;
  mifExtractHiddenConditionalText: boolean;

  multilingualXlsSourceColumn?: string;
  multilingualXlsTagRegexp?: string;
  multilingualXlsUseHtmlSubfilter: boolean;
  multilingualXlsContextNoteColumn?: string;
  multilingualXlsContextKeyColumn?: string;
  multilingualXlsMaxLengthColumn?: string;
  multilingualXlsSegment: boolean;
  multilingualXlsImportRows?: string;
  multilingualXlsTargetColumn?: string;
  multilingualXlsAllTargetColumns: string[];

  msExcelCellFlow: 'DownRight' | 'RightDown' | 'DownLeft' | 'LeftDown';
  msExcelExtractSheetNames: boolean;
  msExcelExtractHidden: boolean;
  msExcelExtractOther: boolean;
  msExcelUseHtmlSubfilter: boolean;
  msExcelTagRegexp?: string;
  msExcelExtractComments: boolean;

  msPowerPointExtractNotes: boolean;
  msPowerPointExtractMasterSlides: boolean;
  msPowerPointExtractOther: boolean;
  msPowerPointExtractHidden: boolean;

  msWordExtractComments: boolean;
  msWordExtractIndexes: boolean;
  msWordExtractOther: boolean;
  msWordTagRegexp?: string;
  msWordTargetFont?: string;
  msWordExtractHyperlinkTarget: boolean;
  msWordJoinSimilarRuns: boolean;
  msWordExtractProperties: boolean;
  msWordExtractHidden: boolean;

  ttxSaveConfirmedSegments: boolean;

  txtTagRegexp?: string;
  txtTranslatableTextRegexp?: string;

  poTagRegexp?: string;
  poExportMultilineStrings: boolean;

  propertiesTagRegexp?: string;
  propertiesEscapeAllBackslashes: boolean;
  propertiesSmartHtmlSubfilter: boolean;

  xlfExtractNote: boolean;
  xlfSaveConfirmedSegmentsToTMOnImport: boolean;
  xlfSegment: boolean;
  xlfInterpretLineBreaksTags: boolean;
  xlfPreserveWhitespace: boolean;
  xlfCopySourceToTargetIfSegmentNotImported: boolean;
  xlfDoNotImportSegmentWhenRules?: string;
  xlfConfirmSegmentWhenRules?: string;
  xlfLockSegmentWhenRules?: string;
  xlfExportConfirmedAndLockedRules?: string;
  xlfExportConfirmedAndNotLockedRules?: string;
  xlfExportNotConfirmedAndLockedRules?: string;
  xlfExportNotConfirmedAndNotLockedRules?: string;
  xlfContextType?: string;

  xlf2ImportNotes: boolean;
  xlf2SaveConfirmedSegmentsToTMOnImport: boolean;
  xlf2Segment: boolean;
  xlf2InterpretLineBreakTags: boolean;
  xlf2PreserveWhitespace: boolean;
  xlf2CopySourceToTargetIfSegmentNotImported: boolean;
  xlf2RespectTranslateAttribute: boolean;
  xlf2SkipImportRules?: string;
  xlf2ConfirmSegmentWhenRules?: string;
  xlf2LockSegmentWhenRules?: string;
  xlf2ExportConfirmedAndLockedRules?: string;
  xlf2ExportConfirmedAndNotLockedRules?: string;
  xlf2ExportNotConfirmedAndLockedRules?: string;
  xlf2ExportNotConfirmedAndNotLockedRules?: string;
  xlf2ContextKeyXPath?: string;

  sdlXlfSkipImportRules?: string;
  sdlXlfImportAsConfirmedRules?: string;
  sdlXlfImportAsLockedRules?: string;
  sdlXlfExportAttrsWhenConfirmedAndLocked?: string;
  sdlXlfExportAttrsWhenConfirmedAndNotLocked?: string;
  sdlXlfExportAttrsWhenNotConfirmedAndLocked?: string;
  sdlXlfExportAttrsWhenNotConfirmedAndNotLocked?: string;
  sdlXlfSaveConfirmedSegments: boolean;

  xmlRulesFormat: 'PLAIN' | 'XPATH';
  xmlIncludeTags: boolean;
  xmlIncludeExcludeTags?: string;
  xmlIncludeAttributes: boolean;
  xmlIncludeExcludeAttributes?: string;
  xmlImportEntities: boolean;
  xmlTagRegexp?: string;
  xmlCharacterEntitiesToPreserveOnExport?: string;
  xmlSegment: boolean;
  xmlXslUrl?: string;
  xmlEscapeUnresolvedEntities: boolean;
  xmlIgnorePreprocessingInstructions: boolean;
  xmlLockedTags?: string;
  xmlLockedAttributes?: string;
  xmlElementsToProcessAsHtml?: string;
  xmlSmartHtmlSubfilter: boolean;
  xmlInlineTags?: string;
  xmlInlineSingleTag?: string;
  xmlSmartInlineTags: boolean;
  xmlElementsAndAttributesXPath?: string;
  xmlLockedElementsAndAttributesXPath?: string;
  xmlInlineElementsXPath?: string;
  xmlInlineSingleElementXPath?: string;
  xmlSubfilterElementsXPath?: string;
  xmlXPathSmartHtmlSubfilter: boolean;
  xmlPreserveWhitespaceXPath?: string;
  xmlContextNoteXPath?: string;
  xmlMaxLengthXPath?: string;
  xmlXpathSmartInlineElement: boolean;
  xmlContextKeyXPath?: string;

  yamlTagRegexp?: string;
  yamlUseHtmlSubfilter: boolean;
  yamlContextKeyRegexp?: string;
  yamlContextNoteRegexp?: string;

  multilingualXmlTranslatableElementsXPath?: string;
  multilingualXmlSourceElementsXPath?: string;
  multilingualXmlTargetElementsXPath?: string;
  multilingualXmlNonTranslatableInlineElementsXPath?: string;
  multilingualXmlTagRegexp?: string;
  multilingualXmlSegment: boolean;
  multilingualXmlUseHtmlSubfilter: boolean;
  multilingualXmlContextNoteXPath?: string;
  multilingualXmlContextKeyXPath?: string;
  multilingualXmlMaxLengthXPath?: string;
  multilingualXmlPreserveWhitespace: boolean;

  macStringsUseHtmlSubfilter: boolean;
  macStringsTagRegexp?: string;

  androidStringTagRegexp?: string;

  phpTagRegexp?: string;
  phpUseHtmlSubfilter: boolean;

  resxTagRegexp?: string;

  pdfFilter: 'DEFAULT' | 'TRANS_PDF';

  quarkTagRemoveKerningTrackingTags: boolean;

  markdownTranslateImageAltText: boolean;
  markdownTranslateHeaderMetadata: boolean;
  markdownTranslateCodeBlocks: boolean;
  markdownTranslateInlineCodeBlocks: boolean;
  markdownTranslateUrls: boolean;
  markdownConvertToTags: string;

  contextType: 'AUTO' | 'PREV_AND_NEXT_SEGMENT' | 'SEGMENT_KEY' | 'NO_CONTEXT';
  previousOrNextSegmentContextAs101: boolean;
  penalizeMultiple101TMMatchesBy1: boolean;
}

interface ReducerState {
  isError: boolean;
  error?: string;
}

export const jobCreateSchemaStep3 = yup.object().shape({});

export const jobCreateInitialFormValuesStep3 = (
  currentValue?: ProjectTemplate | JobCreateAPIStep3,
  projectValue?: Project
): JobCreateAPIStep3 => {
  return {
    zipCharset: currentValue?.zipCharset ?? 'UTF-8',
    importEncoding: currentValue?.importEncoding,
    exportEncoding: currentValue?.exportEncoding,
    fileFormat: currentValue?.fileFormat,
    sourceSegmentationRuleId: currentValue?.sourceSegmentationRuleId,
    targetSegmentationRuleId: currentValue?.targetSegmentationRuleId,

    targetLength: currentValue?.targetLength ?? false,
    targetLengthMax: currentValue?.targetLengthMax ?? 1000,
    targetLengthPercent: currentValue?.targetLengthPercent ?? false,
    targetLengthPercentValue: currentValue?.targetLengthPercentValue ?? 130,

    csvDelimiter: currentValue?.csvDelimiter ?? ',',
    csvUseHtmlSubfilter: currentValue?.csvUseHtmlSubfilter ?? false,
    csvTagRegexp: currentValue?.csvTagRegexp,
    csvImportColumns: currentValue?.csvImportColumns,
    csvContextNoteColumns: currentValue?.csvContextNoteColumns,
    csvContextKeyColumn: currentValue?.csvContextKeyColumn,
    csvMaxLengthColumn: currentValue?.csvMaxLengthColumn,
    csvImportRows: currentValue?.csvImportRows,

    ditaTranslatableElements: currentValue?.ditaTranslatableElements,
    ditaInlineElements: currentValue?.ditaInlineElements,
    ditaInlineSingleElements: currentValue?.ditaInlineSingleElements,
    ditaSkipElements: currentValue?.ditaSkipElements,

    docBookTranslatableElements: currentValue?.docBookTranslatableElements,
    docBookInlineElements: currentValue?.docBookInlineElements,
    docBookInlineSingleElements: currentValue?.docBookInlineSingleElements,
    docBookSkipElements: currentValue?.docBookSkipElements,

    htmlBreakTagCreatesSegment: currentValue?.htmlBreakTagCreatesSegment ?? true,
    htmlUnknownTagCreatesTag: currentValue?.htmlUnknownTagCreatesTag ?? true,
    htmlPreserveWhitespace: currentValue?.htmlPreserveWhitespace ?? false,
    htmlCharacterEntitiesToConvertToTags: currentValue?.htmlCharacterEntitiesToConvertToTags,
    htmlTagRegexp: currentValue?.htmlTagRegexp,
    htmlTranslatableMetaTagRegexp: currentValue?.htmlTranslatableMetaTagRegexp,
    htmlExcludeElements: currentValue?.htmlExcludeElements,

    idmlExtractNotes: currentValue?.idmlExtractNotes ?? true,
    idmlSimplifyCodes: currentValue?.idmlSimplifyCodes ?? false,
    idmlExtractMasterSpreads: currentValue?.idmlExtractMasterSpreads ?? true,
    idmlExtractInvisibleLayers: currentValue?.idmlExtractInvisibleLayers ?? true,
    idmlExtractHiddenConditionalText: currentValue?.idmlExtractHiddenConditionalText ?? true,
    idmlExtractLockedLayer: currentValue?.idmlExtractLockedLayer ?? true,
    idmlKeepKerning: currentValue?.idmlKeepKerning ?? false,
    idmlKeepTracking: currentValue?.idmlKeepTracking ?? false,
    idmlTargetFont: currentValue?.idmlTargetFont,
    idmlReplaceFont: currentValue?.idmlReplaceFont ?? false,
    idmlRemoveXmlElements: currentValue?.idmlRemoveXmlElements ?? false,
    idmlTagRegexp: currentValue?.idmlTagRegexp,

    jsonTagRegexp: currentValue?.jsonTagRegexp,
    jsonIncludedKeysRegexp: currentValue?.jsonIncludedKeysRegexp,
    jsonContextNotePath: currentValue?.jsonContextNotePath,
    jsonUseHtmlSubfilter: currentValue?.jsonUseHtmlSubfilter ?? true,
    jsonMaxLengthPath: currentValue?.jsonMaxLengthPath,

    mifExtractBodyPages: currentValue?.mifExtractBodyPages ?? true,
    mifExtractReferencePages: currentValue?.mifExtractReferencePages ?? false,
    mifExtractCustomReferencePages: currentValue?.mifExtractCustomReferencePages ?? true,
    mifExtractDefaultReferencePages: currentValue?.mifExtractDefaultReferencePages ?? false,
    mifExtractMasterPages: currentValue?.mifExtractMasterPages ?? true,
    mifExtractHiddenPages: currentValue?.mifExtractHiddenPages ?? false,
    mifExtractVariables: currentValue?.mifExtractVariables ?? false,
    mifExtractUsedVariables: currentValue?.mifExtractUsedVariables ?? true,
    mifExtractIndexMarkers: currentValue?.mifExtractIndexMarkers ?? true,
    mifExtractLinks: currentValue?.mifExtractLinks ?? false,
    mifExtractXRefs: currentValue?.mifExtractXRefs ?? false,
    mifExtractUsedXRefs: currentValue?.mifExtractUsedXRefs ?? true,
    mifExtractPgfNumberFormats: currentValue?.mifExtractPgfNumberFormats ?? false,
    mifExtractUsedPgfNumberFormats: currentValue?.mifExtractUsedPgfNumberFormats ?? true,
    mifExtractHiddenConditionalText: currentValue?.mifExtractHiddenConditionalText ?? false,

    multilingualXlsSourceColumn: currentValue?.multilingualXlsSourceColumn,
    multilingualXlsTagRegexp: currentValue?.multilingualXlsTagRegexp,
    multilingualXlsUseHtmlSubfilter: currentValue?.multilingualXlsUseHtmlSubfilter ?? false,
    multilingualXlsContextNoteColumn: currentValue?.multilingualXlsContextNoteColumn,
    multilingualXlsContextKeyColumn: currentValue?.multilingualXlsContextKeyColumn,
    multilingualXlsMaxLengthColumn: currentValue?.multilingualXlsMaxLengthColumn,
    multilingualXlsSegment: currentValue?.multilingualXlsSegment ?? true,
    multilingualXlsImportRows: currentValue?.multilingualXlsImportRows,
    multilingualXlsTargetColumn: currentValue?.multilingualXlsTargetColumn,
    multilingualXlsAllTargetColumns: currentValue?.multilingualXlsAllTargetColumns ?? [],

    msExcelCellFlow: currentValue?.msExcelCellFlow ?? 'LeftDown',
    msExcelExtractSheetNames: currentValue?.msExcelExtractSheetNames ?? true,
    msExcelExtractHidden: currentValue?.msExcelExtractHidden ?? true,
    msExcelExtractOther: currentValue?.msExcelExtractOther ?? false,
    msExcelUseHtmlSubfilter: currentValue?.msExcelUseHtmlSubfilter ?? false,
    msExcelTagRegexp: currentValue?.msExcelTagRegexp,
    msExcelExtractComments: currentValue?.msExcelExtractComments ?? true,

    msPowerPointExtractNotes: currentValue?.msPowerPointExtractNotes ?? true,
    msPowerPointExtractMasterSlides: currentValue?.msPowerPointExtractMasterSlides ?? true,
    msPowerPointExtractOther: currentValue?.msPowerPointExtractOther ?? false,
    msPowerPointExtractHidden: currentValue?.msPowerPointExtractHidden ?? true,

    msWordExtractComments: currentValue?.msWordExtractComments ?? false,
    msWordExtractIndexes: currentValue?.msWordExtractIndexes ?? true,
    msWordExtractOther: currentValue?.msWordExtractOther ?? true,
    msWordTagRegexp: currentValue?.msWordTagRegexp,
    msWordTargetFont: currentValue?.msWordTargetFont,
    msWordExtractHyperlinkTarget: currentValue?.msWordExtractHyperlinkTarget ?? false,
    msWordJoinSimilarRuns: currentValue?.msWordJoinSimilarRuns ?? false,
    msWordExtractProperties: currentValue?.msWordExtractProperties ?? false,
    msWordExtractHidden: currentValue?.msWordExtractHidden ?? false,

    ttxSaveConfirmedSegments: currentValue?.ttxSaveConfirmedSegments ?? true,

    txtTagRegexp: currentValue?.txtTagRegexp,
    txtTranslatableTextRegexp: currentValue?.txtTranslatableTextRegexp,

    poTagRegexp: currentValue?.poTagRegexp,
    poExportMultilineStrings: currentValue?.poExportMultilineStrings ?? false,

    propertiesTagRegexp: currentValue?.propertiesTagRegexp,
    propertiesEscapeAllBackslashes: currentValue?.propertiesEscapeAllBackslashes ?? false,
    propertiesSmartHtmlSubfilter: currentValue?.propertiesSmartHtmlSubfilter ?? false,

    xlfExtractNote: currentValue?.xlfExtractNote ?? true,
    xlfSaveConfirmedSegmentsToTMOnImport:
      currentValue?.xlfSaveConfirmedSegmentsToTMOnImport ?? true,
    xlfSegment: currentValue?.xlfSegment ?? true,
    xlfInterpretLineBreaksTags: currentValue?.xlfInterpretLineBreaksTags ?? false,
    xlfPreserveWhitespace: currentValue?.xlfPreserveWhitespace ?? true,
    xlfCopySourceToTargetIfSegmentNotImported:
      currentValue?.xlfCopySourceToTargetIfSegmentNotImported ?? true,
    xlfContextType: currentValue?.xlfContextType,
    xlfDoNotImportSegmentWhenRules: currentValue?.xlfDoNotImportSegmentWhenRules,
    xlfConfirmSegmentWhenRules: currentValue?.xlfConfirmSegmentWhenRules,
    xlfLockSegmentWhenRules: currentValue?.xlfLockSegmentWhenRules,
    xlfExportConfirmedAndLockedRules: currentValue?.xlfExportConfirmedAndLockedRules,
    xlfExportConfirmedAndNotLockedRules: currentValue?.xlfExportConfirmedAndNotLockedRules,
    xlfExportNotConfirmedAndLockedRules: currentValue?.xlfExportNotConfirmedAndLockedRules,
    xlfExportNotConfirmedAndNotLockedRules: currentValue?.xlfExportNotConfirmedAndNotLockedRules,

    xlf2ImportNotes: currentValue?.xlf2ImportNotes ?? true,
    xlf2SaveConfirmedSegmentsToTMOnImport:
      currentValue?.xlf2SaveConfirmedSegmentsToTMOnImport ?? true,
    xlf2Segment: currentValue?.xlf2Segment ?? true,
    xlf2InterpretLineBreakTags: currentValue?.xlf2InterpretLineBreakTags ?? false,
    xlf2PreserveWhitespace: currentValue?.xlf2PreserveWhitespace ?? true,
    xlf2CopySourceToTargetIfSegmentNotImported:
      currentValue?.xlf2CopySourceToTargetIfSegmentNotImported ?? true,
    xlf2RespectTranslateAttribute: currentValue?.xlf2RespectTranslateAttribute ?? true,
    xlf2SkipImportRules: currentValue?.xlf2SkipImportRules,
    xlf2ConfirmSegmentWhenRules: currentValue?.xlf2ConfirmSegmentWhenRules,
    xlf2LockSegmentWhenRules: currentValue?.xlf2LockSegmentWhenRules,
    xlf2ExportConfirmedAndLockedRules: currentValue?.xlf2ExportConfirmedAndLockedRules,
    xlf2ExportConfirmedAndNotLockedRules: currentValue?.xlf2ExportConfirmedAndNotLockedRules,
    xlf2ExportNotConfirmedAndLockedRules: currentValue?.xlf2ExportNotConfirmedAndLockedRules,
    xlf2ExportNotConfirmedAndNotLockedRules: currentValue?.xlf2ExportNotConfirmedAndNotLockedRules,
    xlf2ContextKeyXPath: currentValue?.xlf2ContextKeyXPath,

    sdlXlfSkipImportRules: currentValue?.sdlXlfSkipImportRules,
    sdlXlfImportAsConfirmedRules: currentValue?.sdlXlfImportAsConfirmedRules,
    sdlXlfImportAsLockedRules: currentValue?.sdlXlfImportAsLockedRules,
    sdlXlfExportAttrsWhenConfirmedAndLocked: currentValue?.sdlXlfExportAttrsWhenConfirmedAndLocked,
    sdlXlfExportAttrsWhenConfirmedAndNotLocked:
      currentValue?.sdlXlfExportAttrsWhenConfirmedAndNotLocked,
    sdlXlfExportAttrsWhenNotConfirmedAndLocked:
      currentValue?.sdlXlfExportAttrsWhenNotConfirmedAndLocked,
    sdlXlfExportAttrsWhenNotConfirmedAndNotLocked:
      currentValue?.sdlXlfExportAttrsWhenNotConfirmedAndNotLocked,
    sdlXlfSaveConfirmedSegments: currentValue?.sdlXlfSaveConfirmedSegments ?? true,

    xmlRulesFormat: currentValue?.xmlRulesFormat ?? 'PLAIN',
    xmlIncludeTags: currentValue?.xmlIncludeTags ?? true,
    xmlIncludeExcludeTags: currentValue?.xmlIncludeExcludeTags,
    xmlIncludeAttributes: currentValue?.xmlIncludeAttributes ?? true,
    xmlIncludeExcludeAttributes: currentValue?.xmlIncludeExcludeAttributes,
    xmlImportEntities: currentValue?.xmlImportEntities ?? true,
    xmlTagRegexp: currentValue?.xmlTagRegexp,
    xmlCharacterEntitiesToPreserveOnExport: currentValue?.xmlCharacterEntitiesToPreserveOnExport,
    xmlSegment: currentValue?.xmlSegment ?? true,
    xmlXslUrl: currentValue?.xmlXslUrl,
    xmlEscapeUnresolvedEntities: currentValue?.xmlEscapeUnresolvedEntities ?? false,
    xmlIgnorePreprocessingInstructions: currentValue?.xmlIgnorePreprocessingInstructions ?? false,
    xmlLockedTags: currentValue?.xmlLockedTags,
    xmlLockedAttributes: currentValue?.xmlLockedAttributes,
    xmlElementsToProcessAsHtml: currentValue?.xmlElementsToProcessAsHtml,
    xmlSmartHtmlSubfilter: currentValue?.xmlSmartHtmlSubfilter ?? false,
    xmlInlineTags: currentValue?.xmlInlineTags,
    xmlInlineSingleTag: currentValue?.xmlInlineSingleTag,
    xmlSmartInlineTags: currentValue?.xmlSmartInlineTags ?? false,
    xmlElementsAndAttributesXPath: currentValue?.xmlElementsAndAttributesXPath,
    xmlLockedElementsAndAttributesXPath: currentValue?.xmlLockedElementsAndAttributesXPath,
    xmlInlineElementsXPath: currentValue?.xmlInlineElementsXPath,
    xmlInlineSingleElementXPath: currentValue?.xmlInlineSingleElementXPath,
    xmlSubfilterElementsXPath: currentValue?.xmlSubfilterElementsXPath,
    xmlXPathSmartHtmlSubfilter: currentValue?.xmlXPathSmartHtmlSubfilter ?? false,
    xmlPreserveWhitespaceXPath: currentValue?.xmlPreserveWhitespaceXPath,
    xmlContextNoteXPath: currentValue?.xmlContextNoteXPath,
    xmlMaxLengthXPath: currentValue?.xmlMaxLengthXPath,
    xmlXpathSmartInlineElement: currentValue?.xmlXpathSmartInlineElement ?? false,
    xmlContextKeyXPath: currentValue?.xmlContextKeyXPath,

    yamlTagRegexp: currentValue?.yamlTagRegexp,
    yamlUseHtmlSubfilter: currentValue?.yamlUseHtmlSubfilter ?? false,
    yamlContextKeyRegexp: currentValue?.yamlContextKeyRegexp,
    yamlContextNoteRegexp: currentValue?.yamlContextNoteRegexp,

    multilingualXmlTranslatableElementsXPath:
      currentValue?.multilingualXmlTranslatableElementsXPath,
    multilingualXmlSourceElementsXPath: currentValue?.multilingualXmlSourceElementsXPath,
    multilingualXmlTargetElementsXPath: currentValue?.multilingualXmlTargetElementsXPath,
    multilingualXmlNonTranslatableInlineElementsXPath:
      currentValue?.multilingualXmlNonTranslatableInlineElementsXPath,
    multilingualXmlTagRegexp: currentValue?.multilingualXmlTagRegexp,
    multilingualXmlSegment: currentValue?.multilingualXmlSegment ?? false,
    multilingualXmlUseHtmlSubfilter: currentValue?.multilingualXmlUseHtmlSubfilter ?? false,
    multilingualXmlContextNoteXPath: currentValue?.multilingualXmlContextNoteXPath,
    multilingualXmlContextKeyXPath: currentValue?.multilingualXmlContextKeyXPath,
    multilingualXmlMaxLengthXPath: currentValue?.multilingualXmlMaxLengthXPath,
    multilingualXmlPreserveWhitespace: currentValue?.multilingualXmlPreserveWhitespace ?? false,

    macStringsUseHtmlSubfilter: currentValue?.macStringsUseHtmlSubfilter ?? false,
    macStringsTagRegexp: currentValue?.macStringsTagRegexp,

    androidStringTagRegexp: currentValue?.androidStringTagRegexp,

    phpTagRegexp: currentValue?.phpTagRegexp,
    phpUseHtmlSubfilter: currentValue?.phpUseHtmlSubfilter ?? false,

    resxTagRegexp: currentValue?.resxTagRegexp,

    pdfFilter: currentValue?.pdfFilter ?? 'DEFAULT',

    quarkTagRemoveKerningTrackingTags: currentValue?.quarkTagRemoveKerningTrackingTags ?? false,

    markdownTranslateImageAltText: currentValue?.markdownTranslateImageAltText ?? true,
    markdownTranslateHeaderMetadata: currentValue?.markdownTranslateHeaderMetadata ?? true,
    markdownTranslateCodeBlocks: currentValue?.markdownTranslateCodeBlocks ?? true,
    markdownTranslateInlineCodeBlocks: currentValue?.markdownTranslateInlineCodeBlocks ?? true,
    markdownTranslateUrls: currentValue?.markdownTranslateUrls ?? true,
    markdownConvertToTags: currentValue?.markdownConvertToTags ?? '',

    contextType: projectValue?.contextType ?? currentValue?.contextType ?? 'PREV_AND_NEXT_SEGMENT',
    previousOrNextSegmentContextAs101:
      projectValue?.previousOrNextSegmentContextAs101 ??
      currentValue?.previousOrNextSegmentContextAs101 ??
      false,
    penalizeMultiple101TMMatchesBy1:
      projectValue?.penalizeMultiple101TMMatchesBy1 ??
      currentValue?.penalizeMultiple101TMMatchesBy1 ??
      false,
  };
};

const Content = <T extends JobCreateAPIStep3>({
  values,
  errors,
  validating,
  handleChange,
  handleBlur,
  setFieldValue,
  isLoadingSupportedFileFormats,
  isLoadingSupportedFileEncodings,
  isLoadingSegmentationRules,
  supportedFileFormats,
  supportedFileEncodings,
  segmentationRules,
  isProjectTemplateMode,
}: Props<T>): ReactElement => {
  const init = (): ReducerState => ({
    isError: false,
  });

  const reducer = (state, action): ReducerState => {
    switch (action.type) {
      default:
        return state;
    }
  };

  const [reducerState] = useReducer(reducer, null, init);

  const fileEncodingsPlusAutoDetect = useMemo(
    () => [autoDetectValue, ...(supportedFileEncodings ?? [])],
    [supportedFileEncodings]
  );

  const fileFormatsPlusNone = useMemo(
    () => [
      { backendFormat: autoDetectValue, title: autoDetectValue },
      ...(supportedFileFormats ?? []),
    ],
    [supportedFileFormats]
  );

  const segmentationRulesPlusNone = useMemo(
    () => [{ name: I18n.t('None'), locale: '', id: undefined }, ...(segmentationRules ?? [])],
    [segmentationRules]
  );

  const handleAutoDetectableFieldChange = useCallback(
    (field): ((event: CustomEvent<Select.ChangeDetail>) => void) => (
      e: CustomEvent<Select.ChangeDetail>
    ): void => {
      const encoding = e.detail.selectedId === autoDetectValue ? undefined : e.detail.selectedId;
      setFieldValue(field, encoding);
    },
    []
  );

  const regexHint = (
    <T>
      <div>{'Example: \\{[^\\}]+\\} represents {variable}'}</div>
      See{' '}
      <Link
        external
        variant="info"
        target="_blank"
        href="https://w.amazon.com/index.php/ATMS/Docs/Regexp"
      >
        the Regex Manual
      </Link>{' '}
      for help with regex
    </T>
  );

  const htmlHint = (
    <T>
      <div>
        {I18n.t(
          'Comma-separated list of HTML character entities that will be converted to ATMS tags '
        )}
        <br />
        {I18n.t(
          '(for example, &#57506;,&copy;). If empty, no entity will be converted to an ATMS tag. '
        )}
        <br />
        {I18n.t('If * is entered, all character entities will be converted to ATMS tags.')}
      </div>
    </T>
  );

  const xsltHint = (
    <T>
      <div>
        {I18n.t('Optional ')}
        <Link
          external
          variant="info"
          target="_blank"
          href="https://www.w3schools.com/xml/xsl_intro.asp"
        >
          XSLT
        </Link>
        {I18n.t(' file for converting file to HTML, giving translators an in-context preview.')}
        <br />
        {I18n.t('Upload the file to an S3 bucket and provide the Object URL here.')}
      </div>
    </T>
  );

  const xpathHint = (
    <T>
      <div>Example: //element[@attr='value']</div>
      See{' '}
      <Link
        external
        variant="info"
        target="_blank"
        href="https://w.amazon.com/index.php/ATMS/Docs/XPath"
      >
        the XPath Manual
      </Link>{' '}
      for help with XPath
    </T>
  );

  const contextKeyHint = (
    <T>
      <div>Examples: @ID - attribute ID of the parent (//tu) element</div>
      For more details see our{' '}
      <Link
        external
        variant="info"
        target="_blank"
        href="https://w.amazon.com/index.php/ATMS/Docs/XPath"
      >
        XPath Manual
      </Link>{' '}
      .
    </T>
  );

  const xliffDocumentationLink = (
    <Link
      external
      variant="info"
      target="_blank"
      href="https://w.amazon.com/bin/view/INTech/TranslationServices/LinguistExperience/ATMS/Docs/XLIFF"
    >
      {' '}
      XLIFF Manual{' '}
    </Link>
  );

  const importExportHint = (
    <T>
      Refer to {xliffDocumentationLink} for a list of possible values for each argument. <br />
      Separating arguments by comma creates the AND logical relationship, while a new line creates
      the OR logical relationship.
      <br />
    </T>
  );

  const xliffImportExportHints = (
    <T>
      Import/Export rules can be created using arguments like translate, approved and state.
      <br /> {importExportHint}
    </T>
  );
  const xliffTextBoxHint = (
    <T>
      <div> Example: translate=yes,approved=no,state=final</div>For more details see our{' '}
      {xliffDocumentationLink}.
    </T>
  );

  const xliff2ImportExportHints = (
    <T>
      Import/Export rules can be created using arguments like translate and state.
      <br /> {importExportHint}
    </T>
  );
  const xliff2TextBoxHint = (
    <T>
      <div> Example: translate=yes,state=inital</div>For more details see our{' '}
      {xliffDocumentationLink}
    </T>
  );

  const sdlXliffImportExportHints = (
    <T>
      Import/Export rules can be created using arguments like translate, locked and state.
      <br /> {importExportHint}
    </T>
  );
  const sdlXliffTextBoxHint = (
    <T>
      <div> Example: translate=yes,locked=true,state=draft</div>
      For more details see our {xliffDocumentationLink}.
    </T>
  );

  const handleXmlIncludeExcludeAttributes = (event: CustomEvent<Input.ChangeDetail>): void => {
    setFieldValue(
      'xmlIncludeExcludeAttributes',
      event.detail.value === '' ? undefined : event.detail.value
    );
  };

  return (
    <FormContainer>
      <Form
        errorText={
          validating && Object.keys(errors).length !== 0
            ? I18n.t('The form contains errors. Fix them and resubmit.')
            : ''
        }
      >
        <ExpandableSection
          variant="container"
          header={<Header variant="h2">General</Header>}
          defaultExpanded={true}
        >
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField errorText={errors.importEncoding} label={I18n.t('Import encoding')}>
                <Select
                  loading={isLoadingSupportedFileEncodings}
                  options={
                    fileEncodingsPlusAutoDetect?.map(encoding => {
                      return {
                        label: encoding,
                        id: encoding,
                      };
                    }) ?? []
                  }
                  id="importEncoding"
                  controlId="importEncoding"
                  selectedId={values.importEncoding ?? autoDetectValue}
                  onChange={handleAutoDetectableFieldChange('importEncoding')}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField errorText={errors.exportEncoding} label={I18n.t('Export encoding')}>
                <Select
                  loading={isLoadingSupportedFileEncodings}
                  options={
                    fileEncodingsPlusAutoDetect?.map(encoding => {
                      return {
                        label: encoding ?? I18n.t('Auto-detect'),
                        id: encoding,
                      };
                    }) ?? []
                  }
                  id="exportEncoding"
                  controlId="exportEncoding"
                  selectedId={values.exportEncoding ?? autoDetectValue}
                  onChange={handleAutoDetectableFieldChange('exportEncoding')}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField
                errorText={errors.zipCharset}
                label={I18n.t('Encoding of zipped file names')}
              >
                <Select
                  loading={isLoadingSegmentationRules}
                  options={ENCODING_ZIP_FILE}
                  id="zipCharset"
                  controlId="zipCharset"
                  selectedId={'' + values.zipCharset}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField errorText={errors.fileFormat} label={I18n.t('File format')}>
                <Select
                  loading={isLoadingSupportedFileFormats}
                  options={
                    fileFormatsPlusNone?.map(ff => {
                      return {
                        label: ff.title,
                        id: ff.backendFormat,
                      };
                    }) ?? []
                  }
                  id="fileFormat"
                  controlId="fileFormat"
                  selectedId={values.fileFormat ?? autoDetectValue}
                  onChange={handleAutoDetectableFieldChange('fileFormat')}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField
                errorText={errors.sourceSegmentationRuleId}
                label={I18n.t('Source segmentation rules')}
              >
                <Select
                  loading={isLoadingSegmentationRules}
                  options={
                    segmentationRulesPlusNone?.map(sr => {
                      return {
                        label: sr.name,
                        labelTag: formatLocaleCode(sr.locale),
                        id: '' + sr.id,
                      };
                    }) ?? []
                  }
                  id="sourceSegmentationRuleId"
                  controlId="sourceSegmentationRuleId"
                  selectedId={'' + values.sourceSegmentationRuleId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField
                errorText={errors.targetSegmentationRuleId}
                label={I18n.t('Target segmentation rules')}
              >
                <Select
                  loading={isLoadingSegmentationRules}
                  options={
                    segmentationRulesPlusNone?.map(sr => {
                      return {
                        label: sr.name,
                        labelTag: formatLocaleCode(sr.locale),
                        id: '' + sr.id,
                      };
                    }) ?? []
                  }
                  id="targetSegmentationRuleId"
                  controlId="targetSegmentationRuleId"
                  selectedId={'' + values.targetSegmentationRuleId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Max. target segment length in characters')}
                  checked={values.targetLength}
                  id="targetLength"
                  controlId="targetLength"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Input
                  disabled={!values.targetLength}
                  value={values.targetLengthMax?.toString()}
                  id="targetLengthMax"
                  controlId="targetLengthMax"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Max. target segment length in % of source')}
                  checked={values.targetLengthPercent}
                  id="targetLengthPercent"
                  controlId="targetLengthPercent"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Input
                  disabled={!values.targetLengthPercent}
                  value={values.targetLengthPercentValue?.toString()}
                  id="targetLengthPercentValue"
                  controlId="targetLengthPercentValue"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </ExpandableSection>
        <ExpandableSection
          variant="container"
          header={<Header variant="h2">Adobe FrameMaker</Header>}
        >
          {/* MIF */}
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField>
                <Checkbox
                  label={I18n.t('Extract body pages')}
                  checked={values.mifExtractBodyPages}
                  id="mifExtractBodyPages"
                  controlId="mifExtractBodyPages"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Extract reference pages')}
                  checked={values.mifExtractReferencePages}
                  id="mifExtractReferencePages"
                  controlId="mifExtractReferencePages"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Extract custom reference pages')}
                  checked={values.mifExtractCustomReferencePages}
                  id="mifExtractCustomReferencePages"
                  controlId="mifExtractCustomReferencePages"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Extract default reference pages')}
                  checked={values.mifExtractDefaultReferencePages}
                  id="mifExtractDefaultReferencePages"
                  controlId="mifExtractDefaultReferencePages"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Extract master pages')}
                  checked={values.mifExtractMasterPages}
                  id="mifExtractMasterPages"
                  controlId="mifExtractMasterPages"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Extract hidden pages')}
                  checked={values.mifExtractHiddenPages}
                  id="mifExtractHiddenPages"
                  controlId="mifExtractHiddenPages"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Extract variables')}
                  checked={values.mifExtractVariables}
                  id="mifExtractVariables"
                  controlId="mifExtractVariables"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Extract used variables')}
                  checked={values.mifExtractUsedVariables}
                  id="mifExtractUsedVariables"
                  controlId="mifExtractUsedVariables"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Extract index markers')}
                  checked={values.mifExtractIndexMarkers}
                  id="mifExtractIndexMarkers"
                  controlId="mifExtractIndexMarkers"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Extract links')}
                  checked={values.mifExtractLinks}
                  id="mifExtractLinks"
                  controlId="mifExtractLinks"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Extract text from XRefDef')}
                  checked={values.mifExtractXRefs}
                  id="mifExtractXRefs"
                  controlId="mifExtractXRefs"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Extract text from used XRefDef')}
                  checked={values.mifExtractUsedXRefs}
                  id="mifExtractUsedXRefs"
                  controlId="mifExtractUsedXRefs"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Extract text from PgfNumFormat')}
                  checked={values.mifExtractPgfNumberFormats}
                  id="mifExtractPgfNumberFormats"
                  controlId="mifExtractPgfNumberFormats"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Extract text from used PgfNumFormat')}
                  checked={values.mifExtractUsedPgfNumberFormats}
                  id="mifExtractUsedPgfNumberFormats"
                  controlId="mifExtractUsedPgfNumberFormats"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Extract hidden conditional text')}
                  checked={values.mifExtractHiddenConditionalText}
                  id="mifExtractHiddenConditionalText"
                  controlId="mifExtractHiddenConditionalText"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </ExpandableSection>

        <ExpandableSection
          variant="container"
          header={<Header variant="h2">Adobe InDesign</Header>}
        >
          {/* IDML */}
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField label={I18n.t('Convert to ATMS tags')} hintText={regexHint}>
                <Input
                  value={values.idmlTagRegexp}
                  id="idmlTagRegexp"
                  controlId="idmlTagRegexp"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField
                label={I18n.t('Target font')}
                hintText={
                  <T>
                    Replace font with the specified Target font. Otherwise, replace with default
                    fonts listed{' '}
                    <Link
                      external
                      variant="info"
                      target="_blank"
                      href="https://w.amazon.com/bin/view/INTech/TranslationServices/LinguistExperience/ATMS/Docs/Adobe_InDesign_ATMS"
                    >
                      here
                    </Link>
                    .
                  </T>
                }
              >
                <Input
                  value={values.idmlTargetFont}
                  id="idmlTargetFont"
                  controlId="idmlTargetFont"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={!values.idmlReplaceFont}
                />
                <Checkbox
                  label={I18n.t('Replace font')}
                  checked={values.idmlReplaceFont}
                  id="idmlReplaceFont"
                  controlId="idmlReplaceFont"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Checkbox
                  label={I18n.t('Keep kerning')}
                  checked={values.idmlKeepKerning}
                  id="idmlKeepKerning"
                  controlId="idmlKeepKerning"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Checkbox
                  label={I18n.t('Keep tracking')}
                  checked={values.idmlKeepTracking}
                  id="idmlKeepTracking"
                  controlId="idmlKeepTracking"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Extract notes')}
                  checked={values.idmlExtractNotes}
                  id="idmlExtractNotes"
                  controlId="idmlExtractNotes"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Simplify codes')}
                  checked={values.idmlSimplifyCodes}
                  id="idmlSimplifyCodes"
                  controlId="idmlSimplifyCodes"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Extract master spreads')}
                  checked={values.idmlExtractMasterSpreads}
                  id="idmlExtractMasterSpreads"
                  controlId="idmlExtractMasterSpreads"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Extract invisible layers')}
                  checked={values.idmlExtractInvisibleLayers}
                  id="idmlExtractInvisibleLayers"
                  controlId="idmlExtractInvisibleLayers"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Extract hidden conditional text')}
                  checked={values.idmlExtractHiddenConditionalText}
                  id="idmlExtractHiddenConditionalText"
                  controlId="idmlExtractHiddenConditionalText"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Extract locked layers')}
                  checked={values.idmlExtractLockedLayer}
                  id="idmlExtractLockedLayer"
                  controlId="idmlExtractLockedLayer"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Remove XML elements')}
                  checked={values.idmlRemoveXmlElements}
                  id="idmlRemoveXmlElements"
                  controlId="idmlRemoveXmlElements"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </ExpandableSection>

        <ExpandableSection
          variant="container"
          header={<Header variant="h2">Android Strings</Header>}
        >
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField label={I18n.t('Tag regex')} hintText={regexHint}>
                <Input
                  value={values.androidStringTagRegexp}
                  id="androidStringTagRegexp"
                  controlId="androidStringTagRegexp"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </ExpandableSection>

        {/* TODO: There seems to be an up-stream issue with saving CSV config values */}

        <ExpandableSection variant="container" header={<Header variant="h2">CSV</Header>}>
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField label={I18n.t('Delimiter')}>
                <Input
                  value={values.csvDelimiter}
                  id="csvDelimiter"
                  controlId="csvDelimiter"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField label={I18n.t('Tag regex')} hintText={regexHint}>
                <Input
                  value={values.csvTagRegexp}
                  id="csvTagRegexp"
                  controlId="csvTagRegexp"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField
                label={I18n.t('Import columns')}
                hintText={
                  <T>
                    <div>'2': only column 2</div>
                    <div>'-4': columns 1-4</div>
                    <div>'3-': from the third to the last column</div>
                    <div>'-2,4,7-9': columns 1, 2, 4, 7, 8, 9</div>
                  </T>
                }
              >
                <Input
                  value={values.csvImportColumns}
                  id="csvImportColumns"
                  controlId="csvImportColumns"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField
                label={I18n.t('Import rows')}
                hintText={
                  <T>
                    <div>'2': only row 2</div>
                    <div>'-4': rows 1-4</div>
                    <div>'3-': from the third to the last row</div>
                    <div>'-2,4,7-9': rows 1, 2, 4, 7, 8, 9</div>
                  </T>
                }
              >
                <Input
                  value={values.csvImportRows}
                  id="csvImportRows"
                  controlId="csvImportRows"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField label={I18n.t('Context note column')} hintText={I18n.t('Column number')}>
                <Input
                  value={values.csvContextNoteColumns}
                  id="csvContextNoteColumns"
                  controlId="csvContextNoteColumns"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField label={I18n.t('Context key column')} hintText={I18n.t('Column number')}>
                <Input
                  value={values.csvContextKeyColumn}
                  id="csvContextKeyColumn"
                  controlId="csvContextKeyColumn"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField label={I18n.t('Max target length')} hintText={I18n.t('Column number')}>
                <Input
                  value={values.csvMaxLengthColumn}
                  id="csvMaxLengthColumn"
                  controlId="csvMaxLengthColumn"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Use HTML subfilter')}
                  checked={values.csvUseHtmlSubfilter}
                  id="csvUseHtmlSubfilter"
                  controlId="csvUseHtmlSubfilter"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </ExpandableSection>

        <ExpandableSection variant="container" header={<Header variant="h2">DITA</Header>}>
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField
                label={I18n.t('Add translatable elements')}
                hintText={I18n.t(
                  'Default translatable elements will be imported. Specify additional elements separated by comma.'
                )}
              >
                <Input
                  value={values.ditaTranslatableElements}
                  id="ditaTranslatableElements"
                  controlId="ditaTranslatableElements"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField
                label={I18n.t('Add non-translatable elements')}
                hintText={I18n.t(
                  'Default non-translatable elements will be skipped. Specify additional elements separated by comma.'
                )}
              >
                <Input
                  value={values.ditaSkipElements}
                  id="ditaSkipElements"
                  controlId="ditaSkipElements"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField
                label={I18n.t('Add translatable inline elements')}
                hintText={I18n.t(
                  'Default translatable inline elements will be imported. Specify additional elements separated by comma.'
                )}
              >
                <Input
                  value={values.ditaInlineElements}
                  id="ditaInlineElements"
                  controlId="ditaInlineElements"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField
                label={I18n.t('Add non-translatable inline elements')}
                hintText={I18n.t(
                  'Default non-translatable inline elements will be imported. Specify additional elements separated by comma.'
                )}
              >
                <Input
                  value={values.ditaInlineSingleElements}
                  id="ditaInlineSingleElements"
                  controlId="ditaInlineSingleElements"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </ExpandableSection>

        <ExpandableSection variant="container" header={<Header variant="h2">DocBook</Header>}>
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField
                label={I18n.t('Add translatable elements')}
                hintText={I18n.t(
                  'Default translatable elements will be imported. Specify additional elements separated by comma.'
                )}
              >
                <Input
                  value={values.docBookTranslatableElements}
                  id="docBookTranslatableElements"
                  controlId="docBookTranslatableElements"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField
                label={I18n.t('Add non-translatable elements')}
                hintText={I18n.t(
                  'Default non-translatable elements will be skipped. Specify additional elements separated by comma.'
                )}
              >
                <Input
                  value={values.docBookSkipElements}
                  id="docBookSkipElements"
                  controlId="docBookSkipElements"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField
                label={I18n.t('Add translatable inline elements')}
                hintText={I18n.t(
                  'Default translatable inline elements will be imported. Specify additional elements separated by comma.'
                )}
              >
                <Input
                  value={values.docBookInlineElements}
                  id="docBookInlineElements"
                  controlId="docBookInlineElements"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField
                label={I18n.t('Add non-translatable inline elements')}
                hintText={I18n.t(
                  'Default non-translatable inline elements will be imported. Specify additional elements separated by comma.'
                )}
              >
                <Input
                  value={values.docBookInlineSingleElements}
                  id="docBookInlineSingleElements"
                  controlId="docBookInlineSingleElements"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </ExpandableSection>

        <ExpandableSection variant="container" header={<Header variant="h2">HTML</Header>}>
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField label={I18n.t('Convert to ATMS tags')} hintText={regexHint}>
                <Input
                  value={values.htmlTagRegexp}
                  id="htmlTagRegexp"
                  controlId="htmlTagRegexp"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField label={I18n.t('Translatable meta tags')} hintText={regexHint}>
                <Input
                  value={values.htmlTranslatableMetaTagRegexp}
                  id="htmlTranslatableMetaTagRegexp"
                  controlId="htmlTranslatableMetaTagRegexp"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField label={I18n.t('Character entities to ATMS tags')} hintText={htmlHint}>
                <Input
                  value={values.htmlCharacterEntitiesToConvertToTags}
                  id="htmlCharacterEntitiesToConvertToTags"
                  controlId="htmlCharacterEntitiesToConvertToTags"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField
                label={I18n.t('Exclude elements')}
                hintText={I18n.t('Comma separated list of element names')}
              >
                <Input
                  value={values.htmlExcludeElements}
                  id="htmlExcludeElements"
                  controlId="htmlExcludeElements"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Break tag (<br/>) creates new segment')}
                  checked={values.htmlBreakTagCreatesSegment}
                  id="htmlBreakTagCreatesSegment"
                  controlId="htmlBreakTagCreatesSegment"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Convert non-HTML tags to ATMS tags')}
                  checked={values.htmlUnknownTagCreatesTag}
                  id="htmlUnknownTagCreatesTag"
                  controlId="htmlUnknownTagCreatesTag"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Preserve whitespace')}
                  checked={values.htmlPreserveWhitespace}
                  id="htmlPreserveWhitespace"
                  controlId="htmlPreserveWhitespace"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </ExpandableSection>

        <ExpandableSection variant="container" header={<Header variant="h2">iOS Strings</Header>}>
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField label={I18n.t('Tag regex')} hintText={regexHint}>
                <Input
                  value={values.macStringsTagRegexp}
                  id="macStringsTagRegexp"
                  controlId="macStringsTagRegexp"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Use HTML subfilter')}
                  checked={values.macStringsUseHtmlSubfilter}
                  id="macStringsUseHtmlSubfilter"
                  controlId="macStringsUseHtmlSubfilter"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </ExpandableSection>

        <ExpandableSection variant="container" header={<Header variant="h2">JSON</Header>}>
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField label={I18n.t('Convert to ATMS tags')} hintText={regexHint}>
                <Input
                  value={values.jsonTagRegexp}
                  id="jsonTagRegexp"
                  controlId="jsonTagRegexp"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField label={I18n.t('Import specific keys only')} hintText={regexHint}>
                <Input
                  value={values.jsonIncludedKeysRegexp}
                  id="jsonIncludedKeysRegexp"
                  controlId="jsonIncludedKeysRegexp"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField label={I18n.t('Context note')}>
                <Input
                  value={values.jsonContextNotePath}
                  id="jsonContextNotePath"
                  controlId="jsonContextNotePath"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField label={I18n.t('Max. target length')}>
                <Input
                  value={values.jsonMaxLengthPath}
                  id="jsonMaxLengthPath"
                  controlId="jsonMaxLengthPath"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Use HTML subfilter')}
                  checked={values.jsonUseHtmlSubfilter}
                  id="jsonUseHtmlSubfilter"
                  controlId="jsonUseHtmlSubfilter"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </ExpandableSection>

        <ExpandableSection variant="container" header={<Header variant="h2">Markdown</Header>}>
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField label={I18n.t('Convert to ATMS tags')} hintText={regexHint}>
                <Input
                  value={values.markdownConvertToTags}
                  id="markdownConvertToTags"
                  controlId="markdownConvertToTags"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Translate image alt text')}
                  checked={values.markdownTranslateImageAltText}
                  id="markdownTranslateImageAltText"
                  controlId="markdownTranslateImageAltText"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Translate YAML metadata header')}
                  checked={values.markdownTranslateHeaderMetadata}
                  id="markdownTranslateHeaderMetadata"
                  controlId="markdownTranslateHeaderMetadata"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Translate fenced code blocks')}
                  checked={values.markdownTranslateCodeBlocks}
                  id="markdownTranslateCodeBlocks"
                  controlId="markdownTranslateCodeBlocks"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Translate inline code blocks')}
                  checked={values.markdownTranslateInlineCodeBlocks}
                  id="markdownTranslateInlineCodeBlocks"
                  controlId="markdownTranslateInlineCodeBlocks"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Translate URLs in link and images')}
                  checked={values.markdownTranslateUrls}
                  id="markdownTranslateUrls"
                  controlId="markdownTranslateUrls"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </ExpandableSection>

        <ExpandableSection
          variant="container"
          header={<Header variant="h2">Microsoft Excel</Header>}
        >
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField label={I18n.t('Tag regex')} hintText={regexHint}>
                <Input
                  value={values.msExcelTagRegexp}
                  id="msExcelTagRegexp"
                  controlId="msExcelTagRegexp"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Select
                  options={[
                    { id: 'DownRight', label: I18n.t('Columns, left to right') },
                    { id: 'RightDown', label: I18n.t('Rows, left to right') },
                    { id: 'DownLeft', label: I18n.t('Columns, right to left') },
                    { id: 'LeftDown', label: I18n.t('Rows, right to left') },
                  ]}
                  selectedId={values.msExcelCellFlow}
                  id="msExcelCellFlow"
                  controlId="msExcelCellFlow"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Use HTML subfilter')}
                  checked={values.msExcelUseHtmlSubfilter}
                  id="msExcelUseHtmlSubfilter"
                  controlId="msExcelUseHtmlSubfilter"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Import sheet names')}
                  checked={values.msExcelExtractSheetNames}
                  id="msExcelExtractSheetNames"
                  controlId="msExcelExtractSheetNames"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Import comments')}
                  checked={values.msExcelExtractComments}
                  id="msExcelExtractComments"
                  controlId="msExcelExtractComments"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Import hidden columns, hidden rows, and hidden sheets')}
                  checked={values.msExcelExtractHidden}
                  id="msExcelExtractHidden"
                  controlId="msExcelExtractHidden"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Extract other')}
                  checked={values.msExcelExtractOther}
                  id="msExcelExtractOther"
                  controlId="msExcelExtractOther"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </ExpandableSection>

        <ExpandableSection
          variant="container"
          header={<Header variant="h2">Microsoft PowerPoint</Header>}
        >
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField>
                <Checkbox
                  label={I18n.t('Import notes')}
                  checked={values.msPowerPointExtractNotes}
                  id="msPowerPointExtractNotes"
                  controlId="msPowerPointExtractNotes"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Import master slides')}
                  checked={values.msPowerPointExtractMasterSlides}
                  id="msPowerPointExtractMasterSlides"
                  controlId="msPowerPointExtractMasterSlides"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Import hidden slides')}
                  checked={values.msPowerPointExtractHidden}
                  id="msPowerPointExtractHidden"
                  controlId="msPowerPointExtractHidden"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Extract other')}
                  checked={values.msPowerPointExtractOther}
                  id="msPowerPointExtractOther"
                  controlId="msPowerPointExtractOther"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </ExpandableSection>

        <ExpandableSection
          variant="container"
          header={<Header variant="h2">Microsoft Word</Header>}
        >
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField label={I18n.t('Convert to ATMS tag')} hintText={regexHint}>
                <Input
                  value={values.msWordTagRegexp}
                  id="msWordTagRegexp"
                  controlId="msWordTagRegexp"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField label={I18n.t('Target font')}>
                <Input
                  value={values.msWordTargetFont}
                  id="msWordTargetFont"
                  controlId="msWordTargetFont"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Import comments')}
                  checked={values.msWordExtractComments}
                  id="msWordExtractComments"
                  controlId="msWordExtractComments"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Import index entries')}
                  checked={values.msWordExtractIndexes}
                  id="msWordExtractIndexes"
                  controlId="msWordExtractIndexes"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Import hyperlink target')}
                  checked={values.msWordExtractHyperlinkTarget}
                  id="msWordExtractHyperlinkTarget"
                  controlId="msWordExtractHyperlinkTarget"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Import document properties')}
                  checked={values.msWordExtractProperties}
                  id="msWordExtractProperties"
                  controlId="msWordExtractProperties"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Import hidden text')}
                  checked={values.msWordExtractHidden}
                  id="msWordExtractHidden"
                  controlId="msWordExtractHidden"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Extract other')}
                  checked={values.msWordExtractOther}
                  id="msWordExtractOther"
                  controlId="msWordExtractOther"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Minimize number of tags')}
                  checked={values.msWordJoinSimilarRuns}
                  id="msWordJoinSimilarRuns"
                  controlId="msWordJoinSimilarRuns"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </ExpandableSection>

        {/* TODO: Need to dynamically generate 'target elements' fields per target language */}

        <ExpandableSection
          variant="container"
          header={<Header variant="h2">Multilingual XML</Header>}
        >
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField label={I18n.t('Tag regex')} hintText={regexHint}>
                <Input
                  value={values.multilingualXmlTagRegexp}
                  id="multilingualXmlTagRegexp"
                  controlId="multilingualXmlTagRegexp"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField label={I18n.t('Translatable elements XPath')} hintText={xpathHint}>
                <Input
                  value={values.multilingualXmlTranslatableElementsXPath}
                  id="multilingualXmlTranslatableElementsXPath"
                  controlId="multilingualXmlTranslatableElementsXPath"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField label={I18n.t('Source elements XPath')}>
                <Input
                  value={values.multilingualXmlSourceElementsXPath}
                  id="multilingualXmlSourceElementsXPath"
                  controlId="multilingualXmlSourceElementsXPath"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField label={I18n.t('Target elements XPath')}>
                <Input
                  value={values.multilingualXmlTargetElementsXPath}
                  id="multilingualXmlTargetElementsXPath"
                  controlId="multilingualXmlTargetElementsXPath"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField label={I18n.t('Non-translatable inline elements XPath')}>
                <Input
                  value={values.multilingualXmlNonTranslatableInlineElementsXPath}
                  id="multilingualXmlNonTranslatableInlineElementsXPath"
                  controlId="multilingualXmlNonTranslatableInlineElementsXPath"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField
                label={I18n.t('Context note XPath')}
                hintText={I18n.t(
                  'Examples: @Note - attribute Note of the source element, ../Note - Note element is sibling of the source element'
                )}
              >
                <Input
                  value={values.multilingualXmlContextNoteXPath}
                  id="multilingualXmlContextNoteXPath"
                  controlId="multilingualXmlContextNoteXPath"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField label={I18n.t('Context key XPath')} hintText={contextKeyHint}>
                <Input
                  value={values.multilingualXmlContextKeyXPath}
                  id="multilingualXmlContextKeyXPath"
                  controlId="multilingualXmlContextKeyXPath"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField
                label={I18n.t('Max length XPath')}
                hintText={I18n.t('Example: @maxlength')}
              >
                <Input
                  value={values.multilingualXmlMaxLengthXPath}
                  id="multilingualXmlMaxLengthXPath"
                  controlId="multilingualXmlMaxLengthXPath"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Segment multilingual XML')}
                  checked={values.multilingualXmlSegment}
                  id="multilingualXmlSegment"
                  controlId="multilingualXmlSegment"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Use HTML subfilter')}
                  checked={values.multilingualXmlUseHtmlSubfilter}
                  id="multilingualXmlUseHtmlSubfilter"
                  controlId="multilingualXmlUseHtmlSubfilter"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Preserve whitespace')}
                  checked={values.multilingualXmlPreserveWhitespace}
                  id="multilingualXmlPreserveWhitespace"
                  controlId="multilingualXmlPreserveWhitespace"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </ExpandableSection>

        <ExpandableSection variant="container" header={<Header variant="h2">PO gettext</Header>}>
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField label={I18n.t('Tag regex')} hintText={regexHint}>
                <Input
                  value={values.poTagRegexp}
                  id="poTagRegexp"
                  controlId="poTagRegexp"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Export multi-line strings')}
                  checked={values.poExportMultilineStrings}
                  id="poExportMultilineStrings"
                  controlId="poExportMultilineStrings"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </ExpandableSection>

        <ExpandableSection variant="container" header={<Header variant="h2">Properties</Header>}>
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField label={I18n.t('Tag regex')} hintText={regexHint}>
                <Input
                  value={values.propertiesTagRegexp}
                  id="propertiesTagRegexp"
                  controlId="propertiesTagRegexp"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Escape all backslashes')}
                  checked={values.propertiesEscapeAllBackslashes}
                  id="propertiesEscapeAllBackslashes"
                  controlId="propertiesEscapeAllBackslashes"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField hintText={I18n.t('Process a segment as HTML if HTML is present')}>
                <Checkbox
                  label={I18n.t('Smart HTML subfilter')}
                  checked={values.propertiesSmartHtmlSubfilter}
                  id="propertiesSmartHtmlSubfilter"
                  controlId="propertiesSmartHtmlSubfilter"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </ExpandableSection>

        <ExpandableSection variant="container" header={<Header variant="h2">Quark Tag</Header>}>
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField>
                <Checkbox
                  label={I18n.t('Remove kerning tracking tags')}
                  checked={values.quarkTagRemoveKerningTrackingTags}
                  id="quarkTagRemoveKerningTrackingTags"
                  controlId="quarkTagRemoveKerningTrackingTags"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </ExpandableSection>

        <ExpandableSection variant="container" header={<Header variant="h2">Trados TTX</Header>}>
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField>
                <Checkbox
                  label={I18n.t('Save confirmed segments to TM on import')}
                  checked={values.ttxSaveConfirmedSegments}
                  id="ttxSaveConfirmedSegments"
                  controlId="ttxSaveConfirmedSegments"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </ExpandableSection>

        <ExpandableSection variant="container" header={<Header variant="h2">TXT</Header>}>
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField label={I18n.t('Tag regex')}>
                <Input
                  value={values.txtTagRegexp}
                  id="txtTagRegexp"
                  controlId="txtTagRegexp"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField label={I18n.t('Translatable text regex')} hintText={regexHint}>
                <Input
                  value={values.txtTranslatableTextRegexp}
                  id="txtTranslatableTextRegexp"
                  controlId="txtTranslatableTextRegexp"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </ExpandableSection>

        <ExpandableSection
          variant="container"
          header={<Header variant="h2">Windows RESX Resources</Header>}
        >
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField label={I18n.t('Tag regex')} hintText={regexHint}>
                <Input
                  value={values.resxTagRegexp}
                  id="resxTagRegexp"
                  controlId="resxTagRegexp"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </ExpandableSection>

        <ExpandableSection variant="container" header={<Header variant="h2">XLIFF 1.2</Header>}>
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              {/* TODO: Missing 'Context note' field */}

              {/* TODO: Get import rules from com/memsource/web/xliff/XliffTagLib.groovy:8 */}

              <TextContent>
                <p>
                  <strong>{I18n.t('Import Segment Rules')}</strong>
                  <br />
                  <small>{xliffImportExportHints}</small>
                </p>
              </TextContent>
              <FormField label={I18n.t('Do not import segments when')} hintText={xliffTextBoxHint}>
                <Textarea
                  value={values.xlfDoNotImportSegmentWhenRules}
                  id="xlfDoNotImportSegmentWhenRules"
                  controlId="xlfDoNotImportSegmentWhenRules"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField label={I18n.t('Set segment confirmed when')} hintText={xliffTextBoxHint}>
                <Textarea
                  value={values.xlfConfirmSegmentWhenRules}
                  id="xlfConfirmSegmentWhenRules"
                  controlId="xlfConfirmSegmentWhenRules"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField label={I18n.t('Set segment locked when')} hintText={xliffTextBoxHint}>
                <Textarea
                  value={values.xlfLockSegmentWhenRules}
                  id="xlfLockSegmentWhenRules"
                  controlId="xlfLockSegmentWhenRules"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <TextContent>
                <p>
                  <strong>{I18n.t('Export Attributes Mapping')}</strong>
                  <br />
                  <small>{xliffImportExportHints}</small>
                </p>
              </TextContent>
              <FormField
                label={I18n.t('Export attributes mapping when segment is confirmed and locked')}
                hintText={xliffTextBoxHint}
              >
                <Textarea
                  value={values.xlfExportConfirmedAndLockedRules}
                  id="xlfExportConfirmedAndLockedRules"
                  controlId="xlfExportConfirmedAndLockedRules"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField
                label={I18n.t('Export attributes mapping when segment is confirmed but not locked')}
                hintText={xliffTextBoxHint}
              >
                <Textarea
                  value={values.xlfExportConfirmedAndNotLockedRules}
                  id="xlfExportConfirmedAndNotLockedRules"
                  controlId="xlfExportConfirmedAndNotLockedRules"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField
                label={I18n.t('Export attributes mapping when segment is not confirmed and locked')}
                hintText={xliffTextBoxHint}
              >
                <Textarea
                  value={values.xlfExportNotConfirmedAndLockedRules}
                  id="xlfExportNotConfirmedAndLockedRules"
                  controlId="xlfExportNotConfirmedAndLockedRules"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField
                label={I18n.t('Export attributes mapping when segment is not confirmed not locked')}
                hintText={xliffTextBoxHint}
              >
                <Textarea
                  value={values.xlfExportNotConfirmedAndNotLockedRules}
                  id="xlfExportNotConfirmedAndNotLockedRules"
                  controlId="xlfExportNotConfirmedAndNotLockedRules"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField label={I18n.t('Context note')} hintText={contextKeyHint}>
                <Input
                  value={values.xlfContextType}
                  id="xlfContextType"
                  controlId="xlfContextType"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Import <note>')}
                  checked={values.xlfExtractNote}
                  id="xlfExtractNote"
                  controlId="xlfExtractNote"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Save confirmed segments to TM on import')}
                  checked={values.xlfSaveConfirmedSegmentsToTMOnImport}
                  id="xlfSaveConfirmedSegmentsToTMOnImport"
                  controlId="xlfSaveConfirmedSegmentsToTMOnImport"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Segment XLIFF')}
                  checked={values.xlfSegment}
                  id="xlfSegment"
                  controlId="xlfSegment"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Interpret line break/page break tags')}
                  checked={values.xlfInterpretLineBreaksTags}
                  id="xlfInterpretLineBreaksTags"
                  controlId="xlfInterpretLineBreaksTags"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Preserve whitespaces')}
                  checked={values.xlfPreserveWhitespace}
                  id="xlfPreserveWhitespace"
                  controlId="xlfPreserveWhitespace"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Copy source to target if segment not imported')}
                  checked={values.xlfCopySourceToTargetIfSegmentNotImported}
                  id="xlfCopySourceToTargetIfSegmentNotImported"
                  controlId="xlfCopySourceToTargetIfSegmentNotImported"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </ExpandableSection>

        <ExpandableSection variant="container" header={<Header variant="h2">XLIFF 2.0</Header>}>
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              {/* TODO: Get import rules from com/memsource/web/xliff/XliffTagLib.groovy:10 */}
              <TextContent>
                <p>
                  <strong>{I18n.t('Import Segment Rules')}</strong>
                  <br />
                  <small>{xliff2ImportExportHints}</small>
                </p>
              </TextContent>
              <FormField label={I18n.t('Do not import segments when')} hintText={xliff2TextBoxHint}>
                <Textarea
                  value={values.xlf2SkipImportRules}
                  id="xlf2SkipImportRules"
                  controlId="xlf2SkipImportRules"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField label={I18n.t('Set segment confirmed when')} hintText={xliff2TextBoxHint}>
                <Textarea
                  value={values.xlf2ConfirmSegmentWhenRules}
                  id="xlf2ConfirmSegmentWhenRules"
                  controlId="xlf2ConfirmSegmentWhenRules"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField label={I18n.t('Set segment locked when')} hintText={xliff2TextBoxHint}>
                <Textarea
                  value={values.xlf2LockSegmentWhenRules}
                  id="xlf2LockSegmentWhenRules"
                  controlId="xlf2LockSegmentWhenRules"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <TextContent>
                <p>
                  <strong>{I18n.t('Export Attributes Mapping')}</strong>
                  <br />
                  <small>{xliff2ImportExportHints}</small>
                </p>
              </TextContent>
              <FormField
                label={I18n.t('Export attributes mapping when segment is confirmed and locked')}
                hintText={xliff2TextBoxHint}
              >
                <Textarea
                  value={values.xlf2ExportConfirmedAndLockedRules}
                  id="xlf2ExportConfirmedAndLockedRules"
                  controlId="xlf2ExportConfirmedAndLockedRules"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField
                label={I18n.t('Export attributes mapping when segment is confirmed and not locked')}
                hintText={xliff2TextBoxHint}
              >
                <Textarea
                  value={values.xlf2ExportConfirmedAndNotLockedRules}
                  id="xlf2ExportConfirmedAndNotLockedRules"
                  controlId="xlf2ExportConfirmedAndNotLockedRules"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField
                label={I18n.t('Export attributes mapping when segment is not confirmed and locked')}
                hintText={xliff2TextBoxHint}
              >
                <Textarea
                  value={values.xlf2ExportNotConfirmedAndLockedRules}
                  id="xlf2ExportNotConfirmedAndLockedRules"
                  controlId="xlf2ExportNotConfirmedAndLockedRules"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField
                label={I18n.t(
                  'Export attributes mapping when segment is not confirmed and not locked'
                )}
                hintText={xliff2TextBoxHint}
              >
                <Textarea
                  value={values.xlf2ExportNotConfirmedAndNotLockedRules}
                  id="xlf2ExportNotConfirmedAndNotLockedRules"
                  controlId="xlf2ExportNotConfirmedAndNotLockedRules"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField label={I18n.t('Context key')} hintText={contextKeyHint}>
                <Input
                  value={values.xlf2ContextKeyXPath}
                  id="xlf2ContextKeyXPath"
                  controlId="xlf2ContextKeyXPath"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Import <note>')}
                  checked={values.xlf2ImportNotes}
                  id="xlf2ImportNotes"
                  controlId="xlf2ImportNotes"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Save confirmed segments to TM on import')}
                  checked={values.xlf2SaveConfirmedSegmentsToTMOnImport}
                  id="xlf2SaveConfirmedSegmentsToTMOnImport"
                  controlId="xlf2SaveConfirmedSegmentsToTMOnImport"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Segment XLIFF')}
                  checked={values.xlf2Segment}
                  id="xlf2Segment"
                  controlId="xlf2Segment"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Interpret line break/page break tags')}
                  checked={values.xlf2InterpretLineBreakTags}
                  id="xlf2InterpretLineBreakTags"
                  controlId="xlf2InterpretLineBreakTags"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Preserve whitespaces')}
                  checked={values.xlf2PreserveWhitespace}
                  id="xlf2PreserveWhitespace"
                  controlId="xlf2PreserveWhitespace"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Copy source to empty target if segment is not imported')}
                  checked={values.xlf2CopySourceToTargetIfSegmentNotImported}
                  id="xlf2CopySourceToTargetIfSegmentNotImported"
                  controlId="xlf2CopySourceToTargetIfSegmentNotImported"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t(
                    "Represent text with attribute 'translate=no' as non-translatable tags"
                  )}
                  checked={values.xlf2RespectTranslateAttribute}
                  id="xlf2RespectTranslateAttribute"
                  controlId="xlf2RespectTranslateAttribute"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </ExpandableSection>

        <ExpandableSection variant="container" header={<Header variant="h2">SDL XLIFF</Header>}>
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <TextContent>
                <p>
                  <strong>{I18n.t('Import Segment Rules')}</strong>
                  <br />
                  <small>{sdlXliffImportExportHints}</small>
                </p>
              </TextContent>
              <FormField
                label={I18n.t('Do not import segments when')}
                hintText={sdlXliffTextBoxHint}
              >
                <Textarea
                  value={values.sdlXlfSkipImportRules}
                  id="sdlXlfSkipImportRules"
                  controlId="sdlXlfSkipImportRules"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField
                label={I18n.t('Set segment confirmed when')}
                hintText={sdlXliffTextBoxHint}
              >
                <Textarea
                  value={values.sdlXlfImportAsConfirmedRules}
                  id="sdlXlfImportAsConfirmedRules"
                  controlId="sdlXlfImportAsConfirmedRules"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField label={I18n.t('Set segment locked when')} hintText={sdlXliffTextBoxHint}>
                <Textarea
                  value={values.sdlXlfImportAsLockedRules}
                  id="sdlXlfImportAsLockedRules"
                  controlId="sdlXlfImportAsLockedRules"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <TextContent>
                <p>
                  <strong>{I18n.t('Export Attributes Mapping')}</strong>
                  <br />
                  <small>{sdlXliffImportExportHints}</small>
                </p>
              </TextContent>
              <FormField
                label={I18n.t('Export attributes mapping when segment is confirmed and locked')}
                hintText={sdlXliffTextBoxHint}
              >
                <Textarea
                  value={values.sdlXlfExportAttrsWhenConfirmedAndLocked}
                  id="sdlXlfExportAttrsWhenConfirmedAndLocked"
                  controlId="sdlXlfExportAttrsWhenConfirmedAndLocked"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField
                label={I18n.t('Export attributes mapping when segment is confirmed and not locked')}
                hintText={sdlXliffTextBoxHint}
              >
                <Textarea
                  value={values.sdlXlfExportAttrsWhenConfirmedAndNotLocked}
                  id="sdlXlfExportAttrsWhenConfirmedAndNotLocked"
                  controlId="sdlXlfExportAttrsWhenConfirmedAndNotLocked"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField
                label={I18n.t('Export attributes mapping when segment is not confirmed and locked')}
                hintText={sdlXliffTextBoxHint}
              >
                <Textarea
                  value={values.sdlXlfExportAttrsWhenNotConfirmedAndLocked}
                  id="sdlXlfExportAttrsWhenNotConfirmedAndLocked"
                  controlId="sdlXlfExportAttrsWhenNotConfirmedAndLocked"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField
                label={I18n.t(
                  'Export attributes mapping when segment is not confirmed and not locked'
                )}
                hintText={sdlXliffTextBoxHint}
              >
                <Textarea
                  value={values.sdlXlfExportAttrsWhenNotConfirmedAndNotLocked}
                  id="sdlXlfExportAttrsWhenNotConfirmedAndNotLocked"
                  controlId="sdlXlfExportAttrsWhenNotConfirmedAndNotLocked"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Save confirmed segments to TM on import')}
                  checked={values.sdlXlfSaveConfirmedSegments}
                  id="sdlXlfSaveConfirmedSegments"
                  controlId="sdlXlfSaveConfirmedSegments"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </ExpandableSection>

        <ExpandableSection variant="container" header={<Header variant="h2">XML</Header>}>
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField label={I18n.t('Tag regex')} hintText={regexHint}>
                <Input
                  value={values.xmlTagRegexp}
                  id="xmlTagRegexp"
                  controlId="xmlTagRegexp"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField label={I18n.t('Character entities to preserve on export')}>
                <Input
                  value={values.xmlCharacterEntitiesToPreserveOnExport}
                  id="xmlCharacterEntitiesToPreserveOnExport"
                  controlId="xmlCharacterEntitiesToPreserveOnExport"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField label={I18n.t('XSLT url')} hintText={xsltHint}>
                <Input
                  value={values.xmlXslUrl}
                  id="xmlXslUrl"
                  controlId="xmlXslUrl"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField
                label={I18n.t('Rules format')}
                hintText={
                  values.xmlRulesFormat === 'XPATH'
                    ? xpathHint
                    : I18n.t('Example: "name,title,para". Use * to select all.')
                }
              >
                <Select
                  options={[
                    { id: 'PLAIN', label: I18n.t('Plain') },
                    { id: 'XPATH', label: I18n.t('XPath') },
                  ]}
                  selectedId={values.xmlRulesFormat}
                  id="xmlRulesFormat"
                  controlId="xmlRulesFormat"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
            </div>
          </ColumnLayout>
          {values.xmlRulesFormat === 'PLAIN' && (
            <ColumnLayout columns={2}>
              <div data-awsui-column-layout-root="true">
                <React.Fragment>
                  <FormField label={I18n.t('Elements')}>
                    <Input
                      value={values.xmlIncludeExcludeTags}
                      id="xmlIncludeExcludeTags"
                      controlId="xmlIncludeExcludeTags"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {/* TODO: Fix change handler */}
                    <RadioGroup
                      items={[
                        { label: I18n.t('Include selected'), value: 'true' },
                        { label: I18n.t('Exclude selected'), value: 'false' },
                      ]}
                      value={'' + values.xmlIncludeTags}
                      name="xmlIncludeTags"
                      onChange={handleChange}
                    />
                  </FormField>
                  <FormField label={I18n.t('Attributes')}>
                    <Input
                      value={values.xmlIncludeExcludeAttributes}
                      id="xmlIncludeExcludeAttributes"
                      controlId="xmlIncludeExcludeAttributes"
                      onChange={handleXmlIncludeExcludeAttributes}
                      onBlur={handleBlur}
                    />
                    {/* TODO: Fix change handler */}
                    <RadioGroup
                      items={[
                        { label: I18n.t('Include selected'), value: 'true' },
                        { label: I18n.t('Exclude selected'), value: 'false' },
                      ]}
                      value={'' + values.xmlIncludeAttributes}
                      name="xmlIncludeAttributes"
                      onChange={handleChange}
                    />
                  </FormField>
                  <FormField label={I18n.t('Locked elements')}>
                    <Input
                      value={values.xmlLockedTags}
                      id="xmlLockedTags"
                      controlId="xmlLockedTags"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormField>
                  <FormField label={I18n.t('Locked attributes')}>
                    <Input
                      value={values.xmlLockedAttributes}
                      id="xmlLockedAttributes"
                      controlId="xmlLockedAttributes"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormField>
                  <FormField label={I18n.t('Elements to process as HTML')}>
                    <Input
                      value={values.xmlElementsToProcessAsHtml}
                      id="xmlElementsToProcessAsHtml"
                      controlId="xmlElementsToProcessAsHtml"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Checkbox
                      label={I18n.t('Smart HTML subfilter')}
                      checked={values.xmlSmartHtmlSubfilter}
                      id="xmlSmartHtmlSubfilter"
                      controlId="xmlSmartHtmlSubfilter"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormField>
                  <FormField label={I18n.t('Translatable inline elements')}>
                    <Input
                      value={values.xmlInlineTags}
                      id="xmlInlineTags"
                      controlId="xmlInlineTags"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Checkbox
                      label={I18n.t('Identify inline elements automatically')}
                      checked={values.xmlSmartInlineTags}
                      id="xmlSmartInlineTags"
                      controlId="xmlSmartInlineTags"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormField>
                  <FormField label={I18n.t('Non-translatable inline elements')}>
                    <Input
                      value={values.xmlInlineSingleTag}
                      id="xmlInlineSingleTag"
                      controlId="xmlInlineSingleTag"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormField>
                </React.Fragment>
              </div>
            </ColumnLayout>
          )}

          {values.xmlRulesFormat === 'XPATH' && (
            <ColumnLayout columns={2}>
              <div data-awsui-column-layout-root="true">
                <React.Fragment>
                  <FormField label={I18n.t('Elements and attributes XPath')}>
                    <Input
                      value={values.xmlElementsAndAttributesXPath}
                      id="xmlElementsAndAttributesXPath"
                      controlId="xmlElementsAndAttributesXPath"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormField>
                  <FormField label={I18n.t('Locked elements and attributes XPath')}>
                    <Input
                      value={values.xmlLockedElementsAndAttributesXPath}
                      id="xmlLockedElementsAndAttributesXPath"
                      controlId="xmlLockedElementsAndAttributesXPath"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormField>
                  <FormField label={I18n.t('Inline elements XPath')}>
                    <Input
                      value={values.xmlInlineElementsXPath}
                      id="xmlInlineElementsXPath"
                      controlId="xmlInlineElementsXPath"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Checkbox
                      label={I18n.t('Identify inline elements automatically')}
                      checked={values.xmlXpathSmartInlineElement}
                      id="xmlXpathSmartInlineElement"
                      controlId="xmlXpathSmartInlineElement"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormField>
                  <FormField label={I18n.t('Inline single element XPath')}>
                    <Input
                      value={values.xmlInlineSingleElementXPath}
                      id="xmlInlineSingleElementXPath"
                      controlId="xmlInlineSingleElementXPath"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormField>
                  <FormField label={I18n.t('Subfilter elements XPath')}>
                    <Input
                      value={values.xmlSubfilterElementsXPath}
                      id="xmlSubfilterElementsXPath"
                      controlId="xmlSubfilterElementsXPath"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Checkbox
                      label={I18n.t('Smart HTML subfilter')}
                      checked={values.xmlXPathSmartHtmlSubfilter}
                      id="xmlXPathSmartHtmlSubfilter"
                      controlId="xmlXPathSmartHtmlSubfilter"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormField>
                  <FormField label={I18n.t('Preserve whitespace XPath')}>
                    <Input
                      value={values.xmlPreserveWhitespaceXPath}
                      id="xmlPreserveWhitespaceXPath"
                      controlId="xmlPreserveWhitespaceXPath"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormField>
                  <FormField label={I18n.t('Context note XPath')}>
                    <Input
                      value={values.xmlContextNoteXPath}
                      id="xmlContextNoteXPath"
                      controlId="xmlContextNoteXPath"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormField>
                  {/* TODO: Not sure about this one */}
                  <FormField label={I18n.t('Max length XPath')}>
                    <Input
                      value={values.xmlMaxLengthXPath}
                      id="xmlMaxLengthXPath"
                      controlId="xmlMaxLengthXPath"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormField>
                  <FormField label={I18n.t('Context key XPath')}>
                    <Input
                      value={values.xmlContextKeyXPath}
                      id="xmlContextKeyXPath"
                      controlId="xmlContextKeyXPath"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormField>
                </React.Fragment>
              </div>
            </ColumnLayout>
          )}
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField>
                <Checkbox
                  label={I18n.t('Segment XML')}
                  checked={values.xmlSegment}
                  id="xmlSegment"
                  controlId="xmlSegment"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Import XML entities')}
                  checked={values.xmlImportEntities}
                  id="xmlImportEntities"
                  controlId="xmlImportEntities"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Escape unresolved entities')}
                  checked={values.xmlEscapeUnresolvedEntities}
                  id="xmlEscapeUnresolvedEntities"
                  controlId="xmlEscapeUnresolvedEntities"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Ignore pre-processing instructions')}
                  checked={values.xmlIgnorePreprocessingInstructions}
                  id="xmlIgnorePreprocessingInstructions"
                  controlId="xmlIgnorePreprocessingInstructions"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </ExpandableSection>

        <ExpandableSection variant="container" header={<Header variant="h2">YAML</Header>}>
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField label={I18n.t('Convert to ATMS tag')} hintText={regexHint}>
                <Input
                  value={values.yamlTagRegexp}
                  id="yamlTagRegexp"
                  controlId="yamlTagRegexp"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField label={I18n.t('Import specific keys only')} hintText={regexHint}>
                <Input
                  value={values.yamlContextKeyRegexp}
                  id="yamlContextKeyRegexp"
                  controlId="yamlContextKeyRegexp"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField label={I18n.t('Context note')} hintText={I18n.t('Example: ../title/en')}>
                <Input
                  value={values.yamlContextNoteRegexp}
                  id="yamlContextNoteRegexp"
                  controlId="yamlContextNoteRegexp"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
              <FormField>
                <Checkbox
                  label={I18n.t('Use HTML subfilter')}
                  checked={values.yamlUseHtmlSubfilter}
                  id="yamlUseHtmlSubfilter"
                  controlId="yamlUseHtmlSubfilter"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </ExpandableSection>

        <br />
        {!isProjectTemplateMode && (
          <TranslationMemoryMatchingSettingsConfiguration
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        )}

        {reducerState.isError && (
          <div className={formSpacing}>
            <Flash
              id="validation-error"
              type="error"
              dismissible={true}
              content={I18n.t('Please try again. If the issue persists, contact support.')}
              header={I18n.t('Something went wrong.')}
            />
          </div>
        )}
      </Form>
    </FormContainer>
  );
};

export const JobCreateStep3FileImporting = <T extends JobCreateAPIStep3>(
  props: Props<T>
): WizardStepWithSummary => {
  const title = I18n.t('File importing');
  return {
    title: title,
    info: (): ReactElement => <HelpInfoLink helpId={PROJECT_CREATE_ASSETS_HELP} />,
    description: I18n.t('Settings for importing files'),
    content: (): ReactElement => <Content {...props} />,
    isOptional: true,
    summary: (currentValue): ReactNode => {
      const { values, supportedFileFormats, segmentationRules } = props;
      const projectTemplate = currentValue as ProjectTemplate;
      const summaryElements = [
        coalesceSummaryValue({
          value: values.importEncoding ?? autoDetectValue,
          currentValue: projectTemplate?.importEncoding,
          label: I18n.t('Import encoding'),
        }),
        coalesceSummaryValue({
          value: values.exportEncoding ?? autoDetectValue,
          currentValue: projectTemplate?.exportEncoding,
          label: I18n.t('Export encoding'),
        }),
        coalesceSummaryValue({
          value: values.zipCharset,
          currentValue: projectTemplate?.zipCharset,
          label: I18n.t('Encoding of zipped file names'),
        }),
        coalesceSummaryValue({
          value: values.fileFormat ?? autoDetectValue,
          currentValue: projectTemplate?.fileFormat,
          label: I18n.t('File format'),
          displayValue: supportedFileFormats?.filter(
            fileFormat => values.fileFormat === fileFormat.backendFormat
          )?.[0]?.title, // TODO: Make lookup table
        }),
        coalesceSummaryValue({
          value: values.sourceSegmentationRuleId,
          currentValue: projectTemplate?.sourceSegmentationRuleId,
          label: I18n.t('Source segmentation rule'),
          displayValue: segmentationRules?.filter(
            segRule => values.sourceSegmentationRuleId === segRule.id
          )?.[0]?.name, // TODO: Make lookup table
        }),
        coalesceSummaryValue({
          value: values.targetSegmentationRuleId,
          currentValue: projectTemplate?.targetSegmentationRuleId,
          label: I18n.t('Target segmentation rule'),
          displayValue: segmentationRules?.filter(
            segRule => values.targetSegmentationRuleId === segRule.id
          )?.[0]?.name, // TODO: Make lookup table
        }),

        coalesceSummaryValue({
          value: values.ditaTranslatableElements,
          currentValue: projectTemplate?.ditaTranslatableElements,
          label: I18n.t('DITA: translatable elements'),
        }),
        coalesceSummaryValue({
          value: values.ditaSkipElements,
          currentValue: projectTemplate?.ditaSkipElements,
          label: I18n.t('DITA: non-translatable elements'),
        }),
        coalesceSummaryValue({
          value: values.ditaInlineElements,
          currentValue: projectTemplate?.ditaInlineElements,
          label: I18n.t('DITA: translatable inline elements'),
        }),
        coalesceSummaryValue({
          value: values.ditaInlineSingleElements,
          currentValue: projectTemplate?.ditaInlineSingleElements,
          label: I18n.t('DITA: non-translatable inline elements'),
        }),

        coalesceSummaryValue({
          value: values.docBookTranslatableElements,
          currentValue: projectTemplate?.docBookTranslatableElements,
          label: I18n.t('DocBook: translatable elements'),
        }),
        coalesceSummaryValue({
          value: values.docBookSkipElements,
          currentValue: projectTemplate?.docBookSkipElements,
          label: I18n.t('DocBook: non-translatable elements'),
        }),
        coalesceSummaryValue({
          value: values.docBookInlineElements,
          currentValue: projectTemplate?.docBookInlineElements,
          label: I18n.t('DocBook: translatable inline elements'),
        }),
        coalesceSummaryValue({
          value: values.docBookInlineSingleElements,
          currentValue: projectTemplate?.docBookInlineSingleElements,
          label: I18n.t('DocBook: non-translatable inline elements'),
        }),

        coalesceSummaryValue({
          value: values.htmlCharacterEntitiesToConvertToTags,
          currentValue: projectTemplate?.htmlCharacterEntitiesToConvertToTags,
          label: I18n.t('HTML: character entities to convert to tags'),
        }),
        coalesceSummaryValue({
          value: values.htmlTranslatableMetaTagRegexp,
          currentValue: projectTemplate?.htmlTranslatableMetaTagRegexp,
          label: I18n.t('HTML: translatable meta tag regex'),
        }),
        coalesceSummaryValue({
          value: values.htmlExcludeElements,
          currentValue: projectTemplate?.htmlExcludeElements,
          label: I18n.t('HTML: exclude elements'),
        }),
        coalesceSummaryValue({
          value: values.htmlBreakTagCreatesSegment,
          currentValue: projectTemplate?.htmlBreakTagCreatesSegment,
          label: I18n.t('HTML: break tag creates segment'),
          displayValue: values.htmlBreakTagCreatesSegment ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.htmlUnknownTagCreatesTag,
          currentValue: projectTemplate?.htmlUnknownTagCreatesTag,
          label: I18n.t('HTML: unknown tag creates tag'),
          displayValue: values.htmlUnknownTagCreatesTag ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.htmlPreserveWhitespace,
          currentValue: projectTemplate?.htmlPreserveWhitespace,
          label: I18n.t('HTML: preserve whitespace'),
          displayValue: values.htmlPreserveWhitespace ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),

        coalesceSummaryValue({
          value: values.idmlTagRegexp,
          currentValue: projectTemplate?.idmlTagRegexp,
          label: I18n.t('Adobe InDesign: tag regex'),
        }),
        coalesceSummaryValue({
          value: values.idmlTargetFont,
          currentValue: projectTemplate?.idmlTargetFont,
          label: I18n.t('Adobe InDesign: target font'),
        }),
        coalesceSummaryValue({
          value: values.idmlExtractNotes,
          currentValue: projectTemplate?.idmlExtractNotes,
          label: I18n.t('Adobe InDesign: extract notes'),
          displayValue: values.idmlExtractNotes ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.idmlSimplifyCodes,
          currentValue: projectTemplate?.idmlSimplifyCodes,
          label: I18n.t('Adobe InDesign: simplify codes'),
          displayValue: values.idmlSimplifyCodes ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.idmlExtractMasterSpreads,
          currentValue: projectTemplate?.idmlExtractMasterSpreads,
          label: I18n.t('Adobe InDesign: extract master spreads'),
          displayValue: values.idmlExtractMasterSpreads ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.idmlExtractInvisibleLayers,
          currentValue: projectTemplate?.idmlExtractInvisibleLayers,
          label: I18n.t('Adobe InDesign: extract invisible layers'),
          displayValue: values.idmlExtractInvisibleLayers ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.idmlExtractHiddenConditionalText,
          currentValue: projectTemplate?.idmlExtractHiddenConditionalText,
          label: I18n.t('Adobe InDesign: extract hidden conditional text'),
          displayValue: values.idmlExtractHiddenConditionalText ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.idmlExtractLockedLayer,
          currentValue: projectTemplate?.idmlExtractLockedLayer,
          label: I18n.t('Adobe InDesign: extract locked layer'),
          displayValue: values.idmlExtractLockedLayer ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.idmlKeepKerning,
          currentValue: projectTemplate?.idmlKeepKerning,
          label: I18n.t('Adobe InDesign: keep kerning'),
          displayValue: values.idmlKeepKerning ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.idmlKeepTracking,
          currentValue: projectTemplate?.idmlKeepTracking,
          label: I18n.t('Adobe InDesign: keep tracking'),
          displayValue: values.idmlKeepTracking ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.idmlReplaceFont,
          currentValue: projectTemplate?.idmlReplaceFont,
          label: I18n.t('Adobe InDesign: replace font'),
          displayValue: values.idmlReplaceFont ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.idmlRemoveXmlElements,
          currentValue: projectTemplate?.idmlRemoveXmlElements,
          label: I18n.t('Adobe InDesign: remove xml elements'),
          displayValue: values.idmlRemoveXmlElements ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),

        coalesceSummaryValue({
          value: values.mifExtractBodyPages,
          currentValue: projectTemplate?.mifExtractBodyPages,
          label: I18n.t('Adobe FrameMaker: extract body pages'),
          displayValue: values.mifExtractBodyPages ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.mifExtractReferencePages,
          currentValue: projectTemplate?.mifExtractReferencePages,
          label: I18n.t('Adobe FrameMaker: extract reference pages'),
          displayValue: values.mifExtractReferencePages ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.mifExtractCustomReferencePages,
          currentValue: projectTemplate?.mifExtractCustomReferencePages,
          label: I18n.t('Adobe FrameMaker: extract custom reference pages'),
          displayValue: values.mifExtractCustomReferencePages ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.mifExtractDefaultReferencePages,
          currentValue: projectTemplate?.mifExtractDefaultReferencePages,
          label: I18n.t('Adobe FrameMaker: extract default reference pages'),
          displayValue: values.mifExtractDefaultReferencePages ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.mifExtractMasterPages,
          currentValue: projectTemplate?.mifExtractMasterPages,
          label: I18n.t('Adobe FrameMaker: extract master pages'),
          displayValue: values.mifExtractMasterPages ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.mifExtractHiddenPages,
          currentValue: projectTemplate?.mifExtractHiddenPages,
          label: I18n.t('Adobe FrameMaker: extract hidden pages'),
          displayValue: values.mifExtractHiddenPages ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.mifExtractVariables,
          currentValue: projectTemplate?.mifExtractVariables,
          label: I18n.t('Adobe FrameMaker: extract variables'),
          displayValue: values.mifExtractVariables ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.mifExtractUsedVariables,
          currentValue: projectTemplate?.mifExtractUsedVariables,
          label: I18n.t('Adobe FrameMaker: extract used variables'),
          displayValue: values.mifExtractUsedVariables ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.mifExtractIndexMarkers,
          currentValue: projectTemplate?.mifExtractIndexMarkers,
          label: I18n.t('Adobe FrameMaker: extract index markers'),
          displayValue: values.mifExtractIndexMarkers ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.mifExtractLinks,
          currentValue: projectTemplate?.mifExtractLinks,
          label: I18n.t('Adobe FrameMaker: extract links'),
          displayValue: values.mifExtractLinks ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.mifExtractXRefs,
          currentValue: projectTemplate?.mifExtractXRefs,
          label: I18n.t('Adobe FrameMaker: extract XRefs'),
          displayValue: values.mifExtractXRefs ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.mifExtractUsedXRefs,
          currentValue: projectTemplate?.mifExtractUsedXRefs,
          label: I18n.t('Adobe FrameMaker: extract used XRefs'),
          displayValue: values.mifExtractUsedXRefs ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.mifExtractPgfNumberFormats,
          currentValue: projectTemplate?.mifExtractPgfNumberFormats,
          label: I18n.t('Adobe FrameMaker: extract PGF number formats'),
          displayValue: values.mifExtractPgfNumberFormats ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.mifExtractUsedPgfNumberFormats,
          currentValue: projectTemplate?.mifExtractUsedPgfNumberFormats,
          label: I18n.t('Adobe FrameMaker: extract used PGF number formats'),
          displayValue: values.mifExtractUsedPgfNumberFormats ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.mifExtractHiddenConditionalText,
          currentValue: projectTemplate?.mifExtractHiddenConditionalText,
          label: I18n.t('Adobe FrameMaker: extract hidden conditional text'),
          displayValue: values.mifExtractHiddenConditionalText ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),

        coalesceSummaryValue({
          value: values.jsonTagRegexp,
          currentValue: projectTemplate?.jsonTagRegexp,
          label: I18n.t('JSON: tag regex'),
        }),
        coalesceSummaryValue({
          value: values.jsonIncludedKeysRegexp,
          currentValue: projectTemplate?.jsonIncludedKeysRegexp,
          label: I18n.t('JSON: included keys regex'),
        }),
        coalesceSummaryValue({
          value: values.jsonContextNotePath,
          currentValue: projectTemplate?.jsonContextNotePath,
          label: I18n.t('JSON: context note path'),
        }),
        coalesceSummaryValue({
          value: values.jsonMaxLengthPath,
          currentValue: projectTemplate?.jsonMaxLengthPath,
          label: I18n.t('JSON: max length path'),
        }),
        coalesceSummaryValue({
          value: values.jsonUseHtmlSubfilter,
          currentValue: projectTemplate?.jsonUseHtmlSubfilter,
          label: I18n.t('JSON: use HTML subfilter'),
          displayValue: values.jsonUseHtmlSubfilter ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),

        coalesceSummaryValue({
          value: values.csvDelimiter,
          currentValue: projectTemplate?.csvDelimiter,
          label: I18n.t('CSV: delimiter'),
        }),
        coalesceSummaryValue({
          value: values.csvTagRegexp,
          currentValue: projectTemplate?.csvTagRegexp,
          label: I18n.t('CSV: tag regex'),
        }),
        coalesceSummaryValue({
          value: values.csvImportColumns,
          currentValue: projectTemplate?.csvImportColumns,
          label: I18n.t('CSV: import columns'),
        }),
        coalesceSummaryValue({
          value: values.csvImportRows,
          currentValue: projectTemplate?.csvImportRows,
          label: I18n.t('CSV: import rows'),
        }),
        coalesceSummaryValue({
          value: values.csvContextNoteColumns,
          currentValue: projectTemplate?.csvContextNoteColumns,
          label: I18n.t('CSV: note columns'),
        }),
        coalesceSummaryValue({
          value: values.csvContextKeyColumn,
          currentValue: projectTemplate?.csvContextKeyColumn,
          label: I18n.t('CSV: key column'),
        }),
        coalesceSummaryValue({
          value: values.csvMaxLengthColumn,
          currentValue: projectTemplate?.csvMaxLengthColumn,
          label: I18n.t('CSV: max length column'),
        }),
        coalesceSummaryValue({
          value: values.csvUseHtmlSubfilter,
          currentValue: projectTemplate?.csvUseHtmlSubfilter,
          label: I18n.t('CSV: use HTML subfilter'),
          displayValue: values.csvUseHtmlSubfilter ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),

        coalesceSummaryValue({
          value: values.msExcelTagRegexp,
          currentValue: projectTemplate?.msExcelTagRegexp,
          label: I18n.t('MS Excel: tag regex'),
        }),
        coalesceSummaryValue({
          value: values.msExcelCellFlow,
          currentValue: projectTemplate?.msExcelCellFlow,
          label: I18n.t('MS Excel: cell flow'),
        }),
        coalesceSummaryValue({
          value: values.msExcelUseHtmlSubfilter,
          currentValue: projectTemplate?.msExcelUseHtmlSubfilter,
          label: I18n.t('MS Excel: use HTML subfilter'),
          displayValue: values.msExcelUseHtmlSubfilter ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.msExcelExtractSheetNames,
          currentValue: projectTemplate?.msExcelExtractSheetNames,
          label: I18n.t('MS Excel: extract sheet names'),
          displayValue: values.msExcelExtractSheetNames ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.msExcelExtractComments,
          currentValue: projectTemplate?.msExcelExtractComments,
          label: I18n.t('MS Excel: extract comments'),
          displayValue: values.msExcelExtractComments ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.msExcelExtractHidden,
          currentValue: projectTemplate?.msExcelExtractHidden,
          label: I18n.t('MS Excel: extract hidden'),
          displayValue: values.msExcelExtractHidden ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.msExcelExtractOther,
          currentValue: projectTemplate?.msExcelExtractOther,
          label: I18n.t('MS Excel: extract other'),
          displayValue: values.msExcelExtractOther ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),

        coalesceSummaryValue({
          value: values.msPowerPointExtractNotes,
          currentValue: projectTemplate?.msPowerPointExtractNotes,
          label: I18n.t('MS PowerPoint: extract notes'),
          displayValue: values.msPowerPointExtractNotes ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.msPowerPointExtractMasterSlides,
          currentValue: projectTemplate?.msPowerPointExtractMasterSlides,
          label: I18n.t('MS PowerPoint: extract master slides'),
          displayValue: values.msPowerPointExtractMasterSlides ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.msPowerPointExtractHidden,
          currentValue: projectTemplate?.msPowerPointExtractHidden,
          label: I18n.t('MS PowerPoint: extract hidden'),
          displayValue: values.msPowerPointExtractHidden ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.msPowerPointExtractOther,
          currentValue: projectTemplate?.msPowerPointExtractOther,
          label: I18n.t('MS PowerPoint: extract other'),
          displayValue: values.msPowerPointExtractOther ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),

        coalesceSummaryValue({
          value: values.msWordTagRegexp,
          currentValue: projectTemplate?.msWordTagRegexp,
          label: I18n.t('MS Word: tag regex'),
        }),
        coalesceSummaryValue({
          value: values.msWordTargetFont,
          currentValue: projectTemplate?.msWordTargetFont,
          label: I18n.t('MS Word: target font'),
        }),
        coalesceSummaryValue({
          value: values.msWordExtractComments,
          currentValue: projectTemplate?.msWordExtractComments,
          label: I18n.t('MS Word: extract comments'),
          displayValue: values.msWordExtractComments ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.msWordExtractIndexes,
          currentValue: projectTemplate?.msWordExtractIndexes,
          label: I18n.t('MS Word: extract indexes'),
          displayValue: values.msWordExtractIndexes ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.msWordExtractOther,
          currentValue: projectTemplate?.msWordExtractOther,
          label: I18n.t('MS Word: extract other'),
          displayValue: values.msWordExtractOther ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.msWordExtractHyperlinkTarget,
          currentValue: projectTemplate?.msWordExtractHyperlinkTarget,
          label: I18n.t('MS Word: extract hyperlink target'),
          displayValue: values.msWordExtractHyperlinkTarget ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.msWordJoinSimilarRuns,
          currentValue: projectTemplate?.msWordJoinSimilarRuns,
          label: I18n.t('MS Word: join similar runs'),
          displayValue: values.msWordJoinSimilarRuns ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.msWordExtractProperties,
          currentValue: projectTemplate?.msWordExtractProperties,
          label: I18n.t('MS Word: extract properties'),
          displayValue: values.msWordExtractProperties ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.msWordExtractHidden,
          currentValue: projectTemplate?.msWordExtractHidden,
          label: I18n.t('MS Word: extract hidden'),
          displayValue: values.msWordExtractHidden ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),

        // TODO: Break values up by section
        coalesceSummaryValue({
          value: values.ttxSaveConfirmedSegments,
          currentValue: projectTemplate?.ttxSaveConfirmedSegments,
          label: I18n.t('Trados TTX: save confirmed segments'),
          displayValue: values.ttxSaveConfirmedSegments ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),

        coalesceSummaryValue({
          value: values.txtTagRegexp,
          currentValue: projectTemplate?.txtTagRegexp,
          label: I18n.t('TXT: tag regex'),
        }),
        coalesceSummaryValue({
          value: values.txtTranslatableTextRegexp,
          currentValue: projectTemplate?.txtTranslatableTextRegexp,
          label: I18n.t('TXT: translatable text regex'),
        }),

        coalesceSummaryValue({
          value: values.poTagRegexp,
          currentValue: projectTemplate?.poTagRegexp,
          label: I18n.t('PO: tag regex'),
        }),
        coalesceSummaryValue({
          value: values.poExportMultilineStrings,
          currentValue: projectTemplate?.poExportMultilineStrings,
          label: I18n.t('PO: export multiline strings'),
          displayValue: values.poExportMultilineStrings ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),

        coalesceSummaryValue({
          value: values.propertiesTagRegexp,
          currentValue: projectTemplate?.propertiesTagRegexp,
          label: I18n.t('Properties: tag regex'),
        }),
        coalesceSummaryValue({
          value: values.propertiesEscapeAllBackslashes,
          currentValue: projectTemplate?.propertiesEscapeAllBackslashes,
          label: I18n.t('Properties: escape all backslashes'),
          displayValue: values.propertiesEscapeAllBackslashes ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.propertiesSmartHtmlSubfilter,
          currentValue: projectTemplate?.propertiesSmartHtmlSubfilter,
          label: I18n.t('Properties: smart HTML subfilter'),
          displayValue: values.propertiesSmartHtmlSubfilter ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.xlfInterpretLineBreaksTags,
          currentValue: projectTemplate?.xlfInterpretLineBreaksTags,
          label: I18n.t('XLIFF 1.2: interpret line break tags'),
          displayValue: values.xlfInterpretLineBreaksTags ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.xlfPreserveWhitespace,
          currentValue: projectTemplate?.xlfPreserveWhitespace,
          label: I18n.t('XLIFF 1.2: preserve whitespace'),
          displayValue: values.xlfPreserveWhitespace ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.xlfCopySourceToTargetIfSegmentNotImported,
          currentValue: projectTemplate?.xlfCopySourceToTargetIfSegmentNotImported,
          label: I18n.t('XLIFF 1.2: copy source to target if segment not imported'),
          displayValue: values.xlfCopySourceToTargetIfSegmentNotImported ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.xlfDoNotImportSegmentWhenRules,
          currentValue: projectTemplate?.xlfDoNotImportSegmentWhenRules,
          label: I18n.t('XLIFF 1.2: Do not import segments when'),
        }),
        coalesceSummaryValue({
          value: values.xlfConfirmSegmentWhenRules,
          currentValue: projectTemplate?.xlfConfirmSegmentWhenRules,
          label: I18n.t('XLIFF 1.2: Confirm segments when'),
        }),
        coalesceSummaryValue({
          value: values.xlfLockSegmentWhenRules,
          currentValue: projectTemplate?.xlfLockSegmentWhenRules,
          label: I18n.t('XLIFF 1.2: Lock segments when'),
        }),
        coalesceSummaryValue({
          value: values.xlfExportConfirmedAndLockedRules,
          currentValue: projectTemplate?.xlfExportConfirmedAndLockedRules,
          label: I18n.t('XLIFF 1.2: Export when confirmed and locked'),
        }),
        coalesceSummaryValue({
          value: values.xlfExportConfirmedAndNotLockedRules,
          currentValue: projectTemplate?.xlfExportConfirmedAndNotLockedRules,
          label: I18n.t('XLIFF 1.2: Export when confirmed but not locked'),
        }),
        coalesceSummaryValue({
          value: values.xlfExportNotConfirmedAndLockedRules,
          currentValue: projectTemplate?.xlfExportNotConfirmedAndLockedRules,
          label: I18n.t('XLIFF 1.2: Export when locked but not confirmed'),
        }),
        coalesceSummaryValue({
          value: values.xlfExportNotConfirmedAndNotLockedRules,
          currentValue: projectTemplate?.xlfExportNotConfirmedAndNotLockedRules,
          label: I18n.t('XLIFF 1.2: Export when neither confirmed nor locked'),
        }),
        coalesceSummaryValue({
          value: values.xlfContextType,
          currentValue: projectTemplate?.xlfContextType,
          label: I18n.t('XLIFF 1.2: Context note'),
        }),
        coalesceSummaryValue({
          value: values.xlfExtractNote,
          currentValue: projectTemplate?.xlfExtractNote,
          label: I18n.t('XLIFF 1.2: import <note>'),
          displayValue: values.xlfExtractNote ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.xlfSaveConfirmedSegmentsToTMOnImport,
          currentValue: projectTemplate?.xlfSaveConfirmedSegmentsToTMOnImport,
          label: I18n.t('XLIFF 1.2: save confirmed segments to TM on import'),
          displayValue: values.xlfSaveConfirmedSegmentsToTMOnImport ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.xlfSegment,
          currentValue: projectTemplate?.xlfSegment,
          label: I18n.t('XLIFF 1.2: segment XLIFF'),
          displayValue: values.xlfSegment ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),

        coalesceSummaryValue({
          value: values.xlf2PreserveWhitespace,
          currentValue: projectTemplate?.xlf2PreserveWhitespace,
          label: I18n.t('XLIFF 2.0: preserve whitespaces'),
          displayValue: values.xlf2PreserveWhitespace ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.xlf2ImportNotes,
          currentValue: projectTemplate?.xlf2ImportNotes,
          label: I18n.t('XLIFF 2.0: import <note>'),
          displayValue: values.xlf2ImportNotes ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.xlf2SaveConfirmedSegmentsToTMOnImport,
          currentValue: projectTemplate?.xlf2SaveConfirmedSegmentsToTMOnImport,
          label: I18n.t('XLIFF 2.0: save confirmed segments to TM on import'),
          displayValue: values.xlf2SaveConfirmedSegmentsToTMOnImport ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.xlf2Segment,
          currentValue: projectTemplate?.xlf2Segment,
          label: I18n.t('XLIFF 2.0: segment XLIFF'),
          displayValue: values.xlf2Segment ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.xlf2InterpretLineBreakTags,
          currentValue: projectTemplate?.xlf2InterpretLineBreakTags,
          label: I18n.t('XLIFF 2.0: interpret line break tags'),
          displayValue: values.xlf2InterpretLineBreakTags ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.xlf2CopySourceToTargetIfSegmentNotImported,
          currentValue: projectTemplate?.xlf2CopySourceToTargetIfSegmentNotImported,
          label: I18n.t('XLIFF 2.0: copy source to empty target if segment is not imported'),
          displayValue: values.xlf2CopySourceToTargetIfSegmentNotImported ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.xlf2RespectTranslateAttribute,
          currentValue: projectTemplate?.xlf2RespectTranslateAttribute,
          label: I18n.t(
            "XLIFF 2.0: represent text with attribute 'translate=no' as non-translatable tags"
          ),
          displayValue: values.xlf2RespectTranslateAttribute ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.xlf2SkipImportRules,
          currentValue: projectTemplate?.xlf2SkipImportRules,
          label: I18n.t('XLIFF 2.0: do not import segments when'),
        }),
        coalesceSummaryValue({
          value: values.xlf2ConfirmSegmentWhenRules,
          currentValue: projectTemplate?.xlf2ConfirmSegmentWhenRules,
          label: I18n.t('XLIFF 2.0: set segment confirmed when'),
        }),
        coalesceSummaryValue({
          value: values.xlf2LockSegmentWhenRules,
          currentValue: projectTemplate?.xlf2LockSegmentWhenRules,
          label: I18n.t('XLIFF 2.0: set segment locked when'),
        }),
        coalesceSummaryValue({
          value: values.xlf2ExportConfirmedAndLockedRules,
          currentValue: projectTemplate?.xlf2ExportConfirmedAndLockedRules,
          label: I18n.t('XLIFF 2.0: confirmed and locked'),
        }),
        coalesceSummaryValue({
          value: values.xlf2ExportConfirmedAndNotLockedRules,
          currentValue: projectTemplate?.xlf2ExportConfirmedAndNotLockedRules,
          label: I18n.t('XLIFF 2.0: confirmed and not locked'),
        }),
        coalesceSummaryValue({
          value: values.xlf2ExportNotConfirmedAndLockedRules,
          currentValue: projectTemplate?.xlf2ExportNotConfirmedAndLockedRules,
          label: I18n.t('XLIFF 2.0: not confirmed and locked'),
        }),
        coalesceSummaryValue({
          value: values.xlf2ExportNotConfirmedAndNotLockedRules,
          currentValue: projectTemplate?.xlf2ExportNotConfirmedAndNotLockedRules,
          label: I18n.t('XLIFF 2.0: not confirmed and not locked'),
        }),
        coalesceSummaryValue({
          value: values.xlf2ContextKeyXPath,
          currentValue: projectTemplate?.xlf2ContextKeyXPath,
          label: I18n.t('XLIFF 2.0: context key'),
        }),

        coalesceSummaryValue({
          value: values.sdlXlfSkipImportRules,
          currentValue: projectTemplate?.sdlXlfSkipImportRules,
          label: I18n.t('SDL XLIFF: do not import segments when'),
        }),
        coalesceSummaryValue({
          value: values.sdlXlfImportAsConfirmedRules,
          currentValue: projectTemplate?.sdlXlfImportAsConfirmedRules,
          label: I18n.t('SDL XLIFF: set segment confirmed when'),
        }),
        coalesceSummaryValue({
          value: values.sdlXlfImportAsLockedRules,
          currentValue: projectTemplate?.sdlXlfImportAsLockedRules,
          label: I18n.t('SDL XLIFF: set segment locked when'),
        }),
        coalesceSummaryValue({
          value: values.sdlXlfExportAttrsWhenConfirmedAndLocked,
          currentValue: projectTemplate?.sdlXlfExportAttrsWhenConfirmedAndLocked,
          label: I18n.t('SDL XLIFF: confirmed and locked'),
        }),
        coalesceSummaryValue({
          value: values.sdlXlfExportAttrsWhenConfirmedAndNotLocked,
          currentValue: projectTemplate?.sdlXlfExportAttrsWhenConfirmedAndNotLocked,
          label: I18n.t('SDL XLIFF: confirmed and not locked'),
        }),
        coalesceSummaryValue({
          value: values.sdlXlfExportAttrsWhenNotConfirmedAndLocked,
          currentValue: projectTemplate?.sdlXlfExportAttrsWhenNotConfirmedAndLocked,
          label: I18n.t('SDL XLIFF: not confirmed and locked'),
        }),
        coalesceSummaryValue({
          value: values.sdlXlfExportAttrsWhenNotConfirmedAndNotLocked,
          currentValue: projectTemplate?.sdlXlfExportAttrsWhenNotConfirmedAndNotLocked,
          label: I18n.t('SDL XLIFF: not confirmed and not locked'),
        }),
        coalesceSummaryValue({
          value: values.sdlXlfSaveConfirmedSegments,
          currentValue: projectTemplate?.sdlXlfSaveConfirmedSegments,
          label: I18n.t('SDL XLIFF: copy source to empty target if segment is not imported'),
          displayValue: values.sdlXlfSaveConfirmedSegments ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),

        coalesceSummaryValue({
          value: values.xmlRulesFormat,
          currentValue: projectTemplate?.xmlRulesFormat,
          label: I18n.t('XML: rules format'),
          displayValue: values.xmlRulesFormat === 'PLAIN' ? 'Plain' : 'XPath',
        }),
        coalesceSummaryValue({
          value: values.xmlIncludeTags,
          currentValue: projectTemplate?.xmlIncludeTags,
          label: I18n.t('XML: include or exclude tags'),
          displayValue: values.xmlIncludeTags ? 'Include' : 'Exclude',
        }),
        coalesceSummaryValue({
          value: values.xmlIncludeExcludeTags,
          currentValue: projectTemplate?.xmlIncludeExcludeTags,
          label: I18n.t('XML: tags'),
          displayValue: values.xmlIncludeExcludeTags ? 'Include' : 'Exclude',
        }),
        coalesceSummaryValue({
          value: values.xmlIncludeAttributes,
          currentValue: projectTemplate?.xmlIncludeAttributes,
          label: I18n.t('XML: include or exclude attributes'),
          displayValue: values.xmlIncludeAttributes ? 'Include' : 'Exclude',
        }),
        coalesceSummaryValue({
          value: values.xmlIncludeExcludeAttributes,
          currentValue: projectTemplate?.xmlIncludeExcludeAttributes,
          label: I18n.t('XML: attributes'),
        }),
        coalesceSummaryValue({
          value: values.xmlImportEntities,
          currentValue: projectTemplate?.xmlImportEntities,
          label: I18n.t('XML: import entities'),
          displayValue: values.xmlImportEntities ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.xmlTagRegexp,
          currentValue: projectTemplate?.xmlTagRegexp,
          label: I18n.t('XML: tag regex'),
        }),
        coalesceSummaryValue({
          value: values.xmlCharacterEntitiesToPreserveOnExport,
          currentValue: projectTemplate?.xmlCharacterEntitiesToPreserveOnExport,
          label: I18n.t('XML: character entities to preserve on export'),
        }),
        coalesceSummaryValue({
          value: values.xmlSegment,
          currentValue: projectTemplate?.xmlSegment,
          label: I18n.t('XML: segment'),
          displayValue: values.xmlSegment ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.xmlXslUrl,
          currentValue: projectTemplate?.xmlXslUrl,
          label: I18n.t('XML: XSL URL'),
        }),
        coalesceSummaryValue({
          value: values.xmlEscapeUnresolvedEntities,
          currentValue: projectTemplate?.xmlEscapeUnresolvedEntities,
          label: I18n.t('XML: escape unresolved entities'),
          displayValue: values.xmlEscapeUnresolvedEntities ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.xmlIgnorePreprocessingInstructions,
          currentValue: projectTemplate?.xmlIgnorePreprocessingInstructions,
          label: I18n.t('XML: ignore pre-processing instructions'),
          displayValue: values.xmlIgnorePreprocessingInstructions ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.xmlLockedTags,
          currentValue: projectTemplate?.xmlLockedTags,
          label: I18n.t('XML: locked tags'),
        }),
        coalesceSummaryValue({
          value: values.xmlLockedAttributes,
          currentValue: projectTemplate?.xmlLockedAttributes,
          label: I18n.t('XML: locked attributes'),
        }),
        coalesceSummaryValue({
          value: values.xmlElementsToProcessAsHtml,
          currentValue: projectTemplate?.xmlElementsToProcessAsHtml,
          label: I18n.t('XML: elements to process as HTML'),
        }),
        coalesceSummaryValue({
          value: values.xmlSmartHtmlSubfilter,
          currentValue: projectTemplate?.xmlSmartHtmlSubfilter,
          label: I18n.t('XML: smart HTML subfilter'),
          displayValue: values.xmlSmartHtmlSubfilter ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.xmlInlineTags,
          currentValue: projectTemplate?.xmlInlineTags,
          label: I18n.t('XML: inline tags'),
        }),
        coalesceSummaryValue({
          value: values.xmlInlineSingleTag,
          currentValue: projectTemplate?.xmlInlineSingleTag,
          label: I18n.t('XML: inline single tag'),
        }),
        coalesceSummaryValue({
          value: values.xmlSmartInlineTags,
          currentValue: projectTemplate?.xmlSmartInlineTags,
          label: I18n.t('XML: smart inline tags'),
          displayValue: values.xmlSmartInlineTags ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.xmlElementsAndAttributesXPath,
          currentValue: projectTemplate?.xmlElementsAndAttributesXPath,
          label: I18n.t('XML: elements and attributes XPath'),
        }),
        coalesceSummaryValue({
          value: values.xmlLockedElementsAndAttributesXPath,
          currentValue: projectTemplate?.xmlLockedElementsAndAttributesXPath,
          label: I18n.t('XML: locked elements and attributes XPath'),
        }),
        coalesceSummaryValue({
          value: values.xmlInlineElementsXPath,
          currentValue: projectTemplate?.xmlInlineElementsXPath,
          label: I18n.t('XML: inline elements XPath'),
        }),
        coalesceSummaryValue({
          value: values.xmlInlineSingleElementXPath,
          currentValue: projectTemplate?.xmlInlineSingleElementXPath,
          label: I18n.t('XML: inline single element XPath'),
        }),
        coalesceSummaryValue({
          value: values.xmlSubfilterElementsXPath,
          currentValue: projectTemplate?.xmlSubfilterElementsXPath,
          label: I18n.t('XML: subfilter elements XPath'),
        }),
        coalesceSummaryValue({
          value: values.xmlPreserveWhitespaceXPath,
          currentValue: projectTemplate?.xmlPreserveWhitespaceXPath,
          label: I18n.t('XML: preserve whitespace XPath'),
        }),
        coalesceSummaryValue({
          value: values.xmlContextNoteXPath,
          currentValue: projectTemplate?.xmlContextNoteXPath,
          label: I18n.t('XML: context note XPath'),
        }),
        coalesceSummaryValue({
          value: values.xmlMaxLengthXPath,
          currentValue: projectTemplate?.xmlMaxLengthXPath,
          label: I18n.t('XML: max length XPath'),
        }),
        coalesceSummaryValue({
          value: values.xmlXpathSmartInlineElement,
          currentValue: projectTemplate?.xmlXpathSmartInlineElement,
          label: I18n.t('XML: XPath smart inline element'),
          displayValue: values.xmlXpathSmartInlineElement ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.xmlContextKeyXPath,
          currentValue: projectTemplate?.xmlContextKeyXPath,
          label: I18n.t('XML: context key XPath'),
        }),

        coalesceSummaryValue({
          value: values.yamlTagRegexp,
          currentValue: projectTemplate?.yamlTagRegexp,
          label: I18n.t('YAML: tag regex'),
        }),
        coalesceSummaryValue({
          value: values.yamlUseHtmlSubfilter,
          currentValue: projectTemplate?.yamlUseHtmlSubfilter,
          label: I18n.t('YAML: use HTML subfilter'),
          displayValue: values.yamlUseHtmlSubfilter ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.yamlContextKeyRegexp,
          currentValue: projectTemplate?.yamlContextKeyRegexp,
          label: I18n.t('YAML: context key regex'),
        }),
        coalesceSummaryValue({
          value: values.yamlContextNoteRegexp,
          currentValue: projectTemplate?.yamlContextNoteRegexp,
          label: I18n.t('YAML: context note regex'),
        }),

        coalesceSummaryValue({
          value: values.multilingualXmlTranslatableElementsXPath,
          currentValue: projectTemplate?.multilingualXmlTranslatableElementsXPath,
          label: I18n.t('Multilingual XML: translatable elements XPath'),
        }),
        coalesceSummaryValue({
          value: values.multilingualXmlSourceElementsXPath,
          currentValue: projectTemplate?.multilingualXmlSourceElementsXPath,
          label: I18n.t('Multilingual XML: source elements XPath'),
        }),
        coalesceSummaryValue({
          value: values.multilingualXmlTargetElementsXPath,
          currentValue: projectTemplate?.multilingualXmlTargetElementsXPath,
          label: I18n.t('Multilingual XML: target elements XPath'),
        }),
        coalesceSummaryValue({
          value: values.multilingualXmlNonTranslatableInlineElementsXPath,
          currentValue: projectTemplate?.multilingualXmlNonTranslatableInlineElementsXPath,
          label: I18n.t('Multilingual XML: translatable inline elements XPath'),
        }),
        coalesceSummaryValue({
          value: values.multilingualXmlTagRegexp,
          currentValue: projectTemplate?.multilingualXmlTagRegexp,
          label: I18n.t('Multilingual XML: tag regex'),
        }),
        coalesceSummaryValue({
          value: values.multilingualXmlSegment,
          currentValue: projectTemplate?.multilingualXmlSegment,
          label: I18n.t('Multilingual XML: segment'),
          displayValue: values.multilingualXmlSegment ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.multilingualXmlUseHtmlSubfilter,
          currentValue: projectTemplate?.multilingualXmlUseHtmlSubfilter,
          label: I18n.t('Multilingual XML: use HTML subfilter'),
          displayValue: values.multilingualXmlUseHtmlSubfilter ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.multilingualXmlContextNoteXPath,
          currentValue: projectTemplate?.multilingualXmlContextNoteXPath,
          label: I18n.t('Multilingual XML: context note XPath'),
        }),
        coalesceSummaryValue({
          value: values.multilingualXmlContextKeyXPath,
          currentValue: projectTemplate?.multilingualXmlContextKeyXPath,
          label: I18n.t('Multilingual XML: context key XPath'),
        }),
        coalesceSummaryValue({
          value: values.multilingualXmlMaxLengthXPath,
          currentValue: projectTemplate?.multilingualXmlMaxLengthXPath,
          label: I18n.t('Multilingual XML: max length XPath'),
        }),
        coalesceSummaryValue({
          value: values.multilingualXmlPreserveWhitespace,
          currentValue: projectTemplate?.multilingualXmlPreserveWhitespace,
          label: I18n.t('Multilingual XML: preserve whitespace'),
          displayValue: values.multilingualXmlPreserveWhitespace ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),

        coalesceSummaryValue({
          value: values.macStringsUseHtmlSubfilter,
          currentValue: projectTemplate?.macStringsUseHtmlSubfilter,
          label: I18n.t('iOS Strings: use HTML subfilter'),
          displayValue: values.macStringsUseHtmlSubfilter ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.macStringsTagRegexp,
          currentValue: projectTemplate?.macStringsTagRegexp,
          label: I18n.t('iOS Strings: tag regex'),
        }),

        coalesceSummaryValue({
          value: values.androidStringTagRegexp,
          currentValue: projectTemplate?.androidStringTagRegexp,
          label: I18n.t('Android Strings: tag regex'),
        }),

        coalesceSummaryValue({
          value: values.phpTagRegexp,
          currentValue: projectTemplate?.phpTagRegexp,
          label: I18n.t('PHP: tag regex'),
        }),
        coalesceSummaryValue({
          value: values.phpTagRegexp,
          currentValue: projectTemplate?.phpTagRegexp,
          label: I18n.t('PHP: use HTML subfilter'),
          displayValue: values.phpTagRegexp ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),

        coalesceSummaryValue({
          value: values.resxTagRegexp,
          currentValue: projectTemplate?.resxTagRegexp,
          label: I18n.t('Windows RESX: tag regex'),
        }),

        coalesceSummaryValue({
          value: values.pdfFilter,
          currentValue: projectTemplate?.pdfFilter,
          label: I18n.t('PDF: filter'),
        }),

        coalesceSummaryValue({
          value: values.quarkTagRemoveKerningTrackingTags,
          currentValue: projectTemplate?.quarkTagRemoveKerningTrackingTags,
          label: I18n.t('Quark Tag: remove kerning tracking tags'),
          displayValue: values.quarkTagRemoveKerningTrackingTags ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),

        /*
          markdownConvertToTags: currentValue?.markdownConvertToTags ?? '',
       */
        coalesceSummaryValue({
          value: values.markdownTranslateImageAltText,
          currentValue: projectTemplate?.markdownTranslateImageAltText,
          label: I18n.t('Markdown: translate image alt text'),
          displayValue: values.markdownTranslateImageAltText ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.markdownTranslateHeaderMetadata,
          currentValue: projectTemplate?.markdownTranslateHeaderMetadata,
          label: I18n.t('Markdown: translate header metadata'),
          displayValue: values.markdownTranslateHeaderMetadata ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.markdownTranslateCodeBlocks,
          currentValue: projectTemplate?.markdownTranslateCodeBlocks,
          label: I18n.t('Markdown: translate code blocks'),
          displayValue: values.markdownTranslateCodeBlocks ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.markdownTranslateInlineCodeBlocks,
          currentValue: projectTemplate?.markdownTranslateInlineCodeBlocks,
          label: I18n.t('Markdown: translate inline code blocks'),
          displayValue: values.markdownTranslateInlineCodeBlocks ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.markdownTranslateUrls,
          currentValue: projectTemplate?.markdownTranslateUrls,
          label: I18n.t('Markdown: translate URLs'),
          displayValue: values.markdownTranslateUrls ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
        coalesceSummaryValue({
          value: values.markdownConvertToTags,
          currentValue: projectTemplate?.markdownConvertToTags,
          label: I18n.t('Markdown: convert to tags'),
          displayValue: values.markdownConvertToTags ? (
            <Icon name={'status-positive'} />
          ) : (
            <Icon name={'status-negative'} />
          ),
        }),
      ].filter(s => s);
      if (!props.isProjectTemplateMode) {
        summaryElements.push(
          coalesceSummaryValue({
            value: values.contextType,
            label: I18n.t('TM match context and optimization: Context Type'),
          }),
          coalesceSummaryValue({
            value: values.penalizeMultiple101TMMatchesBy1,
            label: I18n.t('TM match context and optimization: Penalize multiple 101% TM by 1%'),
            displayValue: values.penalizeMultiple101TMMatchesBy1 ? (
              <Icon name={'status-positive'} />
            ) : (
              <Icon name={'status-negative'} />
            ),
          }),
          coalesceSummaryValue({
            value: values.previousOrNextSegmentContextAs101,
            label: I18n.t(
              'TM match context and optimization: Previous OR next segment context as 101%'
            ),
            displayValue: values.previousOrNextSegmentContextAs101 ? (
              <Icon name={'status-positive'} />
            ) : (
              <Icon name={'status-negative'} />
            ),
          })
        );
      }
      return (
        <Container title={title} key={title}>
          <ColumnLayout columns={4}>
            <div data-awsui-column-layout-root="true">
              {summaryElements.length > 0 ? (
                summaryElements
              ) : (
                <div>
                  <div>
                    <T>No changes</T>
                  </div>
                </div>
              )}
            </div>
          </ColumnLayout>
        </Container>
      );
    },
  };
};
