import { AtmsApiClient } from '@amzn/et-console-components';
import { User } from '../../types/commonTypes';
import { stringify } from 'query-string';
import { getAppHostConfig } from '@amzn/et-console-components';

const { WEB_HOST_AND_PORT } = getAppHostConfig();

export interface Reference {
  id: string;
  name?: string;
  file?: File;
  note?: string;
  dateCreated?: string;
  createdBy: User;
}

export const createReferences = (isProjectMode: boolean, referencesToAdd, uid): Promise<any>[] => {
  return (Array.isArray(referencesToAdd) ? referencesToAdd : []).map(r => {
    const body = isProjectMode ? { project: uid } : { template: uid };
    if (r.file) {
      body['file'] = r.file;
    }
    if (r.note) {
      body['note'] = r.note;
    }
    const api = isProjectMode
      ? `//${WEB_HOST_AND_PORT}/web/api/v2/referenceFile/create`
      : `//${WEB_HOST_AND_PORT}/web/api/v2/referenceFile/createForTemplate`;
    return AtmsApiClient.multipartUpload(api, body);
  });
};

export const deleteReferences = (isProjectMode, referencesToDelete): Promise<any> => {
  return referencesToDelete.length > 0
    ? isProjectMode
      ? AtmsApiClient.httpDelete(
          `//${WEB_HOST_AND_PORT}/web/api/v9/referenceFile/delete?${stringify({
            referenceFile: referencesToDelete.map(r => r.id),
          })}`
        )
      : AtmsApiClient.httpDelete(
          `//${WEB_HOST_AND_PORT}/web/api/v9/referenceFile/deleteForTemplate?${stringify({
            referenceFile: referencesToDelete.map(r => r.id),
          })}`
        )
    : Promise.resolve();
};
