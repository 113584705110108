import { Select } from '@amzn/awsui-components-react/polaris';
import * as React from 'react';
import { ReactElement, useState } from 'react';
import styled from '@emotion/styled';
import { WorkflowStep } from '../../types/commonTypes';
import I18n from '../../../setupI18n';

/******************* Private Props *********************/
const WorkflowStepFont = styled('span')`
  color: var(--awsui-color-grey-600);
  font-weight: bold;
  margin-right: 5px;
`;

const SelectFilterDiv = styled.div({
  marginRight: 'auto',
  order: 0,
});

const SelectWrapper = styled.div({
  display: 'inline-block',
});

export interface Props {
  id: string;
  onChange: (selectedWorkflowStepId: string | null) => void;
  workflowSteps?: WorkflowStep[];
}

const ALL_WORKFLOW_STEPS = 'Default';
const DEFAULT_WORKFLOW_OPTION = { id: 'null', label: ALL_WORKFLOW_STEPS };

export const WorkflowStepFilter = ({ id, workflowSteps = [], onChange }: Props): ReactElement => {
  const [selected, setSelected] = useState(DEFAULT_WORKFLOW_OPTION);
  const workflowStepOptions = [
    DEFAULT_WORKFLOW_OPTION,
    ...workflowSteps.map(wfs => ({ id: '' + wfs.id, label: wfs.name })),
  ];

  return (
    <SelectFilterDiv>
      <WorkflowStepFont>{I18n.t('Settings for ')}</WorkflowStepFont>
      <SelectWrapper>
        <Select
          id={id}
          onChange={(event): void => {
            setSelected(event.detail.selectedOption);
            onChange(event.detail.selectedId === 'null' ? null : event.detail.selectedId);
          }}
          selectedOption={selected}
          selectedLabel={I18n.t('Selected')}
          options={workflowStepOptions}
        />
      </SelectWrapper>
    </SelectFilterDiv>
  );
};
