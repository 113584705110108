import { projectHelpContent } from './project/projectHelpContent';
import { legacyPageHelpContent } from './legacyPageHelpContent';
import { HelpContentSpec, HelpContentCollectionSpec } from './HelpContentRouter';

export const unifiedHelpContent: HelpContentCollectionSpec = {
  ...projectHelpContent,
  ...legacyPageHelpContent,
};

export default (key: string): HelpContentSpec => unifiedHelpContent[key];
