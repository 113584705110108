import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo, useReducer } from 'react';
import * as yup from 'yup';
import {
  Checkbox,
  ColumnLayout,
  Flash,
  Form,
  FormField,
  FormSection,
  Select,
} from '@amzn/awsui-components-react/polaris';
import I18n from '../../../setupI18n';
import styled from '@emotion/styled';
import { css } from 'emotion';
import { HelpInfoLink } from '../../HelpContentRouter';
import { PROJECT_CREATE_ASSETS_HELP } from '../projectHelpContent';
import {
  Language,
  Project,
  ProjectTemplate,
  User,
  Vendor,
  WorkflowStep,
} from '../../types/commonTypes';
import { FormikProps } from 'formik';
import { coalesceSummaryValue, WizardStepWithSummary } from '../../components/WizardWithSummary';
import { Container } from '@amzn/et-polaris-utils';
import { T } from '../../components/T';
import { MultiselectWithSelectAllInGroup } from '../../components/MultiselectWithSelectAllInGroup';
import { AuthenticatedSession, displayUser, inferEnvironment } from '@amzn/et-console-components';
import {
  PROD_ENV,
  STAGING_ENV,
  LOCAL_ENV,
  DEV_ENV,
} from '@amzn/et-console-components/dist/utils/inferEnvironment';
import { EmailNotificationSettings, JobNotification } from '../../../shared/JobNotification';
import { DateTime, LocalZone, Zone } from 'luxon';
import { DateTimePicker } from '../../components/DateTimePicker';

const formSpacing = css`
  padding-top: 14px;
  padding-bottom: 14px;
`;

const overflowWrap = css`
  overflow-wrap: anywhere;
`;

const SummaryTable = styled('table')`
  border-collapse: collapse;
  text-align: left;
`;

const SummaryTableTR = styled('tr')`
  border-bottom: 1px solid #d3d3d3;
`;

const FormContainer = styled('div')`
  max-width: 800px;
  min-width: 280px;
`;

//add TSP:15 and retailLoc:172 to prod once ready to release Dynamic Assignment
const prodOrgsUsingDynamicAssignment: number[] = [15 /*TSP*/, 172 /*retailLoc*/];
const stagingOrgsUsingDynamicAssignment: number[] = [29 /*TSP*/, 124 /*TOR DA Test org*/];

// add dev org ids here if we want to test DA changes on Dev
const devOrgsUsingDynamicAssignment: number[] = [-2 /*adding test coverage*/, 5];
//unit test purposes
const localOrgsUsingDynamicAssignment: number[] = [-2, 3];

export const getUserTimezone = (session): Zone | string => {
  return session?.user?.timezone ?? new LocalZone();
};

export const dynamicAssignmentEnabled = (orgId: number, env): boolean => {
  switch (env) {
    case PROD_ENV:
      return prodOrgsUsingDynamicAssignment.includes(orgId);
    case STAGING_ENV:
      return stagingOrgsUsingDynamicAssignment.includes(orgId);
    case DEV_ENV:
      return devOrgsUsingDynamicAssignment.includes(orgId);
    case LOCAL_ENV:
      return localOrgsUsingDynamicAssignment.includes(orgId);
    default:
      return false;
  }
};

export const getUserOptions = (foundUsers: any): any => {
  return (
    (foundUsers &&
      ([
        foundUsers.recent &&
          foundUsers.recent.length > 0 && {
            label: I18n.t('Recent'),
            options: [
              ...foundUsers.recent.map(linguist => {
                return {
                  id: '' + linguist.id,
                  label: '' + displayUser(linguist),
                  description: `${linguist.userName} (${linguist.email})`,
                  labelTag: linguist.role,
                };
              }),
            ],
          },
        foundUsers.vendors &&
          foundUsers.vendors.length > 0 && {
            label: I18n.t('Vendors'),
            options: [
              ...foundUsers.vendors.reduce(function(result: any[], vendor) {
                result.push({
                  id: '' + vendor.buyerUserId,
                  label: '' + vendor.name,
                });
                return result;
              }, []),
            ],
          },
        foundUsers.discovered &&
          foundUsers.discovered.length > 0 && {
            label: I18n.t('Discovered'),
            options: [
              ...foundUsers.discovered.map(linguist => {
                return {
                  id: '' + linguist.id,
                  label: '' + displayUser(linguist),
                  description: `${linguist.userName} (${linguist.email})`,
                  labelTag: linguist.role,
                };
              }),
            ],
          },
        foundUsers.other &&
          foundUsers.other.length > 0 && {
            label: I18n.t('Other'),
            options: [
              ...foundUsers.other.map(linguist => {
                return {
                  id: '' + linguist.id,
                  label: '' + displayUser(linguist),
                  description: `${linguist.userName} (${linguist.email})`,
                  labelTag: linguist.role,
                };
              }),
            ],
          },
      ].filter(o => o) as Select.OptionsGroup[])) ||
    []
  );
};

export interface Props<T extends JobCreateAPIStep2> extends FormikProps<T> {
  validating: boolean;
  session: AuthenticatedSession;
  isLoadingActiveLanguages: boolean;
  isLoadingWorkflowSteps: boolean;
  isLoadingUsers: boolean;
  isProjectTemplateMode: boolean;
  workflowSteps: WorkflowStep[];
  workflowStepsById: { [id: number]: WorkflowStep };
  activeLanguages: Language[];
  activeLanguagesByCode: { [code: string]: Language };
  foundUsers?: {
    other: User[];
    vendors: Vendor[];
    recent: User[];
    discovered: User[];
  };
}

interface WorkflowStepConfig {
  assignedUserIds?: number[];
  assignedUserIdsByTargetLocale?: { [localeCode: string]: number[] };
  assignPerTargetLocale?: boolean;
  notifyLinguists?: EmailNotificationSettings;
  dueDate?: string;
}

export interface JobCreateAPIStep2 {
  targetLocales?: string[];
  workflowStepIds: number[];
  workflowStepConfigs?: WorkflowStepConfig;
}

interface ReducerState {
  isError: boolean;
  error?: string;
}

export const jobCreateSchemaStep2 = yup.object().shape({});

export const jobCreateInitialFormValuesStep2 = (
  currentValue?: Project | ProjectTemplate,
  initProjectTemplate?: ProjectTemplate // We will only use this parameter in JobCreate.tsx to get assignments and email notification settings
): JobCreateAPIStep2 => {
  const assignmentsSource: ProjectTemplate | undefined = (initProjectTemplate ??
    currentValue) as ProjectTemplate;

  return {
    targetLocales: currentValue?.targetLocales,
    workflowStepIds: currentValue?.workflowStepIds ?? [],
    workflowStepConfigs:
      assignmentsSource?.workflowStepConfigs ??
      // -1 is for default workflow step. Concat so that it gets default values as well.
      currentValue?.workflowStepIds?.concat([-1])?.reduce((acc, wfsId) => {
        return {
          ...acc,
          [wfsId]: {
            assignedUserIds: [],
            assignedUserIdsByTargetLocale: {},
            assignPerTargetLocale: false,
            notifyLinguists: {
              emailIntervalInMinutes: 0,
              enabled: false,
            },
          },
        };
      }, {}),
  };
};

const Content = <T extends JobCreateAPIStep2>({
  errors,
  validating,
  values,
  setFieldValue,
  handleBlur,
  handleChange,
  activeLanguagesByCode,
  workflowStepsById,
  isLoadingUsers,
  foundUsers,
  session,
}: Props<T>): ReactElement => {
  const init = (): ReducerState => ({
    isError: false,
  });

  const reducer = (state, action): ReducerState => {
    switch (action.type) {
      default:
        return state;
    }
  };

  const [reducerState] = useReducer(reducer, null, init);

  const getWorkflowStepInfo = (): WorkflowStep[] => {
    // This step filters out any workflow step which are deleted in an organization.
    // workflowStepsById is a map of active workflow steps in an organization
    const workflowSteps =
      values.workflowStepIds.length > 0
        ? values.workflowStepIds.filter(id => workflowStepsById[id] !== undefined)
        : [];
    const workflowInfo: WorkflowStep[] =
      workflowSteps.length > 0
        ? workflowSteps.map(wfsId => workflowStepsById[wfsId])
        : [{ name: I18n.t('Default workflow step'), id: -1, level: 0, ord: 0, type: 'OTHER' }];
    return workflowInfo;
  };

  const workflowStepsOrDefault: WorkflowStep[] = useMemo(() => getWorkflowStepInfo(), [
    values.workflowStepIds,
  ]);

  const handleChangeAsInt = fieldName => value => {
    if (Array.isArray(value)) {
      setFieldValue(
        fieldName,
        value.map(v => parseInt(v))
      );
    } else if (typeof value === 'string') {
      setFieldValue(fieldName, parseInt(value));
    } else if (typeof value === 'number') {
      setFieldValue(fieldName, value);
    } else {
      throw 'Unexpected value: ${';
    }
  };

  const userTimezone = useMemo((): Zone | string => {
    return getUserTimezone(session);
  }, [session]);

  const toDateTime = useCallback(
    (date: string | undefined, time: string | undefined): DateTime | undefined => {
      let dateString: string;
      if (!date || date.length === 0) {
        return undefined;
      }
      if (time && time.length > 0) {
        dateString = `${date}T${time}`;
      } else {
        dateString = date;
      }
      return DateTime.fromISO(dateString, {
        zone: userTimezone,
      });
    },
    [userTimezone]
  );

  const isDateEnabled = (date: Date): boolean => {
    const dateTime = toDateTime(date.toISOString(), '') ?? DateTime.local();
    return dateTime >= DateTime.local().minus({ days: 1 });
  };

  const userOptions = useMemo(() => getUserOptions(foundUsers), [foundUsers]);
  useEffect(() => {
    values.workflowStepIds.forEach(id => {
      if (!values?.workflowStepConfigs?.hasOwnProperty(id)) {
        setFieldValue('workflowStepConfigs', {
          ...values.workflowStepConfigs,
          [id]: {},
        });
      }
    });
  }, [values.workflowStepIds]);

  return (
    <FormContainer>
      <Form
        errorText={
          validating && Object.keys(errors).length !== 0
            ? I18n.t('The form contains errors. Fix them and resubmit.')
            : ''
        }
      >
        {workflowStepsOrDefault.map(wfs => (
          <FormSection key={wfs.id} header={wfs.name} id={wfs.name}>
            <ColumnLayout>
              <div data-awsui-column-layout-root="true">
                <FormField>
                  <Checkbox
                    label={I18n.t('Assign jobs per target language')}
                    checked={values.workflowStepConfigs?.[wfs.id]?.assignPerTargetLocale ?? false}
                    id={`workflowStepConfigs[${wfs.id}].assignPerTargetLocale`}
                    controlId={`workflowStepConfigs[${wfs.id}].assignPerTargetLocale`}
                    onChange={handleChange}
                  />
                </FormField>
                {values.workflowStepConfigs?.[wfs.id]?.assignPerTargetLocale &&
                  values.targetLocales &&
                  values.targetLocales.map(
                    targetLocale =>
                      activeLanguagesByCode[targetLocale] && (
                        <FormField
                          key={targetLocale}
                          description={activeLanguagesByCode[targetLocale].name}
                        >
                          <MultiselectWithSelectAllInGroup
                            loading={isLoadingUsers}
                            options={userOptions}
                            selectedIds={(
                              values?.workflowStepConfigs?.[wfs.id]
                                ?.assignedUserIdsByTargetLocale?.[targetLocale] ?? []
                            ).map(id => '' + id)}
                            onChange={(e): void =>
                              handleChangeAsInt(
                                `workflowStepConfigs[${wfs.id}].assignedUserIdsByTargetLocale[${targetLocale}]`
                              )(e.detail.selectedIds)
                            }
                            onBlur={handleBlur}
                            placeholder={I18n.t('Choose linguists')}
                            checkboxes={true}
                            keepOpen={true}
                            controlId={`workflowStepConfigs[${wfs.id}].assignedUserIdsByTargetLocale[${targetLocale}]`}
                            id={`workflowStepConfigs[${wfs.id}].assignedUserIdsByTargetLocale[${targetLocale}]`}
                            filteringType="auto"
                          />
                        </FormField>
                      )
                  )}
                {!values.workflowStepConfigs?.[wfs.id]?.assignPerTargetLocale && (
                  <FormField description={I18n.t('All target locales')}>
                    <MultiselectWithSelectAllInGroup
                      loading={isLoadingUsers}
                      options={userOptions}
                      selectedIds={(
                        values.workflowStepConfigs?.[wfs.id]?.assignedUserIds ?? []
                      ).map(id => '' + id)}
                      onChange={(e): void =>
                        handleChangeAsInt(`workflowStepConfigs[${wfs.id}].assignedUserIds`)(
                          e.detail.selectedIds
                        )
                      }
                      onBlur={handleBlur}
                      placeholder={I18n.t('Choose linguists')}
                      checkboxes={true}
                      keepOpen={true}
                      controlId={`workflowStepConfigs[${wfs.id}].assignedUserIds`}
                      id={`workflowStepConfigs[${wfs.id}].assignedUserIds`}
                      filteringType="auto"
                    />
                  </FormField>
                )}
                {/* TODO: Due date/time should not be on the project template pages */}
                <FormField
                  label={I18n.t('Due date and time')}
                  description={I18n.t('Date/time when the job must be completed')}
                  hintText={I18n.t('Time zone: %{tz}', {
                    tz: (values.workflowStepConfigs?.[wfs.id]?.dueDate
                      ? DateTime.fromISO(values.workflowStepConfigs?.[wfs.id]?.dueDate)
                      : DateTime.local()
                    )
                      .setZone(userTimezone)
                      .toFormat("ZZZZZ '(UTC'ZZ')'"),
                  })}
                  errorText={errors['dueDate']}
                >
                  <DateTimePicker
                    value={values.workflowStepConfigs?.[wfs.id]?.dueDate}
                    onChange={timestamp =>
                      setFieldValue(`workflowStepConfigs[${wfs.id}].dueDate`, timestamp)
                    }
                    timezone={userTimezone}
                    isDateEnabled={isDateEnabled}
                  />
                </FormField>
                <FormField>
                  <JobNotification
                    isError={reducerState.isError}
                    onEmailTemplateChange={(emailTemplate): void => {
                      setFieldValue(
                        `workflowStepConfigs.[${wfs.id}].notifyLinguists.emailTemplateId`,
                        emailTemplate
                      );
                    }}
                    onEnableChange={(isEnabled): void => {
                      setFieldValue(
                        `workflowStepConfigs.[${wfs.id}].notifyLinguists.enabled`,
                        isEnabled
                      );
                    }}
                    onIntervalChange={(notificationInterval): void => {
                      setFieldValue(
                        `workflowStepConfigs.[${wfs.id}].notifyLinguists.emailIntervalInMinutes`,
                        notificationInterval
                      );
                    }}
                    enabled={values.workflowStepConfigs?.[wfs.id]?.notifyLinguists?.enabled}
                    emailIntervalInMinutes={
                      values.workflowStepConfigs?.[wfs.id]?.notifyLinguists?.emailIntervalInMinutes
                    }
                    emailTemplateId={
                      values.workflowStepConfigs?.[wfs.id]?.notifyLinguists?.emailTemplateId
                    }
                  />
                </FormField>
              </div>
            </ColumnLayout>
          </FormSection>
        ))}
        {reducerState.isError && (
          <div className={formSpacing}>
            <Flash
              id="validation-error"
              type="error"
              dismissible={true}
              content={I18n.t('Please try again. If the issue persists, contact support.')}
              header={I18n.t('Something went wrong.')}
            />
          </div>
        )}
      </Form>
    </FormContainer>
  );
};

export const JobCreateStep2AssignJobs = <T extends JobCreateAPIStep2>(
  props: Props<T>
): WizardStepWithSummary => {
  const { foundUsers } = props;
  const title = I18n.t('Job assignment');
  let description = I18n.t(
    'Set up default assignments for jobs created with this project template'
  );
  if (
    props.isProjectTemplateMode &&
    dynamicAssignmentEnabled(props?.session?.organization?.id ?? -1, inferEnvironment())
  ) {
    description = I18n.t(
      'The jobs will be dynamically assigned. The settings specified in the project template will be used as a fallback.'
    );
  }
  const linguistsName = useMemo(() => {
    if (foundUsers) {
      let linguists: any[] = [];
      Object.keys(foundUsers).forEach(u => (linguists = linguists.concat(foundUsers[u])));
      return linguists.reduce(
        (obj, item) => ({
          ...obj,
          [item.buyerUserId ?? item.id]:
            item.name ??
            (item.firstName !== null ? item.firstName + ' ' + item.lastName : item.userName),
        }),
        {}
      );
    } else {
      return {};
    }
  }, [foundUsers]);
  return {
    title: title,
    info: (): ReactElement => <HelpInfoLink helpId={PROJECT_CREATE_ASSETS_HELP} />,
    description: description,
    content: (): ReactElement => <Content {...props} />,
    isOptional: true,
    summary: (): ReactNode => {
      const { values, workflowStepsById } = props;
      const getLinguistsNamesAsString = (linguistsIds: number[]): string =>
        Array.isArray(linguistsIds)
          ? linguistsIds
              .map(id => linguistsName[id + ''])
              .reduce((pre, cur) => (pre === '-' && cur ? cur + '' : pre + ',' + cur), '-')
          : '';

      const getSummaryDisplay = (values: JobCreateAPIStep2, id: number): ReactNode => (
        <SummaryTable>
          <tbody>
            <SummaryTableTR>
              <th>{'Locale(s)'}</th>
              <th>{'Linguist(s)'}</th>
            </SummaryTableTR>
            {values.workflowStepConfigs?.[id]?.assignPerTargetLocale &&
            values.workflowStepConfigs?.[id]?.assignedUsers ? (
              Object.keys(values.workflowStepConfigs?.[id]?.assignedUsers).map(tl => (
                <SummaryTableTR key={id}>
                  <td>{tl}</td>
                  <td className={overflowWrap}>
                    {getLinguistsNamesAsString(
                      values.workflowStepConfigs?.[id]?.assignedUsers[tl] ?? []
                    )}
                  </td>
                </SummaryTableTR>
              ))
            ) : (
              <SummaryTableTR>
                <td>All target locales</td>
                <td className={overflowWrap}>
                  {getLinguistsNamesAsString(values.workflowStepConfigs?.[id]?.assignedUsers ?? [])}{' '}
                </td>
              </SummaryTableTR>
            )}
          </tbody>
        </SummaryTable>
      );
      const summaryElements = values.workflowStepIds.map(id => {
        return coalesceSummaryValue({
          value: values.workflowStepConfigs?.[id]?.assignedUsers,
          label: workflowStepsById[id]?.name,
          displayValue: getSummaryDisplay(values, id),
        });
      });
      return (
        <Container title={title} key={title}>
          <ColumnLayout columns={4}>
            <div data-awsui-column-layout-root="true">
              {summaryElements.length > 0 ? (
                summaryElements
              ) : (
                <div>
                  <div>
                    <T>No changes</T>
                  </div>
                </div>
              )}
            </div>
          </ColumnLayout>
        </Container>
      );
    },
  };
};
