import { useEffect, useState } from 'react';
import { ProjectTemplate } from '../types/commonTypes';
import { paginateInParallel } from '../../shared/paginationHelper';
import { getAppHostConfig } from '@amzn/et-console-components';

const { WEB_HOST_AND_PORT } = getAppHostConfig();

interface State {
  isLoadingProjectTemplates: boolean;
  projectTemplates: ProjectTemplate[];
}
// TODO: Needs to reload when the user profile changes
export const useProjectTemplates = (): State => {
  const [state, setState] = useState<State>({
    isLoadingProjectTemplates: true,
    projectTemplates: [],
  });
  const pageSize = 60;
  useEffect(() => {
    const fetchTemplates = async (): Promise<any> => {
      const results = await paginateInParallel(
        `//${WEB_HOST_AND_PORT}/web/api/v9/projectTemplate/listIdName?pageSize=${pageSize}`,
        5
      );
      setState({
        isLoadingProjectTemplates: false,
        projectTemplates: results,
      });
    };
    fetchTemplates();
  }, []);
  return state;
};
