import styled from '@emotion/styled';
import { useLayoutEffect, useMemo, useState } from 'react';
import { AtmsApiClient } from '@amzn/et-console-components';
import React, { ReactElement } from 'react';
import { Checkbox, FormField, Select, SpaceBetween } from '@amzn/awsui-components-react-v3';
import I18n from '../setupI18n';
import { EmailTemplate } from '../App/types/commonTypes';
import { OptionDefinition } from '@amzn/awsui-components-react-v3/polaris/internal/components/option/interfaces';
import { getAppHostConfig } from '@amzn/et-console-components';

const { WEB_HOST_AND_PORT } = getAppHostConfig();

const SelectContainer = styled('div')`
  width: 70%;
`;

const GreyText = styled('span')`
  color: #879596;
`;

const CheckBoxChildren = styled('div')`
  border-left: 3px solid #eaeded;
  margin-left: 22px;
  padding-left: 22px;
  padding-top: 10px;
  height: 140px;
`;

interface EmailState {
  isLoadingEmailTemplates: boolean;
  emailTemplates?: EmailTemplate[];
}

export const getSelectedEmailTemplateOption = (
  emailState?: any,
  emailTemplateId?: number | undefined
): OptionDefinition => {
  const selectedEmailTemplate = emailState?.emailTemplates?.find(e => {
    if (emailTemplateId) {
      return e.id === emailTemplateId;
    } else {
      return e.name.includes('New Work');
    }
  });
  return {
    label: selectedEmailTemplate?.name ?? 'None',
    value: selectedEmailTemplate?.id ? selectedEmailTemplate?.id + '' : '-1',
  };
};

export const getIntervalOption = (interval?: number): OptionDefinition => {
  switch (interval) {
    case 1:
      return { value: interval + '', label: `${interval} minute` };
    case 5:
    case 10:
    case 20:
    case 40:
    case 60:
      return { value: interval + '', label: `${interval} minutes` };
    case 360:
    case 720:
    case 1440:
      return { value: interval + '', label: `${interval / 60} hours` };
    default:
      return { value: 0 + '', label: 'All at once' };
  }
};

const intervalOptions = [1, 5, 10, 20, 40, 60, 360, 720, 1440, 0].map(v => getIntervalOption(v));

export interface EmailNotificationSettings {
  enabled: boolean;
  emailIntervalInMinutes?: number;
  emailTemplateId?: number;
}

interface Props extends EmailNotificationSettings {
  onEnableChange: (isEnabled: boolean) => void;
  onEmailTemplateChange: (emailTemplateId?: number) => void;
  onIntervalChange: (notificationInterval?: number) => void;
  isError: boolean;
}

export const JobNotification = ({
  onEmailTemplateChange,
  onEnableChange,
  onIntervalChange,
  enabled,
  emailIntervalInMinutes,
  emailTemplateId,
  isError,
}: Props): ReactElement => {
  const [emailState, setEmailState] = useState<EmailState>({
    isLoadingEmailTemplates: true,
    emailTemplates: undefined,
  });
  useLayoutEffect(() => {
    AtmsApiClient.httpGet(`//${WEB_HOST_AND_PORT}/web/api/v9/emailTemplate/list?type=JobAssigned`)
      .then(result => {
        setEmailState({ isLoadingEmailTemplates: false, emailTemplates: result });
      })
      .catch(() => {
        setEmailState({ isLoadingEmailTemplates: false });
      });
  }, []);
  const emailTemplateSelectionStatus = emailState.isLoadingEmailTemplates
    ? 'loading'
    : isError
    ? 'error'
    : 'finished';
  const getColoredLabel = (text: string): ReactElement =>
    enabled ? <span>{text}</span> : <GreyText>{text}</GreyText>;

  const emailTemplatesPlusBlank = useMemo(
    () => [{ id: -1, name: 'None' }, ...(emailState?.emailTemplates ?? [])],
    [emailState.emailTemplates]
  );

  const emailTemplateOptions = useMemo(
    () =>
      emailTemplatesPlusBlank.map(t => ({
        label: t.name,
        value: t.id + '',
      })),
    [emailTemplatesPlusBlank]
  );

  const selectedEmailTemplateOption = useMemo(() => {
    const selectedOption = getSelectedEmailTemplateOption(emailState, emailTemplateId);
    // This ensures that after the call to the emailTemplate/list endpoint returns results, the value
    // gets saved to the workflowStepConfigs in addition to populating the dropdown menu when
    // it sets the 'New Work' option.
    if (
      selectedOption.value &&
      selectedOption.value !== 'undefined' &&
      selectedOption.value !== '-1'
    ) {
      onEmailTemplateChange(parseInt(selectedOption.value));
    }
    return selectedOption;
  }, [emailState.emailTemplates, emailTemplateId]);

  const selectedIntervalOption = useMemo(() => {
    return getIntervalOption(emailIntervalInMinutes);
  }, [emailIntervalInMinutes]);

  return (
    <SpaceBetween direction="vertical" size="xs">
      <Checkbox
        id="isEnabled"
        controlId="isEnabled"
        checked={enabled}
        onChange={(e): void => {
          onEnableChange(e.detail.checked);
          // When enabling notifications and emailInterval hasn't been manually set,
          // default to all at once interval notifications
          if (emailIntervalInMinutes === -1) {
            onIntervalChange(0);
          }
        }}
      >
        {I18n.t('Notify linguists of job creation')}
      </Checkbox>
      <CheckBoxChildren>
        <SpaceBetween direction="vertical" size="xs">
          <FormField label={getColoredLabel(I18n.t('Email template'))}>
            <SelectContainer>
              <Select
                id="notifyLinguistsEmailTemplate"
                name="notifyLinguistsEmailTemplate"
                statusType={emailTemplateSelectionStatus}
                disabled={!enabled}
                selectedOption={selectedEmailTemplateOption}
                options={emailTemplateOptions}
                selectedAriaLabel="Selected"
                onChange={(e): void => {
                  onEmailTemplateChange(
                    e.detail.selectedOption.value && e.detail.selectedOption.value !== 'undefined'
                      ? parseInt(e.detail.selectedOption.value)
                      : undefined
                  );
                }}
              />
            </SelectContainer>
          </FormField>
          <FormField label={getColoredLabel(I18n.t('Interval'))}>
            <SelectContainer>
              <Select
                id="notifyLinguistsInterval"
                name="notifyLinguistsInterval"
                disabled={!enabled}
                selectedOption={selectedIntervalOption}
                options={intervalOptions}
                selectedAriaLabel="Selected"
                onChange={(e): void => {
                  onIntervalChange(
                    e.detail.selectedOption.value
                      ? parseInt(e.detail.selectedOption.value)
                      : undefined
                  );
                }}
              />
            </SelectContainer>
          </FormField>
        </SpaceBetween>
      </CheckBoxChildren>
    </SpaceBetween>
  );
};
