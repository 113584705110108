import { Client, CostCenter } from '../types/commonTypes';
import { useEffect, useState } from 'react';
import { paginateAll } from '../../shared/paginationHelper';
import { getAppHostConfig } from '@amzn/et-console-components';

const { WEB_HOST_AND_PORT } = getAppHostConfig();

interface State {
  isLoadingCostCenters: boolean;
  costCenters: CostCenter[];
  costCentersById: { [id: number]: Client };
}

// TODO: Needs to reload when the user profile changes
export const useCostCenters = (): State => {
  const [state, setState] = useState<State>({
    isLoadingCostCenters: true,
    costCenters: [],
    costCentersById: {},
  });
  useEffect(() => {
    const paginate = async (): Promise<void> => {
      const result = await paginateAll(`//${WEB_HOST_AND_PORT}/web/api/v9/costCenter/list`);

      setState({
        isLoadingCostCenters: false,
        costCenters: result,
        costCentersById: result.reduce((acc, val) => {
          acc[val.id] = val;
          return acc;
        }, {}),
      });
    };

    paginate();
  }, []);

  return state;
};
