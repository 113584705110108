import { useEffect, useState } from 'react';
import { AtmsApiClient } from '@amzn/et-console-components';
import { getAppHostConfig } from '@amzn/et-console-components';

const { WEB_HOST_AND_PORT } = getAppHostConfig();

interface FileFormat {
  value: string;
  title: string;
  backendFormat: string;
}

interface State {
  isLoadingSupportedFileFormats: boolean;
  supportedFileFormats: FileFormat[];
  supportedFileFormatsByBackendFormat: { [backendFormat: string]: FileFormat };
  supportedFileFormatsByValue: { [value: string]: FileFormat };
}

// TODO: Needs to reload when the user profile changes
export const useSupportedFileFormats = (): State => {
  const [state, setState] = useState<State>({
    isLoadingSupportedFileFormats: true,
    supportedFileFormats: [],
    supportedFileFormatsByBackendFormat: {},
    supportedFileFormatsByValue: {},
  });
  useEffect(() => {
    AtmsApiClient.httpGet(`//${WEB_HOST_AND_PORT}/web/api/v9/file/getSupportedFileFormats`).then(
      result => {
        setState({
          isLoadingSupportedFileFormats: false,
          supportedFileFormats: result,
          supportedFileFormatsByBackendFormat: result.reduce((acc, val) => {
            acc[val.backendFormat] = val;
            return acc;
          }, {}),
          supportedFileFormatsByValue: result.reduce((acc, val) => {
            acc[val.value] = val;
            return acc;
          }, {}),
        });
      }
    );
  }, []);

  return state;
};
