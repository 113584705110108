import * as React from 'react';
import { ReactElement, useReducer, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { PageHeader, PolarisFormik } from '@amzn/et-polaris-utils';
import * as yup from 'yup';
import {
  Button,
  Checkbox,
  ColumnLayout,
  Flash,
  Form,
  FormField,
  FormSection,
  Select,
} from '@amzn/awsui-components-react/polaris';
import { css } from 'emotion';
import { AtmsApiClient } from '@amzn/et-console-components';
import styled from '@emotion/styled';
import { HelpInfoLink } from '../HelpContentRouter';
import { UPLOAD_BILINGUAL_FILE_HELP } from './projectHelpContent';
import I18n from '../../setupI18n';
import { publishCountMetric } from '../metricHelper';
import { getAppHostConfig } from '@amzn/et-console-components';

const { WEB_HOST_AND_PORT } = getAppHostConfig();

const formSpacing = css`
  padding-top: 14px;
  padding-bottom: 14px;
`;

const FormContainer = styled('div')`
  max-width: 800px;
  min-width: 280px;
`;

interface ReducerState {
  isUploading: boolean;
  isError: boolean;
  bilingualFile?: File;
  saveToTransMemory: 'All' | 'Confirmed' | 'None';
  setCompleted: boolean;
  error?: string;
}

export const UploadBilingualFileWorkflow = withRouter(
  ({ location: { pathname }, history }: RouteComponentProps) => {
    // TODO: We should be able to get this from the Router.match.params prop
    const projectUID = pathname.substr(pathname.lastIndexOf('/') + 1);

    const init = (): ReducerState => ({
      isUploading: false,
      isError: false,
      saveToTransMemory: 'Confirmed',
      setCompleted: false,
    });

    const reducer = (state, action): ReducerState => {
      switch (action.type) {
        case 'uploadingBilingualFile':
          return {
            ...state,
            isUploading: true,
            isError: false,
            saveToTransMemory: action.saveToTransMemory,
            setCompleted: action.setCompleted,
          };
        case 'uploadedBilingualFile':
          return {
            ...state,
            isUploading: false,
          };
        case 'uploadBilingualFileFailed':
          return {
            ...state,
            isError: true,
            isUploading: false,
            error: action.error,
          };
        default:
          return state;
      }
    };

    const [reducerState, dispatch] = useReducer(reducer, null, init);
    const [validating, setValidating] = useState(false);
    const { isUploading } = reducerState;

    /**
     * Per AWS Specifications, only start validating after the initial submit.
     * @param e the event
     * @param handleSubmit formik submit event handler function.
     */
    const enableValidation = (e, handleSubmit): void => {
      e.preventDefault();
      setValidating(true);
      handleSubmit();
    };

    const handleSubmit = (values): void => {
      const file = values.bilingualFile[0];
      const fileNameSplit = file.name.toLowerCase().split('.');
      let format;
      switch (fileNameSplit[fileNameSplit.length - 1]) {
        case 'mxliff':
          format = 'MXLF';
          break;
        case 'docx':
          format = 'DOCX';
          break;
        default:
          dispatch({
            type: 'uploadBilingualFileFailed',
            error: `Unexpected file type: ${values.bilingualFile[0]}`,
          });
          return;
      }

      const body = {
        bilingualFile: values.bilingualFile[0],
        format: format,
        saveToTransMemory: values.saveToTransMemory,
        setCompleted: values.setCompleted,
      };

      dispatch({
        type: 'uploadingBilingualFile',
        ...body,
      });
      AtmsApiClient.multipartUpload(
        `//${WEB_HOST_AND_PORT}/web/api/v9/job/uploadBilingualFile`,
        body
      )
        .then(() => {
          dispatch({ type: 'uploadedBilingualFile' });
          history.push(`/web/project2/show/${projectUID}`);
        })
        .catch(e => {
          publishCountMetric(
            'uploadBilingualFileFailed-UploadBilingualFileWorkflow',
            'error',
            e.message
          );
          dispatch({ type: 'uploadBilingualFileFailed', error: e });
        });
    };

    const renderForm = ({
      values,
      errors,
      handleSubmit,
      handleChange,
      handleBlur,
    }): ReactElement => {
      return (
        <FormContainer>
          <form onSubmit={e => enableValidation(e, handleSubmit)}>
            <Form
              header={
                <PageHeader
                  title="Upload bilingual file"
                  extraContent={<HelpInfoLink helpId={UPLOAD_BILINGUAL_FILE_HELP} />}
                />
              }
              actions={
                <div>
                  <Button
                    id="upload"
                    text="Upload file"
                    variant="primary"
                    onClick={e => enableValidation(e, handleSubmit)}
                    disabled={isUploading}
                    loading={isUploading}
                  />
                </div>
              }
              errorText={
                Object.keys(errors).length !== 0
                  ? 'The form contains errors. Fix them and resubmit.'
                  : ''
              }
            >
              <FormSection>
                <ColumnLayout>
                  <div data-awsui-column-layout-root="true">
                    <FormField
                      label={I18n.t('Bilingual file')}
                      errorText={errors.bilingualFile ? I18n.t('Bilingual file is required') : ''}
                    >
                      {/* TODO: Replace with AWS-UI component when available: https://polaris.corp.amazon.com/system/structures/fileupload/ */}
                      <input
                        type="file"
                        name="bilingualFile"
                        accept=".mxliff,.docx"
                        onChange={handleChange}
                      />
                    </FormField>
                    <FormField
                      label={I18n.t('What segments should be saved to translation memory?')}
                    >
                      <Select
                        controlId="saveToTransMemory"
                        options={[
                          {
                            id: 'All',
                            label: I18n.t('All segments'),
                          },
                          {
                            id: 'Confirmed',
                            label: I18n.t('Confirmed segments only'),
                          },
                          {
                            id: 'None',
                            label: I18n.t('None'),
                          },
                        ]}
                        selectedId={values.saveToTransMemory}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormField>
                    <FormField>
                      <Checkbox
                        name="setCompleted"
                        label={I18n.t("Set job status to 'Completed by Linguist'")}
                        onChange={handleChange}
                        checked={values.setCompleted}
                      />
                    </FormField>
                  </div>
                </ColumnLayout>
              </FormSection>
              {reducerState.isError && (
                <div className={formSpacing}>
                  <Flash
                    type="error"
                    dismissible={true}
                    content={I18n.t('Please try again. If the issue persists, contact support.')}
                    header={I18n.t('Something went wrong.')}
                  />
                </div>
              )}
            </Form>
          </form>
        </FormContainer>
      );
    };

    const initialFormValues = {
      bilingualFile: reducerState.bilingualFile,
      saveToTransMemory: reducerState.saveToTransMemory,
      setCompleted: reducerState.setCompleted,
    };

    const schema = yup.object().shape({
      // bilingualFile is a FileList type which doesn't match array() or object()
      bilingualFile: yup.mixed().required(),
      saveToTransMemory: yup.string(),
      setCompleted: yup.boolean(),
    });

    return (
      <PolarisFormik
        initialValues={initialFormValues}
        validationSchema={schema}
        validateOnChange={validating}
        onSubmit={handleSubmit}
        render={renderForm}
      />
    );
  }
);
