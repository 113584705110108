import * as React from 'react';
import { ReactElement, useEffect, useReducer, useState } from 'react';
import { withRouter } from 'react-router';
import { PageHeader, PolarisFormik } from '@amzn/et-polaris-utils';
import * as yup from 'yup';
import {
  Button,
  ColumnLayout,
  Flash,
  Form,
  FormField,
  FormSection,
  Input,
  Select,
  Spinner,
} from '@amzn/awsui-components-react/polaris';
import { css } from 'emotion';
import { AtmsApiClient, formatPercent } from '@amzn/et-console-components';
import styled from '@emotion/styled';
import { History, Location } from 'history';
import I18n from '../../setupI18n';
import { PreTranslateSettings, SelectAllOnServerApi } from '../types/commonTypes';
import { useCallback } from 'react';
import { publishCountMetric } from '../metricHelper';

const formSpacing = css`
  padding-top: 14px;
  padding-bottom: 14px;
`;

const FormContainer = styled('div')`
  max-width: 800px;
  min-width: 280px;
`;

export interface Props {
  location: Location<SelectAllOnServerApi>;
  history: History;
}

interface ReducerState {
  preTranslateSettings?: PreTranslateSettings;
  isPseudoTranslating: boolean;
  isError: boolean;
  replacement?: string;
  prefix?: string;
  suffix?: string;
  length: number;
}

export const PseudoTranslateWorkflow = withRouter(
  ({ location: { pathname, state }, history }: Props) => {
    // TODO: We should be able to get this from the Router.match.params prop
    const projectUID = pathname.substr(pathname.lastIndexOf('/') + 1);

    const init = (): ReducerState => ({
      isPseudoTranslating: false,
      isError: false,
      replacement: '$',
      prefix: '',
      suffix: '',
      length: 1,
    });

    const reducer = (state, action): ReducerState => {
      switch (action.type) {
        case 'preTranslateSettingsFetching':
          return {
            ...state,
            preTranslateSettings: null,
          };
        case 'preTranslateSettingsFetched':
          return {
            ...state,
            preTranslateSettings: action.preTranslateSettings,
          };
        case 'preTranslateSettingsFetchFailed':
          return {
            ...state,
            isError: true,
            error: action.error,
          };
        case 'pseudoTranslating':
          return {
            ...state,
            isPseudoTranslating: true,
            isError: false,
            replacement: action.replacement,
            prefix: action.prefix,
            suffix: action.suffix,
            length: action.length,
          };
        case 'finishedPseudoTranlslating':
          return {
            ...state,
            isPseudoTranslating: false,
          };
        case 'pseudoTranslatingFailed':
          return {
            ...state,
            isPseudoTranslating: false,
            isError: true,
            error: action.error,
          };
        default:
          return state;
      }
    };

    const [reducerState, dispatch] = useReducer(reducer, null, init);
    const [validating, setValidating] = useState(false);
    const { isPseudoTranslating, preTranslateSettings } = reducerState;

    /**
     * Per AWS Specifications, only start validating after the initial submit.
     * @param e the event
     * @param handleSubmit formik submit event handler function.
     */
    const enableValidation = (e, handleSubmit): void => {
      e.preventDefault();
      setValidating(true);
      handleSubmit();
    };

    const handleLoadPreTranslateSettings = useCallback(() => {
      dispatch({
        type: 'preTranslateSettingsFetching',
      });
      AtmsApiClient.httpGet(`/api/project?uid=${projectUID}`)
        .then(response => {
          dispatch({
            type: 'preTranslateSettingsFetched',
            preTranslateSettings: response.preTranslateSettings,
          });
        })
        .catch(e => {
          dispatch({ type: 'preTranslateSettingsFetchFailed', error: e });
        });
    }, [projectUID]);

    const handleSubmit = (values): void => {
      // TODO: There are other options that are supported by the API
      const request = {
        projectUid: projectUID,
        jobPartIds: state.jobPartIds,
        selectAllOnServer: state.selectAllOnServer,
        level: state.level,
        assignedTo: state.assignedTo,
        status: state.status,
        fileName: state.fileName,
        targetLocale: state.targetLocale,
        dueInHours: state.dueInHours,

        replacement: values.replacement,
        prefix: values.prefix,
        suffix: values.suffix,
        length: Number.parseFloat(values.length), //Convert from display value to API value
      };

      // TODO: Formik triggers this method asynchronously; so there's no way to avoid
      //  the 'use act()' test warning.
      dispatch({
        type: 'pseudoTranslating',
      });
      AtmsApiClient.httpPost('/api/job/pseudoTranslate', request)
        .then(() => {
          dispatch({ type: 'finishedPseudoTranslating' });
          history.push(`/web/project2/show/${projectUID}`);
        })
        .catch(e => {
          publishCountMetric('pseudoTranslatingFailed-PseudoTranslateWorkflow', 'error', e.message);
          dispatch({ type: 'pseudoTranslatingFailed', error: e });
        });
    };

    useEffect(handleLoadPreTranslateSettings, [handleLoadPreTranslateSettings]);

    const renderForm = ({
      values,
      errors,
      handleSubmit,
      handleChange,
      handleBlur,
    }): ReactElement => {
      return (
        <FormContainer>
          <form onSubmit={e => enableValidation(e, handleSubmit)}>
            <Form
              header={<PageHeader title={I18n.t('Pseudo-translate')} />}
              actions={
                <div>
                  <Button
                    id="pseudoTranslate"
                    text={I18n.t('Pseudo-translate')}
                    variant="primary"
                    onClick={e => enableValidation(e, handleSubmit)}
                    loading={isPseudoTranslating}
                  />
                </div>
              }
              errorText={
                Object.keys(errors).length !== 0
                  ? I18n.t('The form contains errors. Fix them and resubmit.')
                  : ''
              }
            >
              <FormSection>
                <ColumnLayout>
                  <div data-awsui-column-layout-root="true">
                    <FormField label={I18n.t('Pseudo-translate using')}>
                      <Input
                        id="replacement"
                        name="replacement"
                        onChange={handleChange}
                        value={values.replacement}
                      />
                    </FormField>
                    <FormField label={I18n.t('Add target segment start')}>
                      <Input
                        id="prefix"
                        name="prefix"
                        onChange={handleChange}
                        value={values.prefix}
                      />
                    </FormField>
                    <FormField label={I18n.t('Add target segment end')}>
                      <Input
                        id="suffix"
                        name="suffix"
                        onChange={handleChange}
                        value={values.suffix}
                      />
                    </FormField>
                    <FormField label={I18n.t('Target segment length')}>
                      <Select
                        id="length"
                        controlId="length"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        selectedId={'' + values.length}
                        options={[
                          0.1,
                          0.5,
                          0.6,
                          0.7,
                          0.8,
                          0.9,
                          1,
                          1.1,
                          1.2,
                          1.3,
                          1.4,
                          1.5,
                          2,
                        ].map(v => ({ id: '' + v, label: formatPercent(v) }))}
                      />
                    </FormField>
                  </div>
                </ColumnLayout>
              </FormSection>
              {reducerState.isError && (
                <div className={formSpacing}>
                  <Flash
                    type="error"
                    dismissible={true}
                    content={I18n.t('Please try again. If the issue persists, contact support.')}
                    header={I18n.t('Something went wrong.')}
                  />
                </div>
              )}
            </Form>
          </form>
        </FormContainer>
      );
    };

    const initialFormValues = {
      replacement: preTranslateSettings?.pseudoTranslateReplacement ?? reducerState.replacement,
      prefix: preTranslateSettings?.pseudoTranslatePrefix ?? reducerState.prefix,
      suffix: preTranslateSettings?.pseudoTranslateSuffix ?? reducerState.suffix,
      length: preTranslateSettings?.pseudoTranslateLength ?? reducerState.length,
    };

    const schema = yup.object().shape({
      replacement: yup.string().nullable(),
      prefix: yup.string().nullable(),
      suffix: yup.string().nullable(),
      length: yup
        .number()
        .min(0)
        .nullable(),
    });

    return !preTranslateSettings ? (
      <Spinner size="large" />
    ) : (
      <PolarisFormik
        initialValues={initialFormValues}
        validationSchema={schema}
        validateOnChange={validating}
        onSubmit={handleSubmit}
        render={renderForm}
      />
    );
  }
);
