import React, { ReactElement, Fragment, useEffect, useReducer } from 'react';
import { Alert } from '@amzn/awsui-components-react/polaris';
import { RouteComponentProps, withRouter } from 'react-router';

import { AtmsApiClient, Session } from '@amzn/et-console-components';
import I18n from '../setupI18n';
import { T } from './components/T';
import { Link } from 'react-router-dom';
import { dateLateUpdated } from './PrivacyPolicy';

interface ReducerState {
  acceptanceDate?: Date;
}

export const PrivacyPolicyConsent = withRouter(
  ({ session }: { session: Session } & RouteComponentProps): ReactElement => {
    const init = (): ReducerState => {
      return {
        acceptanceDate: new Date(),
      };
    };

    const reducer = (state, action): ReducerState => {
      switch (action.type) {
        case 'gotAcceptance':
          return {
            ...state,
            acceptanceDate: action.acceptanceDate,
          };
        case 'accepted':
          return {
            ...state,
            acceptanceDate: new Date(),
          };
        default:
          return state;
      }
    };

    const [reducerState, dispatch] = useReducer(reducer, null, init);
    const { acceptanceDate } = reducerState;

    const handleAcceptCookie = (): void => {
      AtmsApiClient.httpPut(`/api/cookieConsent`, {
        cookieConsent: {
          // This field is no longer used but required by the server, so we'll continue to send it for now.
          acceptance: true,
          // The default Date.toString() in Javascript is garbage, so convert to string explicitly
          acceptanceDate: new Date().toISOString(),
        },
      }).then(result => {
        dispatch({
          type: 'accepted',
          response: result,
        });
      });
    };

    useEffect(() => {
      AtmsApiClient.httpGet(`/api/cookieConsent`).then(result => {
        dispatch({
          type: 'gotAcceptance',
          // Convert the acceptance date back from an ISO date string
          acceptanceDate: result.acceptanceDate ? new Date(result.acceptanceDate) : undefined,
        });
      });
    }, [session]);

    const shouldShowAlert =
      (acceptanceDate ?? dateLateUpdated) <= dateLateUpdated && new Date() > dateLateUpdated;

    return (
      <Fragment>
        {shouldShowAlert && (
          <Alert
            id="accept-cookies"
            type="warning"
            content={
              <T>
                By continuing to use this website you agree with our{' '}
                <Link to={'/privacyPolicy'}>privacy policy</Link>, including our use of cookies to
                identify users within ATMS.
              </T>
            }
            buttonText={I18n.t('OK')}
            onButtonClick={(): void => {
              handleAcceptCookie();
            }}
          />
        )}
      </Fragment>
    );
  }
);
