import { useEffect, useState } from 'react';
import { AtmsApiClient } from '@amzn/et-console-components';
import { Language } from '../types/commonTypes';
import { getAppHostConfig } from '@amzn/et-console-components';

const { WEB_HOST_AND_PORT } = getAppHostConfig();

interface State {
  isLoadingActiveLanguages: boolean;
  activeLanguages: Language[];
  activeLanguagesByCode: { [code: number]: Language };
}

// TODO: Needs to reload when the user profile changes
export const useActiveLanguages = (): State => {
  const [state, setState] = useState<State>({
    isLoadingActiveLanguages: true,
    activeLanguages: [],
    activeLanguagesByCode: {},
  });
  useEffect(() => {
    AtmsApiClient.httpGet(`//${WEB_HOST_AND_PORT}/web/api/v9/language/listActiveLangs`).then(
      result => {
        result.sort((l1, l2) => (l1.name === l2.name ? 0 : l1.name > l2.name ? 1 : -1));
        setState({
          isLoadingActiveLanguages: false,
          activeLanguages: result,
          activeLanguagesByCode: result.reduce((acc, val) => {
            acc[val.code] = val;
            return acc;
          }, {}),
        });
      }
    );
  }, []);

  return state;
};
