import { useEffect, useState } from 'react';
import { AtmsApiClient } from '@amzn/et-console-components';
import { MachineTranslateSettings } from '../types/commonTypes';
import { getAppHostConfig } from '@amzn/et-console-components';

const { WEB_HOST_AND_PORT } = getAppHostConfig();

interface State {
  isLoadingMachineTranslateSettings: boolean;
  machineTranslateSettings: MachineTranslateSettings[];
  machineTranslateSettingsById: { [id: number]: MachineTranslateSettings };
}

// TODO: Needs to reload when the user profile changes
export const useMachineTranslateSettings = (): State => {
  const [state, setState] = useState<State>({
    isLoadingMachineTranslateSettings: true,
    machineTranslateSettings: [],
    machineTranslateSettingsById: {},
  });
  useEffect(() => {
    AtmsApiClient.httpGet(`//${WEB_HOST_AND_PORT}/web/api/v9/machineTranslateSettings/list`).then(
      result => {
        setState({
          isLoadingMachineTranslateSettings: false,
          machineTranslateSettings: result,
          machineTranslateSettingsById: result.reduce((acc, val) => {
            acc[val.id] = val;
            return acc;
          }, {}),
        });
      }
    );
  }, []);

  return state;
};
