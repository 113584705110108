import * as React from 'react';
import { ReactElement, useCallback, useEffect, useReducer, useState } from 'react';
import { RouteChildrenProps, withRouter } from 'react-router';
import { PolarisFormik } from '@amzn/et-polaris-utils';
import * as yup from 'yup';
import { stringify } from 'query-string';
import {
  Button,
  ColumnLayout,
  Flash,
  Form,
  FormField,
  FormSection,
  Select,
} from '@amzn/awsui-components-react/polaris';
import { css } from 'emotion';
import styled from '@emotion/styled';
import { AtmsApiClient } from '@amzn/et-console-components';
import I18n from '../../setupI18n';
import { formatLocaleCode } from '@amzn/et-console-components';
import { publishCountMetric } from '../metricHelper';

const formSpacing = css`
  padding-top: 14px;
  padding-bottom: 14px;
`;

const FormContainer = styled('div')`
  max-width: 800px;
  min-width: 280px;
`;

interface ReducerState {
  isLoadingMetadata: boolean;
  isError: boolean;
  error?: object;
  workflowSteps: [];
  workflow?: string;
  targetLocales: string[];
  targetLocale?: string;
}

export const SelectTranslationMemoryWorkflow = withRouter(
  ({ location: { pathname }, history }: RouteChildrenProps) => {
    // TODO: We should be able to get this from the Router.match.params prop
    const projectUID = pathname.substr(pathname.lastIndexOf('/') + 1);

    const ANY_TARGET_LOCALE_ID = '_any_';

    const init = (): ReducerState => ({
      isLoadingMetadata: false,
      isError: false,
      workflowSteps: [],
      targetLocales: [],
    });

    const reducer = (state, action): ReducerState => {
      switch (action.type) {
        case 'loadingMetadata':
          return {
            ...state,
            isLoadingMetadata: true,
            isError: false,
          };
        case 'loadedMetadata':
          return {
            ...state,
            isLoadingMetadata: false,
            workflowSteps: action.workflowSteps,
            targetLocales: action.targetLocales,
          };
        case 'loadMetadataFailed':
          return {
            ...state,
            isLoadingMetadata: false,
            isError: true,
            error: action.error,
          };
        default:
          return state;
      }
    };

    const [reducerState, dispatch] = useReducer(reducer, null, init);
    const [validating, setValidating] = useState(false);
    const { isLoadingMetadata, workflowSteps, targetLocales } = reducerState;

    /**
     * Per AWS Specifications, only start validating after the initial submit.
     * @param e the event
     * @param handleSubmit formik submit event handler function.
     */
    const enableValidation = (e, handleSubmit): void => {
      e.preventDefault();
      setValidating(true);
      handleSubmit();
    };

    const handleLoadMetadata = useCallback((): void => {
      // Use getProject API
      dispatch({
        type: 'loadingMetadata',
      });
      AtmsApiClient.httpGet(`/api/project?uid=${projectUID}`)
        .then(response => {
          dispatch({
            type: 'loadedMetadata',
            workflowSteps: response.workflowSteps,
            targetLocales: response.targetLangs,
          });
        })
        .catch(e => {
          publishCountMetric(
            'loadMetadataFailed-SelectTranslationMemoryWorkflow',
            'error',
            e.message
          );
          dispatch({ type: 'loadMetadataFailed', error: e });
        });
    }, [projectUID]);

    const handleRedirect = (values): void => {
      const query = {
        workflow: values.workflow,
        project: projectUID,
      };

      if (values.targetLocale !== ANY_TARGET_LOCALE_ID) {
        query['targetLocale'] = values.targetLocale;
      }

      history.push(`/web/project/assignTransMemory?${stringify(query)}`);
    };

    useEffect(handleLoadMetadata, [handleLoadMetadata]);

    const workflowStepsPlusAll = [
      { projectWorkflowStepId: undefined, name: I18n.t('All') },
      ...workflowSteps,
    ];

    const targetLocalesPlusAll = [undefined, ...targetLocales];

    const renderForm = ({
      values,
      errors,
      handleSubmit,
      handleChange,
      handleBlur,
    }): ReactElement => {
      return (
        <FormContainer>
          <form onSubmit={e => enableValidation(e, handleSubmit)}>
            <Form
              header={I18n.t('Select TMs')}
              actions={
                <div>
                  <Button
                    id="select-translation-memories"
                    text={I18n.t('Continue')}
                    variant="primary"
                    onClick={e => enableValidation(e, handleSubmit)}
                  />
                </div>
              }
              errorText={
                Object.keys(errors).length !== 0
                  ? I18n.t('The form contains errors. Fix them and resubmit.')
                  : ''
              }
            >
              <FormSection>
                <ColumnLayout>
                  <div data-awsui-column-layout-root="true">
                    <FormField label={I18n.t('Language')}>
                      <Select
                        id="target-locales-select"
                        loading={isLoadingMetadata}
                        options={targetLocalesPlusAll.map(targetLocale => {
                          return {
                            id: targetLocale ?? ANY_TARGET_LOCALE_ID,
                            label:
                              (targetLocale && formatLocaleCode(targetLocale)) ||
                              I18n.t('Any (show TMs that have at least one locale in the project)'),
                          };
                        })}
                        selectedId={values.targetLocale || ANY_TARGET_LOCALE_ID}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        controlId="targetLocale"
                      />
                    </FormField>
                    <FormField label={I18n.t('Workflow step')}>
                      <Select
                        id="workflow-steps-select"
                        loading={isLoadingMetadata}
                        options={workflowStepsPlusAll.map(workflowStep => {
                          return {
                            id: '' + workflowStep.projectWorkflowStepId,
                            label: workflowStep.name,
                          };
                        })}
                        selectedId={'' + values.workflow}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        controlId="workflow"
                      />
                    </FormField>
                  </div>
                </ColumnLayout>
              </FormSection>
              {reducerState.isError && (
                <div className={formSpacing}>
                  <Flash
                    type="error"
                    dismissible={true}
                    content={I18n.t('Please try again. If the issue persists, contact support.')}
                    header={I18n.t('Something went wrong.')}
                  />
                </div>
              )}
            </Form>
          </form>
        </FormContainer>
      );
    };

    const initialFormValues = {
      workflow: reducerState.workflow,
      targetLocale: reducerState.targetLocale,
    };

    const schema = yup.object().shape({
      workflow: yup.string().nullable(),
      targetLocale: yup.string().nullable(),
    });

    return (
      <PolarisFormik
        initialValues={initialFormValues}
        validationSchema={schema}
        validateOnChange={validating}
        onSubmit={handleRedirect}
        render={renderForm}
      />
    );
  }
);
