import React, { ReactElement, useEffect, useReducer } from 'react';
import {
  DatePicker,
  DatePickerProps,
  NonCancelableCustomEvent,
  TimeInput,
  TimeInputProps,
} from '@amzn/awsui-components-react-v3';
import { DateTime, Zone } from 'luxon';
import styled from 'react-emotion';
import I18n from '../../setupI18n';

interface Props {
  value?: string;
  timezone?: string | Zone;
  isDateEnabled(d: Date): boolean;
  onChange(value?: string): void;
}

interface ReducerState {
  date?: string;
  time?: string;
}

const DateTimeContainer = styled('div')`
  display: inline-block;
  max-width: 200px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const toDateTime = (
  date: string | undefined,
  time: string | undefined,
  timezone: string | Zone
): DateTime | undefined => {
  let dateString: string;
  if (!date || date.length === 0) {
    return undefined;
  }
  if (time && time.length > 0) {
    dateString = `${date}T${time}`;
  } else {
    dateString = `${date}T00:00`;
  }
  return DateTime.fromISO(dateString, {
    zone: timezone,
  });
};

const toTimestamp = (
  date: string | undefined,
  time: string | undefined,
  timezone: string | Zone
): string | undefined => {
  const dateTime = toDateTime(date, time, timezone);
  if (dateTime?.invalidReason) {
    return `${date}T${time ?? '00:00'}`; // We need to be more forgiving so we can handle date-times as they're typed
  }
  return dateTime?.toISO();
};

const toDateAndTime = (
  timestamp: string | undefined,
  timezone: string | Zone
): { date?: string; time?: string } => {
  if (!timestamp) {
    return { date: undefined, time: undefined };
  }

  const dateTime = DateTime.fromISO(timestamp, {
    zone: timezone,
  });
  if (dateTime.invalidReason) {
    const split = timestamp.split(/[^0-9-:]/);
    return { date: split[0], time: split[1] }; // Attempt to parse the string as given to be more forgiving
  }

  return { date: dateTime.toISODate(), time: dateTime.toISOTime()?.substring(0, 5) };
};

/**
 * Returns a control that produces an ISO-8601 timestamp as output.
 *
 * @param timestamp
 * @constructor
 */
export const DateTimePicker = ({
  value,
  timezone = 'UTC',
  isDateEnabled,
  onChange,
}: Props): ReactElement => {
  const init = (): ReducerState => ({});

  const reducer = (state, action): ReducerState => {
    switch (action.type) {
      case 'updateDate':
        return {
          ...state,
          date: action.date,
        };
      case 'updateTime':
        return {
          ...state,
          time: action.time === '00:00' ? undefined : action.time,
        };
      default:
        return state;
    }
  };

  const [reducerState, dispatch] = useReducer(reducer, null, init);
  const { date, time } = reducerState;

  useEffect(() => {
    const { date: newDate, time: newTime } = toDateAndTime(value, timezone);

    if (date !== newDate) {
      dispatch({ type: 'updateDate', date: newDate });
    }

    if (time !== newTime) {
      dispatch({ type: 'updateTime', time: newTime });
    }
  }, [value, timezone]);

  const handleChangeDateField = (
    e: NonCancelableCustomEvent<DatePickerProps.ChangeDetail>
  ): void => {
    const timestamp = toTimestamp(e.detail.value, time, timezone);

    onChange(timestamp);
  };

  const handleChangeTimeField = (
    e: NonCancelableCustomEvent<TimeInputProps.ChangeDetail>
  ): void => {
    const timestamp = toTimestamp(date, e.detail.value, timezone);

    onChange(timestamp);
  };

  return (
    <div>
      <DateTimeContainer>
        <DatePicker
          onChange={handleChangeDateField}
          value={date ?? ''}
          isDateEnabled={isDateEnabled}
          placeholder="YYYY/MM/DD"
          nextMonthAriaLabel={I18n.t('Next month')}
          previousMonthAriaLabel={I18n.t('Previous month')}
          todayAriaLabel={I18n.t('Today')}
        />
      </DateTimeContainer>
      <DateTimeContainer>
        <TimeInput
          onChange={handleChangeTimeField}
          value={time ?? ''}
          format="hh:mm"
          placeholder="hh:mm"
        />
      </DateTimeContainer>
    </div>
  );
};
